import {
  Button,
  CircularProgress,
  Link,
  Paper,
  Typography,
} from '@material-ui/core';
import { BugReport } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useClusters } from '../../contexts/ClusterConfig';
import model from '../../services/model';
import {
  captureBugReport,
  costMetricsQuery,
  fsUsageQuery,
  hasCadvisorQuery,
  ksmVersionQuery,
  nodeMetricQuery,
  requestQuery,
  upQuery,
} from './settingsHelpers';

interface PromDisplayItem {
  dataSource: string;
  label: string;
}

interface PromResultSet {
  upResult: boolean;
  nodeMetricResult: boolean;
  fsUsageResult: boolean;
  requestResult: boolean;
  hasCadvisorResult: boolean;
  costMetricsResult: boolean;
  ksmVersionResult: boolean;
}

export const PrometheusStatus = () => {
  const { baseApiUrl } = useClusters();
  const [promQueryResults, setPromQueryResults] =
    useState<PromResultSet | null>(null);
  const [bugReportLoading, setBugReportLoading] = useState(false);
  const promStatusResultList: PromDisplayItem[] = [
    {
      dataSource: 'upResult',
      label: `Established connection with Prometheus at ${baseApiUrl}`,
    },
    {
      dataSource: 'nodeMetricResult',
      label: 'Node exporter metrics available in Prometheus',
    },
    {
      dataSource: 'fsUsageResult',
      label: 'Cadvisor metrics available in Prometheus',
    },
    {
      dataSource: 'requestResult',
      label: 'Kube state metrics available in Prometheus',
    },
    {
      dataSource: 'hasCadvisorResult',
      label: 'Found expected cadvisor metric labels',
    },
    {
      dataSource: 'costMetricsResult',
      label: 'Kubecost cost-model metrics available in Prometheus',
    },
    {
      dataSource: 'ksmVersionResult',
      label: 'Kube state metrics version check',
    },
  ];

  const initialize = async () => {
    await Promise.all([
      model.prometheusQuery(upQuery),
      model.prometheusQuery(nodeMetricQuery),
      model.prometheusQuery(fsUsageQuery),
      model.prometheusQuery(requestQuery),
      model.prometheusQuery(hasCadvisorQuery),
      model.prometheusQuery(costMetricsQuery),
      model.prometheusQuery(ksmVersionQuery),
    ]).then(
      ([
        upResult,
        nodeMetricResult,
        fsUsageResult,
        requestResult,
        hasCadvisorResult,
        costMetricsResult,
        ksmVersionResult,
      ]) => {
        setPromQueryResults({
          upResult: upResult.status === 'success',
          nodeMetricResult: nodeMetricResult.status === 'success',
          fsUsageResult: fsUsageResult.status === 'success',
          requestResult: requestResult.status === 'success',
          hasCadvisorResult: hasCadvisorResult.status === 'success',
          costMetricsResult: costMetricsResult.status === 'success',
          ksmVersionResult: ksmVersionResult.status === 'success',
        });
      },
    );
  };

  const handleBugReportCapture = async () => {
    setBugReportLoading(true);
    const element = document.createElement('a');
    const bugReportStringResp = await captureBugReport();
    const file = new Blob(['------BUG REPORT------', bugReportStringResp], {
      type: 'text/plain',
    });
    element.href = URL.createObjectURL(file);
    element.download = 'kubecost-bug-report.txt'; // looks like we also append current date/time in ISO
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    setBugReportLoading(false);
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <div>
      <Typography style={{ marginBottom: '1.5em' }} variant="h5">
        <strong>Prometheus Status</strong>
      </Typography>
      <Typography style={{ marginBottom: '1em' }} variant="h6">
        Diagnostic output used to verify that required metrics are available in
        your Prometheus deployment. Kubecost may not perform as expected if
        metrics are missing.
      </Typography>
      {promStatusResultList.map((item: PromDisplayItem) => (
        <Typography style={{ marginBottom: '1em' }} variant="subtitle1">
          {promQueryResults === null ? (
            <CircularProgress />
          ) : promQueryResults[`${item.dataSource}`] ? (
            '✓'
          ) : (
            'X'
          )}{' '}
          {item.label}
        </Typography>
      ))}
      <Button onClick={handleBugReportCapture} variant="contained">
        <div style={{ display: 'flex', gap: 10 }}>
          {bugReportLoading ? <CircularProgress size={25} /> : <BugReport />}
          <div>Capture Bug Report</div>
        </div>
      </Button>
      <Link component={RouterLink} style={{ float: 'right' }} to="/diagnostics">
        View Full Diagnostics
      </Link>
    </div>
  );
};
