import React, { FC, useState } from 'react';
import { Button, Modal, Typography } from '@kubecost-frontend/holster';
import CircularProgress from '@material-ui/core/CircularProgress';

import Logger from '../../services/logger';

import { RequestSizingResponse } from './types';
import {
  requestRecommendationsToPlan,
  applyRequestPlan,
} from '../../services/clustercontroller/requestsizerv2';

import { ApplyMessages } from './ApplyMessages';
import { ApplyExceptions } from './ApplyExceptions';

interface ApplyRecommendationsProps {
  recommendations: RequestSizingResponse;
  filters?: any[];
  open: boolean;
  setOpen: (o: boolean) => void;
}

export const ApplyRecommendations: FC<ApplyRecommendationsProps> = ({
  recommendations,
  filters,
  setOpen,
  open,
}) => {
  // const [applyDialogOpen, setApplyDialogOpen] = useState(false);

  const [applyResp, setApplyResp] = useState();
  const [applyException, setApplyException] = useState<Error>();
  const [applying, setApplying] = useState(false);

  //let isUnfilteredRec = filters.length === 0

  async function handleApply() {
    setApplyResp(undefined);
    setApplyException(undefined);
    setApplying(true);
    try {
      Logger.log('Creating plan from recs:', recommendations);
      let plan = await requestRecommendationsToPlan(recommendations);
      Logger.log('Got plan:', plan);
      let applyResp = await applyRequestPlan(plan);
      setApplying(false);
      setApplyResp(applyResp);
      Logger.log('apply resp:', applyResp);
    } catch (error) {
      setApplying(false);
      if (error instanceof Error) {
        setApplyException(error);
      }
      console.error(error);
      Logger.error('apply failure:', error);
    }
  }

  return (
    <>
      <Button
        variant="default"
        className="flex items-center h-33px mr-3"
        onClick={() => setOpen(true)}
      >
        Automatically Implement Recommendations
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title="Confirm implementation of recommendations"
      >
        <Typography variant="p">
          Are you sure you want to apply these container request sizing
          recommendations to{' '}
          <Typography
            className="inline"
            variant="p"
            style={{ fontWeight: 600 }}
          >
            {recommendations.length} containers
          </Typography>
          ?
        </Typography>

        {
          /*isUnfilteredRec && (*/
          <Typography variant="p" style={{ fontWeight: 600 }} className="mb-3">
            <br />
            Warning: this is an unfiltered recommendation and will apply to an
            entire cluster.
          </Typography>
          /*)*/
        }
        <div>
          <Button
            variant="primary"
            className="mr-3"
            onClick={() => {
              handleApply();
              setOpen(false);
            }}
          >
            Yes, apply the recommendation
          </Button>
          <Button
            variant="primary"
            className="mr-3"
            onClick={() => {
              setOpen(false);
            }}
          >
            No, do not apply the recommendation
          </Button>
        </div>
      </Modal>
      {applying && <CircularProgress size={20} />}
      {applyException !== undefined && (
        <ApplyExceptions
          header="Failed to apply"
          exception={applyException.toString()}
        />
      )}

      {applyResp !== undefined &&
        applyResp.Errors !== null &&
        applyResp.Errors.length != 0 && (
          <ApplyMessages
            header="Applying recommendation partially failed:"
            data={applyResp.Errors}
          />
        )}

      {applyResp !== undefined &&
        applyResp.Warnings !== null &&
        applyResp.Warnings.length != 0 && (
          <ApplyMessages
            header="Applying recommendation had some warnings:"
            data={applyResp.Warnings}
          />
        )}
    </>
  );
};

ApplyRecommendations.displayName = 'ApplyRecommendations';
