import { List } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import { green, grey, red, yellow } from '@material-ui/core/colors';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import NotInterestedOutlinedIcon from '@material-ui/icons/NotInterestedOutlined';
import map from 'lodash/map';
import React from 'react';
import { PricingSourceStatus } from '../../services/diagnostics';

type PricingSourceProps = {
  source: PricingSourceStatus;
};

// Pricing Source Component
const PricingSource = ({ source }: PricingSourceProps): React.ReactElement => {
  const { available, enabled, name, error, config } = source;
  const [open, setOpen] = React.useState(false);
  const handleClick = () => setOpen(!open);

  const icon = enabled ? (
    available ? (
      <CheckCircleOutlinedIcon style={{ color: green[700] }} />
    ) : (
      <ReportProblemOutlinedIcon style={{ color: yellow[700] }} />
    )
  ) : (
    <NotInterestedOutlinedIcon style={{ color: grey[700] }} />
  );

  // Whether or not the source is expandable
  if (error !== '' || (config && Object.keys(config).length)) {
    return (
      <>
        <ListItem button onClick={handleClick} dense>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={name} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List style={{ paddingLeft: '4%' }} dense>
            {map(
              config,
              (propertyValue: string, propertyName: string) =>
                propertyName && (
                  <ListItem key={propertyName} dense>
                    {propertyValue && propertyValue !== '' && (
                      <>
                        <ListItemIcon>
                          <CheckIcon style={{ color: green[700] }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={`Config ${propertyName} is set`}
                        />
                      </>
                    )}
                    {(!propertyValue || propertyValue === '') && (
                      <>
                        <ListItemIcon>
                          <ErrorOutlineIcon style={{ color: red[700] }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={`Config ${propertyName} is missing`}
                        />
                      </>
                    )}
                  </ListItem>
                ),
            )}
            {enabled && error && error !== '' && (
              <ListItem key="error-key" dense>
                <ListItemIcon>
                  <ErrorOutlineIcon style={{ color: red[700] }} />
                </ListItemIcon>
                <ListItemText primary={error} />
              </ListItem>
            )}
            {!enabled && (
              <ListItem key="info-key" dense>
                <ListItemIcon>
                  <NotInterestedOutlinedIcon style={{ color: grey[700] }} />
                </ListItemIcon>
                <ListItemText primary={error} />
              </ListItem>
            )}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItem dense>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={name} />
    </ListItem>
  );
};

export default PricingSource;
