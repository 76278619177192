// react
import React, { FC, useState } from 'react';

// mui
import Popover from '@material-ui/core/Popover';

// holster
import {
  Chip,
  FormControlLabel,
  Input,
  Typography,
} from '@kubecost-frontend/holster';

// local
import { useClusters } from '../../contexts/ClusterConfig';
import { toCurrency } from '../../services/format';
import { AddFilter } from '../AllocationNew/AddFilter';
import { pluralize } from '../../services/util';

interface EditReportDialogProps {
  anchorEl?: Element;
  defaultSharedNamespaces: string[];
  defaultSharedLabels: string[];
  defaultSharedOverhead: number;
  shareTenancyCosts: boolean;
  customSharedNamespaces: string[] | null;
  setCustomSharedNamespaces: (s: string) => void;
  customSharedLabels: string[] | null;
  setCustomSharedLabels: (s: string) => void;
  customSharedOverhead: number | null;
  setCustomSharedOverhead: (arg: string | null) => void;
  onClose: () => void;
  setFilters: (arg: { property: string; value: string }[]) => void;
  filters: { property: string; value: string }[];
}

const allFilterPropertyOptions = [
  'cluster',
  'node',
  'namespace',
  'label',
  'service',
  'controller',
  'controllerkind',
  'pod',
];

export const EditReportDialog: FC<EditReportDialogProps> = ({
  anchorEl,
  defaultSharedNamespaces,
  defaultSharedLabels,
  defaultSharedOverhead,
  shareTenancyCosts,
  customSharedNamespaces,
  setCustomSharedNamespaces,
  customSharedLabels,
  setCustomSharedLabels,
  customSharedOverhead,
  setCustomSharedOverhead,
  onClose,
  filters,
  setFilters,
}) => {
  const baseLocalOverhead =
    customSharedOverhead != null ? customSharedOverhead : 0;
  const baseLocalNamespaces =
    customSharedNamespaces && customSharedNamespaces.length
      ? customSharedNamespaces
      : [];
  const baseLocalLabels =
    customSharedLabels && customSharedLabels.length ? customSharedLabels : [];
  const { modelConfig } = useClusters();
  const [localSharedOverhead, setLocalSharedOverhead] = useState(
    baseLocalOverhead.toString(),
  );
  const [localSharedNamespaces, setLocalSharedNamespaces] = useState(
    baseLocalNamespaces.join(','),
  );
  const [localSharedLabels, setLocalSharedLabels] = useState(
    baseLocalLabels.join(','),
  );

  const handleUpdateFilter = (f: { property: string; value: string }) => {
    const property = f.property;
    const value = f.value.replace('=', ':');

    setFilters([...filters, { property, value }]);
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div className="p-4" style={{ width: 450 }}>
        <section className="my-3">
          <FormControlLabel>Filters</FormControlLabel>
          <AddFilter
            propertyOptions={allFilterPropertyOptions}
            handleAddFilter={handleUpdateFilter}
          />
          {filters.map(({ property, value }) => (
            <Chip
              className="text-tiny"
              color="primary"
              label={`${property}: ${value}`}
              onDelete={() =>
                setFilters(filters.filter((f) => value !== f.value))
              }
            />
          ))}
        </section>

        <section className="my-3">
          <FormControlLabel>Default shared resources</FormControlLabel>
          <Typography variant="h6">
            Default shared namespaces, shared labels, and fixed costs can be
            adjusted in{' '}
            <a className="text-kc-link" href="../settings">
              settings
            </a>
            .
          </Typography>
          {defaultSharedNamespaces && defaultSharedNamespaces.length ? (
            <Chip
              className={`m-1 text-tiny ${
                customSharedNamespaces && customSharedNamespaces.length
                  ? 'line-through'
                  : ''
              }`}
              color="primary"
              label={`${pluralize(
                'namespace',
                defaultSharedNamespaces.length,
              )}: ${defaultSharedNamespaces.join(', ')}`}
            />
          ) : null}
          {defaultSharedLabels && defaultSharedLabels.length ? (
            <Chip
              className={`m-1 text-tiny ${
                customSharedLabels && customSharedLabels.length
                  ? 'line-through'
                  : ''
              }`}
              color="primary"
              label={`${pluralize(
                'label',
                defaultSharedLabels.length,
              )}: ${defaultSharedLabels.join(', ')}`}
            />
          ) : null}
          {defaultSharedOverhead ? (
            <Chip
              className={`m-1 text-tiny ${
                customSharedOverhead ? 'line-through' : ''
              }`}
              color="primary"
              label={`cost: ${toCurrency(
                defaultSharedOverhead,
                modelConfig.currencyCode,
              )}/mo`}
            />
          ) : null}
          {shareTenancyCosts ? (
            <Chip
              className="m-1 text-tiny"
              color="primary"
              label="Tenancy Costs"
            />
          ) : null}
        </section>

        <section className="my-3">
          <FormControlLabel>Custom shared resources</FormControlLabel>
          <Typography variant="h6">
            Custom shared namespaces, shared labels, and fixed costs can be set
            here to override the defaults above.
          </Typography>
          {customSharedNamespaces && customSharedNamespaces.length ? (
            <Chip
              className="m-1 text-tiny"
              color="primary"
              label={`${pluralize(
                'namespace',
                customSharedNamespaces.length,
              )}: ${customSharedNamespaces.join(', ')}`}
              onDelete={() => {
                setCustomSharedNamespaces('');
              }}
            />
          ) : null}
          {customSharedLabels && customSharedLabels.length ? (
            <Chip
              className="m-1 text-tiny"
              color="primary"
              label={`${pluralize(
                'label',
                customSharedLabels.length,
              )}: ${customSharedLabels.join(', ')}`}
              onDelete={() => {
                setCustomSharedLabels('');
              }}
            />
          ) : null}
          {customSharedOverhead != null ? (
            <Chip
              className="m-1 text-tiny"
              color="primary"
              label={`cost: ${toCurrency(
                customSharedOverhead,
                modelConfig.currencyCode,
              )}/mo`}
              onDelete={() => {
                setCustomSharedOverhead(null);
              }}
            />
          ) : null}
        </section>

        <section className="my-3">
          <FormControlLabel>Configure custom shared resources</FormControlLabel>
          <Input
            className="mt-2 w-full"
            helperText="Custom shared overhead cost"
            onChange={(e) => setLocalSharedOverhead(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setCustomSharedOverhead(localSharedOverhead);
              }
            }}
            value={localSharedOverhead}
          />
          <Input
            className="mt-2 w-full"
            helperText="Custom shared namespaces"
            onChange={(e) => setLocalSharedNamespaces(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setCustomSharedNamespaces(localSharedNamespaces);
              }
            }}
            value={localSharedNamespaces}
          />
          <Input
            className="mt-2 w-full"
            helperText="Custom shared labels"
            onChange={(e) => setLocalSharedLabels(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setCustomSharedLabels(localSharedLabels);
              }
            }}
            value={localSharedLabels}
          />
        </section>
      </div>
    </Popover>
  );
};
