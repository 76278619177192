import React from 'react';
import { Link } from 'react-router-dom';
import {
  IconButton,
  Link as MuiLink,
  Paper,
  Tabs,
  Tab,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core';
import {
  Refresh as RefreshIcon,
  Settings as SettingsIcon,
} from '@material-ui/icons';

import Header from '../../../components/Header';

import { getUnclaimedVolumeData } from './api';

const UnclaimedVolumes: React.FC = () => {
  const [volumes, setVolumes] = React.useState<
    {
      name: string;
      owner: string;
      size: string;
      storageClass: string;
    }[]
  >([]);
  const [grouping, setGrouping] = React.useState(0);
  const [columns, setColumns] = React.useState([
    { head: 'Volume', prop: '' },
    { head: 'Storage Class', prop: '' },
    { head: 'Size', prop: '' },
  ]);

  const initializeData = () => {
    getUnclaimedVolumeData().then((data) => {
      setVolumes(data);
    });
  };

  React.useEffect(() => {
    initializeData();
  }, []);

  React.useEffect(() => {
    if (grouping === 0) {
      setColumns([
        { head: 'Volume', prop: 'name' },
        { head: 'Storage Class', prop: 'storageClass' },
        { head: 'Size', prop: 'size' },
      ]);
    } else if (grouping === 1) {
      setColumns([
        { head: 'Volume', prop: 'name' },
        { head: 'Owner', prop: 'owner' },
        { head: 'Size', prop: 'size' },
      ]);
    }
  }, [grouping]);

  const handleGroupingChange = React.useCallback((event, newValue) => {
    setGrouping(newValue);
  }, []);
  return (
    <>
      <Header
        breadcrumbs={[
          { name: 'Cluster Savings', href: 'savings' },
          { name: 'Unclaimed Volumes', href: 'unclaimed-volumes' },
        ]}
      >
        <IconButton onClick={() => initializeData()} aria-label="refresh">
          <RefreshIcon />
        </IconButton>
        <MuiLink component={Link} to="../settings">
          <IconButton aria-label="settings">
            <SettingsIcon />
          </IconButton>
        </MuiLink>
      </Header>

      <Paper style={{ padding: '20px 20px 20px 20px' }}>
        <Typography
          style={{ fontSize: '22px', marginBottom: '12px' }}
          variant="h1"
        >
          Volumes unused by any volume claims
        </Typography>
        <Typography variant="body1">
          View volumes unused by any pods. Consider deleting or moving these to
          a cheaper storage tier to save money.
        </Typography>
      </Paper>

      <Paper style={{ marginTop: 20 }}>
        <Tabs
          indicatorColor="primary"
          onChange={handleGroupingChange}
          value={grouping}
        >
          <Tab label="By Owner" />
          <Tab label="By Namespace" />
        </Tabs>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((col) => {
                  return <TableCell>{col.head}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {volumes.map((volume) => {
                return (
                  <TableRow>
                    {columns.map((col) => {
                      return <TableCell>{volume[col.prop]}</TableCell>;
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default UnclaimedVolumes;
