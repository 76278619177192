// react
import React, { FC, FocusEvent, useState } from 'react';

// lodash
import sortBy from 'lodash/sortBy';

// holster
import { Button, Input, Menu } from '@kubecost-frontend/holster';
import { MenuItemProps } from '@kubecost-frontend/holster/dist/components/Menu';

// local
import { FilterIcon, PlusIcon } from '../../assets/images';

interface AddFilterProps {
  handleAddFilter: (f: { property: string; value: string }) => void;
  propertyOptions: string[];
}

export const AddFilter: FC<AddFilterProps> = ({
  propertyOptions,
  handleAddFilter,
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [property, setProperty] = useState('');
  const [value, setValue] = useState('');

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu);
  };

  const handleSetFilterProperty = (prop: MenuItemProps) => {
    setProperty(prop.text);
    setOpenMenu(false);
  };

  const handleSetFilterValue = (
    event: FocusEvent<HTMLInputElement, Element>,
  ) => {
    const val = event.target.value;
    setValue(val);
  };

  const handleAddNewFilter = () => {
    handleAddFilter({ property, value });
    setOpenMenu(false);
    setValue('');
  };

  return (
    <div
      className="py-4"
      style={{
        display: 'grid',
        gridTemplateColumns: '.3fr 1fr .1fr',
        gap: '10px',
      }}
    >
      <Button variant="default" onClick={handleOpenMenu}>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <div style={{ paddingRight: '.5em', paddingTop: '.2em' }}>
            <FilterIcon />
          </div>
          {property ? property : 'Filters'}
        </div>
      </Button>
      {openMenu ? (
        <Menu
          items={sortBy(propertyOptions, 'asc')}
          onClose={() => setOpenMenu(false)}
          selectItem={handleSetFilterProperty}
        />
      ) : null}
      <div>
        <Input
          onChange={handleSetFilterValue}
          style={{ width: '100%', paddingLeft: '1em' }}
          value={value}
          onKeyDown={(press) => {
            if (press.key === 'Enter') {
              handleAddNewFilter();
            }
          }}
        />
      </div>

      <div style={{ display: 'flex', gap: '5px' }}>
        <Button variant="default" onClick={handleAddNewFilter}>
          <PlusIcon />
        </Button>
      </div>
    </div>
  );
};
