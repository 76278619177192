import kubecost from './kubecost';
import { getCurrentContainerAddressModel } from './util';

async function fetchActiveReservations(region: string): Promise<Reservation[]> {
  const url = `${getCurrentContainerAddressModel()}/reservations/active?region=${encodeURIComponent(
    region,
  )}`;
  return (await kubecost.get<Reservation[]>(url)).data;
}

async function fetchReservationRecommendations(
  region: string,
  win: string,
): Promise<RecommendationSet> {
  const r = encodeURIComponent(region);
  const w = encodeURIComponent(win);
  const url = `${getCurrentContainerAddressModel()}/reservations/recommendation?region=${r}&window=${w}`;
  return (await kubecost.get<RecommendationSet>(url)).data;
}

async function fetchOfferings(
  nodeType: string,
  region: string,
): Promise<number> {
  const url = `${getCurrentContainerAddressModel()}/reservations/offerings?instanceType=${nodeType}&region=${region}`;
  return (await kubecost.get<number>(url)).data;
}

export type Reservation = {
  availabilityZone: string | null;
  end: string;
  id: string;
  nodeType: string;
  start: string;
  term: number;
};

export interface RecommendationSet {
  [index: string]: Recommendation;
}

export interface Recommendation {
  reservations: number;
  savingsPct: number;
}

export default {
  fetchOfferings,
  fetchActiveReservations,
  fetchReservationRecommendations,
};
