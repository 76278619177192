import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

type AggregationState<T> = {
  agg?: T[];
  labels?: string[];
  window?: string;
};

const useAggregationParamState = <T extends string>(
  validParams: Record<T, string>,
  defaultState = {
    agg: ['service'],
    labels: undefined,
    window: '7d',
  },
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const isValidParam = useCallback(
    (param: string): param is T =>
      Object.keys(validParams ?? []).includes(param) ||
      param.startsWith('label:'),
    [validParams],
  );

  const getParams = (
    key: keyof AggregationState<T>,
    paramsInit = searchParams,
  ) => {
    const params = paramsInit.get(key);

    return params ? decodeURIComponent(params) : undefined;
  };

  const aggregationState: T[] | undefined = (
    getParams('agg')?.split(',') ?? defaultState?.agg
  )?.filter(isValidParam);

  const windowState = getParams('window') || defaultState?.window;

  const labelState = getParams('labels')?.split(',') || defaultState?.labels;

  const setAggregationState = (newParamState: AggregationState<T>) =>
    // Use functional return to avoid state batching issues
    setSearchParams((prevParamState) => {
      const newAggregationState =
        newParamState?.agg?.toString() || getParams('agg', prevParamState);

      const newLabelsState =
        newParamState?.labels?.toString() ||
        getParams('labels', prevParamState);

      const newWindowState =
        newParamState?.window || getParams('window', prevParamState);

      return {
        // Only add params if a param has data to avoid empty param "keys" in the URL
        ...(newAggregationState && { agg: newAggregationState }),
        ...(newLabelsState && { labels: newLabelsState }),
        ...(newWindowState && { window: newWindowState }),
      };
    });

  return {
    aggregationState,
    isValidParam,
    labelState,
    setParamState: setAggregationState,
    windowState,
  };
};

export { useAggregationParamState };
