import {
  Link,
} from '@material-ui/core';
import { Typography } from '@kubecost-frontend/holster';
import React from 'react';
import { designSystemColorMap, primary } from '../../../services/colors';
import { toCurrency } from '../../../services/format';

interface SavingsCard2Props {
  title: string;
  value: number;
  currency: string;
  onClickHref?: string;
}

export const SavingsCard2 = ({
  title,
  value,
  currency,
  onClickHref,
}: SavingsCard2Props) => (
  <div
    style={{
      padding: '1em',
      height: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      justifyContent: 'space-between',
    }}
    className="border-b border-kc-gray-100"
  >
    <div>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="h5">
        <strong>{toCurrency(value, currency)}</strong>
      </Typography>
    </div>
    {onClickHref && (
      <Link
        style={{ marginLeft: 'auto', color: designSystemColorMap.primary }}
        href={onClickHref}
      >
        Request sizing
      </Link>
    )}
  </div>
);
