import React from 'react';

import { SavingsCard2 } from './SavingsCard2';

interface SavingsTileProps {
  savingsData: any;
  currency: string;
  onClickHref: string;
}

export const SavingsTile = ({
  savingsData,
  currency,
  onClickHref,
}: SavingsTileProps) => (
  <div
    style={{
      height: '100%',
      display: 'grid',
      gridTemplateRows: '1fr 1fr 1fr',
      alignItems: 'center',
    }}
  >
      <SavingsCard2
        title="Monthly CPU Savings"
        value={savingsData.monthlySavingsCPU}
        currency={currency}
      />
      <SavingsCard2
        title="Total Monthly Savings"
        value={savingsData.monthlySavings}
        currency={currency}
        onClickHref={onClickHref}
      />
      <SavingsCard2
        title="Monthly RAM Savings"
        value={savingsData.monthlySavingsRAM}
        currency={currency}
      />
  </div>
);
