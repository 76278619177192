import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import get from 'lodash/get';
import round from 'lodash/round';
import { makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import Link from '@material-ui/core/Link';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AllocationChart from '../AllocationChart';
import AllocationReportRow from './Row';
import Analytics from '../../../services/analytics';
import { toCurrency } from '../../../services/format';
import { getCost, AllocationTotals } from '../allocation';
import { Allocation } from '../../../types/Allocation';
import { useClusters } from '../../../contexts/ClusterConfig';

const useStyles = makeStyles({
  noResults: {
    padding: 24,
  },
});

// descendingComparator provides a comparator for stableSort, which compares
// the given orderBy column of two rows, a and b. Due to the design of getCost,
// whereby the complete value of a "cost" column is actually the cost plus the
// associated adjustment (e.g. cpuCost = cpuCost + cpuCostAdjustment) the
// getCost function must be called here for "cost" columns. Kind of a hacky
// solution, but it's the simplest way to fix sorting. See the complete
// discussion: https://github.com/kubecost/cost-analyzer-frontend/issues/301
function descendingComparator(a: Allocation, b: Allocation, orderBy: string) {
  const isCost = orderBy.indexOf('Cost') > 0;
  if (isCost) {
    if (getCost(b, orderBy) < getCost(a, orderBy)) {
      return -1;
    }
    if (getCost(b, orderBy) > getCost(a, orderBy)) {
      return 1;
    }
  } else {
    if (get(b, orderBy) < get(a, orderBy)) {
      return -1;
    }
    if (get(b, orderBy) > get(a, orderBy)) {
      return 1;
    }
  }
  return 0;
}

function getComparator(order: string, orderBy: string) {
  return order === 'desc'
    ? (a: Allocation, b: Allocation) => descendingComparator(a, b, orderBy)
    : (a: Allocation, b: Allocation) => -descendingComparator(a, b, orderBy);
}

function stableSort(
  array: Array<Allocation>,
  comparator: (a: Allocation, b: Allocation) => number,
) {
  const stabilizedThis: [Allocation, number][] = array.map((el, index) => [
    el,
    index,
  ]);
  stabilizedThis.sort((a: [Allocation, number], b: [Allocation, number]) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, label: 'Name', width: 'auto' },
  { id: 'cpuCost', numeric: true, label: 'CPU', width: 90 },
  { id: 'gpuCost', numeric: true, label: 'GPU', width: 90 },
  { id: 'ramCost', numeric: true, label: 'RAM', width: 90 },
  { id: 'pvCost', numeric: true, label: 'PV', width: 90 },
  { id: 'networkCost', numeric: true, label: 'Network', width: 90 },
  { id: 'loadBalancerCost', numeric: true, label: 'LB', width: 90 },
  { id: 'sharedCost', numeric: true, label: 'Shared', width: 90 },
  { id: 'totalEfficiency', numeric: true, label: 'Efficiency', width: 90 },
  { id: 'totalCost', numeric: true, label: 'Total cost', width: 90 },
];

const efficiencyTooltip =
  'Efficiency is defined as (usage / request) for CPU and RAM. If resources are used, but no resources are requested, then efficiency is considered infinite.';

const labelAliases = [
  'team',
  'department',
  'environment',
  'product',
  'daemonset',
  'job',
  'deployment',
];

const DetailsPageLink = ({
  aggregateBy,
  resourceName,
}: {
  aggregateBy: string[];
  resourceName: string;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  // in cases where resourceName has the format clusterName/clusterID, we want only clusterID passed to details.html
  let baseResourceName = resourceName;
  if (
    aggregateBy.length === 1 &&
    aggregateBy[0] === 'cluster' &&
    baseResourceName.split('/').length > 1
  ) {
    baseResourceName = baseResourceName.split('/')[1];
  }
  let baseAggList = aggregateBy.toString();
  const urlParams = new URLSearchParams(location.search);
  const currentFilters = JSON.parse(
    atob(urlParams.get('filters') || '') || '{}',
  );

  const handleDrilldown = (e: SyntheticEvent) => {
    e.stopPropagation();
    Analytics.record('inspected_item', {
      resourceName,
      resourceType: aggregateBy,
    });
    window.open(
      `./details?name=${baseResourceName}&type=${baseAggList}`,
      '_blank',
    );
  };
  if (currentFilters.length > 0) {
    currentFilters.forEach((item: { property: string; value: string }) => {
      // to handle cases where we want to venture to type=namespace&name=kubecost via row click,
      // but we also have a filter of namespace=kubecost
      if (!baseAggList.includes(item.property)) {
        if (item.property !== 'label') {
          baseAggList += `,${item.property}`;
          baseResourceName += `/${item.value}`;
        } else {
          baseAggList += `,${item.property}:${item.value.split(':')[0]}`;
          baseResourceName += `/${item.value.split(':')[1]}`;
        }
      }
    });
  }

  return (
    <Tooltip title="Click to inspect details">
      <Link onClick={handleDrilldown}>
        {resourceName} <OpenInNewIcon style={{ fontSize: 12, color: 'gray' }} />
      </Link>
    </Tooltip>
  );
};

interface ComponentProps {
  aggregateBy: string[];
  allocationData: Allocation[][];
  chartDisplay: string;
  cumulativeData: Allocation[];
  drillDownCompatible: string[];
  drillDownExemptRows: string[];
  rate: string;
  sharingIdle: boolean;
  totalData: AllocationTotals;
  canDrillDown: (row: {
    totalCost: number;
    externalCost: number;
    name: string;
  }) => boolean;
  drillDownForRow: (row: {
    name: string;
    cluster: string;
    node: string;
    totalCost: number;
    externalCost: number;
    namespace: string;
    controllerkind: string;
    service: string;
    department: string;
    environment: string;
    owner: string;
    product: string;
    team: string;
  }) => () => void;
}

const AllocationReport = ({
  allocationData,
  cumulativeData,
  totalData,
  drillDownForRow,
  aggregateBy,
  chartDisplay,
  canDrillDown,
  sharingIdle,
  rate,
  drillDownCompatible,
  drillDownExemptRows,
}: ComponentProps) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = React.useState('totalCost');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { modelConfig } = useClusters();

  const numData = cumulativeData.length;

  useEffect(() => {
    setPage(0);
  }, [numData]);

  if (allocationData.length === 0) {
    return (
      <Typography variant="body2" className={classes.noResults}>
        No results
      </Typography>
    );
  }

  const inspectableAggs = [
    'namespace',
    'controller',
    'service',
    ...labelAliases,
  ];

  function isInspectable(row: {
    name: string;
    totalCost: number;
    externalCost: number;
  }) {
    if (aggregateBy.length !== 1) {
      return false;
    }
    const agg = aggregateBy[0];
    if (agg.startsWith('label:')) {
      let labelName = aggregateBy[0].split(':').pop() || '';
      labelName = labelName.replace(/\.|\//g, '_');
      return row.name.startsWith(`${labelName}=`);
    }
    return canDrillDown(row) && inspectableAggs.includes(agg);
  }

  const lastPage = Math.floor(numData / rowsPerPage);

  const orderedRows = stableSort(cumulativeData, getComparator(order, orderBy));
  const pageRows = orderedRows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  const dataToAllocationRow = (row) => {
    let name = row.name;
    if (name === '__idle__' && sharingIdle) {
      name = 'Undistributable idle';
    }
    if (name === '__unmounted__') {
      name = 'Unmounted PVs';
    }

    return (
      <AllocationReportRow
        key={row.name}
        name={
          isInspectable(row) ? (
            <DetailsPageLink aggregateBy={aggregateBy} resourceName={name} />
          ) : (
            name
          )
        }
        drillDown={canDrillDown(row) ? drillDownForRow(row) : null}
        efficiency={row.totalEfficiency}
        efficiencyTooltip={efficiencyTooltip}
        cpuCost={row.cpuCost}
        gpuCost={row.gpuCost}
        ramCost={row.ramCost}
        pvCost={row.pvCost}
        loadBalancerCost={row.loadBalancerCost}
        networkCost={row.networkCost}
        sharedCost={row.sharedCost}
        totalCost={row.totalCost}
        cpuRequest={row.cpuCoreRequestAverage * row.minutes}
        ramRequest={row.ramByteRequestAverage * row.minutes}
        isIdle={row.name.includes('__idle__')}
        isUnmounted={row.name.includes('__unmounted__')}
        costSuffix={
          { hourly: '/hr', monthly: '/mo', daily: '/day' }[rate] || ''
        }
      />
    );
  };

  return (
    <div id="report">
      <AllocationChart
        allocationRange={allocationData}
        n={10}
        height={300}
        aggregateBy={aggregateBy}
        chartDisplay={chartDisplay}
        sharingIdle={sharingIdle}
        allocationRows={pageRows.map(dataToAllocationRow)}
        drillDownCompatible={drillDownCompatible}
        drillDownExemptRows={drillDownExemptRows}
      />
      <hr
        style={{
          margin: 0,
          border: 'none',
          borderTop: '1px solid rgba(0,0,0,0.1)',
        }}
      />
      <TableContainer style={{ marginRight: '2em' }}>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map((cell) => (
                <TableCell
                  key={cell.id}
                  align={cell.numeric ? 'right' : 'left'}
                  sortDirection={orderBy === cell.id ? order : false}
                  style={{
                    width: cell.width,
                    paddingRight: cell.id === 'totalCost' ? '2em' : '',
                  }}
                >
                  <TableSortLabel
                    active={orderBy === cell.id}
                    direction={orderBy === cell.id ? order : 'asc'}
                    onClick={() => {
                      const isDesc = orderBy === cell.id && order === 'desc';
                      setOrder(isDesc ? 'asc' : 'desc');
                      setOrderBy(cell.id);
                    }}
                  >
                    {cell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left" style={{ fontWeight: 500 }}>
                {totalData.name}
              </TableCell>
              <TableCell align="right" style={{ fontWeight: 500 }}>
                {toCurrency(
                  getCost(totalData, 'cpuCost'),
                  modelConfig.currencyCode,
                )}
              </TableCell>
              <TableCell align="right" style={{ fontWeight: 500 }}>
                {toCurrency(
                  getCost(totalData, 'gpuCost'),
                  modelConfig.currencyCode,
                )}
              </TableCell>
              <TableCell align="right" style={{ fontWeight: 500 }}>
                {toCurrency(
                  getCost(totalData, 'ramCost'),
                  modelConfig.currencyCode,
                )}
              </TableCell>
              <TableCell align="right" style={{ fontWeight: 500 }}>
                {toCurrency(
                  getCost(totalData, 'pvCost'),
                  modelConfig.currencyCode,
                )}
              </TableCell>
              <TableCell align="right" style={{ fontWeight: 500 }}>
                {toCurrency(
                  getCost(totalData, 'networkCost'),
                  modelConfig.currencyCode,
                )}
              </TableCell>
              <TableCell align="right" style={{ fontWeight: 500 }}>
                {toCurrency(
                  getCost(totalData, 'loadBalancerCost'),
                  modelConfig.currencyCode,
                )}
              </TableCell>
              <TableCell align="right" style={{ fontWeight: 500 }}>
                {toCurrency(
                  getCost(totalData, 'sharedCost'),
                  modelConfig.currencyCode,
                )}
              </TableCell>
              {totalData.totalEfficiency === 1.0 &&
              totalData.cpuReqCoreHrs === 0 &&
              totalData.ramReqByteHrs === 0 ? (
                <Tooltip title={efficiencyTooltip}>
                  <TableCell align="right" style={{ fontWeight: 500 }}>
                    Inf%
                  </TableCell>
                </Tooltip>
              ) : (
                <TableCell align="right" style={{ fontWeight: 500 }}>
                  {round(totalData.totalEfficiency * 100, 1)}%
                </TableCell>
              )}
              <TableCell
                align="right"
                style={{ fontWeight: 500, paddingRight: '2em' }}
              >
                {toCurrency(
                  getCost(totalData, 'totalCost'),
                  modelConfig.currencyCode,
                )}
              </TableCell>
            </TableRow>
            {pageRows.map(dataToAllocationRow)}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={numData}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25, 50]}
        page={Math.min(page, lastPage)}
        onPageChange={(event, newPage) => setPage(newPage)}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
    </div>
  );
};

export default React.memo(AllocationReport);
