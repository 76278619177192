import React from 'react';
import { IconProps } from './types';

export const CloseIcon = ({ ...args }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
    {...args}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.657 12.071L5 6.414L6.414 5l5.657 5.657L17.728 5l1.414 1.414l-5.657 5.657l5.657 5.657l-1.414 1.414l-5.657-5.657l-5.657 5.657L5 17.728z"
    />
  </svg>
);
