import { ButtonGroup, Button } from '@material-ui/core';
import React from 'react';

export const GraphToggle = ({
  setActiveGraph,
}: {
  setActiveGraph: (type: string) => void;
}) => (
  <ButtonGroup
    style={{ float: 'right', height: 20 }}
    variant="text"
    color="primary"
    aria-label="text primary button group"
  >
    <Button onClick={() => setActiveGraph('Bar')}>Bar</Button>
    <Button onClick={() => setActiveGraph('Line')}>Line</Button>
  </ButtonGroup>
);
