import React from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const UpgradeSettings = () => {
  return (
    <section>
      <header className={'flex items-center justify-between pb-4'}>
        <Typography className={'!font-[700]'} variant="h5">
          Streamline your monitoring
        </Typography>

        <Button
          href={'https://www.kubecost.com/pricing/?upgrade=true'}
          target={'_blank'}
          variant="contained"
        >
          Upgrade plan
        </Button>
      </header>

      <Typography variant="h6">Unified multi-cluster view</Typography>
      <Typography className={'w-3/4'} variant="subtitle1">
        The Unified multi-cluster view allows for a single-pane-of-glass view
        into all aggregated cluster costs globally.
      </Typography>

      <Typography className={'pt-4'} variant="h6">
        Long-term metric retention
      </Typography>
      <Typography className={'w-3/4'} variant="subtitle1">
        Retain spend, allocation, and efficiency data for up to 30 days while
        also sharing custom reports across teams.
      </Typography>

      <Divider
        variant="middle"
        style={{ marginTop: '4em', marginBottom: '4em' }}
      />
    </section>
  );
};

export default UpgradeSettings;
