// MUI components
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

// MUI styles
import { makeStyles } from '@material-ui/styles';

// react
import React, { useState } from 'react';

// local
import {
  Alert,
  AlertTypes,
  BudgetAlert,
  ClusterHealthAlert,
  DiagnosticAlert,
  EfficiencyAlert,
  RecurringUpdate,
  SpendChangeAlert,
} from '../../services/alerts';

import If from '../If';
import BudgetAlertsTable from './BudgetAlertsTable';
import ClusterHealthAlertsTable from './ClusterHealthAlertsTable';
import DiagnosticAlertsTable from './DiagnosticAlertsTable';
import EfficiencyAlertsTable from './EfficiencyAlertsTable';
import RecurringAlertsTable from './RecurringAlertsTable';
import SpendChangeAlertsTable from './SpendChangeAlertsTable';

const useStyles = makeStyles({
  formControl: {
    marginBottom: 16,
  },
});

function AlertsTable({
  alerts,
  alertTypeOpts,
  createAlert,
  removeAlert,
}: ComponentProps): React.ReactElement {
  const classes = useStyles();

  const [alertType, setAlertType] = useState(alertTypeOpts[0]);

  return (
    <>
      {/* select alert type to work with */}
      <FormControl
        data-test="alerts-table-select"
        className={classes.formControl}
      >
        <InputLabel id="alert-select-label">Alert Type</InputLabel>
        <Select
          data-test="alerts-table-selector"
          labelId="alert-select-label"
          value={alertType.value}
          onChange={(e) => {
            const chosen = alertTypeOpts.find(
              (ato) => ato.value === e.target.value,
            );
            if (chosen) {
              setAlertType(chosen);
            }
          }}
        >
          {alertTypeOpts.map((at) => (
            <MenuItem key={at.value} value={at.value}>
              {at.label} ({alerts.filter((a) => a.type === at.value).length})
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* blurb explaining the chosen alert type */}
      <If condition={!!alertType.explanation}>
        <Typography>{alertType.explanation}</Typography>
      </If>

      {/* table showing alerts of the selected type */}
      {getAlertsTable()}
    </>
  );

  function getAlertsTable() {
    switch (alertType.value) {
      case AlertTypes.Budget:
        return (
          <BudgetAlertsTable
            alerts={
              alerts.filter((a) => a.type === alertType.value) as BudgetAlert[]
            }
            createAlert={createAlert}
            removeAlert={removeAlert}
            locked={alertType.lockedFields}
          />
        );
      case AlertTypes.Health:
        return (
          <ClusterHealthAlertsTable
            alerts={
              alerts.filter(
                (a) => a.type === alertType.value,
              ) as ClusterHealthAlert[]
            }
            createAlert={createAlert}
            removeAlert={removeAlert}
            locked={alertType.lockedFields}
          />
        );
      case AlertTypes.Diagnostic:
        return (
          <DiagnosticAlertsTable
            alerts={
              alerts.filter(
                (a) => a.type === alertType.value,
              ) as DiagnosticAlert[]
            }
            createAlert={createAlert}
            removeAlert={removeAlert}
            locked={alertType.lockedFields}
          />
        );
      case AlertTypes.Efficiency:
        return (
          <EfficiencyAlertsTable
            alerts={
              alerts.filter(
                (a) => a.type === alertType.value,
              ) as EfficiencyAlert[]
            }
            createAlert={createAlert}
            removeAlert={removeAlert}
            locked={alertType.lockedFields}
          />
        );
      case AlertTypes.Recurring:
        return (
          <RecurringAlertsTable
            alerts={
              alerts.filter(
                (a) => a.type === alertType.value,
              ) as RecurringUpdate[]
            }
            createAlert={createAlert}
            removeAlert={removeAlert}
            locked={alertType.lockedFields}
          />
        );
      case AlertTypes.SpendChange:
        return (
          <SpendChangeAlertsTable
            alerts={
              alerts.filter(
                (a) => a.type === alertType.value,
              ) as SpendChangeAlert[]
            }
            createAlert={createAlert}
            removeAlert={removeAlert}
            locked={alertType.lockedFields}
          />
        );
      default:
        return <></>;
    }
  }
}

export default React.memo(AlertsTable);

interface ComponentProps {
  alerts: Alert[];
  alertTypeOpts: AlertTypeOpt[];
  createAlert: (arg0: Alert) => void;
  removeAlert: (arg0: Alert) => void;
}

interface AlertTypeOpt {
  label: string;
  value: AlertTypes;
  explanation?: React.ReactElement[] | React.ReactElement | string;
  lockedFields?: {
    window?: string;
    aggregate?: string;
    threshold?: number;
    baseline?: string;
    effThresh?: number;
    spendThresh?: number;
  };
}
