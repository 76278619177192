import { useClusters } from '../contexts/ClusterConfig';

enum ProductTier {
  Free = 'free',
  Business = 'business',
  Enterprise = 'enterprise',
}

type isProductTier = {
  [key in keyof typeof ProductTier]: boolean;
};

const useProductTier = (): isProductTier => {
  const { licenseConfig: { productKey: { tier: currentTier } = {} } = {} } =
    useClusters();

  /* Map our `ProductTier` values to a representative boolean. To appese TS we have to generate an
   * array of tiers as arrays without a type assertion. */
  const tiers = Object.entries(ProductTier).map(([key, value]) => [
    key,
    value === currentTier?.toLowerCase(),
  ]);

  /* Convert array of arrays into a `isProductTier` object. We have to do this in two steps or TS
   * is angry. */
  return Object.fromEntries(tiers);
};

export { useProductTier as default, ProductTier };
