// types
import { toCurrency } from '../../services/format';
import { RequestSizingRecommendation, RequestSizingResponse } from './types';

export function sumNonNegativeSavings(recs: RequestSizingResponse): number {
  const precision = 2;
  const roundingFactor = Math.pow(10, precision);
  return recs
    .filter((rec) => !!rec.monthlySavings) // this was blowing up as some recommendations didn't have monthly savings
    .map((rec) => Object.values(rec.monthlySavings).reduce((a, b) => a + b, 0)) // get total savings for each rec
    .filter((totalSavings) => totalSavings > 0) // drop recs that increase spend
    .map((savings) => Math.round(savings * roundingFactor) / roundingFactor) // round savings to their displayed values
    .reduce((a, b) => a + b, 0); // sum these savings values for total positive savings
}

export function formatFilters(
  filters?: { property: string; value: string; equality: string }[],
) {
  const filterMap = {};
  filters?.forEach(
    ({
      property,
      value,
      equality,
    }: {
      property: string;
      value: string;
      equality: string;
    }) => {
      const mapKey = `${property}${equality}`;
      if (filterMap[mapKey]) {
        filterMap[mapKey] = [...filterMap[mapKey], `"${value}"`];
      } else {
        filterMap[mapKey] = [`"${value}"`];
      }
    },
  );

  const newStringArray = Object.keys(filterMap).map(
    (key: string) => `${key}${filterMap[key].join(',')}`,
  );

  return newStringArray.join('+');
}

// The CPU and Ram parse are from https://github.com/etiennedi/kubernetes-resource-parser
export function cpuParser(input: string) {
  const milliMatch = input.match(/^([0-9]+)m$/);
  if (milliMatch) {
    return milliMatch[1] * 1;
  }

  return parseFloat(input);
}

const memoryMultipliers = {
  k: 1000,
  M: 1000 ** 2,
  G: 1000 ** 3,
  T: 1000 ** 4,
  P: 1000 ** 5,
  E: 1000 ** 6,
  Ki: 1024,
  Mi: 1024 ** 2,
  Gi: 1024 ** 3,
  Ti: 1024 ** 4,
  Pi: 1024 ** 5,
  Ei: 1024 ** 6,
};

export function memoryParser(input: string) {
  const unitMatch = input.match(/^([0-9]+)([A-Za-z]{1,2})$/);
  if (unitMatch) {
    return parseInt(unitMatch[1], 10) * memoryMultipliers[unitMatch[2]];
  }

  return parseInt(input, 10);
}

export const recCurrency = (
  recommendation: RequestSizingRecommendation,
  currency: string,
) => {
  // This isn't a great solution, but I needed to move quickly for a
  // patch without adding proper error handling logic for the page.
  return (
    recommendation.monthlySavings != null &&
    toCurrency(
      recommendation.monthlySavings['cpu'] +
        recommendation.monthlySavings['memory'],
      currency,
    )
  );
};

export const mebibyteFactor = memoryMultipliers.Mi;
