import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@kubecost-frontend/holster';

interface ApplyExceptionsProps {
    header: string;
    exception: string;
}

export const ApplyExceptions: FC<ApplyExceptionsProps> = ({
  header,
  exception,
}) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5">{header}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="h6">{exception}</Typography>
      </Grid>
    </Grid>
  );
};

ApplyExceptions.displayName = 'ApplyExceptions';