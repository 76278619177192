import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import { Snackbar, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Alert } from '@material-ui/lab';
import { SnackbarTypes } from './types';

interface AzureConfigFormProps {
  handleUpdateAzureConfig: (values: any) => void;
  snackbar: SnackbarTypes;
}

export const AzureConfigForm: FC<AzureConfigFormProps> = ({
  handleUpdateAzureConfig,
  snackbar,
}) => {
  const validationSchema = yup.object({
    azureSubscriptionID: yup.string().required('Subscription ID is required'),
    azureStorageAccount: yup.string().required('Storage Account is required'),
    azureStorageAccessKey: yup
      .string()
      .required('Storage Account Access Key is required'),
    azureStorageContainer: yup
      .string()
      .required('Storage Container is required'),
    azureContainerPath: yup.string(),
    azureCloud: yup.string(),
  });

  const initialValues = {
    azureSubscriptionID: '',
    azureStorageAccount: '',
    azureStorageAccessKey: '',
    azureStorageContainer: '',
    azureContainerPath: '',
    azureCloud: '',
  };

  const { touched, errors, values, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values) => {
        handleUpdateAzureConfig(values);
      },
    });

  return (
    <>
      <form onSubmit={handleSubmit}>
        <TextField
          id="azureSubscriptionID"
          name="azureSubscriptionID"
          label="Azure Subscription ID"
          variant="outlined"
          data-testid="azure-subscription-id-field"
          value={values.azureSubscriptionID}
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            touched.azureSubscriptionID && Boolean(errors.azureSubscriptionID)
          }
          helperText={touched.azureSubscriptionID && errors.azureSubscriptionID}
          style={{ paddingBottom: '1rem' }}
        />

        <TextField
          id="azureSubscriptionID"
          name="azureStorageAccount"
          label="Azure Storage Account"
          variant="outlined"
          data-testid="azure-storage-account-field"
          value={values.azureStorageAccount}
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            touched.azureStorageAccount && Boolean(errors.azureStorageAccount)
          }
          helperText={touched.azureStorageAccount && errors.azureStorageAccount}
          style={{ paddingBottom: '1rem' }}
        />
        <TextField
          id="azureStorageAccessKey"
          name="azureStorageAccessKey"
          label="Azure Storage Access Key"
          variant="outlined"
          data-testid="azure-storage-access-key-field"
          value={values.azureStorageAccessKey}
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            touched.azureStorageAccessKey &&
            Boolean(errors.azureStorageAccessKey)
          }
          helperText={
            touched.azureStorageAccessKey && errors.azureStorageAccessKey
          }
          style={{ paddingBottom: '1rem' }}
        />
        <TextField
          id="azureStorageContainer"
          name="azureStorageContainer"
          label="Azure Storage Container"
          variant="outlined"
          data-testid="azure-storage-container-field"
          value={values.azureStorageContainer}
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            touched.azureStorageContainer &&
            Boolean(errors.azureStorageContainer)
          }
          helperText={
            touched.azureStorageContainer && errors.azureStorageContainer
          }
          style={{ paddingBottom: '1rem' }}
        />
        <TextField
          id="azureContainerPath"
          name="azureContainerPath"
          label="Azure Container Path"
          variant="outlined"
          data-testid="azure-container-path-field"
          value={values.azureContainerPath}
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            touched.azureContainerPath && Boolean(errors.azureContainerPath)
          }
          helperText={touched.azureContainerPath && errors.azureContainerPath}
          style={{ paddingBottom: '1rem' }}
        />
        <TextField
          id="azureCloud"
          name="azureCloud"
          label="Azure Cloud"
          variant="outlined"
          data-testid="azure-cloud-field"
          value={values.azureCloud}
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.azureCloud && Boolean(errors.azureCloud)}
          helperText={touched.azureCloud && errors.azureCloud}
          style={{ paddingBottom: '1rem' }}
        />
        <Button
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          data-testid="submit-azure-form-button"
        >
          Submit
        </Button>

        <Snackbar
          open={!!snackbar.message}
          autoHideDuration={4000}
          data-testid={`submit-azure-form-${snackbar.severity}-notification`}
        >
          <Alert severity={snackbar.severity} variant="filled">
            {snackbar.message}
          </Alert>
        </Snackbar>
      </form>
    </>
  );
};
