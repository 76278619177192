import { Grid, Paper } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import React from "react";
import { useNavigate } from "react-router-dom";

export const AlphaAdvancedReportingBanner = () => {
  const navigate = useNavigate();
  return (
    <Grid
      item
      component={Paper}
      xs={12}
      style={{
        backgroundColor: "rgba(180, 222, 250, 1.9)",
        cursor: "pointer",
        marginTop: "1em",
        paddingLeft: "1em",
      }}
      onClick={() => navigate("/advanced-reporting")}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ margin: "1em", color: "#0f3e0f" }}>
          <strong>Alpha |</strong> Visit the new Advanced Reporting page
        </div>
        <ArrowForwardIcon style={{ fill: "#0f3e0f" }} />
      </div>
    </Grid>
  );
};
