import React from 'react';
import { FacebookCircularProgress } from '../../components/CircularProgress';

interface CircleProgressProps {
  width: string;
  label: string;
  value: number;
}

export const CircleProgress = ({
  width,
  label,
  value,
}: CircleProgressProps) => (
  <div>
    <FacebookCircularProgress
      variant="determinate"
      size={width}
      thickness={10}
      value={value > 100 ? 100 : value}
    />
    <div>{label}</div>
    <div>{value}%</div>
  </div>
);
