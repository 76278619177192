import { Grid, Card, CardContent, Typography, Button } from '@material-ui/core';
import React from 'react';
import { colorMap } from '../../services/colors';
import { BarChartVisualAddOn } from './AreaChartVisualAddOn';
import {
  getDataFromDataSetGivenColumn,
  ReportConfig,
  VisualAddOn,
  VisualAddOnType,
} from './reportingHelpers';

const chartDataPoints = [
  {
    label: 'CPU',
    dataKey: 'allocation.cpuCost',
  },
  {
    label: 'GPU',
    dataKey: 'allocation.gpuCost',
  },
  {
    label: 'LB',
    dataKey: 'allocation.loadBalancerCost',
  },
  {
    label: 'Network',
    dataKey: 'allocation.networkCost',
  },
  {
    label: 'RAM',
    dataKey: 'allocation.ramCost',
  },
  {
    label: 'External',
    dataKey: 'assets.totalCost',
  },
];

const mainTextColor = '#4CAF50'
const subTextColor = '#BFBFBF'

// there should be a separate AddOn for display and one for managerSelection

export const AddOn = ({
  cardProperties,
  value,
  selected,
  dataSet,
}: {
  cardProperties: VisualAddOn;
  value: string | number;
  selected: boolean;
  dataSet?: any;
}) => {
  const data = dataSet
    ? chartDataPoints.map((item: any) => ({
        name: item.label,
        cost: getDataFromDataSetGivenColumn(dataSet, { dataKey: item.dataKey }),
      }))
    : [];

  return cardProperties.type === VisualAddOnType.Card ? (
    <Grid key={cardProperties.name} item xs={3}>
      <Card
        style={{
          cursor: 'pointer',
          borderStyle: selected ? 'solid' : '',
          borderColor: selected ? colorMap.blue : '',
          textAlign: 'center'
        }}
      >
        <CardContent>
          <Typography style={{ color: mainTextColor }} variant="h4" component="h2">
            <strong>{value}</strong>
          </Typography>
          <Typography style={{ color: subTextColor }}  variant="h5" component="h2">
            {cardProperties.config.label}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  ) : (
    <Grid key={cardProperties.name} item xs={6}>
      <Card
        style={{
          cursor: 'pointer',
          borderStyle: selected ? 'solid' : '',
          borderColor: selected ? colorMap.blue : '',
        }}
      >
        <CardContent style={{ height: 400, color: subTextColor }}>
          <BarChartVisualAddOn dataSet={data || []} />
        </CardContent>
      </Card>
    </Grid>
  );
};

export const AddOnManager = ({
  reportConfig,
}: {
  reportConfig: ReportConfig;
}) => {
  return (
    <div style={{ marginTop: '2em' }}>
      <Button disabled variant="contained">
        New Element
      </Button>
      <Grid spacing={4} container style={{ padding: '2em' }}>
        {reportConfig.visualAddOns!.map((item: VisualAddOn) => (
          <AddOn
            key={item.name}
            selected
            cardProperties={item}
            value="$XXX.XK"
          />
        ))}
      </Grid>
    </div>
  );
};
