import { withStyles, TableCell } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

export const StyledTableCell = withStyles(() =>
  createStyles({
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);
