// react
import React, { FC, useState } from 'react';

// holster
import { Button, Select } from '@kubecost-frontend/holster';

// local
import { Asset, AssetReport } from '../../types/asset';
import { EllipsisIcon } from '../../assets/images/ellipsis-icon';
import { SettingsIcon } from '../../assets/images/settings-icon';
import { SaveIcon } from '../../assets/images/save-icon';
import { SelectWindowMemoizedNew } from '../../components/SelectWindowNew';
import { CustomizeReportMenu } from './CustomizeReportMenu';
import AggregateByControl from '../../components/AggregateByControlNew';
import { aggregationOptions } from './AssetsPage';
import { DateSelectorNew } from '../../components/DateSelectorNew';
import { AssetFilter } from '../../types/asset';

interface CustomizeReportHeaderProps {
  aggregateBy: string[];
  setAggregateBy: any;
  title: string;
  cumulativeDate: Asset[];
  setOpenReportDialog: (open: boolean) => void;
  setEditReportDialog: (open: boolean) => void;
  setOpenSaveReportDialog: (open: boolean) => void;
  window: string;
  setWindow: (window: string) => void;
  report?: AssetReport;
  save: (title: string) => void;
  unsave: (report: AssetReport) => void;
  filters: AssetFilter[];
  accumulate: boolean;
  sharedMonthlyCost: number;
}

const windowOptions = [
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Week-to-date', value: 'week' },
  { label: 'Month-to-date', value: 'month' },
  { label: 'Last week', value: 'lastweek' },
  { label: 'Last month', value: 'lastmonth' },
  { label: 'Last 24h', value: '24h' },
  { label: 'Last 48h', value: '48h' },
  { label: 'Last 7 days', value: '7d' },
  { label: 'Last 15 days', value: '15d' },
  { label: 'Last 30 days', value: '30d' },
  { label: 'Last 60 days', value: '60d' },
  { label: 'Last 90 days', value: '90d' },
  { label: 'Custom', value: '3d' },
];

export const CustomizeReportHeader: FC<CustomizeReportHeaderProps> = ({
  aggregateBy,
  setAggregateBy,
  setWindow,
  window,
  title,
  cumulativeDate,
  setEditReportDialog,
  setOpenReportDialog,
  setOpenSaveReportDialog,
  report,
  filters,
  accumulate,
  sharedMonthlyCost,
}) => {
  const [openMenu, setOpenMenu] = useState(false);

  const handleCustomizeClick = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <div className="flex justify-between mt-6">
      <DateSelectorNew
        window={window}
        setWindow={setWindow}
        windowOptions={windowOptions}
      />
      <div className="flex items-stretch">
        <AggregateByControl
          id="aggregate-by-control"
          aggregateBy={aggregateBy}
          aggregateByOptions={aggregationOptions}
          setAggregateBy={setAggregateBy}
          unaggregatedEnabled={true}
        />
        <Button
          variant="primary"
          className="flex items-center mr-3"
          onClick={() => setOpenSaveReportDialog(true)}
        >
          <SaveIcon className="mr-2" />
          {report ? 'Unsave Report' : 'Save Report'}
        </Button>
        <Button
          variant="default"
          className="flex items-center mr-3"
          onClick={() => setEditReportDialog(true)}
        >
          <SettingsIcon className="mr-2" />
          Edit Report
        </Button>
        <div className="relative">
          <Button
            variant="default"
            style={{ height: '100%' }}
            onClick={handleCustomizeClick}
          >
            <EllipsisIcon />
          </Button>
          {openMenu ? (
            <CustomizeReportMenu
              title={title}
              cumulativeData={cumulativeDate}
              setOpenReportDialog={setOpenReportDialog}
              onClose={() => setOpenMenu(false)}
              aggregateBy={aggregateBy}
              window={window}
              filters={filters}
              accumulate={accumulate}
              sharedMonthlyCost={sharedMonthlyCost}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
