import React, { FC, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Alert,
  Button,
  Input,
  Menu,
  Typography,
} from '@kubecost-frontend/holster';
import { FetchStates } from '../../constants';
import { Header } from '../../components/Header2New';
import Loading from '../../components/Loading';
import {
  deleteAdvancedReport,
  deleteAllocationReport,
  deleteAssetReport,
  listAdvancedReports,
  listAllocationReports,
  listAssetReports,
} from '../../services/reports';
import { AllocationReport } from '../../types/allocation';
import { AssetReport } from '../../types/asset';
import { ReportsTable } from './ReportsTable';
import { useClusters } from '../../contexts/ClusterConfig';
import { AdvancedReport } from '../../types/advancedReport';

export const ReportsNew: FC = () => {
  const navigate = useNavigate();
  const { modelConfig } = useClusters();

  // component state variables
  const [allocationReportsFetchState, setAllocationReportsFetchState] =
    useState(FetchStates.INIT);
  const [assetReportsFetchState, setAssetReportsFetchState] = useState(
    FetchStates.INIT,
  );
  const [advancedReportsFetchState, setAdvancedReportsFetchState] = useState(
    FetchStates.INIT,
  );
  const [allocationReports, setAllocationReports] = useState<
    AllocationReport[]
  >([]);
  const [assetReports, setAssetReports] = useState<AssetReport[]>([]);
  const [advancedReports, setAdvancedReports] = useState<AdvancedReport[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  // used to anchor the Create Report popup menu
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | Element>(null);

  // user's readonly status
  const readOnly =
    modelConfig.readOnly !== 'false' && modelConfig.editorAccess !== 'true';

  // whenever fetch state is "init", fetch allocation reports
  useEffect(() => {
    if (allocationReportsFetchState !== FetchStates.INIT) {
      return;
    }
    fetchAllocationReports();
  }, [allocationReportsFetchState]);

  // whenever fetch state is "init", fetch asset reports
  useEffect(() => {
    if (assetReportsFetchState !== FetchStates.INIT) {
      return;
    }
    fetchAssetReports();
  }, [assetReportsFetchState]);

  // whenever fetch state is "init", fetch advanced reports
  useEffect(() => {
    if (advancedReportsFetchState !== FetchStates.INIT) {
      return;
    }
    fetchAdvancedReports();
  }, [advancedReportsFetchState]);

  // content is determined by the current load-state of the page

  async function fetchAllocationReports() {
    setAllocationReportsFetchState(FetchStates.LOADING);

    try {
      const reports = await listAllocationReports();
      setAllocationReports(sortReports(reports));
      setAllocationReportsFetchState(FetchStates.DONE);
    } catch (err) {
      setAllocationReportsFetchState(FetchStates.ERROR);
    }
  }

  async function fetchAssetReports() {
    setAssetReportsFetchState(FetchStates.LOADING);

    try {
      const reports = await listAssetReports();
      setAssetReports(sortReports(reports));
      setAssetReportsFetchState(FetchStates.DONE);
    } catch (err) {
      setAssetReportsFetchState(FetchStates.ERROR);
    }
  }
  async function fetchAdvancedReports() {
    setAdvancedReportsFetchState(FetchStates.LOADING);

    try {
      const reports = await listAdvancedReports();
      setAdvancedReports(sortReports(reports));
      setAdvancedReportsFetchState(FetchStates.DONE);
    } catch (err) {
      setAdvancedReportsFetchState(FetchStates.ERROR);
    }
  }

  function fetchAll() {
    fetchAllocationReports();
    fetchAssetReports();
    fetchAdvancedReports()
  }

  function sortReports(reports: AllocationReport[]): AllocationReport[];
  function sortReports(reports: AssetReport[]): AssetReport[];
  function sortReports(reports: AdvancedReport[]): AdvancedReport[];
  function sortReports(
    reports: Array<AllocationReport | AssetReport | AdvancedReport>,
  ): Array<AllocationReport | AssetReport | AdvancedReport> {
    return reports.sort((a, b) => (a.title > b.title ? 1 : -1));
  }

  function isInitializing(): boolean {
    return (
      allocationReportsFetchState === FetchStates.INIT ||
      advancedReportsFetchState === FetchStates.INIT ||
      assetReportsFetchState === FetchStates.INIT
    );
  }

  function isLoading(): boolean {
    return (
      allocationReportsFetchState === FetchStates.LOADING ||
      advancedReportsFetchState === FetchStates.LOADING ||
      assetReportsFetchState === FetchStates.LOADING
    );
  }

  function isError(): boolean {
    return (
      !isLoading() &&
      !isInitializing() &&
      (allocationReportsFetchState === FetchStates.ERROR ||
        assetReportsFetchState === FetchStates.ERROR || 
        advancedReportsFetchState === FetchStates.ERROR)
    );
  }

  const handleDeleteAllocationReport = async (report: AllocationReport) => {
    const resp = await deleteAllocationReport(report);
    setAllocationReports(
      sortReports(
        resp.map((r) => ({
          ...r,
          aggregateBy: r.aggregateBy.split(','),
        })),
      ),
    );
  };

  const handleDeleteAssetReport = async (report: AssetReport) => {
    const resp = await deleteAssetReport(report);
    setAssetReports(sortReports(resp));
  };

  const handleDeleteAdvancedReport = async (report: AdvancedReport) => {
    const resp = await deleteAdvancedReport(report);
    setAssetReports(sortReports(resp));
  };

  const ready =
    allocationReportsFetchState === FetchStates.DONE &&
    advancedReportsFetchState === FetchStates.DONE &&
    assetReportsFetchState === FetchStates.DONE;

  return (
    <>
      <Header
        title="Reports"
        refreshCallback={() => fetchAll()}
        helpTooltip="Product Documentation"
        helpHref="https://docs.kubecost.com/saved-reports"
      />

      <div>
        <div>
          <Typography variant="p" style={{ marginRight: '35%' }}>
            Kubecost saved reports are predefined views stored for easy access.
            These reports can be configured in the Cost Allocation and Asset
            tabs or via YAML at install time.{' '}
            <a
              href="http://docs.kubecost.com/saved-reports.html"
              target="_blank"
              style={{ color: '#28B359', textDecoration: 'underline' }}
            >
              Learn more.
            </a>
          </Typography>

          <div style={{ display: 'flex', marginTop: '1em' }}>
            <RouterLink
              to="../advanced-reporting"
              style={{ color: '#28B359', textDecoration: 'underline' }}
            >
              Visit the new Advanced Reporting page (Beta)
            </RouterLink>
            <div style={{ flexGrow: 1 }}></div>
            <div style={{ position: 'relative' }}>
              <Button
                variant="primary"
                onClick={(e) => setMenuAnchorEl(e.currentTarget)}
              >
                Create a report
              </Button>
              {Boolean(menuAnchorEl) && (
                <Menu
                  items={[
                    {
                      text: 'Allocation Report',
                    },
                    {
                      text: 'Asset Report',
                    },
                    {
                      text: 'Advanced Report',
                    },
                  ]}
                  onClose={() => setMenuAnchorEl(null)}
                  selectItem={({ text }) => {
                    const searchParams = new URLSearchParams();
                    searchParams.set('new-report', 'true');
                    if (text === 'Allocation Report') {
                      navigate(`/allocations?${searchParams.toString()}`);
                    } 
                    else if (text === 'Advanced Report') {
                      navigate(`/advanced-reporting?${searchParams.toString()}`);
                    } 
                    else {
                      navigate(`/assets?${searchParams.toString()}`);
                    }
                  }}
                />
              )}
            </div>
            <Input
              placeholder="Filter reports"
              style={{ marginLeft: 24 }}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
          </div>

          <Alert
            content="Any modifications to reports (creating / deleting) will be overwritten by the reporting ConfigMap on pod restart."
            link="https://guide.kubecost.com/hc/en-us/articles/4407595977879-Saved-Reports#combining-ui-report-management-with-values-yaml"
            title="It's important to know"
            variant="info"
            style={{ marginTop: 28 }}
          ></Alert>
        </div>

        {(isInitializing() || isLoading()) && <Loading />}

        {isError() ? (
          <Typography variant="p">
            Unable to retrieve a list of reports. Refresh to try again.
          </Typography>
        ) : (
          <></>
        )}

        {ready ? (
          <div style={{ marginTop: 24 }}>
            <ReportsTable
              allocationReports={allocationReports}
              assetReports={assetReports}
              advancedReports={advancedReports}
              searchTerm={searchTerm}
              readOnly={readOnly}
              handleDeleteAllocationReport={handleDeleteAllocationReport}
              handleDeleteAssetReport={handleDeleteAssetReport}
              handleDeleteAdvancedReport={handleDeleteAdvancedReport}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

ReportsNew.displayName = 'Reports';
