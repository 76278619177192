import React, { FC } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

interface IdleIconProps {
  idle: string;
}

export const IdleListItem: FC<IdleIconProps> = ({ idle }) => {
  let sharing = false;
  let shareBy = '';

  if (idle === 'share' || idle === 'shareByCluster') {
    sharing = true;
    shareBy = 'cluster';
  } else if (idle === 'shareByNode') {
    sharing = true;
    shareBy = 'node';
  }

  if (sharing) {
    return (
      <ListItem style={{ paddingLeft: '0rem', paddingRight: '0rem' }}>
        <ListItemText primary={`Sharing idle by ${shareBy}`} />
      </ListItem>
    );
  }

  return (
    <ListItem style={{ paddingLeft: '0rem', paddingRight: '0rem' }}>
      <ListItemText primary="Not sharing idle" />
    </ListItem>
  );
};

IdleListItem.displayName = 'IdleListItem';
export default IdleListItem;
