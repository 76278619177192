export const windowOptions = [
  { name: '1 day', value: '1d' },
  { name: '2 days', value: '2d' },
  { name: '3 days', value: '3d' },
  { name: '4 days', value: '4d' },
  { name: '5 days', value: '5d' },
  { name: '6 days', value: '6d' },
  { name: '7 days', value: '7d' },
];

export const defaultAggOptions = [
  {
    name: 'Cluster',
    value: 'cluster',
  },
  {
    name: 'Controller',
    value: 'controller',
  },
  {
    name: 'Container',
    value: 'container',
  },
  {
    name: 'Deployment',
    value: 'deployment',
  },
  {
    name: 'Label',
    value: 'label',
  },
  {
    name: 'Namespace',
    value: 'namespace',
  },
  {
    name: 'Pod',
    value: 'pod',
  },
  {
    name: 'Service',
    value: 'service',
  },
];

export const allFilterPropertyOptions = [
  'Account',
  'Category',
  'Cluster',
  'Label/Tag',
  'Name',
  'Project',
  'Provider',
  'ProviderID',
  'Service',
  'Type',
];

export const filterPropertyOptions = [
  'cluster',
  'node',
  'namespace',
  'label',
  'service',
  'controller',
  'controllerKind',
  'pod',
];

export const filterKeyMap: Record<string, string> = {
  cluster: 'filterClusters',
  node: 'filterNodes',
  namespace: 'filterNamespaces',
  label: 'filterLabels',
  service: 'filterServices',
  controllerkind: 'filterControllerKinds',
  controller: 'filterControllers',
  pod: 'filterPods',
  department: 'filterDepartments',
  environment: 'filterEnvironments',
  owner: 'filterOwners',
  product: 'filterProducts',
  team: 'filterTeams',
};
