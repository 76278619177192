// React
import React, { FC, FocusEvent, useEffect, useState } from 'react';

// Hoslter Components
import {
  Button,
  Chip,
  FormControlLabel,
  Input,
  Menu,
  Modal,
  Select,
  Typography,
} from '@kubecost-frontend/holster';

// Icons
import { FilterIcon, PlusIcon } from '../../assets/images';

// Types and Data objects(tokens)
import { WindowOptions } from '../AssetsNew/AssetControls/types';
import { windowOptions, filterPropertyOptions, filterKeyMap } from './tokens';
import {
  AggregationType,
  Filter,
  RecommendationAlgorithm,
  RequestSizingParams,
  SelectOptions,
} from './types';
import { MenuItemProps } from '@kubecost-frontend/holster/src/components/Menu';

// Lodash helper funcs
import sortBy from 'lodash/sortBy';

// local KC Components
import { SelectWindowMemoized } from '../../components/SelectWindow';
import { formatFilters } from './utils';

interface ChipOption {
  property: string;
  value: string;
  equality: string;
}
type FilterEquality = ':' | '!:';

interface CustomizeRequestSizingMenuProps {
  activeFilters: Filter[];
  handleRecsUpdate: (r: RequestSizingParams) => void;
  handleAddFilter: (f: Filter) => void;
  open: boolean;
  userInputs: RequestSizingParams;
  onClose: () => void;
}

//TODO: There is a lot of code commented out for adding filters, the code works but
// filtering is not supported, it will be soon though 🤞
export const CustomizeRequestSizingMenu: FC<CustomizeRequestSizingMenuProps> =
  ({
    handleRecsUpdate,
    handleAddFilter,
    open,
    userInputs,
    onClose,
    activeFilters,
  }) => {
    const [openMenu, setOpenMenu] = useState(false);
    const [window, setWindowValue] = useState<WindowOptions['value']>(
      userInputs.window,
    );
    const [targetUtilizationCPU, setTargetUtilizationCPU] = useState(
      userInputs.targetUtilizationCPU * 100,
    );
    const [targetUtilizationRAM, setTargetUtilizationRAM] = useState(
      userInputs.targetUtilizationRAM * 100,
    );
    const [qCPU, setCPUQuantile] = useState(userInputs.qCPU! * 100);
    const [qRAM, setRAMQuantile] = useState(userInputs.qRAM! * 100);
    const [aggregation, setAggregation] =
      useState<AggregationType['value']>('cluster');
    const [labelValue, setLabelValue] = useState('');
    const [chips, setChips] = useState<ChipOption[]>(activeFilters || []);
    const [algorithmCPU, setAlgorithmCPU] = useState<
      RecommendationAlgorithm | string
    >(userInputs.algorithmCPU);
    const [algorithmRAM, setAlgorithmRAM] = useState<
      RecommendationAlgorithm | string
    >(userInputs.algorithmRAM);
    const [filters, setFilters] = useState<Filter[]>(activeFilters || []);
    const [filterEquality, setFilterEquality] =
      useState<FilterEquality | string>(':');

    const cpuAlgorithmOptions: SelectOptions[] = [
      {
        label: 'Max',
        value: RecommendationAlgorithm.Max,
      },
      {
        label: 'Percentile',
        value: RecommendationAlgorithm.Quantile,
      },
    ];

    const ramAlgorithmOptions: SelectOptions[] = [
      {
        label: 'Max',
        value: RecommendationAlgorithm.Max,
      },
      {
        label: 'Percentile',
        value: RecommendationAlgorithm.Quantile,
      },
    ];

    const filterEqualOptions: SelectOptions[] = [
      {
        label: 'Equals',
        value: ':',
      },
      {
        label: 'Not Equals',
        value: '!:',
      },
    ];

    const handleOpenMenu = () => {
      setOpenMenu(!openMenu);
    };

    const handleSetWindow = (newWindow: WindowOptions['value']) => {
      setWindowValue(newWindow);
    };

    const handleSetRAMUtilization = (
      event: FocusEvent<HTMLInputElement, Element>,
    ) => {
      const ram = event.target.value;
      setTargetUtilizationRAM(Number(ram));
    };

    const handleSetCPUUtilization = (
      event: FocusEvent<HTMLInputElement, Element>,
    ) => {
      const cpu = event.target.value;
      setTargetUtilizationCPU(Number(cpu));
    };

    const handleSetRAMPercentile = (
      event: FocusEvent<HTMLInputElement, Element>,
    ) => {
      const ram = event.target.value;
      setRAMQuantile(Number(ram));
    };

    const handleSetCPUPercentile = (
      event: FocusEvent<HTMLInputElement, Element>,
    ) => {
      const cpu = event.target.value;
      setCPUQuantile(Number(cpu));
    };

    const handleSetFilterProperty = (property: MenuItemProps) => {
      setAggregation(property.text);
      setOpenMenu(false);
    };

    const handleSetFilterLabelValue = (
      event: FocusEvent<HTMLInputElement, Element>,
    ) => {
      const label = event.target.value;
      setLabelValue(label);
    };

    const handleRemoveChip = (index: number) => {
      setChips((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);
      setFilters((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);
    };

    const handleAddFilterValue = () => {
      const newChips: ChipOption = {
        property: aggregation,
        value: labelValue,
        equality: filterEquality,
      };
      const newFltr: Filter = {
        property: aggregation,
        value: labelValue,
        equality: filterEquality,
      };
      setChips((prev) => [newChips, ...prev]);
      setFilters((prev) => [newFltr, ...prev]);
    };

    const handleClose = () => {
      onClose();
    };

    const handleSaveNewRecs = () => {
      const recs = {
        window,
        algorithmCPU,
        algorithmRAM,
        targetUtilizationCPU: targetUtilizationCPU / 100,
        targetUtilizationRAM: targetUtilizationRAM / 100,
        qCPU: qCPU ? qCPU / 100 : undefined,
        qRAM: qRAM ? qRAM / 100 : undefined,
        filter: formatFilters(filters),
        // ...parseFilters(filters, filterKeyMap),
      };
      handleRecsUpdate(recs as any);
      handleAddFilter({
        property: aggregation,
        value: labelValue,
        equality: filterEquality,
      });
      onClose();
    };

    // resets our stats on Close
    useEffect(() => {
      if (Boolean(onClose)) {
        setAggregation('');
        setLabelValue('');
        setFilterEquality(':');
      }
    }, [onClose]);

    const cpuAlgoHelpText = {
      [RecommendationAlgorithm.Max]: (
        <Typography variant="h6">
          Recommendations that keep utilization below the target at all times.
        </Typography>
      ),
      [RecommendationAlgorithm.Quantile]: (
        <Typography variant="h6">
          Recommendations that keep utilization below the target {qCPU}% of the
          time. Requires the{' '}
          <a
            className="text-kc-link"
            href="https://github.com/kubecost/docs/blob/main/api-request-right-sizing-v2.md"
            target="_blank"
          >
            ContainerStats pipeline to be enabled
          </a>
          .
        </Typography>
      ),
    }[algorithmCPU];

    const ramAlgoHelpText = {
      [RecommendationAlgorithm.Max]: (
        <Typography variant="h6">
          Recommendations that keep utilization below the target at all times.
        </Typography>
      ),
      [RecommendationAlgorithm.Quantile]: (
        <Typography variant="h6">
          Recommendations that keep utilization below the target {qRAM}% of the
          time. Requires the{' '}
          <a
            href="https://github.com/kubecost/docs/blob/main/api-request-right-sizing-v2.md"
            className="text-kc-link"
            target="_blank"
          >
            ContainerStats pipeline to be enabled
          </a>
          .
        </Typography>
      ),
    }[algorithmRAM];

    return (
      <>
        <Modal
          open={open}
          onClose={handleClose}
          title="Customize Request Sizing Recommendations"
        >
          <div className="w-full p-4">
            <SelectWindowMemoized
              windowOptions={windowOptions}
              window={window}
              setWindow={handleSetWindow}
            />
          </div>
          <div className="grid gap-1 grid-cols-2 p-4">
            <div>
              <FormControlLabel>CPU recommendation algorithm</FormControlLabel>
              <Select
                options={cpuAlgorithmOptions}
                value={algorithmCPU}
                setValue={setAlgorithmCPU}
              />
              <Typography variant="h6">{cpuAlgoHelpText}</Typography>
            </div>
            <div>
              <FormControlLabel>RAM recommendation algorithm</FormControlLabel>
              <Select
                options={ramAlgorithmOptions}
                value={algorithmRAM}
                setValue={setAlgorithmRAM}
              />
              <Typography variant="h6">{ramAlgoHelpText}</Typography>
            </div>
          </div>
          <div className="grid gap-1 grid-cols-2 p-4">
            <div>
              <FormControlLabel>CPU target utilization</FormControlLabel>
              <Input
                className="w-full p-4"
                onChange={handleSetCPUUtilization}
                value={targetUtilizationCPU}
                placeholder="CPU Target Utilization"
                type="number"
                min="1"
                max="100"
              />
              <Typography variant="h6">
                Recommendations will aim to keep utilization below this
                percentage.
              </Typography>
              {algorithmCPU === 'quantile' && (
                <div className="mt-5">
                  <FormControlLabel>CPU Percentile</FormControlLabel>
                  <Input
                    className="w-full p-4"
                    onChange={handleSetCPUPercentile}
                    value={qCPU}
                    placeholder="CPU Percentile"
                    type="number"
                    min="1"
                    max="100"
                  />
                </div>
              )}
            </div>
            <div>
              <FormControlLabel>RAM target utilization</FormControlLabel>
              <Input
                className="w-full p-4"
                onChange={handleSetRAMUtilization}
                value={targetUtilizationRAM}
                placeholder="RAM Target Utilization"
                type="number"
                min="1"
                max="100"
              />
              <Typography variant="h6">
                Recommendations will aim to keep utilization below this
                percentage.
              </Typography>
              {algorithmRAM === 'quantile' && (
                <div className="mt-5">
                  <FormControlLabel>RAM Percentile</FormControlLabel>
                  <Input
                    className="w-full p-4"
                    onChange={handleSetRAMPercentile}
                    value={qRAM}
                    placeholder="RAM Percentile"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="pl-4">
            <FormControlLabel>Add Filters</FormControlLabel>
          </div>
          <div className="flex gap-4 p-4">
            <div>
              <Button variant="default" onClick={handleOpenMenu}>
                <div className="flex justify-start">
                  <div className="pr-2 pt-1">
                    <FilterIcon />
                  </div>
                  {aggregation ? aggregation : 'Filters'}
                </div>
              </Button>
              {openMenu ? (
                <Menu
                  items={sortBy(filterPropertyOptions, 'asc')}
                  onClose={() => setOpenMenu(false)}
                  selectItem={handleSetFilterProperty}
                />
              ) : null}
            </div>
            <div>
              <Select
                options={filterEqualOptions}
                value={filterEquality}
                setValue={setFilterEquality}
              />
            </div>
            <div className="flex-grow">
              <Input
                className="h-8 w-full"
                onChange={handleSetFilterLabelValue}
                value={labelValue}
                placeholder="Label"
              />
            </div>
            <div className="justify-start">
              <Button
                className="h-8"
                variant="default"
                onClick={handleAddFilterValue}
              >
                <PlusIcon />
              </Button>
            </div>
          </div>

          {chips.length > 0 &&
            chips.map((c, idx) => (
              <ul className="inline-block p-2">
                <li>
                  <Chip
                    color="primary"
                    label={`${c.property}${c.equality}${c.value}`}
                    onDelete={() => handleRemoveChip(idx)}
                  />
                </li>
              </ul>
            ))}
          <div className="grid grid-cols-[.2fr,.2fr] pt-4 pr-4 pb-0 pl-4 gap-2.5 justify-end">
            <Button variant="primary" onClick={handleSaveNewRecs}>
              Save
            </Button>
            <Button variant="default" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </Modal>
      </>
    );
  };
CustomizeRequestSizingMenu.displayName = 'CustomizeRequestSizingMenu';
