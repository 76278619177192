import React from 'react';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar } from 'recharts';
import { primary } from '../../services/colors';
import { toCurrency } from '../../services/format';

export interface AllocationOverTimeSet {
  name: string;
  cpuCost: number;
  gpuCost: number;
  loadBalancerCost: number;
  networkCost: number;
  ramCost: number;
}

interface HistoricalCostAreaChartProps {
  data: AllocationOverTimeSet[];
  resourceTypes: string[];
  chartWidth: number;
  currency: string;
}

export const HistoricalCostBarChart = ({
  data,
  resourceTypes,
  chartWidth,
  currency,
}: HistoricalCostAreaChartProps) => (
  <BarChart width={chartWidth} height={250} data={data} margin={{ left: 30 }}>
    <CartesianGrid strokeDasharray="0" vertical={false} />
    <XAxis dataKey="name" />
    <YAxis tickFormatter={(val: number) => toCurrency(val, currency, undefined, true)} />
    <Tooltip formatter={(val) => toCurrency(val, currency)} />
    {resourceTypes.map((type: string, idx: number) => (
      <Bar key={type} dataKey={type} stackId="a" fill={primary[idx]} />
    ))}
  </BarChart>
);
