// react
import React from 'react';

// holster
import {
  FormControlLabel,
  Modal,
  Select,
  Typography,
} from '@kubecost-frontend/holster';

// local
import { getProfile } from './profiles';

interface ClusterSizingFilterModalProps {
  visible: boolean;
  setVisible: (status: boolean) => void;
  selectedCluster: string;
  updateSelectedCluster: (clusterName: string) => void;
  clusters: string[];
  profile: string;
  updateProfile: (profile: string) => void;
  restrictArchitectureOptions: boolean;
  architecture: string;
  updateArchitecture: (arch: string) => void;
}

const profileOptions = [
  { label: 'Development', value: 'development' },
  { label: 'Production', value: 'production' },
  { label: 'High-Availability', value: 'high-availability' },
];

export const ClusterSizingFilterModal = ({
  visible,
  setVisible,
  profile,
  updateProfile,
  clusters,
  selectedCluster,
  updateSelectedCluster,
  restrictArchitectureOptions,
  architecture,
  updateArchitecture,
}: ClusterSizingFilterModalProps) => {
  const archOptions = [
    { label: 'x86', value: 'x86' },
    {
      label: `Any Architecture ${
        restrictArchitectureOptions && '(Unavailable)'
      }`,
      value: 'any',
    },
    {
      label: `ARM ${restrictArchitectureOptions && '(Unavailable)'}`,
      value: 'ARM',
    },
  ];
  const clusterOptions = clusters.map((cluster: string) => ({
    label: cluster,
    value: cluster,
  }));
  return (
    <div style={{ maxWidth: 10 }}>
      <Modal
        open={visible}
        onClose={() => setVisible(false)}
        title="Cluster Sizing Settings"
      >
        {clusters.length !== 0 && (
          <>
            <FormControlLabel>Cluster</FormControlLabel>
            <Select
              options={clusterOptions}
              setValue={updateSelectedCluster}
              value={selectedCluster}
            />
            <Typography variant="h6">The cluster to right size</Typography>
          </>
        )}
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '1em',
            marginTop: '1em',
            marginBottom: '1em',
          }}
        >
          <div>
            <FormControlLabel>Profile</FormControlLabel>
            <Select
              options={profileOptions}
              setValue={updateProfile}
              value={profile}
            />
            <Typography variant="h6">Performance profile</Typography>
          </div>
          <div>
            <FormControlLabel>Architecture</FormControlLabel>
            <Select
              options={archOptions}
              setValue={updateArchitecture}
              value={architecture}
            />
            <Typography variant="h6">
              Choose a specific chipset for all recommended nodes
            </Typography>
          </div>
        </div>
        <Typography variant="p-small">
          {getProfile(profile).description}
        </Typography>
      </Modal>
    </div>
  );
};
