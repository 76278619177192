import React, { FC, ReactNode } from 'react';
import { Skeleton } from '@material-ui/lab';
import { Typography } from '@kubecost-frontend/holster';
import { TrendIndicatorChip } from './TrendIndicatorChip';
import HighlightCardArrow from '../../assets/images/HighlightCardArrow';

/**
 *
 * Cards for the top-level highlight metrics
 */
export interface HighlightCardProps {
  content: ReactNode;
  footer: ReactNode;
  header: ReactNode;
  isEfficiency?: boolean;
  trendIndicator?: ReactNode;
  arrowDirection?: 'UP' | 'DOWN';
  loading?: boolean;
  href?: string;
}

export const HighlightCard: FC<HighlightCardProps> = ({
  content,
  footer,
  header,
  isEfficiency,
  trendIndicator,
  arrowDirection,
  loading = false,
  href = '',
}) => {
  const Card = () => (
    <figure className={'border-kc-gray-100 border-b border-r h-full p-2.5'}>
      {loading ? (
        <>
          <Skeleton
            style={{
              height: '28px',
              width: '50%',
              opacity: 0.5,
            }}
            animation="wave"
          />
          <Skeleton
            style={{
              marginTop: 5,
              height: '14px',
              width: '70%',
              opacity: 0.5,
            }}
            animation="wave"
          />
          <Skeleton
            style={{
              marginTop: 5,
              height: '20px',
              width: '80%',
              opacity: 0.5,
            }}
            animation="wave"
          />
        </>
      ) : (
        <>
          <figcaption className={'text-base font-bold'}>{header}</figcaption>

          <Typography
            className={'flex items-center justify-between'}
            variant="h5"
          >
            {content}

            {!!trendIndicator &&
            trendIndicator !== 'NaN' &&
            trendIndicator !== 'Infinity' ? (
              <TrendIndicatorChip
                trendIndicator={trendIndicator}
                arrowDirection={arrowDirection}
                isEfficiency={isEfficiency}
              />
            ) : null}
          </Typography>
          <Typography className="pt-4 text-kc-gray-200" variant="h6">
            {footer}
          </Typography>
          {href && (
            <Typography
              className={'flex font-bold items-center text-kc-link'}
              variant={'p-small'}
            >
              View report
              <HighlightCardArrow className={'ml-2'} />
            </Typography>
          )}
        </>
      )}
    </figure>
  );

  return href ? (
    <a href={href} style={{ textDecoration: 'none' }}>
      <Card />
    </a>
  ) : (
    <Card />
  );
};
