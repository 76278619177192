import React from 'react';
import reverse from 'lodash/reverse';
import { makeStyles } from '@material-ui/styles';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { primary, browns, accents } from '../../../services/colors';
import { toCurrency } from '../../../services/format';
import { useClusters } from '../../../contexts/ClusterConfig';

const useStyles = makeStyles({
  tooltip: {
    borderRadius: 2,
    background: 'rgba(255, 255, 255, 0.95)',
    padding: 12,
  },
  tooltipLineItem: {
    fontSize: '1rem',
    margin: 0,
    marginBottom: 4,
    padding: 0,
  },
});

function toBarLabels(assetRange) {
  const nameToFill = {};
  let p = 0;
  let b = 0;

  assetRange.forEach(({ other, top }) => {
    top.forEach((asset) => {
      const chartKey = `${asset.name} + ${asset.providerID}` as const;
      if (nameToFill[chartKey] === undefined) {
        // assets get the next available color
        nameToFill[chartKey] = primary[p];
        p = (p + 1) % primary.length;
      }
    });
    other.forEach((asset) => {
      const chartKey = `${asset.name} + ${asset.providerID}` as const;

      if (nameToFill[chartKey] === undefined) {
        // other assets are assigned brown
        nameToFill[chartKey] = browns[b];
        b = (b + 1) % browns.length;
      }
    });
  });

  let labels = Object.entries(nameToFill).map(([dataKey, fill]) => ({
    dataKey,
    fill,
  }));

  // Pin other category to bottom
  const other = labels.filter((l) => l.dataKey === 'other');
  if (other.length > 0) {
    labels = labels.filter((l) => l.dataKey !== 'other');
    labels.push(other[0]);
  }

  return reverse(labels);
}

function toBar(datum) {
  const { top, other } = datum;
  const bar = {};

  const dateFormatter = Intl.DateTimeFormat(navigator.language, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'UTC',
  });

  const timeFormatter = Intl.DateTimeFormat(navigator.language, {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'UTC',
  });

  top.forEach((asset) => {
    if (!asset.window) {
      console.warn(`Asset ${asset.name} is missing a window.`);
      return;
    }

    const windowStart = new Date(asset.window.start);
    const windowEnd = new Date(asset.window.end);
    const windowHours = (windowEnd - windowStart) / 1000 / 60 / 60;
    const chartKey = `${asset.name} + ${asset.providerID}` as const;

    bar.start = new Date(asset.start);
    bar.end = new Date(asset.end);
    if (windowHours >= 24) {
      bar.key = dateFormatter.format(bar.start);
    } else {
      bar.key = timeFormatter.format(bar.start);
    }

    bar[chartKey] = asset.totalCost;
  });

  other.forEach((asset) => {
    if (!asset.window) {
      console.warn(`Asset ${asset.name} is missing a window.`);
      return;
    }
    const windowStart = new Date(asset.window.start);
    const windowEnd = new Date(asset.window.end);
    const windowHours = (windowEnd - windowStart) / 1000 / 60 / 60;
    const chartKey = `${asset.name} + ${asset.providerID}` as const;

    bar.start = new Date(asset.start);
    bar.end = new Date(asset.end);
    if (windowHours >= 24) {
      bar.key = dateFormatter.format(bar.start);
    } else {
      bar.key = timeFormatter.format(bar.start);
    }

    bar[chartKey] = asset.totalCost;
  });

  return bar;
}

const RangeChart = ({ data, currency, height }) => {
  const { modelConfig } = useClusters();
  const classes = useStyles();

  const barData = data.map(toBar);
  const barLabels = toBarLabels(data);

  const CustomTooltip = (params) => {
    const { active, payload } = params;

    if (!payload || payload.length === 0) {
      return null;
    }

    const dateTimeFormatter = Intl.DateTimeFormat(navigator.language, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });

    const total = payload.reduce((sum, item) => sum + item.value, 0.0);

    let start = null;
    if (payload[0] && payload[0].payload) {
      start = payload[0].payload.start;
    }

    let end = null;
    if (payload[0] && payload[0].payload) {
      end = payload[0].payload.end;
    }

    if (active) {
      return (
        <div className={classes.tooltip}>
          {start && end && (
            <p
              className={classes.tooltipLineItem}
              style={{ color: '#808080', fontSize: '0.75rem' }}
            >{`${dateTimeFormatter.format(start)} to ${dateTimeFormatter.format(
              end,
            )}`}</p>
          )}
          <p
            className={classes.tooltipLineItem}
            style={{ color: '#000000' }}
          >{`Total: ${toCurrency(total, currency)}`}</p>
          {reverse(payload).map((item, i) => (
            <p
              key={i}
              className={classes.tooltipLineItem}
              style={{ color: item.fill }}
            >{`${item.name}: ${toCurrency(item.value, currency)}`}</p>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart
        data={barData}
        margin={{ top: 30, right: 30, left: 30, bottom: 12 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="key" />
        <YAxis
          tickFormatter={(val) =>
            toCurrency(val, modelConfig.currencyCode, 2, true)
          }
        />
        <Tooltip content={<CustomTooltip />} />
        {barLabels.map((barLabel, i) => (
          <Bar
            key={i}
            dataKey={barLabel.dataKey}
            stackId="a"
            fill={barLabel.fill}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default RangeChart;
