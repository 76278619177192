import { Box, FormControlLabel, Switch, Button } from '@material-ui/core';
import React, { useState, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import AlertService, {
  ClusterHealthAlert,
  AlertTypes,
} from '../../../services/alerts';
import TestAlertButton from '../TestAlertButton';
import CreateHealthAlertModal from './HealthAlertModal';

const HealthAlert = () => {
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const { data } = useQuery('alerts', AlertService.getAlerts);
  const createAlert = useMutation(AlertService.upsertAlert, {
    onSuccess: () => {
      queryClient.invalidateQueries('alerts');
    },
  });
  const deleteAlert = useMutation(AlertService.deleteAlert, {
    onSuccess: () => {
      queryClient.invalidateQueries('alerts');
    },
  });

  let clusterHealthAlert = useMemo((): ClusterHealthAlert | undefined => {
    if (!data) return undefined;
    let values = data.find((a) => a.type === 'health');
    if (!values) return undefined;

    return new ClusterHealthAlert({
      ...values,
      type: AlertTypes.Health,
    });
  }, [data]);

  const newAlertCreated = async (alert: ClusterHealthAlert) => {
    await createAlert.mutateAsync(alert);
    setShowDialog(false);
  };

  const deleteAlertClicked = () => {
    if (clusterHealthAlert === undefined) return;
    deleteAlert.mutate(clusterHealthAlert);
  };

  return (
    <div>
      <Box width={260}>
        <FormControlLabel
          checked={clusterHealthAlert !== undefined}
          data-test="cluster-health-switch"
          label="Monitor Cluster Health"
          control={
            <Switch
              inputProps={{ 'data-testid': 'alert-health-switch' }}
              color="primary"
            />
          }
          onChange={async () => {
            if (clusterHealthAlert === undefined) {
              setShowDialog(true);
            } else {
              deleteAlertClicked();
            }
          }}
        />
        {clusterHealthAlert && (
          <Button
            data-testid="ha-edit-button"
            color="primary"
            variant="contained"
            disableElevation
            style={{ marginRight: 8 }}
            onClick={() => {
              setShowDialog(true);
            }}
          >
            Edit
          </Button>
        )}
        {clusterHealthAlert && (
          <span data-testid="test-alert-button">
            <TestAlertButton alert={clusterHealthAlert} />
          </span>
        )}
        <CreateHealthAlertModal
          alert={clusterHealthAlert}
          open={showDialog}
          cancelClicked={() => setShowDialog(false)}
          alertSaved={newAlertCreated}
        />
      </Box>
    </div>
  );
};

export default HealthAlert;
