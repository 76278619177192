import { makeStyles } from '@material-ui/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
    width: '100%',
  },
  context: {
    flex: '0 1 auto',
  },
  actions: {
    flex: '0 0 auto',
  },
  ellipse: {
    whiteSpace: 'nowrap',
    wordBreak: 'keep-all',
    '&:last-child': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  nowrap: {
    flexWrap: 'nowrap',
  },
});

function Header({
  breadcrumbs,
  children,
  title,
}: HeaderProps): React.ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div
        className={classes.context}
        style={{
          textOverflow: 'ellipsis',
          margin: 8,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          wordBreak: 'keep-all',
        }}
      >
        {title && <Typography variant="h4">{title}</Typography>}
        {breadcrumbs && breadcrumbs.length > 0 && (
          <Breadcrumbs
            aria-label="breadcrumb"
            classes={{ ol: classes.nowrap, li: classes.ellipse }}
          >
            {breadcrumbs.slice(0, breadcrumbs.length - 1).map((b) => (
              <Link color="inherit" href={b.href} key={b.name}>
                {b.name}
              </Link>
            ))}
            <Typography className={classes.ellipse} color="textPrimary">
              {breadcrumbs[breadcrumbs.length - 1].name}
            </Typography>
          </Breadcrumbs>
        )}
      </div>
      <div className={classes.actions}>{children}</div>
    </div>
  );
}

Header.defaultProps = {
  children: null,
  breadcrumbs: [],
  title: '',
};

type HeaderProps = {
  breadcrumbs?: Breadcrumb[];
  title?: string;
  children?: React.ReactElement[] | React.ReactElement;
};

export type Breadcrumb = {
  href: string;
  name: string;
};

export default Header;
