import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import React from 'react';

import { AlertTypes, DiagnosticAlert } from '../../services/alerts';

interface ComponentProps {
  alert: DiagnosticAlert | null;
  close: () => void;
  save: (alert: DiagnosticAlert) => Promise<void>;
  test: (alert: DiagnosticAlert) => Promise<void>;
}

const DiagnosticAlertModal: React.FC<ComponentProps> = ({
  alert,
  close,
  save,
  test,
}: ComponentProps) => {
  const [slackDirty, setSlackDirty] = React.useState(false);
  const [slackUrl, setSlackUrl] = React.useState('');
  React.useEffect(() => {
    if (alert && alert.slackWebhookUrl) {
      setSlackUrl(
        'https://hooks.slack.com/services/XXXXXXXXXXX/XXXXXXXXXXX/XXXXXXXXXXXXXXXXXXXXXXXX',
      );
    }
  }, [alert]);
  return (
    <Dialog data-test="diagnostic-alert-dialog" onClose={onClose} open={!!alert}>
      <DialogTitle>Enable Kubecost Health Monitoring</DialogTitle>
      <DialogContent>
        <Typography style={{ marginBottom: 16 }}>
          With Kubecost Health monitoring enabled, Kubecost will send a message
          to the designated Slack webhook any time it is unable to contact its
          backing data store.
        </Typography>

        <Typography>
          Learn more about Slack webhooks{' '}
          <Link href="https://api.slack.com/messaging/webhooks" target="_blank">
            here
          </Link>
          .
        </Typography>

        <FormGroup>
          <TextField
            label="Slack webhook"
            placeholder="https://hooks.slack.com/services/XXXXXXXXXXX/XXXXXXXXXXX/XXXXXXXXXXXXXXXXXXXXXXXX"
            style={{ marginTop: 16 }}
            onChange={(e) => {
              setSlackUrl(e.target.value);
              setSlackDirty(true);
            }}
            value={slackUrl}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          disableElevation
          variant="contained"
          onClick={onSave}
        >
          Save
        </Button>
        <Button
          color="secondary"
          disableElevation
          variant="contained"
          onClick={onTest}
        >
          Test
        </Button>
      </DialogActions>
    </Dialog>
  );

  function onClose() {
    setSlackUrl('');
    setSlackDirty(false);
    close();
  }

  async function onSave() {
    const a = new DiagnosticAlert({
      type: AlertTypes.Diagnostic,
      window: '5m',
      slackWebhookUrl: alert?.slackWebhookUrl,
      id: alert?.id,
    });
    if (slackDirty) {
      a.slackWebhookUrl = slackUrl;
    }
    await save(a);
    onClose();
  }

  async function onTest() {
    const a = new DiagnosticAlert({
      type: AlertTypes.Diagnostic,
      slackWebhookUrl: alert?.slackWebhookUrl,
      window: '5m',
    });
    if (slackDirty) {
      a.slackWebhookUrl = slackUrl;
    }
    test(a);
  }
};

export default DiagnosticAlertModal;
