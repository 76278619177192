import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';

import { AllocationSummaryResponse } from '../types/allocation';
import { BackupStatus } from '../pages/Diagnostics/BackupStatus/types';
import { AssetFilter, AssetResponse } from '../types/asset';
import { APIGetCloudCosts, APICloudCostParamType } from '../types/cloudCosts';

import { useAPIClient } from './APIClient';
import KubecostService, { KubecostResponse } from './kubecost';
import Logger from './logger';
import { getCurrentContainerAddressModel, parseResponseJSON } from './util';
import { APIEnablementsResponse } from '../hooks/useEnablements';
import { string } from 'prop-types';

const allocationKeyMap: Record<string, string> = {
  cluster: 'filterClusters',
  node: 'filterNodes',
  namespace: 'filterNamespaces',
  label: 'filterLabels',
  service: 'filterServices',
  controllerkind: 'filterControllerKinds',
  controller: 'filterControllers',
  pod: 'filterPods',
  department: 'filterDepartments',
  environment: 'filterEnvironments',
  owner: 'filterOwners',
  product: 'filterProducts',
  team: 'filterTeams',
};

export const savingsKeyMap: Record<string, string> = {
  cluster: 'filterClusters',
  node: 'filterNodes',
  namespace: 'filterNamespaces',
  label: 'filterLabels',
  service: 'filterServices',
  controllerkind: 'filterControllerKinds',
  controller: 'filterControllers',
  pod: 'filterPods',
  annotation: 'filterAnnotations',
  container: 'filterContainers',
};

const cacheBuster = () => Date.now();
const paramsToQuery = (params: Record<string, string | number | boolean>) => {
  let queryStr = '';
  if (isPlainObject(params)) {
    queryStr = `?${Object.keys(params)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
      )
      .join('&')}`;
  }
  return queryStr;
};

// model provides a basic interface for making requests to the
// cost-model API endpoints
const model = {
  get: <T = unknown>(
    url: string,
    params?: Record<string, string | number | boolean>,
    options?: RequestInit,
  ): Promise<T> => {
    const parameters = {
      ...params,
      req: cacheBuster(),
    };
    return fetch(
      `${getCurrentContainerAddressModel()}${url}${paramsToQuery(parameters)}`,
      options,
    ).then((res) => parseResponseJSON<T>(res));
  },

  post: <T = unknown>(
    url: string,
    params?: Record<string, any>,
  ): Promise<T> => {
    return fetch(
      `${getCurrentContainerAddressModel()}${url}?req=${cacheBuster()}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
      },
    ).then((res) => parseResponseJSON<T>(res));
  },

  delete: <T = unknown>(
    url: string,
    params?: Record<string, string | number | boolean>,
  ): Promise<T> => {
    const parameters = {
      ...params,
      req: cacheBuster(),
    };
    return fetch(
      `${getCurrentContainerAddressModel()}${url}${paramsToQuery(parameters)}`,
      {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      },
    ).then((res) => parseResponseJSON<T>(res));
  },

  getSavings: async (window: string, options: GetSavingsOptions) => {
    // Required parameters
    let params = {
      window,
    };

    // Optional parameters
    const { filters } = options;

    params = { ...params, ...parseFilters(filters, savingsKeyMap) };

    return model.get('/savings/requestSizing', params);
  },

  getAllocation: async (window, aggregateBy, options, requestOpts) => {
    // Required parameters
    let params = {
      window,
      aggregate: aggregateBy,
      external: get(options, 'external', 'false'),
    };

    // Optional parameters
    const {
      accumulate,
      shareIdle,
      filters,
      shareNamespaces,
      shareLabels,
      shareCost,
      shareSplit,
      shareTenancyCosts,
      idle,
      idleByNode,
      reconcile,
    } = options;

    if (accumulate !== undefined && typeof accumulate === 'boolean') {
      params.accumulate = accumulate;
    }
    if (shareIdle !== undefined && typeof shareIdle === 'boolean') {
      params.shareIdle = shareIdle;
    }
    if (idle !== undefined && typeof idle === 'boolean') {
      params.idle = idle;
    }
    if (idleByNode !== undefined && typeof idleByNode === 'boolean') {
      params.idleByNode = idleByNode;
    }
    if (reconcile !== undefined && typeof reconcile === 'boolean') {
      params.reconcile = reconcile;
    }
    if (
      shareTenancyCosts !== undefined &&
      typeof shareTenancyCosts === 'boolean'
    ) {
      params.shareTenancyCosts = shareTenancyCosts;
    }

    params = { ...params, ...parseFilters(filters, allocationKeyMap) };

    if (shareNamespaces !== undefined && isArray(shareNamespaces)) {
      params.shareNamespaces = shareNamespaces.join(',');
    }
    if (shareLabels !== undefined && isArray(shareLabels)) {
      params.shareLabels = shareLabels.join(',');
    }
    if (shareCost !== undefined && typeof shareCost === 'number') {
      params.shareCost = shareCost;
    }
    if (shareSplit !== undefined && typeof shareSplit === 'string') {
      params.shareSplit = shareSplit;
    }
    if (
      shareTenancyCosts !== undefined &&
      typeof shareTenancyCosts === 'boolean'
    ) {
      params.shareTenancyCosts = shareTenancyCosts ? 'true' : 'false';
    }

    let url = `${getCurrentContainerAddressModel()}/allocation`;
    url = `${url}${paramsToQuery(params)}`;
    return KubecostService.get<AllocationSummaryResponse>(url, requestOpts);
  },

  getAllocationExternal: async (window, aggregateBy, options, requestOpts) => {
    // Required parameters
    let params = {
      window,
      aggregate: aggregateBy,
      // external: get(options, 'external', 'false'),
    };

    // Optional parameters
    const {
      accumulate,
      shareIdle,
      filters,
      shareNamespaces,
      shareLabels,
      shareCost,
      shareSplit,
      shareTenancyCosts,
      idle,
      idleByNode,
      reconcile,
      cloudBreakdown,
      cloudJoin,
    } = options;

    if (accumulate !== undefined && typeof accumulate === 'boolean') {
      params.accumulate = accumulate;
    }
    if (shareIdle !== undefined && typeof shareIdle === 'boolean') {
      params.shareIdle = shareIdle;
    }
    if (idle !== undefined && typeof idle === 'boolean') {
      params.idle = idle;
    }
    if (idleByNode !== undefined && typeof idleByNode === 'boolean') {
      params.idleByNode = idleByNode;
    }
    if (reconcile !== undefined && typeof reconcile === 'boolean') {
      params.reconcile = reconcile;
    }
    if (
      shareTenancyCosts !== undefined &&
      typeof shareTenancyCosts === 'boolean'
    ) {
      params.shareTenancyCosts = shareTenancyCosts;
    }

    params = { ...params, ...parseFilters(filters, allocationKeyMap) };

    if (shareNamespaces !== undefined && isArray(shareNamespaces)) {
      params.shareNamespaces = shareNamespaces.join(',');
    }
    if (shareLabels !== undefined && isArray(shareLabels)) {
      params.shareLabels = shareLabels.join(',');
    }
    if (shareCost !== undefined && typeof shareCost === 'number') {
      params.shareCost = shareCost;
    }
    if (shareSplit !== undefined && typeof shareSplit === 'string') {
      params.shareSplit = shareSplit;
    }
    if (
      shareTenancyCosts !== undefined &&
      typeof shareTenancyCosts === 'boolean'
    ) {
      params.shareTenancyCosts = shareTenancyCosts ? 'true' : 'false';
    }

    // safe this
    params.cloudBreakdown = cloudBreakdown;
    params.cloudJoin = cloudJoin;

    let url = `${getCurrentContainerAddressModel()}/allocation/external`;
    url = `${url}${paramsToQuery(params)}`;
    return KubecostService.get<ExternalAllocationResponse>(url, requestOpts);
  },

  getEtlConfig: async (): Promise<KubecostResponse<BackupStatus>> => {
    let url = `${getCurrentContainerAddressModel()}/etl/config`;
    return await KubecostService.get<BackupStatus>(url);
  },

  async getAssets(
    window: string,
    options: Record<string, string | number | boolean>,
    requestOptions?: RequestInit,
  ): Promise<KubecostResponse<AssetResponse>> {
    const aggregate = get(options, 'aggregate', '');
    const accumulate = get(options, 'accumulate', false);
    const filters = get(options, 'filters');
    const noLimit = get(options, 'noLimit', false);
    const sharedMonthlyCost = get(options, 'sharedMonthlyCost', 0.0);
    const url = this.getAssetsQueryUrl(
      window,
      aggregate,
      accumulate,
      filters,
      noLimit,
      sharedMonthlyCost,
    );
    return KubecostService.get<AssetResponse>(url, requestOptions);
  },

  getAssetsQueryUrl(
    win: string,
    aggregate: string | string[],
    accumulate = false,
    filters: AssetFilter[] = [],
    noLimit = false,
    sharedMonthlyCost = 0,
  ) {
    const params = {
      window: win,
      aggregate: aggregate.toString(),
      accumulate,
      noLimit,
      sharedMonthlyCost,
    };
    filters.forEach((f) => {
      const filterPropLower = f.property.toLowerCase();
      if (filterPropLower === 'account') {
        params.filterAccounts = f.value;
      } else if (filterPropLower === 'category') {
        params.filterCategories = f.value;
      } else if (filterPropLower === 'cluster') {
        params.filterClusters = f.value;
      } else if (filterPropLower === 'label/tag') {
        params.filterLabels = f.value;
      } else if (filterPropLower === 'label') {
        params.filterLabels = f.value;
      } else if (filterPropLower === 'name') {
        params.filterNames = f.value;
      } else if (filterPropLower === 'project') {
        params.filterProjects = f.value;
      } else if (filterPropLower === 'provider') {
        params.filterProviders = f.value;
      } else if (filterPropLower === 'providerid') {
        params.filterProviderIDs = f.value;
      } else if (filterPropLower === 'region') {
        params.filterRegions = f.value;
      } else if (filterPropLower === 'service') {
        params.filterServices = f.value;
      } else if (filterPropLower === 'type') {
        params.filterTypes = f.value;
      } else {
        Logger.warn(`Warning: failed to parse filter ${f.property}=${f.value}`);
      }
    });
    return `${getCurrentContainerAddressModel()}/assets${paramsToQuery(
      params,
    )}`;
  },

  getCloudUsage: async (
    window: string,
    options,
    requestOptions?: RequestInit,
  ): Promise<KubecostResponse<any>> => {
    const aggregate = get(options, 'aggregate', '');
    const accumulate = get(options, 'accumulate', false);
    const filters = get(options, 'filters');
    const compute = get(options, 'compute', false);

    // Required parameters
    const params = {
      window,
      aggregate,
      accumulate,
      compute,
    };

    if (isArray(filters)) {
      forEach(filters, (f) => {
        const filterPropLower = f.property.toLowerCase();
        if (filterPropLower === 'account') {
          params.filterAccounts = f.value;
        } else if (filterPropLower === 'category') {
          params.filterCategories = f.value;
        } else if (filterPropLower === 'cluster') {
          params.filterClusters = f.value;
        } else if (filterPropLower === 'label/tag') {
          params.filterLabels = f.value;
        } else if (filterPropLower === 'label') {
          params.filterLabels = f.value;
        } else if (filterPropLower === 'name') {
          params.filterNames = f.value;
        } else if (filterPropLower === 'project') {
          params.filterProjects = f.value;
        } else if (filterPropLower === 'provider') {
          params.filterProviders = f.value;
        } else if (filterPropLower === 'providerid') {
          params.filterProviderIDs = f.value;
        } else if (filterPropLower === 'region') {
          params.filterRegions = f.value;
        } else if (filterPropLower === 'service') {
          params.filterServices = f.value;
        } else if (filterPropLower === 'type') {
          params.filterTypes = f.value;
        } else {
          Logger.warn(
            `Warning: failed to parse filter ${f.property}=${f.value}`,
          );
        }
      });
    }

    let url = `${getCurrentContainerAddressModel()}/cloudUsage`;
    url = `${url}${paramsToQuery(params)}`;
    return await KubecostService.get<AllocationSummaryResponse>(
      url,
      requestOptions,
    );
  },

  async getConfigs(): Promise<ModelConfigs> {
    return model
      .get<{ data: ModelConfigs }>('/getConfigs')
      .then((resp) => resp.data);
  },

  configEnv: async () => model.get('/config/env'),

  getApiConfig: async (): Promise<APIConfigResponse> =>
    model.get('/getApiConfig'),

  async getAllocationSummary(
    window: string,
    aggregateBy: string,
    options: SummaryQueryOpts,
    requestOpts?: RequestInit,
  ): Promise<KubecostResponse<AllocationSummaryResponse>> {
    const query = this.getAllocationQueryParams(window, aggregateBy, options);
    const url = `${getCurrentContainerAddressModel()}/allocation/summary${query}`;
    return KubecostService.get<AllocationSummaryResponse>(url, requestOpts);
  },

  getAllocationSummaryPaged(
    windows: string[],
    aggregateBy: string,
    options: SummaryQueryOpts,
    requestOpts?: RequestInit,
  ): Promise<KubecostResponse<AllocationSummaryResponse>>[] {
    return windows.map((window) => {
      return this.getAllocationSummary(
        window,
        aggregateBy,
        options,
        requestOpts,
      );
    });
  },

  async getAllocationView(
    window: string,
    aggregate: string,
    options: SummaryQueryOpts,
    viewOpts: ViewQueryOpts,
    requestOpts?: RequestInit,
  ): Promise<AllocationViewResponse> {
    const query = this.getAllocationQueryParams(
      window,
      aggregate,
      options,
      viewOpts,
    );
    // const url = `${getCurrentContainerAddressModel()}/allocation/view${query}`;
    const data = model.get<AllocationViewResponse>(`/allocation/view${query}`);

    return data;
  },

  async getAllocationPdf(
    window: string,
    aggregate: string,
    options: SummaryQueryOpts,
  ) {
    const query = this.getAllocationQueryParams(window, aggregate, options);
    const url = `${getCurrentContainerAddressModel()}/allocation/summary${query}&format=pdf`;
    open(url);
  },

  getAllocationQueryParams(
    window: string,
    aggregate: string,
    options: SummaryQueryOpts,
    viewOpts?: ViewQueryOpts,
  ): string {
    // Required parameters
    let params: SummaryRequestQueryOpts = {
      aggregate,
      window,
    };

    // Optional parameters
    const {
      accumulate,
      external,
      filters,
      idleByNode,
      shareIdle,
      shareNamespaces,
      shareLabels,
      shareCost,
      shareSplit,
      shareTenancyCosts,
      idle,
      reconcile,
    } = options;

    if (typeof accumulate === 'boolean') {
      params.accumulate = accumulate;
    }
    if (typeof external === 'boolean') {
      params.external = external;
    }
    if (typeof shareIdle === 'boolean') {
      params.shareIdle = shareIdle;
    }
    if (typeof idle === 'boolean') {
      params.idle = idle;
    }
    if (typeof idleByNode === 'boolean') {
      params.idleByNode = idleByNode;
    }
    if (typeof reconcile === 'boolean') {
      params.reconcile = reconcile;
    }
    if (typeof shareTenancyCosts === 'boolean') {
      params.shareTenancyCosts = shareTenancyCosts;
    }

    params = { ...params, ...parseFilters(filters, allocationKeyMap) };

    if (isArray(shareNamespaces)) {
      params.shareNamespaces = shareNamespaces.join(',');
    }
    if (isArray(shareLabels)) {
      params.shareLabels = shareLabels.join(',');
    }
    if (typeof shareCost === 'number') {
      params.shareCost = shareCost;
    }
    if (typeof shareSplit === 'string') {
      params.shareSplit = shareSplit;
    }
    if (typeof shareTenancyCosts === 'boolean') {
      params.shareTenancyCosts = shareTenancyCosts;
    }

    return paramsToQuery({ ...params, ...viewOpts, req: cacheBuster() });
  },

  // convert a relative window like 7d or 4h to a series of absolute windows
  relativeToAbsoluteWindows: (window: string) => {
    const dayMatchRE = /([0-9]+)d/;
    const hourMatchRE = /([0-9]+)h/;
    const end = new Date();
    end.setSeconds(0);
    end.setMinutes(0);
    const windows = [];
    const dayWindow = dayMatchRE.exec(window);
    if (dayWindow) {
      end.setHours(-end.getTimezoneOffset() / 60);
      end.setDate(end.getDate() + 1);
      const days = parseInt(dayWindow[1]);
      for (let i = 0; i < days; ++i) {
        const e = end.toISOString().replace(/\.[0-9]*Z/, '') + 'Z';
        // get datestring for the start of this window, and decrement the date of "end" by 1 day
        const s =
          new Date(end.setDate(end.getDate() - 1))
            .toISOString()
            .replace(/\.[0-9]*Z/, '') + 'Z';
        windows.push(`${s},${e}`);
      }
      return windows;
    }

    const hourWindow = hourMatchRE.exec(window);
    if (hourWindow) {
      end.setHours(end.getHours() + 1);
      const hours = parseInt(hourWindow[1]);
      for (let i = 0; i < hours; ++i) {
        const e = end.toISOString().replace(/\.[0-9]*Z/, '');
        // get datestring for the start of this window, and decrement the date of "end" by 1 day
        const s = new Date(end.setDate(end.getDate() - 1))
          .toISOString()
          .replace(/\.[0-9]*Z/, '');
        windows.push(`${s},${e}`);
      }
      return windows;
    }
    throw new Error(`Could not interpret window: ${window}`);
  },

  grafanaAddress: async () => {
    const customUrl = get(await model.get('/getApiConfig'), 'grafanaURL', '');
    if (
      customUrl != null &&
      typeof customUrl !== 'undefined' &&
      customUrl.length > 1
    ) {
      return customUrl;
    }
    const basename = location.pathname.split('/').slice(0, -1).join('/');
    return `${basename}/grafana`;
  },

  getNegotiatedDiscount: async () => {
    let configs;
    try {
      configs = await model.getConfigs();
      let negotiatedDiscount = parseFloat(configs.negotiatedDiscount) / 100;

      if (Number.isNaN(negotiatedDiscount)) {
        negotiatedDiscount = 0.0;
      }

      return negotiatedDiscount;
    } catch (error) {
      Logger.error('error: unable to determine negotiated discount', configs);
      return 0.0;
    }
  },

  getDiscount: async () => {
    try {
      const configs = await model.getConfigs();
      let negotiatedDiscount = parseFloat(configs.negotiatedDiscount) / 100;
      let discount = parseFloat(configs.discount) / 100;

      if (Number.isNaN(discount)) {
        discount = 0;
      }

      if (Number.isNaN(negotiatedDiscount)) {
        negotiatedDiscount = 0;
      }

      const totalDiscount = 1.0 - (1.0 - discount) * (1.0 - negotiatedDiscount);
      return totalDiscount;
    } catch (error) {
      Logger.error('error: unable to determine discount', error);
      return 0.0;
    }
  },

  getPricing: async () => {
    try {
      const configs = await model.getConfigs();
      const pricing = {
        cpu: parseFloat(get(configs, 'CPU', 0.0)),
        spotCPU: parseFloat(get(configs, 'spotCPU', 0.0)),
        ram: parseFloat(get(configs, 'RAM', 0.0)),
        spotRAM: parseFloat(get(configs, 'spotRAM', 0.0)),
        gpu: parseFloat(get(configs, 'GPU', 0.0)),
        spotGPU: parseFloat(get(configs, 'spotGPU', 0.0)),
        storage: parseFloat(get(configs, 'storage', 0.0)),
        zoneNetworkEgress: parseFloat(get(configs, 'zoneNetworkEgress', 0.0)),
        regionNetworkEgress: parseFloat(
          get(configs, 'regionNetworkEgress', 0.0),
        ),
        internetNetworkEgress: parseFloat(
          get(configs, 'internetNetworkEgress', 0.0),
        ),
      };
      return pricing;
    } catch (error) {
      Logger.error('error: unable to determine pricing', error);
      return {
        cpu: null,
        spotCPU: null,
        ram: null,
        spotRAM: null,
        gpu: null,
        spotGPU: null,
        storage: null,
        zoneNetworkEgress: null,
        regionNetworkEgress: null,
        internetNetworkEgress: null,
      };
    }
  },

  clusterCosts: async (window, offset, multi) => {
    const offsetParam = offset || '';
    return model
      .get('/clusterCosts', { window, offsetParam, multi })
      .then((resp) => resp.data);
  },

  prometheusQuery: (query, thanos = false) =>
    fetch(
      `${getCurrentContainerAddressModel()}/${thanos ? 'thanosQuery' : 'prometheusQuery'}?query=${encodeURI(
        query,
      )}`,
    ).then(parseResponseJSON<{ data: { result: { metric: Record<string, string>; value: [number, string] }[] } }>),

  prometheusQueryRange: (query, thanos = false) =>
    fetch(
      `${getCurrentContainerAddressModel()}/${thanos ? 'thanosQuery' : 'prometheusQuery'}Range?query=${encodeURI(
        query,
      )}`,
    ).then(parseResponseJSON),

  prometheusTargets: async () => {
    const targets = await fetch(
      `${getCurrentContainerAddressModel()}/prometheusTargets`,
    );

    let resp;
    let text;
    try {
      text = await targets.text();
      resp = JSON.parse(text);
    } catch (err) {
      Logger.log(`Error Parsing Prometheus Targets: ${text}`);
      // the error here is returned as a string, need to wrap in expected object format
      resp = {
        status: 'error',
        message: text,
      };
    }

    return resp;
  },

  async clusterInfo(): Promise<ClusterInfoResponse> {
    const response = await KubecostService.get<ClusterInfoResponse>(
      `${getCurrentContainerAddressModel()}/clusterInfo`,
    );
    return response.data;
  },

  clusterInfoMap: async () =>
    model.get('/clusterInfoMap').then((resp) => resp.data),

  serviceAccountStatus: async () =>
    model.get('/serviceAccountStatus').then((resp) => resp.data),

  pricingSourceStatus: async () =>
    model.get('/pricingSourceStatus').then((resp) => resp.data),

  etlStatus: async () => model.get('/etl/status').then((resp) => resp.data),

  etlBackupStatus: async () =>
    model.get('/etl/backup/status').then((resp) => resp.data),

  etlAllocationRepair: async (window) =>
    model.get('/etl/allocation/repair', { window }).then((resp) => resp.data),

  etlAssetRepair: async (window) =>
    model.get('/etl/asset/repair', { window }).then((resp) => resp.data),

  nodeCount: async (window) =>
    model.get('/diagnostics/nodeCount', { window }).then((resp) => resp.data),

  savingsDiagnostics: async () => model.get('/savings/diagnostics'),

  ///--SETTINGS--///
  getAPIConfig: async () => await model.get<ApiConfig>('/getApiConfig', {}, {}),
  getPodLogs: async (payload: any) => await model.get(`/podLogs`, payload, {}),
  updateConfigByKey: async (payload: any) =>
    await model.post('/updateConfigByKey', payload),
  setApiConfig: async (payload: any) =>
    await model.post('/setApiConfig', payload),
  updateAthenaInfoConfigs: async (payload: any) =>
    await model.post('/updateAthenaInfoConfigs', payload),
  updateSpotInfoConfigs: async (payload: any) =>
    await model.post('/updateSpotInfoConfigs', payload),
  updateAzureConfig: async (payload: any) =>
    await model.post('/updateAzureStorageConfigs', payload),

  ///--SETTINGS--///

  diagnosticsPrometheusMetrics: async (): Promise<{
    code: number;
    data: { prometheus?: PromMetric[]; thanos?: PromMetric[] };
  }> =>
    model.get<{
      code: number;
      data: { prometheus?: PromMetric[]; thanos?: PromMetric[] };
    }>('/diagnostics/prometheusMetrics'),

  diagnosticsRequestQueue: async (): Promise<{
    code: number;
    data: { prometheus?: PromRequestQueue; thanos?: PromRequestQueue };
  }> =>
    model.get<{
      code: number;
      data: { prometheus?: PromRequestQueue; thanos?: PromRequestQueue };
    }>('/diagnostics/requestQueue'),

  trialStatus: () =>
    model.get<{ productKey: { key: string }; usedTrial: boolean }>(
      '/trialStatus',
    ),

  // computes the total cost for an allocation summary object
  getSummaryTotalCost(allocationSummary): number {
    return (
      (allocationSummary.cpuCost || 0) +
      (allocationSummary.gpuCost || 0) +
      (allocationSummary.loadBalancerCost || 0) +
      (allocationSummary.networkCost || 0) +
      (allocationSummary.pvCost || 0) +
      (allocationSummary.ramCost || 0) +
      (allocationSummary.externalCost || 0) +
      (allocationSummary.sharedCost || 0)
    );
  },

  // return network costs for a summary allocation query
  getSummaryNetworkCost(allocationSummary): number {
    return allocationSummary.networkCost || 0;
  },

  // cputes the total efficiency for a given allocation summary object
  getSummaryTotalEfficiency(alloc): number {
    const cpuEfficiency = this.getSummaryCpuEfficiency(alloc);
    const ramEfficiency = this.getSummaryRamEfficiency(alloc);

    // Compute efficiency as the cost-weighted average of CPU and RAM
    // efficiency
    let totalEfficiency;
    if (alloc.cpuCost + alloc.ramCost > 0) {
      totalEfficiency =
        (alloc.cpuCost * cpuEfficiency + alloc.ramCost * ramEfficiency) /
        (alloc.cpuCost + alloc.ramCost);
    } else {
      totalEfficiency = 0;
    }
    return totalEfficiency;
  },

  getSummaryCpuEfficiency(alloc): number {
    // CPU efficiency is defined as (usage/request). If request == 0 but
    // usage > 0, then efficiency gets set to 1.0.
    let cpuEfficiency = 0;
    if (alloc.cpuCoreRequestAverage > 0) {
      cpuEfficiency = alloc.cpuCoreUsageAverage / alloc.cpuCoreRequestAverage;
    } else if (alloc.cpuCoreUsageAverage > 0) {
      cpuEfficiency = 1.0;
    }
    return cpuEfficiency;
  },

  getSummaryRamEfficiency(alloc): number {
    // RAM efficiency is defined as (usage/request). If request == 0 but
    // usage > 0, then efficiency gets set to 1.0.
    let ramEfficiency = 0;
    if (alloc.ramByteRequestAverage > 0) {
      ramEfficiency = alloc.ramByteUsageAverage / alloc.ramByteRequestAverage;
    } else if (alloc.ramByteUsageAverage > 0) {
      ramEfficiency = 1.0;
    }
    return ramEfficiency;
  },

  getSummaryMinutes({ end, start }: { end: string; start: string }): number {
    return (Date.parse(end) - Date.parse(start)) / 1000 / 60;
  },

  /**
   *
   * @param {Object} configs - Currency options
   *
   * Return local currency.
   */
  getModelCurrency(configs: ModelConfigs): string {
    let currency = 'USD';

    if (
      configs !== null &&
      configs.currencyCode !== null &&
      configs.currencyCode !== ''
    ) {
      currency = configs.currencyCode;
    }
    return currency;
  },

  getCloudCosts: async (
    window: string,
    aggregate: APICloudCostParamType[],
  ): Promise<APIGetCloudCosts> => {
    const APIClient = useAPIClient();
    const { data } = await APIClient.get<KubecostResponse<APIGetCloudCosts>>(
      `/cloudCost/aggregate/view?window=${window}&aggregate=${encodeURIComponent(
        aggregate.toString(),
      )}`,
    );

    return data.data;
  },

  getEnablements: async (): Promise<APIEnablementsResponse> => {
    const APIClient = useAPIClient();
    const { data } = await APIClient.get<
      KubecostResponse<APIEnablementsResponse>
    >('/enablements');

    return data.data;
  },

  getNetworkTraffic: async (namespace?: string): Promise<any> => {
    let params: { maxDestinations: number; namespace?: string } = {
      maxDestinations: 8,
    };
    if (namespace) {
      params.namespace = namespace;
    }
    return model.get('/networkTraffic', params);
  },
};

export default model;

export function parseFilters(
  filters?: { property: string; value: string }[],
  filterKeyMap?: Record<string, string>,
) {
  if (isArray(filters) && filterKeyMap) {
    // separate any controller / controllerkind combination filters into distinct filters
    const flatFilters = filters.flatMap((f) => {
      const tokens = f.value.split('/');
      if (f.property.toLowerCase() === 'controller' && tokens.length === 2) {
        return [
          { property: 'controllerkind', value: tokens[0] },
          { property: 'controller', value: tokens[1] },
        ];
      }
      return f;
    });

    /* map combined user-set and context-set filters into the request params object.
     * filters of the form { property: 'prop', value: 'v1, v2' } will have their comma-delimted
     * `value`s OR'd together. multiple filter objects with the same { property: 'prop' } are AND'd.
     * In this way, a flatFilters array like
     *
     * ```
     * [
     *   { property: 'namespace', value: 'kubecost, kube-system' },
     *   { property: 'namespace', value: 'kubecost' }
     * ]
     * ```
     *
     * is interpreted as equivalent to
     *
     * ```
     * (namespace == kubecost || namespace == kube-system) && namespace == kubecost
     * ```
     */
    const combinedFilters = flatFilters.reduce(
      (combined: Record<string, string>, f) => {
        // keyMap maps between our client-side filter names and the equivalent
        // filter parameters supported by the REST API
        const keyMap: Record<string, string> = filterKeyMap;

        // Normalize property name -- all lowercase, no spaces.
        // This is mostly for `controllerkind`
        const key = f.property.toLowerCase().replace(/ /g, '');
        const comboKey = keyMap[key];

        // The reduce starts with an empty object `{}` for `combined` and each call to this function
        // returns a new object with the given filter `f` set appropriately.
        // If the given filter is unrecognized, log a warning and return the unaltered object for
        // use in the next iteration.
        if (!comboKey) {
          Logger.warn(
            `Warning: failed to parse filter ${f.property}=${f.value}`,
          );
          return combined;
        }

        // In the event that `combined` has no `filter*` property set yet, return the original
        // `combined` object, with the `filter*` key added and set to f.value.
        if (!Object.prototype.hasOwnProperty.call(combined, comboKey)) {
          return { ...combined, [comboKey]: f.value };
        }

        // Not taking the above block means `combined` already has a key `filter*`, and
        // the filter from `combined` should be AND'd with the new filter.
        const currentSet = new Set(combined[comboKey].split(', '));
        const newSet = new Set(
          f.value.split(', ').filter((s) => !currentSet.has(s)),
        );
        let val = Array.from(newSet);

        // Combine the two sets together
        val = [...val, ...Array.from(currentSet)];
        return { ...combined, [comboKey]: val.join(', ') };
      },
      {},
    );

    return combinedFilters;
    // params = { ...params, ...combinedFilters };
  }
  return {};
}

export interface ModelConfigs {
  billingDataDataset: string;
  provider: string;
  description: string;
  CPU: string;
  spotCPU: string;
  RAM: string;
  spotRAM: string;
  GPU: string;
  spotGPU: string;
  storage: string;
  zoneNetworkEgress: string;
  regionNetworkEgress: string;
  internetNetworkEgress: string;
  firstFiveForwardingRulesCost: string;
  additionalForwardingRuleCost: string;
  LBIngressDataCost: string;
  athenaBucketName: string;
  athenaRegion: string;
  athenaDatabase: string;
  athenaTable: string;
  masterPayerARN: string;
  customPricesEnabled: string;
  defaultIdle: string;
  azureSubscriptionID: string;
  azureClientID: string;
  azureClientSecret: string;
  azureTenantID: string;
  azureBillingRegion: string;
  currencyCode: string;
  discount: string;
  negotiatedDiscount: string;
  sharedOverhead: string;
  clusterName: string;
  sharedNamespaces: string;
  sharedLabelNames: string;
  sharedLabelValues: string;
  shareTenancyCosts: string;
  readOnly: string;
  kubecostToken: string;
  athenaProjectID?: string;
  awsSpotDataBucket?: string;
  awsSpotDataRegion?: string;
  projectID?: string;
  excludeProviderID: string;
}

interface PromMetric {
  id: string;
  query: string;
  label: string;
  description: string;
  docLink: string;
  result: Array<unknown>;
  passed: boolean;
}

interface PromRequestQueue {
  queuedRequests: Array<PrometheusRequest>;
  outboundRequests: number;
  totalRequests: number;
  maxQueryConcurrency: number;
}

interface PrometheusRequest {
  context: string;
  query: string;
  queueTime: number;
}

interface APIConfigResponse {
  cluster_external_label: string;
  controller_external_label: string;
  daemonset_external_label: string;
  department_external_label: string;
  department_label: string;
  deployment_external_label: string;
  disableFE: string;
  environment_external_label: string;
  environment_label: string;
  grafanaURL: string;
  namespace_external_label: string;
  owner_external_label: string;
  owner_label: string;
  pod_external_label: string;
  product_external_label: string;
  product_label: string;
  service_external_label: string;
  statefulset_external_label: string;
  team_external_label: string;
  team_label: string;
}

export interface ApiConfig {
  grafanaEnabled: boolean;
  grafanaURL: string;
  disableFE: string;
  owner_label: string;
  team_label: string;
  department_label: string;
  product_label: string;
  environment_label: string;
  namespace_external_label: string;
  cluster_external_label: string;
  controller_external_label: string;
  product_external_label: string;
  service_external_label: string;
  deployment_external_label: string;
  team_external_label: string;
  environment_external_label: string;
  department_external_label: string;
  statefulset_external_label: string;
  daemonset_external_label: string;
  pod_external_label: string;
  owner_external_label: string;
}

export interface ModelConfig {
  provider: string;
  description: string;
  CPU: string;
  spotCPU: string;
  RAM: string;
  spotRAM: string;
  GPU: string;
  spotGPU: string;
  storage: string;
  zoneNetworkEgress: string;
  regionNetworkEgress: string;
  internetNetworkEgress: string;
  firstFiveForwardingRulesCost: string;
  additionalForwardingRuleCost: string;
  LBIngressDataCost: string;
  athenaBucketName: string;
  athenaRegion: string;
  athenaDatabase: string;
  athenaTable: string;
  masterPayerARN: string;
  customPricesEnabled: string;
  defaultIdle: string;
  azureSubscriptionID: string;
  azureClientID: string;
  azureClientSecret: string;
  azureTenantID: string;
  azureBillingRegion: string;
  currencyCode: string;
  discount: string;
  negotiatedDiscount: string;
  sharedOverhead: string;
  clusterName: string;
  sharedNamespaces: string;
  sharedLabelNames: string;
  sharedLabelValues: string;
  shareTenancyCosts: string;
  readOnly: string;
  editorAccess: string;
  kubecostToken: string;
  athenaProjectID?: string;
  awsSpotDataBucket?: string;
  awsSpotDataRegion?: string;
  projectID?: string;
}

interface GetSavingsOptions {
  filters?: { property: string; value: string }[];
}

export interface SummaryQueryOpts {
  accumulate?: boolean;
  external?: boolean;
  filters?: { property: string; value: string }[];
  idleByNode?: boolean;
  shareCost?: number;
  shareIdle?: boolean;
  shareLabels?: string[];
  shareNamespaces?: string[];
  shareSplit?: 'even' | 'weighted';
  shareTenancyCosts?: boolean;
  idle?: boolean;
  reconcile?: boolean;
  step?: string;
}

interface SummaryRequestQueryOpts extends SummaryRequestFilters {
  accumulate?: boolean;
  aggregate: string;
  external?: boolean;
  idleByNode?: boolean;
  shareCost?: number;
  shareIdle?: boolean;
  shareLabels?: string;
  shareNamespaces?: string;
  shareSplit?: 'even' | 'weighted';
  shareTenancyCosts?: boolean;
  idle?: boolean;
  reconcile?: boolean;
  window: string;
}

interface SummaryRequestFilters {
  filterClusters?: string;
  filterNodes?: string;
  filterNamespaces?: string;
  filterLabels?: string;
  filterServices?: string;
  filterControllerKinds?: string;
  filterControllers?: string;
  filterPods?: string;
  filterDepartments?: string;
  filterEnvironments?: string;
  filterOwners?: string;
  filterProducts?: string;
  filterTeams?: string;
}

export interface ClusterInfoResponse {
  account: string;
  clusterProfile: string;
  errorReporting: string;
  id: string;
  logCollection: string;
  name: string;
  productAnalytics: string;
  provider: string;
  provisioner: string;
  region: string;
  remoteReadEnabled: string;
  thanosEnabled: string;
  valuesReporting: string;
  version: string;
}

export interface ClusterEnvResponse {
  promClusterIDLabel: string;
}

export interface InstallInfoResponse {
  version: string;
  containers: ContainerInfo[];
  clusterInfo: ClusterInfo;
}

interface ClusterInfo {
  nodeCount: string;
  podCount: string;
}

interface ContainerInfo {
  containerName: string;
  image: string;
  imageID: string;
  startTime: string;
  restarts: number;
}

export interface LocalConfig {
  version: number;
  cpu: number;
  pcpu: number;
  ram: number;
  pram: number;
  storage: number;
  ssd: number;
  egress: number;
  grafana: string;
  container: string;
  all_containers: string;
  contact_label: string;
  product_label: string;
  environment_label: string;
  department_label: string;
  team_label: string;
  spot_label_tag: string;
  spot_label: string;
}

export interface AllocationViewResponse {
  errors: string;
  initialResponse: {
    graphData: {
      topResultsGraph: TopResultsGraph;
      timeSeriesGraph: TimeSeriesGraph;
    };
    totalRow: TotalsRow;
    totalItems: number;
  };
  tableResults: {
    tableItems: TableResults[];
  };
  warnings: string;
}

export interface TopResultsGraph {
  items: { cost: number; efficiency: number; name: string }[];
}

export interface TimeSeriesGraph {
  graphItems: APIAllocationsTimeSeriesGraphPoint[];
}

export interface APITimeSeriesGraphPoint {
  start: string;
  end: string;
}

export interface APIAllocationsTimeSeriesGraphPoint
  extends APITimeSeriesGraphPoint {
  graph: {
    items: APIAllocationsTimeSeriesGraphPointItem[];
  };
}

export interface APITimeSeriesGraphPointItem {
  name: string;
}

export interface APIAllocationsTimeSeriesGraphPointItem
  extends APITimeSeriesGraphPointItem {
  cost: number;
  efficiency: number;
}

export interface TableResults {
  cpuCost: number;
  externalCost: number;
  name: string;
  ramCost: number;
  sharedCost: number;
  totalCost: number;
  loadBalancerCost: number;
  averageCpuUtilization: number;
  averageRamUtilization: number;
  efficiency: number;
  pvCost: number;
  gpuCost: number;
  networkCost: number;
}

export interface TotalsRow {
  averageCpuUtilization: number;
  averageRamUtilization: number;
  cpuCost: number;
  efficiency: number;
  externalCost: number;
  gpuCost: number;
  loadBalancerCost: number;
  name: string;
  networkCost: number;
  pvCost: number;
  ramCost: number;
  sharedCost: number;
  totalCost: number;
}

export interface ViewQueryOpts {
  chartType: ChartType;
  costMetric: CostMetric;
  startIndex: number;
  maxResults: number;
}

enum ChartType {
  Cost = 1,
  CostOverTime = 2,
  EfficiencyOverTime = 3,
  ProportionalCost = 4,
  CostTreemap = 5,
}

enum CostMetric {
  Cumulative = 1,
  Monthly = 2,
  Daily = 3,
  Hourly = 4,
}

export interface ExternalAllocationResponse {
  items: ExternalAllocationResponseItem[];
  totals: ExternalAllocationResponseTotals;
}

export interface ExternalAllocationResponseItem {
  cpuCost: number;
  efficiency: number;
  externalCost: number;
  gpuCost: number;
  externalBreakdown?: Array<{
    cost: number;
    name: string;
  }>;
  loadBalancerCost: number;
  name: string;
  networkCost: number;
  pvCost: number;
  ramCost: number;
  sharedCost: number;
  totalCost: number;
}

export interface ExternalAllocationResponseTotals {
  cpuCost: number;
  efficiency: number;
  externalCost: number;
  gpuCost: number;
  loadBalancerCost: number;
  networkCost: number;
  pvCost: number;
  ramCost: number;
  sharedCost: number;
  totalCost: number;
}
