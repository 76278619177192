import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import { toCurrency } from '../../../services/format';
import { useClusters } from '../../../contexts/ClusterConfig';

function CategoryChart({ top, other, idle, aggregateBy }) {
  const { modelConfig } = useClusters();
  const margin = { top: 30, right: 35, left: 15, bottom: 45 };
  const data = [...top, ...other, ...idle];
  data.sort((a, b) => (a.totalCost > b.totalCost ? 1 : -1));
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart margin={margin} data={data}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis
          dataKey="name"
          angle={330}
          tick={{ fontSize: 12 }}
          tickFormatter={shorten}
          tickMargin={16}
          interval={0}
        />
        <YAxis
          tickFormatter={(val) =>
            toCurrency(val, modelConfig.currencyCode, 2, true)
          }
        />
        <Tooltip formatter={tooltipFormatter} />
        <Bar dataKey="totalCost" fill="#2196f3" />
      </BarChart>
    </ResponsiveContainer>
  );

  function tooltipFormatter(value: number) {
    return [toCurrency(value, modelConfig.currencyCode), 'Total cost'];
  }

  function shorten(s: string) {
    let text = s;
    const maxlen = 16;
    if (aggregateBy === 'controller' && s !== '__idle__') {
      const spl = s.split(':');
      text = spl[1] || spl[0];
    }
    if (text.length < maxlen) {
      return text;
    }

    if (typeof text !== 'string') return s;

    return `${text.slice(0, maxlen - 3)}...`;
  }
}

export default CategoryChart;
