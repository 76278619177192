import React from 'react';
import { IconProps } from './types';

export const FilterIcon = ({ ...args }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 16 16"
    {...args}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15 2v1.67l-5 4.759V14H6V8.429l-5-4.76V2h14zM7 8v5h2V8l5-4.76V3H2v.24L7 8z"
      clipRule="evenodd"
    />
  </svg>
);
