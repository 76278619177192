import { Select, Button } from '@kubecost-frontend/holster';
import React from 'react';

interface TablePaginationProps {
  count: number;
  rowsPerPage: number;
  rowsPerPageOptions: number[];
  page: number;
  onPageChange: (newPage: number) => void;
  onRowsPerPageChange: (newRowsPerPageCount: number) => void;
}

export const TablePagination = ({
  count,
  rowsPerPage,
  rowsPerPageOptions,
  page,
  onPageChange,
  onRowsPerPageChange,
}: TablePaginationProps) => {
  const formatCurrentPositionString = () => {
    const fromPosition = page * rowsPerPage;
    let toPosition = fromPosition + rowsPerPage;
    if (toPosition > count) {
      toPosition = count;
    }
    return `${fromPosition + 1}-${toPosition} of ${count}`;
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '1em',
      }}
    >
      <div style={{ display: 'flex', gap: '1em', alignItems: 'center' }}>
        <div>Rows per page</div>
        <Select
          style={{ width: 75 }}
          options={rowsPerPageOptions.map((item: number) => ({
            label: `${item}`,
            value: `${item}`,
          }))}
          setValue={(newCount: string) =>
            onRowsPerPageChange(parseInt(newCount, 10))
          }
          value={`${rowsPerPage}`}
        />
      </div>
      <div style={{ display: 'flex', gap: '1em', alignItems: 'center' }}>
        <div>{formatCurrentPositionString()}</div>
        <Button
          disabled={page === 0}
          variant="default"
          onClick={() => onPageChange(page - 1)}
        >
          Previous
        </Button>
        <Button
          disabled={(page + 1) * rowsPerPage > count}
          variant="default"
          onClick={() => onPageChange(page + 1)}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
