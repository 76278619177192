import React, { FC } from 'react';

import { NavIconProps } from './types';

const OverviewNavIcon: FC<NavIconProps> = ({ active, ...args }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...args}
    >
      <mask id="path-1-inside-1_2_45" fill="white">
        <rect x="5" y="5" width="8.75" height="8.75" rx="1" />
      </mask>
      <rect
        x="5"
        y="5"
        width="8.75"
        height="8.75"
        rx="1"
        stroke={active ? '#63E892' : '#607971'}
        strokeWidth="3"
        mask="url(#path-1-inside-1_2_45)"
      />
      <mask id="path-2-inside-2_2_45" fill="white">
        <rect x="5" y="16.25" width="8.75" height="8.75" rx="1" />
      </mask>
      <rect
        x="5"
        y="16.25"
        width="8.75"
        height="8.75"
        rx="1"
        stroke={active ? '#63E892' : '#607971'}
        strokeWidth="3"
        mask="url(#path-2-inside-2_2_45)"
      />
      <mask id="path-3-inside-3_2_45" fill="white">
        <rect x="16.25" y="5" width="8.75" height="8.75" rx="1" />
      </mask>
      <rect
        x="16.25"
        y="5"
        width="8.75"
        height="8.75"
        rx="1"
        stroke={active ? '#63E892' : '#607971'}
        strokeWidth="3"
        mask="url(#path-3-inside-3_2_45)"
      />
      <mask id="path-4-inside-4_2_45" fill="white">
        <rect x="16.25" y="16.25" width="8.75" height="8.75" rx="1" />
      </mask>
      <rect
        x="16.25"
        y="16.25"
        width="8.75"
        height="8.75"
        rx="1"
        stroke={active ? '#63E892' : '#607971'}
        strokeWidth="3"
        mask="url(#path-4-inside-4_2_45)"
      />
    </svg>
  );
};
export default OverviewNavIcon;
