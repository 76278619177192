// mui
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// formik/yup
import { useFormik } from 'formik';
import * as Yup from 'yup';

// react
import React from 'react';

// local
import { AlertTypes, ClusterHealthAlert } from '../../../services/alerts';
import TestAlertButton from '../TestAlertButton';

export interface ComponentProps {
  open?: boolean;
  cancelClicked?: () => void;
  alertSaved?: (values: ClusterHealthAlert) => void;
  alert?: ClusterHealthAlert;
}

const HealthAlertFormSchema = Yup.object().shape({
  threshold: Yup.number()
    .required('Health score is required.')
    .min(0, 'Health score ranges from 0 to 100!')
    .max(100, 'Health score ranges from 0 to 100!'),
  window: Yup.string().required('Window is required.'),
  slackWebhookUrl: Yup.string().required('Slack Url is required'),
});

const HealthAlertModal: React.FC<ComponentProps> = ({
  open = false,
  cancelClicked,
  alertSaved,
  alert,
}: ComponentProps) => {
  const {
    handleChange,
    submitForm,
    handleSubmit,
    values,
    touched,
    errors,
    validateForm,
    setTouched,
  } = useFormik<ClusterHealthAlert>({
    enableReinitialize: true,
    initialValues:
      alert ??
      new ClusterHealthAlert({
        type: AlertTypes.Health,
        window: '',
        threshold: 50,
        slackWebhookUrl: '',
      }),
    onSubmit: (values) => {
      if (!values || !alertSaved) return;
      alertSaved(values);
    },
    validationSchema: HealthAlertFormSchema,
  });

  return (
    <Dialog data-test="health-alert-dialog" onClose={cancelClicked} open={open}>
      <form onSubmit={handleSubmit}>
        <DialogTitle data-testid="ham-title">
          Enable Cluster Health Monitoring
        </DialogTitle>
        <DialogContent>
          <Typography style={{ marginBottom: 16 }}>
            With cluster health monitoring enabled, Kubecost will send a message
            to the designated Slack webhook any time the cluster&apos;s Health
            score deteriorates by more than the designated threshold, within the
            designated time window.
          </Typography>

          <Typography>
            Learn more about Slack webhooks{' '}
            <Link
              href="https://api.slack.com/messaging/webhooks"
              target="_blank"
            >
              here
            </Link>
            .
          </Typography>
          <FormGroup>
            <TextField
              type="number"
              style={{ marginTop: 16 }}
              label="Health change threshold"
              name="threshold"
              inputProps={{ 'data-testid': 'ham-healthScore' }}
              onChange={handleChange}
              value={values.threshold}
              error={touched.threshold && Boolean(errors.threshold)}
              helperText={
                touched.threshold && errors.threshold
                  ? errors.threshold
                  : 'Health scores range from 0 to 100'
              }
            />
            <TextField
              inputProps={{ 'data-testid': 'ham-window' }}
              style={{ marginTop: 16 }}
              label="Window"
              name="window"
              onChange={handleChange}
              value={values.window}
              error={touched.window && Boolean(errors.window)}
              helperText={
                touched.window && errors.window
                  ? errors.window
                  : 'Must be a relative window, such as 7d or 10m'
              }
            />
            <TextField
              inputProps={{ 'data-testid': 'ham-slackurl' }}
              style={{ marginTop: 16 }}
              label="Slack webhook"
              name="slackWebhookUrl"
              onChange={handleChange}
              value={values.slackWebhookUrl}
              error={touched.slackWebhookUrl && Boolean(errors.slackWebhookUrl)}
              helperText={touched.slackWebhookUrl && errors.slackWebhookUrl}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={cancelClicked}>
            Cancel
          </Button>
          <Button
            color="primary"
            disableElevation
            variant="contained"
            onClick={submitForm}
          >
            Save
          </Button>
          <TestAlertButton
            alert={values}
            validate={async () => {
              setTouched({
                threshold: true,
                slackWebhookUrl: true,
                window: true,
              });
              let errors = await validateForm();
              if (Object.keys(errors).length > 0) return false;
              return true;
            }}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default HealthAlertModal;
