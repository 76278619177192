/* eslint-disable react/jsx-props-no-spreading */

// MUI components
import Typography from '@material-ui/core/Typography';

// react
import React, { useEffect, useState } from 'react';

// local
import Loading from './Loading';
import { FetchStates } from '../constants';

/**
 * DataLoader is an HOC that handles conditionally displaying ``component`` based on the resolution state of ``promise``.
 * This should provide a more uniform mode of handling data loading while also reducing burden on programmers.
 *
 * While the given Promise is unresolved, a standard loading spinner is shown, along with the given message ``loadMessage``.
 * If the Promise rejects, ``errorMessage`` is shown with formatted text.
 * If the Promise resolves successfully, the given ``component`` is returned.
 *
 * @param props - An object containing component, errorMessage, loadMessage, and promise
 * @returns React.ReactElement
 */
function WithDataLoader<P>(
  WrappedComponent: React.ComponentType<P>,
): React.FC<P & WrapperProps> {
  return function WithDataLoaderComponent({
    errorMessage,
    loadMessage,
    promise,
    ...props
  }: WrapperProps): React.ReactElement {
    const [fetchState, setFetchState] = useState(FetchStates.LOADING);
    useEffect(() => {
      if (!promise) {
        return;
      }
      promise
        .then((arg: unknown) => {
          setFetchState(FetchStates.DONE);
          return arg;
        })
        .catch((err: Error) => {
          setFetchState(FetchStates.ERROR);
          throw err;
        });
    }, [promise]);

    switch (fetchState) {
      case FetchStates.ERROR:
        return (
          <Typography variant="h6" color="textSecondary">
            {errorMessage}
          </Typography>
        );
      case FetchStates.DONE:
        return <WrappedComponent {...(props as P)} />;
      case FetchStates.LOADING:
        return <Loading message={loadMessage} />;
      default:
        throw new Error('Internal state error');
    }
  };
}

export default WithDataLoader;

interface WrapperProps {
  errorMessage: string;
  loadMessage: string;
  promise: Promise<unknown> | null;
}
