import { makeStyles } from '@material-ui/styles';
import reverse from 'lodash/reverse';
import React from 'react';

import { toCurrency } from '../services/format';

interface ComponentProps {
  active?: boolean;
  payload?: TooltipPayload[];
}

interface TooltipPayload {
  color: string;
  dataKey: string;
  fill: string;
  name: string;
  payload: { end: Date; start: Date };
  value: number;
  formatter?: () => string;
}

const useStyles = makeStyles({
  tooltip: {
    borderRadius: 2,
    background: 'rgba(255, 255, 255, 0.95)',
    padding: 12,
  },
  tooltipLineItem: {
    fontSize: '1rem',
    margin: 0,
    marginBottom: 4,
    padding: 0,
  },
});

function BarChartTooltipMaker(currency: string): React.FC<ComponentProps> {
  const BarChartTooltip: React.FC<ComponentProps> = ({ active, payload }) => {
    const classes = useStyles();
    if (!payload || payload.length === 0) {
      return null;
    }

    const dateTimeFormatter = Intl.DateTimeFormat(navigator.language, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });

    const total = payload.reduce((sum, item) => sum + item.value, 0.0);

    let start = null;
    if (payload[0] && payload[0].payload) {
      start = payload[0].payload.start;
    }

    let end = null;
    if (payload[0] && payload[0].payload) {
      end = payload[0].payload.end;
    }

    if (active) {
      return (
        <div className={classes.tooltip}>
          {start && end && (
            <p
              className={classes.tooltipLineItem}
              style={{ color: '#808080', fontSize: '0.75rem' }}
            >{`${dateTimeFormatter.format(start)} to ${dateTimeFormatter.format(
              end,
            )}`}</p>
          )}
          <p
            className={classes.tooltipLineItem}
            style={{ color: '#000000' }}
          >{`Total: ${toCurrency(total, currency)}`}</p>
          {reverse(payload).map((item) => (
            <p
              key={item.dataKey}
              className={classes.tooltipLineItem}
              style={{ color: item.fill }}
            >{`${item.name}: ${toCurrency(item.value, currency)}`}</p>
          ))}
        </div>
      );
    }

    return null;
  };
  BarChartTooltip.defaultProps = {
    active: undefined,
    payload: undefined,
  };
  return BarChartTooltip;
}

export default BarChartTooltipMaker;
