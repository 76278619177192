import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import slice from 'lodash/slice';
import values from 'lodash/values';
import model from './model';

function innerJoinVectors(vecs, ...keys) {
  const maps = [];
  // convert all vectors in vecs into maps from (sets of join keys) to
  // (metric, value); e.g. for keys=['pod', 'namespace'] convert each
  // vector into a map of the format:
  //
  // {
  //   'pod=abc,namespace=xyz': {
  //      metric: { pod: 'abc', namespace: 'xyz'},
  //      value: [1576696037.716, "8.38619"],
  //   },
  //   'pod=def,namespace=xyz': {
  //      metric: { pod: 'def', namespace: 'xyz'},
  //      value: [1576696037.716, "5.38619"],
  //   },
  //   ...
  // }
  for (let v = 0; v < vecs.length; v++) {
    // iterating over each result (metric: ..., value: [...]) in the vector
    maps[v] = {};
    for (const vec of vecs[v].data.result) {
      // create a new metric, composed of only the joined properties
      const metric = {};
      // keySet is a stable-ordered list representing the metric, allowing
      // efficient matching between vecs1 and vecs2
      const keySet = [];

      for (let k = 0; k < keys.length; k++) {
        const key = keys[k];
        const val = get(vec, `metric.${key}`, '');
        keySet.push(`${key}=${val}`);
        metric[key] = val;
      }
      const keySetStr = keySet.join(',');

      // confirm that the key set is unique; i.e. for each key set, there is
      // exactly one value; otherwise,
      if (maps[v][keySetStr] !== undefined) {
        throw {
          status: 'error',
          errorType: 'non_unique_keys',
          error: `join can only be used with unique key set key set "${keySetStr}" is not unique`,
        };
      }

      // handle joining vectors with either 'value' or 'values' properties because
      // we need to be able to interchangeably join original vector results ('value')
      // with the output of join ('values')
      let { values } = vec;
      if (values === undefined && vec.value !== undefined) {
        values = [vec.value];
      }
      values = slice(values, 0);

      maps[v][keySetStr] = {
        metric,
        values,
      };
    }
  }

  const resultMap = [];
  // join each map into a single map of results, turning each result's
  // value into an array of values
  for (let m = 0; m < maps.length; m++) {
    for (const keySetStr in maps[m]) {
      // only accept keySetStrs that exist in all maps
      let exists = true;
      for (let n = 0; n < maps.length; n++) {
        if (maps[n][keySetStr] === undefined) {
          exists = false;
          break;
        }
      }
      if (!exists) {
        continue;
      }

      if (resultMap[keySetStr] === undefined) {
        // create new entry
        resultMap[keySetStr] = {
          metric: maps[m][keySetStr].metric,
          values: maps[m][keySetStr].values,
        };
      } else {
        // append to existing entry
        resultMap[keySetStr].values.push(...maps[m][keySetStr].values);
      }
    }
  }

  return {
    status: 'success',
    data: {
      resultType: 'vector',
      result: values(resultMap),
    },
  };
}

// prometheus provides utility functions for operating on Prometheus
// queries and responses
const prometheus = {
  checkStatus: (...resps) => {
    for (const resp of resps) {
      const status = get(resp, 'status', 'error');
      if (status !== 'success') {
        const err = get(resp, 'error', 'unknown error occurred');
        const errType = get(resp, 'errorType', 'unknown');
        throw {
          status: 'error',
          errorType: errType,
          error: err,
        };
      }
    }

    return true;
  },

  checkData: (...resps) => {
    const types = [];

    for (const resp of resps) {
      const data = get(resp, 'data', undefined);
      if (data === undefined) {
        throw {
          status: 'error',
          errorType: 'no_data',
          error: 'response contained no data',
        };
      }

      types.push(get(data, 'resultType', undefined));
    }

    if (types.length === 0) {
      throw {
        status: 'error',
        errorType: 'invalid_result_type',
        error: 'result of invalid type',
      };
    }

    for (let i = 0; i < types.length - 1; i++) {
      if (types[i] !== types[i + 1]) {
        throw {
          status: 'error',
          errorType: 'invalid_response_type',
          error: `response types must match: found both ${types[i]} and ${
            types[i + 1]
          }`,
        };
      }
    }

    return types[0];
  },

  filter: (resp, filterFunc) => {
    if (typeof filterFunc !== 'function') {
      return {
        status: 'error',
        errorType: 'invalid_function',
        error:
          'filter requires a filtering function of type (result object) => boolean',
      };
    }

    try {
      // check that both responses are valid and consistent
      prometheus.checkStatus(resp);
      prometheus.checkData(resp);

      // clone the incoming Prometheus response, then apply the filterFunc
      const out = cloneDeep(resp);
      const result = [];
      for (let i = 0; i < out.data.result.length; i++) {
        if (filterFunc(out.data.result[i])) {
          result.push(out.data.result[i]);
        }
      }
      out.data.result = result;
      return out;
    } catch (err) {
      return err;
    }
  },

  join: (in1, in2, ...keys) => {
    // check that there is at least one key with which to join
    if (keys.length < 0) {
      return {
        status: 'error',
        errorType: 'invalid_keys',
        error: 'join requires at least one key',
      };
    }

    // check that both responses are valid and consistent
    try {
      prometheus.checkStatus(in1, in2);
      const type = prometheus.checkData(in1, in2);

      // TODO write a joinMatrices
      if (type !== 'vector') {
        throw {
          status: 'error',
          errorType: 'invalid_response_type',
          error: `join can only be used on vectors: found ${type}`,
        };
      }

      return innerJoinVectors([in1, in2], ...keys);
    } catch (err) {
      return err;
    }
  },

  map: (resp, mapFunc) => {
    if (typeof mapFunc !== 'function') {
      return {
        status: 'error',
        errorType: 'invalid_function',
        error:
          'map requires a mapping function of type (result object) => object',
      };
    }

    try {
      // check that both responses are valid and consistent
      prometheus.checkStatus(resp);
      prometheus.checkData(resp);

      // clone the incoming Prometheus response, then apply the mapFunc
      const out = cloneDeep(resp);
      for (let i = 0; i < out.data.result.length; i++) {
        out.data.result[i] = mapFunc(cloneDeep(out.data.result[i]));
      }
      return out;
    } catch (err) {
      return err;
    }
  },

  labelCopy: (vec, fromName, toName) => {
    try {
      prometheus.checkData(vec);
      for (let i = 0; i < vec.data.result.length; i++) {
        vec.data.result[i].metric[toName] = vec.data.result[i].metric[fromName];
      }
      return vec;
    } catch (err) {
      return err;
    }
  },

  targets: model.prometheusTargets,

  query: model.prometheusQuery,
  queryRange: model.prometheusQueryRange,
};

export default prometheus;
