// react
import React from 'react';

// holster
import { Tooltip, Typography } from '@kubecost-frontend/holster';

// local
import { toVerboseTimeRange } from '../../services/format';
import { AllocationContext } from '../../types/allocation';
import NavigateNextIcon from '../../assets/images/NavigateNextIcon';

type SubtitleProps = {
  report: { aggregateBy: string[]; window: string };
  context: AllocationContext;
  clearContext: () => void;
  goToContext: (i: number) => void;
};

const Subtitle = ({
  report,
  context,
  clearContext,
  goToContext,
}: SubtitleProps) => {
  const { aggregateBy, window } = report;

  const aggByStr = aggregateBy.join(', ');

  if (!Array.isArray(context) || context.length === 0) {
    return (
      <Typography variant="p-small">
        {toVerboseTimeRange(window)}
        {aggByStr ? ` by ${aggByStr}` : ''}
      </Typography>
    );
  }

  return (
    <>
      <a
        onClick={clearContext}
        style={{ cursor: 'pointer', display: 'inline-block' }}
      >
        <Typography variant="p-small">
          {toVerboseTimeRange(window)} by {context[0].property.toLowerCase()}
        </Typography>
      </a>
      {context.map((ctx, i) => {
        if (i === context.length - 1) {
          return (
            <>
              <NavigateNextIcon style={{ fontSize: 14 }} />
              <Tooltip key={ctx.property} content={ctx.property}>
                <Typography variant="p-small">{ctx.name}</Typography>
              </Tooltip>
            </>
          );
        }
        return (
          <>
            <NavigateNextIcon style={{ fontSize: 14 }} />
            <a
              key={ctx.property}
              onClick={(e) => {
                e.preventDefault();
                goToContext(i);
              }}
              style={{ display: 'inline-block' }}
            >
              <Tooltip content={ctx.property}>
                <Typography variant="p-small">{ctx.name}</Typography>
              </Tooltip>
            </a>
          </>
        );
      })}
    </>
  );
};

export default React.memo(Subtitle);
