import React from 'react';

import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ReadyIcon from './ReadyIcon';
import { SpotReady } from '../../services/savings/spot';

function keyEntryFor(readiness: SpotReady): React.ReactElement {
  return (
    <ListItem>
      <ListItemIcon>
        <ReadyIcon readiness={readiness} />
      </ListItemIcon>
      <ListItemText>
        <Typography>{readiness}</Typography>
      </ListItemText>
    </ListItem>
  );
}

function TableKey(): React.ReactElement {
  return (
    <List>
      {keyEntryFor(SpotReady.No)}
      {keyEntryFor(SpotReady.Maybe)}
      {keyEntryFor(SpotReady.Yes)}
      {keyEntryFor(SpotReady.NA)}
    </List>
  );
}

export default React.memo(TableKey);
