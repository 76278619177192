import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Input, Modal, Typography } from '@kubecost-frontend/holster';
import { AlertTypes, ClusterHealthAlert } from '../../../services/alerts';
import TestAlertButton from '../TestAlertButton';

export interface ComponentProps {
  open?: boolean;
  cancelClicked?: () => void;
  alertSaved?: (values: ClusterHealthAlert) => void;
  alert?: ClusterHealthAlert;
}

const HealthAlertFormSchema = Yup.object().shape({
  threshold: Yup.number()
    .required('Health score is required.')
    .min(0, 'Health score ranges from 0 to 100!')
    .max(100, 'Health score ranges from 0 to 100!'),
  window: Yup.string().required('Window is required.'),
  slackWebhookUrl: Yup.string().required('Slack Url is required'),
});

const HealthAlertModal: React.FC<ComponentProps> = ({
  open = false,
  cancelClicked,
  alertSaved,
  alert,
}: ComponentProps) => {
  const {
    handleChange,
    submitForm,
    handleSubmit,
    values,
    touched,
    errors,
    validateForm,
    setTouched,
  } = useFormik<ClusterHealthAlert>({
    enableReinitialize: true,
    initialValues:
      alert ??
      new ClusterHealthAlert({
        type: AlertTypes.Health,
        window: '',
        threshold: 50,
        slackWebhookUrl: '',
      }),
    onSubmit: (values) => {
      if (!values || !alertSaved) return;
      values.slackWebhookUrl = '';
      alertSaved(values);
    },
    validationSchema: HealthAlertFormSchema,
  });

  return (
    <Modal
      data-test="health-alert-dialog"
      onClose={cancelClicked}
      open={open}
      title="Enable Cluster Health Monitoring"
    >
      <form onSubmit={handleSubmit}>
        <Typography variant="p" style={{ marginBottom: 16 }}>
          With cluster health monitoring enabled, Kubecost will send a message
          to the designated Slack webhook any time the cluster&apos;s Health
          score deteriorates by more than the designated threshold, within the
          designated time window.
        </Typography>

        <Typography variant="p">
          Learn more about Slack webhooks{' '}
          <a
            href="https://api.slack.com/messaging/webhooks"
            target="_blank"
            style={{ color: '#28B359' }}
          >
            here
          </a>
          .
        </Typography>
        <Input
          type="number"
          style={{ width: '100%' }}
          label="Health change threshold"
          name="threshold"
          onChange={handleChange}
          value={values.threshold}
          // error={touched.threshold && Boolean(errors.threshold)}
          helperText={
            touched.threshold && errors.threshold
              ? errors.threshold
              : 'Health scores range from 0 to 100'
          }
        />
        <Input
          style={{ width: '100%' }}
          label="Window"
          name="window"
          onChange={handleChange}
          value={values.window}
          // error={touched.window && Boolean(errors.window)}
          helperText={
            touched.window && errors.window
              ? errors.window
              : 'Must be a relative window, such as 7d or 10m'
          }
        />
        <Input
          style={{ width: '100%' }}
          label="Slack webhook"
          name="slackWebhookUrl"
          onChange={handleChange}
          value={values.slackWebhookUrl}
          placeholder="https://hooks.slack.com/services/XXXXXXXXXXX/XXXXXXXXXXX/XXXXXXXXXXXXXXXXXXXXXXXX"
          // error={touched.slackWebhookUrl && Boolean(errors.slackWebhookUrl)}
          helperText={touched.slackWebhookUrl && errors.slackWebhookUrl}
          type="password"
        />
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 8 }}
        >
          <Button
            variant="primary"
            onClick={cancelClicked}
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={submitForm}
            style={{ marginRight: 8 }}
          >
            Save
          </Button>
          <TestAlertButton
            alert={values}
            validate={async () => {
              setTouched({
                threshold: true,
                slackWebhookUrl: true,
                window: true,
              });
              let errors = await validateForm();
              if (Object.keys(errors).length > 0) return false;
              return true;
            }}
          />
        </div>
      </form>
    </Modal>
  );
};

export default HealthAlertModal;
