import React from 'react';

import Typography from '@material-ui/core/Typography';

import { Tooltip } from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ReadyIcon from './ReadyIcon';
import {
  ChecklistResponse,
  ChecklistResult,
  ChecklistCheck,
} from '../../services/savings/spot';

function tableCellForCheck(cc: ChecklistCheck): React.ReactElement {
  return (
    <TableCell align="left">
      <Tooltip title={cc.longMessage} arrow placement="right">
        <Typography>
          <ReadyIcon readiness={cc.ready} /> {cc.shortMessage}
        </Typography>
      </Tooltip>
    </TableCell>
  );
}

function controllerRow(c: ChecklistResult): React.ReactElement {
  return (
    <TableRow hover style={{ cursor: 'pointer' }}>
      <TableCell align="left">
        <ReadyIcon readiness={c.readiness} />
      </TableCell>
      <TableCell align="left">
        <Typography variant="subtitle2" color="textSecondary">
          {c.namespace}:
        </Typography>
        <Typography variant="subtitle2">{c.name}</Typography>
      </TableCell>
      {tableCellForCheck(c.checklist.controllerTypeCheck)}
      {tableCellForCheck(c.checklist.replicasCheck)}
      {tableCellForCheck(c.checklist.localStorageCheck)}
      {tableCellForCheck(c.checklist.controllerPDBCheck)}
      {tableCellForCheck(c.checklist.deploymentRollingUpdateCheck)}
      {tableCellForCheck(c.checklist.namespaceAnnotationOverrideCheck)}
      {tableCellForCheck(c.checklist.controllerAnnotationOverrideCheck)}
    </TableRow>
  );
}

interface TableProps {
  response: ChecklistResponse;
}

function SpotChecklistTable({ response }: TableProps): React.ReactElement {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Overall Readiness</TableCell>
            <TableCell>Controller</TableCell>
            <TableCell>Controller Type</TableCell>
            <TableCell>Replicas</TableCell>
            <TableCell>Local Storage</TableCell>
            <TableCell>Controller PDB</TableCell>
            <TableCell>Deployment Rolling Update</TableCell>
            <TableCell>Namespace Annotation Override</TableCell>
            <TableCell>Controller Annotation Override</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {response.map((c: ChecklistResult) => controllerRow(c))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default React.memo(SpotChecklistTable);
