import model from './model';
import { getCacheBuster } from './util';

// A jQuery shim.
// All of the functions in this file were copy/pasted from an older, jQuery-based codebase.
// Many of them call each other in lengthy-ish dependency trees.
// jQuery is not present in the React-based codebase, and it was easier to shim the relevant
// methods than to rewrite all of the dependent functions using modern equivalents.
// - Neal

class Deferred {
  prom: Promise<unknown>;
  resolve: (value: unknown) => void = () => {};
  reject: (reason?: any) => void = () => {};
  constructor() {
    this.prom = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  promise() {
    return this.prom;
  }
}

const $ = {
  Deferred: Deferred,
  when(...args) {
    return Promise.all(args);
  },
  getJSON(url, callback) {
    const p = fetch(url)
      .then((response) => response.json())
      .then(callback);
    p.fail = p.catch;
    return p;
  },
  each(iter, callback) {
    if (Array.isArray(iter)) {
      iter.forEach((item, index) => callback(index, item));
    } else if (iter && typeof iter === 'object') {
      Object.entries(iter).forEach(([key, value]) => callback(key, value));
    }
  },
};

const query_path = '/prometheusQuery?query=';
const query_range_path = '/prometheusQueryRange?query=';
const thanos_query_path = '/thanosQuery?query=';

// 100 TB
const MAX_FS_BYTES = 100 * 1099511627776;

class HelperDumpService {
  checkNodeTurndown(utilizationThreshold) {
    return $.getJSON(
      `${this.getCurrentContainerAddressModel()}/savings/nodeTurndown?utilization=${utilizationThreshold}`,
    ).promise();
  }

  async getApiConfig() {
    return fetch(
      `${this.getCurrentContainerAddressModel()}/getApiConfig?${getCacheBuster()}`,
    ).then((response) => response.json());
  }

  /**
    Wrap the helper.js function to limit direct exposure to helper.js
  */
  getCurrentContainerAddressModel() {
    const DEFAULT_SERVICE_ADDRESS = 'http://localhost:9090/model';

    let service = localStorage.getItem('container');
    if (!service) {
      service = this.getConnectedAddress();
      if (service.includes('localhost')) {
        service = DEFAULT_SERVICE_ADDRESS;
      }
    }
    if (service && service.endsWith('/api')) {
      service = service.replace('/api', '/model');
    } else if (service && service.endsWith('/model') === false) {
      service = service + '/model';
    }
    return service;
  }

  getConnectedAddress() {
    let address = this.getPathWithoutFile(
      window.location.origin + window.location.pathname,
    );
    return address;
  }

  getPathWithoutFile(href: string) {
    if (href.split('/').length < 4) {
      // This should never happen, but best to be defensive in case this is called with something like http://123.456.789.1011
      return href.split('?')[0]; //remove arguments and then call
    } else if (href.lastIndexOf('.') > href.lastIndexOf('/'))
      return href.substring(0, href.lastIndexOf('/'));
    else if (href.endsWith('/')) return href.substring(0, href.length - 1);
    else return href;
  }

  async getDataFromRecordingRule(ruleName, range, params) {
    const recordingRuleMap = {
      'cluster:cpu_usage:rate5m':
        'sum(rate(container_cpu_usage_seconds_total{container_name!=""}[5m]))',
    };

    const containerAddress = this.getCurrentContainerAddressModel();

    const response = await fetch(
      `${containerAddress}/prometheusRecordingRules`,
    );
    const data = await response.json();
    let ruleFound = false;

    data.data.groups.forEach((group) => {
      group.rules.forEach((rule) => {
        if (rule.name === ruleName) {
          ruleFound = true;
        }
      });
    });

    const query = ruleFound ? ruleName : recordingRuleMap[ruleName];
    if (range) {
      return fetch(
        `${containerAddress}${query_range_path}${encodeURIComponent(
          query,
        )}${params}`,
      )
        .then((res) => res.json())
        .catch(() => null);
    }
    return fetch(`${containerAddress}${query_path}${encodeURIComponent(query)}`)
      .then((res) => res.json())
      .catch(() => null);
  }

  getLocalClusterUnutilizedLocalDisks(threshold) {
    const q1 = 'sum(container_fs_usage_bytes{id="/"}) by (instance)';
    const q2 =
      'sum(container_fs_limit_bytes{device!="tmpfs",id="/"}) by (instance)';
    const base = this.getCurrentContainerAddressModel() + query_path;
    const deferred = new $.Deferred();
    const diskArray = [];

    $.when(
      $.getJSON(base + encodeURIComponent(q1)),
      $.getJSON(base + encodeURIComponent(q2)),
    ).then(([qr1, qr2]) => {
      if (
        qr1 == null ||
        qr2 == null ||
        typeof qr1.data === 'undefined' ||
        typeof qr2.data === 'undefined'
      ) {
        console.warn(
          'Warning: unable to find "container_fs_usage_bytes" and/or "container_fs_limit_bytes" metrics',
        );
        deferred.resolve(diskArray);
        return;
      }
      helper.joinPromQRs([qr1], [qr2], 'instance', 'instance');

      $.each(qr1.data.result, (i, val) => {
        if (val.value[2] > MAX_FS_BYTES) {
          console.warn(
            'Warning: very large container_fs_limit_bytes result detected, ignoring...',
          );
          return;
        }

        const utilization = val.value[1] / val.value[2];

        if (utilization < threshold) {
          // assume you can save 50% of current cost estimate
          val.savings =
            helper.getHourlyStoragePrice(
              'standard',
              'us-central',
              'gcp',
              true,
            ) *
            Math.ceil(val.value[2] / 1024 / 1024 / 1024 / 5) *
            5 *
            0.5;

          diskArray.push(val);
        }
      });

      deferred.resolve(diskArray);
    });

    return deferred.promise();
  }

  getModelCurrencyPrefix(modelConfigs, shortCode) {
    let prefix = '$';

    try {
      if (modelConfigs.currencyCode === 'EUR') prefix = '\u20AC';
      else if (modelConfigs.currencyCode === 'CHF' && shortCode) prefix = '';
      else if (modelConfigs.currencyCode === 'CHF') prefix = 'CHF ';
      else if (modelConfigs.currencyCode === 'CAD') prefix = 'CA$ ';
    } catch (error) {}

    return prefix;
  }

  getMultiClusterUnutilizedLocalDisks(threshold, offset) {
    return model.configEnv().then((env) => {
      const clusterIDLabel = env.promClusterIDLabel;
      const q1 = `sum(container_fs_usage_bytes{id="/"} ${offset}) by (instance, ${clusterIDLabel})`;
      const q2 = `sum(container_fs_limit_bytes{device!="tmpfs",id="/"} ${offset}) by (instance, ${clusterIDLabel})`;
      const base = this.getCurrentContainerAddressModel() + thanos_query_path;
      const deferred = new $.Deferred();
      const diskArray = [];

      $.when(
        $.getJSON(base + encodeURIComponent(q1)),
        $.getJSON(base + encodeURIComponent(q2)),
      ).then(([qr1, qr2]) => {
        if (
          qr1 == null ||
          qr2 == null ||
          typeof qr1.data === 'undefined' ||
          typeof qr2.data === 'undefined'
        ) {
          console.warn(
            'Warning: unable to find "container_fs_usage_bytes" and/or "container_fs_limit_bytes" metrics',
          );
          deferred.resolve(diskArray);
          return;
        }

        helper.joinPromQsMultipleKeys([qr1], [qr2], 'instance', clusterIDLabel);

        $.each(qr1.data.result, (i, val) => {
          if (val.value[2] > MAX_FS_BYTES) {
            console.warn(
              'Warning: very large container_fs_limit_bytes result detected, ignoring...',
            );
            return;
          }

          const utilization = val.value[1] / val.value[2];

          if (utilization < threshold) {
            // assume you can save 50% of current cost estimate
            val.savings =
              helper.getHourlyStoragePrice(
                'standard',
                'us-central',
                'gcp',
                true,
              ) *
              Math.ceil(val.value[2] / 1024 / 1024 / 1024 / 5) *
              5 *
              0.5;

            diskArray.push(val);
          }
        });

        deferred.resolve(diskArray);
      });

      return deferred.promise();
    });
  }

  getNodePrice(np, nodeName, modelConfigs, cpus, GbRam, usageType) {
    const hourlyCPUPrice =
      usageType === 'spot' ? modelConfigs.spotCPU : modelConfigs.CPU;
    const hourlyRAMPrice =
      usageType === 'spot' ? modelConfigs.spotRAM : modelConfigs.RAM;
    let hourlyPrice = cpus * hourlyCPUPrice + GbRam * hourlyRAMPrice;
    let discount = helper.getRemoteDiscount(modelConfigs);

    if (
      np === null ||
      typeof np.data === 'undefined' ||
      typeof np.data.result === 'undefined'
    ) {
      console.warn('Warning: unable to get node prices');
    } else {
      np.data.result.forEach((node) => {
        const currentName = node.metric.instance;
        if (currentName === nodeName && typeof node.value === 'object') {
          hourlyPrice = node.value[1];
        }
      });
    }

    // no discounts for spot or preemptible
    if (usageType !== 'OnDemand') {
      discount = 0;
    }

    return hourlyPrice * (1 - discount);
  }

  getNodeRamCapacityGiBytes(node) {
    let capacityAmt = node;

    if (typeof node.status !== 'undefined') {
      const capacityStr = node.status.capacity.memory;

      if (
        typeof capacityStr === 'string' &&
        capacityStr.toLowerCase().includes('mi')
      )
        capacityAmt = parseInt(capacityStr, 10) / 1024;
      else capacityAmt = parseInt(capacityStr, 10) / 1024 / 1024;
    }

    capacityAmt = Math.ceil(parseFloat(capacityAmt) * 20) / 20;

    return capacityAmt;
  }

  getQueryRangeParams(timeWindowDays, stepDuration, stepDurationUnits) {
    const end = new Date();
    const start = new Date(
      end.getFullYear(),
      end.getMonth(),
      end.getDate() - timeWindowDays,
    );

    let params = `&start=${encodeURIComponent(start.toISOString())}`;
    params += `&end=${encodeURIComponent(end.toISOString())}`;
    params += `&duration=${stepDuration + stepDurationUnits}`;
    params += `&window=${stepDuration + stepDurationUnits}`; // support both window and duration as the param for legacy

    return params;
  }

  async getReservedRec(timeWindowDays) {
    const query_path = '/prometheusQuery?query=';
    const query_range_path = '/prometheusQueryRange?query=';
    const containerAddress = this.getCurrentContainerAddressModel();
    const q1 =
      'sum(kube_pod_container_resource_requests{resource="memory", unit="byte"}) / 1024 / 1024 / 1024';
    const q2 =
      'sum(node_memory_MemTotal_bytes - node_memory_MemFree_bytes - node_memory_Cached_bytes - node_memory_Buffers_bytes) / 1024 / 1024 / 1024';
    const q3 =
      'sum(kube_pod_container_resource_requests{resource="cpu", unit="core"})';
    const q4 = 'cluster:cpu_usage:rate5m';

    const base = containerAddress + query_path;
    const rangeBase = containerAddress + query_range_path;
    const params = this.getQueryRangeParams(timeWindowDays, 30, 'm');

    const q1Promise = fetch(`${base}${encodeURIComponent(q1)}`).then((resp) =>
      resp.json(),
    );
    const q2Promise = fetch(
      `${rangeBase}${encodeURIComponent(q2)}${params}`,
    ).then((response) => response.json());
    const q3Promise = fetch(`${base}${encodeURIComponent(q3)}`).then(
      (response) => response.json(),
    );
    const q4Promise = this.getDataFromRecordingRule(q4, true, params);

    return Promise.all([q1Promise, q2Promise, q3Promise, q4Promise]).then(
      ([qr1, qr2, qr3, qr4]) => {
        if (
          typeof qr1.data === 'undefined' ||
          typeof qr1.data.result === 'undefined' ||
          qr1.data.result.length < 1 ||
          typeof qr2.data === 'undefined' ||
          typeof qr3.data === 'undefined' ||
          qr4 === null ||
          typeof qr4.data === 'undefined'
        ) {
          console.warn(
            'Warning: unable to return reserved instance recommendations',
          );
          return { totalMemoryFloor: 0, totalCPUFloor: 0, savings: 0 };
        }

        const memoryRequests = qr1.data.result[0].value[1];
        let memoryUsageLowPoint = null;

        if (
          typeof qr2.data !== 'undefined' &&
          qr2.data.result.length > 0 &&
          qr2.data.result[0].values.length > 0
        ) {
          qr2.data.result[0].values.forEach((val) => {
            const memUsage = parseFloat(val[1]);
            if (
              memUsage < memoryUsageLowPoint ||
              memoryUsageLowPoint === null
            ) {
              memoryUsageLowPoint = memUsage;
            }
          });
        } else
          console.warn(
            'Warning: node exporter metrics may be missing or data may not be available yet.',
          );

        let memoryCapacityFloor = Math.max(
          memoryUsageLowPoint,
          Math.min(memoryRequests, memoryUsageLowPoint),
        );
        memoryCapacityFloor = Math.ceil(memoryCapacityFloor);

        const cpuRequests = qr3.data.result[0].value[1];
        let cpuUsageLowPoint = 0;
        const cpuUsageArray = [];

        if (typeof qr4.data.result[0] !== 'undefined') {
          qr4.data.result[0].values.forEach((val) => {
            cpuUsageArray.push(parseFloat(val[1]));
          });
        }

        cpuUsageArray.sort((a, b) => a - b);
        if (cpuUsageArray.length > 0) {
          const index = Math.ceil(
            Math.min(cpuUsageArray.length - 1, cpuUsageArray.length * 0.2),
          );
          cpuUsageLowPoint = cpuUsageArray[index];
        }

        let cpuCapacityFloor = Math.max(
          cpuUsageLowPoint,
          Math.min(cpuRequests, cpuUsageLowPoint),
        );
        cpuCapacityFloor = Math.ceil(cpuCapacityFloor);

        const DEFAULT_RAM_PRICE = 0.004237;
        const DEFAULT_CPU_PRICE = 0.031611;

        const savings =
          memoryCapacityFloor * 0.3 * DEFAULT_RAM_PRICE * 730 +
          cpuCapacityFloor * 0.3 * DEFAULT_CPU_PRICE * 730;
        return {
          totalMemoryFloor: memoryCapacityFloor,
          totalCPUFloor: cpuCapacityFloor,
          savings,
        };
      },
    );
  }

  async getSavingsSummaryPromise() {
    const url = `${this.getCurrentContainerAddressModel()}/savings`;
    return fetch(url).then((response) => response.json());
  }

  getUnutilizedLocalDisks(threshold, clusterInfo) {
    let thanosEnabled = false;
    let thanosOffset = 'offset 3h';
    if (clusterInfo.thanosEnabled) {
      thanosEnabled = clusterInfo.thanosEnabled === 'true';
    } else {
      thanosEnabled = clusterInfo.thanosOffset !== undefined;
    }

    if (thanosEnabled) {
      if (clusterInfo.thanosOffset !== '') {
        thanosOffset = `offset ${clusterInfo.thanosOffset}`;
      }
    }

    if (thanosEnabled) {
      return this.getMultiClusterUnutilizedLocalDisks(threshold, thanosOffset);
    }

    return this.getLocalClusterUnutilizedLocalDisks(threshold);
  }
}

export default new HelperDumpService();
