import get from 'lodash/get';
import moment from 'moment';
import Logger from './logger';
import model from './model';
import prometheus from './prometheus';

type FileSize = {
  value: number;
  unit: string;
};

// Splits numerical size and unit
const toSize = (s: string): FileSize => {
  const result = /([0-9.]+)/g.exec(s);
  if (!result || result.length === 0) {
    return { value: 0.0, unit: 'B' };
  }

  const valueStr = result[0];
  const value = parseFloat(valueStr);
  const unit = s.substring(valueStr.length);

  return { value, unit };
};

// Coverts an RFC339 date string to a simplified DOTW, DAY, MON, YEAR
const toSimpleDate = (dateString: string) =>
  moment(dateString).utc().format('ddd MMM DD YYYY');

// Converts RFC339 date string to the format Month Day Year, 12h Time
const toDateTime = (dateString: string) => moment(dateString).format('LLL');

export type ETLFile = {
  name: string;
  size: FileSize;
  date: string;
  lastModified: string;
  isRepairing: boolean;
  errors: Array<string>;
  warnings: Array<string>;
  empty: boolean;
};

// creates a simplified file object of the returned etl file
const toETLFile = (file: any): ETLFile => {
  const { name } = file;
  const fileSize = file.size;
  const { window } = file.details;
  const fileDate = toSimpleDate(window.substring(1, window.indexOf(',')));
  const lastModified = toDateTime(file.lastModified);
  const { isRepairing } = file;

  const s = toSize(fileSize);
  const errors = get(file, 'errors', []);
  const warnings = get(file, 'warnings', []);
  let empty = s.unit === 'B' && s.value < 200.0;

  // If we have errors or warnings, it's not empty
  if (errors.length > 0 || warnings.length > 0) {
    empty = false;
  }

  return {
    name,
    size: fileSize,
    date: fileDate,
    lastModified,
    isRepairing,
    errors,
    warnings,
    empty,
  };
};

export type ETLStatus = {
  start: string;
  end: string;
  progress: string;
  refreshRate: string;
  path: string;
  size: FileSize;
  files: Array<ETLFile>;
};

// creates a simplified breakdown for etl status
const toETLStatus = (data: any): ETLStatus => {
  const start = toSimpleDate(data.coverage.start);
  const end = toSimpleDate(data.coverage.end);
  const progress = `${Math.round(data.progress * 100.0)}%`;
  const { refreshRate } = data;
  const { path } = data.backup;
  const { size } = data.backup;
  const files = data.backup.files.map(toETLFile);

  return {
    start,
    end,
    progress,
    refreshRate,
    path,
    size,
    files,
  };
};

export type ETLCloudProcessStatus = {
  coverage: {
    start: string;
    end: string;
  };
  progress: number;
  refreshRate: string;
  resolution: string;
  lastRun: string;
  nextRun: string;
  startTime: string;
};

// creates a simplified breakdown for etl cloud status
const toETLCloudStatus = (data: any): ETLCloudProcessStatus => {
  const start = toSimpleDate(data.coverage.start);
  const end = toSimpleDate(data.coverage.end);
  const progress = `${Math.round(data.progress * 100.0)}%`;
  const { refreshRate } = data;
  const { resolution } = data;
  const lastRun = toDateTime(data.lastRun);
  const nextRun = toDateTime(data.nextRun);
  const startTime = toDateTime(data.startTime);

  return {
    coverage: {
      start,
      end,
    },
    progress,
    refreshRate,
    resolution,
    lastRun,
    nextRun,
    startTime,
  };
};

export type PrometheusJob = {
  up: number;
  targets: Array<PrometheusTarget>;
};

export type PrometheusTarget = {
  labels: string;
  health: string;
};

type Cluster = {
  id: string;
  profile: string;
};

export type ETLCloudStatus = {
  cloudConnectionStatus: string;
  reconciliation: ETLCloudProcessStatus;
  cloudUsage: ETLCloudProcessStatus;
  providerType: string;
};

export type ETLDiagnostic = {
  allocation: Record<string, ETLStatus>;
  asset: Record<string, ETLStatus>;
  cloud: Record<string, ETLCloudStatus>;
};

export type NodeCount = {
  max: number;
  avg: number;
};

export type PrometheusMetric = {
  id: string;
  query: string;
  label: string;
  description: string;
  docLink: string;
  result: Array<unknown>;
  passed: boolean;
};

export type PrometheusRequest = {
  context: string;
  query: string;
  queueTime: number;
};

export type RequestQueueStatus = {
  queuedRequests: Array<PrometheusRequest>;
  outboundRequests: number;
  totalRequests: number;
  maxQueryConcurrency: number;
};

// Pricing Source Names
export const ReservedInstanceName =
  'Savings Plan, Reserved Instance, and Out-Of-Cluster';
export const SpotFeedName = 'Spot Data Feed';
export const RateCardName = 'Rate Card API';

export type PricingSourceStatus = {
  name: string;
  enabled: boolean;
  available: boolean;
  error: string;
  config: Record<string, string | undefined>;
};

// exported diagnostics object
const diagnostics = {
  // Gets the prometheus targets by job.
  prometheusTargets: async (): Promise<Record<string, PrometheusJob>> => {
    const byJob: Record<string, PrometheusJob> = {};

    try {
      const promTargets = await prometheus.targets();
      if (promTargets.status === 'error') {
        return { error: promTargets.message };
      }

      const active = promTargets.data.activeTargets;

      for (let i = 0; i < active.length; ++i) {
        const target = active[i];
        const { job } = target.labels;
        const isUp = target.health === 'up';

        if (byJob[job] === undefined) {
          byJob[job] = {
            up: 1,
            targets: [target],
          };
        } else {
          byJob[job].targets.push(target);
          if (isUp) {
            byJob[job].up += 1;
          }
        }
      }
    } catch (err) {
      Logger.log(`Error Retrieving Prometheus Targets: ${err}`);
    }

    return byJob;
  },

  // Get Prom metrics for Prometheus and Thanos
  prometheusMetrics: async (): Promise<
    Record<string, Array<PrometheusMetric>>
  > => {
    const promMetricsResult = await model.diagnosticsPrometheusMetrics();
    const promMetricsData = promMetricsResult.data;
    const promMetrics: Array<PrometheusMetric> = promMetricsData.prometheus
      ? promMetricsData.prometheus
      : [];
    const thanMetrics: Array<PrometheusMetric> = promMetricsData.thanos
      ? promMetricsData.thanos
      : [];
    return { promMetrics, thanMetrics };
  },

  requestQueue: async (): Promise<
    Record<string, RequestQueueStatus | undefined>
  > => {
    const requestQueueResult = await model.diagnosticsRequestQueue();
    const requestQueueData = requestQueueResult.data;
    const promRequestQueue = requestQueueData.prometheus
      ? requestQueueData.prometheus
      : undefined;
    const thanRequestQueue = requestQueueData.thanos
      ? requestQueueData.thanos
      : undefined;
    return { promRequestQueue, thanRequestQueue };
  },

  nodeCount: async (): Promise<NodeCount> => {
    const result = await model.nodeCount('7d');
    const nodeCount = <NodeCount>{};
    nodeCount.max = result.max;
    nodeCount.avg = result.avg;
    return nodeCount;
  },

  // Savings Diagnostics
  savingsDiagnostics: async (): Promise<{
    abandonedWorkloads: {
      error: string;
      windows: string[];
    };
    clusterSizing: {
      clusters: Cluster[];
      error: string;
    };
    requestSizing: {
      error: string;
      profiles: string[];
    };
  }> => {
    const ClusterSizingKey = 'ClusterSizing';
    const AbandonedWorkloadsKey = 'AbandonedWorkloads';
    const RequestSizingKey = 'RequestSizing';

    const result = await model.savingsDiagnostics();
    let cacheKeys = get(result, 'cacheKeys', []);
    if (!Array.isArray(cacheKeys)) {
      cacheKeys = [];
    }

    // layout the data more cleanly for react view
    const diag = {
      abandonedWorkloads: {
        windows: new Array<string>(),
        error: get(result, 'abandonedWorkloadsError', ''),
      },
      requestSizing: {
        profiles: new Array<string>(),
        error: get(result, 'requestSizingError', ''),
      },
      clusterSizing: {
        clusters: new Array<Cluster>(),
        error: get(result, 'clusterSizingError', ''),
      },
    };

    // parse cache keys by their "type" to grab a bit more information about the
    // cached data.

    // NOTE: (bolt) this is pretty hacky, as the key format could really change. The errors
    // NOTE: will always propagate, which is the priority, so we'll just try our best with key,
    // NOTE: and if it fails to parse, then continue on.
    cacheKeys.forEach((k: string) => {
      const s = k.split(':');
      if (s.length > 0) {
        switch (s[0]) {
          case ClusterSizingKey: {
            if (s.length !== 3) {
              break;
            }

            diag.clusterSizing.clusters.push({
              id: s[1],
              profile: s[2],
            });
            break;
          }

          case RequestSizingKey: {
            if (s.length !== 2) {
              break;
            }

            diag.requestSizing.profiles.push(s[1]);
            break;
          }

          case AbandonedWorkloadsKey: {
            if (s.length !== 2) {
              break;
            }

            diag.abandonedWorkloads.windows.push(`${s[1]}d`);
            break;
          }
          default: {
            Logger.log(`Unknown Savings Cache Key: ${s[0]}`);
            break;
          }
        }
      }
    });
    return diag;
  },

  // Pricing Source Diagnostics
  pricingSources: async (): Promise<Array<PricingSourceStatus>> => {
    const pricingSourceStatus = await model.pricingSourceStatus();
    const configs = await model.getConfigs();

    const keys = Object.keys(pricingSourceStatus);

    const pricingSources: Array<PricingSourceStatus> = [];
    for (let i = 0; i < keys.length; ++i) {
      const r: PricingSourceStatus = pricingSourceStatus[keys[i]];
      r.config = {};
      if (r.name === ReservedInstanceName) {
        r.config.athenaBucketName = configs.athenaBucketName;
        r.config.athenaDatabase = configs.athenaDatabase;
        r.config.athenaProjectID = configs.athenaProjectID;
        r.config.athenaRegion = configs.athenaRegion;
      } else if (r.name === SpotFeedName) {
        if (r.enabled) {
          r.config.awsSpotDataBucket = configs.awsSpotDataBucket;
          r.config.awsSpotDataRegion = configs.awsSpotDataRegion;
          r.config.projectID = configs.projectID;
        }
      } else if (r.name === RateCardName) {
        r.config.azureSubscriptionID = configs.azureSubscriptionID;
        r.config.azureClientID = configs.azureClientID;
        r.config.azureClientSecret = configs.azureClientSecret;
        r.config.azureTenantID = configs.azureTenantID;
        r.config.azureBillingRegion = configs.azureBillingRegion;
      }

      pricingSources.push(r);
    }

    return pricingSources;
  },

  etlStatus: async (): Promise<ETLDiagnostic> => {
    const data = await model.etlStatus();

    const etlDiagnostic = <ETLDiagnostic>{};

    if (data.allocation) {
      etlDiagnostic.allocation = {};
      Object.keys(data.allocation).forEach((dur) => {
        etlDiagnostic.allocation[dur] = toETLStatus(data.allocation[dur]);
      });
    }

    if (data.asset) {
      etlDiagnostic.asset = {};
      Object.keys(data.asset).forEach((dur) => {
        etlDiagnostic.asset[dur] = toETLStatus(data.asset[dur]);
      });
    }

    if (data.cloud) {
      etlDiagnostic.cloud = {};
      Object.keys(data.cloud).forEach((providerKey) => {
        const cloudConnection = data.cloud[providerKey];
        if (cloudConnection) {
          const etlCloudStatus: ETLCloudStatus = <ETLCloudStatus>{};
          if (cloudConnection.cloudUsage) {
            etlCloudStatus.cloudUsage = toETLCloudStatus(
              cloudConnection.cloudUsage,
            );
          }
          if (cloudConnection.reconciliation) {
            etlCloudStatus.reconciliation = toETLCloudStatus(
              cloudConnection.reconciliation,
            );
          }
          etlCloudStatus.cloudConnectionStatus =
            cloudConnection.cloudConnectionStatus;
          etlDiagnostic.cloud[providerKey] = etlCloudStatus;

          etlCloudStatus.providerType = cloudConnection.providerType;
        }
      });
    }
    return etlDiagnostic;
  },
};

export default diagnostics;
