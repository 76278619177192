import { Card, CardContent, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles({
  messageBox: {
    maxHeight: '50px',
    borderRadius: '8px',
  },
  messageBoxInfo: {
    margin: '0 16px 0 0',
    verticalAlign: 'middle',
    fontSize: '32px',
    color: '#F4B400',
  },
  messageBoxContents: {
    padding: '8px 16px',
    whiteSpace: 'nowrap',
    display: 'flex',
  },
  messageBoxText: {
    fontSize: 16,
    fontWeight: 400,
    margin: '3px',
  },
});

const MessageBox = (props) => {
  const classes = useStyles();

  return (
    <div style={{ padding: '8px 0' }}>
      <Card className={classes.messageBox}>
        <CardContent className={classes.messageBoxContents}>
          <InfoIcon className={classes.messageBoxInfo} />
          <Typography variant="subtitle1" className={classes.messageBoxText}>
            {props.children}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default MessageBox;
