// React
import React, { FC } from 'react';

//Holster
import { Modal } from '@kubecost-frontend/holster';

// recharts
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { accents, designSystemColorMap } from '../../services/colors';
import { mebibyteFactor, recCurrency } from './utils';
import { Typography } from '@material-ui/core';

interface RequestSizingDrilldownProps {
  drilldownData: any;
  currency: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
}

export const RequestSizingDrilldown: FC<RequestSizingDrilldownProps> = ({
  drilldownData,
  currency,
  open,
  setOpen,
  title,
}) => {
  const savingsFigure = recCurrency(
    drilldownData.recommendation ? drilldownData.recommendation : null,
    currency,
  );
  return (
    <>
      <Modal title={title} onClose={() => setOpen(false)} open={open}>
        <div>
          <Typography variant="subtitle1">
            Estimated Savings: {savingsFigure}
          </Typography>
        </div>
        <main className="mt-4">
          {drilldownData && drilldownData.recommendation && (
            <div className="flex mb-6 gap-6">
              <div>
                <p>Cluster</p>
                <p className="font-bold">
                  {drilldownData.recommendation.clusterID}
                </p>
              </div>
              <div>
                <p>Namespace</p>
                <p className="font-bold">
                  {drilldownData.recommendation.namespace}
                </p>
              </div>
              <div>
                <p>Type</p>
                <p className="font-bold">
                  {drilldownData.recommendation.controllerKind}
                </p>
              </div>
              <div>
                <p>Controller Name</p>
                <p className="font-bold">
                  {drilldownData.recommendation.controllerName}
                </p>
              </div>
              <div>
                <p>Container</p>
                <p className="font-bold">
                  {drilldownData.recommendation.containerName}
                </p>
              </div>
            </div>
          )}
          <div className="font-bold mb-4">RAM Requests</div>
          <ResponsiveContainer height={250} width="100%" id="ram-overview">
            <LineChart
              data={drilldownData.ramDrillDownData}
              margin={{
                top: 0,
                bottom: 10,
                left: 25,
                right: 0,
              }}
            >
              <CartesianGrid vertical={false} />
              <Legend verticalAlign="bottom" />
              <XAxis dataKey="time" />
              <YAxis
                tickFormatter={(tick) =>
                  (tick / mebibyteFactor).toFixed() + 'Mi'
                }
                interval={'preserveStartEnd'}
                allowDataOverflow
                scale="linear"
                domain={[
                  (dataMin: number) => Math.min(0, dataMin),
                  (dataMax: number) => dataMax * 1.5,
                ]}
              />
              <Line
                dataKey="currentRequest"
                name="Current Request"
                dot={false}
                type="monotone"
                stroke={accents[4]}
                strokeWidth={3}
              />
              <Line
                dataKey="recommendation"
                name="Recommendation"
                dot={false}
                type="monotone"
                stroke={designSystemColorMap.primary}
                strokeWidth={3}
              />

              <Line
                dataKey="dailyAvg"
                name="Daily Average"
                dot={false}
                type="monotone"
                strokeWidth={3}
                stroke={accents[3]}
                strokeDasharray="4"
              />
              <Tooltip
                formatter={(value: any) =>
                  (value / mebibyteFactor).toFixed(2) + 'Mi'
                }
              />
            </LineChart>
          </ResponsiveContainer>
          <div className="font-bold mb-4 mt-4 p-4">CPU Requests</div>
          <ResponsiveContainer height={250} width="100%" id="cpu-overview">
            <LineChart
              data={drilldownData.cpuDrillDownData}
              margin={{
                top: 0,
                bottom: 10,
                left: 20,
                right: 0,
              }}
            >
              <CartesianGrid vertical={false} />
              <Legend verticalAlign="bottom" />
              <XAxis dataKey="time" />
              <YAxis
                tickFormatter={(tick) => tick + 'm'}
                interval={'preserveStartEnd'}
                allowDataOverflow
                scale="linear"
                domain={[
                  (dataMin: number) => Math.min(0, dataMin),
                  (dataMax: number) => dataMax * 1.5,
                ]}
              />
              <Line
                dataKey="currentRequest"
                name="Current Request"
                dot={false}
                type="monotone"
                strokeWidth={3}
                stroke={accents[4]}
              />
              <Line
                dataKey="recommendation"
                name="Recommendation"
                dot={false}
                type="monotone"
                strokeWidth={3}
                stroke={designSystemColorMap.primary}
              />

              <Line
                dataKey="dailyAvg"
                name="Daily Average"
                dot={false}
                type="monotone"
                stroke={accents[3]}
                strokeWidth={3}
                strokeDasharray="4"
              />
              <Tooltip formatter={(value: any) => value + 'm'} />
            </LineChart>
          </ResponsiveContainer>
        </main>
      </Modal>
    </>
  );
};
