import React from 'react';

import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import NotInterestedOutlinedIcon from '@material-ui/icons/NotInterestedOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import { green, yellow, red, grey } from '@material-ui/core/colors';

import { SpotReady } from '../../services/savings/spot';

interface IconProps {
  readiness: SpotReady;
}

function ReadyIcon({ readiness }: IconProps): React.ReactElement {
  if (readiness === SpotReady.No) {
    return <ErrorOutlineOutlinedIcon style={{ color: red[700] }} />;
  }
  if (readiness === SpotReady.Maybe) {
    return <HelpOutlineOutlinedIcon style={{ color: yellow[700] }} />;
  }
  if (readiness === SpotReady.NA) {
    return <NotInterestedOutlinedIcon style={{ color: grey[700] }} />;
  }
  if (readiness === SpotReady.Yes) {
    return <CheckCircleOutlinedIcon style={{ color: green[700] }} />;
  }

  return <ReportProblemOutlinedIcon style={{ color: yellow[700] }} />;
}

export default React.memo(ReadyIcon);
