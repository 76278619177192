/* eslint-disable react/require-default-props */
import React, { FC } from "react";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box } from "@material-ui/core";
import { AlertTitle } from "@material-ui/lab";
import { grey } from "@material-ui/core/colors";

interface ErrorFalbackProps {
  error?: boolean;
  loading?: boolean;
  errorMessage?: string;
}

export const ErrorFallback: FC<ErrorFalbackProps> = ({
  errorMessage = "Whoops! Something went wrong. Refreshing your browser should get things back to normal.",
  loading = false,
  error = true,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        height: "100vh",
        margin: "auto",
        width: "100vw",
      }}
    >
      {error && !loading ? (
        <>
          <Alert
            icon={false}
            style={{
              alignContent: "center",
              alignItems: "center",
              height: "35vh",
              width: "100vw",
              justifyContent: "center",
              backgroundColor: `${grey[500]}`,
              color: "#FFFFFF",
            }}
          >
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        </>
      ) : (
        <CircularProgress color="secondary" />
      )}
    </Box>
  );
};

ErrorFallback.displayName = "ErrorFallback";
