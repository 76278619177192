// react
import React, { FC } from 'react';

// mui
import Typography from '@material-ui/core/Typography';

// local
import {
  TableResults,
  TimeSeriesGraph,
  TopResultsGraph,
} from '../../../services/model';
import CategoryChart from './CategoryChart';
import RangeChart from './RangeChart';
import SummaryChart from './SummaryChart';
import TreemapChart from './TreeMapChart';
import LineChart from './LineChart';

interface AllocationChartProps {
  aggregateBy: string[];
  allocationRows: TableResults[];
  chartDisplay: 'category' | 'efficiency' | 'percentage' | 'series' | 'treemap';
  drillDownCompatible: string[];
  drillDownExemptRows: string[];
  drillDownFactory: (row: TableResults) => () => void;
  graphData: TimeSeriesGraph | TopResultsGraph;
  height: number;
}

const AllocationChart: FC<AllocationChartProps> = ({
  height,
  chartDisplay,
  aggregateBy,
  allocationRows,
  drillDownCompatible,
  drillDownExemptRows,
  graphData,
  drillDownFactory,
}) => {
  if (!graphData) {
    return <Typography variant="body2">No data</Typography>;
  }

  if (chartDisplay === 'category') {
    if (!graphData.items) {
      return <Typography variant="body2">No data</Typography>;
    }
    return (
      <CategoryChart height={height} data={graphData as TopResultsGraph} />
    );
  }

  if (chartDisplay === 'treemap') {
    if (!graphData.items) {
      return <Typography variant="body2">No data</Typography>;
    }
    return (
      <TreemapChart
        height={2 * height} // For clarity, double heigh from barcharts
        data={graphData as TopResultsGraph}
        canDrillDown={
          aggregateBy.length === 1 &&
          drillDownCompatible.includes(aggregateBy[0])
        }
        drillDownExemptRows={drillDownExemptRows}
        allocationRows={allocationRows}
        drillDownFactory={drillDownFactory}
      />
    );
  }

  if (chartDisplay === 'percentage') {
    if (!graphData.items) {
      return <Typography variant="body2">No data</Typography>;
    }
    return <SummaryChart data={graphData as TopResultsGraph} height={height} />;
  }

  if (chartDisplay === 'efficiency') {
    if (!graphData.graphItems) {
      return <Typography variant="body2">No data</Typography>;
    }
    return <LineChart height={height} data={graphData as TimeSeriesGraph} />;
  }

  if (chartDisplay === 'series') {
    if (!graphData.graphItems) {
      return <Typography variant="body2">No data</Typography>;
    }
    return <RangeChart height={height} data={graphData as TimeSeriesGraph} />;
  }

  return <RangeChart height={height} data={graphData as TimeSeriesGraph} />;
};

export default React.memo(AllocationChart);
