import React, { useEffect, useState } from 'react';
// local
import { bytesToString } from '../../services/format';
import { toCurrency } from '../../services/format';
import model from '../../services/model';
// MUI components
import { CardActions, Chip, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
// MUI styles
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    padding: '2em',
    display: 'grid',
    gridTemplateRows: '30px 1fr 30px',
  },
  totalRow: {
    fontWeight: 500,
  },
});

interface NetworkCostsTileProps {
  resourceName: string;
  currency: string;
}

export const NetworkCostsTile = ({
  resourceName,
  currency,
}: NetworkCostsTileProps) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [secondaryData, setSecondaryData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  if (!data || data.length === 0) {
    if (!secondaryData || secondaryData.length === 0) {
      const title = 'Network Traffic allocation not configured.';
      const buttonText = 'Visit Documentation';
      const linkHref =
        'https://guide.kubecost.com/hc/en-us/articles/4407595973527-Network-Traffic-Cost-Allocation';

      return (
        <Card
          className={classes.root}
          style={{
            backgroundColor: '#ececec',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <div>
            <CardHeader title={title} />
            <CardContent>
              <Link target="_blank" href={linkHref}>
                <Button variant="contained" color="primary" size="large">
                  {buttonText}
                </Button>
              </Link>
            </CardContent>
          </div>
        </Card>
      );
    } else {
      const buttonText = 'Visit Full Network Page';
      const linkHref = `./network.html?window=7d&ns=${resourceName}`;
      return (
        <Card
          className={classes.root}
          style={{
            backgroundColor: '#ececec',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <div>
            <CardContent>
              <Link href={linkHref}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ marginTop: 20 }}
                >
                  {buttonText}
                </Button>
              </Link>
            </CardContent>
          </div>
        </Card>
      );
    }
  }

  const buttonText = 'Visit Full Network Page';
  const linkHref = `./network.html?window=7d&ns=${resourceName}`;

  return (
    <Card className={classes.root}>
      <Typography component="div" variant="h6">
        Network Traffic
      </Typography>
      <CardContent>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Service</TableCell>
                <TableCell>Egress</TableCell>
                <TableCell>Cost (7d)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(({ service, egressTotal, cost }: any) => (
                <TableRow key={service}>
                  <TableCell>
                    <Chip label={service} />
                  </TableCell>
                  <TableCell>{bytesToString(egressTotal)}</TableCell>
                  <TableCell>{toCurrency(cost, currency)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <CardActions>
        <Link style={{ marginLeft: 'auto' }} href={linkHref}>
          <Button
            style={{ color: '#2196f3' }}
            endIcon={<KeyboardArrowRightIcon />}
          >
            {buttonText}
          </Button>
        </Link>
      </CardActions>
    </Card>
  );
  // checking to see if enabled
  // need some timeout here if prom queries take too long
  async function fetchData() {
    const egressBytesTotalQuery = `sort_desc(sum(increase(kubecost_pod_network_egress_bytes_total{service!="", namespace="${resourceName}"}[7d])) by (service)) `;
    const averageNetworkCostQuery = `avg(kubecost_network_internet_egress_cost)`;
    const internetTrafficQuery = `sum(increase(kubecost_pod_network_egress_bytes_total{namespace="${resourceName}"}[7d])) by (namespace,pod_name) / 1024 / 1024 / 1024`;
    const [
      egressBytesTotalResponse,
      averageNetworkCostsResponse,
      internetTrafficQueryResponse,
    ] = await Promise.all([
      model.prometheusQuery(egressBytesTotalQuery),
      model.prometheusQuery(averageNetworkCostQuery),
      model.prometheusQuery(internetTrafficQuery),
    ]);

    const averageByteCost = parseFloat(
      averageNetworkCostsResponse.data.result[0].value[1],
    );
    const egressBytesArray = egressBytesTotalResponse.data.result.map(
      (item: any) => ({
        service: item.metric.service,
        egressTotal: item.value[1],
        cost: (item.value[1] / 1024 / 1024 / 1024) * averageByteCost,
      }),
    );
    // only show top5
    setData(egressBytesArray.slice(0, 5));
    setSecondaryData(internetTrafficQueryResponse.data.result);
  }
};
