import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import StarIcon from '@material-ui/icons/Star';
import RAMIcon from '@material-ui/icons/Memory';
import CPUIcon from '@material-ui/icons/DeveloperBoard';
import CostIcon from '@material-ui/icons/AttachMoney';
import WarningIcon from '@material-ui/icons/Warning';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import round from 'lodash/round';
import { toCurrency } from '../../services/format';
import AdoptDialog from './AdoptDialog';

const useStyles = makeStyles({
  table: {
    paddingLeft: '12px',
  },
  accordionSummarySpacing: {
    justifyContent: 'space-between',
    minWidth: '220px',
  },
  chip: {
    marginRight: 8,
  },
  cardActions: {
    justifyContent: 'center',
  },
});

const RecommendationTable = ({
  recommendations,
  currentClusterInformation,
  negotiatedDiscount,
  status,
  ready,
  setup,
  showActions,
  adoptRecommendation,
  currency,
}: RecommendationTableProps): React.ReactElement | null => {
  const classes = useStyles();

  const [isExpandedCost, setIsExpandedCost] = React.useState(false);
  const [isExpandedCPU, setIsExpandedCPU] = React.useState(false);
  const [isExpandedRAM, setIsExpandedRAM] = React.useState(false);

  return (
    <TableContainer
      style={{ padding: '12px', borderBottom: 'unset' }}
      component={Paper}
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell align="center" style={{ color: grey[800] }}>
              Current
            </TableCell>
            {recommendations.map(
              (recommendation: Recommendation, key: number) => (
                <TableCell align="center">
                  <div style={{ display: 'flex' }}>
                    <div style={{ flexGrow: 1 }}>{recommendation.strategy}</div>
                    {key === 0 && (
                      <Tooltip title="Kubecost's recommended solution">
                        <StarIcon style={{ color: green[700] }} />
                      </Tooltip>
                    )}
                  </div>
                </TableCell>
              ),
            )}
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell padding="checkbox">
              <IconButton onClick={() => setIsExpandedCost(!isExpandedCost)}>
                {isExpandedCost ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </TableCell>
            <TableCell align="left" variant="head">
              Total cost
            </TableCell>
            <TableCell align="center">
              <Typography font-size="medium">
                {toCurrency(currentClusterInformation.monthlyRate, currency)}/mo
              </Typography>
            </TableCell>
            {recommendations.map((recommendation: Recommendation) => (
              <TableCell align="center">
                <Typography
                  font-size="medium"
                  style={{ fontWeight: 'bold', color: green[700] }}
                >
                  {toCurrency(recommendation.totalMonthlyCost, currency)}/mo
                </Typography>
              </TableCell>
            ))}
          </TableRow>
          {isExpandedCost && (
            <TableRow style={{ borderTopStyle: 'hidden' }}>
              <TableCell />
              <TableCell align="left" variant="head">
                Savings
              </TableCell>
              <TableCell />
              {recommendations.map((recommendation: Recommendation) => {
                return recommendation.monthlySavings > 0 ? (
                  <TableCell align="center">
                    <Typography
                      font-size="medium"
                      style={{ color: green[700] }}
                    >
                      {toCurrency(recommendation.monthlySavings, currency)} (
                      {round(
                        (100 * recommendation.monthlySavings) /
                          currentClusterInformation.monthlyRate,
                        1,
                      )}
                      %)
                    </Typography>
                  </TableCell>
                ) : (
                  <TableCell align="center">
                    <Typography font-size="medium" style={{ color: grey[700] }}>
                      {toCurrency(-recommendation.monthlySavings, currency)}{' '}
                      increase (
                      {round(
                        (100 * recommendation.monthlySavings) /
                          currentClusterInformation.monthlyRate,
                        1,
                      )}
                      %)
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          )}

          <TableRow>
            <TableCell />
            <TableCell align="left" variant="head">
              Node count
            </TableCell>
            {currentClusterInformation.totalCounts ? (
              <TableCell align="center" style={{ color: grey[800] }}>
                {currentClusterInformation.totalCounts.totalNodeCount}
              </TableCell>
            ) : (
              <TableCell align="center">
                <Tooltip
                  placement="bottom"
                  title="Could not fetch current cluster information."
                >
                  <WarningIcon style={{ color: grey[800] }} />
                </Tooltip>
              </TableCell>
            )}
            {recommendations.map((recommendation: Recommendation) => (
              <TableCell align="center">{recommendation.nodeCount}</TableCell>
            ))}
          </TableRow>

          <TableRow>
            <TableCell padding="checkbox">
              <IconButton onClick={() => setIsExpandedCPU(!isExpandedCPU)}>
                {isExpandedCPU ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </TableCell>
            <TableCell align="left" variant="head">
              CPU
            </TableCell>
            {currentClusterInformation.totalCounts ? (
              <TableCell align="center" style={{ color: grey[800] }}>
                {currentClusterInformation.totalCounts.totalVCPUs} VCPUs
              </TableCell>
            ) : (
              <TableCell />
            )}
            {recommendations.map((recommendation: Recommendation) => (
              <TableCell align="center">
                {round(recommendation.totalVCPUs, 2)} VCPUs
              </TableCell>
            ))}
          </TableRow>
          {isExpandedCPU && (
            <TableRow style={{ borderTopStyle: 'hidden' }}>
              <TableCell />
              <TableCell align="left" variant="head">
                CPU utilization
              </TableCell>
              {currentClusterInformation.totalCounts ? (
                <TableCell align="center" style={{ color: grey[800] }}>
                  {round(
                    100 *
                      currentClusterInformation.totalCounts.utilizationVCPUs,
                    1,
                  )}
                  % utilized
                </TableCell>
              ) : (
                <TableCell />
              )}
              {recommendations.map((recommendation: Recommendation) => (
                <TableCell align="center">
                  {round(100 * recommendation.utilizationVCPUs, 1)}% utilized
                </TableCell>
              ))}
            </TableRow>
          )}

          <TableRow>
            <TableCell padding="checkbox">
              <IconButton onClick={() => setIsExpandedRAM(!isExpandedRAM)}>
                {isExpandedRAM ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </TableCell>
            <TableCell align="left" variant="head">
              RAM
            </TableCell>
            {currentClusterInformation.totalCounts ? (
              <TableCell align="center" style={{ color: grey[800] }}>
                {currentClusterInformation.totalCounts.totalRAMGB} GB
              </TableCell>
            ) : (
              <TableCell />
            )}
            {recommendations.map((recommendation: Recommendation) => (
              <TableCell align="center">
                {round(recommendation.totalRAMGB, 2)} GB
              </TableCell>
            ))}
          </TableRow>
          {isExpandedRAM && (
            <TableRow style={{ borderTopStyle: 'hidden' }}>
              <TableCell />
              <TableCell align="left" variant="head">
                RAM utilization
              </TableCell>
              {currentClusterInformation.totalCounts ? (
                <TableCell align="center" style={{ color: grey[800] }}>
                  {round(
                    100 *
                      currentClusterInformation.totalCounts.utilizationRAMGB,
                    1,
                  )}
                  % utilized
                </TableCell>
              ) : (
                <TableCell />
              )}
              {recommendations.map((recommendation: Recommendation) => (
                <TableCell align="center">
                  {round(100 * recommendation.utilizationRAMGB, 1)}% utilized
                </TableCell>
              ))}
            </TableRow>
          )}

          <TableRow style={{ verticalAlign: 'top' }}>
            <TableCell />
            <TableCell align="left" variant="head">
              Instance breakdown
            </TableCell>
            {currentClusterInformation.nodes ? (
              <TableCell align="center">
                {currentClusterInformation.nodes.map((node, i) => (
                  <Accordion key={i}>
                    <AccordionSummary
                      classes={{ content: classes.accordionSummarySpacing }}
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <div style={{ display: 'flex' }}>
                        <Chip
                          label={node.count}
                          size="small"
                          className={classes.chip}
                        />
                        <Typography key={i} gutterBottom>
                          {node.name}
                        </Typography>
                      </div>
                      <Chip
                        label={node.architecture === 'ARM' ? 'ARM' : 'x86'}
                        size="small"
                        className={classes.chip}
                      />
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                      <List dense>
                        <ListItem>
                          <ListItemIcon>
                            <CPUIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={`${round(node.vCPUs, 2)} VCPUs ea.`}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <RAMIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={`${round(node.RAMGB, 2)} RAM (GB) ea.`}
                          />
                        </ListItem>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </TableCell>
            ) : (
              <TableCell />
            )}
            {recommendations.map((recommendation: Recommendation) => (
              <TableCell align="center">
                {recommendation.pools.map((pool, i) => (
                  <Accordion key={i}>
                    <AccordionSummary
                      classes={{ content: classes.accordionSummarySpacing }}
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <div style={{ display: 'flex' }}>
                        <Chip
                          label={pool.count}
                          size="small"
                          className={classes.chip}
                        />
                        <Typography key={i} gutterBottom>
                          {pool.type.name}
                        </Typography>
                      </div>
                      <Chip
                        label={pool.type.architecture === 'ARM' ? 'ARM' : 'x86'}
                        size="small"
                        className={classes.chip}
                      />
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                      <List dense>
                        <ListItem>
                          <ListItemIcon>
                            <CPUIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={`${round(pool.type.vCPUs, 2)} VCPUs ea.`}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <RAMIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={`${round(
                              pool.type.RAMGB,
                              2,
                            )} RAM (GB) ea.`}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <CostIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={`${toCurrency(
                              round(
                                730.0 *
                                  (1.0 - negotiatedDiscount) *
                                  pool.type.hourlyPrice,
                                2,
                              ),
                              currency,
                            )}/mo ea.`}
                          />
                        </ListItem>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </TableCell>
            ))}
          </TableRow>

          <TableRow>
            <TableCell style={{ borderBottom: 'none' }} />
            <TableCell style={{ borderBottom: 'none' }} />
            <TableCell style={{ borderBottom: 'none' }} />
            {recommendations.map((recommendation: Recommendation) => (
              <TableCell style={{ borderBottom: 'none' }} align="center">
                {showActions && (
                  <AdoptDialog
                    recommendation={recommendation}
                    adopt={adoptRecommendation}
                    isError={status.error !== undefined}
                    isReady={ready}
                    isSetUp={setup}
                  />
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export type CurrentNodeInformation = {
  name: string;
  provider: string;
  architecture: string;
  count: number;
  RAMGB: number;
  vCPUs: number;
};

export type TotalCounts = {
  totalNodeCount: number;
  totalRAMGB: number;
  totalVCPUs: number;
  utilizationVCPUs: number;
  utilizationRAMGB: number;
};

export type CurrentClusterInformation = {
  monthlyRate: number;
  nodes: CurrentNodeInformation[];
  totalCounts: TotalCounts;
};

export type Type = {
  provider: string;
  name: string;
  vCPUs: number;
  RAMGB: number;
  hourlyPrice: number;
  spotHourlyPrice: number;
  sharedCore: boolean;
  architecture: string;
  pricePerRAMByteHr: number;
  pricePerCPUCoreHr: number;
  spotPricePerRAMByteHr: number;
  spotPricePerCPUCoreHr: number;
};

export type NodePool = {
  type: Type;
  count: number;
  totalMonthlyCost: number;
  totalRAMGB: number;
  totalVCPUs: number;
};

export type Recommendation = {
  strategy: string;
  pools: NodePool[];
  nodeCount: number;
  monthlySavings: number;
  totalMonthlyCost: number;
  requiredRAMGB: number;
  totalRAMGB: number;
  utilizationRAMGB: number;
  requiredVCPUs: number;
  totalVCPUs: number;
  utilizationVCPUs: number;
};

export type RecommendationTableProps = {
  recommendations: any;
  currentClusterInformation: CurrentClusterInformation;
  negotiatedDiscount: number;
  status:
    | { data: string }
    | { error: unknown; message: string; status: number };
  ready: boolean;
  setup: boolean;
  showActions: boolean;
  adoptRecommendation: (r: Recommendation) => void;
  currency: string;
};

export default RecommendationTable;
