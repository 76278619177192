import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  Popover,
  Tab,
  Tabs,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import upperFirst from 'lodash/upperFirst';
import PropTypes from 'prop-types';
import React, { useEffect, useState, SyntheticEvent } from 'react';

const useStyles = makeStyles({
  formControl: {
    margin: 8,
    minWidth: 120,
  },
});

const AggregateByControl = ({
  aggregateBy,
  setAggregateBy,
  aggregateByOptions,
  id,
  unaggregatedEnabled,
}: {
  aggregateBy: string[];
  setAggregateBy: (s: string[]) => void;
  aggregateByOptions: { value: string }[];
  id: string;
  unaggregatedEnabled: boolean;
}) => {
  const classes = useStyles();
  const initialFilters = aggregateBy.filter((item: string) =>
    item.startsWith('label:'),
  );
  const [labelNameToAdd, setLabelNameToAdd] = useState<string>('');
  const [anchorEl, setAnchorEl] =
    useState<(EventTarget & Element) | null>(null);
  const [labels, setLabels] = useState<string[]>(initialFilters);
  const [aggMode, setAggMode] = useState(aggregateBy.length > 1 ? 1 : 0);

  const open = Boolean(anchorEl);
  // Can only remove an element when more than one exists.
  const canRemove = (elems: unknown[]) => elems.length > 1;

  const handleOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLabelAdd = () => {
    const labelName = `label:${labelNameToAdd}`;
    // prevent from added dup labels
    if (!labels.includes(labelName)) {
      setLabelNameToAdd('');
      setLabels([...labels, labelName]);
      if (aggMode === 1) {
        setAggregateBy(
          aggregateBy.filter((s) => s !== 'unaggregated').concat(labelName),
        );
      } else if (aggMode === 0) {
        setAggregateBy([labelName]);
      }
    }
  };

  const addOrRemoveItem = (itemName: string) => {
    const namePresent = aggregateBy.includes(itemName);
    if (namePresent && aggregateBy.length > 1) {
      // name is present and we can remove, so remove
      setAggregateBy(aggregateBy.filter((s) => s !== itemName));
    } else if (!namePresent) {
      // name is not pressent, so add it
      setAggregateBy(
        aggregateBy.filter((s) => s !== 'unaggregated').concat([itemName]),
      );
    }
  };

  const handleLabelRemoval = (labelName: string) => {
    if (aggMode === 1) {
      addOrRemoveItem(labelName);
    } else if (aggMode === 0) {
      setAggregateBy([labelName]);
    }
  };

  const ControlSelector = ({ name, onClick }: any) => (
    <Chip
      onClick={onClick}
      variant={aggregateBy.includes(name) ? 'default' : 'outlined'}
      label={name === 'controllerkind' ? 'Ctlr Kind' : upperFirst(name)}
    />
  );

  const formattedAggByForDisplay = () =>
    aggregateBy.map((item: string) => upperFirst(item)).toString();

  const handleUnaggregated = () => {
    setAggregateBy(['unaggregated']);
  };

  return (
    <div id={id}>
      <FormControl className={classes.formControl}>
        <TextField
          label="Aggregate by"
          value={formattedAggByForDisplay()}
          onClick={handleOpen}
          inputProps={{
            readOnly: true,
            style: { cursor: 'pointer' },
          }}
        />
      </FormControl>
      <Popover
        id={`${id}-popover`}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: { overflowY: 'hidden', width: 400, padding: '1.5em' },
        }}
      >
        <Tabs
          style={{
            borderBottom: 'solid 2px #eee',
            marginBottom: 14,
          }}
          value={aggMode}
          onChange={(_e, v) => {
            setAggMode(v);
          }}
          indicatorColor="primary"
        >
          <Tab label="Single Aggregation" />
          <Tab label="Multi Aggregation" />
        </Tabs>
        <Grid
          style={{ textAlign: 'center', overflow: 'hidden', gap: '.24em' }}
          spacing={1}
          container
        >
          {aggregateByOptions.map((opt: any) => (
            <ControlSelector
              key={opt.value}
              name={opt.value}
              onClick={() => {
                if (aggMode === 1) {
                  addOrRemoveItem(opt.value);
                } else if (aggMode === 0) {
                  setAggregateBy([opt.value]);
                }
              }}
            />
          ))}
          {labels.map((name: string) => (
            <ControlSelector
              key={name}
              name={name}
              onClick={() => handleLabelRemoval(name)}
            />
          ))}
          {unaggregatedEnabled && (
            <ControlSelector name="unaggregated" onClick={handleUnaggregated} />
          )}
        </Grid>
        <Box
          mt="1em"
          gridColumnGap={20}
          display="flex"
          justifyContent="flex-start"
        >
          <TextField
            InputProps={{
              endAdornment: (
                <Button
                  onClick={handleLabelAdd}
                  disabled={!labelNameToAdd}
                  size="small"
                >
                  + Label
                </Button>
              ),
            }}
            value={labelNameToAdd}
            onChange={(e) => setLabelNameToAdd(e.target.value)}
            size="small"
            id="standard-basic"
            label="Label Name"
          />
        </Box>
      </Popover>
    </div>
  );
};

const aggregateByOptionPropType = PropTypes.shape({
  name: PropTypes.string,
  value: PropTypes.string,
});

const defaultAggregateByOptions = [
  {
    name: 'Cluster',
    value: 'cluster',
  },
  {
    name: 'Container',
    value: 'container',
  },
  {
    name: 'Controller',
    value: 'controller',
  },
  {
    name: 'Controller Kind',
    value: 'controllerkind',
  },
  {
    name: 'DaemonSet',
    value: 'daemonset',
  },
  {
    name: 'Department',
    value: 'department',
  },
  {
    name: 'Deployment',
    value: 'deployment',
  },
  {
    name: 'Environment',
    value: 'environment',
  },
  {
    name: 'Job',
    value: 'job',
  },
  {
    name: 'Namespace',
    value: 'namespace',
  },
  {
    name: 'Node',
    value: 'node',
  },
  {
    name: 'Owner',
    value: 'owner',
  },
  {
    name: 'Pod',
    value: 'pod',
  },
  {
    name: 'Product',
    value: 'product',
  },
  {
    name: 'Service',
    value: 'service',
  },
  {
    name: 'StatefulSet',
    value: 'statefulset',
  },
  {
    name: 'Team',
    value: 'team',
  },
];

AggregateByControl.propTypes = {
  aggregateBy: PropTypes.arrayOf(PropTypes.string).isRequired,
  setAggregateBy: PropTypes.func.isRequired,
  aggregateByOptions: PropTypes.arrayOf(aggregateByOptionPropType),
};

AggregateByControl.defaultProps = {
  aggregateByOptions: defaultAggregateByOptions,
};

export default React.memo(AggregateByControl);
