import { IconButton, Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/HelpOutline';
import React from 'react';
import Analytics from '../services/analytics';

interface HelpIconLinkProps {
  tooltipText: string;
  href: string;
}

const HelpLink = ({ tooltipText, href }: HelpIconLinkProps) => (
  <Tooltip title={tooltipText}>
    <a
      onClick={() => Analytics.record('documentation_visited', { url: href })}
      target="_blank"
      href={href}
    >
      <IconButton>
        <HelpIcon />
      </IconButton>
    </a>
  </Tooltip>
);

export const HelpIconLink = React.memo(HelpLink);
