export const getProfile = (name) => {
  if (name === 'high-availability') {
    return {
      description:
        'Requirements for high-availability clusters include a minimum of three nodes and a target resource utilization of 65% over the last 2 days.',
      minNodeCount: 3,
      targetUtilization: 0.65,
      range: '2d',
      p: 0.999,
      allowSharedCore: false,
    };
  }

  if (name === 'production') {
    return {
      description:
        'Requirements for production clusters include a minimum of two nodes and a target resource utilization of 75% over the last 2 days.',
      minNodeCount: 2,
      targetUtilization: 0.75,
      range: '2d',
      p: 0.98,
      allowSharedCore: false,
    };
  }

  return {
    description:
      'Requirements for development clusters include a minimum of one node and a target resource utilization of 80% over the last 2 days.',
    minNodeCount: 1,
    targetUtilization: 0.8,
    range: '2d',
    p: 0.85,
    allowSharedCore: true,
  };
};

export default { getProfile };
