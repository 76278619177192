import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Analytics from '../services/analytics';

export function usePageViewRecorder() {
  let location = useLocation();
  
  useEffect(() => {
    Analytics.record('page_visit', {
      url: location.pathname,
      search: location.search,
    });
  }, [location]);
}
