import React, { useEffect, useRef, useState } from 'react';
import { monitorWidthResize } from '../../../hooks';
import { AllocationOverTimeSet } from '../HistoricalCostBarChart';
import { CostBreakdown } from './CostBreakdown';
import { GraphBreakdown } from './GraphBreakdown';

interface ChartTileProps {
  dataSet: AllocationOverTimeSet[];
  width: number;
  resourceTypes: string[];
  currency: string;
  totalCost: number;
  activeTimeWindow: string;
  sortedTypesByCost: any;
  tooltipMessage: string;
}

export const ChartTile = ({
  currency,
  totalCost,
  activeTimeWindow,
  sortedTypesByCost,
  tooltipMessage,
  dataSet,
  width,
  resourceTypes,
}: ChartTileProps) => {
  const inspectGraphRef = useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = useState(0);
  useEffect(() => {
    monitorWidthResize(inspectGraphRef, setChartWidth);
  }, []);
  return (
    <div>
      <CostBreakdown
        activeTimeWindow={activeTimeWindow}
        sortedTypesByCost={sortedTypesByCost}
        totalCost={totalCost}
        currency={currency}
        //   costByTypeList={costByTypeList}
        tooltipMessage={tooltipMessage}
      />
      <div ref={inspectGraphRef}>
        <GraphBreakdown
          width={chartWidth}
          dataSet={dataSet}
          resourceTypes={resourceTypes}
          currency={currency}
        />
      </div>
    </div>
  );
};
