import React, { ReactNode } from 'react';
import { Typography } from '@kubecost-frontend/holster';

export interface SectionCardProps {
  title: string;
  content: ReactNode;
  loading?: boolean;
}

export const SectionCard: React.FC<SectionCardProps> = ({ title, content }) => {
  return (
    <div className="flex flex-col justify-center border border-kc-gray-100">
      <div
        className="flex border-b border-kc-gray-100 align-center"
        style={{
          fontSize: '17.5px',
          padding: '8px 19px',
        }}
      >
        <Typography variant="p" className="font-bold" style={{ flexGrow: 1 }}>
          {title}
        </Typography>
      </div>
        {content}
    </div>
  );
};

SectionCard.defaultProps = {
  loading: false,
};
