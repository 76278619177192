import React, { FC } from 'react';
import NumberFormat, {
  NumberFormatProps,
  NumberFormatValues,
} from 'react-number-format';

interface NumberFormatCustomProps extends NumberFormatProps {
  inputRef?: (instance: NumberFormat<number> | null) => void;
  onChange?: (event: { target: { name: string; value: string } }) => void;
  name?: string;
}

export const NumberFormatCustom: FC<NumberFormatCustomProps> = ({
  inputRef,
  onChange,
  name,
  ...rest
}) => {
  const maxPercentage = 100;
  const minPercentage = 1;

  const percentageLimit = (inputObj: NumberFormatValues) => {
    const { value } = inputObj;

    if (Number(value) <= maxPercentage || Number(value) >= minPercentage)
      return true;
    return false;
  };
  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange!({
          target: {
            name: name || '',
            value: values.value,
          },
        });
      }}
      suffix="%"
      isAllowed={percentageLimit}
    />
  );
};
