import React, { useEffect, useState } from 'react';
import {
  Chip,
  IconButton,
  Link,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
} from '@material-ui/core';
import Header from '../../components/Header';
import RefreshIcon from '@material-ui/icons/Refresh';
import { makeStyles } from '@material-ui/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import { findIssues } from './api';
import { renderScoreGraph } from './legacy';
import { issues } from './issues';
import IssueDialog from './issueDialog';
import { Check, Error } from '@material-ui/icons';
import Info from '@material-ui/icons/Info';
import { useNavigate } from 'react-router';

const useStyles = makeStyles({
  toolbar: {
    justifyContent: 'flex-end',
  },
});

function Health(): React.ReactElement {
  const classes = useStyles();
  const [results, setResults] = useState([]);
  const [onlyShowErrors, setOnlyShowErrors] = useState(false);
  const [selectedResult, setSelectedResult] = useState(null);
  const [selectedError, setSelectedError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    findIssues((results: any) => {
      setResults(results);
    });
  }, []);

  useEffect(() => {
    renderScoreGraph();
  }, []);

  return (
    <div id="health">
      <IssueDialog
        issue={selectedResult}
        error={selectedError}
        open={selectedResult !== null}
        onClose={() => {
          setSelectedError(null);
          setSelectedResult(null);
        }}
      />
      <Header breadcrumbs={[{ name: 'Cluster Health', href: 'health.html' }]}>
        <Toolbar className={classes.toolbar} variant="dense">
          <IconButton onClick={() => window.location.reload()}>
            <RefreshIcon />
          </IconButton>
          <Link href="">
            <IconButton onClick={() => navigate('/settings')}>
              <SettingsIcon />
            </IconButton>
          </Link>
        </Toolbar>
      </Header>
      <div style={{}}>
        <div style={{ marginBottom: '1em', textAlign: 'center' }}>
          <span id="health-description">
            <span>Generating health score...</span>
          </span>
          <Tooltip title="Cluster health ratings are an assessment of infrastructure reliability and performance risks. Scores range from 1-100.">
            <Info style={{ fontSize: '17px', marginLeft: '0.5em' }} />
          </Tooltip>
        </div>
        <div className="gauge-container">
          <div className="gauge-a"></div>
          <div className="gauge-b"></div>
          <div className="gauge-c"></div>
          <div className="gauge-data">
            <div
              id="gauge-loading"
              className="mdl-spinner mdl-js-spinner is-active"
            ></div>
            <h1 id="gauge-percent"></h1>
          </div>
        </div>
        <div style={{ float: 'right' }} id="toggle-box">
          <span>Show All</span>
          <Switch
            color="default"
            onChange={(e) => setOnlyShowErrors(!e.target.checked)}
            defaultChecked
          />
        </div>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Health Test</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results &&
                issues.map((issue) => {
                  let result = results[issue[0]];
                  let error =
                    result &&
                    (result.data === true ||
                      (result.data !== null && result.data.length > 0) ||
                      (result.data !== null &&
                        result.data.overThreshold === true));
                  if (onlyShowErrors && !error) {
                    return <></>;
                  }

                  return (
                    <TableRow
                      hover
                      onClick={() => {
                        setSelectedResult(issue);
                        setSelectedError(result);
                      }}
                      id="${id}"
                    >
                      <TableCell>
                        <span className="issue-title">{issue[2]}</span>

                        <Chip
                          style={{ marginLeft: '1em' }}
                          label={issue[1]}
                          size="small"
                        />

                        {issue[3] && issue[3] === 'high' && (
                          <Chip
                            style={{
                              marginLeft: '1em',
                              color: 'white',
                              backgroundColor: 'red',
                            }}
                            label="high"
                            size="small"
                          />
                        )}

                        {issue[3] && issue[3] !== 'high' && (
                          <Chip
                            style={{
                              marginLeft: '1em',
                              color: 'white',
                              backgroundColor: 'orange',
                            }}
                            label="medium"
                            size="small"
                          />
                        )}

                        {/* ${getSeverityChip(issues[i][3])} */}
                      </TableCell>
                      <TableCell className="issue-cost">
                        {error && <Error style={{ color: '#db3236' }} />}
                        {!error && <Check style={{ color: 'green' }} />}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <div id="message-box"></div>
      </div>

      <dialog id="switch-dialog" className="mdl-dialog"></dialog>
    </div>
  );
}

export default Health;
