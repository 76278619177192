// react
import React, { FC, useCallback, useEffect, useState } from 'react';

// mui
import { Tabs, Tab } from '@material-ui/core';

// holster
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from '@kubecost-frontend/holster';

// local
import { ArrowIcon } from '../../../assets/images';
import { Header } from '../../../components/Header2New';
import { getUnclaimedVolumeData } from './api';
import { TableColumn } from './types';

export const UnclaimedVolumesNew: FC = () => {
  const [volumes, setVolumes] = useState<
    {
      name: string;
      owner: string;
      size: string;
      storageClass: string;
    }[]
  >([]);
  const [grouping, setGrouping] = useState(0);
  const [columns, setColumns] = useState<TableColumn[]>([
    { head: 'Volume', prop: 'name' },
    { head: 'Storage Class', prop: 'storageClass' },
    { head: 'Size', prop: 'size' },
  ]);

  const initializeData = () => {
    getUnclaimedVolumeData().then((data) => {
      setVolumes(data);
    });
  };

  useEffect(() => {
    initializeData();
  }, []);

  useEffect(() => {
    if (grouping === 0) {
      setColumns([
        { head: 'Volume', prop: 'name' },
        { head: 'Storage Class', prop: 'storageClass' },
        { head: 'Size', prop: 'size' },
      ]);
    } else if (grouping === 1) {
      setColumns([
        { head: 'Volume', prop: 'name' },
        { head: 'Owner', prop: 'owner' },
        { head: 'Size', prop: 'size' },
      ]);
    }
  }, [grouping]);

  const handleGroupingChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setGrouping(newValue);
    },
    [],
  );
  return (
    <>
      <Header
        title={
          <>
            Unclaimed Volumes
            <Typography variant="p" className="text-kc-gray-200 font-normal">
              <a href="savings">
                <ArrowIcon direction="UP" className="inline" />
                Back to savings
              </a>
            </Typography>
          </>
        }
        refreshCallback={initializeData}
      />

      <Typography variant="h5">Volumes unused by any volume claims</Typography>
      <Typography variant="p" className="mb-6">
        View volumes unused by any pods. Consider deleting or moving these to a
        cheaper storage tier to save money.
      </Typography>

      <Tabs
        indicatorColor="primary"
        onChange={handleGroupingChange}
        value={grouping}
      >
        <Tab label="By Owner" />
        <Tab label="By Namespace" />
      </Tabs>
      <Table style={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            {columns.map((col) => {
              return <TableHeadCell>{col.head}</TableHeadCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {volumes.map((volume) => {
            return (
              <TableRow>
                {columns.map((col) => {
                  return <TableCell>{volume[col.prop]}</TableCell>;
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

UnclaimedVolumesNew.displayName = 'UnclaimedVolumes';
