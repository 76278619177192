import now from 'lodash/now';
import { Allocation } from '../types/allocation';
import { version } from './env';
import Logger from './logger';

type GithubRelease = {
  name: string;
  body: string;
};

export function pluralize(s: string, count: number) {
  return `${s}${count > 1 ? 's' : ''}`;
}

const githubReleasesURL =
  'https://api.github.com/repos/kubecost/cost-model/releases';

export const currencyList = [
  'USD',
  'AUD',
  'BRL',
  'CAD',
  'CHF',
  'CNY',
  'DKK',
  'EUR',
  'GBP',
  'INR',
  'JPY',
  'NOK',
  'SEK',
  'PLN',
];

export const AppVersion = version;

interface SemanticVersion {
  major: number;
  minor: number;
  patch: number;
}

const cacheNextReleaseVersion = (versionNumber: string) => {
  var expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 1);

  localStorage.setItem('current_version', versionNumber);
  localStorage.setItem(
    'current_version_expiration',
    expirationDate.toISOString(),
  );
};

const getCachedNextReleaseVersion = (): string | null => {
  const expirationDateString = localStorage.getItem(
    'current_version_expiration',
  );
  const currentVersion = localStorage.getItem('current_version');

  if (!expirationDateString || !currentVersion) return null;

  const expirationDate = Date.parse(expirationDateString);

  if (expirationDate < now()) {
    return null;
  }

  return currentVersion;
};

// Exports a version string to an object containing major, minor, and patch
// int properties. Parsing failures result in 0, 0, 0 returns.
export const toNumericalVersion = (versionString?: string): SemanticVersion => {
  let major;
  let minor;
  let patch;
  if (!versionString) {
    return { major: 0, minor: 0, patch: 0 };
  }
  try {
    const splitVersion = versionString.split('.');
    if (splitVersion.length === 3) {
      major = parseInt(splitVersion[0], 10);
      minor = parseInt(splitVersion[1], 10);
      patch = parseInt(splitVersion[2], 10);
    } else if (splitVersion.length === 2) {
      major = 0;
      minor = parseInt(splitVersion[0], 10);
      patch = parseInt(splitVersion[1], 10);
    } else {
      major = 0;
      minor = 0;
      patch = 0;
    }
  } catch (err) {
    major = 0;
    minor = 0;
    patch = 0;
  }

  return { major, minor, patch };
};

// isUnstableRelease checks for any metadata applied to the release number.
//
const isUnstableRelease = (versionString: string): boolean => {
  // Quick and dirty check for a hyphen that denotes metadata
  // added to the release.
  return versionString.includes('-');
};

const fetchCurrentReleaseNumberFromGithub = async (): Promise<string> => {
  const resp = await fetch(githubReleasesURL);
  const results: GithubRelease[] = await resp.json();

  // Find the first stable release in the list.
  let latestStableRelease = results.find(
    (release) => !isUnstableRelease(release.name),
  )?.name;
  return latestStableRelease?.substring(1) || '';
};

const fetchCurrentReleaseInfoFromGithub = async (): Promise<string> => {
  const resp = await fetch(githubReleasesURL);
  const results: GithubRelease[] = await resp.json();
  let latestStableReleaseBody = results.find(
    (release) => !isUnstableRelease(release.name),
  )?.body;
  return latestStableReleaseBody?.substring(0) || '';
};

export const getEarliestSupportedVersion = async (): Promise<SemanticVersion> => {
  const releaseInfo = await fetchCurrentReleaseInfoFromGithub();
  const regex = /earliest supported version is v[0-9]\.[0-9]*\.[0-9]*/g;
  const found = releaseInfo.match(regex)
  var vString;
  if (found != null && found.length  > 0) {
    vString = found[0].replace(/earliest supported version is v/, '')
  } else {
    vString = "1.70.0";
  }
  return toNumericalVersion(vString);
}

// Get the latest version in the object format containing major, minor, and patch
// int properties.
export const getLatestStableVersion = async (): Promise<SemanticVersion> => {
  try {
    let version = getCachedNextReleaseVersion();
    if (version) {
      return toNumericalVersion(version);
    }

    version = await fetchCurrentReleaseNumberFromGithub();

    cacheNextReleaseVersion(version);

    return toNumericalVersion(version);
  } catch (err) {
    Logger.log(`Failed to locate latest version info: ${err}`);
    return toNumericalVersion(AppVersion);
  }
};

export const isUnsupportedVersion = async (): Promise<boolean> => {
  const earliestSupportedVersion = await getEarliestSupportedVersion();
  const currentVersion = toNumericalVersion(AppVersion);

  if (earliestSupportedVersion.major > currentVersion.major) {
    return true;
  }

  if (earliestSupportedVersion.major === currentVersion.major) {
    if (earliestSupportedVersion.minor > currentVersion.minor) {
      return true;
    }

    if (earliestSupportedVersion.minor === currentVersion.minor) {
      if (earliestSupportedVersion.patch > currentVersion.patch) {
        return true;
      }
    }
  }
  return false;
}

// Returns true if the current available version is greater than the current version.
export const isUpdateAvailable = async (): Promise<boolean> => {
  const latestVersion = await getLatestStableVersion();
  const currentVersion = toNumericalVersion(AppVersion);

  if (latestVersion.major > currentVersion.major) {
    return true;
  }

  if (latestVersion.major === currentVersion.major) {
    if (latestVersion.minor > currentVersion.minor) {
      return true;
    }

    if (latestVersion.minor === currentVersion.minor) {
      if (latestVersion.patch > currentVersion.patch) {
        return true;
      }
    }
  }
  return false;
};

export function isDebugMode(): boolean {
  const url = new URL(window.location.href);
  const debugMode = url.searchParams.get('debug');
  return debugMode === 'true';
}

export const parseResponseJSON = <T = unknown>(
  response: Response,
): Promise<T> => {
  // check status and parse response into JSON
  if (!response.ok) {
    return response.text().then((raw) => {
      try {
        throw JSON.parse(raw);
      } catch (e) {
        throw {
          status: response.status,
          message: raw,
        };
      }
    });
  }
  return response.json();
};

export const randomSuffix = () =>
  Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(0, 5);

export const sleep = (ms: number): Promise<void> =>
  new Promise<void>((resolve) => {
    setTimeout(() => resolve(), ms);
  });

/**
 * Generate a random number between 0 and 1,000,000.
 * Return string `req=${randomNumber}`.
 */
export function getCacheBuster(): string {
  const str = `req=${Math.floor(Math.random() * Math.floor(1000000))}`;
  return str;
}

/**
 *
 * @param {String} href - A url.
 *
 * Return the url with its filename trimmed from the end, if one exists.
 */
export function getPathWithoutFile(href: string): string {
  if (href.split('/').length < 4) {
    // This should never happen, but best to be defensive in case this is called with something like http://123.456.789.1011
    return href.split('?')[0]; // remove arguments and then call
  }
  if (href.lastIndexOf('.') > href.lastIndexOf('/'))
    return href.substring(0, href.lastIndexOf('/'));
  if (href.endsWith('/')) return href.substring(0, href.length - 1);
  return href;
}

/**
 * @param {Object} params - A plain object of key:value pairs.
 *
 * Return a valid and properly encoded query string from the pairs.
 */
export function paramsToQuery(params: Record<string, string | number>): string {
  const args = Object.entries(params).map(
    ([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
  );
  return `?${args.join('&')}`;
}

/**
 * Return a breakdown of asset set range expenses.
 */
export function assetSetRangeBreakdown(assetSetRange) {
  let totalMonthlyCost = 0.0;

  const cpuMonthlyCost = 0.0;
  const cpuBreakdown = {
    idle: 0.0,
    other: 0.0,
    system: 0.0,
    user: 0.0,
  };

  const ramMonthlyCost = 0.0;
  const ramBreakdown = {
    idle: 0.0,
    other: 0.0,
    system: 0.0,
    user: 0.0,
  };

  let gpuMonthlyCost = 0.0;

  let storageMonthlyCost = 0.0;
  const storageBreakdown = {
    idle: 0.0,
    other: 0.0,
    system: 0.0,
    user: 0.0,
  };

  // AssetSetRange should only include one AssetsSet (accumulate=true),
  // but iterate anyways.
  assetSetRange.forEach((assetSet) => {
    Object.values(assetSet).forEach((asset) => {
      if (asset.type.toLowerCase() === 'disk') {
        var cost = (asset.totalCost * (730.0 * 60.0)) / asset.minutes;
        totalMonthlyCost += cost;
        storageMonthlyCost += cost;

        let idleStoragePct = 0.0;
        if (
          asset.breakdown !== null &&
          asset.breakdown !== undefined &&
          asset.breakdown.idle > 0
        ) {
          idleStoragePct = asset.breakdown.idle;
        }
        storageBreakdown.idle = cost * idleStoragePct;

        let systemStoragePct = 0.0;
        if (
          asset.breakdown !== null &&
          asset.breakdown !== undefined &&
          asset.breakdown.system > 0
        ) {
          systemStoragePct = asset.breakdown.system;
        }
        storageBreakdown.system = cost * systemStoragePct;

        let userStoragePct = 0.0;
        if (
          asset.breakdown !== null &&
          asset.breakdown !== undefined &&
          asset.breakdown.user > 0
        ) {
          userStoragePct = asset.breakdown.user;
        }
        storageBreakdown.user = cost * userStoragePct;
      }

      if (asset.type.toLowerCase() === 'node') {
        // adjustmentRate is used to scale RAM and CPU costs by the total rate of
        // adjustment so that efficiency is calculated approximately correctly.
        // This method is necessary because we only get one adjustment for the
        // node, as opposed to one for RAM and one for CPU. Therefore, there
        // might be some inaccuracies in efficiency, if the adjustment and/or
        // idle percentages are heavily lop-sided w/r/t RAM vs CPU.
        //
        // e.g. total cost = $90, adjustment = -$10 => adjustmentRate = 0.9
        // e.g. total cost = $150, adjustment = -$300 => adjustmentRate will be 0.3333
        // e.g. total cost = $150, adjustment = $50 => adjustmentRate will be 1.5
        let adjustmentRate = 1.0;
        if (
          asset.adjustment !== null &&
          asset.adjustment !== undefined &&
          asset.totalCost - asset.adjustment !== 0.0
        ) {
          adjustmentRate =
            asset.totalCost / (asset.totalCost - asset.adjustment);
        } else if (asset.totalCost === 0.0) {
          // If (asset.totalCost - asset.adjustment) is 0.0 then adjutment cancels out
          // total cost and we need to adjust everything to 0 without dividing by 0 above.
          adjustmentRate = 0.0;
        }

        var cost = (asset.totalCost * (730.0 * 60.0)) / asset.minutes;
        totalMonthlyCost += cost;

        const cpuCost =
          (asset.cpuCost * (1.0 - asset.discount) * (730.0 * 60.0)) /
          asset.minutes;
        const ramCost =
          (asset.ramCost * (1.0 - asset.discount) * (730.0 * 60.0)) /
          asset.minutes;
        const gpuCost = (asset.gpuCost * (730.0 * 60.0)) / asset.minutes;

        let idleCPUPct = 0.0;
        if (
          asset.cpuBreakdown !== null &&
          asset.cpuBreakdown !== undefined &&
          asset.cpuBreakdown.idle > 0
        ) {
          idleCPUPct = asset.cpuBreakdown.idle;
        }
        cpuBreakdown.idle += cpuCost * idleCPUPct * adjustmentRate;

        let otherCPUPct = 0.0;
        if (
          asset.cpuBreakdown !== null &&
          asset.cpuBreakdown !== undefined &&
          asset.cpuBreakdown.other > 0
        ) {
          otherCPUPct = asset.cpuBreakdown.other;
        }
        cpuBreakdown.other += cpuCost * otherCPUPct * adjustmentRate;

        let systemCPUPct = 0.0;
        if (
          asset.cpuBreakdown !== null &&
          asset.cpuBreakdown !== undefined &&
          asset.cpuBreakdown.system > 0
        ) {
          systemCPUPct = asset.cpuBreakdown.system;
        }
        cpuBreakdown.system += cpuCost * systemCPUPct * adjustmentRate;

        let userCPUPct = 0.0;
        if (
          asset.cpuBreakdown !== null &&
          asset.cpuBreakdown !== undefined &&
          asset.cpuBreakdown.user > 0
        ) {
          userCPUPct = asset.cpuBreakdown.user;
        }
        cpuBreakdown.user += cpuCost * userCPUPct * adjustmentRate;

        if (idleCPUPct + otherCPUPct + systemCPUPct + userCPUPct === 0.0) {
          cpuBreakdown.system += cpuCost; // assume systemCPUPct is 1.0
        }

        let idleRAMPct = 0.0;
        if (
          asset.ramBreakdown !== null &&
          asset.ramBreakdown !== undefined &&
          asset.ramBreakdown.idle > 0
        ) {
          idleRAMPct = asset.ramBreakdown.idle;
        }
        ramBreakdown.idle += ramCost * idleRAMPct * adjustmentRate;

        let otherRAMPct = 0.0;
        if (
          asset.ramBreakdown !== null &&
          asset.ramBreakdown !== undefined &&
          asset.ramBreakdown.other > 0
        ) {
          otherRAMPct = asset.ramBreakdown.other;
        }
        ramBreakdown.other += ramCost * otherRAMPct * adjustmentRate;

        let systemRAMPct = 0.0;
        if (
          asset.ramBreakdown !== null &&
          asset.ramBreakdown !== undefined &&
          asset.ramBreakdown.system > 0
        ) {
          systemRAMPct = asset.ramBreakdown.system;
        }
        ramBreakdown.system += ramCost * systemRAMPct * adjustmentRate;

        let userRAMPct = 0.0;
        if (
          asset.ramBreakdown !== null &&
          asset.ramBreakdown !== undefined &&
          asset.ramBreakdown.user > 0
        ) {
          userRAMPct = asset.ramBreakdown.user;
        }
        ramBreakdown.user += ramCost * userRAMPct * adjustmentRate;

        gpuMonthlyCost += gpuCost;
      }
    });
  });

  return {
    cpuBreakdown,
    ramBreakdown,
    storageBreakdown,
    totalMonthlyCost,
    gpuMonthlyCost,
    cpuMonthlyCost,
    ramMonthlyCost,
    storageMonthlyCost,
  };
}

/**
 *
 * @param {String} str - The string to sanitize.
 */
export function sanitizeHTML(str: string): string {
  const temp = document.createElement('div');
  temp.textContent = str;
  return temp.innerHTML;
}

export function getConnectedAddress(): string {
  const href = window.location.origin + window.location.pathname;
  if (href.split('/').length < 4) {
    // This should never happen, but best to be defensive in case this is
    // called with something like http://123.456.789.1011
    return href.split('?')[0]; // remove arguments and then call
  }
  if (href.lastIndexOf('.') > href.lastIndexOf('/')) {
    return href.substring(0, href.lastIndexOf('/'));
  }
  if (href.endsWith('/')) {
    return href.substring(0, href.length - 1);
  }
  return href;
}

export function getCurrentContainerAddressModel(): string {
  const DEFAULT_SERVICE_ADDRESS = 'http://localhost:9090/model';
  let service = localStorage.getItem('container');
  if (!service) {
    service = getConnectedAddress();
    if (service.includes('localhost')) {
      service = DEFAULT_SERVICE_ADDRESS;
    }
  }
  if (service && service.endsWith('/')) {
    service = service.slice(0, -1);
  }
  if (service && service.endsWith('/api')) {
    service = `${service.substring(0, service.length - 4)}/model`;
  } else if (service && !service.endsWith('/model')) {
    service = `${service}/model`;
  }
  return service;
}

export function getClusterNameId(
  entity?: {
    clusterId?: string;
    clusterName?: string;
  } | null,
): string {
  if (entity === null || entity === undefined) {
    return '';
  }

  const clusterId = entity.clusterId;
  const clusterName = entity.clusterName;

  if (clusterId === undefined) {
    return '';
  }

  if (clusterName === undefined) {
    return clusterId;
  }

  return `${clusterName}/${clusterId}`;
}

export function generateHslaColors(
  startingHue: number,
  saturation: number,
  lightness: number,
  alpha: number,
  amount: number,
): string[] {
  const colors = [];
  const huedelta = Math.trunc(360 / amount);

  for (let i = 0; i < amount; i++) {
    const hue = i * huedelta + startingHue;
    colors.push(`hsla(${hue},${saturation}%,${lightness}%,${alpha})`);
  }

  return colors;
}

export function joinPromQsMultipleKeys(qr1, qr2, key1, key2) {
  if (typeof qr1.data === 'undefined' || typeof qr2.data === 'undefined') {
    return;
  }

  qr1.data.result.forEach((val1) => {
    var foundValue = 0;

    qr2.data.result.forEach((val2) => {
      if (
        val1.metric[key1] === val2.metric[key1] &&
        val1.metric[key2] === val2.metric[key2]
      ) {
        foundValue = val2.value[1];
        return;
      }
    });
    val1.value.push(foundValue);
  });
}

export function joinPromQRs(qr1, qr2, key1, key2) {
  if (typeof qr1.data === 'undefined' || typeof qr2.data === 'undefined') {
    return;
  }

  if (!qr1.data) return;

  qr1.data.result.forEach((val1) => {
    var foundValue = 0;

    qr2.data.result.forEach((val2) => {
      if (val1.metric[key1] === val2.metric[key2]) {
        foundValue = val2.value[1];
        return;
      }
    });
    val1.value.push(foundValue);
  });
}

export function getHourlyStoragePrice(
  type: string,
  region: string,
  platform: string,
  local: boolean,
) {
  if (typeof window === 'undefined') {
    // no pricing data available in node.
    var resourceGroup =
      typeof type !== 'undefined' && type.includes('SSD')
        ? 'SSD'
        : 'PDStandard';
    if (typeof type !== 'undefined' && type.includes('gp2')) {
      resourceGroup = 'SSD';
    }
    var price = 0.04;
    if (resourceGroup === 'SSD') {
      price = 0.17;
    }

    if (resourceGroup === 'gp2') {
      price = 0.1;
    }

    return price;
  }

  resourceGroup =
    typeof type !== 'undefined' && type.includes('SSD') ? 'SSD' : 'PDStandard';
  var BILLING_TAG = platform + '_pricing';

  var pricing_obj = localStorage.getItem(BILLING_TAG);
  var price = 0.04;

  if (typeof type !== 'undefined' && type.includes('gp2')) {
    resourceGroup = 'SSD';
  }

  if (resourceGroup === 'SSD') price = 0.17;

  if (resourceGroup === 'gp2') price = 0.1;

  if (pricing_obj != null) {
    pricing_obj[resourceGroup.toLowerCase()].forEach((val) => {
      if (
        jQuery.inArray(region, val.serviceRegions) !== -1 &&
        !val.description.toLowerCase().includes('regional')
      ) {
        let lasttier =
          val.pricingInfo[0].pricingExpression.tieredRates.length - 1;
        let nanos =
          val.pricingInfo[0].pricingExpression.tieredRates[lasttier].unitPrice
            .nanos;
        price = nanos * Math.pow(10, -9);
      }
    });
  }

  return price;
}

export const getQueryRangeParams = (
  timeWindow: number,
  timeWindowUnits: string,
  stepDuration: number,
  stepDurationUnits?: string,
) => {
  var end = new Date();
  var timeWindowDays = 0;
  var timeWindowHours = 0;
  var timeWindowMins = 0;

  if (timeWindowUnits === 'd') timeWindowDays = timeWindow;
  else if (timeWindowUnits === 'h') timeWindowHours = timeWindow;
  else timeWindowMins = timeWindow;

  var start = new Date(
    end.getFullYear(),
    end.getMonth(),
    end.getDate() - timeWindowDays,
    end.getHours() - timeWindowHours,
    end.getMinutes() - timeWindowMins,
  );
  var params = `&start=${encodeURIComponent(start.toISOString())}`;
  params += `&end=${encodeURIComponent(end.toISOString())}`;
  params += `&duration=${stepDuration.toString() + stepDurationUnits}`;
  params += `&window=${stepDuration.toString() + stepDurationUnits}`;

  return params;
};

export async function fetchWithTimeout(resource, options = {}) {
  const { timeout = 4000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);
  return response;
}

export const getSingleDayEfficiency = (allocationRange: Allocation[]) => {
  const reqAndUsage = allocationRange.reduce(
    (result, alloc) => {
      const hrs =
        (new Date(alloc.window.end) - new Date(alloc.window.start)) /
        1000 /
        60 /
        60;
      result.cpuReqCoreHrs += alloc.cpuCoreRequestAverage * hrs;
      result.cpuUseCoreHrs += alloc.cpuCoreUsageAverage * hrs;
      result.ramReqByteHrs += alloc.ramByteRequestAverage * hrs;
      result.ramReqUseHrs += alloc.ramByteUsageAverage * hrs;
      result.cpuCost += alloc.cpuCost;
      result.ramCost += alloc.ramCost;
      return result;
    },
    {
      cpuReqCoreHrs: 0,
      cpuUseCoreHrs: 0,
      ramReqByteHrs: 0,
      ramReqUseHrs: 0,
      cpuCost: 0,
      ramCost: 0,
    },
  );

  let cpuEfficiency = 0;
  if (reqAndUsage.cpuReqCoreHrs > 0) {
    cpuEfficiency = reqAndUsage.cpuUseCoreHrs / reqAndUsage.cpuReqCoreHrs;
  } else if (reqAndUsage.cpuUseCoreHrs > 0) {
    cpuEfficiency = 1;
  } else {
    cpuEfficiency = 0;
  }

  let ramEfficiency = 0;
  if (reqAndUsage.ramReqByteHrs > 0) {
    ramEfficiency = reqAndUsage.ramReqUseHrs / reqAndUsage.ramReqByteHrs;
  } else if (reqAndUsage.ramReqUseHrs > 0) {
    ramEfficiency = 1;
  }

  let totalEfficiency = 0;
  if (reqAndUsage.cpuCost + reqAndUsage.ramCost > 0) {
    totalEfficiency =
      (reqAndUsage.cpuCost * cpuEfficiency +
        reqAndUsage.ramCost * ramEfficiency) /
      (reqAndUsage.cpuCost + reqAndUsage.ramCost);
  }
  return totalEfficiency;
};
