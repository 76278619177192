import React, { useEffect, useState } from 'react';
import map from 'lodash/map';
import Typography from '@material-ui/core/Typography';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import { Badge, Box, Popover, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Alert from '@material-ui/lab/Alert';
import { isUpdateAvailable } from '../services/util';
import diagnostics, {
  PricingSourceStatus,
  PrometheusMetric,
} from '../services/diagnostics';
import Logger from '../services/logger';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
    padding: '1em',
    boxSizing: 'border-box',
  },
  warning: {
    alignSelf: 'center',
    margin: 'auto',
    marginTop: '1em',
  },
});

const WARNINGS_KEY = 'diagnosticWarnings';
const UPDATE_CATEGORY = 'Update Available';
const PROM_TARGET_CATEGORY = 'Prometheus Targets';
const PROM_METRICS_CATEGORY = 'Prometheus Metrics';
const PRICING_SOURCE_CATEGORY = 'Pricing Source';

type Warning = {
  category: string;
  type: string;
  message: string;
};

const DiagnosticsChecker = (): React.ReactElement => {
  const [fetch, setFetch] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [warnings, setWarnings] = useState<Array<Warning>>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const pushWarning = (warning: Warning) => {
    const warns = warnings;
    warns.push(warning);
    setWarnings(warns);
    window.sessionStorage.setItem(WARNINGS_KEY, JSON.stringify(warnings));
  };

  const clearWarning = (index: number) => {
    const warns = [...warnings];
    warns.splice(index, 1);
    setWarnings(warns);
    window.sessionStorage.setItem(WARNINGS_KEY, JSON.stringify(warns));
  };

  // initialize
  async function initialize() {
    const warns = window.sessionStorage.getItem(WARNINGS_KEY);
    if (warns) {
      setWarnings(JSON.parse(warns));
      setFetch(false);
    }
    setInitialized(true);
  }

  async function checkUpdateAvailable() {
    try {
      const updateAvailable = await isUpdateAvailable();
      if (updateAvailable) {
        pushWarning({
          category: UPDATE_CATEGORY,
          type: '',
          message: 'There is a newer version of Kubecost Available',
        });
      }
    } catch (err) {
      Logger.error(err);
    }
  }

  // Check Prometheus targets endpoint and create warnings for any targets which are down.
  async function checkPromTargets() {
    try {
      const promTargets = await diagnostics.prometheusTargets();
      Object.keys(promTargets).forEach((targetName) => {
        const upTargets = promTargets[targetName].up;
        const totalTargets = promTargets[targetName].targets.length;
        if (upTargets < totalTargets) {
          pushWarning({
            category: PROM_TARGET_CATEGORY,
            type: 'Targets Down:',
            message: `${targetName} has [${upTargets}/${totalTargets}] prometheus targets up`,
          });
        }
      });
    } catch (err) {
      Logger.error(err);
    }
  }

  // Check that all Prometheus metrics are present and create warning for missing metrics.
  async function checkPromMetrics() {
    try {
      const { promMetrics, thanMetrics } =
        await diagnostics.prometheusMetrics();
      promMetrics.forEach((metric: PrometheusMetric) => {
        if (!metric.passed) {
          pushWarning({
            category: PROM_METRICS_CATEGORY,
            type: 'Failed Prometheus Metric Test:',
            message: `Test failed for "${metric.label}"`,
          });
        }
      });
      thanMetrics.forEach((metric: PrometheusMetric) => {
        if (!metric.passed) {
          pushWarning({
            category: PROM_METRICS_CATEGORY,
            type: 'Failed Thanos Metric Test:',
            message: `Test failed for "${metric.label}"`,
          });
        }
      });
    } catch (err) {
      Logger.error(err);
    }
  }

  // Check if there are any failing pricing sources
  async function checkPricingSources() {
    try {
      const pricingStatus = await diagnostics.pricingSources();
      pricingStatus.forEach((source: PricingSourceStatus) => {
        if (source.enabled && !source.available) {
          pushWarning({
            category: PRICING_SOURCE_CATEGORY,
            type: 'A pricing source is unavailable:',
            message: source.name,
          });
        }
      });
    } catch (err) {
      Logger.error(err);
    }
  }
  // Fetch all diagnostic data
  async function fetchData() {
    if (fetch) {
      await checkUpdateAvailable();
      await checkPromTargets();
      await checkPromMetrics();
      await checkPricingSources();

      setFetch(false);
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!initialized) {
      initialize();
    }
  }, [initialized]);

  // Handle fetching data
  useEffect(() => {
    if (initialized && fetch) {
      fetchData();
    }
  }, [initialized, fetch]);

  const classes = useStyles();

  return (
    <>
      <Tooltip title="Diagnostic Check">
        <IconButton aria-label="diagnostic check" onClick={handleClick}>
          {warnings.length === 0 && <CheckCircleOutlineIcon />}
          {warnings.length !== 0 && (
            <Badge
              badgeContent={warnings.length}
              color="secondary"
              overlap="rectangular"
            >
              <NotificationImportantIcon />
            </Badge>
          )}
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.root}>
          <Box p={2}>
            {warnings.length === 0 && (
              <Typography variant="body1" align="center">
                No diagnostic issues detected with Kubecost, go to the{' '}
                <Link to="/diagnostics">full diagnostic</Link> page for details
              </Typography>
            )}
            {warnings.length !== 0 && (
              <>
                <Typography variant="body1" align="center">
                  The following issues have been detected with Kubecost, go to
                  the <Link to="/diagnostics">full diagnostic</Link> page for
                  details
                </Typography>
                <Box p={1} />
                {map(warnings, (warning: Warning, index: number) => (
                  <span
                    key={`${warning.category}${warning.type}${warning.message}`}
                  >
                    <Alert
                      severity="warning"
                      className={classes.warning}
                      onClose={() => {
                        clearWarning(index);
                      }}
                    >
                      <AlertTitle>{warning.category}</AlertTitle>
                      <Typography variant="body1">
                        {warning.type} {warning.message}
                      </Typography>
                    </Alert>
                    <Box p={1} />
                  </span>
                ))}
              </>
            )}
          </Box>
        </div>
      </Popover>
    </>
  );
};

export default DiagnosticsChecker;
