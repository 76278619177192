/* eslint-disable no-console */
import React, { useState } from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';

import Logger from '../../services/logger';

import { RequestSizingRec } from '../../services/savings';
import {
  requestRecommendationsToPlan,
  applyRequestPlan,
} from '../../services/clustercontroller/requestsizer';

function applyMessagesComponent(
  header: string,
  data: string[],
): React.ReactElement {
  const listItems = data.map((datum, i) => (
    <li
      key={i}
      style={{
        listStyle: 'disc',
      }}
    >
      <Typography>{datum}</Typography>
    </li>
  ));
  const dataDisplay = <ul>{listItems}</ul>;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography>{header}</Typography>
      </Grid>
      <Grid item>{dataDisplay}</Grid>
    </Grid>
  );
}

function applyExceptionComponent(
  header: string,
  exception: string,
): React.ReactElement {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography>{header}</Typography>
      </Grid>
      <Grid item>
        <Typography>{exception}</Typography>
      </Grid>
    </Grid>
  );
}

interface ApplyRecommendationsProps {
  // the /savings/requestSizing response
  recommendations: RequestSizingRec;
  // the frontend-configured filters that correspond to the response
  filters: any[];
}

function ApplyRecommendations({
  recommendations,
  filters,
}: ApplyRecommendationsProps): React.ReactElement {
  const [applyDialogOpen, setApplyDialogOpen] = useState(false);

  const [applyResp, setApplyResp] = useState();
  const [applyException, setApplyException] = useState<Error>();
  const [applying, setApplying] = useState(false);

  let isUnfilteredRec = filters.length === 0;

  async function handleApply() {
    setApplyResp(undefined);
    setApplyException(undefined);
    setApplying(true);
    try {
      Logger.log('Creating plan from recs:', recommendations);
      let plan = await requestRecommendationsToPlan(recommendations);
      Logger.log('Got plan:', plan);
      let applyResp = await applyRequestPlan(plan);
      setApplying(false);
      setApplyResp(applyResp);
      Logger.log('apply resp:', applyResp);
    } catch (error) {
      setApplying(false);
      if (error instanceof Error) {
        setApplyException(error);
      }
      console.error(error);
      Logger.error('apply failure:', error);
    }
  }

  function recommendationsToContainerCount(
    recommendations: RequestSizingRec,
  ): number {
    let containers = 0;

    recommendations.controllers.forEach((controller) => {
      if (controller.containers == null) {
        return;
      }
      containers += Object.keys(controller.containers).length;
    });

    return containers;
  }

  // This is a little messy, but the idea is to make users double-confirm if
  // they try to apply an unfiltered set of recommendations.
  return (
    <div style={{ marginTop: 12 }}>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setApplyDialogOpen(true)}
      >
        Automatically implement recommendations
      </Button>
      <Dialog open={applyDialogOpen} onClose={() => setApplyDialogOpen(false)}>
        <DialogTitle>Confirm implementation of recommendations</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
              Are you sure you want to apply these container request sizing
              recommendations to{' '}
              <Typography display="inline" style={{ fontWeight: 600 }}>
                {recommendationsToContainerCount(recommendations)} containers
              </Typography>
              ?
            </Typography>

            {isUnfilteredRec && (
              <Typography style={{ fontWeight: 600 }}>
                <br />
                Warning: this is an unfiltered recommendation and will apply to
                an entire cluster.
              </Typography>
            )}
          </DialogContentText>
          <DialogActions>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                handleApply();
                setApplyDialogOpen(false);
              }}
            >
              Yes, apply the recommendation
            </Button>
            <Button
              color="primary"
              autoFocus
              onClick={() => {
                setApplyDialogOpen(false);
              }}
            >
              No, do not apply the recommendation
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      {applying && <CircularProgress size={20} />}
      {applyException !== undefined &&
        applyExceptionComponent('Failed to apply:', applyException.toString())}

      {applyResp !== undefined &&
        applyResp.Errors !== null &&
        applyResp.Errors.length != 0 &&
        applyMessagesComponent(
          'Applying recommendation partially failed:',
          applyResp.Errors,
        )}

      {applyResp !== undefined &&
        applyResp.Warnings !== null &&
        applyResp.Warnings.length != 0 &&
        applyMessagesComponent(
          'Applying recommendation had some warnings:',
          applyResp.Warnings,
        )}
    </div>
  );
}

export default React.memo(ApplyRecommendations);
