import React from 'react';
import { makeStyles } from '@material-ui/styles';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import Paper from '@material-ui/core/Paper';
import InfoIcon from '@material-ui/icons/Info';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import {
  bytesToString,
  coresToString,
  toCurrency,
} from '../../services/format';

const useStyles = makeStyles({
  flexGrow: {
    flexGrow: 1,
  },
  green: {
    color: green[700],
  },
  summary: {
    marginBottom: 20,
  },
});

const Summary = ({
  cpuRequestsCores,
  cpuUsageCores,
  cpuUnderprovisionedCores,
  cpuOverprovisionedCores,
  cpuSavings,
  ramRequestsBytes,
  ramUsageBytes,
  ramUnderprovisionedBytes,
  ramOverprovisionedBytes,
  ramSavings,
  totalSavings,
  currency,
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.summary}>
      <div style={{ display: 'flex', flexFlow: 'row', padding: '12px 16px' }}>
        <div className={classes.flexGrow}>
          <Typography variant="h6">Summary</Typography>
        </div>
        <Typography variant="h6">
          <span className={classes.green} id="total-savings">{`${toCurrency(
            totalSavings,
            currency,
          )}/mo`}</span>
        </Typography>
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left" component="th" scope="row">
                Resource
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                Requested
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                Usage
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                Under-provisioning
                <Tooltip
                  arrow
                  placement="top"
                  title="Total amount of resource usage that exceeds current requests"
                >
                  <InfoIcon
                    style={{ fontSize: 12, color: grey[500], margin: '0 4px' }}
                  />
                </Tooltip>
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                Over-provisioning
                <Tooltip
                  arrow
                  placement="top"
                  title="Total amount of requested resource that exceeds recommended request"
                >
                  <InfoIcon
                    style={{ fontSize: 12, color: grey[500], margin: '0 4px' }}
                  />
                </Tooltip>
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                Savings
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">CPU</TableCell>
              <TableCell align="right">
                {coresToString(cpuRequestsCores)}
              </TableCell>
              <TableCell align="right">
                {coresToString(cpuUsageCores)}
              </TableCell>
              <TableCell align="right">
                {coresToString(cpuUnderprovisionedCores)}
              </TableCell>
              <TableCell align="right">
                {coresToString(cpuOverprovisionedCores)}
              </TableCell>
              <TableCell align="right">
                {toCurrency(cpuSavings, currency)}/mo
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">RAM</TableCell>
              <TableCell align="right">
                {bytesToString(ramRequestsBytes)}
              </TableCell>
              <TableCell align="right">
                {bytesToString(ramUsageBytes)}
              </TableCell>
              <TableCell align="right">
                {bytesToString(ramUnderprovisionedBytes)}
              </TableCell>
              <TableCell align="right">
                {bytesToString(ramOverprovisionedBytes)}
              </TableCell>
              <TableCell align="right">
                {toCurrency(ramSavings, currency)}/mo
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default Summary;
