import React, { memo, useEffect, useState } from 'react';
import get from 'lodash/get';
import round from 'lodash/round';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
  Tooltip,
  Grid,
} from '@material-ui/core';
import ClusterIcon from '@material-ui/icons/GroupWork';
import NodeIcon from '@material-ui/icons/Memory';
import StorageIcon from '@material-ui/icons/SdStorage';
import CloudIcon from '@material-ui/icons/Cloud';
import MapIcon from '@material-ui/icons/Map';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import CategoryIcon from '@material-ui/icons/Category';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import FolderIcon from '@material-ui/icons/Folder';
import model from '../../services/model';
import { captureError } from '../../services/error_reporting';
import { toCurrency } from '../../services/format';
import { Asset, DiskAsset, NodeAsset } from '../../types/asset';

type NodeAssetDetailsProp = {
  nodeAsset: NodeAsset;
  currency: string;
  hours: number;
};

const NodeAssetDetails = ({
  nodeAsset,
  currency,
  hours,
}: NodeAssetDetailsProp): React.ReactElement => {
  const { cpuCores, ramBytes, gpuCount, cpuCost, ramCost, gpuCost } = nodeAsset;
  let { discount } = nodeAsset;

  discount = 1 - discount;

  let costPerCpuPerHour = 0;
  let costPerRamGBHour = 0;
  let costPerGPUHour = 0;
  const ramGB = ramBytes / 1024 / 1024 / 1024;
  if (hours !== 0) {
    // if hours or cpuCores is 0, then either we're getting bad data, or the cost is also 0
    // for now, just show 0 (not sure what to show in event that data is just wrong).
    if (cpuCores !== 0) {
      costPerCpuPerHour = (cpuCost / (cpuCores * hours)) * discount;
    } else if (cpuCost) {
      console.warn('cpuCost is nonzero, but core count is 0');
    }

    // if hours or ramGB is 0, then either we're getting bad data, or the cost is also 0
    // for now, just show 0 (not sure what to show in event that data is just wrong).
    if (ramGB !== 0) {
      costPerRamGBHour = (ramCost / (ramGB * hours)) * discount;
    } else if (ramCost) {
      console.warn('ramCost is nonzero, but ramBytes is 0');
    }

    if (gpuCount !== 0) {
      costPerGPUHour = gpuCost / (gpuCount * hours);
    } else if (ramCost) {
      console.warn('gpuCost is nonzero, but gpuCount is 0');
    }
  }
  return (
    <>
      <Box p={1} />
      <Typography variant="h6">Node Breakdown</Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left" component="th" scope="row" width={200}>
              Resource
            </TableCell>
            <TableCell align="right" component="th" scope="row">
              Amount
            </TableCell>
            <TableCell align="right" component="th" scope="row">
              Hourly Rate
            </TableCell>
            <TableCell align="right" component="th" scope="row">
              Cost
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left" component="th" scope="row" width={200}>
              CPU
            </TableCell>
            <TableCell align="right" component="th" scope="row">
              {cpuCores} Cores
            </TableCell>
            <TableCell align="right" component="th" scope="row">
              {toCurrency(costPerCpuPerHour, currency, 3)}/CoreHr
            </TableCell>
            <TableCell align="right" component="th" scope="row">
              {toCurrency(cpuCost * discount, currency, 2)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" component="th" scope="row" width={200}>
              RAM
            </TableCell>
            <TableCell align="right" component="th" scope="row">
              {round(ramGB, 3)} GiB
            </TableCell>
            <TableCell align="right" component="th" scope="row">
              {toCurrency(costPerRamGBHour, currency, 3)}/GiBHr
            </TableCell>
            <TableCell align="right" component="th" scope="row">
              {toCurrency(ramCost * discount, currency, 2)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" component="th" scope="row" width={200}>
              GPU
            </TableCell>
            <TableCell align="right" component="th" scope="row">
              {gpuCount} GPU
            </TableCell>
            <TableCell align="right" component="th" scope="row">
              {toCurrency(costPerGPUHour, currency, 3)}/GPUHR
            </TableCell>
            <TableCell align="right" component="th" scope="row">
              {toCurrency(gpuCost, currency, 2)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

type DiskAssetDetailsProp = {
  diskAsset: DiskAsset;
  currency: string;
  hours: number;
};

const DiskAssetDetails = ({
  diskAsset,
  currency,
  hours,
}: DiskAssetDetailsProp): React.ReactElement => {
  const totalCost = get(diskAsset, 'totalCost', 0);
  const bytes = get(diskAsset, 'bytes', 0);

  let costPerGBHour = 0;
  const gb = bytes / 1024 / 1024 / 1024;
  if (hours !== 0) {
    // if hours or ramGB is 0, then either we're getting bad data, or the cost is also 0
    // for now, just show 0 (not sure what to show in event that data is just wrong).
    if (gb !== 0) {
      costPerGBHour = totalCost / (gb * hours);
    } else if (totalCost !== 0) {
      console.warn('totalCost is nonzero, but bytes is 0');
    }
  }
  return (
    <>
      <Box p={1} />
      <Typography variant="h6">Disk Breakdown</Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left" component="th" scope="row">
              Space
            </TableCell>
            <TableCell align="right" component="th" scope="row">
              Hourly Rate
            </TableCell>
            <TableCell align="right" component="th" scope="row">
              Cost
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left" component="th" scope="row">
              {round(gb, 3)} GiB
            </TableCell>
            <TableCell align="right" component="th" scope="row">
              {toCurrency(costPerGBHour, currency, 3)}/GiBHr
            </TableCell>
            <TableCell align="right" component="th" scope="row">
              {toCurrency(totalCost, currency, 2)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

type AssetListItemProps = {
  tooltip: string;
  value: string;
  icon: React.ReactElement;
};

const AssetPropertiesItem = ({
  tooltip,
  value,
  icon,
}: AssetListItemProps): React.ReactElement => (
  <Grid item xs={4}>
    <ListItem>
      <ListItemIcon>
        <Tooltip title={tooltip} placement="bottom">
          {icon}
        </Tooltip>
      </ListItemIcon>
      <ListItemText primary={value} />
    </ListItem>
  </Grid>
);

type DetailsProps = {
  window: string;
  currency: string;
  asset: Asset;
};

const Details = ({
  window,
  currency,
  asset,
}: DetailsProps): React.ReactElement => {
  const [fetch, setFetch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [hours, setHours] = useState(0);
  const [costPerHr, setCostPerHr] = useState(0);
  const [accAsset, setAccAsset] = useState<Asset>();
  const properties = get(asset, 'properties', {});
  const category = get(properties, 'category', '');
  const cluster = get(properties, 'cluster', '');
  const provider = get(properties, 'provider', '');
  const account = get(properties, 'account', '');
  const project = get(properties, 'project', '');
  const region = get(properties, 'region', '');
  const providerID = get(properties, 'providerID', '');
  const service = get(properties, 'service', '');
  const type = get(asset, 'type', '');
  let storageClass = '';
  let nodeType = '';

  if (type === 'Node') {
    nodeType = get(asset, 'nodeType', '');
    if (!nodeType) {
      nodeType = 'Unknown';
    }
  }

  if (type === 'Disk') {
    storageClass = get(asset, 'storageClass', '');
    if (!storageClass) {
      storageClass = 'Unknown';
    }
  }

  useEffect(() => {
    if (fetch) {
      fetchData();
    }
  }, [fetch]);

  async function fetchData() {
    setLoading(true);

    const filterProps: Record<string, string> = {
      category,
      cluster,
      providerID,
      service,
      type,
    };
    const filters = getRequestFilters(filterProps);

    try {
      const resp = await model.getAssets(window, {
        filters,
        accumulate: true,
      });
      // After filtering and accumulation there should only be one asset in return
      if (resp.data && resp.data.length > 0) {
        const rowData: Record<string, Asset> = resp.data[0];
        const rowKeys = Object.keys(rowData);
        if (rowKeys.length > 0) {
          setAccAsset(rowData[rowKeys[0]]);
          const hrs = get(rowData[rowKeys[0]], 'minutes', 0) / 60.0;
          setHours(hrs);
          if (hrs !== 0) {
            setCostPerHr(rowData[rowKeys[0]].totalCost / hrs);
          }
        }
      }
      setLoading(false);
      setFetch(false);
    } catch (err) {
      console.warn(err);
      captureError(err as Error);
    }
  }

  function getRequestFilters(props: Record<string, string>) {
    return Object.entries(props)
      .filter((entry) => entry[1])
      .map(([property, value]) => ({ property, value }));
  }

  if (loading) {
    return <>Loading...</>;
  }

  if (accAsset) {
    return (
      <>
        <Grid container>
          {cluster && (
            <AssetPropertiesItem
              tooltip="Cluster"
              value={cluster}
              icon={<ClusterIcon />}
            />
          )}
          {provider && (
            <AssetPropertiesItem
              tooltip="Provider"
              value={provider}
              icon={<CloudIcon />}
            />
          )}
          {account && (
            <AssetPropertiesItem
              tooltip="Account"
              value={account}
              icon={<AccountBoxIcon />}
            />
          )}
          {project && (
            <AssetPropertiesItem
              tooltip="Project"
              value={project}
              icon={<FolderIcon />}
            />
          )}
          {service && (
            <AssetPropertiesItem
              tooltip="Service"
              value={service}
              icon={<DesktopWindowsIcon />}
            />
          )}
          {category && (
            <AssetPropertiesItem
              tooltip="Category"
              value={category}
              icon={<CategoryIcon />}
            />
          )}
          {region && (
            <AssetPropertiesItem
              tooltip="Region"
              value={region}
              icon={<MapIcon />}
            />
          )}
          {type === 'Node' && nodeType && (
            <AssetPropertiesItem
              tooltip="Node Type"
              value={nodeType}
              icon={<NodeIcon />}
            />
          )}
          {type === 'Disk' && storageClass && (
            <AssetPropertiesItem
              tooltip="Storage Class"
              value={storageClass}
              icon={<StorageIcon />}
            />
          )}
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left" component="th" scope="row" width={200}>
                Hours
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                Discount
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                Adjustment
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                Hourly Rate
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                Total Cost
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left" component="th" scope="row" width={200}>
                {round(hours, 5)}
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                {get(accAsset, 'discount', 0)}
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                {toCurrency(get(accAsset, 'adjustment', 0), currency, 3)}
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                {toCurrency(costPerHr, currency, 3)}
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                {toCurrency(get(accAsset, 'totalCost', 0), currency, 2)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {type === 'Node' && (
          <NodeAssetDetails
            nodeAsset={accAsset as NodeAsset}
            currency={currency}
            hours={hours}
          />
        )}
        {type === 'Disk' && (
          <DiskAssetDetails
            diskAsset={accAsset as DiskAsset}
            currency={currency}
            hours={hours}
          />
        )}
      </>
    );
  }

  return <TableContainer />;
};

export default memo(Details);
