import { Alert } from '../../services/alerts';

export interface InspectedResourceListItem {
  name: string;
  type: string;
}

interface InspectStaticState {
  inspectedResourceList: InspectedResourceListItem[] | null;
  shareTenancyCosts: boolean | null; // shareTenancyCosts is either the string value 'true' or 'false'
  sharedNamespaces: any;
  sharedLabels: string[];
  shareCost: number;
  currency: string;
}

export const initialStaticState: InspectStaticState = {
  inspectedResourceList: null,
  shareTenancyCosts: null,
  sharedNamespaces: [],
  sharedLabels: [],
  shareCost: 0,
  currency: 'USD',
};

interface InspectDynamicState {
  loading: boolean;
  allocationOverTime: any;
  allocationSummary: any;
  externalCosts: any;
  alertsData: Alert[];
  efficiencyData?: any;
  controllers: any;
}

export const initialDynamicState: InspectDynamicState = {
  loading: true,
  allocationOverTime: null,
  allocationSummary: null,
  externalCosts: null,
  alertsData: [],
  efficiencyData: null,
  controllers: null,
};
