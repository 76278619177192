import React, { FC } from 'react';

import { NavIconProps } from './types';

const ReportsNavIcon: FC<NavIconProps> = ({ active, ...args }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...args}
    >
      <rect
        x="5"
        y="11"
        width="5"
        height="14"
        rx="1"
        fill={active ? '#63E892' : '#607971'}
      />
      <rect
        x="12.647"
        y="5"
        width="5"
        height="20"
        rx="1"
        fill={active ? '#63E892' : '#607971'}
      />
      <rect
        x="20"
        y="13"
        width="5"
        height="12"
        rx="1"
        fill={active ? '#63E892' : '#607971'}
      />
    </svg>
  );
};

export default ReportsNavIcon;
