// react
import React, { useContext } from 'react';

// mui
import SearchIcon from '@material-ui/icons/Search';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import SettingsIcon from '@material-ui/icons/Settings';

// holster
import { Typography } from '@kubecost-frontend/holster';

// local
import AlertsNavIcon from '../../assets/images/alerts-nav-icon';
import AllocationNavIcon from '../../assets/images/allocation-nav-icon';
import AssetsNavIcon from '../../assets/images/assets-nav-icon';
import HealthNavIcon from '../../assets/images/health-nav-icon';
import OverviewNavIcon from '../../assets/images/overview-nav-icon';
import ReportsNavIcon from '../../assets/images/reports-nav-icon';
import SavingsNavIcon from '../../assets/images/savings-nav-icon';
import logo from '../../images/kubecost-full-logo.png';

import NavItem from './NavItem';
import { SearchBarContext } from '../Page';
import ContextSwitcher from './ContextSwitcher';
import useEnablements, { EnablementKeys } from '../../hooks/useEnablements';
import { CloudNavIcon } from '../../assets/images/cloud-nav-icon';

const Nav = ({ active }: { active: string }) => {
  const { data: enablements, isError: isEnablementsError } = useEnablements();
  // Search box
  const { setIsVisible } = useContext(SearchBarContext);

  const top = [
    { name: 'Overview', href: 'overview', icon: OverviewNavIcon },
    {
      name: 'Allocations',
      href: 'allocations',
      icon: AllocationNavIcon,
    },
    { name: 'Assets', href: 'assets', icon: AssetsNavIcon },
    { name: 'Savings', href: 'savings', icon: SavingsNavIcon },
    { name: 'Health', href: 'health', icon: HealthNavIcon },
    { name: 'Reports', href: 'reports', icon: ReportsNavIcon },
    { name: 'Alerts', href: 'alerts', icon: AlertsNavIcon },
  ];

  if (enablements && enablements[EnablementKeys.cloudCosts]?.isEnabled) {
    top.splice(3, 0, {
      name: 'Cloud Costs',
      href: 'cloud',
      icon: CloudNavIcon,
    });
  }

  return (
    <div className="bg-kc-background w-[240px] shrink-0 grow-0">
      <nav className="fixed w-[240px] p-4 flex flex-col h-full">
        <img className="w-[135px] mb-4 mt-6 mx-5" src={logo} alt="Kubecost" />

        <div className="grow">
          {top.map((l) => (
            <NavItem key={l.name} active={active === `/${l.href}`} {...l} />
          ))}
        </div>
        <div>
          <ContextSwitcher />
          <div
            className="flex items-center p-4"
            onClick={(e: React.SyntheticEvent) => {
              e.stopPropagation();
              setIsVisible(true);
            }}
          >
            <SearchIcon className="mr-4" />
            <Typography
              variant="p"
              className="font-bold hover:text-kc-primary cursor-pointer"
            >
              Search Docs
            </Typography>
          </div>
          <a href="settings">
            <div className="flex items-center p-4">
              <SettingsIcon className="mr-4" />
              <Typography
                variant="p"
                className="font-bold hover:text-kc-primary cursor-pointer"
              >
                Settings
              </Typography>
            </div>
          </a>
          {document.cookie.indexOf('token=') != -1 && (
            <div
              className="flex items-center p-4"
              onClick={(e: React.SyntheticEvent) => {
                window.location.href = '/logout';
              }}
            >
              <MeetingRoomIcon className="mr-4" />
              <Typography
                variant="p"
                className="font-bold hover:text-kc-primary cursor-pointer"
              >
                Logout
              </Typography>
            </div>
          )}
        </div>
      </nav>
    </div>
  );
};

export default Nav;
