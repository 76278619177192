// react
import React, { SyntheticEvent } from 'react';

// react-router
import { Link } from 'react-router-dom';

// holster
import { Typography } from '@kubecost-frontend/holster';

// local
import { NavIconProps } from '../../assets/images/types';

const activeBoxShadowCSS =
  '0 1px 2px 0 rgba(0,0,0,0.08), 0 1px 1.5px 0 rgba(0,0,0,0.07), 0 2px 5px 0 rgba(48,49,61,0), 0 0 1px 0 rgba(48,49,61,0)';

interface NavItemProps {
  name: string;
  active: boolean;
  href?: string;
  icon: React.FC<NavIconProps>;
  onClick?: () => void;
  secondary?: string;
  title?: string;
}

const NavItem = ({
  active,
  name,
  href,
  icon,
  secondary,
  title,
  onClick,
}: NavItemProps) => {
  const Icon = icon;

  const ListItemCore = () => (
    <div
      className={`flex items-center px-4 py-1 my-3 rounded ${
        active ? 'text-kc-gray-300' : 'text-kc-gray-200'
      } hover:text-kc-gray-300`}
      onClick={(e: SyntheticEvent) => {
        if (onClick) {
          onClick();
          e.stopPropagation();
          return;
        }
      }}
      style={
        active
          ? { backgroundColor: 'white', boxShadow: activeBoxShadowCSS }
          : {}
      }
      title={title}
    >
      <Icon active={active} className="mr-4" />
      <Typography variant="p" className={`font-bold`}>
        {name}
      </Typography>
    </div>
  );

  return (
    <>
      {href && !active ? (
        <Link
          to={`${href}`}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <ListItemCore />
        </Link>
      ) : (
        <ListItemCore />
      )}
    </>
  );
};

export default NavItem;
