import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import { grey, green } from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Tune';
import { makeStyles } from '@material-ui/styles';
import filter from 'lodash/filter';
import isArray from 'lodash/isArray';
import sortBy from 'lodash/sortBy';
import trim from 'lodash/trim';
import React, { useState } from 'react';
import { toCurrency } from '../../../services/format';
import { useClusters } from '../../../contexts/ClusterConfig';

const useStyles = makeStyles({
  chip: {
    marginRight: 4,
    marginBottom: 4,
  },
  chipIcon: {
    paddingLeft: 4,
    paddingRight: 2,
  },
  form: {
    padding: 18,
    display: 'flex',
    flexFlow: 'column',
  },
  formControl: {
    margin: 8,
    minWidth: 120,
  },
});

const EditControl = ({
  chartDisplayOptions,
  chartDisplay,
  setChartDisplay,
  idleOptions,
  idle,
  setIdle,
  filters,
  setFilters,
  customSharedOverhead,
  defaultSharedOverhead,
  customShareNamespaces,
  defaultShareNamespaces,
  customShareLabels,
  defaultShareLabels,
  shareSplitOptions,
  shareSplit,
  setShareSplit,
  shareTenancyCosts,
  rateOptions,
  rate,
  setRate,
  setSharedOverhead,
  setSharedNamespaces,
  setSharedLabels,
}) => {
  const classes = useStyles();
  const { modelConfig } = useClusters();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [filterProperty, setFilterProperty] = useState('namespace');
  const [filterValue, setFilterValue] = useState('');

  const [sharedLocalOverhead, setSharedLocalOverhead] = useState('');
  const [sharedLocalNamespaces, setSharedLocalNamespaces] = useState('');
  const [sharedLocalLabels, setSharedLocalLabels] = useState('');

  const filterPropertyOptions = [
    'cluster',
    'node',
    'namespace',
    'label',
    'service',
    'controller',
    'controller kind',
    'pod',
  ];

  const handleAddFilter = (newFilter) => {
    // remove existing filters using the newFilter's property (overwrite it)
    const oldFilters = isArray(filters) ? filters : [];

    // modified to allow multiple filter properties of different values
    const fs = filter(oldFilters, (f) => f.value !== newFilter.value);

    // sanitize comma-separated values
    const vals = sortBy(
      newFilter.value.split(',').map((v) => trim(v).replace('=', ':')),
      (str) => str,
    );
    newFilter.value = vals.join(', ');

    if (newFilter.value.length > 0) {
      setFilters(sortBy([...fs, newFilter], 'property'));
    }

    setFilterValue('');
  };

  const handleLocalOverhead = (overhead) => {
    if (overhead == null) {
      setSharedOverhead(overhead);
    } else if (overhead == '' || overhead == customSharedOverhead) {
      // Do nothing
      // This interprets an empty field as no change
    } else {
      setSharedOverhead(parseFloat(overhead));
    }
    setSharedLocalOverhead('');
  };

  const handleLocalSharedNamespaces = (namespaces) => {
    if (namespaces == null) {
      setSharedNamespaces(namespaces);
    } else {
      const vals = filter(
        namespaces.split(',').map((ns) => trim(ns)),
        (ns) => ns !== '',
      );
      setSharedNamespaces(vals);
    }
    setSharedLocalNamespaces('');
  };

  const handleLocalSharedLabels = (labels) => {
    if (labels == null) {
      setSharedLabels(labels);
    } else {
      const vals = filter(
        labels.split(',').map((lbl) => trim(lbl)),
        (lbl) => lbl !== '',
      );
      setSharedLabels(vals);
    }
    setSharedLocalLabels('');
  };

  const handleDeleteFilter = (delFilter) => {
    const oldFilters = isArray(filters) ? filters : [];
    setFilters(
      filter(
        oldFilters,
        (f) =>
          !(f.property === delFilter.property && f.value === delFilter.value),
      ),
    );
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'edit-form' : undefined;

  const hasSharedResources =
    (defaultShareNamespaces != null && defaultShareNamespaces.length > 0) ||
    defaultShareLabels.length > 0 ||
    defaultSharedOverhead > 0 ||
    shareTenancyCosts;

  return (
    <>
      <Tooltip title="Edit report">
        <IconButton
          id="allocation-edit-report-button"
          aria-describedby={id}
          onClick={handleClick}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.form}>
          <div>
            <FormControl className={classes.formControl}>
              <InputLabel id="share-idle-label">Idle costs</InputLabel>
              <Select
                id="share-idle"
                value={idle}
                onChange={(e) => setIdle(e.target.value)}
              >
                {idleOptions.map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="accumulate-label">Chart</InputLabel>
              <Select
                id="accumulate"
                value={chartDisplay}
                onChange={(e) => setChartDisplay(e.target.value)}
              >
                {chartDisplayOptions.map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel>Cost metric</InputLabel>
              <Select value={rate} onChange={(e) => setRate(e.target.value)}>
                {rateOptions.map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <Typography
              variant="subtitle2"
              display="block"
              style={{ paddingTop: 12 }}
            >
              Filters
            </Typography>
            {isArray(filters) &&
              filters.map((filter, i) => (
                <Chip
                  key={i}
                  className={classes.chip}
                  icon={
                    <div className={classes.chipIcon}>{filter.property}</div>
                  }
                  label={filter.value}
                  onDelete={() => handleDeleteFilter(filter)}
                />
              ))}
          </div>
          <div>
            <FormControl className={classes.formControl}>
              <Select
                id="filter-property"
                value={filterProperty}
                onChange={(e) => setFilterProperty(e.target.value)}
              >
                {filterPropertyOptions.map((opt) => (
                  <MenuItem key={opt} value={opt}>
                    {opt}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="filter-value"
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleAddFilter({
                      property: filterProperty,
                      value: filterValue,
                    });
                  }
                }}
              />
            </FormControl>
            <IconButton
              onClick={() =>
                handleAddFilter({
                  property: filterProperty,
                  value: filterValue,
                })
              }
            >
              <AddIcon />
            </IconButton>
          </div>
          <Typography
            variant="caption"
            display="block"
            style={{ marginLeft: 8 }}
          >
            e.g. cluster "dev", namespace "kubecost, kube-system", label
            "app:cost-analyzer"
          </Typography>
          <div>
            <Typography
              variant="subtitle2"
              display="block"
              style={{ paddingTop: 16 }}
            >
              Default shared resources
            </Typography>
            {!hasSharedResources && (
              <Typography
                variant="caption"
                display="block"
                style={{ marginLeft: 8 }}
              >
                Add a shared namespace, label, or fixed cost in settings
              </Typography>
            )}
            {defaultShareNamespaces != null &&
              defaultShareNamespaces.length > 0 && (
                <Chip
                  className={classes.chip}
                  icon={<div className={classes.chipIcon}>namespace</div>}
                  label={defaultShareNamespaces.join(', ')}
                  variant={
                    customShareNamespaces != null ? 'outlined' : 'default'
                  }
                  style={
                    customShareNamespaces != null
                      ? { textDecoration: 'line-through' }
                      : {}
                  }
                />
              )}
            {defaultShareLabels != null && defaultShareLabels.length > 0 && (
              <Chip
                className={classes.chip}
                icon={<div className={classes.chipIcon}>label</div>}
                label={defaultShareLabels.join(', ')}
                variant={customShareLabels != null ? 'outlined' : 'default'}
                style={
                  customShareLabels != null
                    ? { textDecoration: 'line-through' }
                    : {}
                }
              />
            )}
            {defaultSharedOverhead > 0 && (
              <Chip
                className={classes.chip}
                icon={<div className={classes.chipIcon}>cost</div>}
                label={`${toCurrency(
                  defaultSharedOverhead,
                  modelConfig.currencyCode,
                )}/mo.`}
                variant={customSharedOverhead != null ? 'outlined' : 'default'}
                style={
                  customSharedOverhead != null
                    ? { textDecoration: 'line-through' }
                    : {}
                }
              />
            )}
            {shareTenancyCosts && (
              <Tooltip title="Sharing tenancy costs, such as cluster management fees, is enabled. Visit the Settings page to disable.">
                <Chip className={classes.chip} label="Tenancy costs" />
              </Tooltip>
            )}
          </div>
          <div>
            <Typography
              variant="subtitle2"
              display="block"
              style={{ paddingTop: 16 }}
            >
              Custom shared resources
            </Typography>
            {!(
              customShareNamespaces != null ||
              customShareLabels != null ||
              customSharedOverhead != null
            ) && (
              <Typography
                variant="caption"
                display="block"
                style={{ marginLeft: 8 }}
              >
                Add a custom shared namespace, label, or fixed cost below.
              </Typography>
            )}
            {customShareNamespaces != null && (
              <Chip
                className={classes.chip}
                icon={<div className={classes.chipIcon}>namespace</div>}
                color="primary"
                style={{ backgroundColor: green[400] }}
                label={
                  customShareNamespaces.length > 0
                    ? customShareNamespaces.join(', ')
                    : '—'
                }
                onDelete={() => handleLocalSharedNamespaces(null)}
              />
            )}
            {customShareLabels != null && (
              <Chip
                className={classes.chip}
                icon={<div className={classes.chipIcon}>label</div>}
                color="primary"
                style={{ backgroundColor: green[400] }}
                label={
                  customShareLabels.length > 0
                    ? customShareLabels.join(', ')
                    : '—'
                }
                onDelete={() => handleLocalSharedLabels(null)}
              />
            )}
            {customSharedOverhead != null && (
              <Chip
                className={classes.chip}
                icon={<div className={classes.chipIcon}>cost</div>}
                color="primary"
                style={{ backgroundColor: green[400] }}
                label={`${toCurrency(
                  customSharedOverhead,
                  modelConfig.currencyCode,
                )}/mo.`}
                onDelete={() => handleLocalOverhead(null)}
              />
            )}
          </div>
          <Typography
            variant="subtitle2"
            display="block"
            style={{ paddingTop: 12 }}
          >
            Configure custom shared resouces
            <Tooltip
              title="These fields allow configuration of shared values outside of the settings page. These values will change
              the current report view, but will not change the underlying values in the cost model, and will be saved with a custom saved report."
              placement="right"
            >
              <InfoIcon
                style={{ fontSize: 12, color: grey[500], margin: '0 4px' }}
              />
            </Tooltip>
          </Typography>
          <div>
            <FormControl className={classes.formControl}>
              <TextField
                style={{ paddingBottom: 12 }}
                type="number"
                label="Custom shared overhead cost"
                InputLabelProps={{ shrink: true }}
                id="local-overhead"
                value={sharedLocalOverhead}
                onChange={(e) => setSharedLocalOverhead(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleLocalOverhead(sharedLocalOverhead);
                  }
                }}
              />
            </FormControl>
          </div>
          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-between',
            }}
          >
            <FormControl className={classes.formControl}>
              <TextField
                label="Custom shared namespaces"
                InputLabelProps={{ shrink: true }}
                id="local-shared-namespaces"
                value={sharedLocalNamespaces}
                onChange={(e) => setSharedLocalNamespaces(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleLocalSharedNamespaces(sharedLocalNamespaces);
                  }
                }}
              />
            </FormControl>
          </div>

          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-between',
            }}
          >
            <FormControl className={classes.formControl}>
              <TextField
                label="Custom shared labels"
                InputLabelProps={{ shrink: true }}
                id="local-shared-labels"
                value={sharedLocalLabels}
                onChange={(e) => setSharedLocalLabels(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleLocalSharedLabels(sharedLocalLabels);
                  }
                }}
              />
            </FormControl>
          </div>

          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-between',
            }}
          >
            <FormControl className={classes.formControl}>
              <Select
                id="shareSplit"
                value={shareSplit}
                onChange={(e) => setShareSplit(e.target.value)}
              >
                {shareSplitOptions.map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button href="settings.html" startIcon={<SettingsIcon />}>
              Settings
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default React.memo(EditControl);
