import React, { useMemo } from 'react';

import { useAggregationParamState } from '../../hooks/useAggregationParamState';

import { ActionMenu, ActionMenuOnClickItem } from './ActionMenu';

interface AggregationMenuProps<AggregationType extends string> {
  isMultiSelect?: boolean;
  validItems: Record<AggregationType, string>;
}

const AggregationMenu = <AggregationType extends string>({
  isMultiSelect = false,
  validItems,
}: AggregationMenuProps<AggregationType>) => {
  const { aggregationState, isValidParam, labelState, setParamState } =
    useAggregationParamState<AggregationType>(validItems);

  const handleOnClickItem: ActionMenuOnClickItem = (item, menuMode) => {
    // Format aggregation labels correctly
    const param = labelState?.includes(item) ? `label:${item}` : item;

    // Type guard against invalid types
    if (!isValidParam(param)) return;

    // Set our default value if we do nothing else.
    let newSelectedItems = [param];

    if (menuMode === 'multi') {
      // Append previous `selectedItems` to preserve state in multi select mode
      newSelectedItems = [...(aggregationState ?? []), ...newSelectedItems];

      if (aggregationState?.includes(param)) {
        // Toggle `item` "off" if previously selected
        newSelectedItems = newSelectedItems.filter((f) => f !== param);
      }
    }

    setParamState({ agg: newSelectedItems });
  };

  const handleOnClickAddItem: ActionMenuOnClickItem = (label, menuMode) => {
    // Set our formatted default value and type guard against invalid types
    let newSelectedItems: AggregationType[] = [`label:${label}`].filter(
      isValidParam,
    );

    /* Prevent duplicate items
     * TODO: Show UI validation error instead */
    if (aggregationState?.includes(newSelectedItems[0])) return;

    if (menuMode === 'multi') {
      // Append previous `selectedItems` to preserve state in multi select mode
      newSelectedItems = [...(aggregationState ?? []), ...newSelectedItems];
    }

    setParamState({
      agg: newSelectedItems,
      labels: [...(labelState ?? []), label],
    });
  };

  const getSelectedItems = useMemo(
    () => aggregationState?.map((item) => item.replace('label:', '')),
    [aggregationState],
  );

  return (
    <ActionMenu
      customItems={labelState}
      items={validItems}
      isMultiSelect={isMultiSelect}
      selectedItems={getSelectedItems}
      onClickItem={handleOnClickItem}
      onClickAddItem={handleOnClickAddItem}
    />
  );
};

export { AggregationMenu };
