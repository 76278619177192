// react
import React, { FC, FocusEvent, useState } from 'react';

// lodash
import sortBy from 'lodash/sortBy';

// holster
import { Button, Input, Menu } from '@kubecost-frontend/holster';
import { MenuItemProps } from '@kubecost-frontend/holster/dist/components/Menu';

// local
import { CloseIcon, FilterIcon, PlusIcon } from '../../../assets/images';
import { FilterType } from './types';

interface AddFilterProps {
  handleAddFilter: () => void;
  handleDeleteFilter: () => void;
  handleUpdateFilter: (f: FilterType) => void;
  property: string;
  propertyOptions: string[];
  value: string;
  count: number;
  index: number;
}

export const AddFilter: FC<AddFilterProps> = ({
  property,
  propertyOptions,
  value,
  handleAddFilter,
  handleDeleteFilter,
  handleUpdateFilter,
  count,
  index,
}) => {
  const [openMenu, setOpenMenu] = useState(false);

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu);
  };

  const handleSetFilterProperty = (property: MenuItemProps) => {
    handleUpdateFilter({ property: property.text, value });
    setOpenMenu(false);
  };

  const handleSetFilterValue = (
    event: FocusEvent<HTMLInputElement, Element>,
  ) => {
    const val = event.target.value;
    handleUpdateFilter({ property, value: val });
  };

  const handleAddNewFilter = () => {
    handleAddFilter();
    setOpenMenu(false);
  };

  const handleRemoveFilter = () => {
    if (count === 1) {
      return;
    }
    handleDeleteFilter();
  };

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '.3fr 1fr .1fr',
        padding: '1em',
        gap: '10px',
      }}
    >
      <Button variant="default" onClick={handleOpenMenu}>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <div style={{ paddingRight: '.5em', paddingTop: '.2em' }}>
            <FilterIcon />
          </div>
          {property ? property : 'Filters'}
        </div>
      </Button>
      {openMenu ? (
        <Menu
          items={sortBy(propertyOptions, 'asc')}
          onClose={() => setOpenMenu(false)}
          selectItem={handleSetFilterProperty}
        />
      ) : null}
      <div>
        <Input
          onChange={handleSetFilterValue}
          style={{ width: '100%', paddingLeft: '1em' }}
          value={value}
        />
      </div>

      <div style={{ display: 'flex', gap: '5px' }}>
        {index === count - 1 && (
          <Button variant="default" onClick={handleAddNewFilter}>
            <PlusIcon />
          </Button>
        )}
        <Button
          variant="default"
          onClick={handleRemoveFilter}
          disabled={count <= 1}
        >
          <CloseIcon />
        </Button>
      </div>
    </div>
  );
};
