// react
import React, { FC } from 'react';

// holster
import { Button, Modal, Typography } from '@kubecost-frontend/holster';

// local
import { Reports } from './types';

interface OpenControlProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  reports: Reports[];
  selectReport: (report: Reports) => void;
}

export const OpenControl: FC<OpenControlProps> = ({
  open,
  setOpen,
  reports,
  selectReport,
}) => {
  return (
    <Modal open={open} onClose={() => setOpen(false)} title="Open Report">
      {!reports.length ? (
        <Typography variant="p">
          Saved reports will be listed here. Click the "Save report" button to
          save the current report.
        </Typography>
      ) : (
        reports.map((report) => {
          return (
            <Typography
              className="cursor-pointer hover:bg-kc-primary/25 rounded p-2 flex items-center"
              variant="p"
              key={report.key}
              onClick={() => {
                selectReport(report);
                setOpen(false);
              }}
            >
              {report.title}
            </Typography>
          );
        })
      )}
      <div className="flex justify-end">
        <Button variant="default" onClick={() => setOpen(false)}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

const OpenControlMemoized = React.memo(OpenControl);
export default OpenControlMemoized;
