import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  Typography,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import RefreshIcon from '@material-ui/icons/Refresh';
import { makeStyles } from '@material-ui/styles';
import get from 'lodash/get';
import map from 'lodash/map';
import React, { useEffect, useState } from 'react';
import ETLFileList from '../../components/ETL/ETLFileList';
import Header, { Breadcrumb } from '../../components/Header';
import Loading from '../../components/Loading';
import Section from '../../components/PaperSection';
import Warnings, { Warning } from '../../components/Warnings';
import cluster from '../../services/cluster';
import diagnostics, {
  ETLFile,
  ETLStatus,
  NodeCount,
  PricingSourceStatus,
  PrometheusJob,
  PrometheusMetric,
  RequestQueueStatus,
} from '../../services/diagnostics';
import model from '../../services/model';
import { isUnsupportedVersion, isUpdateAvailable } from '../../services/util';
import { captureError } from '../../services/error_reporting';
import AppInfo from './AppInfo';
import MessageBox from './MessageBox';
import PricingSource from './PricingSource';
import DiagnosticsPrometheusMetric from './DiagnosticsPrometheusMetric';
import DiagnosticsPrometheusTarget from './DiagnosticsPrometheusTarget';
import RequestQueue from './RequestQueue';
import CloudIntegrationStatusPanel from './CloudIntegration';
import { BackupStatusPanel } from './BackupStatus/BackupStatusPanel';
import { NoCacheMessage } from './NoCacheMessage';
import { useClusters } from '../../contexts/ClusterConfig';

const Breadcrumbs = [
  { name: 'Settings', href: 'settings.html' },
  { name: 'Diagnostics', href: 'diagnostics.html' },
];

const KubecostUpdateURL =
  'https://github.com/kubecost/cost-analyzer-helm-chart/releases';
const KubecostETLDoc =
  'https://github.com/kubecost/docs/blob/master/diagnostics.md#kubecost-etl-pipeline-metrics';

const useStyles = makeStyles({
  page: {
    minWidth: '700px',
    maxWidth: '1200px',
    width: '95%',
    margin: 'auto',
  },
  card: {
    margin: '12px 12px 12px 12px',
    display: 'flex',
    flexFlow: 'column',
    padding: '12px 0px 12px 0px',
  },
  description: {
    padding: '24px 36px',
    marginBottom: 20,
  },
});

const DiagnosticsPage = () => {
  const classes = useStyles();

  const [fetch, setFetch] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [grafanaURL, setGrafanaURL] = useState('');
  const [pricingSources, setPricingSources] = useState<
    Array<PricingSourceStatus>
  >([]);
  const [prometheusTargets, setPrometheusTargets] = useState<
    Record<string, PrometheusJob>
  >({});
  const [prometheusMetrics, setPrometheusMetrics] = useState<
    Array<PrometheusMetric>
  >([]);
  const [prometheusRequestQueue, setPrometheusRequestQueue] =
    useState<RequestQueueStatus>({} as RequestQueueStatus);
  const [etlAllocationDailyStatus, setETLAllocationDailyStatus] =
    useState<ETLStatus>({} as ETLStatus);
  const [etlAllocationHourlyStatus, setETLAllocationHourlyStatus] =
    useState<ETLStatus>({} as ETLStatus);
  const [etlAssetsDailyStatus, setETLAssetsDailyStatus] = useState<ETLStatus>(
    {} as ETLStatus,
  );
  const [etlAssetsHourlyStatus, setETLAssetsHourlyStatus] = useState<ETLStatus>(
    {} as ETLStatus,
  );
  const [etlCloudStatus, setETLCloudStatus] = useState({});
  const [etlDisabled, setETLDisabled] = useState(false);
  const [etlStatusOpen, setETLStatusOpen] = useState(false);
  const [etlMessageType, setETLMessageType] = useState('');
  const [etlStatusTitle, setETLStatusTitle] = useState('');
  const [etlMessages, setETLMessages] = useState<Array<string>>([]);
  const [isThanosEnabled, setThanosEnabled] = useState(false);
  const [thanosOffset, setThanosOffset] = useState('');
  const [thanosMetrics, setThanosMetrics] = useState<Array<PrometheusMetric>>(
    [],
  );
  const [thanosRequestQueue, setThanosRequestQueue] =
    useState<RequestQueueStatus>({} as RequestQueueStatus);
  const [loading, setLoading] = useState(true);
  const [isUpdate, setUpdate] = useState(false);
  const [isUnsupported, setUnsupported] = useState(false);
  const [warnings, setWarnings] = useState<Array<Warning>>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<Array<Breadcrumb>>([]);
  const [openedETLBlock, setOpenedETLBlock] = useState<ETLFile | null>(null);
  const [repairingETL, setRepairingETL] = useState(false);
  const [nodeCount, setNodeCount] = useState<NodeCount>({} as NodeCount);
  const [savingsDiagnostics, setSavingsDiagnostics] = useState({});
  const [savingsError, setSavingsError] = useState('');
  const [savingsErrorTitle, setSavingsErrorTitle] = useState('');
  const [savingsErrorOpen, setSavingsErrorOpen] = useState(false);
  const [fetchRequestQueueCount, setFetchRequestQueueCount] = useState(0);

  const { apiConfig, grafanaUrl } = useClusters();

  // removes all _leading_ empty files and reverses the order to start
  // with most recent
  const filterETLStatusFiles = (s: ETLStatus): ETLStatus => {
    const status = s;
    for (let i = 0; i < status.files.length; ++i) {
      if (!status.files[i].empty) {
        status.files = status.files.splice(i);
        break;
      }
    }

    status.files.reverse();
    return status;
  };

  const pushWarning = (warn: Warning) => {
    setWarnings((warns: Warning[]) => [...warns, warn]);
  };

  // initializes ETL diagnostics
  const initETLDiagnostics = async () => {
    try {
      let etlAllocDaily = {} as ETLStatus;
      let etlAllocHourly = {} as ETLStatus;
      let etlAssetDaily = {} as ETLStatus;
      let etlAssetHourly = {} as ETLStatus;
      let etlCloud = {};

      const etlStatus = await diagnostics.etlStatus();

      if (etlStatus.allocation) {
        if (etlStatus.allocation['1d']) {
          etlAllocDaily = filterETLStatusFiles(etlStatus.allocation['1d']);
        }
        if (etlStatus.allocation['1h']) {
          etlAllocHourly = filterETLStatusFiles(etlStatus.allocation['1h']);
        }
      }

      if (etlStatus.asset) {
        if (etlStatus.asset['1d']) {
          etlAssetDaily = filterETLStatusFiles(etlStatus.asset['1d']);
        }
        if (etlStatus.asset['1h']) {
          etlAssetHourly = filterETLStatusFiles(etlStatus.asset['1h']);
        }
      }

      if (etlStatus.cloud) {
        etlCloud = etlStatus.cloud;
      }

      setETLAllocationDailyStatus(etlAllocDaily);
      setETLAllocationHourlyStatus(etlAllocHourly);
      setETLAssetsDailyStatus(etlAssetDaily);
      setETLAssetsHourlyStatus(etlAssetHourly);
      setETLCloudStatus(etlCloud);
    } catch (err) {
      console.error(err);
      // Errors here indicate that either ETL backups are disabled, or ETL is disabled
      // So, we'll just disable ETL panels if this happens
      setETLDisabled(true);
    }
  };

  // initialize
  async function initialize() {
    const clusterMap = await model.clusterInfoMap();
    const clusterInfo = await model.clusterInfo();
    const cinfo = get(clusterMap, clusterInfo.id, clusterInfo);
    const clusterNameId = cluster.clusterNameId({
      clusterId: cinfo.id,
      clusterName: cinfo.name,
    });

    const crumbs = Breadcrumbs.concat();
    crumbs[1].name = `Diagnostics [${clusterNameId}]`;

    const update = await isUpdateAvailable();
    setUpdate(update);

    const unsupported = await isUnsupportedVersion();
    setUnsupported(unsupported);

    const grafanaAddress = grafanaUrl;
    setGrafanaURL(
      `${grafanaAddress}/d/L0HBvojWz/prometheus-benchmark-2-17-x?orgId=1`,
    );

    const { thanosEnabled, thanosOffset: offset } =
      cluster.getMultiClusterStatus(clusterInfo);
    setThanosEnabled(thanosEnabled);
    setThanosOffset(offset);

    setBreadcrumbs(crumbs);
    setInitialized(true);
  }

  const initNodeCount = async () => {
    try {
      const nCount = await diagnostics.nodeCount();
      setNodeCount(nCount);
    } catch (err) {
      console.error(err);
    }
  };

  // initialize savings diagnostics
  const initSavingsDiagnostics = async () => {
    try {
      const savingsDiag = await diagnostics.savingsDiagnostics();
      setSavingsDiagnostics(savingsDiag);
    } catch (err) {
      console.error(err);
    }
  };

  // Fetch all diagnostic data
  async function fetchData() {
    if (fetch) {
      setLoading(true);

      try {
        const pricingStatus = await diagnostics.pricingSources();
        setPricingSources(pricingStatus);

        const promTargets = await diagnostics.prometheusTargets();
        setPrometheusTargets(promTargets);

        const { promMetrics, thanMetrics } =
          await diagnostics.prometheusMetrics();
        setPrometheusMetrics(promMetrics);

        if (isThanosEnabled) {
          setThanosMetrics(thanMetrics);
        }

        await fetchRequestQueue();
      } catch (err) {
        console.error(err);
        pushWarning({
          primary: 'Unexpected Failure Running Diagnostics',
          secondary:
            'Please reference: https://github.com/kubecost/docs/blob/master/diagnostics.md',
        });
        captureError(err);
      }

      await initNodeCount();
      await initETLDiagnostics();
      await initSavingsDiagnostics();

      setLoading(false);
      setFetch(false);
    }
  }

  async function fetchRequestQueue() {
    const { promRequestQueue, thanRequestQueue } =
      await diagnostics.requestQueue();
    setPrometheusRequestQueue(promRequestQueue);

    if (isThanosEnabled) {
      setThanosRequestQueue(thanRequestQueue);
    }
    setTimeout(() => {
      setFetchRequestQueueCount(fetchRequestQueueCount + 1);
    }, 5000);
  }
  // Handle an ETL Expand File Clicked
  const handleETLExpandClicked = (
    file: ETLFile,
    etlType: string,
    messages: Array<string>,
  ) => {
    setETLStatusTitle(`ETL Status [${file.name}]`);
    setOpenedETLBlock(file);
    setETLMessages(messages);
    setETLMessageType(etlType);
    setETLStatusOpen(true);
  };

  // Handle Closing the ETL Error Dialog
  const handleETLStatusClose = () => {
    setETLStatusTitle('');
    setETLMessages([]);
    setOpenedETLBlock(null);
    setETLMessageType('');
    setETLStatusOpen(false);
  };

  // Handle opening the savings error window
  const handleOpenSavingsError = (title: string, error: string) => {
    setSavingsError(error);
    setSavingsErrorOpen(true);
    setSavingsErrorTitle(title);
  };

  // Handle Savings Error Open
  const handleSavingsErrorClosed = () => {
    setSavingsError('');
    setSavingsErrorOpen(false);
    setSavingsErrorTitle('');
  };

  // Handle repairing an ETL entry
  const handleETLRepair = async () => {
    // File Name is epoch start -> end
    const dateRange = get(openedETLBlock, 'name', '');
    if (dateRange === '') {
      return;
    }

    setRepairingETL(true);
    if (etlMessageType === 'allocation') {
      await model.etlAllocationRepair(dateRange);
    } else if (etlMessageType === 'assets') {
      await model.etlAssetRepair(dateRange);
    }
    await initETLDiagnostics();

    setRepairingETL(false);

    handleETLStatusClose();
  };

  // Handle fetching data
  useEffect(() => {
    if (!initialized) {
      initialize();
    }
    if (initialized && fetch) {
      fetchData();
    }
  }, [initialized, fetch]);

  // Loops fetch request queue
  useEffect(() => {
    fetchRequestQueue();
  }, [fetchRequestQueueCount]);

  return (
    <>
      <div className={classes.page}>
        <Header breadcrumbs={breadcrumbs}>
          <IconButton aria-label="refresh" onClick={() => setFetch(true)}>
            <RefreshIcon />
          </IconButton>
        </Header>

        {initialized && !loading && warnings.length > 0 && (
          <Warnings warnings={warnings} />
        )}
        {(!initialized || loading) && (
          <Loading message="Running Diagnostics..." />
        )}

        {isUpdate && (
          <MessageBox>
            New app update available. View{' '}
            <Link href={KubecostUpdateURL} target="_blank" rel="noopener">
              releases
            </Link>{' '}
            for more info.
          </MessageBox>
        )}

        {isUnsupported && (
          <MessageBox>
            Your version is below the minimum supported version. Please update. View {' '}
            <Link href={KubecostUpdateURL} target="_blank" rel="noopener">
              releases
            </Link>{' '}
            for more info.
          </MessageBox>
        )} 

        {initialized && !loading && (
          <AppInfo id="appInfo" isUpdate={isUpdate} nodeCount={nodeCount} />
        )}

        {initialized && !loading && (
          <Section
            id="pricingSources"
            title="Pricing Sources"
            info="Diagnostic data for service based pricing sources"
          >
            {pricingSources.length > 0 && (
              <List dense>
                {map(
                  pricingSources,
                  (source) =>
                    source !== null && (
                      <PricingSource key={source.name} source={source} />
                    ),
                )}
              </List>
            )}
            {pricingSources.length === 0 && (
              <Typography variant="body2">
                Additional Pricing Sources Unavailable
              </Typography>
            )}
          </Section>
        )}

        {initialized && !loading && (
          <Section
            id="promTargets"
            title="Prometheus Targets"
            info="Diagnostic tests to verify necessary Prometheus Targets exist"
            buttonText="GRAFANA DASHBOARDS"
            buttonVariant="outlined"
            buttonHref={grafanaURL}
            buttonDisabled={
              apiConfig.grafanaEnabled || apiConfig.grafanaURL
                ? ''
                : 'Grafana is disabled. Enable Grafana to use this feature.'
            }
          >
            {!loading && prometheusTargets.error === undefined && (
              <List dense>
                {map(
                  prometheusTargets,
                  (target, job) =>
                    target !== null && (
                      <DiagnosticsPrometheusTarget
                        key={job}
                        target={target}
                        job={job}
                      />
                    ),
                )}
              </List>
            )}
            {!loading && prometheusTargets.error !== undefined && (
              <Typography variant="body2" style={{ color: red[500] }}>
                <b>Error:</b> {prometheusTargets.error}
              </Typography>
            )}
          </Section>
        )}

        {initialized && !loading && (
          <Section
            id="promMetrics"
            title="Prometheus Metrics"
            info="Diagnostic tests to verify the correct Prometheus metrics and labels exist."
          >
            {prometheusMetrics.length > 0 && (
              <List dense>
                {map(
                  prometheusMetrics,
                  (metric, index) =>
                    metric !== null && (
                      <DiagnosticsPrometheusMetric
                        key={index}
                        metric={metric}
                      />
                    ),
                )}
              </List>
            )}
            {prometheusMetrics.length === 0 && (
              <Typography variant="body2" style={{ color: red[500] }}>
                Error: Prometheus Metrics Unavailable
              </Typography>
            )}
          </Section>
        )}

        {initialized && !loading && prometheusRequestQueue && (
          <Section
            id="prometheusRequestQueue"
            title="Prometheus Request Queue"
            info="Diagnostic for request queues on Prometheus Source"
          >
            {prometheusRequestQueue && (
              <RequestQueue requestQueue={prometheusRequestQueue} />
            )}
            {!prometheusRequestQueue && (
              <Typography variant="body2" style={{ color: red[500] }}>
                Error: Prometheus Request Queue Unavailable
              </Typography>
            )}
          </Section>
        )}

        {initialized && !loading && (
          <Section
            id="durableStorage"
            title="Durable Storage"
            info="Diagnostic tests to verify the correct Thanos metrics and labels exist."
          >
            {!isThanosEnabled && (
              <Typography variant="body1" color="textSecondary">
                Product upgrade required to enable long-term metric retention.{' '}
                <Link
                  target="_blank"
                  href="http://docs.kubecost.com/enterprise"
                >
                  Learn more
                </Link>
                &nbsp;If you are already on an Enterprise license and want to
                set this feature up, contact the team.
              </Typography>
            )}
            {isThanosEnabled && thanosMetrics.length > 0 && (
              <>
                <Typography variant="body1">
                  <b>Offset:</b> {thanosOffset.replace('offset ', '')}
                </Typography>
                <List dense>
                  {map(
                    thanosMetrics,
                    (metric, index) =>
                      metric !== null && (
                        <DiagnosticsPrometheusMetric
                          key={index}
                          metric={metric}
                        />
                      ),
                  )}
                </List>
                <Typography variant="caption">
                  <i>
                    *Note that durable storage may not be available until the
                    the {thanosOffset} has elapsed.
                  </i>
                </Typography>
              </>
            )}
            {isThanosEnabled && thanosMetrics.length === 0 && (
              <Typography variant="body2" style={{ color: red[500] }}>
                Error: Durable Storage Prometheus Metrics Unavailable
              </Typography>
            )}
          </Section>
        )}

        {initialized && !loading && (
          <Section
            id="durableStorageRequestQueue"
            title="Durable Storage Request Queue"
            info="Diagnostic for request queues on Durable Storage Source"
          >
            {!isThanosEnabled && (
              <Typography variant="body1" color="textSecondary">
                Product upgrade required to enable long-term metric retention.{' '}
                <Link
                  target="_blank"
                  href="http://docs.kubecost.com/enterprise"
                >
                  Learn more
                </Link>
                &nbsp;If you are already on an Enterprise license and want to
                set this feature up, contact the team.
              </Typography>
            )}
            {isThanosEnabled && thanosRequestQueue && (
              <RequestQueue requestQueue={thanosRequestQueue} />
            )}
            {isThanosEnabled && !thanosRequestQueue && (
              <Typography variant="body2" style={{ color: red[500] }}>
                Error: Durable Storage Request Queue Unavailable
              </Typography>
            )}
          </Section>
        )}

        {initialized && !loading && (
          <Section
            id="etlAllocHourly"
            title="ETL Allocation (Hourly) Status"
            info="Diagnostic data for Kubecost ETL Allocation Pipeline"
            buttonText="?"
            buttonVariant="outlined"
            buttonHref={KubecostETLDoc}
          >
            {!etlDisabled && (
              <>
                <Typography variant="body2">
                  <b>Coverage</b>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {etlAllocationHourlyStatus.start} -{' '}
                  {etlAllocationHourlyStatus.end}
                </Typography>
                <Box p={1} />
                <Typography variant="body2">
                  <b>Completed</b>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {etlAllocationHourlyStatus.progress}
                </Typography>
                <Box p={1} />
                <ETLFileList
                  files={etlAllocationHourlyStatus.files}
                  etlType="allocation"
                  onETLFileClick={handleETLExpandClicked}
                />
              </>
            )}
            {etlDisabled && (
              <Typography variant="body2">ETL is disabled</Typography>
            )}
          </Section>
        )}

        {initialized && !loading && (
          <Section
            id="etlAllocDaily"
            title="ETL Allocation (Daily) Status"
            info="Diagnostic data for Kubecost ETL Allocation Pipeline"
            buttonText="?"
            buttonVariant="outlined"
            buttonHref={KubecostETLDoc}
          >
            {!etlDisabled && (
              <>
                <Typography variant="body2">
                  <b>Coverage</b>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {etlAllocationDailyStatus.start} -{' '}
                  {etlAllocationDailyStatus.end}
                </Typography>
                <Box p={1} />
                <Typography variant="body2">
                  <b>Completed</b>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {etlAllocationDailyStatus.progress}
                </Typography>
                <Box p={1} />
                <ETLFileList
                  files={etlAllocationDailyStatus.files}
                  etlType="allocation"
                  onETLFileClick={handleETLExpandClicked}
                />
              </>
            )}
            {etlDisabled && (
              <Typography variant="body2">ETL is disabled</Typography>
            )}
          </Section>
        )}

        {initialized && !loading && (
          <Section
            id="etlAssetsHourly"
            title="ETL Assets (Hourly) Status"
            info="Diagnostic data for Kubecost ETL Assets Pipeline"
            buttonText="?"
            buttonVariant="outlined"
            buttonHref={KubecostETLDoc}
          >
            {!etlDisabled && (
              <>
                <Typography variant="body2">
                  <b>Coverage</b>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {etlAssetsHourlyStatus.start} - {etlAssetsHourlyStatus.end}
                </Typography>
                <Box p={1} />
                <Typography variant="body2">
                  <b>Completed</b>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {etlAssetsHourlyStatus.progress}
                </Typography>
                <Box p={1} />
                <ETLFileList
                  thanosEnabled={isThanosEnabled}
                  files={etlAssetsHourlyStatus.files}
                  etlType="assets"
                  onETLFileClick={handleETLExpandClicked}
                />
              </>
            )}
            {etlDisabled && (
              <Typography variant="body2">ETL is disabled</Typography>
            )}
          </Section>
        )}

        {initialized && !loading && (
          <Section
            id="etlAssetsDaily"
            title="ETL Assets (Daily) Status"
            info="Diagnostic data for Kubecost ETL Assets Pipeline"
            buttonText="?"
            buttonVariant="outlined"
            buttonHref={KubecostETLDoc}
          >
            {!etlDisabled && (
              <>
                <Typography variant="body2">
                  <b>Coverage</b>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {etlAssetsDailyStatus.start} - {etlAssetsDailyStatus.end}
                </Typography>
                <Box p={1} />
                <Typography variant="body2">
                  <b>Completed</b>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {etlAssetsDailyStatus.progress}
                </Typography>
                <Box p={1} />
                <ETLFileList
                  files={etlAssetsDailyStatus.files}
                  etlType="assets"
                  onETLFileClick={handleETLExpandClicked}
                />
              </>
            )}
            {etlDisabled && (
              <Typography variant="body2">ETL is disabled</Typography>
            )}
          </Section>
        )}

        <Dialog
          open={etlStatusOpen}
          onClose={handleETLStatusClose}
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle id="alert-dialog-title">{etlStatusTitle}</DialogTitle>
          <DialogContent>
            {repairingETL && <Loading message="Repairing..." />}
            <List dense>
              {map(
                etlMessages,
                (etlError, index) =>
                  etlError !== null && (
                    <ListItem key={index} dense>
                      <code>{etlError}</code>
                    </ListItem>
                  ),
              )}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleETLStatusClose} color="primary">
              OK
            </Button>
            {openedETLBlock != null && !openedETLBlock.isRepairing && (
              <Button onClick={handleETLRepair} color="primary">
                Repair
              </Button>
            )}
          </DialogActions>
        </Dialog>

        {initialized && !loading && (
          <>
            {!etlDisabled && (
              <CloudIntegrationStatusPanel etlCloudStatus={etlCloudStatus} />
            )}
            {etlDisabled && (
              <Typography variant="body2">ETL is disabled</Typography>
            )}
          </>
        )}

        {initialized && !loading && (
          <div style={{ marginTop: '1em' }}>
            <BackupStatusPanel />
          </div>
        )}

        {initialized && !loading && (
          <Section
            id="savingsDiagnosticsCard"
            title="Savings Diagnostics"
            info="Diagnostic tests to check the savings calculation cache."
          >
            {savingsDiagnostics && (
              <List dense>
                <Typography variant="body2">
                  <b>Abandoned Workloads</b>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Cached Windows:{' '}
                  {savingsDiagnostics.abandonedWorkloads.windows.length ? (
                    savingsDiagnostics.abandonedWorkloads.windows.join(', ')
                  ) : (
                    <NoCacheMessage />
                  )}
                </Typography>
                <Box p={1} />
                <Typography variant="body2">
                  <b>Request Sizing</b>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Cached Profiles:{' '}
                  {savingsDiagnostics.requestSizing.profiles.length ? (
                    savingsDiagnostics.requestSizing.profiles.join(', ')
                  ) : (
                    <NoCacheMessage />
                  )}
                </Typography>
                <Box p={1} />
                <Typography variant="body2">
                  <b>Cluster Sizing</b>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Cached Cluster Profiles:{' '}
                  {savingsDiagnostics.clusterSizing.length ? null : (
                    <NoCacheMessage />
                  )}
                </Typography>
                {map(
                  savingsDiagnostics.clusterSizing.clusters,
                  (clusterItem, index) =>
                    clusterItem !== null && clusterItem.length ? (
                      <Typography key={index} variant="body2">
                        &nbsp;&nbsp;[Cluster: {clusterItem.id}, Profile:{' '}
                        {clusterItem.profile}]
                      </Typography>
                    ) : null,
                )}

                {(savingsDiagnostics.clusterSizing.error !== '' ||
                  savingsDiagnostics.requestSizing.error !== '' ||
                  savingsDiagnostics.abandonedWorkloads.error !== '') && (
                  <>
                    <Box p={1} />
                    <Typography variant="body2">
                      <b>Savings Errors</b>
                    </Typography>
                    {savingsDiagnostics.clusterSizing.error !== '' && (
                      <Button
                        onClick={() =>
                          handleOpenSavingsError(
                            'Cluster Sizing Errors',
                            savingsDiagnostics.clusterSizing.error,
                          )
                        }
                        color="primary"
                        variant="outlined"
                      >
                        Cluster Sizing Errors
                      </Button>
                    )}
                    {savingsDiagnostics.requestSizing.error !== '' && (
                      <Button
                        onClick={() =>
                          handleOpenSavingsError(
                            'Request Sizing Errors',
                            savingsDiagnostics.requestSizing.error,
                          )
                        }
                        color="primary"
                        variant="outlined"
                      >
                        Request Sizing Errors
                      </Button>
                    )}
                    {savingsDiagnostics.abandonedWorkloads.error !== '' && (
                      <Button
                        onClick={() =>
                          handleOpenSavingsError(
                            'Abandoned Workload Errors',
                            savingsDiagnostics.abandonedWorkloads.error,
                          )
                        }
                        color="primary"
                        variant="outlined"
                      >
                        Abandoned Workload Errors
                      </Button>
                    )}
                  </>
                )}
              </List>
            )}
            {!savingsDiagnostics && (
              <Typography variant="body2" style={{ color: red[500] }}>
                Error: Savings Diagnostics Unavailable
              </Typography>
            )}
          </Section>
        )}
        <Dialog
          open={savingsErrorOpen}
          onClose={handleSavingsErrorClosed}
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle id="alert-dialog-title">{savingsErrorTitle}</DialogTitle>
          <DialogContent>
            <List dense>
              <ListItem dense>
                <code>{savingsError}</code>
              </ListItem>
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSavingsErrorClosed} color="default">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default React.memo(DiagnosticsPage);
