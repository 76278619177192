import React, { useState } from 'react';

import { Modal, Typography } from '@kubecost-frontend/holster';

import { ProductTier } from '../../hooks/useProductTier';
import { EnablementNames } from '../../hooks/useEnablements';
import MagicWand from '../../assets/images/magic-wand-icon';
import { Outlet } from 'react-router';
import { use_DEPRECATED_ClustersEnablements } from '../../hooks/useClustersEnablement';

const sessionKey = 'enablementsModalDismissed';

const EnablementsModalNew: React.FC = () => {
  const enablements = use_DEPRECATED_ClustersEnablements();
  const violations = enablements.filter((enablement) => enablement.isViolation);
  const isModalDismissed = window.sessionStorage.getItem(sessionKey) === 'true';

  /* Only show the modal if you haven't dismissed it in the current session or
   * if you have enablement violations. Order matters! */
  const [isOpen, setIsOpen] = useState<boolean>(
    !isModalDismissed || violations.length > 0,
  );

  if (violations.length === 0 || isModalDismissed) return <Outlet />;

  const isPlural = violations.length > 1;

  /* This logic is pretty primitive but basically, you can't be inviolation on
   * the Free tier so if you're not Enterprise than you're Business.
   * Additionally, we "round up" of sorts by suggesting the highest necessary
   * tier to cover all enablements.
   *
   * Example:
   * If a users has 4 enablement violations and even just one is an Enterprise
   * violation we suggest Enterprise.
   */
  const recommendedUpgrade = violations.filter(
    (violation) => violation.requiredTier === ProductTier.Enterprise,
  )
    ? ProductTier.Enterprise
    : ProductTier.Business;

  const articleGrammar =
    recommendedUpgrade === ProductTier.Enterprise ? 'an' : 'a';

  const getTitle = () => {
    if (!isPlural) {
      return (
        <>
          You found {articleGrammar}{' '}
          <span className={'capitalize'}>{violations[0].requiredTier}</span>{' '}
          feature!
        </>
      );
    }
    // Handles title pluralization
    for (const violation of violations) {
      if (violation.requiredTier === ProductTier.Enterprise) {
        return (
          <>
            You found{' '}
            <span className={'capitalize'}>{violation.requiredTier}</span>{' '}
            features!
          </>
        );
      }
    }
  };

  const handleOnClose = () => {
    setIsOpen((prevState) => {
      window.sessionStorage.setItem(sessionKey, Boolean(prevState).toString());

      return !prevState;
    });
  };

  return (
    <>
      <Outlet />
      <Modal onClose={handleOnClose} open={isOpen}>
        <section className={'flex flex-col items-center text-center'}>
          <Typography className={'font-bold pb-4'} variant={'h4'}>
            {getTitle()}
          </Typography>
          <ul>
            {violations.map((violation, index) => (
              <li
                className={'flex items-center justify-center '}
                key={violation.key + index}
              >
                <MagicWand className={'mr-2'} />
                {EnablementNames[violation.key]}
              </li>
            ))}
          </ul>

          <Typography className={'pb-7 pt-4 w-3/4'} variant="p-large">
            {isPlural ? 'These are' : 'This is a'} paid{' '}
            <span className={'capitalize'}>{recommendedUpgrade}</span>{' '}
            {isPlural ? 'features' : 'feature'}. Upgrade to explore{' '}
            {isPlural ? 'these features' : 'this feature'} and many others.
          </Typography>
        </section>
        <footer className={'flex flex-col items-center'}>
          <a
            className={'holster-button kc-button-primary mb-2'}
            color={'primary'}
            href={'https://www.kubecost.com/pricing/?upgrade=true'}
            target={'_blank'}
          >
            Learn More
          </a>

          <button
            className={'pb-4 pt-2 px-4 underline'}
            onClick={handleOnClose}
          >
            Dismiss
          </button>
        </footer>
      </Modal>
    </>
  );
};

export default EnablementsModalNew;
