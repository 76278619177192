import React from 'react';
import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import OverviewOld from './pages/Overview';
import Overview from './pages/OverviewNew';
import Allocation from './pages/Allocation';
import AllocationNew from './pages/AllocationNew';
import AbandonedWorkloads from './pages/AbandonedWorkloads';
import AdvancedReportingDetail from './pages/AdvancedReportingDetail';
import AdvancedReportingDetail2 from './pages/AdvancedReportingDetail/index2';
import AdvancedReportingManager2 from './pages/AdvancedReportingManager/index2';
import AdvancedReportingManager from './pages/AdvancedReportingManager';
import Alerts from './pages/Alerts';
import { AlertsNew } from './pages/AlertsNew';
import Assets from './pages/Assets';
import { AssetPageMemoized } from './pages/AssetsNew';
import ClusterList from './pages/ClusterList';
import ClusterSizing from './pages/ClusterSizing';
import ClusterSizingNew from './pages/ClusterSizingNew';
import Diagnostics from './pages/Diagnostics';
import ETLStatus from './pages/ETLStatus';
import Health from './pages/Health';
import { Health as HealthNew } from './pages/HealthNew';
import OrphanedResources from './pages/OrphanedResources';
import PersistentVolumeSizing from './pages/PersistentVolumeSizing';
import Reports from './pages/Reports';
import { ReportsNew } from './pages/ReportsNew';
import RequestSizing from './pages/RequestSizing';
import { RequestSizing as RequestSizingNew } from './pages/RequestSizingNew';
import Reservations from './pages/Reservations';
import Savings from './pages/Savings';
import { Savings as SavingsNew } from './pages/SavingsNew';
import Spot from './pages/Spot';
import Unauthorized from './pages/Unauthorized';
import UnauthorizedNew from './pages/UnauthorizedNew';
import LowDiskUsage from './pages/Actions/LowDiskUsage';
import { LowDiskUsageNew } from './pages/Actions/LowDiskUsageNew';
import LowCPUMemory from './pages/Actions/LowCPUMemory';
import LowCPUMemoryNew from './pages/Actions/LowCPUMemoryNew';
import ReservedInstances from './pages/Actions/ReservedInstances';
import { ReservedInstancesNew } from './pages/Actions/ReservedInstancesNew';
import UnclaimedVolumes from './pages/Actions/UnclaimedVolumes';
import { UnclaimedVolumesNew } from './pages/Actions/UnclaimedVolumesNew';
import Settings from './pages/Settings';
import Settings2 from './pages/Settings/index2';
import Inspect from './pages/Inspect';
import Inspect2 from './pages/Inspect/index2';
import KeyInstructions from './pages/KeyInstructions';
import KeyInstructionsNew from './pages/KeyInstructionsNew';
import useBetaUI from './hooks/useBetaUI';
import Page from './components/Page';
import BetaUINotificaton from './components/BetaUINotification';
import EnablementsModal, {
  EnablementsModalNew,
} from './components/EnablementsModal';
import { NewAdvancedReporting } from './pages/NewAdvancedReporting';
import { CloudCostsPage } from './pages/Cloud';

export const rewriteLegacyRoutes = (url: string): string => {
  // action.html routes were renamed
  // these substitutions are specific to those views
  const actionMap = {
    '/nodes': /\/action\.html\?a\=unusednodes$/,
    '/low-disk': /\/action\.html\?a\=lowlocalstorage$/,
    '/reserved-instances': /\/action\.html\?a\=reserved$/,
    '/unclaimed-volumes': /\/action\.html\?a\=unclaimedvolumes$/,
  };
  let loc = url;
  Object.entries(actionMap).forEach(([newRoute, oldRoute]) => {
    loc = loc.replace(oldRoute, newRoute);
  });

  // general /page.html -> /page substitution
  if (url.indexOf('.html') > -1) {
    loc = loc.replace('.html', '');
  }
  return loc;
};

export const KubecostRoutes = () => {
  const { isBetaUI } = useBetaUI();

  /*
   * This effect is meant to automatically redirect the user
   * away from .html pages while preserving their legacy links/bookmarks.
   */
  useEffect(() => {
    // actually set the window location
    let newRoute = rewriteLegacyRoutes(window.location.href);

    if (newRoute !== window.location.href) window.location.href = newRoute;
  }, []);

  return (
    <Routes>
      <Route element={<Page />}>
        <Route path="/index" element={<Navigate to="/" />} />

        {/* Routes that have not been updated to support the new UI */}
        <Route element={<BetaUINotificaton />}>
          <Route path="abandoned-workloads" element={<AbandonedWorkloads />} />
          <Route path="context-list" element={<ClusterList />} />
          <Route path="diagnostics" element={<Diagnostics />} />
          <Route path="etl-status" element={<ETLStatus />} />
          <Route path="orphaned-resources" element={<OrphanedResources />} />
          <Route path="reservations" element={<Reservations />} />
          <Route path="spot" element={<Spot />} />
        </Route>
        <Route path="settings" element={<Settings />} />

        {/* Routes that are only supported in the new UI */}
        <Route
          path="persistent-volume-sizing"
          element={<PersistentVolumeSizing />}
        />
        <Route path="unauthorized" element={<UnauthorizedNew />} />
        <Route path="cloud" element={<CloudCostsPage />} />

        {isBetaUI ? (
          // New UI routes below share the same path as the "old" one but render the new UI component
          <Route element={<EnablementsModalNew />}>
            <Route path="/" element={<Overview />} />
            <Route path="/overview" element={<Overview />} />
            <Route path="/allocations" element={<AllocationNew />} />
            <Route path="assets" element={<AssetPageMemoized />} />
            <Route path="savings" element={<SavingsNew />} />
            <Route path="advanced-reporting" element={<NewAdvancedReporting />} />

            <Route path="alerts" element={<AlertsNew />} />
            <Route path="cluster-sizing" element={<ClusterSizingNew />} />
            <Route path="health" element={<HealthNew />} />
            <Route path="reports" element={<ReportsNew />} />
            <Route path="request-sizing" element={<RequestSizingNew />} />
            <Route path="low-disk" element={<LowDiskUsageNew />} />
            <Route path="nodes" element={<LowCPUMemoryNew />} />
            <Route
              path="reserved-instances"
              element={<ReservedInstancesNew />}
            />
            <Route path="unclaimed-volumes" element={<UnclaimedVolumesNew />} />
            <Route path="details" element={<Inspect2 />} />
            <Route path="keyinstructions" element={<KeyInstructionsNew />} />
          </Route>
        ) : (
          // Old UI routes below share the same path as the "new" ones above but render the old UI component
          <Route element={<EnablementsModal />}>
            <Route path="/" element={<OverviewOld />} />
            <Route path="/overview" element={<OverviewOld />} />
            <Route path="/allocations" element={<Allocation />} />
            <Route path="assets" element={<Assets />} />
            <Route path="savings" element={<Savings />} />

            <Route
              path="advanced-reporting-detail"
              element={<AdvancedReportingDetail />}
            />
            <Route
              path="advanced-reporting"
              element={<AdvancedReportingManager />}
            />
            <Route path="alerts" element={<Alerts />} />
            <Route path="cluster-sizing" element={<ClusterSizing />} />
            <Route path="health" element={<Health />} />
            <Route path="reports" element={<Reports />} />
            <Route path="request-sizing" element={<RequestSizing />} />
            <Route path="low-disk" element={<LowDiskUsage />} />
            <Route path="nodes" element={<LowCPUMemory />} />
            <Route path="reserved-instances" element={<ReservedInstances />} />
            <Route path="unclaimed-volumes" element={<UnclaimedVolumes />} />
            <Route path="details" element={<Inspect />} />
            <Route path="keyinstructions" element={<KeyInstructions />} />
          </Route>
        )}
      </Route>
    </Routes>
  );
};
