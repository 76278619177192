import React from 'react';

import { Button, Input, Modal, Typography } from '@kubecost-frontend/holster';

import { AlertTypes, DiagnosticAlert } from '../../services/alerts';

interface ComponentProps {
  alert: DiagnosticAlert | null;
  close: () => void;
  save: (alert: DiagnosticAlert) => Promise<void>;
  test: (alert: DiagnosticAlert) => Promise<void>;
}

const DiagnosticAlertModal: React.FC<ComponentProps> = ({
  alert,
  close,
  save,
  test,
}: ComponentProps) => {
  const [slackDirty, setSlackDirty] = React.useState(false);
  const [slackUrl, setSlackUrl] = React.useState('');
  React.useEffect(() => {
    if (alert && alert.slackWebhookUrl) {
      setSlackUrl(
        'https://hooks.slack.com/services/XXXXXXXXXXX/XXXXXXXXXXX/XXXXXXXXXXXXXXXXXXXXXXXX',
      );
    }
  }, [alert]);
  return (
    <Modal
      data-test="diagnostic-alert-dialog"
      onClose={onClose}
      open={!!alert}
      title="Enable Kubecost Health Monitoring"
    >
      <Typography variant="p" style={{ marginBottom: 16 }}>
        With Kubecost Health monitoring enabled, Kubecost will send a message to
        the designated Slack webhook any time it is unable to contact its
        backing data store.
      </Typography>

      <Typography variant="p">
        Learn more about Slack webhooks{' '}
        <a
          href="https://api.slack.com/messaging/webhooks"
          target="_blank"
          style={{ color: '#28B359' }}
        >
          here
        </a>
        .
      </Typography>

      <Input
        label="Slack webhook"
        placeholder="https://hooks.slack.com/services/XXXXXXXXXXX/XXXXXXXXXXX/XXXXXXXXXXXXXXXXXXXXXXXX"
        onChange={(e) => {
          setSlackUrl(e.target.value);
          setSlackDirty(true);
        }}
        style={{ width: '100%' }}
        value={slackUrl}
      />

      <div
        style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 8 }}
      >
        <Button variant="primary" onClick={onClose} style={{ marginRight: 8 }}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onSave} style={{ marginRight: 8 }}>
          Save
        </Button>
        <Button variant="default" onClick={onTest}>
          Test
        </Button>
      </div>
    </Modal>
  );

  function onClose() {
    setSlackUrl('');
    setSlackDirty(false);
    close();
  }

  async function onSave() {
    const a = new DiagnosticAlert({
      type: AlertTypes.Diagnostic,
      window: '5m',
      slackWebhookUrl: alert?.slackWebhookUrl,
      id: alert?.id,
    });
    if (slackDirty) {
      a.slackWebhookUrl = slackUrl;
    }
    await save(a);
    onClose();
  }

  async function onTest() {
    const a = new DiagnosticAlert({
      type: AlertTypes.Diagnostic,
      slackWebhookUrl: alert?.slackWebhookUrl,
      window: '5m',
    });
    if (slackDirty) {
      a.slackWebhookUrl = slackUrl;
    }
    test(a);
  }
};

export default DiagnosticAlertModal;
