// react
import React from 'react';

// holster
import { Typography } from '@kubecost-frontend/holster';

// local
import QuestionIcon from '../assets/images/question-icon';
import { RefreshIcon } from '../assets/images/refresh-icon';
import Analytics from '../services/analytics';
import DiagnosticsChecker from './DiagnosticsCheckerNew';

interface ComponentProps {
  helpHref?: string;
  helpTooltip?: string;
  refreshCallback?: () => void;
  title: React.ReactNode;
}

export const Header: React.FC<ComponentProps> = ({
  helpHref,
  helpTooltip,
  refreshCallback,
  title,
}) => {
  return (
    <div className="flex mb-[40px]">
      <Typography className="font-bold" variant="h4">
        {title}
      </Typography>
      <div className="flex justify-end grow items-center">
        {refreshCallback && (
          <RefreshIcon
            className="cursor-pointer mr-3"
            onClick={refreshCallback}
            width={24}
            height={24}
          />
        )}
        <DiagnosticsChecker />
        {helpHref && helpTooltip && (
          <a
            className="ml-3"
            href={helpHref}
            target="_blank"
            title={helpTooltip}
            onClick={() =>
              Analytics.record('documentation_visited', { url: helpHref })
            }
          >
            <QuestionIcon width={24} height={24} />
          </a>
        )}
      </div>
    </div>
  );
};

Header.defaultProps = {
  helpHref: '',
  helpTooltip: '',
  refreshCallback: undefined,
};

Header.displayName = 'Header';
