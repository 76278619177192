import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { Outlet } from 'react-router';

import useBetaUI from '../hooks/useBetaUI';
import MagicWand from '../assets/images/magic-wand-icon';

const BetaUINotificaton = () => {
  const { isEnabled: isBetaUIEnabled } = useBetaUI();
  const [isOpen, setIsOpen] = useState<boolean>(isBetaUIEnabled);

  return (
    <>
      <Outlet />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={4000}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Alert
          icon={<MagicWand className={'h-5 w-5'} />}
          severity={'info'}
          variant={'filled'}
        >
          This feature is not currently supported by the Beta Theme. We'll
          switch you back when you're finished.
        </Alert>
      </Snackbar>
    </>
  );
};

export default BetaUINotificaton;
