import React, { useState } from 'react';

import Popover from '@material-ui/core/Popover';

import { Button } from '@kubecost-frontend/holster';

import { ListInput } from '../ListInput';
import { ActionMenuItem } from './ActionMenuItem';

interface ActionMenuProps {
  customItems?: string[];
  onClickItem: ActionMenuOnClickItem;
  onClickAddItem: ActionMenuOnClickItem;
  items: Record<string, string>;
  selectedItems?: string[];
  isMultiSelect?: boolean;
}

type ActionMenuOnClickItem = (item: string, menuMode: MenuMode) => void;

type MenuMode = 'single' | 'multi';

const ActionMenu = ({
  customItems,
  onClickItem,
  onClickAddItem,
  items,
  selectedItems = [],
  isMultiSelect = true,
}: ActionMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<EventTarget & Element>();
  const [menuMode, setMenuMode] = useState<MenuMode>(() =>
    selectedItems.length > 1 ? 'multi' : 'single',
  );

  return (
    <>
      {/* TODO: Abstract `Button` out of `ActionMenu`  */}
      <Button
        variant="default"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        className="mr-3"
      >
        Aggregate By
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => {
          setAnchorEl(undefined);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          className: 'p-6',
        }}
      >
        <section className="flex">
          <ActionMenuItem
            className={'uppercase'}
            isSelected={menuMode === 'single'}
            onClick={() => setMenuMode('single')}
          >
            Single aggregation
          </ActionMenuItem>
          {isMultiSelect && (
            <ActionMenuItem
              className={'uppercase'}
              isSelected={menuMode === 'multi'}
              onClick={() => setMenuMode('multi')}
            >
              Multi aggregation
            </ActionMenuItem>
          )}
        </section>
        <ul className={'columns-2 pb-6'}>
          {Object.entries(items)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map(([key, value], index: number) => (
              <li key={key + index}>
                <ActionMenuItem
                  className={
                    'hover:bg-kc-success-light rounded text-left w-full'
                  }
                  isSelected={selectedItems.includes(key)}
                  onClick={() => onClickItem(key, menuMode)}
                >
                  {value}
                </ActionMenuItem>
              </li>
            ))}
          {customItems &&
            customItems
              .sort((a, b) => a[0].localeCompare(b[0]))
              .map((customItem, index) => (
                <li key={customItem + index}>
                  <ActionMenuItem
                    className={
                      'hover:bg-kc-success-light rounded text-left w-full'
                    }
                    isSelected={selectedItems.includes(customItem)}
                    onClick={() => onClickItem(customItem, menuMode)}
                  >
                    Label: {customItem}
                  </ActionMenuItem>
                </li>
              ))}
        </ul>

        <ListInput
          label={'Label'}
          onClick={(inputValue) => onClickAddItem(inputValue, menuMode)}
        />
      </Popover>
    </>
  );
};

export { ActionMenu, ActionMenuOnClickItem };
