import model from '../../services/model';
import ConfigService from '../../services/config';
import {
  getCurrentContainerAddressModel,
  paramsToQuery,
} from '../../services/util';
import api from '../../services/api';
import { version } from '../../services/env';

const generateHeader = (text: string) =>
  `
================================================================================================================================
${text}
================================================================================================================================
\n`;

// prom Queries
export const upQuery = `sum(up)`;
export const nodeMetricQuery = `sum(node_cpu_seconds_total)`;
export const fsUsageQuery = `sum(sum_over_time(container_cpu_usage_seconds_total[10m]))`;
export const requestQuery = `sum(sum_over_time(kube_pod_container_resource_requests{resource="memory", unit="byte"}[10m]))`;
export const hasCadvisorQuery = `sum(avg_over_time(kube_persistentvolume_capacity_bytes[10m]))`;
export const costMetricsQuery = `avg_over_time(kubecost_savings_memory_allocation_bytes[10m])`;
export const ksmVersionQuery = `sum(sum_over_time(node_cpu_hourly_cost[10m]))`;

const getProductConfigurationData = async () => {
  let productConfigurationDataString = '';
  await Promise.all([
    model.getConfigs(),
    model.clusterInfo(),
    model.getAPIConfig(),
    ConfigService.fetchProductKey(),
  ]).then(([config, clusterInfoResp, apiConfigResp, productKeyResp]) => {
    productConfigurationDataString += `${JSON.stringify(
      config,
    )}\n${JSON.stringify(clusterInfoResp)}\n${JSON.stringify(
      apiConfigResp,
    )}\n${JSON.stringify(productKeyResp)}\n`;
  });
  return productConfigurationDataString;
};

const getPromConfigurationData = async () => {
  let promConfigString = '';
  const textPromise = await fetch(
    `${getCurrentContainerAddressModel()}/status`,
  ).then((res) => {
    return res.text();
  });
  promConfigString += textPromise;
  return promConfigString;
};

const getHelmValues = async () => {
  let helmValText = '';
  await model.get('/helmValues').then((resp) => {
    helmValText += JSON.stringify(resp);
  });
  return helmValText;
};

const getPromDataString = async (query: string) => {
  const promResp = await model.prometheusQuery(query);
  return JSON.stringify(promResp.data);
};
const getThanosDataString = async (query: string) => {
  const thanosQuery = await api.thanosQuery(query);
  return JSON.stringify(thanosQuery);
};

const getPodLogs = async (selector: string, timeFrame: string) => {
  const payload = {
    selector,
    timeFrame,
  };
  const podLogsResp = await fetch(
    `${getCurrentContainerAddressModel()}/podLogs${paramsToQuery(payload)}`,
  ).then((resp) => resp.text());
  return podLogsResp;
};

export const captureBugReport = async () => {
  let bugReportString = '';
  bugReportString += generateHeader('App info');
  bugReportString += `Running app version: ${version}`;
  bugReportString += generateHeader('Product Configuration');
  bugReportString += await getProductConfigurationData();
  bugReportString += generateHeader('Prometheus UP Results');
  bugReportString += await getPromDataString(upQuery);
  bugReportString += generateHeader('Prometheus Configuration');
  bugReportString += await getPromConfigurationData();
  bugReportString += generateHeader('Helm Values');
  bugReportString += await getHelmValues();
  bugReportString += generateHeader('Node Prices');
  bugReportString += await getPromDataString('node_total_hourly_cost*730');
  bugReportString += generateHeader('Prometheus Scale Data');
  bugReportString += await getPromDataString(
    'topk(25, count by (__name__, job)({__name__=~".+"}))',
  );
  bugReportString += generateHeader('Namespace memory usage & requests (GBs)');
  bugReportString += await getPromDataString(
    'topk(25,max(avg_over_time (container_memory_working_set_bytes{pod_name!="",container_name!="",container_name!="POD",namespace="kubecost"}[1d])) by (container_name,pod_name)/1024/1024/1024)',
  );
  bugReportString += generateHeader('Kubecost pod logs');
  bugReportString += await getPodLogs('app=cost-analyzer', '24h');
  bugReportString += generateHeader('Prometheus pod logs');
  bugReportString += await getPodLogs('app=prometheus', '24h');
  bugReportString += generateHeader('Thanos pod logs');
  bugReportString += await getPodLogs('app.kubernetes.io/name=thanos', '`2h');
  bugReportString += generateHeader('Thanos scale data');
  bugReportString += await getThanosDataString(
    'avg(up) by (component, job, kubernetes_namespace)',
  ) + '\n';
  bugReportString += await getThanosDataString(
    'topk(25, count by (__name__, job, cluster_id)({__name__=~".+"} offset 3h))',
  ) + '\n';
  bugReportString += await getThanosDataString(
    'sum(count by (__name__, cluster_id)({__name__=~".+"} offset 3h)) by (cluster_id)',
  ) + '\n';
  return bugReportString;
};
