import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  Grid,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  Chip,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import {
  allocationFilterTypes,
  dateRangeOptions,
  DropdownControl,
  ReportConfig,
  shareSplitOptions,
  AllocationFilter,
} from './reportingHelpers';

export const BaseConfigurations = ({
  reportConfig,
  updateConfig,
}: {
  reportConfig: ReportConfig;
  updateConfig: React.Dispatch<React.SetStateAction<ReportConfig>>;
}) => {
  const [filterType, setFilterType] = useState(allocationFilterTypes[0].key);
  const [filterText, setFilterText] = useState('');

  const updateAggBy = (aggList: string[]) => {
    updateConfig({
      ...reportConfig,
      allocationAggregationBy: aggList.toString(),
    });
  };

  const addNewFilter = () => {
    if (filterText !== '') {
      updateConfig({
        ...reportConfig,
        filtersInclude: [
          ...reportConfig.filtersInclude,
          { property: filterType, value: filterText },
        ],
      });
    }
  };

  const deleteFilterItem = (item: AllocationFilter) => {
    const filteredItems = reportConfig.filtersInclude.filter(
      (existingItem: AllocationFilter) =>
        existingItem.property !== item.property &&
        existingItem.value !== item.value,
    );
    updateConfig({
      ...reportConfig,
      filtersInclude: filteredItems,
    });
  };
  return (
    <Grid
      container
      justifyContent="start"
      spacing={8}
      style={{ paddingTop: '4em' }}
    >
      <Grid item xs={3}>
        <Typography component="div" variant="h6">
          General Configurations
        </Typography>
        <DropdownControl
          currentValue={reportConfig.window}
          setCurrentValue={(value: string) =>
            updateConfig({ ...reportConfig, window: value })
          }
          valueList={dateRangeOptions.map((value: string) => ({
            name: value,
            key: value,
          }))}
          labelText="Date Range"
          placeholder="Custom"
        />
        {/* <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disabled={true}
              disableToolbar
              fullWidth
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              label="Start Date"
              value={reportConfig.startDate}
              style={{ marginBottom: '1em' }}
              onChange={
                (date: MaterialUiPickersDate) =>
                  updateConfig({
                    ...reportConfig,
                    startDate: date || new Date(),
                  })
              }
            />
            <KeyboardDatePicker
              disabled={true}
              disableToolbar
              fullWidth
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              label="End Date"
              value={reportConfig.endDate}
              onChange={
                (date: MaterialUiPickersDate) =>
                  updateConfig({
                    ...reportConfig,
                    endDate: date || new Date(),
                  }) // TODO
              }
            />
          </MuiPickersUtilsProvider>
        </div> */}
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body1">Sharing (Allocations)</Typography>
        <TextField
          style={{ marginTop: '1em' }}
          fullWidth
          value={reportConfig.sharedOverheadCost}
          label="Shared Overhead Cost"
          placeholder="0"
          type="number"
          onChange={(e: any) =>
            updateConfig({
              ...reportConfig,
              sharedOverheadCost: e.target.value,
            })
          }
        />
        <TextField
          fullWidth
          style={{ marginTop: '2em' }}
          value={reportConfig.sharedNamespaces}
          label="Shared Namespaces"
          placeholder="kubecost,kube-system etc."
          onChange={(e: any) =>
            updateConfig({
              ...reportConfig,
              sharedNamespaces: e.target.value,
            })
          }
        />
        <TextField
          style={{ marginTop: '2em' }}
          fullWidth={true}
          value={reportConfig.sharedLabels}
          label="Shared Labels"
          placeholder="app:kubecost,team:dev"
          onChange={(e: any) =>
            updateConfig({ ...reportConfig, sharedLabels: e.target.value })
          }
        />
        <div style={{ marginTop: '2em' }}>
          <DropdownControl
            currentValue={reportConfig.shareBy}
            setCurrentValue={(value: string) =>
              updateConfig({ ...reportConfig, shareBy: value })
            }
            valueList={shareSplitOptions}
            labelText="Share By"
            placeholder="Weighted By Cost, etc."
          />
        </div>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body1">Filters (Allocation)</Typography>
        <DropdownControl
          currentValue={filterType}
          setCurrentValue={(value: string) => setFilterType(value)}
          valueList={allocationFilterTypes}
          labelText="Add Filters"
          placeholder="Filter by Cluster, etc."
        />
        <TextField
          fullWidth={true}
          defaultValue={filterText}
          label="Aggregation Name"
          placeholder="app:kubecost"
          onChange={(e: any) => setFilterText(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={addNewFilter}
                >
                  <AddIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div>
          {reportConfig.filtersInclude.length !== 0 ? (
            reportConfig.filtersInclude.map((item: AllocationFilter) => (
              <div style={{ margin: '1em' }} key={item.property}>
                <Chip
                  label={`${item.property}: ${item.value}`}
                  clickable
                  color="primary"
                  onDelete={() => deleteFilterItem(item)}
                  deleteIcon={<CloseIcon />}
                  variant="outlined"
                />
              </div>
            ))
          ) : (
            <Typography
              style={{
                marginTop: '2em',
                marginLeft: '1em',
                textAlign: 'center',
              }}
              color="textSecondary"
              variant="body1"
            >
              No filters applied
            </Typography>
          )}
        </div>
      </Grid>
    </Grid>
  );
};
