import { Box, Paper } from '@material-ui/core';
import React from 'react';
import { CircleProgress2 } from './CircleProgress2';

interface CostEfficiencyTileProps {
  efficiencyCostWeight: number;
  cpuEfficiency: number;
  ramEfficiency: number;
}

export const CostEfficiencyTile = ({
  efficiencyCostWeight,
  cpuEfficiency,
  ramEfficiency,
}: CostEfficiencyTileProps) => (
  <Box
    component={Paper}
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      padding: '2em'
    }}
  >
    <div>
      <div style={{ fontSize: 34 }}>
        <strong>{(efficiencyCostWeight || 0).toFixed(2)}%</strong>
      </div>
      <Box style={{ marginTop: '1em', display: 'flex', gridGap: '2em' }}>
        <CircleProgress2
          width="5em"
          label="CPU"
          value={parseInt(cpuEfficiency.toFixed(2)) || 0}
        />
        <CircleProgress2
          width="5em"
          label="RAM"
          value={parseInt(ramEfficiency.toFixed(2)) || 0}
        />
      </Box>
    </div>
  </Box>
);
