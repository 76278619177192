import React from 'react';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import map from 'lodash/map';

const Actions = ({ handleAdopt, handleClose, isReady, isSetUp }) => {
  if (!isSetUp) {
    return (
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    );
  }

  return (
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Cancel
      </Button>
      <Button onClick={handleAdopt} color="primary" disabled={!isReady}>
        Adopt
      </Button>
    </DialogActions>
  );
};

const Description = ({ recommendation, isError, isReady, isSetUp }) => {
  if (!isSetUp) {
    return (
      <DialogContentText>
        One-click cluster resizing requires the Kubecost cluster controller.
        Click here to learn how to install it:{' '}
        <Link href="http://docs.kubecost.com/controller" target="_blank">
          Cluster Controller Installation
        </Link>
        .
      </DialogContentText>
    );
  }

  const message = isError
    ? 'Error communicating with cluster controller'
    : 'Adoption is disabled because the cluster controller is not ready';

  const severity = isError ? 'warning' : 'info';

  return (
    <>
      {!isReady && (
        <div style={{ marginBottom: 12 }}>
          <Alert severity={severity}>{message}</Alert>
        </div>
      )}
      <Typography variant="body1">
        Clicking "Adpot" will replace your current node pools with the following
        node pools:
      </Typography>
      <List>
        {map(recommendation.pools, (pool, p) => (
          <ListItem key={p}>
            {`${pool.count} node of type ${pool.type.name}`}
          </ListItem>
        ))}
      </List>
      <Typography variant="body1">
        Please confirm that these details are correct, then click
        &quot;Adopt&quot; to resize. (Resizing may take several minutes.)
      </Typography>
    </>
  );
};

const AdoptDialog = (props) => {
  const { adopt, recommendation, isSetUp } = props;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAdopt = () => {
    adopt(recommendation);
    setOpen(false);
  };

  let message = 'Adopt recommendation';
  if (!isSetUp) {
    message = 'Learn how to adopt';
  }

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        {message}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">Adopt Recommendation</DialogTitle>
        <DialogContent>
          <Description {...props} />
        </DialogContent>
        <Actions
          handleAdopt={handleAdopt}
          handleClose={handleClose}
          {...props}
        />
      </Dialog>
    </>
  );
};

export default AdoptDialog;
