import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { RequestQueueStatus } from '../../services/diagnostics';

type RequestQueueProps = {
  requestQueue: RequestQueueStatus;
};

const RequestQueue = ({
  requestQueue,
}: RequestQueueProps): React.ReactElement => {
  const { totalRequests, outboundRequests, maxQueryConcurrency } = requestQueue;
  return (
    <>
      <Typography variant="body2">
        <b>Total Requests</b>
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {totalRequests}
      </Typography>
      <Box p={1} />
      <Typography variant="body2">
        <b>Outbound Requests</b>
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {outboundRequests}
      </Typography>
      <Box p={1} />
      <Typography variant="body2">
        <b>Max Query Concurrency</b>
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {maxQueryConcurrency}
      </Typography>
    </>
  );
};

export default RequestQueue;
