import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import filter from 'lodash/filter';
import isArray from 'lodash/isArray';
import sortBy from 'lodash/sortBy';
import trim from 'lodash/trim';
import AddIcon from '@material-ui/icons/Add';
import Badge from '@material-ui/core/Badge';
import Chip from '@material-ui/core/Chip';
import TuneIcon from '@material-ui/icons/Tune';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  chip: {
    marginTop: 8,
    marginRight: 8,
    marginBottom: 4,
  },
  chipIcon: {
    paddingLeft: 4,
    paddingRight: 2,
  },
  form: {
    padding: 18,
    display: 'flex',
    flexFlow: 'column',
  },
  formControl: {
    margin: 8,
    minWidth: 120,
  },
});

const EditControl = ({
  accumulateOptions,
  accumulate,
  setAccumulate,
  filters,
  setFilters,
  rate,
  setRate,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const [filterProperty, setFilterProperty] = useState('Account');
  const filterPropertyOptions = [
    'Account',
    'Category',
    'Cluster',
    'Label/Tag',
    'Name',
    'Project',
    'Provider',
    'ProviderID',
    'Service',
    'Type',
  ];
  const [filterValue, setFilterValue] = useState('');

  const handleAddFilter = (newFilter) => {
    // remove existing filters using the newFilter's property (overwrite it)
    const oldFilters = isArray(filters) ? filters : [];
    const fs = filter(oldFilters, (f) => f.property !== newFilter.property);

    // sanitize comma-separated values
    const vals = sortBy(
      newFilter.value.split(',').map((v) => trim(v).replace('=', ':')),
      (str) => str,
    );
    newFilter.value = vals.join(', ');

    if (newFilter.value.length > 0) {
      setFilters(sortBy([...fs, newFilter], 'property'));
    }

    setFilterValue('');
  };

  const handleDeleteFilter = (delFilter) => {
    const oldFilters = isArray(filters) ? filters : [];
    setFilters(filter(oldFilters, (f) => !(f.property === delFilter.property)));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'edit-form' : undefined;

  return (
    <div>
      <Tooltip title="Edit search parameters">
        <IconButton
          style={{ marginTop: 12 }}
          aria-describedby={id}
          onClick={handleClick}
        >
          <Badge badgeContent={filters.length} color="primary">
            <TuneIcon />
          </Badge>
        </IconButton>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        disableScrollLock
      >
        <div className={classes.form}>
          <FormControl className={classes.formControl}>
            <InputLabel>Resolution</InputLabel>
            <Select
              id="accumulate-select"
              value={accumulate}
              onChange={(e) => setAccumulate(e.target.value)}
            >
              {accumulateOptions.map((opt) => (
                <MenuItem key={opt.value} value={opt.value}>
                  {opt.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel>Cost metric</InputLabel>
            <Select
              data-test="rate-select"
              value={rate}
              onChange={handleSetRate}
            >
              <MenuItem value="cumulative">Cumulative Cost</MenuItem>
              <MenuItem value="monthly">Monthly Rate</MenuItem>
              <MenuItem value="daily">Daily Rate</MenuItem>
              <MenuItem value="hourly">Hourly Rate</MenuItem>
            </Select>
          </FormControl>
          <div>
            <Typography
              variant="subtitle2"
              display="block"
              style={{ marginLeft: 8, paddingTop: 12 }}
            >
              Filters
            </Typography>
            <FormControl className={classes.formControl}>
              <Select
                value={filterProperty}
                onChange={(e) => setFilterProperty(e.target.value)}
              >
                {filterPropertyOptions.map((opt) => (
                  <MenuItem key={opt} value={opt}>
                    {opt}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleAddFilter({
                      property: filterProperty,
                      value: filterValue,
                    });
                  }
                }}
              />
            </FormControl>
            <IconButton
              onClick={() =>
                handleAddFilter({
                  property: filterProperty,
                  value: filterValue,
                })
              }
            >
              <AddIcon />
            </IconButton>
          </div>
          <div>
            {isArray(filters) &&
              filters.map((filter, i) => (
                <Chip
                  key={i}
                  className={classes.chip}
                  icon={
                    <div className={classes.chipIcon}>{filter.property}</div>
                  }
                  label={filter.value}
                  onDelete={() => handleDeleteFilter(filter)}
                />
              ))}
          </div>
        </div>
      </Popover>
    </div>
  );

  function handleSetRate(e: React.ChangeEvent<{ value: unknown }>): void {
    setRate(e.target.value);
  }
};

export default React.memo(EditControl);
