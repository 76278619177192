import React, { useEffect, useState } from 'react';
import { Slider } from '@material-ui/core';
import {
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from '@kubecost-frontend/holster';
import {
  ArrowDownward,
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
} from '@material-ui/icons';
import { Header } from '../../../components/Header2New';
import { useClusters } from '../../../contexts/ClusterConfig';

interface RowDialogProps {
  close: () => void;
  name: string;
  passing: boolean;
  reasons: string[];
}

const RowClickDialog: React.FC<RowDialogProps> = ({
  close,
  name,
  passing,
  reasons,
}: RowDialogProps) => {
  return (
    <Modal onClose={close} open={Boolean(name && reasons.length)} title={name}>
      {!passing ? (
        reasons.map((r) => (
          <Typography key={r} variant="p" style={{ marginTop: 12 }}>
            <CancelIcon style={{ color: 'red' }} /> {r}
          </Typography>
        ))
      ) : (
        <>
          <Typography variant="p" style={{ marginTop: 12 }}>
            <CheckCircleIcon style={{ color: 'green' }} />
            Node request utilization (CPU & memory) for non-daemon and
            non-mirror pods is below threshold
          </Typography>

          <Typography variant="p" style={{ marginTop: 12 }}>
            <CheckCircleIcon style={{ color: 'green' }} />
            Kubernetes can reschedule all non-daemon and non-mirror pods that
            will need to be moved
          </Typography>

          <Typography variant="p" style={{ marginTop: 12 }}>
            <CheckCircleIcon style={{ color: 'green' }} />
            Node turndown will not break any pod disruption budgets
          </Typography>

          <Typography variant="p" style={{ marginTop: 12 }}>
            <CheckCircleIcon style={{ color: 'green' }} />
            No pods with local storage detected
          </Typography>

          <Typography variant="p" style={{ marginTop: 12 }}>
            <CheckCircleIcon style={{ color: 'green' }} />
            No pods annotated as unsafe to delete
          </Typography>

          <Typography variant="p" style={{ marginTop: 12 }}>
            <CheckCircleIcon style={{ color: 'green' }} />
            No master processes detected running on this node
          </Typography>
        </>
      )}
      <Button onClick={close} variant="default" style={{ marginTop: 12 }}>
        Close
      </Button>
    </Modal>
  );
};
const UnderUtilizedResources = () => {
  const [rowDialog, setRowDialog] =
    useState<{
      name: string;
      passing: boolean;
      reasons: string[];
    } | null>(null);
  const [nodes, setNodes] = useState<ClusterNode[]>([]);
  const [nodeDetails, setNodeDetails] = useState<ClusterNode | null>(null);
  const [threshold, setThreshold] = useState(60);
  const { baseApiUrl } = useClusters();

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Header title="Cluster Savings" refreshCallback={() => fetchData()} />

      <div style={{ marginBottom: '2em' }}>
        <Typography variant="h5">
          Nodes with underutilized CPU &amp; memory
        </Typography>
        <br />
        Nodes with low memory and CPU utilization are candidates for being
        turned down or resized. The following nodes have sustained usage below
        25% in both categories. Your cluster has enough resource availability to
        support turning these nodes down.
        <div style={{ marginTop: 36 }}>
          <Typography variant="p">
            Maximum CPU/RAM Request Utilization ({threshold}%)
          </Typography>
          <Slider
            onChange={(e, v) => setThreshold(v)}
            onChangeCommitted={fetchData}
            style={{ width: '30%' }}
            value={threshold}
          />
        </div>
      </div>

      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>Node</TableHeadCell>
            <TableHeadCell>Node Checks</TableHeadCell>
            <TableHeadCell>Pod Checks</TableHeadCell>
            <TableHeadCell>Recommendation</TableHeadCell>
            <TableHeadCell style={{ width: '30px' }}></TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {nodes.map((node) => (
            <TableRow
              key={node.nodeName}
              onClick={() => {
                setRowDialog({
                  name: node.nodeName,
                  passing: node.nodeCanScaleDown && node.podsCanScaleDown,
                  reasons: node.nodeReason
                    .concat(node.podReasons)
                    .filter((r) => r),
                });
              }}
            >
              <TableCell>{node.nodeName}</TableCell>
              <TableCell>{node.nodeRecommendation}</TableCell>
              <TableCell>{node.podRecommendation}</TableCell>
              <TableCell>{node.recommendation}</TableCell>
              <TableCell>
                <ArrowDownward />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <RowClickDialog
        close={() => setRowDialog(null)}
        name={rowDialog?.name || ''}
        passing={rowDialog?.passing || false}
        reasons={rowDialog?.reasons || []}
      />
    </>
  );

  async function fetchData() {
    let results = await fetch(
      `${baseApiUrl}/savings/nodeTurndown?utilization=${threshold / 100}`,
    ).then((r) => r.json());

    results.forEach((result) => {
      result.nodeRecommendation =
        result.nodeCanScaleDown === true ? 'Passed' : 'Failed';
      result.podRecommendation =
        result.podsCanScaleDown === true ? 'Passed' : 'Failed';
      result.recommendation = 'Do not drain';

      if (
        result.nodeCanScaleDown === true &&
        result.podsCanScaleDown === true &&
        typeof result.savings != 'undefined'
      ) {
        result.recommendation =
          'Safe to drain. Save $' + result.savings.toFixed(2) + ' / mo.';
      }

      if (
        result.nodeCanScaleDown === true &&
        result.podsCanScaleDown === false &&
        typeof result.savings != 'undefined'
      ) {
        result.recommendation =
          'Investigate pod issues. Potential savings of $' +
          result.savings.toFixed(2) +
          ' / mo.';
      }
    });

    setNodes(results);
  }
};

export default UnderUtilizedResources;

interface ClusterNode {
  isMaster: boolean;
  nodeCanScaleDown: boolean;
  nodeName: string;
  nodeReason: string[];
  nodeRecommendation: string;
  podReasons: string[];
  podRecommendation: string;
  podsCanScaleDown: boolean;
  recommendation: string;
  savings: number;
}
