// react
import React, { useState } from 'react';

// mui
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

// holster
import {
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from '@kubecost-frontend/holster';

// local
import { AlertTypes, Alert, BudgetAlert } from '../../services/alerts';

function BudgetAlertsTable({
  alerts,
  createAlert,
  removeAlert,
  defaults = {},
  locked = {},
}: ComponentProps): React.ReactElement {
  const [newWindow, setNewWindow] = useState('');
  const [newAgg, setNewAgg] = useState('');
  const [newFilter, setNewFilter] = useState('');
  const [newThreshold, setNewThreshold] = useState('');

  return (
    <Table data-test="alerts-table" className="w-full">
      <TableHead>
        <TableRow>
          <TableHeadCell>Window</TableHeadCell>
          <TableHeadCell>Aggregation</TableHeadCell>
          <TableHeadCell>Filter</TableHeadCell>
          <TableHeadCell>Threshold</TableHeadCell>
          <TableHeadCell>Add/Delete</TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {alerts.map((a) => (
          <TableRow key={a.id}>
            <TableCell>{a.window}</TableCell>
            <TableCell>{a.aggregation}</TableCell>
            <TableCell>{a.filter}</TableCell>
            <TableCell>{a.threshold}</TableCell>
            <TableCell>
              <IconButton
                data-test="alert-delete"
                onClick={() => removeAlert(a)}
              >
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell data-test="new-alert-window">
            {locked.window || (
              <Input
                value={newWindow}
                onChange={(e) => setNewWindow(e.target.value)}
              />
            )}
          </TableCell>
          <TableCell data-test="new-alert-aggregate">
            {locked.aggregate || (
              <Input
                value={newAgg}
                onChange={(e) => setNewAgg(e.target.value)}
              />
            )}
          </TableCell>
          <TableCell data-test="new-alert-filter">
            {locked.filter || (
              <Input
                value={newFilter}
                onChange={(e) => setNewFilter(e.target.value)}
              />
            )}
          </TableCell>
          <TableCell data-test="new-alert-threshold">
            {typeof locked.threshold === 'undefined' ? (
              <Input
                value={newThreshold}
                onChange={(e) => setNewThreshold(e.target.value)}
              />
            ) : (
              locked.threshold
            )}
          </TableCell>
          <TableCell>
            <IconButton data-test="new-alert-add" onClick={handleAddAlert}>
              <AddIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );

  function handleAddAlert() {
    const newAlert = new BudgetAlert({
      type: AlertTypes.Budget,
      window: newWindow || locked.window,
      aggregation: newAgg || locked.aggregate,
      filter: newFilter || locked.filter,
      threshold:
        typeof locked.threshold === 'undefined'
          ? parseFloat(newThreshold)
          : locked.threshold,
    });
    createAlert(newAlert);
    setNewWindow(defaults.window || '');
    setNewAgg(defaults.aggregate || '');
    setNewFilter(defaults.filter || '');
    setNewThreshold(
      typeof defaults.threshold === 'undefined'
        ? ''
        : defaults.threshold.toString(),
    );
  }
}

BudgetAlertsTable.defaultProps = {
  defaults: {},
  locked: {},
};

export default BudgetAlertsTable;

interface ComponentProps {
  alerts: BudgetAlert[];
  createAlert: (alert: Alert) => void;
  removeAlert: (alert: Alert) => void;
  defaults?: {
    window?: string;
    aggregate?: string;
    filter?: string;
    threshold?: number;
  };
  locked?: {
    window?: string;
    aggregate?: string;
    filter?: string;
    threshold?: number;
  };
}
