import React, { FC } from 'react';

import { NavIconProps } from './types';

const AssetsNavIcon: FC<NavIconProps> = ({ active, ...args }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...args}
    >
      <rect
        x="6"
        y="6"
        width="18"
        height="18"
        rx="1"
        strokeWidth="2"
        stroke={active ? '#63E892' : '#607971'}
      />
      <rect
        x="9"
        y="9"
        width="5"
        height="5"
        fill={active ? '#63E892' : '#607971'}
      />
      <rect
        x="9"
        y="16"
        width="5"
        height="5"
        fill={active ? '#63E892' : '#607971'}
      />
      <rect
        x="16"
        y="9"
        width="5"
        height="5"
        fill={active ? '#63E892' : '#607971'}
      />
      <rect
        x="16"
        y="16"
        width="5"
        height="5"
        fill={active ? '#63E892' : '#607971'}
      />
    </svg>
  );
};

export default AssetsNavIcon;
