import model from '../../../services/model';

const dateFormatter = Intl.DateTimeFormat(navigator.language, {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  timeZone: 'UTC',
});

const convertTimeSeries = (
  recommendation: number,
  request: [number, string][],
  usage: [number, string][],
) => {
  const timestampMap: Record<
    string,
    { recommendation: number; request: number; time: number; usage: number }
  > = {};
  request.forEach(([timestamp, value]) => {
    const ts = timestamp.toString();
    timestampMap[timestamp.toString()] = {
      recommendation,
      request: 0,
      time: timestamp,
      usage: 0,
    };
    timestampMap[ts].request = parseFloat(value);
  });
  usage.forEach(([timestamp, value]) => {
    const ts = timestamp.toString();
    if (!timestampMap[timestamp]) {
      timestampMap[timestamp.toString()] = {
        recommendation,
        request: 0,
        time: timestamp,
        usage: 0,
      };
    }
    timestampMap[ts].usage = parseFloat(value);
  });

  return Object.values(timestampMap)
    .sort((a, b) => (a.time > b.time ? 1 : -1))
    .map((datapoint) => {
      return {
        time: dateFormatter.format(datapoint.time * 1000),
        recommended: datapoint.recommendation,
        request: datapoint.request,
        usage: datapoint.usage,
      };
    });
};

function getQueryRangeParams(
  timeWindowDays: number,
  stepDuration: number,
  stepDurationUnits: string,
) {
  var end = new Date();
  var start = new Date(
    end.getFullYear(),
    end.getMonth(),
    end.getDate() - timeWindowDays,
  );

  var params = `&start=${encodeURIComponent(start.toISOString())}`;
  params += `&end=${encodeURIComponent(end.toISOString())}`;
  params += `&duration=${stepDuration + stepDurationUnits}`;
  params += `&window=${stepDuration + stepDurationUnits}`; //support both window and duration as the param for legacy

  return params;
}

export const getReservedInstanceData = (
  timeWindow: number,
  timeWindowUnits: string,
  prometheusURL: string,
  prometheusRangeURL: string,
) => {
  var q1 = `sum(kube_pod_container_resource_requests{resource="memory", unit="byte"}) / 1024 / 1024 / 1024`;
  var q2 = `sum(node_memory_MemTotal_bytes - node_memory_MemFree_bytes - node_memory_Cached_bytes - node_memory_Buffers_bytes) / 1024 / 1024 / 1024`;
  var q3 = `sum(kube_pod_container_resource_requests{resource="cpu", unit="core"})`;
  var q4 = `sum(rate(container_cpu_usage_seconds_total{container_name!=""}[5m]))`;
  const q5 = 'avg(avg_over_time(node_total_hourly_cost[1h])) by (instance)';

  var base = prometheusURL;
  var rangeBase = prometheusRangeURL;
  var params = getQueryRangeParams(timeWindow, 1, 'h');

  return Promise.all([
    fetch(rangeBase + encodeURIComponent(q1) + params).then((resp) =>
      resp.json(),
    ),
    fetch(rangeBase + encodeURIComponent(q2) + params).then((resp) =>
      resp.json(),
    ),
    fetch(rangeBase + encodeURIComponent(q3) + params).then((resp) =>
      resp.json(),
    ),
    fetch(rangeBase + encodeURIComponent(q4) + params).then((resp) =>
      resp.json(),
    ),
    getReservedRec(timeWindow, prometheusURL, prometheusRangeURL),
    model.get<{ items: unknown[] }>('/allNodes'),
    model.getConfigs(),
    fetch(base + encodeURIComponent(q5)).then((resp) => resp.json()),
  ]).then(
    ([qr1, qr2, qr3, qr4, reserveRecs, allNodes, modelConfigs, nodePrices]) => {
      let data = {};

      if (
        typeof qr1.data.result[0] === 'undefined' ||
        typeof qr2.data.result[0] === 'undefined' ||
        typeof qr3.data.result[0] === 'undefined' ||
        typeof qr4.data.result[0] === 'undefined'
      ) {
        return {
          loading: true,
        };
      }

      let cpuFloor = reserveRecs.totalCPUFloor;
      let memFloor = reserveRecs.totalMemoryFloor;
      let totalSavings = 0;
      let totalSpend = 0;
      const tableNodes = [];

      allNodes.items.forEach((node) => {
        if (cpuFloor <= 0 && memFloor <= 0) {
          return;
        }
        cpuFloor -= getNodeCPUCapacity(node);
        memFloor -= getNodeRamCapacityGiBytes(node);
        var nodeName = node.metadata.name;
        var instanceType = getInstanceType(node);
        var usageType = getNodeUsageType(node);
        let cpuAmount = getNodeCPUCapacity(node);
        let memAmount = getNodeRamCapacityGiBytes(node);
        let hourlyNodePrice = getNodePrice(
          nodePrices,
          nodeName,
          modelConfigs,
          cpuAmount,
          memAmount,
          usageType,
        );

        // exclude virtual resource price on Azure
        if (!nodeName.toLowerCase().includes('virtual-node')) {
          var monthlyCost = hourlyNodePrice * 730;
          var savings = monthlyCost * 0.4;
          totalSavings += savings;
          totalSpend += monthlyCost;
          tableNodes.push({
            instanceType,
            currentCost: '$' + monthlyCost.toFixed(2),
            savings: '$' + savings.toFixed(2) + ' / mo',
          });
        }
      });

      return {
        cpu: convertTimeSeries(
          reserveRecs.totalCPUFloor,
          qr3.data.result[0].values,
          qr4.data.result[0].values,
        ),
        memory: convertTimeSeries(
          reserveRecs.totalMemoryFloor,
          qr1.data.result[0].values,
          qr2.data.result[0].values,
        ),
        cards: {
          totalSavings,
          totalSpend,
        },
        table: tableNodes,
      };
    },
  );
};

/* All below is crud yanked from helper.js */

function getReservedRec(
  timeWindowDays: number,
  prometheusURL: string,
  prometheusRangeURL: string,
) {
  var DEFAULT_CPU_PRICE =
    typeof localstorage !== 'undefined' && localStorage.getItem('cpu') != null
      ? localStorage.getItem('cpu') / 730
      : 0.031611;
  var DEFAULT_RAM_PRICE =
    typeof localStorage !== 'undefined' && localStorage.getItem('ram') != null
      ? localStorage.getItem('ram') / 730
      : 0.004237;
  var q1 = `sum(kube_pod_container_resource_requests{resource="memory", unit="byte"}) / 1024 / 1024 / 1024`;
  var q2 = `sum(node_memory_MemTotal_bytes - node_memory_MemFree_bytes - node_memory_Cached_bytes - node_memory_Buffers_bytes) / 1024 / 1024 / 1024`;
  var q3 = `sum(kube_pod_container_resource_requests{resource="cpu", unit="core"})`;
  var q4 =
    'sum(rate(container_cpu_usage_seconds_total{container_name!=""}[5m]))';

  var base = prometheusURL;
  var rangeBase = prometheusRangeURL;
  var params = getQueryRangeParams(timeWindowDays, 30, 'm');

  return Promise.all([
    fetch(base + encodeURIComponent(q1)).then((resp) => resp.json()),
    fetch(rangeBase + encodeURIComponent(q2) + params).then((resp) =>
      resp.json(),
    ),
    fetch(base + encodeURIComponent(q3)).then((resp) => resp.json()),
    fetch(rangeBase + encodeURIComponent(q4) + params).then((resp) =>
      resp.json(),
    ),
  ]).then(([qr1, qr2, qr3, qr4]) => {
    if (
      typeof qr1.data === 'undefined' ||
      typeof qr1.data.result === 'undefined' ||
      qr1.data.result.length < 1 ||
      typeof qr2.data === 'undefined' ||
      typeof qr3.data === 'undefined' ||
      qr4 === null ||
      typeof qr4.data === 'undefined'
    ) {
      console.warn(
        'Warning: unable to return reserved instance recommendations',
      );
      return { totalMemoryFloor: 0, totalCPUFloor: 0, savings: 0 };
    }

    var memoryRequests = qr1.data.result[0].value[1];
    var memoryUsageLowPoint: number | null = null;

    if (
      typeof qr2.data != 'undefined' &&
      qr2.data.result.length > 0 &&
      qr2.data.result[0].values.length > 0
    ) {
      $.each(qr2.data.result[0].values, function (i, val) {
        let memUsage = parseFloat(val[1]);
        if (memoryUsageLowPoint === null || memUsage < memoryUsageLowPoint)
          memoryUsageLowPoint = memUsage;
      });
    } else {
      console.warn(
        'Warning: node exporter metrics may be missing or data may not be available yet.',
      );
    }

    var memoryCapacityFloor = memoryUsageLowPoint || 0;
    memoryCapacityFloor = Math.ceil(memoryCapacityFloor);

    var cpuRequests = qr3.data.result[0].value[1];
    var cpuUsageLowPoint = 0;
    var cpuUsageArray = [];

    if (typeof qr4.data.result[0] !== 'undefined') {
      $.each(qr4.data.result[0].values, function (i, val) {
        cpuUsageArray.push(parseFloat(val[1]));
      });
    }

    cpuUsageArray.sort(function (a, b) {
      return a - b;
    });
    if (cpuUsageArray.length > 0) {
      var index = Math.ceil(
        Math.min(cpuUsageArray.length - 1, cpuUsageArray.length * 0.2),
      );
      cpuUsageLowPoint = cpuUsageArray[index];
    }

    var cpuCapacityFloor = Math.max(
      cpuUsageLowPoint,
      Math.min(cpuRequests, cpuUsageLowPoint),
    );
    cpuCapacityFloor = Math.ceil(cpuCapacityFloor);

    var savings =
      memoryCapacityFloor * 0.3 * DEFAULT_RAM_PRICE * 730 +
      cpuCapacityFloor * 0.3 * DEFAULT_CPU_PRICE * 730;
    return {
      totalMemoryFloor: memoryCapacityFloor,
      totalCPUFloor: cpuCapacityFloor,
      savings: savings,
    };
  });
}

function getNodeCPUCapacity(node) {
  var instance = getInstanceType(node);
  var capacity = '0';

  if (node == null) return 0;
  else if (typeof node.status !== 'undefined')
    capacity = node.status.capacity.cpu;
  else if (typeof node.value !== 'undefined') capacity = node.value[1];
  else if (typeof node.values !== 'undefined') capacity = node.values[0][1];

  if (instance != null && instance.toLowerCase() === 'f1micro')
    capacity = '0.2';
  if (instance != null && instance.toLowerCase() === 'e2micro')
    capacity = '0.25';
  else if (
    instance != null &&
    (instance.toLowerCase() === 'g1small' ||
      instance.toLowerCase() === 'e2small')
  )
    capacity = '0.5';
  else if (instance != null && instance.toLowerCase() === 'e2medium')
    capacity = '1';

  return parseFloat(capacity);
}

function getInstanceType(node) {
  var type = null;

  if (
    node != null &&
    node.metric !== undefined &&
    node.metric.label_beta_kubernetes_io_instance_type
  ) {
    type = node.metric.label_beta_kubernetes_io_instance_type
      .split('-', 2)
      .join('');
  } else if (
    node != null &&
    node.metadata &&
    node.metadata.labels['beta.kubernetes.io/instance-type']
  ) {
    type = node.metadata.labels['beta.kubernetes.io/instance-type']
      .split('-', 2)
      .join('');
  } else if (node != null && node.metadata) {
    type = node.metadata.labels['type'];
  }

  return type;
}

function getNodeRamCapacityGiBytes(node) {
  var capacityAmt = node;

  if (typeof node.status != 'undefined') {
    var capacityStr = node.status.capacity.memory;

    if (
      typeof capacityStr === 'string' &&
      capacityStr.toLowerCase().includes('mi')
    )
      capacityAmt = parseInt(capacityStr) / 1024;
    else capacityAmt = parseInt(capacityStr) / 1024 / 1024;
  }

  capacityAmt = Math.ceil(parseFloat(capacityAmt) * 20) / 20;

  return capacityAmt;
}

function getNodeUsageType(node) {
  var type = 'OnDemand';

  if (node == null) {
    // assume not available if node isn't found
  } else if (
    node.metric !== undefined &&
    node.metric.label_cloud_google_com_gke_preemptible
  ) {
    type = 'Preemptible';
  } else if (
    node.metadata !== undefined &&
    node.metadata.labels['cloud.google.com/gke-preemptible']
  ) {
    type = 'Preemptible';
  } else if (
    node.metadata !== undefined &&
    node.metadata.labels[getConfig('spot_label_tag')] ==
      getConfig('spot_label') &&
    getConfig('spot_label') != null
  ) {
    type = 'Preemptible';
  }

  return type;
}

function getRemoteDiscount(modelConfigs) {
  var discount = 0;
  var negotiatedDiscount = 0;

  try {
    negotiatedDiscount = parseFloat(modelConfigs.negotiatedDiscount) / 100;
  } catch (error) {
    console.error(
      'Error: unable to load negotiated discount from model configs',
      modelConfigs,
    );
  }

  try {
    discount = parseFloat(modelConfigs.discount) / 100;
  } catch (error) {
    console.error(
      'Error: unable to load discount from model configs',
      modelConfigs,
    );
  }

  if (isNaN(discount)) discount = 0;
  if (isNaN(negotiatedDiscount)) negotiatedDiscount = 0;
  var totalDiscount = 1 - (1 - discount) * (1 - negotiatedDiscount);
  return totalDiscount;
}

function getNodePrice(np, nodeName, modelConfigs, cpus, GbRam, usageType) {
  let hourlyCPUPrice =
    usageType === 'spot' ? modelConfigs.spotCPU : modelConfigs.CPU;
  let hourlyRAMPrice =
    usageType === 'spot' ? modelConfigs.spotRAM : modelConfigs.RAM;
  let hourlyPrice = cpus * hourlyCPUPrice + GbRam * hourlyRAMPrice;
  let discount = getRemoteDiscount(modelConfigs);

  if (
    np === null ||
    typeof np.data === 'undefined' ||
    typeof np.data.result === 'undefined'
  ) {
    console.warn('Warning: unable to get node prices');
  } else {
    $.each(np.data.result, function (i, node) {
      let currentName = node.metric.instance;

      if (currentName === nodeName && typeof node.value === 'object') {
        hourlyPrice = node.value[1];
        return true;
      }
    });
  }

  // no discounts for spot or preemptible
  if (usageType !== 'OnDemand') {
    discount = 0;
  }

  return hourlyPrice * (1 - discount);
}
