/* eslint no-console: off */

function isTestingEnvironment(): boolean {
  return process.env.JEST_WORKER_ID !== undefined;
}

function error(...params: Array<unknown>): void {
  if (isTestingEnvironment()) return;
  console.error('[Kubecost]', ...params);
}

function log(...params: Array<unknown>): void {
  if (isTestingEnvironment()) return;
  console.log('[Kubecost]', ...params);
}

function warn(...params: Array<unknown>): void {
  if (isTestingEnvironment()) return;
  console.warn('[Kubecost]', ...params);
}

export default { error, log, warn };
