import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import { SnackbarCloseReason } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/styles';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import map from 'lodash/map';
import round from 'lodash/round';
import sortBy from 'lodash/sortBy';
import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import Loading from '../../components/Loading';
import Page from '../../components/Page';
import Warnings, { Warning } from '../../components/Warnings';
import { useInterval } from '../../hooks';
import api from '../../services/api';
import cluster from '../../services/cluster';
import Logger from '../../services/logger';
import model from '../../services/model';
import { fetchClusterSizingRecommendations } from '../../services/savings';
import { randomSuffix } from '../../services/util';
import { captureError } from '../../services/error_reporting';
import { getProfile } from './profiles';
import RecommendationTable from './RecommendationTable';
import DiagnosticsChecker from '../../components/DiagnosticsChecker';

const useStyles = makeStyles({
  description: {
    padding: '24px 36px',
    marginBottom: 20,
  },
  recommendations: {
    display: 'flex',
  },
  recommendationTable: {
    display: 'flex',
  },
  form: {
    alignItems: 'center',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    display: 'flex',
    padding: '16px 24px',
    marginBottom: 0,
  },
  formControl: {
    margin: 8,
    minWidth: 120,
  },
  advancedSwitch: {
    paddingLeft: 6,
    paddingTop: 8,
    paddingBottom: 8,
  },
});

const ClusterSizingPage = () => {
  const classes = useStyles();

  const [fetch, setFetch] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [warnings, setWarnings] = useState<Array<Warning>>([]);
  const [currency, setCurrency] = useState('');
  const [negotiatedDiscount, setNegotiatedDiscount] = useState(0.0);
  const [parameters, setParameters] = useState({});
  const [currentClusterInfo, setCurrentClusterInfo] = useState<any>(null);
  const [profile, setProfile] = useState('development');
  const [architecture, setArchitecture] = useState('x86');
  const [restrictArchitectureOptions, setRestrictArchitectureOptions] =
    useState(false);
  const [recommendations, setRecommendations] = useState({});
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [status, setStatus] = useState<
    { data: string } | { error: unknown; message: string; status: number }
  >({ data: 'Ready' });
  const [ready, setReady] = useState(false);
  const [setup, setSetup] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isThanosEnabled, setThanosEnabled] = useState(false);
  const [localCluster, setLocalCluster] = useState('');
  const [selectedCluster, setSelectedCluster] = useState('');
  const [clusters, setClusters] = useState<string[]>([]);
  const [clusterSizingRecommendations, setClusterSizingRecommendations] =
    useState<any | null>(null);

  useInterval(() => {
    if (!isThanosEnabled) {
      pollStatus();
    }
  }, 60000);

  async function adoptRecommendation(recommendation) {
    const data: { machineType: string; name: string; nodeCount: number }[] = [];
    recommendation.pools.forEach((pool, index) => {
      let name = pool.type.name;
      name = name.replace('.', '-');
      name += `-${randomSuffix()}`;
      data.push({
        name,
        machineType: pool.type.name,
        nodeCount: pool.count,
      });
    });

    setReady(false);
    cluster.resize(data);
  }

  async function initialize() {
    const config = await model.getConfigs();
    setCurrency(config.currencyCode);

    const nd = await model.getNegotiatedDiscount();
    setNegotiatedDiscount(nd);

    const clusterMap = await model.clusterInfoMap();
    const clusterInfo = await model.clusterInfo();
    const { thanosEnabled, thanosOffset: offset } =
      cluster.getMultiClusterStatus(clusterInfo);
    const cinfo = clusterMap[clusterInfo.id];

    // It is possible that ``clusterMap``, in some cases, may not contain an entry for a given cluster ID.
    // This causes ``cinfo`` to be undefined, which leaves us with no way to obtain the clusterNameId string.
    // In these cases, we simply use the cluster ID as-is for display and selection purposes.
    let clusterNameIdOrClusterId = clusterInfo.id;
    if (cinfo) {
      clusterNameIdOrClusterId = cluster.clusterNameId({
        clusterId: cinfo.id,
        clusterName: cinfo.name,
      });
    }

    setProfile(get(clusterInfo, 'clusterProfile', 'development'));
    setLocalCluster(clusterNameIdOrClusterId);
    setSelectedCluster(clusterNameIdOrClusterId);
    setThanosEnabled(thanosEnabled);

    const ccs = await model.clusterCosts('2d', offset, thanosEnabled);
    const clusts: string[] = [];
    keys(ccs).forEach((k) => {
      // for clusters not present in the map, use the cluster ID
      if (!(k in clusterMap)) {
        clusts.push(k);
        return;
      }
      const ci = clusterMap[k];
      const cnid = cluster.clusterNameId({
        clusterId: ci.id,
        clusterName: ci.name,
      });
      clusts.push(cnid);

      if (ci.provider === 'Azure' || ci.provider === 'GCP') {
        // NOTE: Cluster sizing ARM recommendations currently not available for Azure & GCP
        setRestrictArchitectureOptions(true);
      }
    });
    setClusters(clusts);

    // NOTE: Since Cluster Sizing spans multicluster now, we'll only want to enable
    // NOTE: actions when we're using non-multicluster.
    // console.log("ThanosEnabled: " + thanosEnabled + ", clusterInfo.Provider: " + clusterInfo.provider)
    if (!thanosEnabled) {
      // Enable cluster resize action on GCP and EKS only
      if (clusterInfo.provider === 'GCP') {
        setShowActions(true);
      } else if (clusterInfo.provider === 'AWS' && (await api.isEKS())) {
        setShowActions(true);
      }

      await pollStatus();
    }

    setInitialized(true);
  }

  // TODO niko/clustersizing clean up
  const handleCloseSnackbar = (
    event: unknown,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  async function pollStatus() {
    let stat: { data: string } | null = null;
    let error: { error: unknown; message: string; status: number } | null =
      null;

    try {
      stat = await cluster.getStatus();
    } catch (err) {
      error = {
        error: err,
        status: 0,
        message: 'Cluster controller temporarily down',
      };
      if (
        err &&
        typeof err === 'object' &&
        (err as { status: number }).status
      ) {
        error.status = (err as { status: number }).status;
      }
    }
    if (stat) {
      setStatus(stat);
    } else if (error) {
      setStatus(error);
    }

    const su = !error || error.status !== 404;
    setSetup(su);

    const message = get(stat, 'data', 'Cluster controller temporarily down');
    if (message !== get(status, 'data', '') && su) {
      setSnackbarMessage(message);
      setSnackbarOpen(true);
    }

    setReady(!!stat && stat.data === 'Ready');
  }

  async function fetchData() {
    clearWarnings();
    setLoading(true);

    try {
      const {
        minNodeCount,
        targetUtilization,
        description,
        range,
        p,
        allowSharedCore,
      } = getProfile(profile);
      const realClusterId =
        cluster.getClusterId(selectedCluster) || selectedCluster;
      const queryArch = architecture === 'any' ? '' : architecture;
      const queryRes = await fetchClusterSizingRecommendations(
        range,
        targetUtilization,
        minNodeCount,
        allowSharedCore,
        queryArch,
      );

      // Logs any warnings returned by the API call
      if (queryRes.warning) {
        Logger.warn(queryRes.warning)
      }

      let csrs = queryRes.data;
      if (isEmpty(csrs)) {
        throw new Error('No cluster sizing information available. Check console logs for more information.')
      }

      setClusterSizingRecommendations(csrs);

      const params = csrs[realClusterId].parameters;
      params.minNodeCount = minNodeCount;
      params.targetUtilization = targetUtilization;
      params.description = description;
      params.range = range;
      params.p = p;
      params.allowSharedCore = allowSharedCore;

      setParameters(params);

      const clusterInfo = csrs[realClusterId].currentClusterInfo;

      if (clusterInfo.totalCounts) {
        clusterInfo.totalCounts.utilizationVCPUs =
          (params.staticVCPUs +
            params.daemonSetVCPUs * clusterInfo.totalCounts.totalNodeCount) /
          clusterInfo.totalCounts.totalVCPUs;
        clusterInfo.totalCounts.utilizationRAMGB =
          (params.staticRAMGB +
            params.daemonSetRAMGB * clusterInfo.totalCounts.totalNodeCount) /
          clusterInfo.totalCounts.totalRAMGB;
      }

      setCurrentClusterInfo(clusterInfo);

      let recs = csrs[realClusterId].recommendations;

      if (recs.length === 0) {
        pushWarning({
          primary: 'No valid recommendations found',
          secondary: (
            <span>
              Change the input parameters (profile, chipset) and try again.
            </span>
          ),
        });
      }

      // Adjust cost with discount and add strategy name to recommendation
      recs = map(recs, (r, k) => {
        if (r === null) {
          return null;
        }

        const totalMonthlyCost =
          r.totalMonthlyCost * (1.0 - negotiatedDiscount);

        let strategy = 'Recommendation';
        if (k === 'single') {
          strategy = 'Recommendation: Simple';
        }
        if (k === 'multi') {
          strategy = 'Recommendation: Complex';
        }

        return { ...r, totalMonthlyCost, strategy };
      });

      // Ignore null recommendations
      recs = filter(recs, (r) => r !== null);

      // Ignore recommendations that are more expensive than the current cluster state
      recs = sortBy(recs, 'totalMonthlyCost');

      setRecommendations(recs);
    } catch (err) {
      Logger.error(err);
      pushWarning({
        primary: 'Failed to compute cluster sizing recommendations',
        secondary: <span>{err.message}</span>,
      });
      if (err instanceof Error) {
        captureError(err);
      }
    }

    setLoading(false);
    setFetch(false);
  }

  function clearWarnings() {
    setWarnings([]);
  }

  function pushWarning(warn: Warning) {
    setWarnings((warns: Warning[]) => [...warns, warn]);
  }

  const handleProfileChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    if (typeof e.target.value === 'string') {
      setProfile(e.target.value);
      setFetch(true);
    }
  };

  const handleArchitectureChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    if (typeof e.target.value === 'string') {
      setArchitecture(e.target.value);
      setFetch(true);
    }
  };

  const handleAdvancedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowAdvanced(e.target.checked);
  };

  const handleClusterChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    if (typeof e.target.value === 'string') {
      setSelectedCluster(e.target.value);
    }
  };

  async function reloadParameters() {
    try {
      const realClusterId =
        cluster.getClusterId(selectedCluster) || selectedCluster;

      const {
        minNodeCount,
        targetUtilization,
        description,
        range,
        p,
        allowSharedCore,
      } = getProfile(profile);

      const params = clusterSizingRecommendations[realClusterId].parameters;

      params.minNodeCount = minNodeCount;
      params.targetUtilization = targetUtilization;
      params.description = description;
      params.range = range;
      params.p = p;
      params.allowSharedCore = allowSharedCore;

      setParameters(params);
    } catch (err) {
      Logger.error(err);
      pushWarning({
        primary: 'Failed to fetch recommendations for secondary cluster',
        secondary: <span>Check console logs for more information.</span>,
      });
      if (err instanceof Error) {
        captureError(err);
      }
    }
  }

  async function reloadRecommendations() {
    try {
      const realClusterId =
        cluster.getClusterId(selectedCluster) || selectedCluster;

      let recs = clusterSizingRecommendations[realClusterId].recommendations;

      if (recs.length === 0) {
        pushWarning({
          primary: 'No valid recommendations found',
          secondary: (
            <span>
              Change the input parameters (profile, chipset) and try again.
            </span>
          ),
        });
      }

      // Adjust cost with discount and add strategy name to recommendation
      recs = map(recs, (r, k) => {
        if (r === null) {
          return null;
        }

        const totalMonthlyCost =
          r.totalMonthlyCost * (1.0 - negotiatedDiscount);

        let strategy = 'Recommendation';
        if (k === 'single') {
          strategy = 'Recommendation: Simple';
        }
        if (k === 'multi') {
          strategy = 'Recommendation: Complex';
        }

        return { ...r, totalMonthlyCost, strategy };
      });

      // Ignore null recommendations
      recs = filter(recs, (r) => r !== null);

      // Ignore recommendations that are more expensive than the current cluster state
      recs = sortBy(recs, 'totalMonthlyCost');

      setRecommendations(recs);
    } catch (err) {
      Logger.error(err);
      pushWarning({
        primary: 'Failed to fetch recommendations for secondary cluster',
        secondary: <span>Check console logs for more information.</span>,
      });
      if (err instanceof Error) {
        captureError(err);
      }
    }
  }

  async function reloadCurrentClusterInfo() {
    try {
      const realClusterId =
        cluster.getClusterId(selectedCluster) || selectedCluster;

      const clusterInfo =
        clusterSizingRecommendations[realClusterId].currentClusterInfo;
      const params = clusterSizingRecommendations[realClusterId].parameters;

      if (clusterInfo.totalCounts) {
        clusterInfo.totalCounts.utilizationVCPUs =
          (params.staticVCPUs +
            params.daemonSetVCPUs * clusterInfo.totalCounts.totalNodeCount) /
          clusterInfo.totalCounts.totalVCPUs;
        clusterInfo.totalCounts.utilizationRAMGB =
          (params.staticRAMGB +
            params.daemonSetRAMGB * clusterInfo.totalCounts.totalNodeCount) /
          clusterInfo.totalCounts.totalRAMGB;
      }
      setCurrentClusterInfo(clusterInfo);
    } catch (err) {
      Logger.error(err);
      pushWarning({
        primary: 'Failed to fetch recommendations for secondary cluster',
        secondary: <span>Check console logs for more information.</span>,
      });
      if (err instanceof Error) {
        captureError(err);
      }
    }
  }

  useEffect(() => {
    if (selectedCluster === '' || !clusterSizingRecommendations) {
      return;
    }

    if (warnings.length > 0) {
      setFetch(true);
    } else {
      clearWarnings();

      reloadParameters();
      reloadRecommendations();
      reloadCurrentClusterInfo();
    }
  }, [selectedCluster]);

  // Handle fetching data
  useEffect(() => {
    if (!initialized) {
      initialize();
    }
    if (initialized && fetch) {
      fetchData();
    }
  }, [initialized, fetch]);

  return (
    <>
      <Header
        breadcrumbs={[
          { name: 'Cluster Savings', href: 'savings.html' },
          { name: 'Cluster Sizing', href: 'cluster-sizing.html' },
        ]}
      >
        <IconButton aria-label="refresh" onClick={() => setFetch(true)}>
          <RefreshIcon />
        </IconButton>
        <DiagnosticsChecker />
        <Link href="settings.html">
          <IconButton aria-label="refresh">
            <SettingsIcon />
          </IconButton>
        </Link>
      </Header>

      <Paper className={classes.description}>
        <Typography variant="h5" paragraph>
          Cluster Sizing Recommendations
        </Typography>
        <Typography variant="body1" paragraph>
          Right-sizing recommendations use two primary inputs: first, your own
          description of the type of work running on the cluster (e.g.
          development, production, high-availability); second, the
          &quot;shape&quot; of the each workload&apos;s resource requirements,
          as measured by Kubecost metrics. We then consider different heuristic
          strategies for meeting the cluster&apos;s requirements.{' '}
          <Link
            href="http://blog.kubecost.com/blog/cluster-right-sizing/"
            target="_blank"
            rel="noopener"
          >
            Learn more
          </Link>
          .
        </Typography>
        <Grid container spacing={3}>
          <Grid item md={5} lg={4}>
            <FormControl className={classes.formControl}>
              <Select
                id="cluster-select"
                value={selectedCluster}
                onChange={handleClusterChange}
              >
                <MenuItem key={localCluster} value={localCluster}>
                  {localCluster}
                </MenuItem>
                {map(clusters, (clust) =>
                  clust !== localCluster ? (
                    <MenuItem key={clust} value={clust}>
                      {clust}
                    </MenuItem>
                  ) : null,
                )}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <Select
                id="profile-select"
                value={profile}
                onChange={handleProfileChange}
              >
                <MenuItem key="development" value="development">
                  Development
                </MenuItem>
                <MenuItem key="production" value="production">
                  Production
                </MenuItem>
                <MenuItem key="high-availability" value="high-availability">
                  High-availability
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <Select
                id="architecture-select"
                value={architecture}
                onChange={handleArchitectureChange}
              >
                <MenuItem key="x86" value="x86">
                  x86
                </MenuItem>
                {restrictArchitectureOptions ? (
                  <MenuItem key="any" value="any" disabled={true}>
                    Any Architecture (unavailable)
                  </MenuItem>
                ) : (
                  <MenuItem key="any" value="any">
                    Any Architecture
                  </MenuItem>
                )}
                {restrictArchitectureOptions ? (
                  <MenuItem key="ARM" value="ARM" disabled={true}>
                    ARM (unavailable)
                  </MenuItem>
                ) : (
                  <MenuItem key="ARM" value="ARM">
                    ARM
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            <Typography variant="body2" style={{ marginLeft: 8 }}>
              {get(parameters, 'description', '')}
            </Typography>
          </Grid>
          <Grid item md={7} lg={8}>
            <div className={classes.advancedSwitch}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showAdvanced}
                    onChange={handleAdvancedChange}
                    value="showAdvanced"
                    color="primary"
                    size="small"
                  />
                }
                label="Show advanced metrics"
              />
            </div>
            {showAdvanced && (
              <>
                <Typography variant="body1" paragraph>
                  These parameters are specific to your cluster and are computed
                  using Kubecost metrics.
                </Typography>
                <Typography variant="h6">Static Resources</Typography>
                <Typography variant="body1">
                  Sum of resources allocated to pods not controlled by
                  DaemonSets.
                </Typography>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="static-vcpus"
                    label="Static VCPUs"
                    value={round(get(parameters, 'staticVCPUs', 0), 4)}
                    InputProps={{ readOnly: true }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="static-ramgb"
                    label="Static RAM (GB)"
                    value={round(get(parameters, 'staticRAMGB', 0), 4)}
                    InputProps={{ readOnly: true }}
                  />
                </FormControl>
                <Typography variant="h6">DaemonSet Resources</Typography>
                <Typography variant="body1">
                  Sum of resources allocated to pods controlled by DaemonSets.
                </Typography>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="daemonset-vcpus"
                    label="DaemonSet VCPUs"
                    value={round(get(parameters, 'daemonSetVCPUs', 0), 4)}
                    InputProps={{ readOnly: true }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="daemonset-ramgb"
                    label="DaemonSet RAM (GB)"
                    value={round(get(parameters, 'daemonSetRAMGB', 0), 4)}
                    InputProps={{ readOnly: true }}
                  />
                </FormControl>
                <Typography variant="h6">Max Pod Resources</Typography>
                <Typography variant="body1">
                  Largest resource values for any single pod in your cluster.
                </Typography>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="max-pod-vcpus"
                    label="Max VCPUs"
                    value={round(get(parameters, 'maxPodVCPUs', 0), 4)}
                    InputProps={{ readOnly: true }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="max-pod-ramgb"
                    label="Max RAM (GB)"
                    value={round(get(parameters, 'maxPodRAMGB', 0), 4)}
                    InputProps={{ readOnly: true }}
                  />
                </FormControl>
              </>
            )}
          </Grid>
        </Grid>
      </Paper>

      {!loading && warnings.length > 0 ? (
        <Warnings warnings={warnings} />
      ) : (
        <></>
      )}

      {loading ? (
        <Loading message="Computing optimal node recommendations. This may take a moment..." />
      ) : (
        <></>
      )}

      {!loading && warnings.length === 0 ? (
        <div className={classes.recommendationTable}>
          <RecommendationTable
            recommendations={recommendations}
            currentClusterInformation={currentClusterInfo}
            negotiatedDiscount={negotiatedDiscount}
            status={status}
            ready={ready}
            setup={setup}
            showActions={showActions}
            adoptRecommendation={adoptRecommendation}
            currency={currency}
          />
        </div>
      ) : (
        <></>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={snackbarOpen}
        autoHideDuration={10000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
};

export default React.memo(ClusterSizingPage);
