import React, { FC, ReactNode } from 'react';
import { Avatar, Chip } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardICon from '@material-ui/icons/ArrowDownward';

interface TrendIndicatorChipProps {
  trendIndicator?: ReactNode;
  arrowDirection?: 'UP' | 'DOWN';
  isEfficiency?: boolean;
  style?: Record<string, string | number>;
}

export const TrendIndicatorChip: FC<TrendIndicatorChipProps> = ({
  trendIndicator,
  arrowDirection,
  isEfficiency,
  style,
}) => {
  const whichArrowIcon = () => {
    if (arrowDirection === 'UP') {
      return <ArrowUpwardIcon fontSize="small" />;
    }
    return <ArrowDownwardICon fontSize="small" />;
  };

  return (
    <Chip
      size="small"
      variant="default"
      style={{
        opacity: 0.9,
        color: 'white',
        backgroundColor: isEfficiency
          ? arrowDirection === 'UP'
            ? '#4caf50'
            : '#f50057'
          : arrowDirection === 'UP'
          ? '#f50057'
          : '#4caf50',
        ...style,
      }}
      label={`${trendIndicator}%`}
      avatar={
        <Avatar style={{ backgroundColor: 'transparent', color: 'white' }}>
          {whichArrowIcon()}
        </Avatar>
      }
    />
  );
};
