import {
  Card,
  CardContent,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import { Link } from 'react-router-dom';
import Header from '../../../components/Header';
import { useClusters } from '../../../contexts/ClusterConfig';
import { getReservedInstanceData } from './api';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { colorMap } from '../../../services/colors';

const StatCard = ({ title, value }: { title: string; value: string }) => (
  <div style={{ textAlign: 'center', margin: '1em' }}>
    <div style={{ margin: 'auto', border: '1px solid rgba(0,0,0,0.1)' }}>
      <p style={{ color: 'rgba(0,0,0,0.6)' }}>{title}</p>
      <h1>{value}</h1>
    </div>
  </div>
);

const ReservedInstances = () => {
  const [reservedData, setReservedData] = useState<{
    cards: {
      totalSavings: number;
      totalSpend: number;
    };
    cpu: {
      time: string;
      recommendation: number;
      request: number;
      usage: number;
    }[];
    memory: {
      time: string;
      recommendation: number;
      request: number;
      usage: number;
    }[];
    table: {
      instanceType: string;
      currentCost: string;
      savings: string;
    }[];
  }>({
    cards: { totalSavings: 0, totalSpend: 0 },
    cpu: [],
    memory: [],
    table: [],
  });
  const { promQueryUrl, promRangeQueryUrl } = useClusters();

  async function fetchData() {
    let values = await getReservedInstanceData(
      30,
      '3',
      promQueryUrl,
      promRangeQueryUrl,
    );
    setReservedData(values);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Header
        breadcrumbs={[
          { name: 'Cluster Savings', href: 'savings' },
          { name: 'Reserved Instances', href: 'reserved-instances' },
        ]}
      >
        <IconButton onClick={() => fetchData()} aria-label="refresh">
          <RefreshIcon />
        </IconButton>
        <Link to="settings">
          <IconButton aria-label="refresh">
            <SettingsIcon />
          </IconButton>
        </Link>
      </Header>

      <Card style={{ marginBottom: '2em' }}>
        <CardContent>
          <Typography variant="h5">Reserved Instances</Typography>
          <br />A recommended strategy for purchasing reserved instances is to
          observe the sustained usage/request low point (1st percentile) for
          both RAM and CPU capacity. This is often a good indicator for your
          future floor for CPU/RAM needs.
        </CardContent>
        <hr />

        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <div
            style={{
              marginLeft: '1em',
              paddingTop: '1em',
              paddingBottom: '1em',
            }}
          >
            <h4 style={{ margin: 0 }}>
              Memory Usage and Reservation Recommendation
            </h4>
            <p style={{ margin: 0 }}>
              Requests and usage for all containers in this pod
            </p>
            <ResponsiveContainer height={290} width="100%">
              <LineChart
                data={reservedData.memory}
                margin={{
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 15,
                }}
              >
                <CartesianGrid vertical={false} />
                <XAxis dataKey="time" />
                <YAxis />
                <Line
                  dataKey="request"
                  dot={false}
                  type="monotone"
                  stroke={colorMap.blue}
                />
                <Line
                  type="monotone"
                  dataKey="recommended"
                  dot={false}
                  stroke="#f4b400"
                />
                <Line
                  dataKey="usage"
                  dot={false}
                  type="monotone"
                  stroke={colorMap.red}
                />
                <Tooltip />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div style={{ marginLeft: '1em', paddingTop: '1em' }}>
            <h4 style={{ margin: 0 }}>
              CPU Usage and Reservation Recommendation
            </h4>
            <p style={{ margin: 0 }}>
              Requests and usage for all containers in this pod
            </p>
            <ResponsiveContainer height={290} width="100%">
              <LineChart
                data={reservedData.cpu}
                margin={{
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 15,
                }}
              >
                <CartesianGrid vertical={false} />
                <XAxis dataKey="time" />
                <YAxis />
                <Line
                  dataKey="request"
                  dot={false}
                  stroke={colorMap.blue}
                  type="monotone"
                />
                <Line
                  type="monotone"
                  dataKey="recommended"
                  dot={false}
                  stroke="#f4b400"
                />
                <Line
                  dataKey="usage"
                  dot={false}
                  stroke={colorMap.red}
                  type="monotone"
                />
                <Tooltip />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <hr />
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
          <StatCard
            title="Estimated Annual Savings"
            value={'$' + (reservedData.cards.totalSavings * 12).toFixed(2)}
          />
          <StatCard
            title="Savings Rate"
            value={
              (
                (100 * reservedData.cards.totalSavings) /
                  reservedData.cards.totalSpend || 0
              ).toFixed() + '%'
            }
          />
          <StatCard title="Upfront Payment" value="$0.00" />
        </div>
      </Card>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Instance Type</TableCell>
              <TableCell>Current Cost</TableCell>
              <TableCell>Recommendation</TableCell>
              <TableCell>Savings</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reservedData.table.map((row) => {
              return (
                <TableRow>
                  <TableCell>{row.instanceType}</TableCell>
                  <TableCell>{row.currentCost}</TableCell>
                  <TableCell>Reserve</TableCell>
                  <TableCell>{row.savings}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ReservedInstances;
