import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { Grid, TextField, Typography } from '@material-ui/core';
import model from '../../services/model';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface SpotInstanceModalProps {
  visible: boolean;
  closeModal: () => void;
}

interface SpotFormItem {
  label: string;
  dataKey: string;
}

const spotInstanceItems: SpotFormItem[] = [
  { label: 'Node label for spot instances', dataKey: 'spotLabel' },
  { label: 'Node label value for spot instances', dataKey: 'spotLabelValue' },
  { label: 'Spot data bucket name', dataKey: 'bucketName' },
  { label: 'Spot data prefix', dataKey: 'prefix' },
  { label: 'Spot data region', dataKey: 'region' },
  { label: 'AWS account ID', dataKey: 'projectID' },
  { label: 'Service key name', dataKey: 'serviceKeyName' },
  { label: 'Service key secret', dataKey: '' }, // We don't actually send this in the payload??
];

const defaultSpotFormPayload = {
  spotLabel: '',
  spotLabelValue: '',
  bucketName: '',
  prefix: '',
  region: '',
  projectID: '',
  serviceKeyName: '',
};

export const SpotInstanceModal = ({
  visible,
  closeModal,
}: SpotInstanceModalProps) => {
  const [spotInstancePayload, setSpotInstancePayload] = useState(
    defaultSpotFormPayload,
  );

  const handleUpdate = async () => {
    const spotResp = await model.updateSpotInfoConfigs(spotInstancePayload);
    closeModal();
  };

  return (
    <div>
      <Dialog
        open={visible}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeModal}
      >
        <DialogTitle>Update Spot Feed Configuration</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Receive more accurate Spot pricing data by enabling the AWS Spot
            Instances data feed{' '}
            <a
              target="_blank"
              href="https://guide.kubecost.com/hc/en-us/articles/4407595947799#spot-nodes"
            >
              Learn More
            </a>
          </Typography>
          {spotInstanceItems.map((item: SpotFormItem) => (
            <Grid spacing={3} style={{ marginTop: '.5em' }} container>
              <Grid item xs={6}>
                <Typography variant="body1">{item.label}</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label={item.label}
                  variant="outlined"
                  onChange={(e: any) =>
                    setSpotInstancePayload({
                      ...spotInstancePayload,
                      [`${item.dataKey}`]: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Close
          </Button>
          <Button onClick={handleUpdate} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
