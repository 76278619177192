import React, { FC, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, Typography } from '@material-ui/core';
import model from '../../services/model';
import { Transition } from './DialogTransition';
import logger from '../../services/logger';
import { AzureConfigForm } from './AzureConfigForm';
import { SnackbarTypes } from './types';
interface UpdateAzureConfigModalProps {
  visible: boolean;
  closeModal: () => void;
}

export const UpdateAzureConfigModal: FC<UpdateAzureConfigModalProps> = ({
  visible,
  closeModal,
}) => {
  const [snackbar, setSnackbar] = useState<SnackbarTypes>({});

  const handleUpdate = async (values: any) => {
    try {
      await model.updateAzureConfig(values);
      setSnackbar({
        message: 'Azure Config updates succesfully',
        severity: 'success',
      });
    } catch (err) {
      if (err instanceof Error) {
        setSnackbar({
          message: err.message,
          severity: 'error',
        });
      }
      logger.log(err);
    }
    closeModal();
  };

  return (
    <Dialog
      open={visible}
      TransitionComponent={Transition as any}
      keepMounted
      onClose={closeModal}
      data-testid="azure-form-modal"
    >
      <DialogActions>
        <Button onClick={closeModal} color="primary" type="submit">
          X
        </Button>
      </DialogActions>
      <DialogTitle>Azure Billing Data Export Configuration</DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          To allocate out of cluster Azure costs (e.g. Azure blob storage),
          enable billing data export and tag assets appropriately.{' '}
          <a
            target="_blank"
            href="https://guide.kubecost.com/hc/en-us/articles/4407595934871-Azure-Config"
          >
            Learn More
          </a>
        </Typography>
        <Grid
          spacing={3}
          style={{
            marginTop: '.5em',
            display: 'flex',
          }}
          container
        >
          <Grid item xs={6}>
            <AzureConfigForm
              handleUpdateAzureConfig={handleUpdate}
              snackbar={snackbar}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
