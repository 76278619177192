// react
import React from 'react';

// recharts
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';

// local
import { colorMap } from '../../services/colors';
import { toCurrency } from '../../services/format';

interface ARChartData {
  totalCost: number;
  name: string;
}

interface ARChartProps {
  data: ARChartData[];
  dataKey: string;
  width: number;
}

export const ARChart = ({ data, dataKey, width }: ARChartProps) => {
  return (
    <BarChart width={width} height={250} data={data} margin={{ left: 30 }}>
      <CartesianGrid strokeDasharray="0" vertical={false} />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip formatter={(value) => toCurrency(value, 'USD')} />
      <Bar key={dataKey} dataKey={dataKey} fill={colorMap.green} />
    </BarChart>
  );
};
