import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link as MuiLink,
  Paper,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  ArrowDownward,
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
  Refresh as RefreshIcon,
  Settings as SettingsIcon,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import Header from '../../../components/Header';
import { useClusters } from '../../../contexts/ClusterConfig';

interface RowDialogProps {
  close: () => void;
  name: string;
  passing: boolean;
  reasons: string[];
}

const RowClickDialog: React.FC<RowDialogProps> = ({
  close,
  name,
  passing,
  reasons,
}: RowDialogProps) => {
  return (
    <Dialog fullWidth onClose={close} open={Boolean(name && reasons.length)}>
      <DialogTitle>{name}</DialogTitle>
      <DialogContent>
        {!passing ? (
          reasons.map((r) => (
            <Typography key={r}>
              <CancelIcon style={{ color: 'red' }} /> {r}
            </Typography>
          ))
        ) : (
          <>
            <Typography>
              <CheckCircleIcon style={{ color: 'green' }} />
              Node request utilization (CPU & memory) for non-daemon and
              non-mirror pods is below threshold
            </Typography>

            <Typography>
              <CheckCircleIcon style={{ color: 'green' }} />
              Kubernetes can reschedule all non-daemon and non-mirror pods that
              will need to be moved
            </Typography>

            <Typography>
              <CheckCircleIcon style={{ color: 'green' }} />
              Node turndown will not break any pod disruption budgets
            </Typography>

            <Typography>
              <CheckCircleIcon style={{ color: 'green' }} />
              No pods with local storage detected
            </Typography>

            <Typography>
              <CheckCircleIcon style={{ color: 'green' }} />
              No pods annotated as unsafe to delete
            </Typography>

            <Typography>
              <CheckCircleIcon style={{ color: 'green' }} />
              No master processes detected running on this node
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
const UnderUtilizedResources = () => {
  const [rowDialog, setRowDialog] =
    useState<{
      name: string;
      passing: boolean;
      reasons: string[];
    } | null>(null);
  const [nodes, setNodes] = useState<ClusterNode[]>([]);
  const [nodeDetails, setNodeDetails] = useState<ClusterNode | null>(null);
  const [threshold, setThreshold] = useState(60);
  const { baseApiUrl } = useClusters();

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Header
        breadcrumbs={[
          { name: 'Cluster Savings', href: 'savings' },
          { name: 'Low Disk Utilization', href: 'action' },
        ]}
      >
        <IconButton aria-label="refresh">
          <RefreshIcon />
        </IconButton>
        <MuiLink component={Link} to="../settings">
          <IconButton aria-label="settings">
            <SettingsIcon />
          </IconButton>
        </MuiLink>
      </Header>

      <Card style={{ marginBottom: '2em' }}>
        <CardContent>
          <Typography variant="h5">
            Nodes with underutilized CPU &amp; memory
          </Typography>
          <br />
          Nodes with low memory and CPU utilization are candidates for being
          turned down or resized. The following nodes have sustained usage below
          25% in both categories. Your cluster has enough resource availability
          to support turning these nodes down.
          <div style={{ marginTop: 36 }}>
            <Typography>
              Maximum CPU/RAM Request Utilization ({threshold}%)
            </Typography>
            <Slider
              onChange={(e, v) => setThreshold(v)}
              onChangeCommitted={fetchData}
              style={{ width: '30%' }}
              value={threshold}
            />
          </div>
        </CardContent>
      </Card>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Node</TableCell>
              <TableCell>Node Checks</TableCell>
              <TableCell>Pod Checks</TableCell>
              <TableCell>Recommendation</TableCell>
              <TableCell style={{ width: '30px' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {nodes.map((node) => (
              <TableRow
                hover
                key={node.nodeName}
                onClick={() => {
                  setRowDialog({
                    name: node.nodeName,
                    passing: node.nodeCanScaleDown && node.podsCanScaleDown,
                    reasons: node.nodeReason
                      .concat(node.podReasons)
                      .filter((r) => r),
                  });
                }}
                style={{ cursor: 'pointer' }}
              >
                <TableCell>{node.nodeName}</TableCell>
                <TableCell>{node.nodeRecommendation}</TableCell>
                <TableCell>{node.podRecommendation}</TableCell>
                <TableCell>{node.recommendation}</TableCell>
                <TableCell>
                  <ArrowDownward />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <RowClickDialog
        close={() => setRowDialog(null)}
        name={rowDialog?.name || ''}
        passing={rowDialog?.passing || false}
        reasons={rowDialog?.reasons || []}
      />
    </>
  );

  async function fetchData() {
    let results = await fetch(
      `${baseApiUrl}/savings/nodeTurndown?utilization=${threshold / 100}`,
    ).then((r) => r.json());

    results.forEach((result) => {
      result.nodeRecommendation =
        result.nodeCanScaleDown === true ? 'Passed' : 'Failed';
      result.podRecommendation =
        result.podsCanScaleDown === true ? 'Passed' : 'Failed';
      result.recommendation = 'Do not drain';

      if (
        result.nodeCanScaleDown === true &&
        result.podsCanScaleDown === true &&
        typeof result.savings != 'undefined'
      ) {
        result.recommendation =
          'Safe to drain. Save $' + result.savings.toFixed(2) + ' / mo.';
      }

      if (
        result.nodeCanScaleDown === true &&
        result.podsCanScaleDown === false &&
        typeof result.savings != 'undefined'
      ) {
        result.recommendation =
          'Investigate pod issues. Potential savings of $' +
          result.savings.toFixed(2) +
          ' / mo.';
      }
    });

    setNodes(results);
  }
};

export default UnderUtilizedResources;

interface ClusterNode {
  isMaster: boolean;
  nodeCanScaleDown: boolean;
  nodeName: string;
  nodeReason: string[];
  nodeRecommendation: string;
  podReasons: string[];
  podRecommendation: string;
  podsCanScaleDown: boolean;
  recommendation: string;
  savings: number;
}
