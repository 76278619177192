import { Button, Paper, Tooltip, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles({
  section: {
    margin: "12px 12px 12px 12px",
    display: "flex",
    flexFlow: "column",
    padding: "12px 0px 12px 0px",
  },
  content: {
    width: "100%",
    display: "flex",
    flexFlow: "row",
    verticalAlign: "middle",
  },
  info: {
    margin: "0 16px 0 0",
    verticalAlign: "middle",
    fontSize: "20px",
    color: grey[500],
  },
});

const PaperSection = (props) => {
  const classes = useStyles();

  const {
    id,
    title,
    buttonText = "",
    buttonHref = "",
    buttonVariant = "",
    buttonColor = "default",
    info = "",
    elevation = 0,
    buttonDisabled,
  } = props;

  return (
    <Paper elevation={elevation}>
      <div className={classes.section}>
        <div className={classes.content}>
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
          {info !== "" && (
            <Tooltip title={info} placement="right">
              <InfoIcon className={classes.info} />
            </Tooltip>
          )}
          {buttonText !== "" && (
            <>
              <div style={{ flex: 1 }} />
              {buttonDisabled ? (
                <Tooltip title={buttonDisabled}>
                  <span>
                    <Button
                      variant={buttonVariant}
                      color={buttonColor}
                      target="_blank"
                      rel="noopener"
                      href={buttonHref}
                      disabled
                    >
                      {buttonText}
                    </Button>
                  </span>
                </Tooltip>
              ) : (
                <Button
                  variant={buttonVariant}
                  color={buttonColor}
                  target="_blank"
                  rel="noopener"
                  href={buttonHref}
                >
                  {buttonText}
                </Button>
              )}
            </>
          )}
        </div>
        {props.children}
      </div>
    </Paper>
  );
};

export default PaperSection;
