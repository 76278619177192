import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

function NewClusterModal({ addCluster, close, open }) {
  const [address, setAddress] = useState('');

  return (
    <Dialog onClose={close} open={open}>
      <DialogTitle>Add new context</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter an accessible Kubecost endpoint to view data for another cluster
          <br />
          <Link
            target="_blank"
            rel="noreferrer"
            href="http://docs.kubecost.com/multi-cluster"
          >
            Learn more
          </Link>
        </DialogContentText>
        <TextField
          autoFocus
          fullWidth
          label="New address, e.g. http://localhost:9090"
          type="text"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          onKeyUp={(e) => handleKeyup(e)}
        />
      </DialogContent>
      <DialogActions>
        <Button fullWidth={false} color="primary" onClick={() => close()}>
          Close
        </Button>
        <Button
          fullWidth={false}
          color="primary"
          variant="contained"
          onClick={() => addCluster(address)}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );

  function handleKeyup(e) {
    if (e.keyCode === 13) {
      addCluster(address);
    }
  }
}

NewClusterModal.propTypes = {
  addCluster: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default NewClusterModal;
