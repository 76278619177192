// react
import React, { useEffect, useState, FC } from 'react';

// mui
import { Switch } from '@material-ui/core';
import Info from '@material-ui/icons/Info';
import {
  Chip,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Tooltip,
} from '@kubecost-frontend/holster';

// local
import { Header } from '../../components/Header2New';
import { findIssues } from './api';
import { renderScoreGraph } from './legacy';
import { issues } from './issues';
import IssueDialog from './IssueDialog';
import '../../vendor/css/health-new.css';

export const Health: FC = () => {
  const [results, setResults] = useState([]);
  const [onlyShowErrors, setOnlyShowErrors] = useState(false);
  const [selectedResult, setSelectedResult] = useState(null);
  const [selectedError, setSelectedError] = useState(null);

  useEffect(() => {
    findIssues((results: any) => {
      setResults(results);
    });
  }, []);

  useEffect(() => {
    renderScoreGraph();
  }, []);

  return (
    <div id="health-new">
      <IssueDialog
        issue={selectedResult}
        error={selectedError}
        open={selectedResult !== null}
        onClose={() => {
          setSelectedError(null);
          setSelectedResult(null);
        }}
      />
      <Header title="Health" refreshCallback={() => window.location.reload()} />
      <div>
        <div style={{ marginBottom: '1em', textAlign: 'center' }}>
          <span id="health-description">
            <span>Generating health score...</span>
          </span>
          <Tooltip
            header="Cluster Health"
            position="BOTTOM"
            content="Cluster health ratings are an assessment of infrastructure reliability and performance risks. Scores range from 1-100."
          >
            <Info style={{ fontSize: '17px', marginLeft: '0.5em' }} />
          </Tooltip>
        </div>
        <div className="gauge-container">
          <div className="gauge-a"></div>
          <div className="gauge-b"></div>
          <div className="gauge-c"></div>
          <div className="gauge-data">
            <div
              id="gauge-loading"
              className="mdl-spinner mdl-js-spinner is-active"
            />
            <h2 id="gauge-percent"></h2>
          </div>
        </div>
        <div style={{ float: 'right' }} id="toggle-box">
          <span>Show All</span>
          <Switch
            color="default"
            onChange={(e) => setOnlyShowErrors(!e.target.checked)}
            defaultChecked
          />
        </div>
        <>
          <Table style={{ width: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell>Health Test</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results &&
                issues.map((issue) => {
                  let result = results[issue[0]];
                  let error =
                    result &&
                    (result.data === true ||
                      (result.data !== null && result.data.length > 0) ||
                      (result.data !== null &&
                        result.data.overThreshold === true));
                  if (onlyShowErrors && !error) {
                    return <></>;
                  }

                  return (
                    <TableRow
                      onClick={() => {
                        setSelectedResult(issue);
                        setSelectedError(result);
                      }}
                      id="${id}"
                    >
                      <TableCell>
                        <span className="issue-title">{issue[2]}</span>

                        <Chip
                          color="primary"
                          label={issue[1]}
                          style={{ marginLeft: '1em' }}
                        />

                        {issue[3] && issue[3] === 'high' && (
                          <Chip
                            color="danger"
                            label="high"
                            style={{
                              marginLeft: '1em',
                              color: 'white',
                              backgroundColor: 'red',
                            }}
                          />
                        )}

                        {issue[3] && issue[3] !== 'high' && (
                          <Chip
                            color="warning"
                            label="medium"
                            style={{
                              marginLeft: '1em',
                              color: 'white',
                              backgroundColor: 'orange',
                            }}
                          />
                        )}

                        {/* ${getSeverityChip(issues[i][3])} */}
                      </TableCell>
                      <TableCell className="issue-cost">
                        {error && <Icon variant="danger" />}
                        {!error && <Icon variant="success" />}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </>

        <div id="message-box"></div>
      </div>

      <dialog id="switch-dialog" className="mdl-dialog"></dialog>
    </div>
  );
};

Health.displayName = 'Health';
