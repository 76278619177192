import React from 'react'
import { AreaChart, XAxis, YAxis, Tooltip, CartesianGrid, Area, Bar, BarChart, Legend, ResponsiveContainer } from 'recharts'
import { colorMap, primary } from '../../services/colors'
import { toCurrency } from '../../services/format'

interface AreaChartVisualAddOnProps {
    // width: number;
    dataSet: any;

}

export const BarChartVisualAddOn = ({ dataSet}: AreaChartVisualAddOnProps) => {
    const currency = 'USD'
    
    return(
        <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={dataSet}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          {/* <Legend /> */}
          <Bar dataKey="cost" stackId="a" fill={colorMap.blue} />
        </BarChart>
      </ResponsiveContainer>
    )
}