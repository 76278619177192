// react
import React, { FC, useEffect, useState } from 'react';

// holster
import { Button, Modal, Typography } from '@kubecost-frontend/holster';

// local
import { AllocationReport } from '../../types/allocation';
import { AdvancedReport } from '../../types/advancedReport';

interface UnsaveReportDialogProps {
  onClose: () => void;
  open: boolean;
  title: string;
  unsave: (report: AllocationReport | AdvancedReport) => void;
  report: AllocationReport | AdvancedReport;
}

export const UnsaveReportDialog: FC<UnsaveReportDialogProps> = ({
  onClose,
  open,
  title,
  unsave,
  report,
}) => {
  const [localTitle, setLocalTitle] = useState('');

  const onUnsave = () => {
    unsave(report);
    onClose();
  };

  useEffect(() => {
    if (report && report.title) {
      setLocalTitle(report.title);
    } else if (title) {
      setLocalTitle(title);
    }
  }, [report, title]);

  return (
    <Modal open={open} onClose={onClose} title="Unsave report">
      <div style={{ padding: '1em' }}>
        <Typography variant="p">{`Are you sure you want to unsave ${localTitle}? This action affects all users.`}</Typography>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '10px',
        }}
      >
        <Button variant="primary" onClick={onUnsave}>
          Unsave
        </Button>

        <Button variant="default" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
