import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PropTypes from 'prop-types';
import React from 'react';

function DeadClusterListItem({ address, onDelete }) {
  return (
    <div style={{ marginBottom: '4px', minWidth: '200px' }}>
      <Typography>
        <CloudOffIcon
          style={{
            color: 'lightcoral',
            verticalAlign: 'sub',
            marginRight: '8px',
          }}
        />
        <Link href={address} target="_blank">
          {address} <OpenInNewIcon style={{ fontSize: '14px' }} />
        </Link>
        <DeleteIcon
          style={{
            float: 'right',
            color: 'grey',
            paddingLeft: '30px',
            cursor: 'pointer',
            verticalAlign: 'sub',
          }}
          title="Local cluster can be deleted"
          onClick={onDelete}
        />
      </Typography>
    </div>
  );
}

DeadClusterListItem.propTypes = {
  address: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default React.memo(DeadClusterListItem);
