import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import { useClusters } from '../../../contexts/ClusterConfig';
import { version } from '../../../services/env';
import LicenseKeyDisplay from './LicenseKeyDisplay';
import ConfigService from '../../../services/config';
import { LicenseKeyModal } from './LicenseKeyModal';

const LicenseKeyManager = () => {
  const { licenseConfig, modelConfig, apiVersion } = useClusters();
  const [alert, setAlert] =
    useState<{ type: string; message: string } | undefined>();
  const [licenseKeyModalVisible, setLicenseKeyModalVisible] = useState(false);

  const handleAdjustLicenseKeyClicked = () => {
    setLicenseKeyModalVisible(true);
  };

  const handleStartFreeTrialClicked = async () => {
    try {
      const resp = await ConfigService.startTrial();
    } catch (e) {
      setAlert({
        type: 'error',
        message: e as string,
      });
    }
    setAlert({
      type: 'success',
      message:
        'Successfully started your free trial! Your page will reload shortly with the changes...',
    });
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const handleLicenseKeyUpgrade = async (key: string) => {
    let newKey = Object.assign({}, licenseConfig);
    newKey.productKey.key = key;
    try {
      const resp = await ConfigService.setProductKey(newKey);
    } catch {
      setLicenseKeyModalVisible(false);
      setAlert({
        type: 'error',
        message:
          'Uh Oh. Looks like we had a problem setting your license key. Check the logs for more details.',
      });
    }
    setAlert({
      type: 'success',
      message:
        'Successfully set your license key! Your page will reload shortly with the changes...',
    });
    setLicenseKeyModalVisible(false);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  return (
    <div>
      {alert && (
        <Alert style={{ marginBottom: '1em' }} severity={alert.type as any}>
          {alert.message}
        </Alert>
      )}
      <LicenseKeyDisplay
        adjustLicenseKeyClicked={handleAdjustLicenseKeyClicked}
        startFreeTrialClicked={handleStartFreeTrialClicked}
        apiVersion={apiVersion}
        clientVersion={version ?? ''}
        config={licenseConfig}
        readOnly={modelConfig.readOnly === 'true'}
      />
      <LicenseKeyModal
        closeModal={() => setLicenseKeyModalVisible(false)}
        handleUpgrade={handleLicenseKeyUpgrade}
        visible={licenseKeyModalVisible}
      />
    </div>
  );
};

export default LicenseKeyManager;
