// react
import React from 'react';

// mui
import { TableSortLabel } from '@material-ui/core';

// lodash
import get from 'lodash/get';
import round from 'lodash/round';

// holster
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from '@kubecost-frontend/holster';

// local
import { toCurrency } from '../../services/format';
import { TableRowData } from './types';
import { ARDataRow } from './ARDataRow';

const headCells = [
  { id: 'name', numeric: false, label: 'Name', width: 'auto' },
  { id: 'cpuCost', numeric: true, label: 'CPU', width: 90 },
  { id: 'gpuCost', numeric: true, label: 'GPU', width: 90 },
  { id: 'ramCost', numeric: true, label: 'RAM', width: 90 },
  { id: 'pvCost', numeric: true, label: 'PV', width: 90 },
  { id: 'networkCost', numeric: true, label: 'Network', width: 90 },
  { id: 'loadBalancerCost', numeric: true, label: 'LB', width: 90 },
  { id: 'sharedCost', numeric: true, label: 'Shared', width: 90 },
  { id: 'externalCost', numeric: true, label: 'External', width: 90 },
  { id: 'efficiency', numeric: true, label: 'Efficiency', width: 90 },
  { id: 'totalCost', numeric: true, label: 'Total cost', width: 90 },
];

interface NewARDataTableProps {
  tableData: NewARTableData;
  currency: string;
  setActiveRow: (row: any) => void;
}

interface NewARTableData {
  items: TableRowData[];
  totals: {
    cpuCost: number;
    efficiency: number;
    externalCost: number;
    gpuCost: number;
    loadBalancerCost: number;
    networkCost: number;
    pvCost: number;
    ramCost: number;
    sharedCost: number;
    totalCost: number;
  };
}

function descendingComparator(
  a: TableRowData,
  b: TableRowData,
  orderBy: string,
) {
  if (get(b, orderBy, 0) < get(a, orderBy, 0)) {
    return -1;
  }
  if (get(b, orderBy, 0) > get(a, orderBy, 0)) {
    return 1;
  }
  return 0;
}

function getComparator(order: string, orderBy: string) {
  return order === 'desc'
    ? (a: TableRowData, b: TableRowData) => descendingComparator(a, b, orderBy)
    : (a: TableRowData, b: TableRowData) =>
        -descendingComparator(a, b, orderBy);
}

function stableSort(
  array: Array<TableRowData>,
  comparator: (a: TableRowData, b: TableRowData) => number,
) {
  const stabilizedThis: [TableRowData, number][] = array.map((el, index) => [
    el,
    index,
  ]);
  stabilizedThis.sort(
    (a: [TableRowData, number], b: [TableRowData, number]) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    },
  );
  return stabilizedThis.map((el) => el[0]);
}

export const NewARDataTable = ({
  tableData,
  currency,
  setActiveRow,
}: NewARDataTableProps) => {
  // table specific
  const [order, setOrder] = React.useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = React.useState('totalCost');
  const orderedRows = stableSort(
    tableData.items || [],
    getComparator(order, orderBy),
  );

  return (
    <Table className="w-full">
      <TableHead>
        <TableRow>
          {headCells.map((cell) => (
            <TableHeadCell
              key={cell.id}
              align={cell.numeric ? 'right' : 'left'}
              style={{
                width: cell.width,
                paddingRight: cell.id === 'totalCost' ? '2em' : '',
              }}
            >
              <TableSortLabel
                active={orderBy === cell.id}
                direction={orderBy === cell.id ? order : 'asc'}
                onClick={() => {
                  const isDesc = orderBy === cell.id && order === 'desc';
                  setOrder(isDesc ? 'asc' : 'desc');
                  setOrderBy(cell.id);
                }}
              >
                {cell.label}
              </TableSortLabel>
            </TableHeadCell>
          ))}
        </TableRow>
      </TableHead>
      {!!tableData && (
        <TableBody>
          <TableRow>
            <TableCell align="left" style={{ fontWeight: 500 }}>
              Totals
            </TableCell>
            <TableCell align="right" style={{ fontWeight: 500 }}>
              {toCurrency(tableData.totals.cpuCost || 0, currency)}
            </TableCell>

            <TableCell align="right" style={{ fontWeight: 500 }}>
              {toCurrency(tableData.totals.gpuCost || 0, currency)}
            </TableCell>
            <TableCell align="right" style={{ fontWeight: 500 }}>
              {toCurrency(tableData.totals.ramCost || 0, currency)}
            </TableCell>
            <TableCell align="right" style={{ fontWeight: 500 }}>
              {toCurrency(tableData.totals.pvCost || 0, currency)}
            </TableCell>
            <TableCell align="right" style={{ fontWeight: 500 }}>
              {toCurrency(tableData.totals.networkCost || 0, currency)}
            </TableCell>
            <TableCell align="right" style={{ fontWeight: 500 }}>
              {toCurrency(tableData.totals.loadBalancerCost || 0, currency)}
            </TableCell>
            <TableCell align="right" style={{ fontWeight: 500 }}>
              {toCurrency(tableData.totals.sharedCost || 0, currency)}
            </TableCell>

            <TableCell align="right" style={{ fontWeight: 500 }}>
              {toCurrency(tableData.totals.externalCost || 0, currency)}
            </TableCell>
            <TableCell align="right" style={{ fontWeight: 500 }}>
              {round((tableData.totals.efficiency || 0) * 100, 1)}%
            </TableCell>
            <TableCell
              align="right"
              style={{ fontWeight: 500, paddingRight: '2em' }}
            >
              {toCurrency(tableData.totals.totalCost || 0, currency)}
            </TableCell>
          </TableRow>
          {orderedRows.map((item: any) => (
            <ARDataRow
              setActiveRow={setActiveRow}
              item={item}
              currency={currency}
            />
          ))}
        </TableBody>
      )}
    </Table>
  );
};
