import { makeStyles, Typography, Tooltip } from '@material-ui/core';
import {
  CheckCircleOutlined,
  WarningOutlined,
  Cloud,
  Settings,
} from '@material-ui/icons';
import React from 'react';

import { ETLCloudStatus } from '../../../services/diagnostics';

const useStyles = makeStyles({
  cloudRow: {
    display: 'grid',
    gridTemplateColumns: '33px 33px 1fr 220px 33px',
    width: '100%',
    boxSizing: 'border-box',
    gap: '1em',
    padding: '1em',
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.05)',
      cursor: 'pointer',
    },
  },
  cloudRowIcon: {
    height: '33px',
    width: '33px',
    color: 'gray',
  },
  cloudRowHeader: {
    flexGrow: 1,
    display: 'inline',
  },
  cloudRowRefreshed: {
    paddingTop: '7px',
    marginTop: '-0.8em',
  },
  cloudRowSuccessIcon: {
    color: '#81C784',
    height: '33px',
  },
  cloudRowWarningIcon: {
    color: '#FFC107',
    height: '33px',
  },
  settingsIcon: {
    color: 'gray',
    height: '33px',
  },
  rowTitleContainer: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'inline',
    textOverflow: 'ellipsis',
    flexGrow: 1,
  },
});

type Props = {
  cloudStatus: ETLCloudStatus;
  providerKey: string;
  onClick: () => void;
};

const CloudProviderIcon = ({
  providerName,
  className,
}: {
  providerName: string;
  className: string;
}) => {
  const CloudProviderIconList: { [key: string]: string } = {
    AWS: '/images/aws_logo_square.png',
    GCP: '/images/gcp_logo_square.png',
    Azure: '/images/azure_logo_square.png',
  };

  if (providerName in CloudProviderIconList) {
    return (
      <img className={className} src={CloudProviderIconList[providerName]} />
    );
  }

  return <Cloud className={className} />;
};

const formatDate = (date: string): string => {
  const parsed = new Date(date);
  return parsed.toLocaleString('en-US');
};

const CloudIntegrationRow = ({ cloudStatus, providerKey, onClick }: Props) => {
  const classes = useStyles();
  return (
    <Tooltip title={providerKey}>
      <div data-testid="ci-row" className={classes.cloudRow} onClick={onClick}>
        <CloudProviderIcon
          providerName={cloudStatus.providerType}
          className={classes.cloudRowIcon}
        />
        {cloudStatus.cloudConnectionStatus == 'Connection Successful' && (
          <CheckCircleOutlined className={classes.cloudRowSuccessIcon} />
        )}
        {cloudStatus.cloudConnectionStatus != 'Connection Successful' && (
          <WarningOutlined className={classes.cloudRowWarningIcon} />
        )}
        <div className={classes.rowTitleContainer}>
          <Typography variant="h6" className={classes.cloudRowHeader}>
            {providerKey}
          </Typography>
        </div>
        <Typography variant="body2" className={classes.cloudRowRefreshed}>
          {(!cloudStatus.cloudUsage || !cloudStatus.cloudUsage.lastRun) &&
            'No refresh data.'}

          {cloudStatus.cloudUsage && cloudStatus.cloudUsage.lastRun && (
            <span>
              Last synchronized <br />{' '}
              {formatDate(cloudStatus.cloudUsage.lastRun)}
            </span>
          )}
        </Typography>
        <Settings className={classes.settingsIcon} />
      </div>
    </Tooltip>
  );
};

export default CloudIntegrationRow;
