import {
  ClusterEnvResponse,
  ClusterInfoResponse,
} from '../../../services/model';
import {
  joinPromQsMultipleKeys,
  getHourlyStoragePrice,
  joinPromQRs,
} from '../../../services/util';

const MAX_FS_BYTES = 100 * 1099511627776;

async function getMultiClusterUnutilizedLocalDisks(
  threshold: number,
  offset: string,
  envConfig: ClusterEnvResponse,
  thanosQueryPath: string,
): Promise<any[]> {
  let clusterIDLabel = envConfig.promClusterIDLabel;
  var q1 = `sum(container_fs_usage_bytes{id="/"} ${offset}) by (instance, ${clusterIDLabel})`;
  var q2 = `sum(container_fs_limit_bytes{device!="tmpfs",id="/"} ${offset}) by (instance, ${clusterIDLabel})`;
  var base = thanosQueryPath;

  var diskArray: any[] = [];

  let qr1Url = base + encodeURIComponent(q1);
  let qr2Url = base + encodeURIComponent(q2);

  return Promise.all([
    fetch(qr1Url).then((resp) => resp.json()),
    fetch(qr2Url).then((resp) => resp.json()),
  ]).then((queries) => {
    let qr1 = queries[0];
    let qr2 = queries[1];

    if (
      qr1 == null ||
      qr2 == null ||
      typeof qr1.data === 'undefined' ||
      typeof qr2.data === 'undefined'
    ) {
      console.warn(
        'Warning: unable to find "container_fs_usage_bytes" and/or "container_fs_limit_bytes" metrics',
      );
      return [];
    }

    joinPromQsMultipleKeys(qr1, qr2, 'instance', clusterIDLabel);

    qr1.data.result.forEach((val) => {
      if (val.value[2] > MAX_FS_BYTES) {
        console.warn(
          'Warning: very large container_fs_limit_bytes result detected, ignoring...',
        );
        return;
      }

      var utilization = val.value[1] / val.value[2];

      if (utilization < threshold) {
        // assume you can save 50% of current cost estimate
        val.savings =
          getHourlyStoragePrice('standard', 'us-central', 'gcp', true) *
          Math.ceil(val.value[2] / 1024 / 1024 / 1024 / 5) *
          5 *
          0.5;

        diskArray.push(val);
      }
    });

    return diskArray;
  });
}

async function getLocalClusterUnutilizedLocalDisks(
  threshold: number,
  promQueryURL: string,
): Promise<any[]> {
  var q1 = 'sum(container_fs_usage_bytes{id="/"}) by (instance)';
  var q2 =
    'sum(container_fs_limit_bytes{device!="tmpfs",id="/"}) by (instance)';
  var base = promQueryURL;
  var diskArray: any[] = [];

  let qr1Url = base + encodeURIComponent(q1);
  let qr2Url = base + encodeURIComponent(q2);

  return Promise.all([
    fetch(qr1Url).then((resp) => resp.json()),
    fetch(qr2Url).then((resp) => resp.json()),
  ]).then((queries) => {
    let qr1 = queries[0];
    let qr2 = queries[1];

    if (
      qr1 == null ||
      qr2 == null ||
      typeof qr1.data === 'undefined' ||
      typeof qr2.data === 'undefined'
    ) {
      console.warn(
        'Warning: unable to find "container_fs_usage_bytes" and/or "container_fs_limit_bytes" metrics',
      );
      return diskArray;
    }

    joinPromQRs(qr1, qr2, 'instance', 'instance');

    qr1.data.result.forEach((val) => {
      if (val.value[2] > MAX_FS_BYTES) {
        console.warn(
          'Warning: very large container_fs_limit_bytes result detected, ignoring...',
        );
        return;
      }

      var utilization = val.value[1] / val.value[2];

      if (utilization < threshold) {
        // assume you can save 50% of current cost estimate
        val.savings =
          getHourlyStoragePrice('standard', 'us-central', 'gcp', true) *
          Math.ceil(val.value[2] / 1024 / 1024 / 1024 / 5) *
          5 *
          0.5;

        diskArray.push(val);
      }
    });

    return diskArray;
  });
}

export async function getUnutilizedLocalDisks(
  threshold: number,
  clusterInfo: ClusterInfoResponse,
  envConfig: ClusterEnvResponse,
  thanosQueryPath: string,
  prometheusQueryPath: string,
) {
  var thanosEnabled = false;
  var thanosOffset = 'offset 3h';
  if (clusterInfo.thanosEnabled) {
    thanosEnabled = clusterInfo.thanosEnabled === 'true';
  } else {
    thanosEnabled = clusterInfo.thanosOffset !== undefined;
  }

  if (thanosEnabled) {
    if (clusterInfo.thanosOffset !== '') {
      thanosOffset = 'offset ' + clusterInfo.thanosOffset;
    }
  }

  if (thanosEnabled) {
    return getMultiClusterUnutilizedLocalDisks(
      threshold,
      thanosOffset,
      envConfig,
      thanosQueryPath,
    );
  }

  return getLocalClusterUnutilizedLocalDisks(threshold, prometheusQueryPath);
}
