import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TextField, Typography } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useQuery } from 'react-query';
import { useClusters } from '../../../contexts/ClusterConfig';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface UpgradeModalProps {
  visible: boolean;
  closeModal: () => void;
  handleUpgrade: (key: string) => void;
}

export const LicenseKeyModal = ({
  visible,
  closeModal,
  handleUpgrade,
}: UpgradeModalProps) => {
  const [keyText, setKeyText] = useState('');

  const bulletPoints = [
    'Full visibility into costs and performance across all Kubernetes clusters',
    'Real-time notifications to avoid cost overruns & product outages',
    'Unified visibility across all cloud environments',
  ];

  return (
    <div>
      <Dialog
        open={visible}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeModal}
      >
        <DialogTitle>
          <Typography variant="h4">Upgrade Access</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography style={{ color: 'gray' }} variant="body1">
            You can upgrade your free trial to a paid subscription to gain
            access to more features and integrations like the following:
          </Typography>
          {bulletPoints.map((item: string) => (
            <div style={{ display: 'flex', gap: '.5em', marginTop: '1em' }}>
              <CheckCircleOutlineIcon style={{ color: 'green' }} />
              <Typography variant="body1">{item}</Typography>
            </div>
          ))}
          <TextField
            style={{ marginTop: '1.5em' }}
            fullWidth
            label="New Key"
            variant="outlined"
            value={keyText}
            onChange={(e: any) => setKeyText(e.target.value)}
          ></TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Close
          </Button>
          <Button
            disabled={!keyText}
            onClick={() => handleUpgrade(keyText)}
            color="primary"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
