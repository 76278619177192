// MUI components
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

// MUI icons
import HelpIcon from '@material-ui/icons/Help';

// react
import React, { FC, useEffect, useState } from 'react';

// holster
import {
  Alert as HolsterAlert,
  Button,
  Input,
  Toggle,
  Tooltip,
  Typography,
} from '@kubecost-frontend/holster';

// local
import If from '../../components/If';
import { Header } from '../../components/Header2New';
import AlertsList from '../../components/AlertsListNew';

import { FetchStates } from '../../constants';

import AlertService, {
  AlertTypes,
  Alert,
  BudgetAlert,
  DiagnosticAlert,
  RBACUnauthorizedError,
} from '../../services/alerts';
import Logger from '../../services/logger';

import CreateAlertModal from './CreateAlertModal';
import CreateDiagnosticAlertModal from './CreateDiagnosticAlertModal';
import DeleteAlertModal from './DeleteAlertModal';
import EmailRecipients from './EmailRecipients';
import analytics from '../../services/analytics';
import HealthAlert from './ClusterHealthAlerts/HealthAlert';
import TestAlertButton from './TestAlertButton';

export const AlertsNew: FC = () => {
  // alerts
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const [tableAlerts, setTableAlerts] = useState<Alert[]>([]);
  const [displayedAlerts, setDisplayedAlerts] = useState<Alert[]>([]);
  const [alertListFilter, setAlertListFilter] = useState('');
  const [diagnosticAlert, setDiagnosticAlert] = useState<Alert | null>(null);
  const [healthAlert, setHealthAlert] = useState<Alert | null>(null);

  // alert being created / edited
  const [newAlert, setNewAlert] = useState<Alert | null>(null);
  const [newDiagnosticAlert, setNewDiagnosticAlert] =
    useState<DiagnosticAlert | null>(null);

  // alert selected for removal
  const [deletingAlert, setDeletingAlert] = useState<Alert | null>(null);

  // global settings
  const [globalEmails, setGlobalEmails] = useState<string[]>([]);
  const [emailSubject, setEmailSubject] = useState('');
  const [globalSlack, setGlobalSlack] = useState('');
  const [globalSlackDirty, setGlobalSlackDirty] = useState(false);
  const [globalLinkback, setGlobalLinkback] = useState('');

  // initial request to fetch all alerts
  useEffect(() => {
    fetchData();
  }, []);

  // differentiate diagnostic and health alerts from other types.
  // other types belong in the table. Diagnostic and Health get their own controls.
  useEffect(() => {
    const da = alerts.find((a) => a.type === 'diagnostic');
    const ha = alerts.find((a) => a.type === 'health');
    const ta = alerts.filter((a) => !['diagnostic', 'health'].includes(a.type));
    setDiagnosticAlert(da || null);
    setHealthAlert(ha || null);
    setTableAlerts(ta);
  }, [alerts]);

  // determine alerts to display in the table
  // this is tableAlerts, less any alerts failing the search filter
  useEffect(() => {
    const das = tableAlerts
      .filter(
        (a) =>
          a.type.toLowerCase().includes(alertListFilter.toLowerCase()) ||
          a.window.toLowerCase().includes(alertListFilter.toLowerCase()) ||
          a.aggregation.toLowerCase().includes(alertListFilter.toLowerCase()) ||
          a.filter.toLowerCase().includes(alertListFilter.toLowerCase()),
      )
      .sort((a, b) => {
        if (a.type > b.type) {
          return 1;
        }
        if (a.type < b.type) {
          return -1;
        }
        if (a.aggregation > b.aggregation) {
          return 1;
        }
        if (b.aggregation > a.aggregation) {
          return -1;
        }
        if (a.filter > b.filter) {
          return 1;
        }
        return -1;
      });
    setDisplayedAlerts(das);
  }, [tableAlerts, alertListFilter]);

  // data initialization
  const [fetchState, setFetchState] = useState(FetchStates.INIT);

  // user messages
  const [badMessage, setBadMessage] = useState('');
  const [goodMessage, setGoodMessage] = useState('');

  return (
    <div>
      {/* heading */}
      <Header
        title="Alerts"
        refreshCallback={() => location.reload()}
        helpHref="https://docs.kubecost.com/alerts.html"
        helpTooltip="Alerts Documentation"
      />

      <HolsterAlert
        variant="info"
        title="It's important to know"
        content="Kubecost alerts allow teams to receive updates on real-time Kubernetes spend. They are configurable via the Kubecost UI or via Helm values."
        link={{
          href: 'https://guide.kubecost.com/hc/en-us/articles/4407601796759-Alerts-Documentation',
          target: '_blank',
          text: 'Learn More',
        }}
      />

      {/* app-wide settings */}
      <If condition={fetchState === FetchStates.LOADING}>
        <CircularProgress style={{ margin: 'auto' }} />
      </If>

      <If condition={fetchState === FetchStates.ERROR}>
        <Typography style={{ textAlign: 'center' }} variant="h5">
          Something went wrong. Check console for details.
        </Typography>
      </If>

      <If condition={fetchState === FetchStates.DONE}>
        <div
          style={{
            border: '1px solid #E6EBE9',
            marginTop: 36,
            marginBottom: 36,
            padding: 24,
          }}
        >
          {/* health and diagnostics alerts */}
          <Typography variant="h5">
            Cluster and Kubecost Health Alerts
          </Typography>

          <Typography variant="p">
            Activating these alerts monitors the health of Kubecost, as well as
            that of the cluster running Kubecost.
          </Typography>
          <HealthAlert />
          <div style={{ marginTop: 8 }}>
            <div style={{ width: 260 }}>
              <Toggle
                checked={!!diagnosticAlert}
                label="Monitor Kubecost Health"
                onChange={async () => {
                  if (!diagnosticAlert) {
                    setNewDiagnosticAlert(
                      new DiagnosticAlert({
                        type: AlertTypes.Diagnostic,
                        window: '5m',
                      }),
                    );
                  } else {
                    removeAlert(diagnosticAlert);
                    setDiagnosticAlert(null);
                  }
                }}
              />
              {diagnosticAlert ? (
                <Button
                  variant="primary"
                  style={{ marginRight: 8 }}
                  onClick={() => setNewDiagnosticAlert(diagnosticAlert)}
                >
                  Edit
                </Button>
              ) : (
                <></>
              )}
              {diagnosticAlert ? (
                <TestAlertButton alert={diagnosticAlert} />
              ) : (
                <></>
              )}
            </div>
          </div>
          <Typography style={{ marginTop: 28 }} variant="h5">
            Global Recipients
            <Tooltip
              content="Alerts which do not define Email and/or Slack recipients will fall back to the global setting."
              style={{ width: 400 }}
            >
              <HelpIcon
                style={{
                  cursor: 'pointer',
                  color: 'rgb(158,158,158)',
                  fontSize: 18,
                }}
              />
            </Tooltip>
          </Typography>
          <FormGroup style={{ marginTop: 8 }}>
            <FormControl>
              <Input
                helperText="The global Slack webhook"
                label="Slack webhook"
                placeholder="https://hooks.slack.com/services/XXXXXXXXXXX/XXXXXXXXXXX/XXXXXXXXXXXXXXXXXXXXXXXX"
                value={globalSlack}
                onChange={(e) => {
                  setGlobalSlackDirty(true);
                  setGlobalSlack(e.target.value);
                }}
              />
            </FormControl>
          </FormGroup>
          <FormGroup style={{ marginTop: 8 }}>
            <FormControl>
              <Input
                helperText="Custom email subject line"
                label="Subject"
                placeholder="Kubecost Alert"
                value={emailSubject}
                onChange={(e) => {
                  setEmailSubject(e.target.value);
                }}
              />
            </FormControl>
          </FormGroup>
          <div className="mt-4">
            <EmailRecipients
              addItem={(email) => {
                setGlobalEmails((emails) => [...emails, email]);
              }}
              removeItem={(i) => {
                setGlobalEmails((emails) => [
                  ...emails.slice(0, i),
                  ...emails.slice(i + 1),
                ]);
              }}
              recipients={globalEmails}
            />
          </div>
          <FormGroup style={{ marginTop: 16, marginBottom: 8 }}>
            <FormControl>
              <Input
                helperText="Alert messages will refer recipients back to this URL"
                label="Linkback URL"
                placeholder="http://kc.my-domain.tld:port"
                value={globalLinkback}
                onChange={(e) => {
                  setGlobalLinkback(e.target.value);
                }}
              />
            </FormControl>
          </FormGroup>
          <div style={{ marginTop: 16 }}>
            <Button
              variant="primary"
              data-test="globalContactSave"
              onClick={() => {
                const p1 = AlertService.setGlobalEmailRecipients(
                  globalEmails,
                  emailSubject,
                );
                const p2 = AlertService.setGlobalLinkback(globalLinkback);
                let p3 = Promise.resolve(new Response());
                if (globalSlackDirty) {
                  p3 = AlertService.setGlobalSlackWebhook(
                    globalSlack,
                    globalLinkback,
                  );
                }
                setGlobalSlackDirty(false);
                Promise.all([p1, p2, p3])
                  .then(([r1, r2, r3]) => {
                    if (r1.ok && r2.ok && r3.ok) {
                      setGoodMessage('Alert default settings updated');
                    } else {
                      setBadMessage('Failed to update default alert settings');
                    }
                  })
                  .catch((err) => {
                    setBadMessage('Failed to update default alert settings');
                  });
              }}
            >
              Save
            </Button>
          </div>
        </div>

        {/* alerts table */}
        <div style={{ display: 'flex' }}>
          <Typography variant="h5" style={{ flexGrow: 1 }}>
            Alerts
          </Typography>
          <Input
            onChange={(e) => setAlertListFilter(e.target.value)}
            placeholder="Filter alerts"
            style={{ marginRight: 16 }}
            value={alertListFilter}
          />
          <Button
            variant="primary"
            data-test="create-alert-button"
            onClick={() =>
              setNewAlert(
                new BudgetAlert({
                  type: AlertTypes.Budget,
                  window: '7d',
                  aggregation: 'namespace',
                  filter: '',
                  threshold: 0,
                }),
              )
            }
          >
            + Create Alert
          </Button>
        </div>
        <If condition={!tableAlerts.length}>
          <Typography style={{ textAlign: 'center' }} variant="h5">
            No alerts found! Why not create one?
          </Typography>
        </If>
        <If condition={!!tableAlerts.length && !displayedAlerts.length}>
          <Typography style={{ textAlign: 'center' }} variant="h5">
            Applied filter has hidden all alerts.
          </Typography>
        </If>
        <If condition={!!tableAlerts.length && !!displayedAlerts.length}>
          <AlertsList
            alerts={displayedAlerts}
            deleteAlert={(alert) => setDeletingAlert(alert)}
            editAlert={(alert) => setNewAlert(alert)}
            testAlert={(alert) => testAlert(alert)}
          />
        </If>
      </If>
      <Snackbar
        open={!!goodMessage}
        autoHideDuration={4000}
        onClose={() => setGoodMessage('')}
      >
        <MuiAlert onClose={() => setGoodMessage('')} severity="success">
          {goodMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={!!badMessage}
        autoHideDuration={4000}
        onClose={() => setBadMessage('')}
      >
        <MuiAlert onClose={() => setBadMessage('')} severity="error">
          {badMessage}
        </MuiAlert>
      </Snackbar>
      <CreateAlertModal
        alert={newAlert}
        close={() => setNewAlert(null)}
        save={upsertAlert}
        test={testAlert}
      />
      <CreateDiagnosticAlertModal
        alert={newDiagnosticAlert}
        close={() => setNewDiagnosticAlert(null)}
        save={async (alert: DiagnosticAlert) => upsertAlert(alert)}
        test={testAlert}
      />
      <DeleteAlertModal
        alert={deletingAlert}
        close={() => setDeletingAlert(null)}
        remove={(alert) => removeAlert(alert)}
      />
    </div>
  );

  async function fetchData() {
    setFetchState(FetchStates.LOADING);
    try {
      const [alertResponse, emailResponse, slackResponse, linkbackResponse] =
        await Promise.all([
          AlertService.getAlerts(),
          AlertService.getGlobalEmailConfig(),
          AlertService.getGlobalSlackWebhook(),
          AlertService.getGlobalLinkback(),
        ]);

      setAlerts(alertResponse);
      analytics.setProfileValue('alerts_count', alertResponse.length);
      setGlobalEmails(emailResponse.recipients || []);
      setEmailSubject(emailResponse.subject || '');
      setGlobalSlack(
        slackResponse
          ? 'https://hooks.slack.com/services/XXXXXXXXXXX/XXXXXXXXXXX/XXXXXXXXXXXXXXXXXXXXXXXX'
          : '',
      );
      setGlobalLinkback(linkbackResponse);
      setFetchState(FetchStates.DONE);
    } catch (err) {
      setFetchState(FetchStates.ERROR);
      Logger.error(err);
    }
  }

  async function upsertAlert(a: Alert) {
    try {
      const response = await AlertService.upsertAlert(a);
      if (!a.id) {
        setAlerts([...alerts, response]);
      } else {
        setAlerts([...alerts.filter((alert) => alert.id !== a.id), response]);
      }
    } catch (err) {
      setBadMessage('Failed to create alert. See console.');
      Logger.error(err);
    }
  }

  async function removeAlert(a: Alert) {
    try {
      if (await AlertService.deleteAlert(a))
        setAlerts(alerts.filter((aa) => aa.id !== a.id));
    } catch (err) {
      if (err instanceof RBACUnauthorizedError) {
        setBadMessage(
          'You are not authorized to do that. Please contact your administrator.',
        );
      } else {
        setBadMessage('Failed to remove alert. See console.');
      }
    }
  }

  async function testAlert(a: Alert) {
    try {
      const response = await AlertService.testAlert(a);
      if (!response.ok) {
        const text = await response.text();
        throw new Error(text);
      }
      setGoodMessage('Test alert sent');
    } catch (err) {
      const parts = `${err}`.split(':');
      setBadMessage(parts[parts.length - 1]);
    }
  }
};

AlertsNew.displayName = 'AlertsNew';
