import { QueryObserverResult, useQueries, useQuery } from 'react-query';
import model from '../services/model';
import { getCurrentContainerAddressModel } from '../services/util';
import { AllocationSet } from '../types/allocation';
import useProductTier, { ProductTier } from './useProductTier';

enum EnablementKeys {
  unifiedMultiCluster = 'unified-multi-cluster',
  cloudCosts = 'cloudCost',
}

enum EnablementNames {
  'unified-multi-cluster' = 'Unified multi-cluster',
  'cloudCost' = 'Cloud Costs',
}

interface APIEnablementDatum {
  key: EnablementKeys;
  requiredTier: ProductTier;
  isEnabled: boolean;
  isViolation: boolean;
}

type APIEnablementsResponse = Record<EnablementKeys, APIEnablementDatum>;

const useEnablements = (): QueryObserverResult<APIEnablementsResponse> =>
  useQuery({
    queryKey: ['enablements', Object.keys(EnablementKeys)],
    queryFn: model.getEnablements,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
  });

export {
  APIEnablementDatum,
  APIEnablementsResponse,
  useEnablements as default,
  EnablementKeys,
  EnablementNames,
};
