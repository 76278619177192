import { Box, Paper, Tooltip } from '@material-ui/core';
import React from 'react';
import { colorMap, primary } from '../../services/colors';
import { toCurrency } from '../../services/format';
import HelpIcon from '@material-ui/icons/Help';

interface CostBreakdownTileProps {
  currency: string;
  totalCost: number;
  activeTimeWindow: string;
  sortedTypesByCost: any;
  tooltipMessage: string;
}

export const CostBreakdownTile = ({
  currency,
  totalCost,
  activeTimeWindow,
  sortedTypesByCost,
  tooltipMessage,
}: CostBreakdownTileProps) => {
  return (
    <Box
      component={Paper}
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        textAlign: 'center',
        padding: '1em',
      }}
    >
      <div>
        <div style={{ fontSize: 22, marginBottom: '.5em' }}>
          <strong>Total Cost </strong>
          <span style={{ color: 'gray' }}>({activeTimeWindow})</span>
        </div>
        <div style={{ color: colorMap.indigo, fontSize: 34 }}>
          <strong>{toCurrency(totalCost || 0, currency)}</strong>
        </div>
      </div>
      {sortedTypesByCost.map(({ value, label }, idx: any) =>
        label === 'Shared' ? (
          <div key="shared">
            <div style={{ fontSize: 18, marginBottom: '1em' }}>
              Shared
              <Tooltip title={tooltipMessage} placement="right">
                <HelpIcon
                  style={{ fontSize: 14, color: 'gray', marginLeft: 5 }}
                />
              </Tooltip>
            </div>
            <div
              style={{
                color: primary[idx],
                fontSize: 28,
              }}
            >
              <strong>{toCurrency(value || 0, currency)}</strong>
            </div>
          </div>
        ) : (
          <div key={label}>
            <div style={{ fontSize: 18, marginBottom: '1em' }}>{label}</div>
            <div style={{ color: primary[idx], fontSize: 28 }}>
              <strong>{toCurrency(value || 0, currency)}</strong>
            </div>
          </div>
        ),
      )}
    </Box>
  );
};
