import React from 'react';
import isArray from 'lodash/isArray';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import Typography from '@material-ui/core/Typography';
import RangeChart from './RangeChart';
import SummaryChart from './SummaryChart';

// sum assetSet to single asset
function sum(assetSet, name) {
  if (assetSet.length === 0) {
    return null;
  }

  const agg = {
    name,
    totalCost: 0.0,
    count: 0,
  };
  forEach(assetSet, (asset) => {
    agg.start = asset.start;
    agg.end = asset.end;
    agg.window = asset.window;
    agg.totalCost += asset.totalCost;
    agg.count += 1;
  });

  return agg;
}

function top(n, by) {
  return (assets) => {
    if (isArray(assets[0])) {
      return map(assets, top(n, by));
    }

    const sorted = reverse(sortBy(assets, by));
    const topn = sorted.slice(0, n);
    const other = [];
    if (sorted.length > n) {
      other.push(sum(sorted.slice(n), 'other'));
    }

    return {
      top: topn,
      other,
    };
  };
}

const AssetChart = ({ assetRange, currency, n, height }) => {
  if (assetRange.length === 0) {
    return <Typography variant="body2">No data</Typography>;
  }

  if (assetRange.length === 1) {
    const datum = top(n, (asset) => asset.totalCost)(assetRange[0]);
    return (
      <SummaryChart
        top={datum.top}
        other={datum.other}
        currency={currency}
        height={height}
      />
    );
  }

  const data = top(n, (asset) => asset.totalCost)(assetRange);
  return <RangeChart data={data} currency={currency} height={height} />;
};

export default React.memo(AssetChart);
