// MUI components
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import { Link as RouteLink } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

// MUI icons
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

// MUI styles
import { makeStyles } from '@material-ui/styles';

// react
import React, { useEffect, useState } from 'react';

// local
import WithDataLoader from '../../components/DataLoader';
import Model from '../../services/model';
import { toCurrency } from '../../services/format';
import { AllocationSet } from '../../types/allocation';

const useStyles = makeStyles({
  actionButton: {
    color: '#2196f3',
  },
  actionLink: {
    marginLeft: 'auto',
  },
  centered: {
    textAlign: 'center',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  content: {
    color: 'rgba(0, 0, 0, 0.7)',
  },
  root: {
    height: '100%',
  },
  savings: {
    color: '#0F9D58',
    fontWeight: 400,
  },
});

const BoxWithLoading = WithDataLoader(Box);

function SavingsCard(): React.ReactElement {
  const classes = useStyles();

  const [promise, setPromise] = useState<Promise<unknown> | null>(null);
  const [cost, setCost] = useState(0);
  const [currency, setCurrency] = useState('USD');

  useEffect(() => {
    setPromise(fetchData());
  }, []);
  return (
    <Card classes={{ root: classes.root }}>
      <BoxWithLoading
        className={classes.centered}
        errorMessage="Could not load savings data"
        loadMessage="Loading savings data"
        promise={promise}
      >
        <CardContent className={classes.content}>
          <Tooltip title="Estimated monthly cost based on last 7 days of resource consumption. Does not include external cloud costs.">
            <div>
              <Typography className={classes.savings} paragraph variant="h2">
                {toCurrency(cost, currency)}
              </Typography>
              <Typography variant="h5">Monthly Kubernetes costs</Typography>
            </div>
          </Tooltip>
        </CardContent>
        <CardActions>
          <Link
            component={RouteLink}
            className={classes.actionLink}
            to="/allocations?window=7d&agg=cluster&rate=monthly"
          >
            <Button
              className={classes.actionButton}
              endIcon={<KeyboardArrowRightIcon />}
            >
              Monthly Costs
            </Button>
          </Link>
        </CardActions>
      </BoxWithLoading>
    </Card>
  );

  async function fetchData() {
    const conf = await Model.getConfigs();
    const allocationResponse = await Model.getAllocationSummary(
      '7d',
      'cluster',
      {
        accumulate: true,
        shareIdle: false,
        external: 'false',
        shareTenancyCosts: conf.shareTenancyCosts === 'true',
        shareCost: parseFloat(conf.sharedOverhead || 0),
        shareSplit: 'weighted',
      },
    );
    const allocationSet: AllocationSet =
      allocationResponse.data.sets[0].allocations;

    // compute the summed totalCost and summed minutes for all assets in the AssetSet
    const perMinuteCosts = Object.values(allocationSet).map(
      (allocation) =>
        Model.getSummaryTotalCost(allocation) /
        Model.getSummaryMinutes(allocation),
    );
    const totalMonthlyCost =
      perMinuteCosts.reduce((a, b) => a + b) * 60 * 24 * 30.42;

    setCurrency(conf.currencyCode || 'USD');
    setCost(totalMonthlyCost);
  }
}

export default SavingsCard;
