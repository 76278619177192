// react
import React, { FC } from 'react';

// lodash
import isArray from 'lodash/isArray';
import upperFirst from 'lodash/upperFirst';

// mui
import { makeStyles } from '@material-ui/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

// holster
import { Typography } from '@kubecost-frontend/holster';

// local
import { toVerboseTimeRange } from '../../services/format';

const useStyles = makeStyles(() => ({
  root: {
    '& > * + *': {
      marginTop: 2,
    },
  },
}));

const handleBreadcrumbClick =
  (i: number, cb: (arg0: number) => void) => (e) => {
    e.preventDefault();
    cb(i);
  };

interface SubtitleProps {
  aggregateBy: any;
  window: any;
  context: any;
  clearContext: () => void;
  goToContext: (item: number) => void;
}

const Subtitle: FC<SubtitleProps> = ({
  aggregateBy,
  window,
  context,
  clearContext,
  goToContext,
}) => {
  const classes = useStyles();

  if (!isArray(context) || context.length === 0) {
    return (
      <div className={classes.root}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {aggregateBy && aggregateBy.length > 0 ? (
            <Typography variant="p">
              {toVerboseTimeRange(window)} by{' '}
              {aggregateBy.map(upperFirst).join('/')}
            </Typography>
          ) : (
            <Typography variant="p">{toVerboseTimeRange(window)}</Typography>
          )}
        </Breadcrumbs>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <a style={{ color: 'inherit' }} onClick={() => clearContext()}>
          <Typography variant="p">
            {toVerboseTimeRange(window)} by{' '}
            {context.map((c) => c.property).join('/')}
          </Typography>
        </a>
        {context.map((ctx, c) =>
          c === context.length - 1 ? (
            <Typography variant="p" key={c}>
              {ctx.name}
            </Typography>
          ) : (
            <a
              style={{ color: 'inherit' }}
              key={c}
              onClick={handleBreadcrumbClick(c, goToContext)}
            >
              <Typography variant="p">{ctx.name}</Typography>
            </a>
          ),
        )}
      </Breadcrumbs>
    </div>
  );
};

export const SubtitleMemoized = React.memo(Subtitle);
