import React, { useState } from 'react';
import { colorMap } from '../../services/colors';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import { ToggleButtonGroup } from '@material-ui/lab';
import { AddOnManager } from './AddOn';
import { BaseConfigurations } from './BaseConfigurations';
import { DataMapping } from './DataMapping';
import AggregateByControl from '../../components/AggregateByControl';
import { default as TooltipMUI } from '@material-ui/core/Tooltip';
import { DataSourceGroupingTable } from './DataSourceGroupingTable';
import {
  allocationFilterTypes,
  assetAggregationOptions,
  DropdownControl,
  ReportConfig,
} from './reportingHelpers';
import InfoIcon from '@material-ui/icons/Info';

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const EditControls = ({
  reportConfig,
  saveReport,
  labelMap,
}: {
  reportConfig: ReportConfig;
  saveReport: (report: ReportConfig) => void;
  labelMap: any;
}) => {
  const [activeView, setActiveView] = useState<number>(0);
  const [activeReportConfig, setActiveReportConfig] =
    useState<ReportConfig>(reportConfig);
  const [filterType, setFilterType] = useState(allocationFilterTypes[0].key);
  const [filterText, setFilterText] = useState('');

  const updateAggBy = (aggList: string[]) => {
    setActiveReportConfig({
      ...reportConfig,
      allocationAggregationBy: aggList.toString(),
    });
  };

  const handleSaveReport = () => {
    saveReport(activeReportConfig);
  };

  const reportNeedsSave = reportConfig !== activeReportConfig;

  return (
    <div
      style={{
        padding: '2em',
        display: 'grid',
        gridTemplateColumns: '350px 50px 1fr',
        gap: '2em',
      }}
    >
      <div>
        <div
          style={{ display: 'flex', alignItems: 'center', marginBottom: '1em' }}
        >
          <Typography style={{ marginRight: '1em' }} variant="h5">
            Configure Report
          </Typography>
          <Button
            variant="contained"
            onClick={handleSaveReport}
            disabled={!reportNeedsSave}
            style={
              !reportNeedsSave
                ? {}
                : {
                    backgroundColor: colorMap.blue,
                    color: 'white',
                    height: 40,
                  }
            }
          >
            Save
          </Button>
        </div>
        {/* <AppBar
          position="static"
          color="transparent"
          style={{
            background: 'transparent',
            boxShadow: 'none',
            marginBottom: '1em',
          }}
        >
          <Tabs
            value={activeView}
            onChange={(e, newValue: string) => setActiveView(newValue)}
            TabIndicatorProps={{
              style: {
                backgroundColor: '#28B359',
                color: '#28B359'
              },
            }}
            variant="fullWidth"
          >
            <Tab label="Configure" {...a11yProps(0)} />
            <Tab label="Widgets" {...a11yProps(1)} />
          </Tabs>
        </AppBar> */}
        <TextField
          style={{ width: 300, marginTop: '1em', marginBottom: '1em' }}
          helperText="Report Name"
          value={activeReportConfig.name}
          onChange={(e: any) =>
            setActiveReportConfig({
              ...activeReportConfig,
              name: e.target.value,
            })
          }
          placeholder="Monthly Breakdown"
        />
        <div>
          <Typography component="div" variant="h6">
            K8s Allocation Data
          </Typography>
          <div style={{ marginTop: '.5em' }}>
            <AggregateByControl
              id="aggregate-by-control"
              aggregateBy={activeReportConfig.allocationAggregationBy.split(
                ',',
              )}
              setAggregateBy={updateAggBy}
              unaggregatedEnabled={false}
            />
          </div>
        </div>
        <div style={{ marginTop: '1em', marginBottom: '1em' }}>
          <Typography component="div" variant="h6">
            Cloud Asset Data
          </Typography>
          <DropdownControl
            currentValue={activeReportConfig.assetAggregateBy}
            setCurrentValue={(value: string) => {
              setActiveReportConfig({
                ...reportConfig,
                assetAggregateBy: value,
              });
            }}
            valueList={assetAggregationOptions}
            labelText="Breakdown to view Asset Data"
            placeholder="Asset Breakdown"
          />
        </div>
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '1em',
            }}
          >
            <Typography
              component="div"
              variant="h6"
              style={{ marginRight: '.5em' }}
            >
              Data Source Grouping
            </Typography>
            <TooltipMUI
              title={
                <Typography component="div" variant="body2">
                  When combining Asset and Allocation data, we do so using
                  labels. For instance, if you aggregate Allocations by
                  <strong> namespace</strong> unless you've overriden in
                  Settings, then we'll match that in Assets with{' '}
                  <strong>label: kubernetes_namespace</strong>. If you'd like to
                  provide custom labels to override, then do so here.
                </Typography>
              }
            >
              <InfoIcon fontSize="small" />
            </TooltipMUI>
          </div>
          <DataSourceGroupingTable
            labelMap={labelMap}
            reportConfig={activeReportConfig}
            updateConfig={setActiveReportConfig}
          />
        </div>
      </div>
      <Divider orientation="vertical" />
      <div>
        <TabPanel value={activeView} index={0}>
          <BaseConfigurations
            reportConfig={activeReportConfig}
            updateConfig={setActiveReportConfig}
          />
        </TabPanel>
        <TabPanel value={activeView} index={1}>
          <AddOnManager reportConfig={activeReportConfig} />
        </TabPanel>
      </div>
    </div>
  );
};
