import analytics from '../analytics';
import { getCurrentContainerAddressModel, parseResponseJSON } from '../util';

export type ContainerSizingPlan = {
  TargetCPURequestMillicores: number | null;
  TargetRAMRequestBytes: number | null;
};

interface ContainerPlans {
  [index: string]: ContainerSizingPlan;
}

export type ControllerSizingPlan = {
  Name: string;
  Kind: string;
  ContainerPlans: ContainerPlans;
};

export type NamespaceSizingPlan = ControllerSizingPlan[];

interface ClusterSizingPlan {
  [index: string]: NamespaceSizingPlan;
}

interface ClusterPlans {
  [index: string]: ClusterSizingPlan;
}

export type RequestSizingPlan = {
  TimeCreated: string;
  ClusterPlans: ClusterPlans;
};

// Taken from cluster.ts
// TODO make this better
const baseURL = getCurrentContainerAddressModel().substr(
  0,
  getCurrentContainerAddressModel().length - 6,
);

export async function requestRecommendationsToPlan(
  recommendations,
): Promise<RequestSizingPlan> {
  const url = `${baseURL}/cluster/requestsizer/plan`;
  return fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(recommendations),
  }).then(parseResponseJSON);
}

export async function applyRequestPlan(plan: RequestSizingPlan) {
  analytics.record('requests_size_1_click')

  const url = `${baseURL}/cluster/requestsizer/apply`;
  return fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(plan),
  }).then(parseResponseJSON);
}

export async function isRequestSizerAvailable(): Promise<boolean> {
  const url = `${baseURL}/cluster/requestsizer/apply`;
  return fetch(url, {
    method: 'GET',
  }).then(
    // If the endpoint returns a non-404 error, cluster controller's request
    // sizing functionality should be available
    (response) => response.status != 404 && response.status != 502,
  );
}
