import React, { FC } from 'react';

import { NavIconProps } from './types';

const CloudNavIcon: FC<NavIconProps> = ({ active, ...args }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...args}
  >
    <path
      d="M9.63943 9.77074C11.0699 8.5372 12.8944 7.85593 14.7833 7.85001C18.6334 7.85001 21.8294 10.7125 22.1772 14.4037C24.4557 14.7257 26.2333 16.6336 26.2333 18.9751C26.2333 21.5456 24.0893 23.5938 21.4916 23.5938H8.7449C5.77792 23.5938 3.33334 21.2551 3.33334 18.3239C3.33334 15.8006 5.14531 13.711 7.54408 13.1814C7.74875 11.9462 8.54309 10.7154 9.63943 9.77074V9.77074ZM10.574 10.8542C9.49058 11.7888 8.92381 12.9152 8.92381 13.7969V14.438L8.2869 14.5082C6.28744 14.7272 4.76459 16.3688 4.76459 18.3239C4.76459 20.4235 6.52503 22.1625 8.7449 22.1625H21.4916C23.3422 22.1625 24.8021 20.7141 24.8021 18.9751C24.8021 17.2347 23.3422 15.7863 21.4916 15.7863H20.776V15.0707C20.7774 11.8933 18.1153 9.28126 14.7833 9.28126C13.2374 9.28743 11.7446 9.84579 10.574 10.8556V10.8542Z"
      fill={active ? '#63E892' : '#607971'}
    />
  </svg>
);

export { CloudNavIcon };
