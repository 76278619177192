import React, { useEffect, useState } from 'react';
import {
  Button,
  Drawer,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import DiagnosticsChecker from '../../components/DiagnosticsChecker';
import Header from '../../components/Header';
import RefreshIcon from '@material-ui/icons/Refresh';
import Link from '@material-ui/core/Link';
import SettingsIcon from '@material-ui/icons/Settings';
import {
  AssetLabelOverride,
  bogusAllocationEntry,
  getDataFromDataSetGivenColumn,
  handleActionColumn,
  handleValueFromString,
  ReportConfig,
  ReportConfigItem,
  VisualAddOn,
} from './reportingHelpers';
import { labelAssetsMap } from '../Inspect/inspectHelpers';
import model from '../../services/model';
import { colorMap } from '../../services/colors';
import { bogusReportConfig } from './sampleReportPayload';
import { advancedReportingLocalStorageKey } from '../AdvancedReportingManager';
import { useLocation } from 'react-router-dom';
import { CollapsibleRow } from './CollapsibleRow';
import { AddOn } from './AddOn';
import { EditControls } from './EditControls';
import { HelpIconLink } from '../../components/HelpIcon';
import { LoadingScreen } from './LoadingScreen';
import { toCurrency } from '../../services/format';

const breadcrumbs = [
  { name: 'Advanced Reports', href: 'advanced-reporting.html' },
  { name: 'Report Detail', href: '' },
];

// some types need to be passed with label prefix to asset API
const aliasedLabels = ['job', 'controllerkind', 'container', 'node'];

function AdvancedReporting() {
  const routerLocation = useLocation();
  const searchParams = new URLSearchParams(routerLocation.search);
  const [reportConfig, setReportConfig] =
    useState<ReportConfig>(bogusReportConfig);
  const [labelMapForAssets, setLabelMapForAssets] =
    useState<any>(labelAssetsMap);
  const [mergedData, setMergedData] = useState<any>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // TODO: there has to be a better way to accomplish this
  const handleDataMerging = (allocations: any, assetResponse: any) => {
    const dataFromMerge = [];
    const assetObj = assetResponse.data[0];
    // for Assets: totalCost, minutes, indivAssets
    // we're assuming there will always be more 'rows' from allocations
    const getAssetLabelsForAllocAggTypes = (allocationItemName: string) => {
      const allocItemList = allocationItemName
        .split('/')
        .map((item: string, idx: number) => {
          // assuming anything with an equals already, is a label
          const currentAllocationItem =
            reportConfig.allocationAggregationBy.split(',')[idx];
          // we now need to check for overrides, is there another name to use?
          if (currentAllocationItem.startsWith('label')) {
            // then we should strip and get the 'name', then plug in the label to use -- override or same
            const [defaultLabelName, labelValue] = item.split('=');
            let labelNameToUse = defaultLabelName;
            const potentialLabelOverride =
              reportConfig.assetLabelOverrides.find(
                (override: AssetLabelOverride) =>
                  override.allocationAggregation === currentAllocationItem,
              );
            if (potentialLabelOverride) {
              labelNameToUse =
                potentialLabelOverride.assetLabelOverride.split(':')[1]; // want everything after the colon label:teammm
            }
            return `${labelNameToUse}=${labelValue}`;
          } else {
            const potentialLabelOverride =
              reportConfig.assetLabelOverrides.find(
                (override: AssetLabelOverride) =>
                  override.allocationAggregation === currentAllocationItem,
              );

            return `${
              potentialLabelOverride
                ? `${potentialLabelOverride.assetLabelOverride.replace(
                    'label:',
                    '',
                  )}`
                : labelMapForAssets[`${currentAllocationItem}`]
            }=${item}`;
          }
        });
      return allocItemList.join('/');
    };
    // add in bogus allocation record to collect "unclaimed"/"unallocated" records
    allocations = { __undefined__: bogusAllocationEntry, ...allocations };
    Object.entries(allocations).forEach(([key, value]) => {
      let objToReturn = {};
      // we need to get list of keys for assets that match this allocation
      const allocationKeyWithLabelConfigLookup = key.includes('__undefined__')
        ? '__undefined__'
        : getAssetLabelsForAllocAggTypes(key);
      const assetKeys = Object.keys(assetObj).filter((assetKey: string) =>
        assetKey.startsWith(`${allocationKeyWithLabelConfigLookup}/`),
      );
      // then we want to get all of the data from these, sum their totals and stick them under an 'entries' key
      let assetTotal = 0;
      let assetEntries = [];
      if (assetKeys.length > 0) {
        assetKeys.forEach((assetKey: string) => {
          assetTotal += assetObj[assetKey].totalCost;
          assetEntries.push(assetObj[assetKey]);
        });
      }
      objToReturn = {
        allocation: value,
        assets: {
          totalCost: assetTotal,
          entries: assetEntries,
        },
      };
      dataFromMerge.push(objToReturn);
    });
    return dataFromMerge;
  };

  const getData = async () => {
    setLoading(true);
    // first get the label mappings
    const labelConfig = await Promise.resolve(model.getApiConfig());
    // as of now, namespace is the only supported type with external cost information
    // check in the config to see if we need to override the default label for x (in this case namespace)
    // TODD: This needs to be expanded
    if (labelConfig['namespace_external_label'] !== '') {
      setLabelMapForAssets({
        ...labelMapForAssets,
        namespace: labelConfig['namespace_external_label'],
      });
    }
    if (labelConfig['controller_external_label'] !== '') {
      setLabelMapForAssets({
        ...labelMapForAssets,
        controller: labelConfig['controller_external_label'],
      });
    }

    const allocationParams = {
      accumulate: true,
      external: false,
      shareNamespaces: reportConfig.sharedNamespaces.split(','), // comma delimited list
      shareLabels: reportConfig.sharedLabels.split(','), // comma delimited list
      shareCost: reportConfig.sharedOverheadCost,
      filters: reportConfig.filtersInclude,
    };

    const handleAllocationToAssetLabel = (allocationName: string) => {
      const override = reportConfig.assetLabelOverrides.find(
        (item: AssetLabelOverride) =>
          item.allocationAggregation === allocationName,
      );
      if (override) {
        return override.assetLabelOverride;
      } else if (labelMapForAssets[allocationName]) {
        return `label:${labelMapForAssets[`${allocationName}`]}`;
      } else if (aliasedLabels.includes(allocationName)) {
        return `label:${allocationName}`;
      } else {
        return allocationName;
      }
    };

    const [allocationResponse, assetResponse] = await Promise.all([
      model.getAllocationSummary(
        reportConfig.window,
        reportConfig.allocationAggregationBy,
        { ...allocationParams },
        {},
      ),
      model.getAssets(reportConfig.window, {
        accumulate: true,
        // we need to handle this for labels
        aggregate: [
          ...reportConfig.allocationAggregationBy
            .split(',')
            .map((aggItem: string) => handleAllocationToAssetLabel(aggItem)),
          // need to watch this. if multiple ',' we'll have a rogue ,
          reportConfig.assetAggregateBy,
        ].toString(),
      }),
    ]);
    const allocations = allocationResponse.data.sets[0].allocations;

    const mergedDataFromResponses = handleDataMerging(
      allocations,
      assetResponse,
    );
    setMergedData(mergedDataFromResponses);
    setLoading(false);
  };

  const handleSaveReport = (reportConfig: ReportConfig) => {
    setReportConfig(reportConfig);
    setDrawerOpen(false);
    // now we want to save this guy in localStorage
    const existingReports = localStorage.getItem(
      advancedReportingLocalStorageKey,
    );
    if (!existingReports) {
      // this should never happen, but throw an error
    } else {
      const parsedReports = JSON.parse(existingReports);
      const reportFromIdIndex: number = parsedReports.findIndex(
        (report: ReportConfig) => report.id === reportConfig.id,
      );
      // if it exists, update it
      if (reportFromIdIndex !== -1) {
        // this is bad, but for sake of demo, we want to have a current total value
        parsedReports[reportFromIdIndex] = reportConfig;
        localStorage.setItem(
          advancedReportingLocalStorageKey,
          JSON.stringify(parsedReports),
        );
      } else {
      }
      // if not, add it
    }
  };

  const findItemInLocalStorage = async () => {
    const existingReports = localStorage.getItem(
      advancedReportingLocalStorageKey,
    );
    if (!existingReports) {
      // we want to throw an ***** error *****
    } else {
      const parsedReports = JSON.parse(existingReports);
      const reportFromId: ReportConfig = parsedReports.find(
        // TODO
        (report: ReportConfig) =>
          report.id === parseInt(searchParams.get('id')),
      );
      if (reportFromId) {
        setReportConfig(reportFromId);
      } else {
        console.log('didnt find');
        // we want to throw another error
      }
    }
  };

  const setupConfigs = async () => {
    await findItemInLocalStorage();
  };

  useEffect(() => {
    setupConfigs();
  }, []);

  useEffect(() => {
    if (reportConfig.id !== 0) {
      getData();
    }
  }, [reportConfig]);

  return (
    <>
      <Header breadcrumbs={breadcrumbs}>
        <IconButton
          aria-label="refresh"
          onClick={() => console.log('refreshing')}
        >
          <RefreshIcon />
        </IconButton>
        <DiagnosticsChecker />
        <Link href="settings.html">
          <IconButton aria-label="refresh">
            <SettingsIcon />
          </IconButton>
        </Link>
        <HelpIconLink
          href="https://guide.kubecost.com/hc/en-us/articles/5991192077079-Advanced-Reporting"
          tooltipText="AR Documentation"
        />
      </Header>
      <Drawer
        style={{ width: '100vw' }}
        anchor="top"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        {mergedData && (
          <EditControls
            saveReport={handleSaveReport}
            reportConfig={reportConfig}
            labelMap={labelAssetsMap}
          />
        )}
      </Drawer>
      <Paper style={{ padding: '2em' }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 150px',
            marginBottom: '2em',
          }}
        >
          <Typography variant="h5">{reportConfig.name}</Typography>
          <Button
            style={{ backgroundColor: colorMap.blue, color: 'white' }}
            size="medium"
            onClick={() => setDrawerOpen(true)}
          >
            Edit
          </Button>
        </div>
        {loading && <LoadingScreen />}
        {mergedData && (
          <div>
            <Grid
              container
              spacing={4}
              style={{ marginTop: '1em', marginBottom: '1em' }}
            >
              {reportConfig.visualAddOns.map((addOn: VisualAddOn) => (
                <AddOn
                  value={toCurrency(
                    parseFloat(
                      getDataFromDataSetGivenColumn(mergedData, addOn.config),
                    ),
                    'USD',
                  )}
                  key={addOn.name}
                  selected={false}
                  cardProperties={addOn}
                  dataSet={mergedData}
                />
              ))}
            </Grid>
            <TableContainer>
              <Table style={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    {reportConfig.dataMap.map((item: any) => (
                      <TableCell key={item.columnName}>
                        {item.columnName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mergedData.map((item: any, index: number) => (
                    <>
                      {item.assets.entries.length > 0 ? (
                        <CollapsibleRow
                          item={item}
                          key={index}
                          reportConfig={reportConfig}
                        />
                      ) : (
                        <TableRow key={item.allocation.name}>
                          <TableCell />
                          {reportConfig.dataMap.map(
                            (reportItem: ReportConfigItem) => (
                              <TableCell key={reportItem.columnName}>
                                {reportItem.action
                                  ? handleActionColumn(item, reportItem)
                                  : handleValueFromString(
                                      item,
                                      reportItem.dataKey,
                                    )}
                                {}
                              </TableCell>
                            ),
                          )}
                        </TableRow>
                      )}
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </Paper>
    </>
  );
}

export default React.memo(AdvancedReporting);
