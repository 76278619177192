import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import round from 'lodash/round';
import React from 'react';
import {
  bytesToString,
  coresToString,
  toCurrency,
} from '../../../services/format';
import ContainerDialog from './ContainerDialog';
import WarningPopover from './WarningPopover';

const useCellStyles = makeStyles({
  root: {
    fontSize: '0.800rem',
  },
});

const ContainerRow = ({ container, currency, grafanaURL }) => {
  const cellClasses = useCellStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let savings = toCurrency(container.monthlySavings, currency) + '/mo';
  if (container.monthlySavings <= 0) {
    savings = <>&ndash;</>;
  }

  let name = container.controller.name
    ? `${container.controller.namespace}/${container.controller.name}:${container.name}`
    : `${container.namespace}/${container.name}`;

  return (
    <>
      <TableRow hover style={{ cursor: 'pointer' }}>
        <TableCell
          align="left"
          width={200}
          onClick={handleClickOpen}
          classes={cellClasses}
        >
          {name}
        </TableCell>
        <TableCell
          classes={cellClasses}
          onClick={
            Object.keys(container.warnings).length == 0 ? handleClickOpen : null
          }
        >
          {Object.keys(container.warnings).length > 0 && (
            <WarningPopover container={container} />
          )}
        </TableCell>
        <TableCell classes={cellClasses} align="left" onClick={handleClickOpen}>
          {container.cluster}
        </TableCell>
        <TableCell
          classes={cellClasses}
          align="right"
          onClick={handleClickOpen}
        >
          {coresToString(container.usage.cpuCores)}
        </TableCell>
        <TableCell
          classes={cellClasses}
          align="right"
          onClick={handleClickOpen}
        >
          {coresToString(container.requests.cpuCores)}
        </TableCell>
        <TableCell
          classes={cellClasses}
          align="right"
          onClick={handleClickOpen}
        >
          {coresToString(container.target.cpuCores)}
        </TableCell>
        <TableCell
          classes={cellClasses}
          align="right"
          onClick={handleClickOpen}
        >
          {bytesToString(container.usage.ramBytes)}
        </TableCell>
        <TableCell
          classes={cellClasses}
          align="right"
          onClick={handleClickOpen}
        >
          {bytesToString(container.requests.ramBytes)}
        </TableCell>
        <TableCell
          classes={cellClasses}
          align="right"
          onClick={handleClickOpen}
        >
          {bytesToString(container.target.ramBytes)}
        </TableCell>
        <TableCell
          classes={cellClasses}
          align="right"
          onClick={handleClickOpen}
        >
          {round(container.efficiency * 100, 1)}%
        </TableCell>
        <TableCell
          classes={cellClasses}
          align="right"
          onClick={handleClickOpen}
        >
          {savings}
        </TableCell>
      </TableRow>
      <ContainerDialog
        container={container}
        name={name}
        open={open}
        handleClose={handleClose}
        currency={currency}
        grafanaURL={grafanaURL}
      />
    </>
  );
};

export default ContainerRow;
