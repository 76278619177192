import { Input, Modal, Typography, Button } from '@kubecost-frontend/holster';
import React, { FC, useEffect, useState } from 'react';
import { AssetReport } from '../../../types/asset';

interface SaveControlProps {
  report?: AssetReport;
  save: (title: string) => void;
  title: string;
  unsave: (report: AssetReport) => void;
  open: boolean;
  setSaveOpen: () => void;
}

export const SaveControl: FC<SaveControlProps> = ({
  report,
  save,
  title,
  unsave,
  open,
  setSaveOpen,
}) => {
  const [localTitle, setLocalTitle] = useState('');

  const saveHandler = () => {
    save(localTitle);
    setSaveOpen();
  };

  const unsaveHandler = () => {
    if (!report) {
      return;
    }

    unsave(report);
    setSaveOpen();
  };

  useEffect(() => {
    if (report && report.title) {
      setLocalTitle(report.title);
    } else if (title) {
      setLocalTitle(title);
    }
  }, [report, title]);

  return (
    <>
      <Modal
        open={open}
        onClose={setSaveOpen}
        title={report ? 'Unsave report' : 'Save report'}
      >
        <div style={{ padding: '1em' }}>
          {report ? (
            <Typography variant="p">{`Are you sure you want to unsave ${localTitle}? This action affects all users.`}</Typography>
          ) : (
            <Input
              onChange={(e) => setLocalTitle(e.target.value)}
              value={localTitle}
              style={{ width: '100%' }}
            />
          )}
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '10px',
          }}
        >
          {report ? (
            <Button variant="primary" onClick={unsaveHandler}>
              Unsave
            </Button>
          ) : (
            <Button variant="primary" onClick={saveHandler}>
              Save
            </Button>
          )}
          <Button variant="default" onClick={setSaveOpen}>
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(SaveControl);
