import isArray from 'lodash/isArray';
import sortBy from 'lodash/sortBy';
import forEach from 'lodash/forEach';
import model from './model';
import {
  AllocationReport,
  AllocationReportResponse,
} from '../types/allocation';
import { AssetReport } from '../types/asset';
import { AdvancedReportResponse } from '../types/advancedReport';

// See pkg/reports/store.go: func (ps Parameters) key() string
export const allocationKey = (report: AllocationReport): string => {
  let filterStr = '';
  let overheadStr = '';
  let namespaceStr = '';
  let labelStr = '';
  if (isArray(report.filters) && report.filters.length > 0) {
    const fs: string[] = [];
    forEach(report.filters, (f) => fs.push(`${f.property}=${f.value}`));
    filterStr = sortBy(fs, (f) => f).join(',');
  }

  if (report.sharedOverhead != null) {
    overheadStr = report.sharedOverhead.toFixed(6).toString();
  } else {
    overheadStr = 'nil';
  }

  if (report.sharedNamespaces != null) {
    namespaceStr = sortBy(report.sharedNamespaces, (f) => f).join(',');
  } else {
    namespaceStr = 'nil';
  }

  if (report.sharedLabels != null) {
    labelStr = sortBy(report.sharedLabels, (f) => f).join(',');
  } else {
    labelStr = 'nil';
  }

  return `${report.window}:${report.aggregateBy}:${report.idle}:${report.chartDisplay}:${report.rate}:${filterStr}:${overheadStr}:${namespaceStr}:${labelStr}`;
};

const assetKey = (report: AssetReport): string => {
  const filterStr = report.filters
    .map((f) => `${f.property}=${f.value}`)
    .sort()
    .join(',');
  return `${report.window}:${report.aggregateBy}:${report.accumulate}:${filterStr}`;
};

export function listAllocationReports(): Promise<AllocationReport[]> {
  return model.get<AllocationReportResponse[]>('/reports').then((results) =>
    results.map((result) => {
      return {
        ...result,
        aggregateBy: result.aggregateBy.split(','),
      };
    }),
  );
}
export function listAdvancedReports(): Promise<AdvancedReportResponse[]> {
  return model
    .get<AdvancedReportResponse[]>('/reports/advanced')
    .then((results) => results);
}

export function saveAdvancedReport(
  report: AdvancedReportResponse,
): Promise<AdvancedReportResponse> {
  return model.post('/reports/advanced', report);
}

export function saveAllocationReport(
  report: AllocationReport,
): Promise<unknown> {
  const payload = { ...report, aggregateBy: report.aggregateBy.join(',') };
  return model.post('/reports', payload);
}

export function deleteAdvancedReport(
  id?: string,
): Promise<AdvancedReportResponse[]> {
  if (!id) {
    return model.get('/reports/advanced');
  }
  return model.delete(`/reports/advanced`, { id });
}

export function deleteAllocationReport(
  report: AllocationReport,
): Promise<AllocationReportResponse[]> {
  return model.delete('/reports', { key: allocationKey(report) });
}

export async function listAssetReports(): Promise<AssetReport[]> {
  const reports = await model.get<AssetReport[]>('/reports/asset');
  return reports.map(mapAssetReport);
}

export async function saveAssetReport(
  report: AssetReport,
): Promise<AssetReport[]> {
  const reports = await model.post<AssetReport[]>('/reports/asset', report);
  return reports.map(mapAssetReport);
}

export async function deleteAssetReport(
  report: AssetReport,
): Promise<AssetReport[]> {
  const reports = await model.delete<AssetReport[]>('/reports/asset', {
    key: assetKey(report),
  });
  return reports.map(mapAssetReport);
}

function mapAssetReport(report: AssetReport) {
  const filterString = report.filters
    .map((f) => `${f.property}=${f.value}`)
    .sort()
    .join(',');
  return { ...report, key: assetKey(report), filterString };
}

export default {
  listAllocationReports,
  saveAllocationReport,
  deleteAllocationReport,
  listAssetReports,
  saveAssetReport,
  deleteAssetReport,
};
