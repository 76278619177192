import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { ETLCloudStatus } from '../../../services/diagnostics';

type Props = {
  cloudProvider: ETLCloudStatus | null;
  open: boolean;
  onClose: () => void;
};

type CloudIntegrationListStatProps = {
  name: string;
  content: string;
};

const CloudIntegrationListStat = ({
  name,
  content,
}: CloudIntegrationListStatProps) => (
  <div
    style={{
      marginTop: '2em',
      width: '100%',
      height: '50px',
      display: 'inline-flex',
      borderBottom: '1px solid rgba(0,0,0,0.1)',
    }}
  >
    <Typography color="textSecondary" variant="body2">
      {name}
    </Typography>
    <Typography variant="body2" align="right" style={{ flexGrow: '1' }}>
      {content}
    </Typography>
  </div>
);

const CloudIntegrationDetailDialog = ({
  cloudProvider,
  open,
  onClose,
}: Props) => {
  return (
    <Dialog
      data-testid="ci-dialog"
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <div style={{ display: 'inline-flex', width: '100%' }}>
          <span>Cloud Integration Details</span>
          <div style={{ flexGrow: '1' }}>
            <Chip
              style={{
                float: 'right',
                color:
                  cloudProvider &&
                  cloudProvider.cloudConnectionStatus ===
                    'Connection Successful'
                    ? 'green'
                    : '',
              }}
              variant="outlined"
              label={cloudProvider && cloudProvider.cloudConnectionStatus}
            ></Chip>
          </div>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            width: '100%',
          }}
        >
          <div
            style={{ borderRight: '1px solid rgba(0,0,0,0.1)', padding: '1em' }}
          >
            <Typography variant="h6">
              <b>Cloud Usage</b>
            </Typography>
            <Divider style={{ marginTop: '1em' }} />
            {cloudProvider &&
              cloudProvider.cloudUsage &&
              cloudProvider.cloudConnectionStatus ===
                'Connection Successful' && (
                <>
                  <CloudIntegrationListStat
                    name="Coverage"
                    content={
                      cloudProvider.cloudUsage.coverage.start +
                      ' - ' +
                      cloudProvider.cloudUsage.coverage.end
                    }
                  />
                  <CloudIntegrationListStat
                    name="Completed"
                    content={cloudProvider.cloudUsage.progress}
                  />
                  <CloudIntegrationListStat
                    name="Start Time"
                    content={cloudProvider.cloudUsage.startTime}
                  />
                  <CloudIntegrationListStat
                    name="Last Run"
                    content={cloudProvider.cloudUsage.lastRun}
                  />
                  <CloudIntegrationListStat
                    name="Next Run"
                    content={cloudProvider.cloudUsage.nextRun}
                  />
                  <CloudIntegrationListStat
                    name="Refresh Rate"
                    content={cloudProvider.cloudUsage.refreshRate}
                  />
                </>
              )}
          </div>
          <div style={{ padding: '1em' }}>
            <Typography variant="h6">
              <b>Cloud Reconciliation</b>
            </Typography>
            <Divider style={{ marginTop: '1em' }} />
            {cloudProvider &&
              cloudProvider.reconciliation &&
              cloudProvider.cloudConnectionStatus ===
                'Connection Successful' && (
                <>
                  <CloudIntegrationListStat
                    name="Coverage"
                    content={
                      cloudProvider.reconciliation.coverage.start +
                      ' - ' +
                      cloudProvider.reconciliation.coverage.end
                    }
                  />
                  <CloudIntegrationListStat
                    name="Completed"
                    content={cloudProvider.reconciliation.progress}
                  />
                  <CloudIntegrationListStat
                    name="Start Time"
                    content={cloudProvider.reconciliation.startTime}
                  />
                  <CloudIntegrationListStat
                    name="Last Run"
                    content={cloudProvider.reconciliation.lastRun}
                  />
                  <CloudIntegrationListStat
                    name="Next Run"
                    content={cloudProvider.reconciliation.nextRun}
                  />
                  <CloudIntegrationListStat
                    name="Refresh Rate"
                    content={cloudProvider.reconciliation.refreshRate}
                  />
                </>
              )}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button data-testid="ci-dialog-close" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CloudIntegrationDetailDialog;
