import {
  APITimeSeriesGraphPoint,
  APITimeSeriesGraphPointItem,
} from '../services/model';

interface APICloudCostTimeSeriesGraphPoint extends APITimeSeriesGraphPoint {
  items: APICloudCostTimeSeriesGraphPointItem[];
}

interface APICloudCostTimeSeriesGraphPointItem
  extends APITimeSeriesGraphPointItem {
  value: number;
}

interface APICloudCostTableDatum {
  credit: number;
  kubernetesPercent: number;
  name: string;
  totalCost: number;
}

interface APIGetCloudCosts {
  graphData: APICloudCostTimeSeriesGraphPoint[];
  tableRows: APICloudCostTableDatum[];
  tableTotal: APICloudCostTableDatum;
}

// maps `APICloudCostParamType` to presentation/UI values
const APICloudCostParams = {
  account: 'Account',
  project: 'Project',
  provider: 'Provider',
  service: 'Service',
} as const;

type APICustomLabel = `label:${string}`;

type APICloudCostParamType = keyof typeof APICloudCostParams | APICustomLabel;

export {
  APICloudCostParams,
  APICloudCostParamType,
  APICustomLabel,
  APICloudCostTableDatum,
  APIGetCloudCosts,
  APICloudCostTimeSeriesGraphPoint,
};
