// MUI components
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { CloudQueue } from '@material-ui/icons';
import StorageIcon from '@material-ui/icons/Storage';
import Model from '../../services/model';

// MUI styles
import { makeStyles } from '@material-ui/core/styles';

// react
import React, { useEffect, useState } from 'react';

// local
import { toCurrency } from '../../services/format';
import { Chip, Typography } from '@material-ui/core';
import { AssetSet } from '../../types/asset';
import isEmpty from 'lodash/isEmpty';

const useStyles = makeStyles({
  root: {
    padding: '2em',
  },
  totalRow: {
    fontWeight: 500,
  },
});

export const ExternalCostsTile = ({
  costData,
  currency,
  resourceName,
  totalCost,
}: any) => {
  const classes = useStyles();

  const [data, setData] = useState<AssetSet>({});

  useEffect(() => {
    fetchData();
  }, []);

  if (!costData || costData.length == 0) {
    const [title, buttonText, linkHref] = isEmpty(data)
      ? // we can assume that cloud integration hasn't been configured
        [
          'External cloud costs not configured',
          'Configure cloud integration',
          'settings.html#out-of-cluster-box',
        ]
      : // cloud integration is configured, we just don't have any data for this resource
        [
          <div>
            No External Cost data for <strong>{resourceName}</strong>
          </div>,
          'Explore Assets',
          '/assets.html',
        ];

    return (
      <Card
        className={classes.root}
        style={{
          backgroundColor: '#ececec',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <div>
          <CardHeader title={title} />
          <CardContent>
            <Link href={linkHref}>
              <Button variant="contained" color="primary" size="large">
                {buttonText}
              </Button>
            </Link>
          </CardContent>
        </div>
      </Card>
    );
  }
  return (
    <Card className={classes.root}>
      <Typography component="div" variant="h6">
        Cloud Costs for <strong>{resourceName}</strong>
      </Typography>
      <CardContent>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Service</TableCell>
                <TableCell>Cost</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {costData.map(([key, val]: [string, number]) => (
                <TableRow key={key}>
                  <TableCell>
                    <Chip
                      icon={
                        key.includes('Cloud') ? <CloudQueue /> : <StorageIcon />
                      }
                      label={key}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>{toCurrency(val, currency)}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell align="right">Total:</TableCell>
                <TableCell>{toCurrency(totalCost, currency)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
  // checking to see if enabled
  async function fetchData() {
    const response = await Model.getAssets('7d', {
      accumulate: true,
      aggregate: 'service',
    });
    const assetSet: AssetSet = response.data[0];
    delete assetSet.Kubernetes;
    setData(assetSet);
  }
};
