// react
import React, { FC, useState, useEffect } from 'react';

// lodash
import get from 'lodash/get';

// mui
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

// holster
import { Typography, Button, Input, Modal } from '@kubecost-frontend/holster';

// local
import { parseResponseJSON } from '../../../services/util';
import Analytics from '../../../services/analytics';
import model from '../../../services/model';
import { useClusters } from '../../../contexts/ClusterConfig';
import { greyscale } from '../../../services/colors';
import { sanitizeHTML } from '../../../services/util';

const re = /^(https?:\/\/)(.+):(.+)@(.+)/;
const useStyles = makeStyles({
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
  listItem: {
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1)',
      cursor: 'pointer',
    },
  },
});

const activeItemStyle = {
  backgroundColor: greyscale[4],
  cursor: 'pointer',
  borderLeft: '.25em solid #63E892',
};

const baseItemStyle = {
  padding: '1em',
  display: 'grid',
  alignItems: 'center',
  gap: '1em',
  gridTemplateColumns: '1fr auto 50px',
};

async function fetchClusterList(allEndpoints: string[]): Promise<any> {
  const clusters = [];

  for (let i = 0; i < allEndpoints.length; i++) {
    const cluster = allEndpoints[i].replace(/\/api$/, '');
    const url = `${cluster}/model/clusterInfo?req=${Date.now()}`;

    try {
      const info = await fetch(url).then(parseResponseJSON);

      const name = get(info, 'data.name', cluster);
      const federatedCluster = get(info, 'data.thanosEnabled', false);

      clusters.push({
        name,
        federatedCluster: federatedCluster === 'true' ? true : false,
        address: cluster,
        details: get(info, 'details', {}),
      });
    } catch (e) {
      clusters.push({
        address: cluster,
        federatedCluser: false,
        name: cluster,
        details: {},
      });
    }
  }

  return clusters;
}

const ContextSwitcher: FC = () => {
  const classes = useStyles();
  const [clusterList, setClusterList] = useState([]);
  const [clusterName, setClusterName] = useState('');
  const [newClusterName, setNewClusterName] = useState('');
  const [dialogOpen, setDialogOpen] = useState<boolean>(false); //TODO: Change back
  const [addContextView, setAddContextView] = useState(false);
  const {
    remoteClusterEndpoints,
    localClusterEndpoints,
    activeContext,
    addContext,
    removeContext,
  } = useClusters();

  const handleOpenDialog = () => {
    setDialogOpen(!dialogOpen);
  };

  const shouldDisableAddNewCluster = () => (
    newClusterName === '' || newClusterName === 'http://' || newClusterName === 'https://' || newClusterName.length < 7
  )

  function addNewCluster(newClusterAddress: string) {
    const address =
      'http://' +
      sanitizeHTML(newClusterAddress.trim())
        .replace(/^https?:\/\//, '')
        .replace(/\/$/, '');

    if (localClusterEndpoints.includes(address)) {
      return alert(`This address (${address}) has already been added`);
    }

    addContext(address);
  }

  const handleSetCluster = (cluster: any) => {
    const basicAuthAddress = re.exec(cluster.address);
    const auth = cluster.details.auth;

    if (!(basicAuthAddress || auth)) {
      localStorage.setItem('container', cluster.address);
      setDialogOpen(false);
      Analytics.record('general:switch_cluster', {
        from: '',
        to: cluster.name,
      });
      window.location.reload();
    } else {
      window.open(cluster.address);
    }
  };

  useEffect(() => {
    fetchClusterList([
      ...remoteClusterEndpoints,
      ...localClusterEndpoints,
    ]).then(setClusterList);
  }, [remoteClusterEndpoints, localClusterEndpoints]);

  useEffect(() => {
    model.clusterInfo().then((resp) => {
      setClusterName(resp.name);
    });
  }, [activeContext]);

  return (
    <>
      <div className="flex items-center p-4" onClick={handleOpenDialog}>
        <SwapHorizIcon className="mr-4" />
        <Typography
          variant="p"
          className="font-bold hover:text-kc-primary cursor-pointer"
        >
          Switch Context
        </Typography>
      </div>

      <Modal onClose={() => setDialogOpen(false)} open={dialogOpen}>
        <>
          <div className="flex items-center mb-4" style={{ gap: '1em' }}>
            <Typography variant="p-large">Contexts</Typography>
            <a
              target="_blank"
              href="https://guide.kubecost.com/hc/en-us/articles/4407595970711#multi-cluster"
              rel="noreferrer"
            >
              <div
                className="flex justify-center items-center h-5 w-5 text-sm"
                style={{
                  backgroundColor: '#607971',
                  color: 'white',
                }}
              >
                <Typography variant="p">i</Typography>
              </div>
            </a>
          </div>
          <div>
            {clusterList.length === 0 ? (
              <DialogContentText style={{ padding: '1em' }}>
                No contexts configured.
              </DialogContentText>
            ) : (
              <div style={{ padding: 0 }}>
                {clusterList.map((cluster: any) => {
                  const basicAuthName = re.exec(cluster.name);
                  const basicAuthAddress = re.exec(cluster.address);
                  return (
                    <div
                      className={classes.listItem}
                      key={cluster.address}
                      onClick={() => handleSetCluster(cluster)}
                      style={
                        cluster.address === activeContext
                          ? { ...baseItemStyle, ...activeItemStyle }
                          : baseItemStyle
                      }
                    >
                      <div
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <div
                          style={{
                            fontWeight: 700,
                            fontSize: '17px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {basicAuthName ? basicAuthName[4] : cluster.name}
                        </div>
                        <p
                          style={{
                            margin: 0,
                            color: 'rgba(0,0,0,0.6)',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {basicAuthAddress
                            ? basicAuthAddress[4]
                            : cluster.address}
                        </p>
                      </div>
                      <div style={{ textAlign: 'right', paddingTop: '12px' }}>
                        {cluster.federatedCluster
                          ? 'Multi Cluster'
                          : 'Single Cluster'}
                      </div>
                      <DeleteIcon
                        className='mt-3'
                        onClick={(e: React.MouseEvent) => {
                          e.stopPropagation()
                          deleteCluster(cluster.address)
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div
            style={{
              borderTop: '1px solid grey',
              color: '#28B359',
              paddingTop: '2em',
              cursor: 'pointer',
            }}
          >
            {addContextView ? (
              <div>
                <Input
                  style={{
                    width: '100%',
                    marginTop: '1em',
                    marginBottom: '1em',
                  }}
                  onChange={(e: any) => setNewClusterName(e.target.value)}
                />
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Button
                    onClick={() => setAddContextView(false)}
                    style={{ marginRight: '.5em' }}
                    variant="default"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => addNewCluster(newClusterName)}
                    variant="primary"
                    disabled={shouldDisableAddNewCluster()}
                  >
                    Add new
                  </Button>
                </div>
              </div>
            ) : (
              <Typography onClick={() => setAddContextView(true)} variant="p">
                Add new context...
              </Typography>
            )}
          </div>
        </>
      </Modal>
    </>
  );

  function deleteCluster(address: string) {
    if (
      !confirm(
        `Are you sure you want to remove the cluster at ${address} from the Kubecost UI?`,
      )
    ) {
      return;
    }
    removeContext(address);
  }
};

ContextSwitcher.displayName = 'ContextSwitcher';
export default ContextSwitcher;
