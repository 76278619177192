// react
import React, { SyntheticEvent, useEffect, useState } from 'react';

// react-router
import { useLocation } from 'react-router-dom';

// lodash
import get from 'lodash/get';
import round from 'lodash/round';

// mui
import grey from '@material-ui/core/colors/grey';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

// holster
import {
  Button,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Tooltip as HolsterTooltip,
  Typography,
} from '@kubecost-frontend/holster';

// local
import AllocationChart from '../AllocationChart';
import AllocationReportRow from './Row';
import { AllocationAggExplanations } from '../../../constants';
import Analytics from '../../../services/analytics';
import { toCurrency } from '../../../services/format';
import { useClusters } from '../../../contexts/ClusterConfig';
import {
  TableResults,
  TimeSeriesGraph,
  TopResultsGraph,
  TotalsRow,
} from '../../../services/model';
import { ArrowIcon } from '../../../assets/images/arrow-icon';

// descendingComparator provides a comparator for stableSort, which compares
// the given orderBy column of two rows, a and b. Due to the design of getCost,
// whereby the complete value of a "cost" column is actually the cost plus the
// associated adjustment (e.g. cpuCost = cpuCost + cpuCostAdjustment) the
// getCost function must be called here for "cost" columns. Kind of a hacky
// solution, but it's the simplest way to fix sorting. See the complete
// discussion: https://github.com/kubecost/cost-analyzer-frontend/issues/301
function descendingComparator(
  a: TableResults,
  b: TableResults,
  orderBy: string,
) {
  if (get(b, orderBy, 0) < get(a, orderBy, 0)) {
    return -1;
  }
  if (get(b, orderBy, 0) > get(a, orderBy, 0)) {
    return 1;
  }
  return 0;
}

function getComparator(order: string, orderBy: string) {
  return order === 'desc'
    ? (a: TableResults, b: TableResults) => descendingComparator(a, b, orderBy)
    : (a: TableResults, b: TableResults) =>
        -descendingComparator(a, b, orderBy);
}

function stableSort(
  array: Array<TableResults>,
  comparator: (a: TableResults, b: TableResults) => number,
) {
  const stabilizedThis: [TableResults, number][] = array.map((el, index) => [
    el,
    index,
  ]);
  stabilizedThis.sort(
    (a: [TableResults, number], b: [TableResults, number]) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    },
  );
  return stabilizedThis.map((el) => el[0]);
}

const efficiencyTooltip =
  'Efficiency is defined as (usage / request) for CPU and RAM. If resources are used, but no resources are requested, then efficiency is considered infinite.';

const labelAliases = [
  'team',
  'department',
  'environment',
  'product',
  'daemonset',
  'job',
  'deployment',
];

const DetailsPageLink = ({
  aggregateBy,
  resourceName,
}: {
  aggregateBy: string[];
  resourceName: string;
}) => {
  const location = useLocation();

  // in cases where resourceName has the format clusterName/clusterID, we want only clusterID passed to details.html
  let baseResourceName = resourceName;
  if (
    aggregateBy.length === 1 &&
    aggregateBy[0] === 'cluster' &&
    baseResourceName.split('/').length > 1
  ) {
    baseResourceName = baseResourceName.split('/')[1];
  }
  let baseAggList = aggregateBy.toString();
  const urlParams = new URLSearchParams(location.search);
  const currentFilters = JSON.parse(
    atob(urlParams.get('filters') || '') || '{}',
  );

  const handleDrilldown = (e: SyntheticEvent) => {
    e.stopPropagation();
    Analytics.record('inspected_item', {
      resourceName,
      resourceType: aggregateBy,
    });
    if (currentFilters.length > 0) {
      currentFilters.forEach((item: { property: string; value: string }) => {
        // to handle cases where we want to venture to type=namespace&name=kubecost via row click,
        // but we also have a filter of namespace=kubecost
        if (!baseAggList.includes(item.property)) {
          baseAggList += `,${item.property}`;
          baseResourceName += `/${item.value}`;
        }
      });
    }
    window.open(
      `./details?name=${baseResourceName}&type=${baseAggList}`,
      '_blank',
    );
  };

  return (
    <Tooltip title="Click to inspect details">
      <a onClick={handleDrilldown} className="cursor-pointer text-kc-link">
        {resourceName} <OpenInNewIcon style={{ fontSize: 12, color: 'gray' }} />
      </a>
    </Tooltip>
  );
};

interface ComponentProps {
  aggregateBy: string[];
  chartDisplay: 'category' | 'efficiency' | 'percentage' | 'series' | 'treemap';
  count: number;
  drillDownCompatible: string[];
  drillDownExemptRows: string[];
  rate: string;
  sharingIdle: boolean;
  canDrillDown: (row: {
    totalCost: number;
    externalCost: number;
    name: string;
  }) => boolean;
  drillDownForRow: (row: {
    name: string;
    cluster: string;
    node: string;
    totalCost: number;
    externalCost: number;
    namespace: string;
    controllerkind: string;
    service: string;
    department: string;
    environment: string;
    owner: string;
    product: string;
    team: string;
  }) => () => void;
  tableData: TableResults[];
  totalsRow: TotalsRow;
  graphData: TimeSeriesGraph | TopResultsGraph;
}

const AllocationReport = ({
  aggregateBy,
  chartDisplay,
  count,
  drillDownCompatible,
  drillDownExemptRows,
  drillDownForRow,
  canDrillDown,
  sharingIdle,
  rate,
  tableData,
  totalsRow,
  graphData,
}: ComponentProps) => {
  const [order, setOrder] = React.useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = React.useState('totalCost');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [numRowsMenuOpen, setNumRowsMenuOpen] = useState(false);
  const { modelConfig } = useClusters();

  useEffect(() => {
    setPage(0);
  }, [count]);

  if (tableData.length === 0) {
    return (
      <Typography variant="p" style={{ padding: 24 }}>
        No results
      </Typography>
    );
  }

  const inspectableAggs = [
    'namespace',
    'controller',
    'service',
    ...labelAliases,
  ];

  const headCells = [
    {
      id: 'name',
      numeric: false,
      label: 'Name',
      width: 'auto',
      tooltip: getTooltipForAggregation(),
    },
    {
      id: 'cpuCost',
      numeric: true,
      label: 'CPU',
      width: 90,
      tooltip:
        'The total cost of node CPU attributed to each workload over the chosen window.',
    },
    {
      id: 'gpuCost',
      numeric: true,
      label: 'GPU',
      width: 90,
      tooltip:
        'The total cost of node GPU attributed to each workload over the chosen window.',
    },
    {
      id: 'ramCost',
      numeric: true,
      label: 'RAM',
      width: 90,
      tooltip:
        'The total cost of node RAM attributed to each workload over the chosen window.',
    },
    {
      id: 'pvCost',
      numeric: true,
      label: 'PV',
      width: 90,
      tooltip:
        'The total cost of Persistent Volume storage attributed to each workload over the chosen window.',
    },
    {
      id: 'networkCost',
      numeric: true,
      label: 'Network',
      width: 90,
      tooltip:
        'The total cost of network egress/ingress attributed attributed to each workload over the chosen window.',
    },
    {
      id: 'loadBalancerCost',
      numeric: true,
      label: 'LB',
      width: 90,
      tooltip:
        'The total cost of load balancers attributed to each workload over the chosen window.',
    },
    {
      id: 'sharedCost',
      numeric: true,
      label: 'Shared',
      width: 90,
      tooltip:
        'The total shared overhead cost attributed to each workload over the chosen window.',
    },
    {
      id: 'totalEfficiency',
      numeric: true,
      label: 'Efficiency',
      width: 90,
      tooltip: (
        <p>
          The average efficiency of each workload over the chosen window.
          Workload efficiency is the cost-weighted ratio of RAM and CPU usage,
          to configured RAM and CPU request.{' '}
          <a
            className="text-kc-link"
            target="_blank"
            href="https://guide.kubecost.com/hc/en-us/articles/4407601807383-Kubernetes-Cost-Allocation#cost-efficiency-table-example"
          >
            Learn More
          </a>
        </p>
      ),
    },
    {
      id: 'totalCost',
      numeric: true,
      label: 'Total cost',
      width: 90,
      tooltip:
        'The total cost attributed to each workload over the chosen window.',
    },
  ];

  function isInspectable(row: {
    name: string;
    totalCost: number;
    externalCost: number;
  }) {
    if (aggregateBy.length !== 1) {
      return false;
    }
    const agg = aggregateBy[0];
    if (agg.startsWith('label:')) {
      let labelName = aggregateBy[0].split(':').pop() || '';
      labelName = labelName.replace(/\.|\//g, '_');
      return row.name.startsWith(`${labelName}=`);
    }
    return canDrillDown(row) && inspectableAggs.includes(agg);
  }

  function getTooltipForAggregation() {
    if (aggregateBy.length !== 1) {
      return '';
    }
    const agg = aggregateBy[0];
    return AllocationAggExplanations[agg] || '';
  }

  const lastPage = Math.floor(count / rowsPerPage);

  const orderedRows = stableSort(tableData, getComparator(order, orderBy));
  const pageRows = orderedRows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );
  const p = Math.min(page, lastPage);
  const startIndex = p * rowsPerPage + 1;
  const stopIndex = (p + 1) * rowsPerPage;

  const dataToAllocationRow = (row: TableResults) => {
    let name = row.name;
    if (name === '__idle__' && sharingIdle) {
      name = 'Undistributable idle';
    }
    if (name === '__unmounted__') {
      name = 'Unmounted PVs';
    }

    return (
      <AllocationReportRow
        key={row.name}
        name={
          isInspectable(row) ? (
            <DetailsPageLink aggregateBy={aggregateBy} resourceName={name} />
          ) : (
            name
          )
        }
        drillDown={canDrillDown(row) ? drillDownForRow(row) : null}
        efficiency={row.efficiency ?? 0}
        cpuCost={row.cpuCost ?? 0}
        gpuCost={row.gpuCost ?? 0}
        ramCost={row.ramCost ?? 0}
        pvCost={row.pvCost ?? 0}
        loadBalancerCost={row.loadBalancerCost ?? 0}
        networkCost={row.networkCost ?? 0}
        sharedCost={row.sharedCost ?? 0}
        totalCost={row.totalCost ?? 0}
        cpuRequest={row.averageCpuUtilization ?? 0}
        ramRequest={row.averageRamUtilization ?? 0}
        isIdle={row.name?.includes('__idle__')}
        isUnmounted={row.name?.includes('__unmounted__')}
        costSuffix={
          { hourly: '/hr', monthly: '/mo', daily: '/day' }[rate] || ''
        }
      />
    );
  };

  return (
    <div>
      <AllocationChart
        height={300}
        aggregateBy={aggregateBy}
        chartDisplay={chartDisplay}
        allocationRows={pageRows}
        drillDownCompatible={drillDownCompatible}
        drillDownExemptRows={drillDownExemptRows}
        graphData={graphData}
        drillDownFactory={drillDownForRow}
      />
      <hr
        style={{
          margin: 0,
          border: 'none',
          borderTop: '1px solid rgba(0,0,0,0.1)',
        }}
      />
      <div className={'overflow-x-auto'}>
        <Table className="w-full">
          <TableHead>
            <TableRow>
              {headCells.map((cell) => (
                <TableHeadCell
                  key={cell.id}
                  align={cell.numeric ? 'right' : 'left'}
                  style={{
                    width: cell.width,
                    paddingRight: cell.id === 'totalCost' ? '2em' : '',
                  }}
                >
                  <TableSortLabel
                    active={orderBy === cell.id}
                    direction={orderBy === cell.id ? order : 'asc'}
                    onClick={() => {
                      const isDesc = orderBy === cell.id && order === 'desc';
                      setOrder(isDesc ? 'asc' : 'desc');
                      setOrderBy(cell.id);
                    }}
                  >
                    {cell.label}
                    {cell.tooltip && (
                      <HolsterTooltip
                        content={cell.tooltip}
                        style={{ width: 300, color: '#607971' }}
                      >
                        <InfoIcon
                          style={{
                            fontSize: 12,
                            color: grey[500],
                            margin: '0 4px',
                          }}
                        />
                      </HolsterTooltip>
                    )}
                  </TableSortLabel>
                </TableHeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left" style={{ fontWeight: 500 }}>
                {totalsRow.name}
              </TableCell>
              <TableCell align="right" style={{ fontWeight: 500 }}>
                {toCurrency(totalsRow.cpuCost || 0, modelConfig.currencyCode)}
              </TableCell>
              <TableCell align="right" style={{ fontWeight: 500 }}>
                {toCurrency(totalsRow.gpuCost || 0, modelConfig.currencyCode)}
              </TableCell>
              <TableCell align="right" style={{ fontWeight: 500 }}>
                {toCurrency(totalsRow.ramCost || 0, modelConfig.currencyCode)}
              </TableCell>
              <TableCell align="right" style={{ fontWeight: 500 }}>
                {toCurrency(totalsRow.pvCost || 0, modelConfig.currencyCode)}
              </TableCell>
              <TableCell align="right" style={{ fontWeight: 500 }}>
                {toCurrency(
                  totalsRow.networkCost || 0,
                  modelConfig.currencyCode,
                )}
              </TableCell>
              <TableCell align="right" style={{ fontWeight: 500 }}>
                {toCurrency(
                  totalsRow.loadBalancerCost || 0,
                  modelConfig.currencyCode,
                )}
              </TableCell>
              <TableCell align="right" style={{ fontWeight: 500 }}>
                {toCurrency(
                  totalsRow.sharedCost || 0,
                  modelConfig.currencyCode,
                )}
              </TableCell>
              {totalsRow.efficiency === 1.0 &&
              !totalsRow.averageCpuUtilization &&
              !totalsRow.averageRamUtilization ? (
                <Tooltip title={efficiencyTooltip}>
                  <TableCell align="right" style={{ fontWeight: 500 }}>
                    Inf%
                  </TableCell>
                </Tooltip>
              ) : (
                <TableCell align="right" style={{ fontWeight: 500 }}>
                  {round((totalsRow.efficiency || 0) * 100, 1)}%
                </TableCell>
              )}
              <TableCell
                align="right"
                style={{ fontWeight: 500, paddingRight: '2em' }}
              >
                {toCurrency(totalsRow.totalCost || 0, modelConfig.currencyCode)}
              </TableCell>
            </TableRow>
            {pageRows.map(dataToAllocationRow)}
          </TableBody>
        </Table>
      </div>
      <div className="flex justify-between h-6 w-full mt-3">
        <div className="flex items-center">
          <Typography variant="p" className="mr-1">
            Rows per page
          </Typography>
          <Button
            variant="default"
            onClick={() => setNumRowsMenuOpen(true)}
            className="p-1"
          >
            <ArrowIcon direction="LEFT" className="inline" />
            {rowsPerPage}
          </Button>
          {numRowsMenuOpen && (
            <Menu
              items={['10', '25', '50']}
              onClose={() => setNumRowsMenuOpen(false)}
              selectItem={(v) => setRowsPerPage(parseInt(v.text))}
            />
          )}
        </div>
        <div className="flex justify-end items-center">
          <Typography variant="p" style={{ marginRight: 19 }}>
            {startIndex}-{stopIndex} of {count}
          </Typography>
          <Button
            variant="default"
            style={{ marginRight: 7 }}
            onClick={() => setPage(page - 1)}
            disabled={!page}
          >
            Previous
          </Button>
          <Button
            variant="default"
            style={{ marginRight: 15 }}
            onClick={() => setPage(page + 1)}
            disabled={(page + 1) * rowsPerPage >= count}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AllocationReport);
