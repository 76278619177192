import {
  Card,
  CardContent,
  Grid,
  GridList,
  GridListTile,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { green, red, yellow } from '@material-ui/core/colors';
import DescriptionIcon from '@material-ui/icons/Description';
import { makeStyles } from '@material-ui/styles';
import map from 'lodash/map';
import React from 'react';

const useStyles = makeStyles({
  card: {
    margin: '2px',
    padding: '0px',
    maxHeight: '95px',
  },
  cardContent: {
    padding: '2px',
  },
  sizeSmall: {
    fontSize: 12,
  },
});

// ETL File Listing
const ETLFileList = ({
  files,
  etlType,
  onETLFileClick,
  // If thanos is enabled, we're going to remove the
  // first three items from the list. They will always
  // show as failed.
  thanosEnabled = false,
}) => {
  const classes = useStyles();
  let fileList = Object.assign([], files);

  if (thanosEnabled) {
    fileList.splice(0, 3);
  }

  // Handle Clicking on an Errored File
  const onFileClick = (f, t, m) => {
    if (onETLFileClick !== null) {
      onETLFileClick(f, t, m);
    }
  };

  // Provide a summary tooltip text for the file status
  const fileStatusFor = (f) => {
    if (f.isRepairing) {
      return 'Data is currently repairing';
    }

    if (f.errors.length > 0 || f.warnings.length > 0) {
      return 'Invalid ETL Data: Errors & Warnings Exist';
    }

    return `${f.empty ? 'Invalid ' : 'Valid '}ETL Data Stored`;
  };

  // Provide a tool tip for the file icon
  const toolTipFor = (f) => {
    const tip = fileStatusFor(f);
    const lastModifiedSuffix = `Last Modified: ${f.lastModified}`;

    return (
      <>
        {tip}
        <br />
        <br />
        {lastModifiedSuffix}
      </>
    );
  };

  // Provide the properly styled icon for the file
  const iconFor = (f) => {
    // ETL Entry is repairing
    if (f.isRepairing) {
      return (
        <DescriptionIcon
          style={{ color: yellow[500], cursor: 'pointer' }}
          fontSize="large"
          onClick={() => onFileClick(f, etlType, ['Repairing...'])}
        />
      );
    }

    // Errors and Warnings attached to an ETL File
    if (f.errors.length > 0 || f.warnings.length > 0) {
      return (
        <DescriptionIcon
          style={{ color: red[500], cursor: 'pointer' }}
          fontSize="large"
          onClick={() => onFileClick(f, etlType, f.errors)}
        />
      );
    }

    // ETL File contains empty data
    if (f.empty) {
      return (
        <DescriptionIcon
          style={{ color: red[500], cursor: 'pointer' }}
          fontSize="large"
          onClick={() => onFileClick(f, etlType, [fileStatusFor(f)])}
        />
      );
    }

    // Successfully Created ETL File
    return (
      <DescriptionIcon
        style={{ color: green[500], cursor: 'pointer' }}
        fontSize="large"
        onClick={() => onFileClick(f, etlType, [fileStatusFor(f)])}
      />
    );
  };

  return (
    <GridList cols={10} cellHeight={100} spacing={1}>
      {map(
        fileList,
        (file, key) =>
          file !== null && (
            <GridListTile key={key}>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    spacing={0}
                  >
                    <Grid item>
                      <Typography variant="body2">
                        <b>{file.date.substring(4)}</b>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Tooltip title={toolTipFor(file)} arrow>
                        {iconFor(file)}
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Typography
                        className={classes.sizeSmall}
                        color="textSecondary"
                        gutterBottom
                      >
                        {file.isRepairing ? 'Repairing' : file.size}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </GridListTile>
          ),
      )}
    </GridList>
  );
};

export default ETLFileList;
