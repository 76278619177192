import React, { SyntheticEvent, useState } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import { IconButton } from '@material-ui/core';

interface CopyToClipBoardProps {
  copyText: string;
}

export const CopyToClipBoard = ({copyText}: CopyToClipBoardProps) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleCopy = (e: SyntheticEvent) => {
    e.stopPropagation();
    navigator.clipboard.writeText(copyText)
    setIsCopied(true);
    setInterval(() => setIsCopied(false), 2000);
  };

  return (
    <>
      {isCopied ? (
        <CheckIcon color='primary'/>
      ) : (
        <IconButton size='small' onClick={handleCopy}>
          <FilterNoneIcon fontSize='small'/>
        </IconButton>
      )}
    </>
  );
};
