// react
import React, { FC } from 'react';

// holster
import { Modal, Typography } from '@kubecost-frontend/holster';

// local
import { allocationKey } from '../../services/reports';
import { AllocationReport } from '../../types/allocation';

interface OpenReportDialogProps {
  onClose: () => void;
  open: boolean;
  reports: AllocationReport[];
  selectReport: (report: AllocationReport) => void;
}

const OpenReportDialog: FC<OpenReportDialogProps> = ({
  onClose,
  open,
  reports,
  selectReport,
}) => {
  return (
    <Modal onClose={onClose} open={open} title={'Open report'}>
      <ul>
        {reports.map((report) => (
          <li
            className="cursor-pointer hover:bg-kc-primary/25"
            key={allocationKey(report)}
            onClick={() => selectReport(report)}
          >
            <Typography variant="p">{report.title}</Typography>
          </li>
        ))}
      </ul>
    </Modal>
  );
};

export { OpenReportDialog as default };
