export const ENVS_ENUM = Object.freeze({
  DEV: 'development',
  PROD: 'production',
  TEST: 'test',
});

export const env = process.env.NODE_ENV;
export const version = process.env.REACT_APP_VERSION;

export function isProduction(): boolean {
  return process.env.NODE_ENV === ENVS_ENUM.PROD;
}

export default {
  ENVS_ENUM,
  env,
  isProduction,
  version,
};
