export const windowOptions = [
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Week-to-date', value: 'week' },
  { label: 'Month-to-date', value: 'month' },
  { label: 'Last week', value: 'lastweek' },
  { label: 'Last month', value: 'lastmonth' },
  { label: 'Last 24h', value: '24h' },
  { label: 'Last 48h', value: '48h' },
  { label: 'Last 7 days', value: '7d' },
  { label: 'Last 30 days', value: '30d' },
  { label: 'Last 60 days', value: '60d' },
  { label: 'Last 90 days', value: '90d' },
];
