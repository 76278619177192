import { Grid, Typography, TextField, Button, Switch } from '@material-ui/core';
import React from 'react';
import {} from 'react-router-dom';

export enum SettingDataType {
  Text,
  Button,
  Toggle,
}

export interface DataElement {
  primaryText: string;
  secondaryText: string;
  defaultValue?: string; // number?
  dataSource?: string;
  dataType: SettingDataType;
  buttonText?: string;
  onClick?: any;
  onChange?: any;
  updateState?: any;
  currentState?: any;
  disabled?: boolean;
}

export interface DataElementGrouping {
  dataElements: DataElement[];
  title: string;
  subText: string;
}

export const SingleDataElement = ({
  dataType,
  primaryText,
  secondaryText,
  dataSource,
  defaultValue,
  buttonText,
  onClick,
  updateState,
  currentState,
  disabled
}: DataElement) => {
  const updateSwitch = (e: any) => {
    updateState({
      ...currentState,
      // this needs to be stored as a string to comply with api
      [`${dataSource}`]: String(e.target.checked),
    })
  };
  switch (dataType) {
    case SettingDataType.Text:
      return (
        <Grid spacing={3} style={{ marginTop: '.5em' }} container>
          <Grid item xs={4}>
            <Typography variant="h6">{primaryText}</Typography>
            <Typography variant="subtitle1">{secondaryText}</Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              defaultValue={defaultValue}
              fullWidth
              id="outlined-basic"
              label={primaryText}
              variant="outlined"
              disabled={disabled}
              onChange={(e: any) =>
                updateState({
                  ...currentState,
                  [`${dataSource}`]: e.target.value,
                })
              }
            />
          </Grid>
        </Grid>
      );
    case SettingDataType.Button:
      return (
        <Grid spacing={3} style={{ marginTop: '.5em' }} container>
          <Grid item xs={9}>
            <Typography variant="h6">{primaryText}</Typography>
            <Typography variant="subtitle1">{secondaryText}</Typography>
          </Grid>
          <Grid style={{ textAlign: 'end' }} item xs={3}>
            <Button disabled={disabled} onClick={onClick}>{buttonText}</Button>
          </Grid>
        </Grid>
      );
    case SettingDataType.Toggle:
      return (
        <Grid spacing={3} style={{ marginTop: '.5em' }} container>
          <Grid item xs={9}>
            <Typography variant="h6">{primaryText}</Typography>
            <Typography variant="subtitle1">{secondaryText}</Typography>
          </Grid>
          <Grid style={{ textAlign: 'end' }} item xs={3}>
            <Switch
              disabled={disabled}
              defaultChecked={defaultValue === 'true'}
              onChange={updateSwitch}
              name={dataSource}
              color="primary"
            />
          </Grid>
        </Grid>
      );
  }
};
