// react
import React from 'react';

// holster
import { TableCell, TableRow } from '@kubecost-frontend/holster';

// local
import { toCurrency } from '../../../services/format';
import { useClusters } from '../../../contexts/ClusterConfig';
import { APICloudCostTableDatum } from '../../../types/cloudCosts';

interface TableRowProps extends APICloudCostTableDatum {
  costSuffix: string;
}

const CloudCostRow = ({
  costSuffix,
  credit,
  name,
  kubernetesPercent,
  totalCost,
}: TableRowProps) => {
  const { modelConfig } = useClusters();

  return (
    <TableRow>
      {/* row name */}
      <TableCell align="left">{name}</TableCell>
      <TableCell align="left">
        {`${toCurrency(credit, modelConfig.currencyCode)}${costSuffix}`}
      </TableCell>
      <TableCell align="left">{`${(kubernetesPercent * 100).toFixed(
        1,
      )}%`}</TableCell>
      {/* total cost */}
      <TableCell align="right" style={{ paddingRight: '2em' }}>
        {`${toCurrency(totalCost, modelConfig.currencyCode)}${costSuffix}`}
      </TableCell>
    </TableRow>
  );
};

export { CloudCostRow };
