// react
import React, { FC } from 'react';

// holster
import { Tooltip, Typography } from '@kubecost-frontend/holster';

interface AssetListItemProps {
  tooltip: string;
  value: string;
  icon: React.ReactElement;
}

export const AssetPropertiesItem: FC<AssetListItemProps> = ({
  tooltip,
  value,
  icon,
}: AssetListItemProps): React.ReactElement => (
  <div className="flex items-center">
    <Tooltip content={tooltip}>{icon}</Tooltip>
    <Typography className="ml-3" variant="p">
      {value}
    </Typography>
  </div>
);

AssetPropertiesItem.displayName = 'AssetPropertiesItem';
