// react
import React from 'react';

// mui
import SearchIcon from '@material-ui/icons/Search';
import { IconButton } from '@material-ui/core';

// lodash
import round from 'lodash/round';

// holster
import { TableCell, TableRow } from '@kubecost-frontend/holster';

// local
import { toCurrency } from '../../services/format';
import { TableRowData } from './types';

interface ARDataRowProps {
  currency: string;
  item: TableRowData;
  setActiveRow: (row: any) => void;
}

export const ARDataRow = ({
  item,
  item: {
    name,
    cpuCost,
    externalCost,
    gpuCost,
    loadBalancerCost,
    networkCost,
    pvCost,
    ramCost,
    sharedCost,
    externalBreakdown,
    efficiency,
    totalCost,
  },
  currency,
  setActiveRow,
}: ARDataRowProps) => {
  return (
    <>
      <TableRow>
        <TableCell align="left" style={{ fontWeight: 500 }}>
          <div className="flex" style={{ gap: '1em', alignItems: 'center' }}>
            <div>{name}</div>
            {externalBreakdown && externalBreakdown.length > 0 && (
              <IconButton
                size="small"
                className="ml-4"
                onClick={() => {
                  setActiveRow(item);
                }}
              >
                <SearchIcon />
              </IconButton>
            )}
          </div>
        </TableCell>
        <TableCell align="right" style={{ fontWeight: 500 }}>
          {toCurrency(cpuCost || 0, currency)}
        </TableCell>
        <TableCell align="right" style={{ fontWeight: 500 }}>
          {toCurrency(gpuCost || 0, currency)}
        </TableCell>
        <TableCell align="right" style={{ fontWeight: 500 }}>
          {toCurrency(ramCost || 0, currency)}
        </TableCell>
        <TableCell align="right" style={{ fontWeight: 500 }}>
          {toCurrency(pvCost || 0, currency)}
        </TableCell>
        <TableCell align="right" style={{ fontWeight: 500 }}>
          {toCurrency(networkCost || 0, currency)}
        </TableCell>
        <TableCell align="right" style={{ fontWeight: 500 }}>
          {toCurrency(loadBalancerCost || 0, currency)}
        </TableCell>
        <TableCell align="right" style={{ fontWeight: 500 }}>
          {toCurrency(sharedCost || 0, currency)}
        </TableCell>
        <TableCell align="right" style={{ fontWeight: 500 }}>
          {toCurrency(externalCost || 0, currency)}
        </TableCell>
        <TableCell align="right" style={{ fontWeight: 500 }}>
          {round((efficiency || 0) * 100, 1)}%
        </TableCell>
        <TableCell
          align="right"
          style={{ fontWeight: 500, paddingRight: '2em' }}
        >
          {toCurrency(
            cpuCost +
              gpuCost +
              ramCost +
              pvCost +
              externalCost +
              loadBalancerCost +
              networkCost || 0,
            currency,
          )}
        </TableCell>
      </TableRow>
    </>
  );
};
