import forEach from 'lodash/forEach';
import get from 'lodash/get';
import { Asset, AssetTotals } from '../../types/asset';

// rangeToCumulative takes an AssetSetRange (type: array[AssetSet])
// and accumulates the values into a single AssetSet (type: object)
export function rangeToCumulative(
  assetSetRange: Asset[][],
): Record<string, Asset> {
  if (assetSetRange.length === 0) {
    return {};
  }
  const result: Record<string, Asset> = {};
  const count: Record<string, number> = {};
  const detailsMap: Record<string, Set<string>> = {};

  forEach(assetSetRange, (assetSet) => {
    forEach(assetSet, (asset) => {
      const key = get(asset, 'key', '');
      if (result[key] === undefined) {
        // Copy asset and set defaults
        const resultAsset: Asset = { ...asset };
        resultAsset.name = get(asset, 'name', '');
        resultAsset.key = get(asset, 'key', '');
        resultAsset.type = get(asset, 'type', '');
        resultAsset.clusterID = get(asset, 'clusterID', '');
        resultAsset.credit = get(asset, 'credit', 0);
        resultAsset.adjustment = get(asset, 'adjustment', 0);
        resultAsset.totalCost = get(asset, 'totalCost', 0);
        resultAsset.minutes = get(asset, 'minutes', 0);
        result[key] = resultAsset;
        count[key] = 1;
        detailsMap[key] = new Set(get(asset, 'details', []));
      } else {
        count[key] += 1;
        result[key].adjustment += get(asset, 'adjustment', 0);
        result[key].credit += get(asset, 'credit', 0);
        result[key].totalCost += get(asset, 'totalCost', 0);
        result[key].minutes += get(asset, 'minutes', 0);
        forEach(get(asset, 'details', []), (detail) => {
          detailsMap[key].add(detail);
        });
      }
      result[key].details = Array.from(detailsMap[key]);
    });
  });
  return result;
}

// cumulativeToTotals adds each entry in the given AssetSet (type: object)
// and returns a single Asset (type: object) representing the totals
export function cumulativeToTotals(
  assetSet: Record<string, Asset>,
): AssetTotals {
  const totals: AssetTotals = {
    name: 'Totals',
    providerID: '',
    credit: 0,
    adjustment: 0,
    totalCost: 0,
  };

  forEach(assetSet, (asset) => {
    totals.credit += get(asset, 'credit', 0);
    totals.adjustment += get(asset, 'adjustment', 0);
    totals.totalCost += get(asset, 'totalCost', 0);
  });

  return totals;
}

// rangeToRate takes an AssetSetRange and a rate,
// then returns the cost of each range item at that rate.
export function rangeToRate(assetData: Asset[][], rate: number): Asset[][] {
  return assetData.map((assetSet) => {
    return assetSet.map((asset) => ({
      ...asset,
      totalCost: (get(asset, 'totalCost', 0) * rate) / asset.minutes,
      credit: (get(asset, 'credit', 0) * rate) / asset.minutes,
      adjustment: (get(asset, 'adjustment', 0) * rate) / asset.minutes,
    }));
  });
}

// take in cumulative asset data and return with a rate applied
export function cumulativeToRate(
  cumulativeData: Record<string, Asset>,
  rate: number,
): Record<string, Asset> {
  const response: Record<string, Asset> = {};
  Object.keys(cumulativeData).forEach((key) => {
    const asset = cumulativeData[key];
    response[key] = { ...asset };
    response[key].totalCost =
      (get(asset, 'totalCost', 0) * rate) / asset.minutes;
    response[key].credit = (get(asset, 'credit', 0) * rate) / asset.minutes;
    response[key].adjustment =
      (get(asset, 'adjustment', 0) * rate) / asset.minutes;
  });
  return response;
}

export default {
  rangeToCumulative,
  cumulativeToTotals,
  rangeToRate,
  cumulativeToRate,
};
