import { Typography } from '@kubecost-frontend/holster';
import React from 'react';

/**
 *
 * Section break markers
 */
export interface SectionBreakProps {
  title: string;
}

export const SectionBreak: React.FC<SectionBreakProps> = ({ title }) => {
  return (
    <div
      style={{
        marginBottom: 30,
        marginTop: '2em',
      }}
    >
      <Typography style={{ fontWeight: 'bold', color: '#023927' }} variant="h5">
        {title}
      </Typography>
    </div>
  );
};
