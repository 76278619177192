import { useMutation, useQuery, useQueryClient } from 'react-query';
import { RequestSizingParams, RequestSizingResponse } from './types';
import { useAPIClient } from '../../services/APIClient';

const recommendationsQueryKey = (params: RequestSizingParams) => [
  'requestSizing',
  'recommendations',
  { ...params },
];

const queryRecommendationsApi = async (rqParams: RequestSizingParams) => {
  const APIClient = useAPIClient();
  return await APIClient.get<RequestSizingResponse>(
    '/savings/requestSizingV2',
    {
      params: {
        ...rqParams,
      },
    },
  );
};

export const useGetRecommendations = (rqParams: RequestSizingParams) => {
  const APIClient = useAPIClient();
  const queryClient = useQueryClient();
  const { data, ...args } = useQuery(
    [recommendationsQueryKey(rqParams), { useErrorBoundary: true }],
    () => queryRecommendationsApi(rqParams),
  );

  const mutate = useMutation(
    (payload) => APIClient.post('/savings/requestSizingV2', payload),
    {
      onSuccess() {
        queryClient.invalidateQueries(recommendationsQueryKey(rqParams));
      },
    },
  );
  return {
    data,
    updateRecommendations: mutate,
    recommendations: data?.data,
    ...args,
  };
};
