import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
} from '@kubecost-frontend/holster';
import {
  Alert,
  BudgetAlert,
  ClusterHealthAlert,
  DiagnosticAlert,
  EfficiencyAlert,
  RecurringUpdate,
  SpendChangeAlert,
} from '../../../services/alerts';
// MUI icons
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CachedIcon from '@material-ui/icons/Cached';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import { InspectLink } from './InspectLink';

export const AlertsInspectCard = ({
  alerts,
  resourceName,
}: {
  alerts: Alert[];
  resourceName: string;
}) => {
  // as of now, we are not overly concerned with health/diagnostic as it pertains to
  // the inspect page.
  const alertTypeIconMap = {
    budget: {
      icon: <AttachMoneyIcon />,
      displayName: 'Allocation Budget',
      format: ({ window, threshold }: BudgetAlert) =>
        `Every ${window} ${threshold ? `& > $${threshold}` : ''}`,
    },
    recurringUpdate: {
      icon: <CachedIcon />,
      displayName: 'Allocation Recurring Update',
      format: ({ window }: RecurringUpdate) => `Every ${window}`,
    },
    diagnostic: {
      icon: <CachedIcon />,
      displayName: 'Diagnostic',
      format: ({ window }: DiagnosticAlert) =>
        `Event impacts uptime - ${window}`,
    },
    '': {
      icon: <CachedIcon />,
      displayName: 'Unknown',
      format: ({ window }: Alert) => `Every ${window}`,
    },
    efficiency: {
      icon: <AssessmentIcon />,
      displayName: 'Allocation Efficieny',
      format: ({
        efficiencyThreshold,
        window,
        spendThreshold,
      }: EfficiencyAlert) =>
        `Every ${window} if Efficiency < ${efficiencyThreshold * 100}% ${
          spendThreshold ? `and Total Cost > $${spendThreshold}` : ''
        } `,
    },
    health: {
      icon: <CachedIcon />,
      displayName: 'Health',
      format: ({ threshold }: ClusterHealthAlert) =>
        `Health changes by ${threshold}`,
    },
    spendChange: {
      icon: <ShowChartIcon />,
      displayName: 'Spend Change',
      format: ({
        relativeThreshold,
        baselineWindow,
        window,
      }: SpendChangeAlert) =>
        `Cost over last ${window} > ${
          relativeThreshold * 100
        }% change relative to previous ${baselineWindow}`,
    },
    assetBudget: {
      icon: <AttachMoneyIcon />,
      displayName: 'Asset Budget',
      format: ({ window, threshold }: BudgetAlert) =>
        `Every ${window} ${threshold ? `& > $${threshold}` : ''}`,
    },
    cloudReport: {
      icon: <CachedIcon />,
      displayName: 'Asset Recurring Update',
      format: ({ window }: RecurringUpdate) => `Every ${window}`,
    },
  };

  if (!alerts || !alerts.length) {
    return (
      <div className="h-full flex items-center justify-center">
        <div>
          <Typography variant="h5">
            No alerts for <strong>{resourceName}</strong>
          </Typography>
          <div className="flex justify-center">
            <InspectLink text="All alerts" href="/alerts" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex grow flex-col justify-between">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>TYPE</TableCell>
            <TableCell>WINDOW</TableCell>
            <TableCell>AGGREGATION</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {alerts.map((item: Alert) => (
            <TableRow key={item.type}>
              <TableCell>{alertTypeIconMap[item.type].displayName}</TableCell>
              <TableCell>{item.window}</TableCell>
              <TableCell>{item.aggregation}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className="p-4 bg-kc-grey-100">
        <InspectLink text="All alerts" href="/alerts" />
      </div>
    </div>
  );
};
