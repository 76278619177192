import { Typography } from '@kubecost-frontend/holster';
import { get, round } from 'lodash';
import React from 'react';

interface AdvancedMetricsProps {
  parameters: any;
}

export const AdvancedMetrics = ({ parameters }: AdvancedMetricsProps) => (
  <div style={{ padding: '1em' }}>
    <Typography className="font-bold" variant="p-large">Static Resources</Typography>
    <Typography variant="p">
      Sum of resources allocated to pods not controlled by DaemonSets.
    </Typography>
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 3fr', marginTop: '.5em' }}>
      <div>
        <Typography className="font-bold" variant="p">
          Static VCPUs
        </Typography>
        <Typography variant="p">
          {round(get(parameters, 'staticVCPUs', 0), 4)}
        </Typography>
      </div>
      <div>
        <Typography className="font-bold" variant="p">
          Static RAM (GB)
        </Typography>
        <Typography variant="p">
          {round(get(parameters, 'staticRAMGB', 0), 4)}
        </Typography>
      </div>
    </div>
    <Typography style={{ marginTop: '1em'}} className="font-bold" variant="p-large">DaemonSet Resources</Typography>
    <Typography variant="p">
      Sum of resources allocated to pods controlled by DaemonSets.
    </Typography>
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 3fr', marginTop: '.5em' }}>
      <div>
        <Typography className="font-bold" variant="p">
          DaemonSet VCPUs
        </Typography>
        <Typography variant="p">
          {round(get(parameters, 'daemonSetVCPUs', 0), 4)}
        </Typography>
      </div>
      <div>
        <Typography className="font-bold" variant="p">
          DaemonSet RAM (GB)
        </Typography>
        <Typography variant="p">
          {round(get(parameters, 'daemonSetRAMGB', 0), 4)}
        </Typography>
      </div>
    </div>
    <Typography style={{ marginTop: '1em'}}  className="font-bold" variant="p-large">Max Pod Resources</Typography>
    <Typography variant="p">
      Largest resource values for any single pod in your cluster.
    </Typography>
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 3fr', marginTop: '.5em' }}>
      <div>
        <Typography className="font-bold" variant="p">
          Max VCPUs
        </Typography>
        <Typography variant="p">
          {round(get(parameters, 'maxPodVCPUs', 0), 4)}
        </Typography>
      </div>
      <div>
        <Typography className="font-bold" variant="p">
          Max RAM (GB)
        </Typography>
        <Typography variant="p">
          {round(get(parameters, 'maxPodRAMGB', 0), 4)}
        </Typography>
      </div>
    </div>
  </div>
);
