import React, { useState } from 'react';
import round from 'lodash/round';

import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import CostIcon from '@material-ui/icons/AttachMoney';
import CPUIcon from '@material-ui/icons/DeveloperBoard';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionActions';
import green from '@material-ui/core/colors/green';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import RAMIcon from '@material-ui/icons/Memory';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TabPanel from '@material-ui/core/TabPanel';

import { toCurrency } from '../../services/format';
import {
  SizingResponse,
  ClusterSize,
  NodePool,
  clusterRecommendationToGcloud,
  defaultSpotTaint,
  defaultSpotTolerationKey,
  defaultSpotTolerationValue,
  defaultSpotTolerationEffect,
} from '../../services/savings/spotclustersizing';
import { ChecklistResult } from '../../services/savings/spot';

const useStyles = makeStyles({
  description: {
    padding: '24px 36px',
    marginBottom: 20,
  },
  flexdiv: {
    display: 'flex',
  },
  card: {
    minWidth: 400,
    margin: '12px 12px 0 0',
    display: 'flex',
    flexFlow: 'column',
  },
  cardContent: {
    flexGrow: 1,
  },
  chip: {
    marginRight: 8,
  },
});

function tolerationCommands(
  spotReadyChecklists: ChecklistResult[],
  tolerationKey: string,
  tolerationValue: string,
  tolerationEffect: string,
): string {
  // Kubernetes documentation on patching tolerations
  // https://kubernetes.io/docs/tasks/manage-kubernetes-objects/update-api-object-kubectl-patch/
  // TODO: Editing tolerations with patch does an OVERWRITE. Consider pulling current tolerations
  // and appending.
  let command = `PATCH=$(cat << EndOfMessage
spec:
  template:
    spec:
      tolerations:
      - key: "${tolerationKey}"
        operator: Equal
        value: "${tolerationValue}"
        effect: "${tolerationEffect}"
EndOfMessage
)
`;

  for (const checklist of spotReadyChecklists) {
    command =
      command +
      `
kubectl patch ${checklist.kind} \\
    ${checklist.name} \\
    --namespace ${checklist.namespace} \\
    --patch "$\{PATCH\}"
`;
  }

  return command;
}

function runnableCommand(
  explanation: React.ReactElement,
  command: string,
): React.ReactElement {
  const explanationXS = 4;
  const commandXS = 8;

  return (
    <Grid container item>
      <Grid item xs={explanationXS}>
        {explanation}
      </Grid>
      <Grid item xs={commandXS}>
        <Box
          component="div"
          style={{ overflow: 'visible', background: 'black' }}
        >
          <Typography
            style={{
              whiteSpace: 'pre-wrap',
              fontFamily: 'monospace',
              color: 'white',
              fontSize: 10,
              marginBottom: 2,
            }}
          >
            {command}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

function gkeRunnableCommands(
  checklists: ChecklistResult[],
  spotPool: NodePool,
  onDemandPool: NodePool,
): React.ReactElement {
  return (
    <Grid container>
      {runnableCommand(
        <div>
          <Typography style={{ fontWeight: 600 }}>
            Step 0: Add toleration to spot-ready workloads
          </Typography>
          <Typography>
            WARNING: This will overwrite other tolerations. If you have multiple
            tolerations, rewrite this command.
          </Typography>
        </div>,
        tolerationCommands(
          checklists,
          defaultSpotTolerationKey,
          defaultSpotTolerationValue,
          defaultSpotTolerationEffect,
        ),
      )}
      {runnableCommand(
        <Typography style={{ fontWeight: 600 }}>
          Step 1: gcloud configuration
        </Typography>,
        `export GKE_REGION=<your-cluster-region-here>
export GKE_PROJECT=<your-gcp-project-here>
export GKE_CLUSTER=<your-gke-cluster-name-here>`,
      )}
      {runnableCommand(
        <Typography style={{ fontWeight: 600 }}>
          Step 2: Create spot pool
        </Typography>,
        clusterRecommendationToGcloud(
          spotPool,
          true,
          defaultSpotTaint,
          'spot-pool',
        ),
      )}
      {runnableCommand(
        <Typography style={{ fontWeight: 600 }}>
          Step 3: Create on-demand pool
        </Typography>,
        clusterRecommendationToGcloud(
          onDemandPool,
          false,
          '',
          'on-demand-pool',
        ),
      )}
      {runnableCommand(
        <Typography style={{ fontWeight: 600 }}>
          Step 4: List node pools
        </Typography>,
        `gcloud container node-pools \\
  list \\
  --project $\{GKE_PROJECT\} \\
  --region $\{GKE_REGION\} \\
  --cluster $\{GKE_CLUSTER\}`,

        // Example output:
        /*
→ gcloud beta container node-pools \
                    list \
  --project ${GKE_PROJECT} \
  --region ${GKE_REGION} \
  --cluster ${GKE_CLUSTER}
NAME            MACHINE_TYPE   DISK_SIZE_GB  NODE_VERSION
default-pool    e2-medium      100           1.20.9-gke.701
ram             e2-standard-2  100           1.20.9-gke.701
spot-pool       n1-standard-2  100           1.20.9-gke.1001
on-demand-pool  n1-standard-4  100           1.20.9-gke.1001
            */
      )}
      {runnableCommand(
        <Typography style={{ fontWeight: 600 }}>
          Step 5: Delete old node pools
        </Typography>,
        `gcloud container node-pools \\
  delete \\
  <old-node-pool-name-here> \\
  --project $\{GKE_PROJECT\} \\
  --region $\{GKE_REGION\} \\
  --cluster $\{GKE_CLUSTER\}`,

        // Example:
        /*
→ gcloud container node-pools \
    delete \
    "default-pool" \
    --project ${GKE_PROJECT} \
    --region ${GKE_REGION} \
    --cluster ${GKE_CLUSTER}
                */
      )}
    </Grid>
  );
}

function runnableCommands(
  checklists: ChecklistResult[],
  spotPool: NodePool,
  onDemandPool: NodePool,
): React.ReactElement {
  const [dialogOpen, setDialogOpen] = useState(false);

  const [tabsValue, setTabsValue] = useState(0);

  const handleClickOpen = () => {
    setDialogOpen(true);
  };
  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <div style={{ marginTop: 12 }}>
      <Button onClick={handleClickOpen} variant="outlined" color="primary">
        Learn how to implement this recommendation
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        maxWidth={'md'}
        fullWidth={true}
      >
        <DialogTitle>
          <Typography>
            Commands to implement the sizing recommendation
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{ position: 'absolute', right: 1, top: 1 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Tabs
            value={tabsValue}
            onChange={(event, newValue) => {
              setTabsValue(newValue);
            }}
          >
            <Tab label="GKE" />
            <Tab label="EKS" />
          </Tabs>
          {tabsValue === 0 &&
            gkeRunnableCommands(checklists, spotPool, onDemandPool)}
          {tabsValue === 1 && (
            <Typography>
              Generation of commands for EKS clusters is not yet supported.
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

function nodePoolCard(recommendation: ClusterSize): React.ReactElement {
  const classes = useStyles();

  // Pools[0] because the current expectation is that only 1 type of node (and
  // therefore only 1 node pool) will be recommended by the API. This will have
  // to be updated when multi-type recommendations are enabled for spot cluster
  // sizing.
  const nodePool = recommendation.pools[0];

  return (
    <div>
      <TableContainer style={{ marginBottom: 12 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Node count
              </TableCell>
              <TableCell align="right">{nodePool.count}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                CPU
              </TableCell>
              <TableCell align="right">
                {round(nodePool.totalVCPUs, 2)} VCPUs (
                {round(100 * recommendation.utilizationVCPUs, 1)}% utilized)
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                RAM
              </TableCell>
              <TableCell align="right">
                {round(recommendation.totalRAMGB, 2)} GB (
                {round(100 * recommendation.utilizationRAMGB, 1)}% utilized)
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Chip label={nodePool.count} size="small" className={classes.chip} />
          <Typography gutterBottom>{nodePool.type.name}</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ paddingTop: 0, paddingBottom: 0 }}>
          <List dense>
            <ListItem>
              <ListItemIcon>
                <CPUIcon />
              </ListItemIcon>
              <ListItemText
                primary={`${round(nodePool.type.vCPUs, 2)} VCPUs ea.`}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <RAMIcon />
              </ListItemIcon>
              <ListItemText
                primary={`${round(nodePool.type.RAMGB, 2)} RAM (GB) ea.`}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CostIcon />
              </ListItemIcon>
              <ListItemText
                primary={`${round(
                  nodePool.totalMonthlyCost / nodePool.count,
                  2,
                )} $/mo ea.`}
              />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

interface SizingCardProps {
  sizingResponse: SizingResponse;
  spotReadyChecklists: ChecklistResult[];
  currencyCode: string;
}

function SpotChecklistSizingCard({
  sizingResponse,
  spotReadyChecklists,
  currencyCode,
}: SizingCardProps): React.ReactElement {
  const classes = useStyles();

  if (sizingResponse.responseKind === 'empty sizing') {
    return (
      <div>
        <Typography variant="h6">Recommended cluster configuration</Typography>
        <Typography>
          No cluster sizing recommendation is available because none of your
          workloads are spot-ready. Consult the Spot Checklist to see why each
          workload is not considered spot-ready.
        </Typography>
      </div>
    );
  }

  return (
    <div>
      <Typography variant="h6">Recommended cluster configuration</Typography>
      <Typography variant="h6" style={{ color: green[700] }}>
        {toCurrency(sizingResponse.monthlyClusterCostsAfter, currencyCode)}/mo
      </Typography>
      <Typography>
        Compared to current:{' '}
        {toCurrency(sizingResponse.monthlySavings, currencyCode)} savings (
        {round(
          (sizingResponse.monthlySavings /
            sizingResponse.monthlyClusterCostsBefore) *
            100,
          1,
        )}
        %)
      </Typography>
      <div className={classes.flexdiv}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography>Spot node recommendation</Typography>
            {nodePoolCard(
              sizingResponse.spotClusterSizing.recommendations['single'],
            )}
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography>On-demand (regular) node recommendation</Typography>
            {nodePoolCard(
              sizingResponse.nonSpotClusterSizing.recommendations['single'],
            )}
          </CardContent>
        </Card>
      </div>
      {runnableCommands(
        spotReadyChecklists,
        sizingResponse.spotClusterSizing.recommendations['single'].pools[0],
        sizingResponse.nonSpotClusterSizing.recommendations['single'].pools[0],
      )}
    </div>
  );
}

export default SpotChecklistSizingCard;
