// react
import React, { FC, useEffect, useState } from 'react';

// mui
import Popover from '@material-ui/core/Popover';

// holster
import { Button, Chip, Input, Typography } from '@kubecost-frontend/holster';

// local
import { PlusIcon } from '../assets/images';

interface AggregateByControlProps {
  aggregateBy: string[];
  setAggregateBy: (agg: string[]) => void;
}

const AggregateByControl: FC<AggregateByControlProps> = ({
  aggregateBy,
  setAggregateBy,
}) => {
  const [aggOptions, setAggOptions] = useState([
    { display: 'Account', value: 'account' },
    { display: 'Category', value: 'category' },
    { display: 'Cluster', value: 'cluster' },
    { display: 'Project', value: 'project' },
    { display: 'Provider', value: 'provider' },
    { display: 'Provider ID', value: 'providerid' },
    { display: 'Service', value: 'service' },
    { display: 'Type', value: 'type' },
    { display: 'Unaggregated', value: 'unaggregated' },
  ]);

  const [anchorEl, setAnchorEl] = useState<EventTarget & Element>();
  const [labelValue, setLabelValue] = useState('');
  const [mode, setMode] = useState<'single' | 'multi'>(
    aggregateBy.length > 1 ? 'multi' : 'single',
  );

  const col1Options = aggOptions.slice(0, Math.floor(aggOptions.length / 2));
  const col2Options = aggOptions.slice(Math.floor(aggOptions.length / 2));
  const open = Boolean(anchorEl);

  // if we come into the page with label aggregations, make them accessible in the popover
  useEffect(() => {
    const extraOpts = aggregateBy.map((agg) => ({
      display: `Label: ${agg.slice(6)}`,
      value: agg,
    }));
    setAggOptions((prev) => [
      ...prev,
      ...extraOpts.filter((opt) => !prev.find((p) => p.value === opt.value)),
    ]);
  }, []);

  const handleSelectAggOption = (agg: string) => {
    if (mode === 'single') {
      //TODO: Post Kubecon come back and clean this up.
      setAggregateBy([agg]);
      setAnchorEl(undefined);
    } else if (mode === 'multi') {
      if (aggregateBy?.includes(agg)) {
        const maybeAgg = aggregateBy.filter((f) => f !== agg);
        setAggregateBy(maybeAgg);
      } else {
        setAggregateBy([...aggregateBy, agg]);
      }
    }
  };

  const handleAddLabel = (agg: string) => {
    //TODO: Post Kubecon come back and clean this up.
    if (mode === 'single') {
      setAggregateBy([agg]);

      setAnchorEl(undefined);
    } else if (mode === 'multi') {
      if (!aggregateBy.includes(agg)) {
        setAggregateBy([...aggregateBy, agg]);
        setAnchorEl(undefined);
      }
    }
  };

  return (
    <>
      <Button
        variant="default"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        className="mr-3"
      >
        Aggregate By
      </Button>
      {/* See Issue #1198
      https://github.com/kubecost/cost-analyzer-frontend/issues/1198 */}
      {/* {aggregateBy.map((agg) => (
        <Chip
          className="mr-3"
          style={{ fontSize: 14 }}
          color="default"
          label={
            aggOptions.find((a) => a.value === agg)?.display || `Label: ${agg}`
          }
        />
      ))} */}
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(undefined);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          className: 'p-6',
        }}
      >
        <div className="flex">
          <div className="flex flex-col">
            <Typography
              variant="p"
              className={`mb-5 cursor-pointer mr-6 ${
                mode === 'single' ? 'text-kc-link' : ''
              }`}
              onClick={() => setMode('single')}
            >
              SINGLE AGGREGATION
            </Typography>
            {col1Options.map((opt) => (
              <Typography
                key={opt.value}
                variant="p"
                className={`mr-6 p-2 hover:bg-kc-success-light rounded cursor-pointer ${
                  aggregateBy.includes(opt.value) ? 'text-kc-link' : ''
                }`}
                onClick={() => handleSelectAggOption(opt.value)}
              >
                {opt.display}
              </Typography>
            ))}
          </div>
          <div className="flex flex-col">
            <Typography
              variant="p"
              className={`mb-5 cursor-pointer ${
                mode === 'multi' ? 'text-kc-link' : ''
              }`}
              onClick={() => setMode('multi')}
            >
              MULTI AGGREGATION
            </Typography>
            {col2Options.map((opt) => (
              <Typography
                key={opt.value}
                variant="p"
                className={`p-2 hover:bg-kc-success-light rounded cursor-pointer ${
                  aggregateBy.includes(opt.value) ? 'text-kc-link' : ''
                }`}
                onClick={() => handleSelectAggOption(opt.value)}
              >
                {opt.display}
              </Typography>
            ))}
          </div>
        </div>
        <Input
          label="Label"
          value={labelValue}
          onChange={(e) => setLabelValue(e.target.value)}
        />
        <Button
          variant="default"
          className="h-[35px] ml-2 translate-y-[2px]"
          onClick={() => {
            const newAgg = `label:${labelValue}`;
            if (!aggOptions.find((opt) => opt.value === newAgg)) {
              setAggOptions((prev) => [
                ...prev,
                {
                  display: `Label: ${labelValue}`,
                  value: newAgg,
                },
              ]);
            }
            handleAddLabel(newAgg);
            setLabelValue('');
          }}
        >
          <PlusIcon />
        </Button>
      </Popover>
    </>
  );
};

export default AggregateByControl;
