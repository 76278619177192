import { Button, Menu, MenuItem } from '@material-ui/core';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

type ReportActionMenuProps = {
  readonly?: boolean;
  deleteClicked: () => void;
};

const ReportActionMenu = ({
  deleteClicked,
  readonly = false,
}: ReportActionMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpenActionMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        data-testid="menu-button"
        id="action-positioned-button"
        onClick={handleOpenActionMenu}
        aria-controls={open ? 'actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        ...
      </Button>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        onClose={handleClose}
      >
        <Tooltip
          title={
            readonly ? 'Only admin or editor users may delete reports' : ''
          }
        >
          <span>
            <MenuItem disabled={readonly} onClick={deleteClicked}>
              Delete
            </MenuItem>
          </span>
        </Tooltip>
      </Menu>
    </>
  );
};

export default ReportActionMenu;
