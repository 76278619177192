import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/styles';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import sortBy from 'lodash/sortBy';
import toArray from 'lodash/toArray';
import trim from 'lodash/trim';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Alerts from '../../components/Alerts';
import clusterService from '../../services/cluster';
import model from '../../services/model';
import { toVerboseTimeRange, lowerFirst } from '../../services/format';
import {
  deleteAllocationReport,
  listAllocationReports,
  saveAllocationReport,
} from '../../services/reports';
import Analytics from '../../services/analytics';
import ConfigService from '../../services/config';
import logger from '../../services/logger';
import {
  cumulativeToTotals,
  filterIdle,
  rangeToCumulative,
  AllocationTotals,
} from './allocation';
import AllocationReport from './AllocationReport';
import Controls from './Controls';
import Subtitle from './Subtitle';
import DetailsDialog from './DetailsDialog';
import DiagnosticsChecker from '../../components/DiagnosticsChecker';
import {
  AllocationProperties,
  AllocationReport as Report,
  Allocation,
} from '../../types/allocation';
import { HelpIconLink } from '../../components/HelpIcon';
import { useClusters } from '../../contexts/ClusterConfig';
import { IdleCostAction, useIdleViewControl } from '../AllocationNew/hooks';

// regex to detect tier-related query warnings
const tierWarningRegex =
  /Requesting (.+) of data. Tier\[(.+)\] only supports up to (.+) of data/g;

// control options
const windowOptions = [
  { name: 'Today', value: 'today' },
  { name: 'Yesterday', value: 'yesterday' },
  { name: 'Week-to-date', value: 'week' },
  { name: 'Month-to-date', value: 'month' },
  { name: 'Last week', value: 'lastweek' },
  { name: 'Last month', value: 'lastmonth' },
  { name: 'Last 24h', value: '24h' },
  { name: 'Last 48h', value: '48h' },
  { name: 'Last 7 days', value: '7d' },
  { name: 'Last 30 days', value: '30d' },
  { name: 'Last 60 days', value: '60d' },
  { name: 'Last 90 days', value: '90d' },
];

const rateOptions = [
  { name: 'Cumulative Cost', value: 'cumulative' },
  { name: 'Monthly Rate', value: 'monthly' },
  { name: 'Daily Rate', value: 'daily' },
  { name: 'Hourly Rate', value: 'hourly' },
];

const idleOptions = [
  { name: 'Hide', value: 'hide' },
  { name: 'Separate', value: 'separate' },
  { name: 'Share By Cluster', value: 'shareByCluster' },
  { name: 'Share By Node', value: 'shareByNode' },
];

const chartDisplayOptions = [
  { name: 'Cost', value: 'category' },
  { name: 'Cost over time', value: 'series' },
  { name: 'Efficiency over time', value: 'efficiency' },
  { name: 'Proportional cost', value: 'percentage' },
  { name: 'Cost Treemap', value: 'treemap' },
];

const shareSplitOptions = [
  { name: 'Share evenly', value: 'even' },
  { name: 'Share weighted by cost', value: 'weighted' },
];

// drilldown compatible aggregations and exempt rows
const drillDownCompatible = [
  'pod',
  'controller',
  'cluster',
  'namespace',
  'controllerkind',
  'node',
  'service',
  'department',
  'environment',
  'owner',
  'product',
  'team',
];

const drillDownExemptRows = [
  '__idle__',
  '__unmounted__',
  'Unmounted PVs',
  'Undistributable idle',
];

export const unwrappedLabels = (labels?: Array<string>): string | null => {
  if (!labels) return null;
  if (!Array.isArray(labels)) return null;
  return labels.sort().join(',');
};

const useStyles = makeStyles({
  controlsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  reportHeader: {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
  },
});

const AllocationPage = () => {
  const classes = useStyles();

  // URL data: router location, search params, etc.
  const routerLocation = useLocation();
  const searchParams = new URLSearchParams(routerLocation.search);
  const navigate = useNavigate();

  // context
  const { modelConfig } = useClusters();

  // Allocation data state
  const [allocationData, setAllocationData] = useState<any[]>([]);
  const [originalAllocationData, setOriginalAllocationData] = useState<
    Allocation[][]
  >([]);
  const [cumulativeData, setCumulativeData] = useState<Allocation[]>([]);
  const [totalData, setTotalData] = useState<AllocationTotals | null>(null);

  // Form state, which controls form elements, but not the report itself. On
  // certain actions, the form state may flow into the report state.
  const [window, setWindow] = useState(windowOptions[0].value);
  const [aggregateBy, setAggregateBy] = useState<string[]>([]);
  const [chartDisplay, setChartDisplay] = useState(
    chartDisplayOptions[0].value,
  );
  const [filters, setFilters] = useState<{ property: string; value: string }[]>(
    [],
  );
  const [shareTenancyCosts, setShareTenancyCosts] = useState(true);
  const [shareSplit, setShareSplit] = useState('weighted');
  const [rate, setRate] = useState(rateOptions[0].value);

  const [defaultSharedOverhead, setDefaultSharedOverhead] = useState(0.0);
  const [customSharedOverhead, setCustomSharedOverhead] =
    useState<number | null>(null);

  const [defaultShareNamespaces, setDefaultShareNamespaces] = useState<
    string[]
  >([]);
  const [customShareNamespaces, setCustomShareNamespaces] =
    useState<string[] | null>(null);

  const [defaultShareLabels, setDefaultShareLabels] = useState<string[]>([]);
  const [customShareLabels, setCustomShareLabels] = useState<string[] | null>(
    [],
  );

  // Context is used for drill-down; each drill-down gets pushed onto the
  // context stack. Clearing resets to an empty stack. Using a breadcrumb
  // should pop everything above that on the stack.
  const [context, setContext] = useState<
    { property: string; value: string; key: string; name: string }[]
  >([]);

  // page and settings state
  const [init, setInit] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [alerts, setAlerts] = useState<
    {
      primary: string;
      secondary: string | ReactElement;
      level: 'success' | 'error' | 'warning' | 'info';
    }[]
  >([]);
  const [snackbar, setSnackbar] = useState<{
    message?: string;
    severity?: 'success' | 'info' | 'warning' | 'error';
  }>({});

  // Report state, including current report and saved options
  const [title, setTitle] = useState('');
  const [titleField, setTitleField] = useState('');
  const [savedReports, setSavedReports] = useState<Report[]>([]);

  // Setting details to null closes the details dialog. Setting it to an
  // object describing a controller opens it with that state.
  const [details, setDetails] = useState<null | AllocationProperties>(null);

  const { idle, idleBy, idleByNode, shareIdle, dispatch } =
    useIdleViewControl();

  const handleSetIdle = (nextidle: string) => {
    searchParams.set('idle', nextidle);
    navigate({
      search: `?${searchParams.toString()}`,
    });
    dispatch(nextidle as IdleCostAction);
  };

  const drillDownForRow = useCallback(
    (row: {
      name: string;
      cluster: string;
      node: string;
      totalCost: number;
      externalCost: number;
      namespace: string;
      controllerkind: string;
      service: string;
      department: string;
      environment: string;
      owner: string;
      product: string;
      team: string;
    }) => {
      return () => {
        if (!canDrillDown(row)) {
          return;
        }

        if (aggregateBy[0] === 'pod') {
          const pod = row.name;
          const { cluster, node } = row;
          let namespace = '';
          let controllerKind = '';
          let controller = '';

          forEach(context, (ctx) => {
            if (ctx.key === 'namespace') {
              namespace = ctx.value;
            } else if (ctx.key === 'controller') {
              const tokens = ctx.value.split(':');
              if (tokens.length === 2) {
                controllerKind = tokens[0];
                controller = tokens[1];
              } else {
                controller = ctx.value;
              }
            }
          });

          const props: AllocationProperties = {
            cluster,
            node,
            controller,
            controllerKind,
            namespace,
            pod,
            container: '',
            services: [],
            providerID: '',
            labels: {},
            annotations: {},
          };
          openDetails(props);
        }

        if (aggregateBy[0] === 'controller') {
          const ctx = [
            ...context,
            {
              property: 'Controller',
              value: row.name,
              name: row.name,
              key: 'controller',
            },
          ];

          searchParams.set('agg', 'pod');
          searchParams.set('context', btoa(JSON.stringify(ctx)));
          navigate({
            search: `?${searchParams.toString()}`,
          });
        }

        if (aggregateBy[0] === 'cluster') {
          let cluster = get(row, 'cluster', '');
          const clusterTokens = get(row, 'cluster', '').split('/');
          if (clusterTokens.length > 0) {
            cluster = clusterTokens[0];
          }

          const ctx = [
            ...context,
            {
              property: 'Cluster',
              value: cluster,
              name: cluster,
              key: 'cluster',
            },
          ];

          searchParams.set('agg', 'namespace');
          searchParams.set('context', btoa(JSON.stringify(ctx)));
          navigate({
            search: `?${searchParams.toString()}`,
          });
        }

        if (aggregateBy[0] === 'namespace') {
          const ctx = [
            ...context,
            {
              property: 'Namespace',
              value: row.namespace,
              name: row.namespace,
              key: 'namespace',
            },
          ];

          searchParams.set('agg', 'controller');
          searchParams.set('context', btoa(JSON.stringify(ctx)));
          navigate({
            search: `?${searchParams.toString()}`,
          });
        }

        if (aggregateBy[0] === 'controllerkind') {
          const ctx = [
            ...context,
            {
              property: 'Controller Kind',
              value: row.controllerkind,
              name: row.controllerkind,
              key: 'controllerkind',
            },
          ];

          searchParams.set('agg', 'controller');
          searchParams.set('context', btoa(JSON.stringify(ctx)));
          navigate({
            search: `?${searchParams.toString()}`,
          });
        }

        if (aggregateBy[0] === 'node') {
          const ctx = [
            ...context,
            {
              property: 'Node',
              value: row.node,
              name: row.node,
              key: 'node',
            },
          ];
          searchParams.set('agg', 'controller');
          searchParams.set('context', btoa(JSON.stringify(ctx)));
          navigate({
            search: `?${searchParams.toString()}`,
          });
        }

        if (aggregateBy[0] === 'service') {
          const ctx = [
            ...context,
            {
              property: 'Service',
              value: row.service,
              name: row.service,
              key: 'service',
            },
          ];
          searchParams.set('agg', 'pod');
          searchParams.set('context', btoa(JSON.stringify(ctx)));
          navigate({
            search: `?${searchParams.toString()}`,
          });
        }

        if (aggregateBy[0].startsWith('label')) {
          const labelString = aggregateBy[0];
          const rowValue = row[labelString];
          const ctx = [
            ...context,
            {
              property: 'label',
              // API expects app:cost-analyzer as opposed to app=cost-analyzer
              value: rowValue.replace('=', ':'),
              name: rowValue,
              key: labelString,
            },
          ];

          searchParams.set('agg', 'controller');
          searchParams.set('context', btoa(JSON.stringify(ctx)));
          navigate({
            search: `?${searchParams.toString()}`,
          });
        }

        if (aggregateBy[0] === 'department') {
          const ctx = [
            ...context,
            {
              property: 'department',
              value: row.department,
              name: row.department,
              key: 'department',
            },
          ];

          searchParams.set('agg', 'controller');
          searchParams.set('context', btoa(JSON.stringify(ctx)));
          navigate({
            search: `?${searchParams.toString()}`,
          });
        }

        if (aggregateBy[0] === 'environment') {
          const ctx = [
            ...context,
            {
              property: 'environment',
              value: row.environment,
              name: row.environment,
              key: 'environment',
            },
          ];

          searchParams.set('agg', 'controller');
          searchParams.set('context', btoa(JSON.stringify(ctx)));
          navigate({
            search: `?${searchParams.toString()}`,
          });
        }

        if (aggregateBy[0] === 'owner') {
          const ctx = [
            ...context,
            {
              property: 'owner',
              value: row.owner,
              name: row.owner,
              key: 'owner',
            },
          ];

          searchParams.set('agg', 'controller');
          searchParams.set('context', btoa(JSON.stringify(ctx)));
          navigate({
            search: `?${searchParams.toString()}`,
          });
        }

        if (aggregateBy[0] === 'product') {
          const ctx = [
            ...context,
            {
              property: 'product',
              value: row.product,
              name: row.product,
              key: 'product',
            },
          ];

          searchParams.set('agg', 'controller');
          searchParams.set('context', btoa(JSON.stringify(ctx)));
          navigate({
            search: `?${searchParams.toString()}`,
          });
        }

        if (aggregateBy[0] === 'team') {
          const ctx = [
            ...context,
            {
              property: 'team',
              value: row.team,
              name: row.team,
              key: 'team',
            },
          ];

          searchParams.set('agg', 'controller');
          searchParams.set('context', btoa(JSON.stringify(ctx)));
          navigate({
            search: `?${searchParams.toString()}`,
          });
        }
      };
    },
    [aggregateBy, context, searchParams],
  );

  // Set parameters from the given report
  const selectReport = useCallback((report: Report) => {
    searchParams.set('title', report.title);
    searchParams.set('window', report.window);
    searchParams.set('agg', report.aggregateBy.join(','));
    searchParams.set('chartDisplay', report.chartDisplay);
    searchParams.set('idle', report.idle);
    searchParams.set('rate', report.rate);
    searchParams.set('filters', btoa(JSON.stringify(report.filters)));

    if (report.sharedOverhead != null) {
      searchParams.set('sharedOverhead', String(report.sharedOverhead));
    } else {
      searchParams.delete('sharedOverhead');
    }

    if (report.sharedNamespaces != null) {
      searchParams.set('sharedNamespaces', report.sharedNamespaces.join(','));
    } else {
      searchParams.delete('sharedNamespaces');
    }

    if (report.sharedLabels != null) {
      searchParams.set('sharedLabels', report.sharedLabels.join(','));
    } else {
      searchParams.delete('sharedLabels');
    }

    navigate({
      search: `?${searchParams.toString()}`,
    });
  }, []);

  const save = useCallback(async (report: Report) => {
    try {
      await saveAllocationReport(report);
      setSavedReports(await listAllocationReports());
      setSnackbar({
        message: 'Report saved',
        severity: 'success',
      });
      selectReport(report);
    } catch (err) {
      setSnackbar({
        message: 'Failed to save report',
        severity: 'error',
      });
    }
  }, []);

  const unsave = useCallback(async (report: Report) => {
    try {
      await deleteAllocationReport(report);
      setSavedReports(await listAllocationReports());
      setSnackbar({
        message: 'Report unsaved',
        severity: 'success',
      });
    } catch (err) {
      setSnackbar({
        message: 'Failed to unsave report',
        severity: 'success',
      });
    }
  }, []);

  /*
    A number of effects must be run, in order, to initialize the Allocation view.

    1. Initialization
    This entails fetching the list of saved reports, and setting the first one in the list to be active.
    Making a report "active" means navigating to the URL with query params set according to the report's configuration.

    2. Setting input values
    The state values of all form inputs are read from the URL query params, and set based on those params.
    This is a separate step from initialization, because we want query param changes from sources other than
    initialization to be treated in the same way.

    3a. Setting the report title
    The title of the report is set by either.
    a) finding a saved report whose parameters match current inputs, and using its name
    b) when no matching report is found, generating a reasonable title using the current inputs

    3b. Fetching allocation data
    Once the input values have been set, fetch allocation data for display based on the inputs provided.
    This step can also be triggered by setting the state variable ``fetch`` to ``true``.
    This is to allow refreshing data without changing input parameters.

    NOTE: Steps 3a. and 3b. are only treated separately because 3b. has the extra dependency (``fetch``).

    4. If necessary, compute rate-based data from original data.

    Each of these steps has to be executed separately, but they work together. Getting the reports allows us to determine
    whether there is a default set of inputs to use. All of these inputs flow through the URL navigation, which sets
    state variables. Those state variables are then used to fetch the correct data.
  */

  // 1. initialize
  useEffect(() => {
    initialize();
    return () => setInit(true);
  }, []);

  // 2. parse any context information from the URL and set input values
  useEffect(() => {
    // context

    const urlContext = searchParams.get('context') || '';
    let ctx: Array<{
      property: string;
      value: string;
      key: string;
      name: string;
    }> = [];

    try {
      ctx = JSON.parse(atob(urlContext)) || [];
    } catch (err) {
      ctx = [];
    }

    // details
    const urlDetails = searchParams.get('details') || '';
    let deets: AllocationProperties | null = null;

    try {
      deets = JSON.parse(atob(urlDetails)) || null;
    } catch (err) {
      deets = null;
    }

    // filters
    const urlFilter = searchParams.get('filters') || '';
    let fltr: Array<{ property: string; value: string }> = [];
    try {
      fltr = JSON.parse(atob(urlFilter)) || [];
    } catch (err) {
      fltr = [];
    }

    // shared namespaces
    const sns = searchParams.get('sharedNamespaces');
    if (typeof sns === 'string') {
      setCustomShareNamespaces(
        sns
          .split(',')
          .map((s) => trim(s))
          .filter((s) => !!s),
      );
    } else {
      setCustomShareNamespaces(null);
    }

    // shared overhead
    const soh = searchParams.get('sharedOverhead');
    if (!soh) {
      setCustomSharedOverhead(null);
    } else {
      setCustomSharedOverhead(parseFloat(soh) || null);
    }

    // shared labels
    const sls = searchParams.get('sharedLabels');
    if (typeof sls === 'string') {
      setCustomShareLabels(
        sls
          .split(',')
          .map((s) => trim(s))
          .filter((s) => !!s),
      );
    } else {
      setCustomShareLabels(null);
    }

    // Set properties based on search parameters. Only call each set function
    // if the value would change so that we don't end up needlessly re-fetching
    // data for the same parameter values. (This used to happen on opening a
    // Details dialog.)

    const win = searchParams.get('window');
    if (win !== window) {
      setWindow(win || '7d');
    }

    const agg = searchParams.get('agg');
    if (agg !== aggregateBy.join(',')) {
      if (agg) {
        setAggregateBy(agg.split(','));
      } else {
        setAggregateBy(['namespace']);
      }
    }

    const idl = searchParams.get('idle');
    if (idl !== idleBy) {
      handleSetIdle(idl || 'separate');
    }

    const titl = searchParams.get('title');
    if (titl !== title) {
      setTitle(titl || 'Last 7 days by namespace daily');
    }

    const split = searchParams.get('split');
    if (split !== shareSplit) {
      setShareSplit(split || 'weighted');
    }

    const display = searchParams.get('chartDisplay');
    if (display !== chartDisplay) {
      setChartDisplay(display || 'category');
    }

    const r = searchParams.get('rate');
    if (r !== rate) {
      setRate(r || 'cumulative');
    }

    if (btoa(JSON.stringify(ctx)) !== btoa(JSON.stringify(context))) {
      setContext(ctx);
    }

    if (btoa(JSON.stringify(deets)) !== btoa(JSON.stringify(details))) {
      setDetails(deets);
    }

    if (btoa(JSON.stringify(fltr)) !== btoa(JSON.stringify(filters))) {
      setFilters(fltr);
    }
  }, [routerLocation]);

  // 3a. Set report title
  // When parameters change, set report title.
  // The cleanup function calls ``setFetch(true)`` to trigger a data fetch with the new state parameters.
  useEffect(() => {
    if (!init) {
      // Do not continue if the page is still initializing
      return;
    }

    // Use "aggregateBy" by default, but if we're within a context, then
    // only use the top-level context; e.g. if we started by namespace, but
    // drilled down, we'll have (aggregateBy == "controller"), but the
    // report should keep the title of "by namespace".
    let aggBy = aggregateBy;
    if (context.length > 0) {
      aggBy = [context[0].key];
    }

    const curr = {
      window,
      aggregateBy: aggBy,
      rate,
      idle: idleBy,
      filters,
      chartDisplay,
      sharedOverhead: customSharedOverhead || 0,
      sharedNamespaces: customShareNamespaces || [],
      sharedLabels: customShareLabels || [],
      title,
    };
    const sr = findSavedReport(curr);
    if (sr) {
      setTitle(sr.title);
    } else {
      setTitle(generateTitle(curr));
    }
    setFetch(true);
  }, [
    window,
    aggregateBy,
    rate,
    idle,
    filters,
    shareSplit,
    chartDisplay,
    init,
    customSharedOverhead,
    customShareNamespaces,
    customShareLabels,
  ]);

  // 3b. Fetch data.
  useEffect(() => {
    if (!(init && fetch)) {
      return undefined;
    }
    setFetch(false);
    const abortController = new AbortController();
    fetchData(abortController);
    return () => {
      if (!fetch) {
        abortController.abort();
      }
    };
  }, [init, fetch]);

  // When allocation data changes, apply any rate-based adjustments and calculate
  // the accumulated data.
  useEffect(() => {
    // multiplied by $/min cost to get $/factor cost
    const factor = {
      hourly: 60,
      daily: 60 * 24,
      monthly: 60 * 24 * 30.42,
    }[rate];

    // apply ant rate transformations to the range
    const allocationSetRange = originalAllocationData.map((allocations) => {
      const rangeEntry = allocations || {};
      if (!factor) {
        return rangeEntry;
      }
      return rangeEntry.map((alloc) => ({
        ...alloc,
        cpuCost: (get(alloc, 'cpuCost', 0) / get(alloc, 'minutes', 0)) * factor,
        externalCost:
          (get(alloc, 'externalCost', 0) / get(alloc, 'minutes', 0)) * factor,
        gpuCost: (get(alloc, 'gpuCost', 0) / get(alloc, 'minutes', 0)) * factor,
        loadBalancerCost:
          (get(alloc, 'loadBalancerCost', 0) / get(alloc, 'minutes', 0)) *
          factor,
        networkCost:
          (get(alloc, 'networkCost', 0) / get(alloc, 'minutes', 0)) * factor,
        pvCost: (get(alloc, 'pvCost', 0) / get(alloc, 'minutes', 0)) * factor,
        ramCost: (get(alloc, 'ramCost', 0) / get(alloc, 'minutes', 0)) * factor,
        sharedCost:
          (get(alloc, 'sharedCost', 0) / get(alloc, 'minutes', 0)) * factor,
        totalCost:
          (get(alloc, 'totalCost', 0) / get(alloc, 'minutes', 0)) * factor,
      }));
    });

    // accumulate the range and apply any rate transformations
    const cumulative = rangeToCumulative(originalAllocationData, aggregateBy);

    if (cumulative && factor) {
      Object.keys(cumulative).forEach((key) => {
        cumulative[key].cpuCost =
          (get(cumulative[key], 'cpuCost', 0) /
            get(cumulative[key], 'minutes', 0)) *
          factor;
        cumulative[key].externalCost =
          (get(cumulative[key], 'externalCost', 0) /
            get(cumulative[key], 'minutes', 0)) *
          factor;
        cumulative[key].gpuCost =
          (get(cumulative[key], 'gpuCost', 0) /
            get(cumulative[key], 'minutes', 0)) *
          factor;
        cumulative[key].loadBalancerCost =
          (get(cumulative[key], 'loadBalancerCost', 0) /
            get(cumulative[key], 'minutes', 0)) *
          factor;
        cumulative[key].networkCost =
          (get(cumulative[key], 'networkCost', 0) /
            get(cumulative[key], 'minutes', 0)) *
          factor;
        cumulative[key].pvCost =
          (get(cumulative[key], 'pvCost', 0) /
            get(cumulative[key], 'minutes', 0)) *
          factor;
        cumulative[key].ramCost =
          (get(cumulative[key], 'ramCost', 0) /
            get(cumulative[key], 'minutes', 0)) *
          factor;
        cumulative[key].sharedCost =
          (get(cumulative[key], 'sharedCost', 0) /
            get(cumulative[key], 'minutes', 0)) *
          factor;
        cumulative[key].totalCost =
          (get(cumulative[key], 'totalCost', 0) /
            get(cumulative[key], 'minutes', 0)) *
          factor;
      });
    }

    setAllocationData(allocationSetRange);
    setCumulativeData(toArray(cumulative));
    setTotalData(cumulativeToTotals(cumulative));
  }, [originalAllocationData]);

  const breadcrumbs = [
    { name: 'Allocations', href: 'allocations.html' },
    { name: title, href: 'allocations.html' },
  ];

  return (
    <>
      <Header breadcrumbs={breadcrumbs}>
        <IconButton aria-label="refresh" onClick={() => setFetch(true)}>
          <RefreshIcon />
        </IconButton>
        <DiagnosticsChecker />
        <Link href="settings.html">
          <IconButton aria-label="refresh">
            <SettingsIcon />
          </IconButton>
        </Link>
        <HelpIconLink
          href="https://docs.kubecost.com/cost-allocation"
          tooltipText="Product Documentation"
        />
      </Header>

      {!loading && alerts.length > 0 ? (
        <div style={{ marginBottom: 20 }}>
          <Alerts alerts={alerts} />
        </div>
      ) : (
        <></>
      )}

      {init ? (
        <Paper id="report">
          <div className={classes.reportHeader}>
            <div>
              <Typography style={{ wordBreak: 'break-all' }} variant="h5">
                {title}
              </Typography>
              <Subtitle
                report={{
                  window,
                  aggregateBy,
                }}
                context={context}
                clearContext={() => {
                  clearContext();
                  navigate({ search: `?${searchParams.toString()}` });
                }}
                goToContext={goToContext}
              />
            </div>
            <div className={classes.controlsWrapper}>
              <Controls
                windowOptions={windowOptions}
                window={window}
                setWindow={(win: string) => {
                  Analytics.record('allocation:time_window', {
                    from: window,
                    to: win,
                  });
                  searchParams.set('window', win);
                  navigate({
                    search: `?${searchParams.toString()}`,
                  });
                }}
                aggregateBy={aggregateBy}
                setAggregateBy={(agg: string) => {
                  Analytics.record('allocation:aggregation', {
                    from: aggregateBy,
                    to: agg,
                  });
                  searchParams.set('agg', agg);
                  navigate({
                    search: `?${searchParams.toString()}`,
                  });
                }}
                chartDisplayOptions={chartDisplayOptions}
                chartDisplay={chartDisplay}
                setChartDisplay={(cd: string) => {
                  searchParams.set('chartDisplay', cd);
                  navigate({
                    search: `?${searchParams.toString()}`,
                  });
                }}
                idleOptions={idleOptions}
                idle={idleBy}
                setIdle={handleSetIdle}
                title={title}
                setTitle={(t: string) => {
                  searchParams.set('title', t);
                  navigate({
                    search: `?${searchParams.toString()}`,
                  });
                }}
                savedReports={savedReports}
                setSavedReports={setSavedReports}
                selectReport={selectReport}
                cumulativeData={cumulativeData}
                save={save}
                unsave={unsave}
                titleField={titleField}
                setTitleField={setTitleField}
                filters={filters}
                setFilters={(fs: { property: string; value: string }[]) => {
                  const fltr = btoa(JSON.stringify(fs));
                  searchParams.set('filters', fltr);
                  navigate({
                    search: `?${searchParams.toString()}`,
                  });
                }}
                findSavedReport={findSavedReport}
                customShareNamespaces={customShareNamespaces}
                defaultShareNamespaces={defaultShareNamespaces}
                customShareLabels={customShareLabels}
                defaultShareLabels={defaultShareLabels}
                customSharedOverhead={customSharedOverhead}
                defaultSharedOverhead={defaultSharedOverhead}
                shareSplit={shareSplit}
                setShareSplit={(split: string) => {
                  searchParams.set('split', split);
                  navigate({
                    search: `?${searchParams.toString()}`,
                  });
                }}
                shareSplitOptions={shareSplitOptions}
                shareTenancyCosts={shareTenancyCosts}
                clearContext={clearContext}
                context={context}
                rate={rate}
                setRate={(r: string) => {
                  searchParams.set('rate', r);
                  navigate({
                    search: `?${searchParams.toString()}`,
                  });
                }}
                rateOptions={rateOptions}
                setSharedOverhead={(o: string | null) => {
                  if (o !== null) {
                    searchParams.set('sharedOverhead', o);
                  } else {
                    searchParams.delete('sharedOverhead');
                  }
                  navigate({
                    search: `?${searchParams.toString()}`,
                  });
                }}
                setSharedNamespaces={(ns: string) => {
                  if (ns !== null) {
                    searchParams.set('sharedNamespaces', ns);
                  } else {
                    searchParams.delete('sharedNamespaces');
                  }
                  navigate({
                    search: `?${searchParams.toString()}`,
                  });
                }}
                setSharedLabels={(lbls: string) => {
                  if (lbls !== null) {
                    searchParams.set('sharedLabels', lbls);
                  } else {
                    searchParams.delete('sharedLabels');
                  }
                  navigate({
                    search: `?${searchParams.toString()}`,
                  });
                }}
              />
            </div>
          </div>

          {loading && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ paddingTop: 100, paddingBottom: 100 }}>
                <CircularProgress />
              </div>
            </div>
          )}
          {!loading && totalData && (
            <AllocationReport
              allocationData={allocationData}
              cumulativeData={cumulativeData}
              totalData={totalData}
              drillDownForRow={drillDownForRow}
              canDrillDown={canDrillDown}
              aggregateBy={aggregateBy}
              window={window}
              chartDisplay={chartDisplay}
              rate={rate}
              sharingIdle={shareIdle}
              drillDownCompatible={drillDownCompatible}
              drillDownExemptRows={drillDownExemptRows}
            />
          )}
        </Paper>
      ) : (
        <></>
      )}

      <DetailsDialog
        open={details !== null}
        close={() => closeDetails()}
        properties={details}
        service={get(details, 'service', '')}
        window={window}
      />

      <Snackbar
        open={!!snackbar.message}
        autoHideDuration={4000}
        onClose={() => setSnackbar({})}
      >
        <Alert
          onClose={() => setSnackbar({})}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );

  async function initialize() {
    try {
      const config = modelConfig;

      const stc = get(config, 'shareTenancyCosts', 'true') === 'true';
      setShareTenancyCosts(stc);

      const sns = filter(
        get(config, 'sharedNamespaces', '')
          .split(',')
          .map((ns) => trim(ns)),
        (ns) => ns !== '',
      );
      setDefaultShareNamespaces(sns);

      const sls = [];
      const slns = filter(
        get(config, 'sharedLabelNames', '')
          .split(',')
          .map((ln) => trim(ln)),
        (ln) => ln !== '',
      );
      const slvs = filter(
        get(config, 'sharedLabelValues', '')
          .split(',')
          .map((lv) => trim(lv)),
        (lv) => lv !== '',
      );
      if (slns.length === slvs.length && slns.length > 0) {
        for (let i = 0; i < slns.length; i += 1) {
          sls.push(`${slns[i]}:${slvs[i]}`);
        }
      }
      setDefaultShareLabels(sls);
      setDefaultSharedOverhead(parseFloat(get(config, 'sharedOverhead', '0')));

      const srs = await listAllocationReports();
      setSavedReports(srs);
    } catch (error) {
      logger.error(error);

      setAlerts([
        {
          primary: 'Failed to initialize page',
          secondary: String(error),
          level: 'error',
        },
      ]);
    }

    setInit(true);
  }

  function findSavedReport(report: Report) {
    if (!isArray(savedReports) || savedReports.length === 0) {
      return null;
    }

    const filtersToStr = (fs: { property: string; value: string }[]) =>
      isArray(fs)
        ? sortBy(fs, 'property')
            .map((f) => `${f.property}=${f.value}`)
            .join('|')
        : '';

    // eslint-disable-next-line no-param-reassign
    report.filterStr = filtersToStr(report.filters);

    for (let i = 0; i < savedReports.length; i += 1) {
      let match =
        savedReports[i].window === report.window &&
        savedReports[i].aggregateBy.join(',') ===
          report.aggregateBy.join(',') &&
        savedReports[i].rate === report.rate &&
        savedReports[i].idle === report.idle &&
        savedReports[i].chartDisplay === report.chartDisplay &&
        filtersToStr(savedReports[i].filters) === report.filterStr;

      match = match && savedReports[i].sharedOverhead === report.sharedOverhead;

      const thisReportNS =
        savedReports[i].sharedNamespaces == null
          ? null
          : savedReports[i].sharedNamespaces.sort().join(',');
      const thatReportNS =
        report.sharedNamespaces == null
          ? null
          : report.sharedNamespaces.sort().join(',');

      const thisReportLabels = unwrappedLabels(savedReports[i].sharedLabels);
      const thatReportLabels = unwrappedLabels(report.sharedLabels);

      // if the current report has null sharedNamespaces, default to ignoring
      match = match && thisReportNS === thatReportNS;
      match = match && thisReportLabels === thatReportLabels;

      if (match) {
        return savedReports[i];
      }
    }

    return null;
  }

  function closeDetails() {
    searchParams.set('details', btoa(JSON.stringify(null)));
    navigate({ search: `?${searchParams.toString()}` });
  }

  function openDetails(properties: AllocationProperties) {
    searchParams.set('details', btoa(JSON.stringify(properties)));
    navigate({ search: `?${searchParams.toString()}` });
  }

  function clearContext() {
    if (context.length > 0) {
      searchParams.set('agg', context[0].key);
    }
    searchParams.set('context', btoa(JSON.stringify([])));
  }

  function goToContext(i: number) {
    if (!isArray(context)) {
      logger.warn(`context is not an array: ${context}`);
      return;
    }

    if (i > context.length - 1) {
      logger.warn(
        `selected context out of range: ${i} with context length ${context.length}`,
      );
      return;
    }

    if (i === context.length - 1) {
      logger.warn(
        `selected current context: ${i} with context length ${context.length}`,
      );
    }

    searchParams.set('agg', context[i + 1].key);
    searchParams.set('context', btoa(JSON.stringify(context.slice(0, i + 1))));
    navigate({ search: `?${searchParams.toString()}` });
  }

  function canDrillDown(row: {
    totalCost: number;
    externalCost: number;
    name: string;
  }) {
    return (
      aggregateBy.length === 1 &&
      (drillDownCompatible.includes(aggregateBy[0]) ||
        aggregateBy[0].startsWith('label')) &&
      row.totalCost !== row.externalCost &&
      !drillDownExemptRows.includes(row.name)
    );
  }

  async function fetchData(abortController: AbortController) {
    setLoading(true);

    // Collect new alerts and warnings throughout fetching, then display them all.
    const newAlerts: {
      primary: string;
      secondary: string | ReactElement;
      level: 'error' | 'warning' | 'info' | 'success';
    }[] = [];

    // if the user navigated here to create a report, show instructions on how to do that.
    if (searchParams.get('new-report') === 'true') {
      newAlerts.push({
        primary: 'Creating a new report',
        secondary: `Adjust settings to see the data you want, and save using the bookmark icon on the right.
    Afterward, the saved report will be accessible from the Reports tab. `,
        level: 'success',
      });
    }

    try {
      const clusterInfoMap = await model.clusterInfoMap();

      // combine user-set filters with filters that have been applied due to drilldown contexts
      const queryFilters = context
        .map(({ property, value }) => ({ property, value }))
        .concat(filters);

      // accumulate on non-timeseries views
      const accumulate =
        chartDisplay !== 'series' && chartDisplay !== 'efficiency';

      // determine sharing parameters using either query parameters or defaults
      let querySharedOverhead;
      let querySharedNamespaces;
      let querySharedLabels;

      if (typeof customSharedOverhead !== 'number') {
        querySharedOverhead = defaultSharedOverhead;
      } else {
        querySharedOverhead = customSharedOverhead;
      }

      if (!Array.isArray(customShareNamespaces)) {
        querySharedNamespaces = defaultShareNamespaces;
      } else {
        querySharedNamespaces = customShareNamespaces;
      }

      if (!Array.isArray(customShareLabels)) {
        querySharedLabels = defaultShareLabels;
      } else {
        querySharedLabels = customShareLabels;
      }

      const resp = await model.getAllocationSummary(
        window,
        aggregateBy,
        {
          accumulate,
          shareIdle,
          filters: queryFilters,
          shareNamespaces: querySharedNamespaces,
          shareLabels: querySharedLabels,
          shareCost: querySharedOverhead,
          shareSplit,
          shareTenancyCosts,
          external: 'false',
          idleByNode,
          idle,
        },
        { signal: abortController.signal },
      );

      // Feature gate if tier-related warning is returned
      if (resp.warning && resp.warning.length > 0) {
        const isTierWarning = tierWarningRegex.exec(resp.warning);
        tierWarningRegex.lastIndex = 0;
        if (isTierWarning) {
          let tier = isTierWarning[2].toLowerCase();
          tier = tier[0].toUpperCase() + tier.slice(1);

          let secondary = (
            <Typography>
              To view more data{' '}
              <strong>
                <Button
                  color="primary"
                  onClick={async () => {
                    const conf = confirm(
                      'Select OK to begin your 30-day free trial of all paid features!',
                    );
                    let res;
                    if (!conf) {
                      return;
                    }
                    try {
                      res = await ConfigService.startTrial();
                    } catch (err) {
                      alert(
                        'Failed to start trial. Please contact team@kubecost.com for assistance.',
                      );
                      return;
                    }
                    setFetch(true);
                    Analytics.record('trial_start', { page: 'Reports' });
                    Analytics.setProductKey(res.productKey.key);
                    Analytics.setProductTier('trial:enterprise');
                  }}
                >
                  Start Trial
                </Button>
              </strong>
              . Learn more about{' '}
              <strong>
                <Link
                  href="https://kubecost.com/pricing?upgrade=true"
                  target="_blank"
                  color="inherit"
                >
                  Upgrade Options
                </Link>
                .
              </strong>
            </Typography>
          );
          const trialResp = await model.trialStatus();
          if (trialResp.usedTrial) {
            secondary = (
              <Typography>
                Your trial period has expired. To view more data{' '}
                <strong>
                  <Link
                    href="https://kubecost.com/pricing?upgrade=true"
                    target="_blank"
                    color="inherit"
                  >
                    Upgrade
                  </Link>
                </strong>
                .
              </Typography>
            );
          }

          newAlerts.push({
            primary: `You are requesting more than ${
              tier === 'Business' ? 30 : 15
            } days worth of metrics on ${tier} tier`,
            secondary,
            level: 'error',
          });

          resp.data.sets = [];
        }
      }

      if (resp.data.sets && resp.data.sets.length > 0) {
        const originalAllocationRange = resp.data.sets.map(
          ({ allocations, window: win }) => {
            let allocationSet = allocations;

            // optionally drop idle allocation entries
            if (idleBy === 'hide') {
              allocationSet = filterIdle(allocations);
            }

            // reduce is used here in the same way that `map()` might be used for an array.
            // that is, given an array, `map()` produces another array with each element transformed.
            // here, we are taking an object, and producing a new object with the same keys, but each value is transformed.
            // specifically, we are augmenting allocations with extra properties for time, efficiency, and total cost
            const augmentedSet = Object.entries(allocationSet).reduce(
              (allocs, [key, alloc]) => {
                const cpuEfficiency = model.getSummaryCpuEfficiency(alloc);
                const ramEfficiency = model.getSummaryRamEfficiency(alloc);
                const totalEfficiency = model.getSummaryTotalEfficiency(alloc);
                const minutes = model.getSummaryMinutes(alloc);
                const totalCost = model.getSummaryTotalCost(alloc);

                // in the case of aggregating by cluster at the root,
                // we compute the clusterNameId for display
                let clusterNameId;
                if (aggregateBy.length === 1 && aggregateBy[0] === 'cluster') {
                  clusterNameId = getClusterNameId(alloc, clusterInfoMap);
                }

                return {
                  ...allocs,
                  [key]: {
                    ...alloc,
                    window: win,
                    minutes,
                    totalCost,
                    cpuEfficiency,
                    ramEfficiency,
                    totalEfficiency,
                    name: clusterNameId || alloc.name, // if clusterNameId was computed, we use that. Otherwise use the default alloc name
                  },
                };
              },
              {},
            );
            return sortBy(augmentedSet, (a) => a.totalCost);
          },
        );
        setOriginalAllocationData(originalAllocationRange);
      } else {
        if (resp.message && resp.message.indexOf('boundary error') >= 0) {
          const match = resp.message.match(/(ETL.* is \d+\.\d+% complete)/);
          let secondary = 'Try again after ETL build is complete';
          if (match && match.length > 0) {
            secondary = `${match[1]}. ${secondary}`;
          }
          newAlerts.push({
            primary: 'Data unavailable while ETL is building',
            secondary,
            level: 'warning',
          });
        }
        setOriginalAllocationData([]);
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        return;
      }
      if (err.message.indexOf('404') === 0) {
        newAlerts.push({
          primary: 'Failed to load report data',
          secondary:
            'Please update Kubecost to the latest version, then contact support if problems persist.',
          level: 'warning',
        });
      } else if (err.name === 'AbortError') {
        return;
      } else {
        let secondary =
          'Please contact Kubecost support with a bug report if problems persist.';
        if (err.message.length > 0) {
          secondary = err.message;
        }
        newAlerts.push({
          primary: 'Failed to load report data',
          secondary,
          level: 'warning',
        });
      }
      setOriginalAllocationData([]);
    }

    setAlerts(newAlerts);
    setLoading(false);
  }
};

// generateTitle generates a string title from a report object
function generateTitle({
  window,
  aggregateBy,
  idle,
  filters,
  rate,
}: {
  window: string;
  aggregateBy: string[];
  idle: string;
  filters: { property: string; value: string }[];
  rate: string;
}) {
  let windowName = get(find(windowOptions, { value: window }), 'name', '');
  if (windowName === '') {
    windowName = toVerboseTimeRange(window);
  }

  const aggregationName = aggregateBy.join(', ').toLowerCase();
  if (aggregationName === '') {
    logger.warn(`unknown aggregation: ${aggregateBy}`);
  }

  const windowNameLower = lowerFirst(windowName);
  let str = `${rate.slice(0, 1).toUpperCase()}${rate.slice(
    1,
  )} cost for ${windowNameLower} by ${aggregationName}`;

  if (idle === 'share') {
    str = `${str} sharing idle`;
  }
  if (idle === 'hide') {
    str = `${str} hiding idle`;
  }

  if (filters && filters.length > 0) {
    str = `${str} with filters`;
  }

  return str;
}

// maps the cluster identifier to clusterName/clusterId for display
function getClusterNameId(
  item: { name?: string },
  infoMap: Record<string, string>,
) {
  const clusterId = get(item, 'name', '');

  const info = infoMap[clusterId];
  let clusterName;
  if (info !== undefined) {
    clusterName = get(info, 'name', undefined);
  }

  return clusterService.clusterNameId({ clusterId, clusterName });
}

export default AllocationPage;
