import * as warnings from './issues';

export const findIssues = (completion) => {
  var totalRemainingIssues = 30; // used to hide loading div
  var results = {};

  const updateRowStatus = (id, result, key, type, totalRemainingIssues) => {
    results[id] = {
      data: result,
      key,
      type,
    };
    if (totalRemainingIssues <= 0) {
      completion(results);
    }
  };

  warnings.clusterCostsUp('1', '.1', function (data) {
    updateRowStatus('clusterCostsUp', data, null, null, --totalRemainingIssues);
  });

  warnings.podsOOMed('10', function (data) {
    updateRowStatus('podsOOMed', data, 'pod', null, --totalRemainingIssues);
  });

  warnings.failedJobs('10', function (data) {
    updateRowStatus(
      'failedJobs',
      data,
      'job_name',
      null,
      --totalRemainingIssues,
    );
  });

  warnings.pendingPods('30', function (data) {
    updateRowStatus('pendingPods', data, 'pod', 'pod', --totalRemainingIssues);
  });

  warnings.lowReplicas('10', '.5', function (data) {
    updateRowStatus(
      'lowReplicas',
      data,
      'deployment',
      null,
      --totalRemainingIssues,
    );
  });

  warnings.pvErrors('5', function (data) {
    updateRowStatus(
      'pvErrors',
      data,
      'persistentvolume',
      null,
      --totalRemainingIssues,
    );
  });

  warnings.pidPressure('30', 0.8, function (data) {
    updateRowStatus('pidPressure', data, 'node', null, --totalRemainingIssues);
  });

  warnings.tooManyFiles('10', 0.9, function (data) {
    updateRowStatus('tooManyFiles', data, 'name', null, --totalRemainingIssues);
  });

  warnings.localINodes('30', 0.9, function (data) {
    updateRowStatus(
      'iNodeLimitLocal',
      data,
      'name',
      null,
      --totalRemainingIssues,
    );
  });

  warnings.persistentVolINodes('30', 0.9, function (data) {
    updateRowStatus(
      'iNodeLimitPvc',
      data,
      'persistentvolumeclaim',
      null,
      --totalRemainingIssues,
    );
  });

  warnings.highClusterMemoryUsage('120', 0.9, function (data) {
    updateRowStatus(
      'currentCapacity',
      data,
      null,
      null,
      --totalRemainingIssues,
    );
  });

  warnings.lowClusterCPU('30', 0.9, function (data) {
    updateRowStatus(
      'highClusterCpuRequestUtilization',
      data,
      null,
      null,
      --totalRemainingIssues,
    );
  });

  warnings.lowClusterMemory('30', 0.9, function (data) {
    updateRowStatus(
      'highClusterMemRequestUtilization',
      data,
      null,
      null,
      --totalRemainingIssues,
    );
  });

  warnings.crashLooping('10m', 1, function (data) {
    updateRowStatus('crashLooping', data, 'pod', 'pod', --totalRemainingIssues);
  });

  warnings.hasTooManyPods('10m', 0.9, function (data) {
    updateRowStatus(
      'tooManyPods',
      data,
      'instance',
      null,
      --totalRemainingIssues,
    );
  });

  warnings.isCPUThrottled('10m', 0.2, function (data) {
    updateRowStatus(
      'cpuThrottled',
      data,
      'pod_name',
      'pod',
      --totalRemainingIssues,
    );
  });

  warnings.predictFullPVs(48, function (data) {
    updateRowStatus(
      'fullPV',
      data,
      'persistentvolumeclaim',
      null,
      --totalRemainingIssues,
    );
  });

  warnings.predictFullLocalDisks(48, function (data) {
    updateRowStatus(
      'fullAttachedDisk',
      data,
      'instance',
      null,
      --totalRemainingIssues,
    );
  });

  warnings.findBadNodes(function (data) {
    updateRowStatus('badNode', data, 'name', null, --totalRemainingIssues);
  });

  warnings.isClusterSingleRegion(function (data) {
    updateRowStatus(
      'notMultiZoneCluster',
      data,
      null,
      null,
      --totalRemainingIssues,
    );
  });

  warnings.getUnreplicatedDeployments(function (data) {
    updateRowStatus(
      'unreplicatedDeployment',
      data,
      'name',
      'deployment',
      --totalRemainingIssues,
    );
  });

  warnings.getBestEffortRamConsumers(1, 0.01, function (data) {
    updateRowStatus(
      'hungryBestEffortRam',
      data,
      'pod_name',
      'pod',
      --totalRemainingIssues,
    );
  });

  warnings.getBestEffortCPUConsumers(1, 0.01, function (data) {
    updateRowStatus(
      'hungryBestEffortCPU',
      data,
      'pod_name',
      'pod',
      --totalRemainingIssues,
    );
  });

  warnings.getMemoryOverusers(1, 1.2, null, function (data) {
    updateRowStatus(
      'memoryOverUse',
      data,
      'pod_name',
      'pod',
      --totalRemainingIssues,
    );
  });

  warnings.getCPUOverusers(1, 1, null, function (data) {
    updateRowStatus(
      'cpuOverUse',
      data,
      'pod_name',
      'pod',
      --totalRemainingIssues,
    );
  });

  warnings.findNodeMemoryPressure(function (data) {
    updateRowStatus(
      'nodeMemoryPressure',
      data,
      'name',
      'node',
      --totalRemainingIssues,
    );
  });

  warnings.getCloseRAMLimit(1, 0.8, null, function (data) {
    updateRowStatus(
      'closeRAMLimit',
      data,
      'pod_name',
      'pod',
      --totalRemainingIssues,
    );
  });

  warnings.findDaemonsWithoutLimits(function (data) {
    updateRowStatus(
      'daemonsWithoutLimits',
      data,
      'name',
      'daemonset',
      --totalRemainingIssues,
    );
  });

  warnings.getCloseCPULimit(1, 0.9, null, function (data) {
    updateRowStatus(
      'closeCPULimit',
      data,
      'pod_name',
      'pod',
      --totalRemainingIssues,
    );
  });

  warnings.packetsDropped(1, 0, null, function (data) {
    updateRowStatus(
      'networkIssues',
      data,
      'pod_name',
      'pod',
      --totalRemainingIssues,
    );
  });

  warnings.getNetworkErrors(1, 0, function (data) {
    updateRowStatus(
      'networkErrors',
      data,
      'pod_name',
      'pod',
      --totalRemainingIssues,
    );
  });

  warnings.isSingleMasterCluster(function (data) {
    updateRowStatus(
      'notMultiMasterCluster',
      data,
      null,
      null,
      --totalRemainingIssues,
    );
  });
};
