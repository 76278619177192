import React from 'react';
import { IconProps } from './types';

interface ArrowIconProps extends IconProps {
  direction: 'LEFT' | 'RIGHT' | 'UP' | 'DOWN';
}

export const ArrowIcon = ({ direction, ...args }: ArrowIconProps) => {
  let rotation = 0;
  switch (direction) {
    case 'UP':
      rotation = 180;
      break;
    case 'DOWN':
      rotation = 0;
      break;
    case 'RIGHT':
      rotation = 270;
    case 'LEFT':
      rotation = 90;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5em"
      height="1.5em"
      rotate={rotation}
      direction={direction}
      transform={`rotate(${rotation})`}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
      {...args}
    >
      <g transform="rotate(-90 12 12)">
        <path
          fill="currentColor"
          d="M12 16a1 1 0 0 1-.64-.23l-6-5a1 1 0 1 1 1.28-1.54L12 13.71l5.36-4.32a1 1 0 0 1 1.41.15a1 1 0 0 1-.14 1.46l-6 4.83A1 1 0 0 1 12 16Z"
        />
      </g>
    </svg>
  );
};
