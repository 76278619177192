import { Grid } from '@material-ui/core';
import React from 'react';

import { SavingsCard } from './SavingsCard';

interface SavingsDataCardProps {
  savingsData: any;
  currency: string;
  onClickHref: string;
}

export const SavingsCardGroup = ({
  savingsData,
  currency,
  onClickHref,
}: SavingsDataCardProps) => (
  <Grid style={{ textAlign: 'center' }} container spacing={4}>
    <Grid item xs={4}>
      <SavingsCard
        title="Monthly CPU Savings"
        value={savingsData.monthlySavingsCPU}
        currency={currency}
      />
    </Grid>
    <Grid item xs={4}>
      <SavingsCard
        title="Total Monthly Savings"
        value={savingsData.monthlySavings}
        currency={currency}
        onClickHref={onClickHref}
      />
    </Grid>
    <Grid item xs={4}>
      <SavingsCard
        title="Monthly RAM Savings"
        value={savingsData.monthlySavingsRAM}
        currency={currency}
      />
    </Grid>
  </Grid>
);
