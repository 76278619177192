import React from 'react';
import { IconProps } from './types';

export const SettingsIcon = ({ ...args }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
      {...args}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
      >
        <path d="M3 5h4m14 0H11m-8 7h12m6 0h-2M3 19h2m16 0H9" />
        <circle cx="9" cy="5" r="2" />
        <circle cx="17" cy="12" r="2" />
        <circle cx="7" cy="19" r="2" />
      </g>
    </svg>
  );
};
