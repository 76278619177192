// MUI components
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import { Link as RouteLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
// MUI icons
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
// MUI styles
import { makeStyles } from '@material-ui/styles';
// react
import React, { useEffect, useState } from 'react';
import { toCurrency } from '../../services/format';
// local
import Model from '../../services/model';
import {
  getPvSavings,
  getReservedRecSavings,
  getSavingsSummary,
  getUnassignedAddressSavings,
  getUnassignedDiskSavings,
  getUnutilizedLocalDiskSavings,
} from '../../services/savings';

const useStyles = makeStyles({
  actionButton: {
    color: '#2196f3',
  },
  actionLink: {
    marginLeft: 'auto',
  },
  centered: {
    textAlign: 'center',
  },
  content: {
    color: 'rgba(0, 0, 0, 0.7)',
  },
  savings: {
    color: '#0F9D58',
    fontWeight: 400,
  },
});

function SavingsCard(): React.ReactElement {
  const classes = useStyles();

  const [currency, setCurrency] = useState('USD');
  const [totalSavings, setTotalSavings] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [fetchedSavings, setFetchedSavings] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (loading || error) {
      return;
    }

    getSavingsSummary().then((savingsSummary) => {
      let clusterSizing =
        savingsSummary.clusterSizing === undefined
          ? 0
          : savingsSummary.clusterSizing;
      let nodeTurndown =
        savingsSummary.nodeTurndown === undefined
          ? 0
          : savingsSummary.nodeTurndown;

      // Math.max returns NaN if either of the values are undefined.
      const nodeSavings = Math.max(clusterSizing, nodeTurndown);
      const savings =
        savingsSummary.abandonedWorkloads +
        savingsSummary.requestSizing +
        nodeSavings;

      setTotalSavings((prev) => prev + 0.65 * savings);
      setFetchedSavings((prev) => prev + 4);
    });

    getPvSavings().then((savings) => {
      setTotalSavings((prev) => prev + 0.65 * savings);
      setFetchedSavings((prev) => prev + 1);
    });

    getReservedRecSavings().then((savings) => {
      setTotalSavings((prev) => prev + 0.65 * savings);
      setFetchedSavings((prev) => prev + 1);
    });

    Promise.all([
      getUnassignedAddressSavings(),
      getUnassignedDiskSavings(),
    ]).then(([addrSavings, diskSavings]) => {
      setTotalSavings((prev) => prev + 0.65 * (addrSavings + diskSavings));
      setFetchedSavings((prev) => prev + 1);
    });

    getUnutilizedLocalDiskSavings().then((savings) => {
      setTotalSavings((prev) => prev + 0.65 * savings);
      setFetchedSavings((prev) => prev + 1);
    });
  }, [loading, error]);

  function Content() {
    if (loading) {
      return <CircularProgress />;
    }
    if (error) {
      return <Typography>could not load savings data</Typography>;
    }
    return (
      <>
        <CardContent className={`${classes.content} ${classes.centered}`}>
          <Tooltip title="Estimated savings are probability adjusted and include both Kubernetes and external cloud insights.">
            <div>
              <Typography className={classes.savings} paragraph variant="h2">
                {toCurrency(totalSavings, currency)}
              </Typography>
              <Typography variant="h5">Monthly savings identified</Typography>
              <Typography
                style={{
                  display: fetchedSavings < 8 ? undefined : 'none',
                  opacity: fetchedSavings < 8 ? 1 : 0,
                }}
              >
                {fetchedSavings}/8 savings opportunities loaded
              </Typography>
            </div>
          </Tooltip>
        </CardContent>
        <CardActions>
          <Link
            component={RouteLink}
            to="/savings"
            className={classes.actionLink}
          >
            <Button
              className={classes.actionButton}
              endIcon={<KeyboardArrowRightIcon />}
            >
              View Savings
            </Button>
          </Link>
        </CardActions>
      </>
    );
  }

  return (
    <Card>
      <Content />
    </Card>
  );

  async function fetchData() {
    try {
      const modelConfigs = await Model.getConfigs();
      setCurrency(modelConfigs.currencyCode || 'USD');
      setLoading(false);
    } catch (err) {
      setError(true);
      setLoading(false);
    }
  }
}

export default SavingsCard;
