import round from 'lodash/round';
import logger from './logger';

const currencyLocaleMap: Record<string, string> = {
  CHF: 'fr-CH',
  DKK: 'da-DK',
  NOK: 'no-NO',
  PLN: 'pl-PL',
  SEK: 'en-SE',
};

export function toCurrency(
  amount: number,
  currency: string,
  precision?: number,
  shouldCompact?: boolean,
): string {
  if (typeof amount !== 'number') {
    logger.warn(
      `Tried to convert "${amount}" to currency, but it is not a number`,
    );
    return '';
  }

  // Default to USD
  let curr = 'USD';
  if (currency !== undefined && currency !== '') {
    curr = currency;
  }

  const opts: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: curr,
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    notation: shouldCompact ? 'compact' : 'standard',
  };

  return amount.toLocaleString(currencyLocaleMap[curr], opts);
}

export function bytesToString(bytes: number): string {
  const ei = 1024 ** 6;
  if (bytes >= ei) {
    return `${round(bytes / ei, 1)} EiB`;
  }
  const pi = 1024 ** 5;
  if (bytes >= pi) {
    return `${round(bytes / pi, 1)} PiB`;
  }
  const ti = 1024 ** 4;
  if (bytes >= ti) {
    return `${round(bytes / ti, 1)} TiB`;
  }
  const gi = 1024 ** 3;
  if (bytes >= gi) {
    return `${round(bytes / gi, 1)} GiB`;
  }
  const mi = 1024 ** 2;
  if (bytes >= mi) {
    return `${round(bytes / mi, 1)} MiB`;
  }
  const ki = 1024 ** 1;
  if (bytes >= ki) {
    return `${round(bytes / ki, 1)} KiB`;
  }

  return `${round(bytes, 1)} B`;
}

export function bytesToStringBase10(bytes: number): string {
  const e = 1000 ** 6;
  if (bytes >= e) {
    return `${round(bytes / e, 1)} EB`;
  }
  const p = 1000 ** 5;
  if (bytes >= p) {
    return `${round(bytes / p, 1)} PB`;
  }
  const t = 1000 ** 4;
  if (bytes >= t) {
    return `${round(bytes / t, 1)} TB`;
  }
  const g = 1000 ** 3;
  if (bytes >= g) {
    return `${round(bytes / g, 1)} GB`;
  }
  const m = 1000 ** 2;
  if (bytes >= m) {
    return `${round(bytes / m, 1)} MB`;
  }
  const k = 1000 ** 1;
  if (bytes >= k) {
    return `${round(bytes / k, 1)} KB`;
  }

  return `${round(bytes, 1)} B`;
}

export function coresToString(cores: number): string {
  if (cores > 1.0) {
    return `${round(cores, 2)}`;
  }

  return `${round(cores * 1000, 1)}m`;
}

export function parseByteString(str: string): number {
  const m = str.match(/(\d+e?\.?\d*)(\w*)/);
  if (m === null) {
    logger.warn(`Failed to parse byte string: ${str}`);
    return 0.0;
  }

  const value = parseFloat(m[1]);
  const suffix = m[2].toLowerCase();
  let coeff = 1.0;
  switch (suffix) {
    case 'k':
      coeff = 1000 ** 1;
      break;
    case 'm':
      coeff = 1000 ** 2;
      break;
    case 'g':
      coeff = 1000 ** 3;
      break;
    case 't':
      coeff = 1000 ** 4;
      break;
    case 'p':
      coeff = 1000 ** 5;
      break;
    case 'e':
      coeff = 1000 ** 6;
      break;
    case 'ki':
      coeff = 1024 ** 1;
      break;
    case 'mi':
      coeff = 1024 ** 2;
      break;
    case 'gi':
      coeff = 1024 ** 3;
      break;
    case 'ti':
      coeff = 1024 ** 4;
      break;
    case 'pi':
      coeff = 1024 ** 5;
      break;
    case 'ei':
      coeff = 1024 ** 6;
      break;
    default:
      coeff = 1.0;
      break;
  }

  return value * coeff;
}

export function parseCoreString(str: string): number {
  const m = str.match(/(\d+e?\.?\d*)(\w*)/);
  if (m === null) {
    logger.warn(`Failed to parse core string: ${str}`);
    return 0.0;
  }

  const value = parseFloat(m[1]);
  const suffix = m[2].toLowerCase();
  let coeff = 1.0;
  switch (suffix) {
    case 'm':
      coeff = 1000;
      break;
    default:
      coeff = 1.0;
      break;
  }

  return value / coeff;
}

export function checkCustomWindow(window: string): boolean {
  // Example ISO interval string: 2020-12-02T00:00:00Z,2020-12-03T23:59:59Z
  const customDateRegex =
    /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z,\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/;
  return customDateRegex.test(window);
}

export function toDisplayDateRange(window: string): string {
  const start = new Date();
  start.setUTCHours(0, 0, 0, 0);

  const end = new Date();
  end.setUTCHours(0, 0, 0, 0);

  switch (window) {
    case 'today':
      return 'Today';
    case 'yesterday':
      return 'Yesterday';
    case 'week':
      return 'Week-to-date';
    case 'month':
      return 'Month-to-date';
    case 'lastweek':
      return 'Last week';
    case 'lastmonth':
      return 'Last month';
    default:
  }

  const daysRegExp = /^(\d+)d$/;
  const daysMatch = window.match(daysRegExp);
  if (daysMatch) {
    return `Last ${daysMatch[1]} days`;
  }

  const hoursRegExp = /^(\d+)h$/;
  const hoursMatch = window.match(hoursRegExp);
  if (hoursMatch) {
    return `Last ${hoursMatch[1]} hours`;
  }

  const splitDates = window.split(',');
  if (checkCustomWindow(window) && splitDates.length > 1) {
    const s = splitDates[0].split(/\D+/).slice(0, 3);
    const e = splitDates[1].split(/\D+/).slice(0, 3);
    if (s.length === 3 && e.length === 3) {
      const sy = parseInt(s[0], 10);
      const sm = parseInt(s[1], 10) - 1;
      const sd = parseInt(s[2], 10);
      start.setUTCFullYear(sy, sm, sd);

      const ey = parseInt(e[0], 10);
      const em = parseInt(e[1], 10) - 1;
      const ed = parseInt(e[2], 10);
      end.setUTCFullYear(ey, em, ed);
      end.setDate(end.getDate() - 1);

      const startStr = start.toISOString().slice(0, 10);
      const endStr = end.toISOString().slice(0, 10);
      if (startStr === endStr) {
        return startStr;
      }

      return `${startStr} through ${endStr}`;
    }
  }

  return window;
}

export function toVerboseTimeRange(window: string): string {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const start = new Date();
  start.setUTCHours(0, 0, 0, 0);

  const end = new Date();
  end.setUTCHours(0, 0, 0, 0);

  switch (window) {
    case 'today':
      return `${start.getUTCDate()} ${
        months[start.getUTCMonth()]
      } ${start.getUTCFullYear()}`;
    case 'yesterday':
      start.setUTCDate(start.getUTCDate() - 1);
      return `${start.getUTCDate()} ${
        months[start.getUTCMonth()]
      } ${start.getUTCFullYear()}`;
    case 'week':
      start.setUTCDate(start.getUTCDate() - start.getUTCDay());
      return `${start.getUTCDate()} ${
        months[start.getUTCMonth()]
      } ${start.getUTCFullYear()} until now`;
    case 'month':
      start.setUTCDate(1);
      return `${start.getUTCDate()} ${
        months[start.getUTCMonth()]
      } ${start.getUTCFullYear()} until now`;
    case 'lastweek':
      start.setUTCDate(start.getUTCDate() - (start.getUTCDay() + 7));
      end.setUTCDate(end.getUTCDate() - (end.getUTCDay() + 1));
      return `${start.getUTCDate()} ${
        months[start.getUTCMonth()]
      } ${start.getUTCFullYear()} through ${end.getUTCDate()} ${
        months[end.getUTCMonth()]
      } ${end.getUTCFullYear()}`;
    case 'lastmonth':
      end.setUTCDate(1);
      end.setUTCDate(end.getUTCDate() - 1);
      start.setUTCDate(1);
      start.setUTCDate(start.getUTCDate() - 1);
      start.setUTCDate(1);
      return `${start.getUTCDate()} ${
        months[start.getUTCMonth()]
      } ${start.getUTCFullYear()} through ${end.getUTCDate()} ${
        months[end.getUTCMonth()]
      } ${end.getUTCFullYear()}`;
    case '7d':
      start.setUTCDate(start.getUTCDate() - 6);
      return `${start.getUTCDate()} ${
        months[start.getUTCMonth()]
      } ${start.getUTCFullYear()} through now`;
    case '30d':
      start.setUTCDate(start.getUTCDate() - 29);
      return `${start.getUTCDate()} ${
        months[start.getUTCMonth()]
      } ${start.getUTCFullYear()} through now`;
    case '60d':
      start.setUTCDate(start.getUTCDate() - 59);
      return `${start.getUTCDate()} ${
        months[start.getUTCMonth()]
      } ${start.getUTCFullYear()} through now`;
    case '90d':
      start.setUTCDate(start.getUTCDate() - 89);
      return `${start.getUTCDate()} ${
        months[start.getUTCMonth()]
      } ${start.getUTCFullYear()} through now`;
    default:
  }

  const splitDates = window.split(',');
  if (checkCustomWindow(window) && splitDates.length > 1) {
    const s = splitDates[0].split(/\D+/).slice(0, 3);
    const e = splitDates[1].split(/\D+/).slice(0, 3);
    if (s.length === 3 && e.length === 3) {
      const sy = parseInt(s[0], 10);
      const sm = parseInt(s[1], 10) - 1;
      const sd = parseInt(s[2], 10);
      start.setUTCFullYear(sy, sm, sd);

      const ey = parseInt(e[0], 10);
      const em = parseInt(e[1], 10) - 1;
      const ed = parseInt(e[2], 10);
      end.setUTCFullYear(ey, em, ed);
      end.setDate(end.getDate() - 1);

      const startStr = `${start.getUTCDate()} ${
        months[start.getUTCMonth()]
      } ${start.getUTCFullYear()}`;
      const endStr = `${end.getUTCDate()} ${
        months[end.getUTCMonth()]
      } ${end.getUTCFullYear()}`;
      if (startStr === endStr) {
        return startStr;
      }

      return `${startStr} through ${endStr}`;
    }
  }

  return window;
}

export function toDatedTimeRange(window: string): string {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const start = new Date();
  start.setUTCHours(0, 0, 0, 0);

  const end = new Date();
  end.setUTCHours(0, 0, 0, 0);

  const getFormattedDateRange = (start: Date, end?: Date) => {
    const startMonth = months[start.getUTCMonth()];
    const startDay = start.getUTCDate();
    if (!end) {
      return `${startMonth} ${startDay}`;
    }
    const endMonth = months[end.getUTCMonth()];
    const endDay = end.getUTCDate();
    if (startMonth === endMonth) {
      return `${startMonth} ${startDay} - ${endDay}`;
    }
    return `${startMonth} ${startDay} - ${endMonth} ${endDay}`;
  };

  switch (window) {
    case 'today':
      return getFormattedDateRange(start);
    case 'yesterday':
      start.setUTCDate(start.getUTCDate() - 1);
      return getFormattedDateRange(start);
    case 'week':
      start.setUTCDate(start.getUTCDate() - start.getUTCDay());
      return getFormattedDateRange(start, new Date());
    case 'month':
      start.setUTCDate(1);
      return getFormattedDateRange(start, new Date());
    case 'lastweek':
      start.setUTCDate(start.getUTCDate() - (start.getUTCDay() + 7));
      end.setUTCDate(end.getUTCDate() - (end.getUTCDay() + 1));
      return getFormattedDateRange(start, end);
    case 'lastmonth':
      end.setUTCDate(1);
      end.setUTCDate(end.getUTCDate() - 1);
      start.setUTCDate(1);
      start.setUTCDate(start.getUTCDate() - 1);
      start.setUTCDate(1);
      return getFormattedDateRange(start, end);
    case '3d':
      start.setUTCDate(start.getUTCDate() - 2);
      return getFormattedDateRange(start, new Date());
    case '7d':
      start.setUTCDate(start.getUTCDate() - 6);
      return getFormattedDateRange(start, new Date());
    case '30d':
      start.setUTCDate(start.getUTCDate() - 29);
      return getFormattedDateRange(start, new Date());
    case '60d':
      start.setUTCDate(start.getUTCDate() - 59);
      return getFormattedDateRange(start, new Date());
    case '90d':
      start.setUTCDate(start.getUTCDate() - 89);
      return getFormattedDateRange(start, new Date());
    default:
  }

  const splitDates = window.split(',');
  if (checkCustomWindow(window) && splitDates.length > 1) {
    const s = splitDates[0].split(/\D+/).slice(0, 3);
    const e = splitDates[1].split(/\D+/).slice(0, 3);
    if (s.length === 3 && e.length === 3) {
      const sy = parseInt(s[0], 10);
      const sm = parseInt(s[1], 10) - 1;
      const sd = parseInt(s[2], 10);
      start.setUTCFullYear(sy, sm, sd);

      const ey = parseInt(e[0], 10);
      const em = parseInt(e[1], 10) - 1;
      const ed = parseInt(e[2], 10);
      end.setUTCFullYear(ey, em, ed);
      end.setDate(end.getDate() - 1);

      const startStr = `${start.getUTCDate()} ${
        months[start.getUTCMonth()]
      } ${start.getUTCFullYear()}`;
      const endStr = `${end.getUTCDate()} ${
        months[end.getUTCMonth()]
      } ${end.getUTCFullYear()}`;
      if (startStr === endStr) {
        return startStr;
      }

      return `${startStr} - ${endStr}`;
    }
  }

  return window;
}

export function lowerFirst(window: string): string {
  if (!window || window.length === 0) {
    return '';
  }

  return window.slice(0, 1).toLowerCase() + window.slice(1);
}

export function strikeThroughText(text: string): string {
  return text
    .split('')
    .map((char) => `${char}\u0336`)
    .join('');
}

/**
 * Accept a Date and return the MM/dd string format.
 */
export function monthDay(date: Date): string {
  let d;
  let m;
  const month = date.getMonth() + 1;
  if (month < 10) {
    m = `0${month}`;
  } else {
    m = `${month}`;
  }
  const day = date.getDate();
  if (day < 10) {
    d = `0${day}`;
  } else {
    d = `${day}`;
  }
  return `${m}/${d}`;
}

/**
 * Accept a Date and return the YYYY-MM-dd string format.
 */
export function yearMonthDay(date: Date): string {
  let d;
  let m;
  const month = date.getMonth() + 1;
  if (month < 10) {
    m = `0${month}`;
  } else {
    m = month;
  }
  const day = date.getDate();
  if (day < 10) {
    d = `0${day}`;
  } else {
    d = day;
  }
  const year = date.getFullYear();
  return `${year}-${m}-${d}`;
}

export function pluralize(s: string, count: number) {
  return `${s}${count > 1 ? 's' : ''}`;
}
