import forEach from 'lodash/forEach';
import get from 'lodash/get';
import { Allocation, SummaryAllocation } from '../../types/allocation';

// rangeToCumulative takes an AllocationSetRange (type: array[AllocationSet])
// and accumulates the values into a single AllocationSet (type: object)
export function rangeToCumulative(
  allocationSetRange: Allocation[][],
  aggregateBy: Array<string>,
): Record<string, Allocation> {
  const result: Record<string, Allocation> = {};
  if (allocationSetRange.length === 0) {
    return result;
  }

  const efficiencyStore: Record<string, Record<string, number>> = {};

  forEach(allocationSetRange, (allocSet) => {
    forEach(allocSet, (alloc) => {
      const hrs = get(alloc, 'minutes', 0) / 60.0;
      if (result[alloc.name] === undefined) {
        result[alloc.name] = {
          ...alloc,
          name: alloc.name,
          [aggregateBy.join(',')]: alloc.name,
          start: get(alloc, 'start', ''),
          end: get(alloc, 'end', ''),
          window: get(alloc, 'window', { start: '', end: '' }),
          cpuCost: get(alloc, 'cpuCost', 0),
          cpuCoreHours: get(alloc, 'cpuCoreHours', 0),
          cpuCostAdjustment: get(alloc, 'cpuCostAdjustment', 0),
          gpuCost: get(alloc, 'gpuCost', 0),
          gpuHours: get(alloc, 'gpuHours', 0),
          gpuCostAdjustment: get(alloc, 'gpuCostAdjustment', 0),
          ramCost: get(alloc, 'ramCost', 0),
          ramByteHours: get(alloc, 'ramByteHours', 0),
          ramCostAdjustment: get(alloc, 'ramCostAdjustment', 0),
          pvCost: get(alloc, 'pvCost', 0),
          pvCostAdjustment: get(alloc, 'pvCostAdjustment', 0),
          networkCost: get(alloc, 'networkCost', 0),
          networkCostAdjustment: get(alloc, 'networkCostAdjustment', 0),
          networkReceiveBytes: get(alloc, 'networkReceiveBytes', 0),
          networkTransferBytes: get(alloc, 'networkTransferBytes', 0),
          loadBalancerCost: get(alloc, 'loadBalancerCost', 0),
          loadBalancerCostAdjustment: get(
            alloc,
            'loadBalancerCostAdjustment',
            0,
          ),
          sharedCost: get(alloc, 'sharedCost', 0),
          externalCost: get(alloc, 'externalCost', 0),
          totalCost: get(alloc, 'totalCost', 0),
          cpuCoreUsageAverage: get(alloc, 'cpuCoreUsageAverage', 0),
          cpuCoreRequestAverage: get(alloc, 'cpuCoreRequestAverage', 0),
          ramByteUsageAverage: get(alloc, 'ramByteUsageAverage', 0),
          ramByteRequestAverage: get(alloc, 'ramByteRequestAverage', 0),
          cpuEfficiency: get(alloc, 'cpuEfficiency', 0),
          ramEfficiency: get(alloc, 'ramEfficiency', 0),
          totalEfficiency: get(alloc, 'totalEfficiency', 0),
          minutes: get(alloc, 'minutes', 0),
        };
        efficiencyStore[alloc.name] = {
          cpuUseCoreHrs: get(alloc, 'cpuCoreUsageAverage', 0) * hrs,
          cpuReqCoreHrs: get(alloc, 'cpuCoreRequestAverage', 0) * hrs,
          ramUseByteHrs: get(alloc, 'ramByteUsageAverage', 0) * hrs,
          ramReqByteHrs: get(alloc, 'ramByteRequestAverage', 0) * hrs,
        };
      } else {
        result[alloc.name].cpuCost += get(alloc, 'cpuCost', 0);
        result[alloc.name].cpuCostAdjustment += get(
          alloc,
          'cpuCostAdjustment',
          0,
        );
        result[alloc.name].gpuCost += get(alloc, 'gpuCost', 0);
        result[alloc.name].gpuCostAdjustment += get(
          alloc,
          'gpuCostAdjustment',
          0,
        );
        result[alloc.name].ramCost += get(alloc, 'ramCost', 0);
        result[alloc.name].ramCostAdjustment += get(
          alloc,
          'ramCostAdjustment',
          0,
        );
        result[alloc.name].pvCost += get(alloc, 'pvCost', 0);
        result[alloc.name].pvCostAdjustment += get(
          alloc,
          'pvCostAdjustment',
          0,
        );
        result[alloc.name].networkCost += get(alloc, 'networkCost', 0);
        result[alloc.name].networkCostAdjustment += get(
          alloc,
          'networkCostAdjustment',
          0,
        );
        result[alloc.name].loadBalancerCost += get(
          alloc,
          'loadBalancerCost',
          0,
        );
        result[alloc.name].loadBalancerCostAdjustment += get(
          alloc,
          'loadBalancerCostAdjustment',
          0,
        );
        result[alloc.name].sharedCost += get(alloc, 'sharedCost', 0);
        result[alloc.name].externalCost += get(alloc, 'externalCost', 0);
        result[alloc.name].totalCost += get(alloc, 'totalCost', 0);
        result[alloc.name].cpuCoreUsageAverage += get(
          alloc,
          'cpuCoreUsageAverage',
          0,
        );
        result[alloc.name].cpuCoreRequestAverage += get(
          alloc,
          'cpuCoreRequestAverage',
          0,
        );
        result[alloc.name].ramByteUsageAverage += get(
          alloc,
          'ramByteUsageAverage',
          0,
        );
        result[alloc.name].ramByteRequestAverage += get(
          alloc,
          'ramByteRequestAverage',
          0,
        );
        result[alloc.name].minutes += get(alloc, 'minutes', 0);

        efficiencyStore[alloc.name].cpuUseCoreHrs +=
          get(alloc, 'cpuCoreUsageAverage', 0) * hrs;
        efficiencyStore[alloc.name].cpuReqCoreHrs +=
          get(alloc, 'cpuCoreRequestAverage', 0) * hrs;
        efficiencyStore[alloc.name].ramUseByteHrs +=
          get(alloc, 'ramByteUsageAverage', 0) * hrs;
        efficiencyStore[alloc.name].ramReqByteHrs +=
          get(alloc, 'ramByteRequestAverage', 0) * hrs;
      }
    });
  });

  // If the range is of length > 1 (i.e. it is not just a single set) then
  // compute efficiency for each result after accumulating.
  if (allocationSetRange.length > 1) {
    forEach(result, (alloc, name) => {
      // If we can't compute total efficiency, it defaults to 0.0
      let totalEfficiency = 0.0;

      // CPU efficiency is defined as (usage/request). If request == 0.0 but
      // usage > 0, then efficiency gets set to 1.0.
      let cpuEfficiency = 0.0;
      if (efficiencyStore[alloc.name].cpuReqCoreHrs > 0) {
        cpuEfficiency =
          efficiencyStore[alloc.name].cpuUseCoreHrs /
          efficiencyStore[alloc.name].cpuReqCoreHrs;
      } else if (efficiencyStore[alloc.name].cpuUseCoreHrs > 0) {
        cpuEfficiency = 1.0;
      }

      // RAM efficiency is defined as (usage/request). If request == 0.0 but
      // usage > 0, then efficiency gets set to 1.0.
      let ramEfficiency = 0.0;
      if (efficiencyStore[alloc.name].ramReqByteHrs > 0) {
        ramEfficiency =
          efficiencyStore[alloc.name].ramUseByteHrs /
          efficiencyStore[alloc.name].ramReqByteHrs;
      } else if (efficiencyStore[alloc.name].ramUseByteHrs > 0) {
        ramEfficiency = 1.0;
      }

      // Compute efficiency as the cost-weighted average of CPU and RAM
      // efficiency
      if (alloc.cpuCost + alloc.ramCost > 0.0) {
        totalEfficiency =
          (alloc.cpuCost * cpuEfficiency + alloc.ramCost * ramEfficiency) /
          (alloc.cpuCost + alloc.ramCost);
      }

      result[name].cpuEfficiency = cpuEfficiency;
      result[name].ramEfficiency = ramEfficiency;
      result[name].totalEfficiency = totalEfficiency;
    });
  }

  return result;
}

// cumulativeToTotals adds each entry in the given AllocationSet (type: object)
// and returns a single Allocation (type: object) representing the totals
export function cumulativeToTotals(
  allocationSet: Record<string, Allocation>,
): AllocationTotals {
  const totals = {
    name: 'Totals',
    cpuCost: 0,
    cpuCostAdjustment: 0,
    gpuCost: 0,
    gpuCostAdjustment: 0,
    ramCost: 0,
    ramCostAdjustment: 0,
    pvCost: 0,
    pvCostAdjustment: 0,
    networkCost: 0,
    networkCostAdjustment: 0,
    loadBalancerCost: 0,
    loadBalancerCostAdjustment: 0,
    sharedCost: 0,
    externalCost: 0,
    totalCost: 0,
    cpuEfficiency: 0,
    ramEfficiency: 0,
    totalEfficiency: 0,
    cpuReqCoreHrs: 0,
    cpuUseCoreHrs: 0,
    ramReqByteHrs: 0,
    ramUseByteHrs: 0,
  };

  // Use these for computing efficiency. As such, idle will not factor into
  // these numbers, including CPU and RAM cost.
  let cpuReqCoreHrs = 0;
  let cpuUseCoreHrs = 0;
  let ramReqByteHrs = 0;
  let ramUseByteHrs = 0;
  let cpuCost = 0;
  let ramCost = 0;

  forEach(allocationSet, (alloc, name) => {
    // Accumulate efficiency-related fields
    if (name !== '__idle__') {
      const hrs = get(alloc, 'minutes', 0) / 60.0;
      cpuReqCoreHrs += get(alloc, 'cpuCoreRequestAverage', 0.0) * hrs;
      cpuUseCoreHrs += get(alloc, 'cpuCoreUsageAverage', 0.0) * hrs;
      ramReqByteHrs += get(alloc, 'ramByteRequestAverage', 0.0) * hrs;
      ramUseByteHrs += get(alloc, 'ramByteUsageAverage', 0.0) * hrs;
      cpuCost +=
        get(alloc, 'cpuCost', 0.0) + get(alloc, 'cpuCostAdjustment', 0.0);
      ramCost +=
        get(alloc, 'ramCost', 0.0) + get(alloc, 'ramCostAdjustment', 0.0);
    }

    // Sum cumulative fields
    totals.cpuCost += get(alloc, 'cpuCost', 0);
    totals.cpuCostAdjustment += get(alloc, 'cpuCostAdjustment', 0);
    totals.gpuCost += get(alloc, 'gpuCost', 0);
    totals.gpuCostAdjustment += get(alloc, 'gpuCostAdjustment', 0);
    totals.ramCost += get(alloc, 'ramCost', 0);
    totals.ramCostAdjustment += get(alloc, 'ramCostAdjustment', 0);
    totals.pvCost += get(alloc, 'pvCost', 0);
    totals.pvCostAdjustment += get(alloc, 'pvCostAdjustment', 0);
    totals.networkCost += get(alloc, 'networkCost', 0);
    totals.networkCostAdjustment += get(alloc, 'networkCostAdjustment', 0);
    totals.loadBalancerCost += get(alloc, 'loadBalancerCost', 0);
    totals.loadBalancerCostAdjustment += get(
      alloc,
      'loadBalancerCostAdjustment',
      0,
    );
    totals.sharedCost += get(alloc, 'sharedCost', 0);
    totals.externalCost += get(alloc, 'externalCost', 0);
    totals.totalCost += get(alloc, 'totalCost', 0);
  });

  // Compute efficiency
  if (cpuReqCoreHrs > 0) {
    totals.cpuEfficiency = cpuUseCoreHrs / cpuReqCoreHrs;
  } else if (cpuUseCoreHrs > 0) {
    totals.cpuEfficiency = 1.0;
  }

  if (ramReqByteHrs > 0) {
    totals.ramEfficiency = ramUseByteHrs / ramReqByteHrs;
  } else if (ramUseByteHrs > 0) {
    totals.ramEfficiency = 1.0;
  }

  if (cpuCost + ramCost > 0) {
    totals.totalEfficiency =
      (cpuCost * totals.cpuEfficiency + ramCost * totals.ramEfficiency) /
      (cpuCost + ramCost);
  }

  totals.cpuReqCoreHrs = cpuReqCoreHrs;
  totals.cpuUseCoreHrs = cpuUseCoreHrs;
  totals.ramReqByteHrs = ramReqByteHrs;
  totals.ramUseByteHrs = ramUseByteHrs;

  return totals;
}

// filterIdle deletes idle allocations from an AllocationSet (type: object)
export function filterIdle(
  allocationSet: Record<string, Allocation | SummaryAllocation>,
): Record<string, Allocation | SummaryAllocation> {
  const result: Record<string, Allocation | SummaryAllocation> = {};

  forEach(allocationSet, (alloc) => {
    if (!isIdle(alloc)) {
      result[alloc.name] = allocationSet[alloc.name];
    }
  });

  return result;
}

// true if the allocation represents idle spend
export function isIdle(allocation: Allocation | SummaryAllocation): boolean {
  return allocation.name.indexOf('__idle__') >= 0;
}

// Retrieve cost with adjustment applied if it exists
export function getCost(
  obj: Allocation | AllocationTotals,
  field: string,
  adjustment = 'Adjustment',
): number {
  const baseCost = get(obj, field, 0.0);
  const adjustmentCost = get(obj, field + adjustment, 0.0);
  return baseCost + adjustmentCost;
}

export default {
  rangeToCumulative,
  cumulativeToTotals,
  filterIdle,
  isIdle,
  getCost,
};

export interface AllocationTotals {
  name: string;
  cpuCost: number;
  cpuCostAdjustment: number;
  gpuCost: number;
  gpuCostAdjustment: number;
  ramCost: number;
  ramCostAdjustment: number;
  pvCost: number;
  pvCostAdjustment: number;
  networkCost: number;
  networkCostAdjustment: number;
  loadBalancerCost: number;
  loadBalancerCostAdjustment: number;
  sharedCost: number;
  externalCost: number;
  totalCost: number;
  cpuEfficiency: number;
  ramEfficiency: number;
  totalEfficiency: number;
  cpuReqCoreHrs: number;
  cpuUseCoreHrs: number;
  ramReqByteHrs: number;
  ramUseByteHrs: number;
}
