import model from '../../services/model'

interface OrphanedResourcesResponse {
  resourceKind: string;
  region: string;
  description: Map<string, string>;
  diskSizeInGB: number;
  diskName: string;
  ipAddress: string;
  monthlyCost: number; 
};

async function fetchOrphanedResources(): Promise<OrphanedResourcesResponse[]> {
  const response = await model.get<{ data: OrphanedResourcesResponse[] }>(
    '/savings/orphanedResources',
  );

  return response.data;
}

export {
  fetchOrphanedResources,
  OrphanedResourcesResponse,
}