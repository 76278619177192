import { Snackbar } from '@material-ui/core';
import React, { useReducer } from 'react';
import MuiAlert from '@material-ui/lab/Alert';

import { Button } from '@kubecost-frontend/holster';
import AlertService, { Alert } from '../../services/alerts';

interface TestAlertButtonProps {
  alert: Alert;
  validate?: () => Promise<boolean>;
}

type TestStatusAction =
  | { type: 'test' }
  | { type: 'testFail'; message: string }
  | { type: 'testSuceeded' }
  | { type: 'reset' };

type TestStatusState = {
  checkSucceeded: boolean;
  checkFailed: boolean;
  failureMessage: string;
  loading: boolean;
};

const initialTestStatusState: TestStatusState = {
  checkSucceeded: false,
  checkFailed: false,
  failureMessage: '',
  loading: false,
};

const TestAlertButton = ({ alert, validate }: TestAlertButtonProps) => {
  const [{ checkFailed, checkSucceeded, failureMessage, loading }, dispatch] =
    useReducer(
      (state: TestStatusState, action: TestStatusAction): TestStatusState => {
        switch (action.type) {
          case 'test':
            return {
              ...state,
              checkFailed: false,
              checkSucceeded: false,
              failureMessage: '',
              loading: true,
            };
          case 'testFail':
            return {
              ...state,
              checkFailed: true,
              checkSucceeded: false,
              failureMessage: action.message,
              loading: false,
            };
          case 'testSuceeded':
            return {
              ...state,
              checkFailed: false,
              checkSucceeded: true,
              failureMessage: '',
              loading: false,
            };
          case 'reset':
            return {
              ...state,
              checkFailed: false,
              checkSucceeded: false,
              failureMessage: '',
              loading: false,
            };
        }
      },
      initialTestStatusState,
    );

  const testClicked = async () => {
    if (validate !== undefined && (await validate()) === false) return;
    dispatch({ type: 'test' });
    let response = await AlertService.testAlert(alert);
    if (response.status === 204) {
      dispatch({
        type: 'testSuceeded',
      });
    } else {
      let message = await response.text();
      dispatch({
        type: 'testFail',
        message,
      });
    }
  };

  return (
    <>
      <Button
        variant="default"
        data-testid="test-alert-button"
        disabled={loading}
        onClick={async () => {
          testClicked();
        }}
      >
        {loading ? 'Testing...' : 'Test Alert'}
      </Button>

      <Snackbar
        open={checkFailed}
        autoHideDuration={4000}
        onClose={() => dispatch({ type: 'reset' })}
      >
        <MuiAlert
          onClose={() => dispatch({ type: 'reset' })}
          data-testid="test-alert-failure"
          severity="error"
        >
          {failureMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={checkSucceeded}
        autoHideDuration={4000}
        onClose={() => dispatch({ type: 'reset' })}
      >
        <MuiAlert
          onClose={() => dispatch({ type: 'reset' })}
          severity="success"
          data-testid="test-alert-success"
        >
          Alert sent successfully!
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default TestAlertButton;
