import React from 'react';
import { Typography } from '@kubecost-frontend/holster';
import SearchIcon from '@material-ui/icons/Search';

const EmptyAllocationData = () => {
  return (
    <div className="w-full h-48 text-center p-16 mb-8">
      <SearchIcon
        style={{ height: '4rem', width: '4rem', marginBottom: '2rem' }}
      />
      <Typography variant="h5">
        We looked everywhere and couldn't find anything!
      </Typography>
      <Typography variant="p">
        Try adjusting your report parameters and try again!
      </Typography>
    </div>
  );
};

export default EmptyAllocationData;
