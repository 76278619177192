// react
import React, { FC } from 'react';

// recharts
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

// local
import { useClusters } from '../../../contexts/ClusterConfig';
import { accents } from '../../../services/colors';
import { toCurrency } from '../../../services/format';
import { TopResultsGraph } from '../../../services/model';

interface SummaryChartProps {
  data: TopResultsGraph;
  height: number;
}

const SummaryChart: FC<SummaryChartProps> = ({ height, data }) => {
  const { modelConfig } = useClusters();
  const pieData = data.items.map(({ name, cost }, i) => {
    let fill;
    if (name === '__unallocated__') {
      fill = '#212121';
    } else {
      fill = accents[i % accents.length];
    }
    return { name, value: cost, fill };
  });

  // `params: any` is bad, but that's the function signature specified in Recharts'
  // own type definitions for this callback.
  const renderLabel = (params: any) => {
    const { cx, cy, midAngle, outerRadius, percent, name, fill, value } =
      params;

    const RADIAN = Math.PI / 180;
    const radius = outerRadius * 1.1;
    let x = cx + radius * Math.cos(-midAngle * RADIAN);
    x += x > cx ? 2 : -2;
    let y = cy + radius * Math.sin(-midAngle * RADIAN);
    // y -= Math.min(Math.abs(2 / Math.cos(-midAngle * RADIAN)), 8)

    if (percent < 0.02) {
      return undefined;
    }

    return (
      <text
        x={x}
        y={y}
        fill={fill}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${name}: ${toCurrency(value, modelConfig.currencyCode)} (${(
          percent * 100
        ).toFixed(1)}%)`}
      </text>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={height}>
      <PieChart>
        <Pie
          data={pieData}
          dataKey="value"
          nameKey="name"
          label={renderLabel}
          labelLine
          // niko: if tooltips error, try disabling animation
          // isAnimationActive={false}
          animationDuration={400}
          cy="90%"
          outerRadius="140%"
          innerRadius="60%"
          startAngle={180}
          endAngle={0}
        >
          {pieData.map((datum, i) => (
            <Cell key={i} fill={datum.fill} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default SummaryChart;
