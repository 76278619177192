// react
import React, { useEffect, useState } from 'react';

// mui
import Link from '@material-ui/core/Link';

// holster
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@kubecost-frontend/holster';

// local
import { bytesToString } from '../../../services/format';
import { toCurrency } from '../../../services/format';
import model from '../../../services/model';
import { useClusters } from '../../../contexts/ClusterConfig';
import { InspectLink } from './InspectLink';

interface NetworkCostsTileProps {
  resourceName: string;
  currency: string;
}

export const NetworkCostsTile = ({
  resourceName,
  currency,
}: NetworkCostsTileProps) => {
  const {
    clusterConfig: { thanosEnabled },
  } = useClusters();
  // whether the network daemonset is configured
  const [configured, setConfigured] = useState(false);
  const [configLoaded, setConfigLoaded] = useState(false);

  // whether data has loaded
  const [loading, setLoading] = useState(true);

  // whether there is traffic for this namespace
  const [trafficForNamespace, setTrafficForNamespace] = useState(false);

  // network data to display
  const [networkCostsByService, setNetworkCostsByService] =
    useState<[string, { cost: number; bytes: number }][]>();

  useEffect(() => {
    if (configLoaded) {
      return;
    }
    checkForNetworkDaemonset();
  }, [configLoaded]);

  useEffect(() => {
    fetchData();
  }, []);

  if (!configLoaded) {
    return (
      <div className="h-full flex items-center justify-center text-center">
        <div>
          <Typography variant="h5">Loading network costs...</Typography>
        </div>
      </div>
    );
  } else if (!configured) {
    const title = 'Network Traffic pod not configured.';
    const buttonText = 'Visit Documentation';
    const linkHref =
      'https://guide.kubecost.com/hc/en-us/articles/4407595973527-Network-Traffic-Cost-Allocation';

    return (
      <div className="h-full flex items-center justify-center text-center">
        <div>
          <Typography variant="h5">{title}</Typography>
          <div className="flex justify-center">
            <InspectLink text={buttonText} href={linkHref} />
          </div>
        </div>
      </div>
    );
  } else if (loading) {
    return (
      <div className="h-full flex items-center justify-center text-center">
        <div>
          <Typography variant="h5">Loading network costs...</Typography>
        </div>
      </div>
    );
  } else if (networkCostsByService && networkCostsByService.length) {
    return (
      <div>
        <Table className="w-full">
          <TableHead>
            <TableRow>
              <TableCell>Service</TableCell>
              <TableCell>Egress</TableCell>
              <TableCell>Cost (7d)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {networkCostsByService.map(([service, { cost, bytes }]) => (
              <TableRow key={service}>
                <TableCell>{service}</TableCell>
                <TableCell>{bytesToString(bytes)}</TableCell>
                <TableCell>{toCurrency(cost, currency)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className="w-full flex justify-center mt-2">
          <Link
            target="_blank"
            href={`./network.html?window=7d&ns=${resourceName}`}
          >
            <Button variant="primary">Visit Network page</Button>
          </Link>
        </div>
      </div>
    );
  } else if (trafficForNamespace) {
    const title = `No Service-based Network information available for ${resourceName}`;
    const buttonText = `Visit Network Page for ${resourceName}`;
    const linkHref = `./network.html?window=7d&ns=${resourceName}`;
    return (
      <div className="h-full flex items-center justify-center text-center">
        <div>
          <Typography variant="h5">{title}</Typography>
          <div className="flex justify-center">
            <InspectLink text={buttonText} href={linkHref} />
          </div>
        </div>
      </div>
    );
  } else {
    const title = `No Network information available for ${resourceName}`;
    const buttonText = 'Visit Full Network Page';
    const linkHref = `./network.html?window=7d`;
    return (
      <div className="h-full flex items-center justify-center text-center">
        <div>
          <Typography variant="h5">{title}</Typography>
          <div className="flex justify-center">
            <InspectLink text={buttonText} href={linkHref} />
          </div>
        </div>
      </div>
    );
  }

  async function checkForNetworkDaemonset() {
    try {
      const networkAPIResponse = await model.getNetworkTraffic(resourceName);
      if (networkAPIResponse.code > 299) {
        setConfigured(false);
      } else {
        setConfigured(true);
      }
    } catch (err) {
      setConfigured(false);
    } finally {
      setConfigLoaded(true);
    }
  }

  async function fetchData() {
    // query traffic for internet, cross-region, and cross-zone within a region.
    // in-zone traffic is free.
    const internetTrafficByServiceQuery = getTrafficQuery('internet="true"');
    const crossRegionTrafficByServiceQuery = getTrafficQuery(
      'internet="false", sameRegion="false", sameZone="false"',
    );
    const crossZoneTrafficByServiceQuery = getTrafficQuery(
      'internet="false", sameRegion="true", sameZone="false"',
    );

    // query average costs for each traffic type
    const averageInternetCostPerGBQuery =
      'avg(kubecost_network_internet_egress_cost)';
    const averageCrossRegionCostPerGBQuery =
      'avg(kubecost_network_region_egress_cost)';
    const averageCrossZoneCostPerGBQuery =
      'avg(kubecost_network_zone_egress_cost)';

    // query whether we have traffic for this namespace, but not service labels
    const namespaceTrafficQuery = `sort_desc(sum(increase(kubecost_pod_network_egress_bytes_total{namespace="${resourceName}"}[7d])) by (namespace))`;

    const [
      internetTrafficByService,
      crossRegionTrafficByService,
      crossZoneTrafficByService,
      averageInternetCostPerGB,
      averageCrossRegionCostPerGB,
      averageCrossZoneCostPerGB,
      namespaceTraffic,
    ] = await Promise.all([
      model.prometheusQuery(
        internetTrafficByServiceQuery,
        thanosEnabled === 'true',
      ),
      model.prometheusQuery(
        crossRegionTrafficByServiceQuery,
        thanosEnabled === 'true',
      ),
      model.prometheusQuery(
        crossZoneTrafficByServiceQuery,
        thanosEnabled === 'true',
      ),
      model.prometheusQuery(
        averageInternetCostPerGBQuery,
        thanosEnabled === 'true',
      ),
      model.prometheusQuery(
        averageCrossRegionCostPerGBQuery,
        thanosEnabled === 'true',
      ),
      model.prometheusQuery(
        averageCrossZoneCostPerGBQuery,
        thanosEnabled === 'true',
      ),
      model.prometheusQuery(namespaceTrafficQuery, thanosEnabled === 'true'),
    ]);

    setTrafficForNamespace(Boolean(namespaceTraffic.data.result.length));

    const costTotals: Record<string, { cost: number; bytes: number }> = {};

    // internet traffic costs per service
    if (averageInternetCostPerGB.data.result.length) {
      const costFactor =
        parseFloat(averageInternetCostPerGB.data.result[0].value[1]) /
        1024 /
        1024 /
        1024;
      internetTrafficByService.data.result.forEach(({ metric, value }) => {
        costTotals[metric.service] = costTotals[metric.service] || {
          bytes: 0,
          cost: 0,
        };
        const bytes = parseFloat(value[1]);
        costTotals[metric.service].bytes += bytes;
        costTotals[metric.service].cost += bytes * costFactor;
      });
    }

    // add cross-region traffic costs per service
    if (averageCrossRegionCostPerGB.data.result.length) {
      const costFactor =
        parseFloat(averageCrossRegionCostPerGB.data.result[0].value[1]) /
        1024 /
        1024 /
        1024;
      crossRegionTrafficByService.data.result.forEach(({ metric, value }) => {
        costTotals[metric.service] = costTotals[metric.service] || {
          bytes: 0,
          cost: 0,
        };
        const bytes = parseFloat(value[1]);
        costTotals[metric.service].bytes += bytes;
        costTotals[metric.service].cost += bytes * costFactor;
      });
    }

    // add cross-zone traffic costs per service
    if (averageCrossZoneCostPerGB.data.result.length) {
      const costFactor =
        parseFloat(averageCrossZoneCostPerGB.data.result[0].value[1]) /
        1024 /
        1024 /
        1024;
      crossZoneTrafficByService.data.result.forEach(({ metric, value }) => {
        costTotals[metric.service] = costTotals[metric.service] || {
          bytes: 0,
          cost: 0,
        };
        const bytes = parseFloat(value[1]);
        costTotals[metric.service].bytes += bytes;
        costTotals[metric.service].cost += bytes * costFactor;
      });
    }

    const costsArray = Object.entries(costTotals)
      .sort((a, b) => (a[1].cost > b[1].cost ? 1 : -1))
      .slice(0, 5);
    setNetworkCostsByService(costsArray);
    setLoading(false);
  }

  function getTrafficQuery(trafficLabel: string): string {
    return `sort_desc(sum(increase(kubecost_pod_network_egress_bytes_total{service!="", namespace="${resourceName}", ${trafficLabel}}[7d])) by (service))`;
  }
};
