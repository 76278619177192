import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CodeBlock, ocean } from 'react-code-blocks';
import { Button, Input, Typography } from '@kubecost-frontend/holster';

import { useClusters } from '../../contexts/ClusterConfig';
import { FetchStates } from '../../constants';
import model from '../../services/model';

const KeyInstructions: React.FC = () => {
  const navigate = useNavigate();
  const { baseApiUrl } = useClusters();

  const [serviceKey, setServiceKey] = React.useState('');
  const [projectId, setProjectId] = React.useState('');
  const [database, setDatabase] = React.useState('');

  const [readOnly, setReadOnly] = React.useState(true);

  const handleKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setServiceKey(e.target.value);
  };
  const handleProjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProjectId(e.target.value);
  };
  const handleDbChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDatabase(e.target.value);
  };
  const handleGoBack = () => {
    navigate(-1);
  };
  const handleUpdate = React.useCallback(async () => {
    if (!(projectId && database)) {
      // TODO: use a toast instead of a native alert
      alert('Please provide a project ID and database.');
      return;
    }
    let keyObj;
    try {
      keyObj = JSON.parse(serviceKey);
    } catch (err) {
      if (serviceKey.length == 0) {
        // Allow serviceKey to be empty
        console.log("Updating GCP credentials with no service key supplied...")
      } else {
        // TODO: use a toast instead of a native alert
        alert(
          'Error: invalid service key supplied. Ensure the full JSON object generated with the commands in this in Step #1 is provided.',
        );
        return;
      }
    }
    const payload = {
      projectID: projectId,
      billingDataDataset: database,
      key: keyObj,
    };

    const response = await fetch(`${baseApiUrl}/updateBigQueryInfoConfigs`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    if (response.status < 300) {
      alert(
        `Key configuration info successfully saved! Navigate to ${baseApiUrl.replace(
          /\/model$/,
          '',
        )}/diagnostics.html to view Cloud ETL status.`,
      );
    } else {
      console.log(response);
      alert(
        `Error: unable to set bigquery configuration. See developer console for more info. -- please contact us at team@kubecost.com or on Slack if we can assist.`,
      );
    }
  }, [serviceKey, projectId, database]);

  React.useEffect(() => {
    async function fetchData() {
      const configs = await model.getConfigs();

      setReadOnly(configs.readOnly !== 'false');

      if (configs && configs.projectID) {
        setProjectId(configs.projectID);
      }

      if (configs && configs.billingDataDataset) {
        setDatabase(configs.billingDataDataset);
      }

      if (configs.readOnly !== 'false') {
        alert('You are currently viewing with read-only access.');
      }
    }
    fetchData();
  }, []);

  return (
    <div style={{ fontSize: '0.8em', padding: 20, backgroundColor: '#ffffff' }}>
      <>
        <Typography variant="p" style={{ marginTop: 16, marginBottom: 10 }}>
          Provide Project ID (GCP project which contains the Big Query dataset)
        </Typography>
        <Input
          disabled={readOnly}
          style={{ width: '100%', border: '1px solid #BFCDC9' }}
          label="GCP ProjectID"
          onChange={handleProjectChange}
          value={projectId}
        />

        <Typography variant="p" style={{ marginTop: 36, marginBottom: 10 }}>
          Provide Big Query dataset (e.g.
          billing_data.gcp_billing_export_v1_01AC...) with billing data{' '}
          <a
            href="http://docs.kubecost.com/gcp-out-of-cluster.html#bq-name"
            target="_blank"
            style={{ color: '#28B359' }}
          >
            Learn more
          </a>
        </Typography>
        <Input
          disabled={readOnly}
          style={{
            marginBottom: 24,
            width: '100%',
            border: '1px solid #BFCDC9',
          }}
          label="Billing export dataset"
          onChange={handleDbChange}
          value={database}
        />

        <Typography variant="p" style={{ marginBottom: 10 }}>
          Set up a service account to allocate out of cluster resources (e.g. storage
          buckets and managed databases) back to their Kubernetes owners. If you
          don't already have a GCP service account, you can run the following
          commands in your command line to create one. Make sure
          your gcloud project is where your external costs are being run.
        </Typography>

        <CodeBlock
          text={`
    export PROJECT_ID=$(gcloud config get-value project)
    gcloud iam service-accounts create compute-viewer-kubecost --display-name "Compute Read Only Account Created For Kubecost" --format json
    gcloud projects add-iam-policy-binding $PROJECT_ID --member serviceAccount:compute-viewer-kubecost@$PROJECT_ID.iam.gserviceaccount.com --role roles/compute.viewer
    gcloud projects add-iam-policy-binding $PROJECT_ID --member serviceAccount:compute-viewer-kubecost@$PROJECT_ID.iam.gserviceaccount.com --role roles/bigquery.user
    gcloud projects add-iam-policy-binding $PROJECT_ID --member serviceAccount:compute-viewer-kubecost@$PROJECT_ID.iam.gserviceaccount.com --role roles/bigquery.dataViewer
    gcloud projects add-iam-policy-binding $PROJECT_ID --member serviceAccount:compute-viewer-kubecost@$PROJECT_ID.iam.gserviceaccount.com --role roles/bigquery.jobUser
  `}
          language="shell"
          showLineNumbers={false}
          theme={ocean}
        />

        <Typography variant="p" style={{ marginTop: 24, marginBottom: 10 }}>
          (Recommended) Follow the instructions in our docs to integrate your GCP service account via Workload Identity{' '}
          <a
            href="http://docs.kubecost.com/gcp-out-of-cluster.html#bq-name"
            target="_blank"
            style={{ color: '#28B359' }}
          >
            Learn more
          </a>
        </Typography>

        <Typography variant="p" style={{ marginTop: 16, marginBottom: 8 }}>
          Alternatively, create a key for the service account:
        </Typography>
        <CodeBlock
          text={`
    gcloud iam service-accounts keys create ./compute-viewer-kubecost-key.json --iam-account compute-viewer-kubecost@$PROJECT_ID.iam.gserviceaccount.com

    cat compute-viewer-kubecost-key.json
          `}
          language="shell"
          showLineNumbers={false}
          theme={ocean}
        />

        <Typography variant="p" style={{ marginTop: 24 }}>
          Paste your service key here (the entire JSON object from previous
          step):
        </Typography>
        <Input
          disabled={readOnly}
          style={{ width: '100%', border: '1px solid #BFCDC9', marginBottom: 24 }}
          label="Service key. Leave empty if you're integrating via Workload Identity."
          onChange={handleKeyChange}
          value={serviceKey}
        />

        <Button
          variant="primary"
          disabled={readOnly}
          onClick={handleUpdate}
          style={{ marginRight: 12 }}
        >
          Update
        </Button>
        <Button onClick={handleGoBack} variant="default">
          Back to previous page
        </Button>
      </>
    </div>
  );
};

export default KeyInstructions;
