import { Typography, CircularProgress, Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useClusters } from '../../contexts/ClusterConfig';
import { parseResponseJSON } from '../../services/util';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

interface ThanosDisplayItem {
  dataSource: string;
  label: string;
  queryString: string;
}

interface ThanosResultSet {
  establishedConnection: boolean;
  metricsAvailable: boolean;
  clusterIdsAvailable: boolean;
}

export const ThanosCheck = () => {
  const { thanosQueryUrl, baseApiUrl } = useClusters();
  const [thanosResults, setThanosResults] =
    useState<ThanosResultSet | null>(null);
  const [clusterIds, setClusterIds] = useState('');

  const thanosStatusResultList: ThanosDisplayItem[] = [
    {
      queryString: 'sum_over_time(node_cpu_hourly_cost[3h:10m])',
      dataSource: 'establishedConnection',
      label: `Established connection to Thanos store at ${baseApiUrl}`,
    },
    {
      queryString: 'sum(sum_over_time(container_fs_usage_bytes[3h:10m]))',
      dataSource: 'metricsAvailable',
      label: 'Check Kubecost metrics available in Thanos query results',
    },
    {
      queryString:
        'avg(sum_over_time(node_cpu_seconds_total[4h])) by (cluster_id)',
      dataSource: 'clusterIdsAvailable',
      label: `Check Unique cluster IDs with data available: ${clusterIds}`,
    },
  ];

  const runThanosQuery = (query: string) =>
    fetch(`${thanosQueryUrl}${query}`).then((res) => parseResponseJSON(res));

  const initializeThanosQueries = async () => {
    await Promise.all([
      runThanosQuery(thanosStatusResultList[0].queryString),
      runThanosQuery(thanosStatusResultList[1].queryString),
      runThanosQuery(thanosStatusResultList[2].queryString),
    ]).then(
      ([establishedConnection, metricsAvailable, clusterIdsAvailable]) => {
        setThanosResults({
          establishedConnection: establishedConnection.status === 'success',
          metricsAvailable: metricsAvailable.status === 'success',
          clusterIdsAvailable: clusterIdsAvailable.status === 'success',
        });
        if (clusterIdsAvailable.status === 'success') {
          let clusterIdArray: string[] = [];
          clusterIdsAvailable.data.result.forEach((thanosResult) => {
            clusterIdArray.push(thanosResult.metric.cluster_id);
          });
          setClusterIds(clusterIdArray.join(', '));
        }
      },
    );
  };

  useEffect(() => {
    initializeThanosQueries();
  }, []);

  return (
    <>
      <Typography style={{ marginBottom: '1em' }} variant="h6">
        Diagnostic output used to verify the status of a current Thanos
        deployment.
      </Typography>
      {thanosStatusResultList.map((item: ThanosDisplayItem) => (
        <div style={{ display: 'grid', gridTemplateColumns: '40px 1fr' }}>
          {thanosResults === null ? (
            <CircularProgress />
          ) : thanosResults[`${item.dataSource}`] ? (
            <DoneIcon />
          ) : (
            <CloseIcon />
          )}
          <Typography style={{ marginBottom: '1em' }} variant="subtitle1">
            {item.label}
          </Typography>
        </div>
      ))}
      <Button target="_blank" href="/diagnostics">
        View Full Diagnostics
      </Button>
    </>
  );
};
