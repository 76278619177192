// react
import React, { Dispatch, FC, SetStateAction } from 'react';

// holster
import { Button } from '@kubecost-frontend/holster';

// local
import { SaveIcon } from '../../assets/images/save-icon';

interface SaveControlProps {
  saved: boolean;
  setSaveDialogOpen: Dispatch<SetStateAction<boolean>>;
  setUnsaveDialogOpen: Dispatch<SetStateAction<boolean>>;
}

export const SaveControl: FC<SaveControlProps> = ({
  saved,
  setSaveDialogOpen,
  setUnsaveDialogOpen,
}) => {
  return (
    <Button
      variant="primary"
      className="flex items-center h-33px mr-3"
      onClick={() =>
        saved ? setUnsaveDialogOpen(true) : setSaveDialogOpen(true)
      }
    >
      <SaveIcon className="mr-2" />
      {saved ? 'Unsave Report' : 'Save Report'}
    </Button>
  );
};
