import {
  Button,
  Card,
  CardActions,
  CardContent,
  Link,
  Paper,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { primary } from '../../services/colors';
import { toCurrency } from '../../services/format';

// MUI icons
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

interface SavingsCardProps {
  title: string;
  value: number;
  currency: string;
  onClickHref?: string;
}

export const SavingsCard = ({
  title,
  value,
  currency,
  onClickHref,
}: SavingsCardProps) => (
  <Card style={{ height: '100%' }}>
    <CardContent style={{ paddingTop: '4em' }}>
      <div>
        <Typography component="div" variant="h6">
          {title}
        </Typography>
        <Typography
          style={{ color: primary[0], paddingTop: '.5em' }}
          variant="h4"
          component="div"
        >
          <strong>{toCurrency(value, currency)}</strong>
        </Typography>
      </div>
    </CardContent>
    {onClickHref && (
      <CardActions>
        <Link style={{ marginLeft: 'auto' }} href={onClickHref}>
          <Button
            style={{ color: '#2196f3' }}
            endIcon={<KeyboardArrowRightIcon />}
          >
            request sizing
          </Button>
        </Link>
      </CardActions>
    )}
  </Card>
);
