import { Time } from '../../constants';
import configs from '../../services/appconfig';
import logger from '../../services/logger';
import model from '../../services/model';
import {
  getPvSavings,
  getReservedRecSavings,
  getSavingsSummary,
  getUnassignedAddressSavings,
  getUnassignedDiskSavings,
  getUnutilizedLocalDiskSavings,
} from '../../services/savings';

export class Api {
  currency = 'USD';
  kubernetesCosts = 0;
  kubernetesCostsOld = 0;
  kubernetesCostsNew = 0;
  cloudCosts = 0;
  cloudCostsOld = 0;
  cloudCostsNew = 0;
  clusterCount = 0;
  providerCount = 0;
  providers: ProviderAccount[] = [];
  cloudServices: ProviderService[] = [];
  allNamespaces: Set<string> = new Set();
  allClusters: Set<string> = new Set();
  totalSavings = 0;
  totalEfficiency = 0;
  totalEfficiencyOld = 0;
  totalEfficiencyNew = 0;

  clusterDailyCosts: Record<string, number | string>[] = [];
  clusterDailyCounts: Record<string, Set<string> | string>[] = [];

  namespaceDailyCosts: Record<string, number | string>[] = [];
  namespaceDailyCounts: Record<string, Set<string> | string>[] = [];

  clusterTotals: Record<string, ClusterTotals> = {};
  namespaceTotals: Record<string, NamespaceTotals> = {};
  namespaceNetworkCosts: Record<string, NetworkTotals> = {};

  assetFetchLocked: boolean = false;
  savingsFetchLocked: boolean = false;

  networkDaemonsetNotActive: boolean = true;
  networkCheckComplete: boolean = false;

  initAllocations(
    numDays: number,
    countPromiseCallback: () => void,
    costPromiseCallback: () => void,
  ): void {
    const numberOfDaysToQuery = numDays * 2 + 1;
    const win = `${numberOfDaysToQuery}d`;
    // take the given relative window, and break it into a series of absolute windows
    // which can be requested one at a time
    const windows = model.relativeToAbsoluteWindows(win).reverse();

    // a sorted list of window starts helps to keep data ordered as it comes in
    const starts = windows.map((w) => w.split(',')[0]);
    const newStarts = starts.slice(8);

    // zero-out stored values
    this.kubernetesCosts = 0;
    this.kubernetesCostsOld = 0;
    this.kubernetesCostsNew = 0;
    this.allNamespaces = new Set();
    this.allClusters = new Set();
    this.totalEfficiency = 0;
    this.totalEfficiencyOld = 0;
    this.totalEfficiencyNew = 0;

    this.clusterDailyCosts = newStarts.map((date) => ({ date }));
    this.clusterDailyCounts = newStarts.map((date) => ({ date }));

    this.namespaceDailyCosts = newStarts.map((date) => ({ date }));
    this.namespaceDailyCounts = newStarts.map((date) => ({ date }));

    this.clusterTotals = {};
    this.namespaceTotals = {};
    this.namespaceNetworkCosts = {};

    // issue two requests per day -- a cluster/namespace aggregation
    // for computing costs, and a cluster/node/namespace/pod aggregation
    // for computing pod and node counts in a cluster/namespace

    // total accumulated efficiency
    const efficiencyStore: Record<string, EfficiencyStore> = {};
    const oldEfficiencyStore: Record<string, EfficiencyStore> = {};
    const newEfficiencyStore: Record<string, EfficiencyStore> = {};

    // courser-grained requests for costs
    // `costPromises` is an array of 1d-window promises
    const costPromises = model
      .getAllocationSummaryPaged(windows, 'cluster,namespace', {
        accumulate: true,
        external: false,
        idleByNode: false,
        shareCost: configs.getSharedOverhead(),
        shareIdle: true,
        shareLabels: configs.getSharedLabels(),
        shareNamespaces: configs.getSharedNamespaces(),
        shareSplit: 'weighted',
        shareTenancyCosts: configs.getShareTenancyCosts(),
      })
      .map(async (p, i) => {
        let response;
        try {
          response = await p;
        } catch (err) {
          costPromiseCallback();
          return;
        }
        if (i < 7) {
          // dealing with old totals for trend indicators
          response.data.sets.forEach(({ allocations }) => {
            Object.keys(allocations).forEach((key) => {
              const totalCost = model.getSummaryTotalCost(allocations[key]);
              const minutes = model.getSummaryMinutes(allocations[key]);
              this.kubernetesCostsOld += totalCost;

              // efficiency
              if (oldEfficiencyStore[key]) {
                oldEfficiencyStore[key].cpuCoreRequestAverage +=
                  allocations[key].cpuCoreRequestAverage || 0;
                oldEfficiencyStore[key].cpuCoreUsageAverage +=
                  allocations[key].cpuCoreUsageAverage || 0;
                oldEfficiencyStore[key].ramByteRequestAverage +=
                  allocations[key].ramByteRequestAverage || 0;
                oldEfficiencyStore[key].ramByteUsageAverage +=
                  allocations[key].ramByteUsageAverage || 0;
                oldEfficiencyStore[key].cpuCost +=
                  allocations[key].cpuCost || 0;
                oldEfficiencyStore[key].ramCost +=
                  allocations[key].ramCost || 0;
                oldEfficiencyStore[key].minutes += minutes || 0;
              } else {
                oldEfficiencyStore[key] = {
                  cpuCoreRequestAverage:
                    allocations[key].cpuCoreRequestAverage || 0,
                  cpuCoreUsageAverage:
                    allocations[key].cpuCoreUsageAverage || 0,
                  ramByteRequestAverage:
                    allocations[key].ramByteRequestAverage || 0,
                  ramByteUsageAverage:
                    allocations[key].ramByteUsageAverage || 0,
                  cpuCost: allocations[key].cpuCost || 0,
                  ramCost: allocations[key].ramCost || 0,
                  minutes: minutes || 0,
                };
              }
            });
            costPromiseCallback();
          });
        } else if (i === 7) {
          // new trend data, not for display
          response.data.sets.forEach(({ allocations, window }) => {
            Object.keys(allocations).forEach((key) => {
              const totalCost = model.getSummaryTotalCost(allocations[key]);
              const minutes = model.getSummaryMinutes(allocations[key]);
              this.kubernetesCostsNew += totalCost;

              // efficiency
              if (newEfficiencyStore[key]) {
                newEfficiencyStore[key].cpuCoreRequestAverage +=
                  allocations[key].cpuCoreRequestAverage || 0;
                newEfficiencyStore[key].cpuCoreUsageAverage +=
                  allocations[key].cpuCoreUsageAverage || 0;
                newEfficiencyStore[key].ramByteRequestAverage +=
                  allocations[key].ramByteRequestAverage || 0;
                newEfficiencyStore[key].ramByteUsageAverage +=
                  allocations[key].ramByteUsageAverage || 0;
                newEfficiencyStore[key].cpuCost +=
                  allocations[key].cpuCost || 0;
                newEfficiencyStore[key].ramCost +=
                  allocations[key].ramCost || 0;
                newEfficiencyStore[key].minutes += minutes || 0;
              } else {
                newEfficiencyStore[key] = {
                  cpuCoreRequestAverage:
                    allocations[key].cpuCoreRequestAverage || 0,
                  cpuCoreUsageAverage:
                    allocations[key].cpuCoreUsageAverage || 0,
                  ramByteRequestAverage:
                    allocations[key].ramByteRequestAverage || 0,
                  ramByteUsageAverage:
                    allocations[key].ramByteUsageAverage || 0,
                  cpuCost: allocations[key].cpuCost || 0,
                  ramCost: allocations[key].ramCost || 0,
                  minutes: minutes || 0,
                };
              }
            });
            costPromiseCallback();
          });
        } else if (i > 7 && i < 14) {
          // new trend data, also for display
          // determine at what index this day's data belongs
          const idx = i - 8;
          response.data.sets.forEach(({ allocations, window }) => {
            Object.keys(allocations).forEach((key) => {
              const totalCost = model.getSummaryTotalCost(allocations[key]);
              const networkCost = model.getSummaryNetworkCost(allocations[key]);
              const minutes = model.getSummaryMinutes(allocations[key]);
              this.kubernetesCosts += totalCost;
              this.kubernetesCostsNew += totalCost;
              const [cluster, namespace] = key.split('/');

              // these entries may be initialized by this callback, *or* by the counts callback
              this.namespaceTotals[`${cluster}/${namespace}`] = this
                .namespaceTotals[`${cluster}/${namespace}`] || {
                namespace,
                cluster,
                id: `${cluster}/${namespace}`,
                cost: 0,
              };
              this.clusterTotals[cluster] = this.clusterTotals[cluster] || {
                name: cluster,
                id: cluster,
                pods: new Set(),
                nodes: new Set(),
                namespaces: new Set(),
                podCount: 0,
                nodeCount: 0,
                namespaceCount: 0,
                cost: 0,
              };
              this.namespaceNetworkCosts[namespace] = this
                .namespaceNetworkCosts[namespace] || {
                namespace,
                id: namespace,
                cost: 0,
              };

              this.namespaceDailyCosts[idx][namespace + '/cost'] =
                this.namespaceDailyCosts[idx][namespace + '/cost'] || 0;
              this.clusterDailyCosts[idx][cluster + '/cost'] =
                this.clusterDailyCosts[idx][cluster + '/cost'] || 0;

              // namespace costs
              if (namespace && namespace !== 'undefined') {
                this.allNamespaces.add(namespace);
                this.namespaceDailyCosts[idx][namespace + '/cost'] += totalCost;
                this.namespaceTotals[`${cluster}/${namespace}`].cost +=
                  totalCost;
                this.namespaceNetworkCosts[namespace].cost += networkCost;
              }

              // cluster costs
              if (cluster && cluster !== 'undefined') {
                this.allClusters.add(cluster);
                this.clusterDailyCosts[idx][cluster + '/cost'] += totalCost;
                this.clusterTotals[cluster].cost += totalCost;
              }

              // efficiency
              if (efficiencyStore[key]) {
                efficiencyStore[key].cpuCoreRequestAverage +=
                  allocations[key].cpuCoreRequestAverage || 0;
                efficiencyStore[key].cpuCoreUsageAverage +=
                  allocations[key].cpuCoreUsageAverage || 0;
                efficiencyStore[key].ramByteRequestAverage +=
                  allocations[key].ramByteRequestAverage || 0;
                efficiencyStore[key].ramByteUsageAverage +=
                  allocations[key].ramByteUsageAverage || 0;
                efficiencyStore[key].cpuCost += allocations[key].cpuCost || 0;
                efficiencyStore[key].ramCost += allocations[key].ramCost || 0;
                efficiencyStore[key].minutes += minutes || 0;
              } else {
                efficiencyStore[key] = {
                  cpuCoreRequestAverage:
                    allocations[key].cpuCoreRequestAverage || 0,
                  cpuCoreUsageAverage:
                    allocations[key].cpuCoreUsageAverage || 0,
                  ramByteRequestAverage:
                    allocations[key].ramByteRequestAverage || 0,
                  ramByteUsageAverage:
                    allocations[key].ramByteUsageAverage || 0,
                  cpuCost: allocations[key].cpuCost || 0,
                  ramCost: allocations[key].ramCost || 0,
                  minutes: minutes || 0,
                };
              }

              // new efficiency
              if (newEfficiencyStore[key]) {
                newEfficiencyStore[key].cpuCoreRequestAverage +=
                  allocations[key].cpuCoreRequestAverage || 0;
                newEfficiencyStore[key].cpuCoreUsageAverage +=
                  allocations[key].cpuCoreUsageAverage || 0;
                newEfficiencyStore[key].ramByteRequestAverage +=
                  allocations[key].ramByteRequestAverage || 0;
                newEfficiencyStore[key].ramByteUsageAverage +=
                  allocations[key].ramByteUsageAverage || 0;
                newEfficiencyStore[key].cpuCost +=
                  allocations[key].cpuCost || 0;
                newEfficiencyStore[key].ramCost +=
                  allocations[key].ramCost || 0;
                newEfficiencyStore[key].minutes += minutes || 0;
              } else {
                newEfficiencyStore[key] = {
                  cpuCoreRequestAverage:
                    allocations[key].cpuCoreRequestAverage || 0,
                  cpuCoreUsageAverage:
                    allocations[key].cpuCoreUsageAverage || 0,
                  ramByteRequestAverage:
                    allocations[key].ramByteRequestAverage || 0,
                  ramByteUsageAverage:
                    allocations[key].ramByteUsageAverage || 0,
                  cpuCost: allocations[key].cpuCost || 0,
                  ramCost: allocations[key].ramCost || 0,
                  minutes: minutes || 0,
                };
              }
            });
            costPromiseCallback();
          });
        } else if (i === 14) {
          // for display only
          const idx = i - 8;
          response.data.sets.forEach(({ allocations, window }) => {
            Object.keys(allocations).forEach((key) => {
              const totalCost = model.getSummaryTotalCost(allocations[key]);
              const networkCost = model.getSummaryNetworkCost(allocations[key]);
              const minutes = model.getSummaryMinutes(allocations[key]);
              this.kubernetesCosts += totalCost;

              const [cluster, namespace] = key.split('/');

              // these entries may be initialized by this callback, *or* by the counts callback
              this.namespaceTotals[`${cluster}/${namespace}`] = this
                .namespaceTotals[`${cluster}/${namespace}`] || {
                namespace,
                cluster,
                id: `${cluster}/${namespace}`,
                cost: 0,
              };
              this.namespaceNetworkCosts[namespace] = this
                .namespaceNetworkCosts[namespace] || {
                namespace,
                id: namespace,
                cost: 0,
              };
              this.clusterTotals[cluster] = this.clusterTotals[cluster] || {
                name: cluster,
                id: cluster,
                pods: new Set(),
                nodes: new Set(),
                namespaces: new Set(),
                podCount: 0,
                nodeCount: 0,
                namespaceCount: 0,
                cost: 0,
              };

              this.namespaceDailyCosts[idx][namespace + '/cost'] =
                this.namespaceDailyCosts[idx][namespace + '/cost'] || 0;
              this.clusterDailyCosts[idx][cluster + '/cost'] =
                this.clusterDailyCosts[idx][cluster + '/cost'] || 0;

              // namespace costs
              if (namespace && namespace !== 'undefined') {
                this.allNamespaces.add(namespace);
                this.namespaceDailyCosts[idx][namespace + '/cost'] += totalCost;
                this.namespaceTotals[`${cluster}/${namespace}`].cost +=
                  totalCost;
                this.namespaceNetworkCosts[namespace].cost += networkCost;
              }

              // cluster costs
              if (cluster && cluster !== 'undefined') {
                this.allClusters.add(cluster);
                this.clusterDailyCosts[idx][cluster + '/cost'] += totalCost;
                this.clusterTotals[cluster].cost += totalCost;
              }

              // efficiency
              if (efficiencyStore[key]) {
                efficiencyStore[key].cpuCoreRequestAverage +=
                  allocations[key].cpuCoreRequestAverage || 0;
                efficiencyStore[key].cpuCoreUsageAverage +=
                  allocations[key].cpuCoreUsageAverage || 0;
                efficiencyStore[key].ramByteRequestAverage +=
                  allocations[key].ramByteRequestAverage || 0;
                efficiencyStore[key].ramByteUsageAverage +=
                  allocations[key].ramByteUsageAverage || 0;
                efficiencyStore[key].cpuCost += allocations[key].cpuCost || 0;
                efficiencyStore[key].ramCost += allocations[key].ramCost || 0;
                efficiencyStore[key].minutes += minutes || 0;
              } else {
                efficiencyStore[key] = {
                  cpuCoreRequestAverage:
                    allocations[key].cpuCoreRequestAverage || 0,
                  cpuCoreUsageAverage:
                    allocations[key].cpuCoreUsageAverage || 0,
                  ramByteRequestAverage:
                    allocations[key].ramByteRequestAverage || 0,
                  ramByteUsageAverage:
                    allocations[key].ramByteUsageAverage || 0,
                  cpuCost: allocations[key].cpuCost || 0,
                  ramCost: allocations[key].ramCost || 0,
                  minutes: minutes || 0,
                };
              }
            });
            costPromiseCallback();
          });
        }
      });

    // when all cost promises are complete, compute total efficiency and send count promises
    Promise.all(costPromises).then(() => {
      let cpuCoreRequestHours = 0;
      let cpuCoreUsageHours = 0;
      let ramByteRequestHours = 0;
      let ramByteUsageHours = 0;
      let cpuCost = 0;
      let ramCost = 0;
      let oldCpuCoreRequestHours = 0;
      let oldCpuCoreUsageHours = 0;
      let oldRamByteRequestHours = 0;
      let oldRamByteUsageHours = 0;
      let oldCpuCost = 0;
      let oldRamCost = 0;
      let newCpuCoreRequestHours = 0;
      let newCpuCoreUsageHours = 0;
      let newRamByteRequestHours = 0;
      let newRamByteUsageHours = 0;
      let newCpuCost = 0;
      let newRamCost = 0;
      Object.values(efficiencyStore).forEach((entry) => {
        const {
          cpuCoreRequestAverage,
          cpuCoreUsageAverage,
          ramByteRequestAverage,
          ramByteUsageAverage,
          minutes,
        } = entry;
        cpuCost += entry.cpuCost;
        ramCost += entry.ramCost;
        cpuCoreRequestHours +=
          cpuCoreRequestAverage * (minutes / Time.MinutesPerHour);
        cpuCoreUsageHours +=
          cpuCoreUsageAverage * (minutes / Time.MinutesPerHour);
        ramByteRequestHours +=
          ramByteRequestAverage * (minutes / Time.MinutesPerHour);
        ramByteUsageHours +=
          ramByteUsageAverage * (minutes / Time.MinutesPerHour);
      });
      Object.values(oldEfficiencyStore).forEach((entry) => {
        const {
          cpuCoreRequestAverage,
          cpuCoreUsageAverage,
          ramByteRequestAverage,
          ramByteUsageAverage,
          minutes,
        } = entry;
        oldCpuCost += entry.cpuCost;
        oldRamCost += entry.ramCost;
        oldCpuCoreRequestHours +=
          cpuCoreRequestAverage * (minutes / Time.MinutesPerHour);
        oldCpuCoreUsageHours +=
          cpuCoreUsageAverage * (minutes / Time.MinutesPerHour);
        oldRamByteRequestHours +=
          ramByteRequestAverage * (minutes / Time.MinutesPerHour);
        oldRamByteUsageHours +=
          ramByteUsageAverage * (minutes / Time.MinutesPerHour);
      });
      Object.values(newEfficiencyStore).forEach((entry) => {
        const {
          cpuCoreRequestAverage,
          cpuCoreUsageAverage,
          ramByteRequestAverage,
          ramByteUsageAverage,
          minutes,
        } = entry;
        newCpuCost += entry.cpuCost;
        newRamCost += entry.ramCost;
        newCpuCoreRequestHours +=
          cpuCoreRequestAverage * (minutes / Time.MinutesPerHour);
        newCpuCoreUsageHours +=
          cpuCoreUsageAverage * (minutes / Time.MinutesPerHour);
        newRamByteRequestHours +=
          ramByteRequestAverage * (minutes / Time.MinutesPerHour);
        newRamByteUsageHours +=
          ramByteUsageAverage * (minutes / Time.MinutesPerHour);
      });

      if (cpuCost + ramCost > 0) {
        let cpuEfficiency = 0;
        let ramEfficiency = 0;

        if (cpuCoreRequestHours > 0) {
          cpuEfficiency = cpuCoreUsageHours / cpuCoreRequestHours;
        } else if (cpuCoreUsageHours > 0) {
          cpuEfficiency = 1;
        }

        if (ramByteRequestHours > 0) {
          ramEfficiency = ramByteUsageHours / ramByteRequestHours;
        } else if (ramByteUsageHours > 0) {
          ramEfficiency = 1;
        }
        this.totalEfficiency =
          (100 * (cpuCost * cpuEfficiency + ramCost * ramEfficiency)) /
          (cpuCost + ramCost);
      }

      if (oldCpuCost + oldRamCost > 0) {
        let cpuEfficiency = 0;
        let ramEfficiency = 0;

        if (oldCpuCoreRequestHours > 0) {
          cpuEfficiency = oldCpuCoreUsageHours / oldCpuCoreRequestHours;
        } else if (oldCpuCoreUsageHours > 0) {
          cpuEfficiency = 1;
        }

        if (oldRamByteRequestHours > 0) {
          ramEfficiency = oldRamByteUsageHours / oldRamByteRequestHours;
        } else if (oldRamByteUsageHours > 0) {
          ramEfficiency = 1;
        }
        this.totalEfficiencyOld =
          (100 * (oldCpuCost * cpuEfficiency + oldRamCost * ramEfficiency)) /
          (oldCpuCost + oldRamCost);
      }

      if (newCpuCost + newRamCost > 0) {
        let cpuEfficiency = 0;
        let ramEfficiency = 0;

        if (newCpuCoreRequestHours > 0) {
          cpuEfficiency = newCpuCoreUsageHours / newCpuCoreRequestHours;
        } else if (newCpuCoreUsageHours > 0) {
          cpuEfficiency = 1;
        }

        if (newRamByteRequestHours > 0) {
          ramEfficiency = newRamByteUsageHours / newRamByteRequestHours;
        } else if (newRamByteUsageHours > 0) {
          ramEfficiency = 1;
        }
        this.totalEfficiencyNew =
          (100 * (newCpuCost * cpuEfficiency + newRamCost * ramEfficiency)) /
          (newCpuCost + newRamCost);
      }
      costPromiseCallback();

      // fine-grained requests for counts
      // `countPromises` is an array of 1d-window promises
      model
        .getAllocationSummaryPaged(windows.slice(8), 'cluster', {
          accumulate: true,
          external: false,
          idleByNode: false,
          shareCost: configs.getSharedOverhead(),
          shareIdle: true,
          shareLabels: configs.getSharedLabels(),
          shareNamespaces: configs.getSharedNamespaces(),
          shareSplit: 'weighted',
          shareTenancyCosts: configs.getShareTenancyCosts(),
        })
        .map(async (p, idx) => {
          try {
            const response = await p;
            const worker = new Worker('overview-worker.js');
            worker.onmessage = (e: MessageEvent<CountWorkerMessage>) => {
              if (e.data.message !== 'processedCounts') {
                logger.error('unrecognized message: ', e.data.message);
                return;
              }
              try {
                const {
                  clusterCounts,
                  clusterTotals,
                  namespaceCounts,
                  namespaceTotals,
                } = e.data.counts;
                this.clusterDailyCounts[idx] = {
                  ...clusterCounts,
                  date: this.clusterDailyCounts[idx].date,
                };
                Object.keys(clusterTotals).forEach((cluster) => {
                  this.clusterTotals[cluster] =
                    this.clusterTotals[cluster] || {};
                  this.clusterTotals[cluster].pods = new Set([
                    ...Array.from(clusterTotals[cluster].pods),
                    ...Array.from(this.clusterTotals[cluster].pods || []),
                  ]);
                  this.clusterTotals[cluster].nodes = new Set([
                    ...Array.from(clusterTotals[cluster].nodes),
                    ...Array.from(this.clusterTotals[cluster].nodes || []),
                  ]);
                  this.clusterTotals[cluster].namespaces = new Set([
                    ...Array.from(clusterTotals[cluster].namespaces),
                    ...Array.from(this.clusterTotals[cluster].namespaces || []),
                  ]);
                  this.clusterTotals[cluster].podCount =
                    this.clusterTotals[cluster].pods.size;
                  this.clusterTotals[cluster].nodeCount =
                    this.clusterTotals[cluster].nodes.size;
                  this.clusterTotals[cluster].namespaceCount =
                    this.clusterTotals[cluster].namespaces.size;
                });
                this.namespaceDailyCounts[idx] = {
                  ...namespaceCounts,
                  date: this.namespaceDailyCounts[idx].date,
                };
                Object.keys(namespaceTotals).forEach((namespace) => {
                  this.namespaceTotals[namespace] =
                    this.namespaceTotals[namespace] || {};
                  this.namespaceTotals[namespace].pods = new Set([
                    ...Array.from(namespaceTotals[namespace].pods),
                    ...Array.from(this.namespaceTotals[namespace].pods || []),
                  ]);
                  this.namespaceTotals[namespace].clusters = new Set([
                    ...Array.from(namespaceTotals[namespace].clusters),
                    ...Array.from(
                      this.namespaceTotals[namespace].clusters || [],
                    ),
                  ]);
                  this.namespaceTotals[namespace].podCount =
                    this.namespaceTotals[namespace].pods.size;
                  this.namespaceTotals[namespace].clusterCount =
                    this.namespaceTotals[namespace].clusters.size;
                });
              } catch (err) {
                logger.log(err);
              } finally {
                countPromiseCallback();
              }
            };
            worker.postMessage({ command: 'processCounts', counts: response });
          } catch (err) {
            logger.log(err);
            countPromiseCallback();
          }
        });
    });
  }

  async initAssets(numDays: number): Promise<void> {
    const numberOfDaysToQuery = numDays * 2 + 3;
    const win = `${numberOfDaysToQuery}d`;
    if (this.assetFetchLocked) {
      return;
    }
    this.assetFetchLocked = true;
    this.cloudCosts = 0;
    this.cloudCostsOld = 0;
    this.cloudCostsNew = 0;
    this.clusterCount = 0;
    this.providerCount = 0;
    this.providers = [];
    this.cloudServices = [];

    const windows = model.relativeToAbsoluteWindows(win).reverse();
    const oldWindow = windows[0].split(',')[0] + ',' + windows[6].split(',')[1];
    const newWindow =
      windows[7].split(',')[0] + ',' + windows[13].split(',')[1];
    const displayWindow =
      windows[10].split(',')[0] + ',' + windows[16].split(',')[1];

    const [oldResponse, newResponse, displayResponse] = await Promise.all([
      model.getAssets(oldWindow, {
        aggregate: 'provider,account,cluster,service',
        accumulate: true,
        noLimit: true,
      }),
      model.getAssets(newWindow, {
        aggregate: 'provider,account,cluster,service',
        accumulate: true,
        noLimit: true,
      }),
      model.getAssets(displayWindow, {
        aggregate: 'provider,account,cluster,service',
        accumulate: true,
        noLimit: true,
      }),
    ]);
    const assetSet = displayResponse.data[0];
    const clusterSet = new Set();
    const providerSet = new Set();
    const providerAccountMap: Record<string, ProviderAccount> = {};
    const cloudServiceMap: Record<string, ProviderService> = {};
    Object.keys(assetSet).forEach((key) => {
      const [provider, account, cluster, service] = key.split('/');
      if (service !== 'Kubernetes') {
        // total cloud costs are all non-k8s asset costs
        this.cloudCosts += assetSet[key].totalCost;

        // get provider/service line items for non-k8s costs
        if (cloudServiceMap[`${provider}/${service}`]) {
          cloudServiceMap[`${provider}/${service}`].totalCost +=
            assetSet[key].totalCost;
        } else {
          cloudServiceMap[`${provider}/${service}`] = {
            service,
            id: `${provider}/${service}`,
            totalCost: assetSet[key].totalCost,
            provider,
          };
        }

        // get provider/account line items for non-k8s costs
        if (providerAccountMap[`${provider}/${account}`]) {
          providerAccountMap[`${provider}/${account}`].totalCost +=
            assetSet[key].totalCost;
        } else {
          providerAccountMap[`${provider}/${account}`] = {
            id: `${provider}/${account}`,
            account,
            provider,
            totalCost: assetSet[key].totalCost,
          };
        }
      }

      // get a count of all unique clusters under management
      if (cluster !== '__undefined__') {
        clusterSet.add(cluster);
      }

      // get a count of all providers under management
      if (provider && provider !== '__undefined__') {
        providerSet.add(provider);
      }
    });
    this.clusterCount = clusterSet.size;
    this.providers = Object.values(providerAccountMap).sort((pr1, pr2) =>
      pr1.totalCost > pr2.totalCost ? -1 : 1,
    );
    this.providerCount = providerSet.size;
    this.cloudServices = Object.values(cloudServiceMap).sort((s1, s2) =>
      s1.totalCost > s2.totalCost ? -1 : 1,
    );

    const oldAssetSet = oldResponse.data[0];
    Object.keys(oldAssetSet).forEach((key) => {
      const [provider, account, cluster, service] = key.split('/');
      if (service !== 'Kubernetes') {
        // total cloud costs are all non-k8s asset costs
        this.cloudCostsOld += oldAssetSet[key].totalCost;
      }
    });

    const newAssetSet = newResponse.data[0];
    Object.keys(newAssetSet).forEach((key) => {
      const [provider, account, cluster, service] = key.split('/');
      if (service !== 'Kubernetes') {
        // total cloud costs are all non-k8s asset costs
        this.cloudCostsNew += newAssetSet[key].totalCost;
      }
    });
    this.assetFetchLocked = false;
  }

  async initConfigs(): Promise<void> {
    this.currency = 'USD';
    await configs.init();
    this.currency = configs.getCurrency();
  }

  async initSavings(): Promise<void> {
    if (this.savingsFetchLocked) {
      return;
    }
    this.savingsFetchLocked = true;
    this.totalSavings = 0;
    await Promise.all([
      getSavingsSummary().then((savingsSummary) => {
        const nodeSavings = Math.max(
          savingsSummary.clusterSizing || 0,
          savingsSummary.nodeTurndown || 0,
        );
        const savings =
          (savingsSummary.abandonedWorkloads || 0) +
          (savingsSummary.requestSizing || 0) +
          nodeSavings;

        this.totalSavings += 0.65 * savings;
      }),

      getPvSavings().then((savings) => {
        this.totalSavings += 0.65 * savings;
      }),

      getReservedRecSavings().then((savings) => {
        this.totalSavings += 0.65 * savings;
      }),

      getUnassignedAddressSavings().then((savings) => {
        this.totalSavings += 0.65 * savings;
      }),

      getUnassignedDiskSavings().then((savings) => {
        this.totalSavings += 0.65 * savings;
      }),

      getUnutilizedLocalDiskSavings().then((savings) => {
        this.totalSavings += 0.65 * savings;
      }),
    ]);
  }

  async networkCheck(): Promise<void> {
    this.networkCheckComplete = false;
    this.networkDaemonsetNotActive = true;
    try {
      await model.getNetworkTraffic();
      this.networkDaemonsetNotActive = false;
    } catch (err) {
      this.networkDaemonsetNotActive = true;
    } finally {
      this.networkCheckComplete = true;
    }
  }
}

export interface Namespace {
  cluster: string;
  id: string;
  namespace: string;
  totalCost: number;
}

export interface ProviderAccount {
  id: string;
  account: string;
  provider: string;
  totalCost: number;
}

export interface ProviderService {
  id: string;
  service: string;
  totalCost: number;
  provider: string;
}

interface EfficiencyStore {
  cpuCoreRequestAverage: number;
  cpuCoreUsageAverage: number;
  ramByteRequestAverage: number;
  ramByteUsageAverage: number;
  cpuCost: number;
  ramCost: number;
  minutes: number;
}

export interface ClusterTotals {
  id: string;
  name: string;
  nodes: Set<string>;
  pods: Set<string>;
  namespaces: Set<string>;
  nodeCount: number;
  podCount: number;
  namespaceCount: number;
  cost: number;
}

export interface NamespaceTotals {
  id: string;
  namespace: string;
  cluster: string;
  cost: number;
}

export interface NetworkTotals {
  id: string;
  namespace: string;
  cost: number;
}

interface ClusterCount {
  namespaces: Set<string>;
  nodes: Set<string>;
  pods: Set<string>;
  namespaceCount: number;
  nodeCount: number;
  podCount: number;
}

interface NamespaceCount {
  clusters: Set<string>;
  pods: Set<string>;
  clusterCount: number;
  podCount: number;
}

interface WorkerClusterTotals {}

interface CountWorkerMessage {
  counts: {
    clusterCounts: Record<string, number | Set<string>>;
    clusterTotals: Record<string, ClusterCount>;
    namespaceCounts: Record<string, number | Set<string>>;
    namespaceTotals: Record<string, NamespaceCount>;
  };
  message: string;
}
