import { Snackbar, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';

interface FilterItemProps {
  type: string;
  defaultValue: string;
  updateItem: (type: string, value: string) => Promise<boolean>;
}

export const FilterItem = ({
  type,
  defaultValue,
  updateItem,
}: FilterItemProps) => {
  const [currentValue, setCurrentValue] = useState<string>(defaultValue);
  const [errorState, setErrorState] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const sendUpdate = async () => {
    if (currentValue !== defaultValue) {
      // check for bogus values
      const isSuccessful = await updateItem(type, currentValue);
      if (!isSuccessful) {
        setShowErrorAlert(true);
        setErrorState(true);
      }
    }
  };

  const checkForEnter = (e: any) => {
    if (e.keyCode === 13) {
      sendUpdate();
    }
  };

  const handlUnfocus = () => {
    if (errorState) {
      setErrorState(false);
      setCurrentValue(defaultValue);
    } else {
      sendUpdate();
    }
  };

  const updateCurrentValueText = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (errorState === true) {
      setErrorState(false);
    }
    setCurrentValue(e.target.value);
  };

  const errorMessage =
    type === 'controller' ? (
      <div>
        Unknown {type}: this may be because a controllerKind prefix wasn&apos;t
        included. i.e. <strong>deployment:</strong>kubecost-cost-analyzer
      </div>
    ) : (
      `Unknown ${type}`
    );

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showErrorAlert}
        onClose={() => setShowErrorAlert(false)}
      >
        <Alert onClose={() => setShowErrorAlert(false)} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <TextField
        variant="outlined"
        onBlur={handlUnfocus}
        style={{ margin: '.25em' }}
        placeholder="ALL"
        value={currentValue}
        label={type}
        error={errorState}
        InputLabelProps={{ shrink: true }}
        onKeyDown={checkForEnter}
        onChange={updateCurrentValueText}
        size="small"
      />
    </div>
  );
};
