import React, { FC, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select';
import { Allocation } from '../../../types/allocation';
import { StyledTableCell } from './StyledTableCell';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@kubecost-frontend/holster';
import { Paper, TablePagination } from '@material-ui/core';

interface PerformanceInsightsCardProps {
  controllers: Record<string, Allocation> | null;
  handleContextSwitch: (controllerName: string) => void;
}
interface PerformanceInsightsMetrics {
  warningName: string;
  warningType: string;
  warningLevel: string;
}

export const PerformanceInsightsCard: FC<PerformanceInsightsCardProps> = ({
  controllers,
  handleContextSwitch,
}) => {
  const warningTypeFilterOpts = [
    { value: 'ALL', label: 'ALL' },
    { value: 'OOM EVICTION', label: 'OOM EVICTION' },
    { value: 'CPU THROTTLING', label: 'CPU THROTTLING' },
  ];

  const [performanceMetrics, setPerformanceMetrics] = useState<
    (PerformanceInsightsMetrics | undefined)[]
  >([]);

  const [pageRows, setPageRows] = useState(5);
  const [page, setPage] = useState(0);
  const [selectedOption, setSelectedOption] = useState('ALL');

  const calculateWarningLevel = (usage: number, request: number) => {
    //  High risk: 20% over your request size (note request could also be zero and this would apply)
    // Medium: Between 0%-20% over your request
    // Low: request or lower (vote is we don’t show these in workloads in perf insight card)

    const calc = Math.floor((usage / request) * 100);

    if (
      calc >= 120 ||
      calc === 0 ||
      Number.isNaN(calc) ||
      !Number.isFinite(calc)
    ) {
      return 'HIGH';
    }

    if (calc <= 120 && calc >= 100) {
      return 'MEDIUM';
    }

    return 'LOW';
  };

  const oomEvictionCalculation = (item: Allocation) => {
    const usage = item.ramByteUsageAverage;
    const request = item.ramByteRequestAverage;

    const oomEvictionLevel = calculateWarningLevel(usage, request);

    if (oomEvictionLevel !== 'LOW') {
      return {
        warningName: item.name,
        warningType: 'OOM EVICTION',
        warningLevel: oomEvictionLevel,
      };
    }

    return undefined;
  };

  const cpuThrottleCalculation = (item: Allocation) => {
    const usage = item.cpuCoreUsageAverage;
    const request = item.cpuCoreRequestAverage;

    const cpuThrottleLevel = calculateWarningLevel(usage, request);

    if (cpuThrottleLevel !== 'LOW') {
      return {
        warningName: item.name,
        warningType: 'CPU THROTTLING',
        warningLevel: cpuThrottleLevel,
      };
    }

    return undefined;
  };

  const handleOnRowsPerPageChange: React.ChangeEventHandler<HTMLInputElement> =
    (e) => {
      setPageRows(parseInt(e.target.value, 10));
    };

  useEffect(() => {
    if (!controllers) {
      return;
    }

    const memVals = Object.values(controllers)
      .map(oomEvictionCalculation)
      .filter(
        (val) =>
          val !== undefined &&
          val?.warningName !== '__idle__' &&
          val?.warningName !== '__unallocated__',
      );

    const cpuVals = Object.values(controllers)
      .map(cpuThrottleCalculation)
      .filter(
        (val) =>
          val !== undefined &&
          val?.warningName !== '__idle__' &&
          val?.warningName !== '__unallocated__',
      );

    const totalVals = [...memVals, ...cpuVals];

    const filteredVals = totalVals.filter(
      (val) => val?.warningType === selectedOption?.value,
    );

    setPerformanceMetrics(filteredVals.length ? filteredVals : totalVals);
  }, [controllers, selectedOption]);

  return (
    <Paper style={{ padding: '2em' }}>
      <Typography variant="h6">Performance Insights</Typography>
      <Table style={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            <StyledTableCell>Name (Cluster/Controller)</StyledTableCell>
            <StyledTableCell align="right">
              <Select
                placeholder="Risk Type"
                isClearable
                isSearchable={false}
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                options={warningTypeFilterOpts}
              />
            </StyledTableCell>
            <StyledTableCell align="right">Risk Level</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {performanceMetrics
            ?.slice(page * pageRows, (page + 1) * pageRows)
            .map((c) => (
              <TableRow style={{ cursor: 'pointer' }} key={uuidv4()}>
                <StyledTableCell
                  scope="row"
                  onClick={() => handleContextSwitch(c?.warningName || '')}
                >
                  {c?.warningName}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {c?.warningType}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {c?.warningLevel}
                </StyledTableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={performanceMetrics?.length}
        onPageChange={(_e, p) => {
          setPage(p);
        }}
        page={page}
        rowsPerPage={pageRows}
        rowsPerPageOptions={[5, 10, 25, 50]}
        onRowsPerPageChange={handleOnRowsPerPageChange}
      />
    </Paper>
  );
};

PerformanceInsightsCard.displayName = 'PerformanceInsightsCard';
