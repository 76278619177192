import { useReducer } from 'react';

type IdleCostState = {
  idleBy: string;
  idle: boolean;
  shareIdle: boolean;
  idleByNode: boolean;
};

export type IdleCostAction =
  | 'hide'
  | 'separate'
  | 'shareByCluster'
  | 'shareByNode';

const initialState: IdleCostState = {
  idleBy: 'hide',
  idle: false,
  shareIdle: false,
  idleByNode: false,
};

export const useIdleViewControl = () => {
  const [idleState, dispatch] = useReducer(
    (state: IdleCostState, action: IdleCostAction): IdleCostState => {
      switch (action) {
        case 'hide':
          return {
            ...state,
            idleBy: 'hide',
            idle: false,
            shareIdle: false,
            idleByNode: false,
          };
        case 'separate':
          return {
            ...state,
            idleBy: 'separate',
            idle: true,
            shareIdle: false,
            idleByNode: false,
          };
        case 'shareByCluster':
          return {
            ...state,
            idleBy: 'shareByCluster',
            idle: true,
            shareIdle: true,
            idleByNode: false,
          };
        case 'shareByNode':
          return {
            ...state,
            idleBy: 'shareByNode',
            idle: true,
            shareIdle: true,
            idleByNode: true,
          };
      }
    },
    initialState,
  );
  return { ...idleState, dispatch, initialState };
};
