import { Button, ButtonGroup } from '@material-ui/core';
import React from 'react';
import { colorMap } from '../../services/colors';

const timeWindows = ['12h', '24h', '7d', '30d'];

export const TimeWindowSelector = ({
  activeTimeWindow,
  setActiveTimeWindow,
}: any) => (
  <ButtonGroup size="small" aria-label="small outlined button group">
    {timeWindows.map((window: string) => (
      <Button
        style={{ color: activeTimeWindow === window ? colorMap.blue : 'gray' }}
        key={window}
        onClick={() => setActiveTimeWindow(window)}
        size='medium'
      >
        {window}
      </Button>
    ))}
  </ButtonGroup>
);
