//react
import React, { FC, memo, useState, useEffect } from 'react';

// lodash
import get from 'lodash/get';
import round from 'lodash/round';

// mui icons
import ClusterIcon from '@material-ui/icons/GroupWork';
import NodeIcon from '@material-ui/icons/Memory';
import StorageIcon from '@material-ui/icons/SdStorage';
import CloudIcon from '@material-ui/icons/Cloud';
import MapIcon from '@material-ui/icons/Map';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import CategoryIcon from '@material-ui/icons/Category';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import FolderIcon from '@material-ui/icons/Folder';

// holster
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from '@kubecost-frontend/holster';

// local
import model from '../../services/model';
import { captureError } from '../../services/error_reporting';
import { toCurrency } from '../../services/format';
import { Asset, DiskAsset, NodeAsset } from '../../types/asset';
import { AssetPropertiesItem } from './AssetPropertiesItem';
import { NodeAssetDetails } from './NodeAssetDetails';
import { DiskAssetDetails } from './DiskAssetDetails';

interface DetailsProps {
  window: string;
  currency: string;
  asset: Asset;
}

const Details: FC<DetailsProps> = ({ window, currency, asset }) => {
  const [fetch, setFetch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [hours, setHours] = useState(0);
  const [costPerHr, setCostPerHr] = useState(0);
  const [accAsset, setAccAsset] = useState<Asset>();
  const properties = get(asset, 'properties', {});
  const category = get(properties, 'category', '');
  const cluster = get(properties, 'cluster', '');
  const provider = get(properties, 'provider', '');
  const account = get(properties, 'account', '');
  const project = get(properties, 'project', '');
  const region = get(properties, 'region', '');
  const providerID = get(properties, 'providerID', '');
  const service = get(properties, 'service', '');
  const type = get(asset, 'type', '');
  let storageClass = '';
  let nodeType = '';

  if (type === 'Node') {
    nodeType = get(asset, 'nodeType', '');
    if (!nodeType) {
      nodeType = 'Unknown';
    }
  }

  if (type === 'Disk') {
    storageClass = get(asset, 'storageClass', '');
    if (!storageClass) {
      storageClass = 'Unknown';
    }
  }

  useEffect(() => {
    if (fetch) {
      fetchData();
    }
  }, [fetch]);

  async function fetchData() {
    setLoading(true);

    const filterProps: Record<string, string> = {
      category,
      cluster,
      providerID,
      service,
      type,
    };
    const filters = getRequestFilters(filterProps);

    try {
      const resp = await model.getAssets(window, {
        filters,
        accumulate: true,
      });
      // After filtering and accumulation there should only be one asset in return
      if (resp.data && resp.data.length > 0) {
        const rowData: Record<string, Asset> = resp.data[0];
        const rowKeys = Object.keys(rowData);
        if (rowKeys.length > 0) {
          setAccAsset(rowData[rowKeys[0]]);
          const hrs = get(rowData[rowKeys[0]], 'minutes', 0) / 60.0;
          setHours(hrs);
          if (hrs !== 0) {
            setCostPerHr(rowData[rowKeys[0]].totalCost / hrs);
          }
        }
      }
      setLoading(false);
      setFetch(false);
    } catch (err) {
      console.warn(err);
      captureError(err as Error);
    }
  }

  function getRequestFilters(props: Record<string, string>) {
    return Object.entries(props)
      .filter((entry) => entry[1])
      .map(([property, value]) => ({ property, value }));
  }

  if (loading) {
    return <>Loading...</>;
  }

  if (accAsset) {
    return (
      <>
        <div className="grid grid-cols-3 grid-rows-2 gap-5">
          {cluster && (
            <AssetPropertiesItem
              tooltip="Cluster"
              value={cluster}
              icon={<ClusterIcon />}
            />
          )}
          {provider && (
            <AssetPropertiesItem
              tooltip="Provider"
              value={provider}
              icon={<CloudIcon />}
            />
          )}
          {account && (
            <AssetPropertiesItem
              tooltip="Account"
              value={account}
              icon={<AccountBoxIcon />}
            />
          )}
          {project && (
            <AssetPropertiesItem
              tooltip="Project"
              value={project}
              icon={<FolderIcon />}
            />
          )}
          {service && (
            <AssetPropertiesItem
              tooltip="Service"
              value={service}
              icon={<DesktopWindowsIcon />}
            />
          )}
          {category && (
            <AssetPropertiesItem
              tooltip="Category"
              value={category}
              icon={<CategoryIcon />}
            />
          )}
          {region && (
            <AssetPropertiesItem
              tooltip="Region"
              value={region}
              icon={<MapIcon />}
            />
          )}
          {type === 'Node' && nodeType && (
            <AssetPropertiesItem
              tooltip="Node Type"
              value={nodeType}
              icon={<NodeIcon />}
            />
          )}
          {type === 'Disk' && storageClass && (
            <AssetPropertiesItem
              tooltip="Storage Class"
              value={storageClass}
              icon={<StorageIcon />}
            />
          )}
        </div>
        <Table className="w-[100%] my-5">
          <TableHead>
            <TableRow>
              <TableHeadCell align="left" scope="row">
                Hours
              </TableHeadCell>
              <TableHeadCell align="right" scope="row">
                Discount
              </TableHeadCell>
              <TableHeadCell align="right" scope="row">
                Adjustment
              </TableHeadCell>
              <TableHeadCell align="right" scope="row">
                Hourly Rate
              </TableHeadCell>
              <TableHeadCell align="right" scope="row">
                Total Cost
              </TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left" scope="row" width={200}>
                {round(hours, 5)}
              </TableCell>
              <TableCell align="right" scope="row">
                {get(accAsset, 'discount', 0)}
              </TableCell>
              <TableCell align="right" scope="row">
                {toCurrency(get(accAsset, 'adjustment', 0), currency, 3)}
              </TableCell>
              <TableCell align="right" scope="row">
                {toCurrency(costPerHr, currency, 3)}
              </TableCell>
              <TableCell align="right" scope="row">
                {toCurrency(get(accAsset, 'totalCost', 0), currency, 2)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {type === 'Node' && (
          <NodeAssetDetails
            nodeAsset={accAsset as NodeAsset}
            currency={currency}
            hours={hours}
          />
        )}
        {type === 'Disk' && (
          <DiskAssetDetails
            diskAsset={accAsset as DiskAsset}
            currency={currency}
            hours={hours}
          />
        )}
      </>
    );
  }

  return <></>;
};

export const DetailsMemoized = memo(Details);
