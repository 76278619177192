import React, { FC, ReactNode, useState } from 'react';
import { Button, Card, Typography } from '@kubecost-frontend/holster';
import { useNavigate } from 'react-router-dom';
import { toCurrency } from '../../services/format';
import { ArrowIcon } from '../../assets/images';
import { BetaBadge } from '../../components';

interface SavingsCardProps {
  currency: string;
  description: ReactNode;
  icon: any;
  cardLoading: boolean;
  savings: number;
  title: string;
  type: string;
  url: string;
  isBeta?: boolean;
  isBetaMessage?: string;
}

export const SavingsCard: FC<SavingsCardProps> = ({
  currency,
  description,
  icon,
  cardLoading,
  savings,
  title,
  type,
  url,
  isBeta,
  isBetaMessage,
}) => {
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);

  const logoVerbiage =
    type === 'kube' ? 'Kubernetes Insight' : 'Cloud insight outside clusters';

  const hasSavings = savings > 0;
  const footerText = hasSavings ? 'Save up to' : 'Explore savings';

  const goToUrl = (url: string) => {
    navigate(url);
  };

  const onMouseEnter = () => {
    setHover(true);
  };

  const onMouseExit = () => {
    setHover(false);
  };

  return (
    <>
      {!cardLoading && (
        <Card
          style={{
            border: '1px solid #E6EBE9',
            borderRadius: '0px',
            transition: 'box-shadow .1s',
            boxShadow: hover ? '0 1px 1px #BFCDC9' : 'none',
            cursor: 'pointer',
          }}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseExit}
          onClick={() => goToUrl(url)}
        >
          <div
            style={{
              display: 'grid',
              gridTemplateRows: '1fr 70px',
              height: '200px',
            }}
          >
            <div style={{ padding: '1em', color: '#023927' }}>
              <Typography
                variant="p"
                style={{
                  color: '#023927',
                  fontWeight: 'bolder',
                  paddingBottom: '1em',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {title}
                {isBeta && <BetaBadge message={isBetaMessage} />}
              </Typography>
              <Typography
                variant="p-small"
                style={{ color: '#607971', fontSize: '14px' }}
              >
                {description}
              </Typography>
            </div>

            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '.35fr 1fr 1fr',
                backgroundColor: '#FAFBFA',
                alignItems: 'center',
                borderTop: '1 solid #E6EBE9',
              }}
            >
              {icon}
              <Typography variant="p-small">{logoVerbiage}</Typography>
              <div>
                {hasSavings ? (
                  <Typography
                    variant="p-small"
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      alignContent: 'start',
                      paddingRight: '1em',
                    }}
                  >
                    {footerText}
                  </Typography>
                ) : (
                  <Typography
                    variant="p-small"
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      paddingRight: '1em',
                    }}
                  >
                    {footerText}
                  </Typography>
                )}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignContent: 'start',
                  }}
                >
                  {hasSavings ? (
                    <Typography variant="h5" style={{ color: '#28B359' }}>
                      {toCurrency(savings, currency)} /mo
                    </Typography>
                  ) : null}
                  <Button
                    style={{
                      border: 'none',
                      boxShadow: 'none',
                      backgroundColor: 'transparent',
                      padding: 0,
                      color: '#28B359',
                    }}
                    variant="primary"
                    onClick={() => goToUrl(url)}
                  >
                    <ArrowIcon direction="RIGHT" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

SavingsCard.displayName = 'SavingsCard';
