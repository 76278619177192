import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/BookmarkBorder';
import SavedIcon from '@material-ui/icons/Bookmark';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import grey from '@material-ui/core/colors/grey';

const SaveControl = ({
  title,
  setTitle,
  window,
  aggregateBy,
  idle,
  filters,
  save,
  unsave,
  titleField,
  setTitleField,
  findSavedReport,
  chartDisplay,
  rate,
  sharedNamespaces,
  sharedOverhead,
  customSharedOverhead,
  defaultSharedOverhead,
  customShareNamespaces,
  defaultShareNamespaces,
  customShareLabels,
  defaultShareLabels,
}) => {
  const [open, setOpen] = useState(false);

  const report = {
    window,
    aggregateBy,
    chartDisplay,
    rate,
    idle,
    filters,
    sharedOverhead: customSharedOverhead,
    sharedNamespaces: customShareNamespaces,
    sharedLabels: customShareLabels,
  };
  const isSaved = findSavedReport(report) !== null;
  report.title = title;

  return (
    <>
      {isSaved ? (
        <Tooltip title="Unsave report">
          <IconButton
            id="allocation-unsave-report-button"
            onClick={() => setOpen(true)}
          >
            <SavedIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Save report">
          <IconButton
            id="allocation-save-report-button"
            onClick={() => {
              setTitleField(title);
              setOpen(true);
            }}
          >
            <SaveIcon />
          </IconButton>
        </Tooltip>
      )}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={() => setOpen(false)}
      >
        {isSaved ? (
          <>
            <DialogTitle>Unsave report</DialogTitle>
            <DialogContent>
              <DialogContentText>{`Are you sure you want to unsave ${report.title}? This action affects all users.`}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                id="allocation-unsave-report-confirm-button"
                onClick={() => {
                  unsave(report);
                  setOpen(false);
                }}
              >
                Unsave
              </Button>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle>Save report</DialogTitle>
            <DialogContent>
              <TextField
                id="allocation-save-report-name-input"
                value={titleField}
                onChange={(e) => setTitleField(e.target.value)}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button
                id="allocation-save-report-confirm-button"
                onClick={() => {
                  setTitle(titleField);
                  save({ ...report, title: titleField });
                  setOpen(false);
                }}
              >
                Save
              </Button>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default React.memo(SaveControl);
