import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ContainerRow from './ContainerRow';

const useStyles = makeStyles({
  breakdown: {
    marginBottom: 20,
  },
  title: {
    padding: '12px 16px',
  },
  tableCellRoot: {
    fontSize: '0.865rem',
  },
});

function descendingComparator(a, b, orderBy) {
  if (get(b, orderBy) < get(a, orderBy)) {
    return -1;
  }
  if (get(b, orderBy) > get(a, orderBy)) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, label: 'Container', colspan: 2 },
  { id: 'cluster', numeric: false, label: 'Cluster', colspan: 1 },
  { id: 'usage.cpuCores', numeric: true, label: 'CPU usage', colspan: 1 },
  { id: 'requests.cpuCores', numeric: true, label: 'CPU request', colspan: 1 },
  { id: 'target.cpuCores', numeric: true, label: "CPU recomm'd", colspan: 1 },
  { id: 'usage.ramBytes', numeric: true, label: 'RAM usage', colspan: 1 },
  { id: 'requests.ramBytes', numeric: true, label: 'RAM request', colspan: 1 },
  { id: 'target.ramBytes', numeric: true, label: "RAM recomm'd", colspan: 1 },
  { id: 'efficiency', numeric: true, label: 'Efficiency', colspan: 1 },
  { id: 'monthlySavings', numeric: true, label: 'Savings', colspan: 1 },
];

const Breakdown = ({ containers, currency, grafanaURL }) => {
  const classes = useStyles();

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('monthlySavings');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const navigate = useNavigate();
  const routerLocation = useLocation();
  const search = new URLSearchParams(routerLocation.search);
  const numContainers = containers.length;

  useEffect(() => {
    search.delete('page');
    navigate({ search: `?${search.toString()}` }, { replace: true });
  }, [numContainers]);

  useEffect(() => {
    if (search.has('page')) {
      setPage(parseInt(search.get('page'), 10));
    } else {
      setPage(0);
    }

    if (search.has('pageSize')) {
      setRowsPerPage(parseInt(search.get('pageSize'), 10));
    } else {
      setRowsPerPage(10);
    }
  }, [routerLocation]);

  const lastPage = Math.floor(numContainers / rowsPerPage);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, numContainers - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    search.set('page', newPage);
    navigate({ search: `?${search.toString()}` });
  };

  const handleChangeRowsPerPage = (event) => {
    search.set('page', 0);
    search.set('pageSize', event.target.value);
    navigate({ search: `?${search.toString()}` });
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  return (
    <Paper className={classes.breakdown}>
      <Typography variant="h6" className={classes.title}>
        Breakdown
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map((cell) => (
                <TableCell
                  key={cell.id}
                  colSpan={cell.colspan}
                  align={cell.numeric ? 'right' : 'left'}
                  sortDirection={orderBy === cell.id ? order : false}
                  classes={{ root: classes.tableCellRoot }}
                >
                  <TableSortLabel
                    active={orderBy === cell.id}
                    direction={orderBy === cell.id ? order : 'asc'}
                    onClick={createSortHandler(cell.id)}
                  >
                    {cell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(containers, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((container, c) => (
                <ContainerRow
                  key={c}
                  container={container}
                  currency={currency}
                  grafanaURL={grafanaURL}
                />
              ))}
            {emptyRows > 0 && rowsPerPage === 10 && (
              <TableRow style={{ height: 59 * emptyRows }}>
                <TableCell classes={{ root: classes.tableCellRoot }} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={numContainers}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25, 50]}
        page={Math.min(page, lastPage)}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default Breakdown;
