export const issues = [
  [
    'currentCapacity',
    'reliability',
    'Facing compute pressure',
    'high',
    'Monitoring for high CPU and memory utilizating can help identify broad performance risks. This test monitors 99% memory and CPU usage over the past 24h in comparison to currently provisioned capacity.',
  ],
  [
    'highClusterMemRequestUtilization',
    'reliability',
    'Memory requests nearing cluster capacity',
    'high',
    'Your memory requests are approaching cluster capacity, which could restrict your ability to deploy new deployments and reducing your resiliancy to node failure.',
  ],
  [
    'highClusterCpuRequestUtilization',
    'reliability',
    'CPU requests nearing cluster capacity',
    'high',
    'Your CPU requests are approaching cluster capacity, which could restrict your ability to deploy new deployments and reducing your resiliancy to node failure.',
  ],
  [
    'crashLooping',
    'reliability',
    'Crash looping pods',
    'high',
    'The following pods have been crash looping over the last 10 minutes. Crash looping pods can unnecessarily consume cluster resources, not to mention cause specific application reliability issues.',
  ],
  [
    'tooManyFiles',
    'capacity',
    'Approaching open file limit',
    'high',
    'The following pod/jobs are using greater than 90% of the maximum open file limit. You will likely see Go and other programs start to reveive a "too many open files" error when this limit is reached.',
  ],
  [
    'pidPressure',
    'capacity',
    'Node is facing PID pressure',
    'high',
    'The following nodes have too many processes running.',
  ],
  [
    'pvErrors',
    'storage',
    'Persistent volume errors found',
    'high',
    'The following persistent volumes have been experiencing errors for the past 5 minutes.',
  ],
  [
    'pendingPods',
    'reliability',
    'Pending pods found',
    'high',
    'The following pods are unable to be scheduled due to resource constraints.',
  ],
  [
    'failedJobs',
    'reliability',
    'Recently failing jobs found',
    'high',
    'The following jobs have failed in the last 10 minutes.',
  ],
  [
    'podsOOMed',
    'reliability',
    'Out of memory event detected',
    'high',
    'The following pods have experienced an out of memory (OOM) event in the last 10 minutes.',
  ],
  [
    'iNodeLimitPvc',
    'storage',
    'Persistent volume near iNode threshold',
    'high',
    'The following persistent volumes are using 90% or more of available iNodes and may hit space constraints soon.',
  ],
  [
    'iNodeLimitLocal',
    'storage',
    'Local device near iNode threshold',
    'high',
    'The following nodes have devices with 90% or more of total iNodes used and may hit space constraints soon.',
  ],

  [
    'nodeMemoryPressure',
    'memory',
    'Node memory pressure detected',
    'high',
    'Nodes become unstable when facing kubelet memory pressure. The kubelet will begin failing pods in this state. You can see how to configure OOM eviction handling in <a href="https://kubernetes.io/docs/tasks/administer-cluster/out-of-resource/#node-oom-behavior">this article</a>.',
  ],

  [
    'fullPV',
    'storage',
    'Persistent volume predicted to reach capacity in 48 hours',
    'high',
    'The following persistent volumes are expected to reach greater than 90% of capacity within the next two days. Resizing the disk or moving storage is recommended.',
  ],
  [
    'fullAttachedDisk',
    'storage',
    'Local storage predicted to reach capacity in 48 hours',
    'high',
    'The following local disks are expected to reach greater than 90% of capacity within the next two days. Resizing the disk or moving storage is recommended.',
  ],
  [
    'notMultiZoneCluster',
    'replication',
    'Worker nodes not spread across multiple failure zones',
    'medium',
    'To reduce the impact of zone outages, spread your nodes across multiple availability zones. When completed, Kubernetes will use best efforts to spread pods in a replication controller or service across multiple zones. For more information, please see <a href="https://kubernetes.io/docs/setup/multiple-zones/">Running in Multiple Zones</a>.',
  ],
  [
    'badNode',
    'stability',
    'Bad node detected (includes memory, kernel, etc.)',
    'medium',
    'The following nodes are stuck in a bad state. This can lead to pods that hang as pending. ',
  ],
  [
    'clusterCostsUp',
    'cost',
    'Daily cluster costs increased 10% or more',
    'medium',
    `We have detected that your cluster costs have increased more than 10% day-over-day. Visit the <a href="overview.html">Overview</a> and <a href="/allocations.html">Cost Allocation</a> tabs to learn more about what's driving this spend increase.`,
  ],
  [
    'notMultiMasterCluster',
    'replication',
    'Cluster does not have replicated masters',
    'medium',
    'When a controlling master node fails, the Kubernetes API goes offline, which reduces the cluster to a collection of ad-hoc nodes without centralized management. This means the cluster will be unresponsive to issues like additional node failures, requests to create new resources, or to move pods to different nodes, until the master node is brought back online. While applications will typically continue to function normally during master node downtime, DNS queries may not resolve if a node is rebooted during master node downtime. For production clusters, we recommend master replication.',
  ],

  [
    'networkIssues',
    'network',
    'Network issues detected',
    'high',
    'Network issues are flagged when packet loss is excessive or a high rate of network erorrs are detected.',
  ],
  [
    'cpuThrottled',
    'perf',
    'CPU throttling detected',
    'medium',
    'The following pods were throttled more than 20% over the last 10 minutes.',
  ],
  [
    'tooManyPods',
    'reliability',
    'Approaching kubelet pod limit',
    'medium',
    'The following nodes are near the the default 110 pod limit imposed by kubelets. The maximum number of pods per node can be increased with the kubelet "--max-pods" option',
  ],
];

// 100 TB
const MAX_FS_BYTES = 100 * 1099511627776;

export const clusterHealthDropped = function (
  timeWindowMins,
  threshold,
  callback,
) {
  var q1 = 'stackwatch_environment_health';
  var params = helper.getQueryRangeParams(timeWindowMins, 'm', 1, 'm');
  $.when(
    $.getJSON(helper.getQueryRangeEndpoint() + encodeURIComponent(q1) + params),
  ).then(function (qr1) {
    var min;
    var max = 0;

    // health metric not found
    if (
      typeof qr1.data === 'undefined' ||
      typeof qr1.data.result === 'undefined' ||
      typeof qr1.data.result[0] === 'undefined'
    ) {
      console.warn(
        'Warning: cluster health metrics not available in Prometheus. See log statement below for more info.',
      );

      callback({
        overThreshold: false,
      });
      return;
    }

    $.each(qr1.data.result[0].values, function (i, n) {
      var val = parseFloat(n[1]);
      if (min == undefined) {
        min = val;
      } else if (val < min) {
        min = val;
      } else if (val > max) {
        max = val;
      }
    });
    if (max - min > threshold) {
      callback({
        overThreshold: true,
      });
    } else {
      callback({
        overThreshold: false,
      });
    }
  });
};

export const exceededClusterBudget = function (
  timeWindowDays,
  threshold,
  callback,
) {
  var baseUrl = getCurrentContainerAddressModel();
  $.getJSON(baseUrl + '/clusterInfo', function (res) {
    if (res && res.data) {
      var id = res.data['id'];
      $.getJSON(
        baseUrl +
          '/aggregatedCostModel?window=' +
          timeWindowDays +
          '&offset=1m&aggregation=cluster&allocateIdle=true',
        function (response) {
          var clusterCost = response.data[id]['totalCost'];
          if (clusterCost > threshold) {
            callback({
              overThreshold: true,
              totalCost: clusterCost,
            });
          } else {
            callback({
              overThreshold: false,
              totalCost: clusterCost,
            });
          }
        },
      );
    }
  });
};

export const getBestEffortRamConsumers = function (
  timeWindowDays,
  threshold,
  callback,
) {
  var deferred = new $.Deferred();
  var q1 = `label_replace( sum(kube_pod_container_status_running) by (pod,namespace) , "pod_name", "$1", "pod", "(.+)" )`;
  var q2 = `sum(avg(avg_over_time(container_memory_usage_bytes{container_name!="",container_name!="POD"}[${timeWindowDays}d])) by (namespace,pod_name,container_name)) by (namespace,pod_name) / 1024 / 1024 / 1024`;
  var q3 = `label_replace(sum(kube_pod_container_resource_requests{resource="memory", unit="byte"}) by (pod,namespace), "pod_name", "$1", "pod", "(.+)" ) / 1024 / 1024 / 1024`;
  var totalClusterRam = 0;
  var podArray = [];

  $.when(
    helper.getAllNodesPromise(),
    $.getJSON(helper.getQueryEndpoint() + encodeURIComponent(q1)),
    $.getJSON(helper.getQueryEndpoint() + encodeURIComponent(q2)),
    $.getJSON(helper.getQueryEndpoint() + encodeURIComponent(q3)),
  ).then(function (allNodes, qr1, qr2, qr3) {
    $.each(allNodes.items, function (i, node) {
      totalClusterRam += getNodeRamCapacityGiBytes(node);
    });

    helper.joinPromQsMultipleKeys(qr1, qr2, 'namespace', 'pod_name');
    helper.joinPromQsMultipleKeys(qr1, qr3, 'namespace', 'pod_name');

    $.each(qr1[0].data.result, function (i, pod) {
      if (pod.value[3] == 0 && pod.value[2] > totalClusterRam * threshold)
        podArray.push(pod);
    });

    if (typeof callback !== 'undefined') {
      callback(podArray);
    }

    deferred.resolve(podArray);
  });
  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const getNodeRamCapacityGiBytes = function (node) {
  var capacity = node.status.capacity.memory;
  return parseInt(capacity) / 1024 / 1024;
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const isCPUThrottled = function (timeWindow, threshold, callback) {
  var deferred = new $.Deferred();
  var foundArray = [];

  var q1 = `avg(increase(container_cpu_cfs_throttled_periods_total{pod_name!=""}[${timeWindow}])) by (container_name, pod_name, namespace)
                / avg(increase(container_cpu_cfs_periods_total{pod_name!=""}[${timeWindow}])) by (container_name, pod_name, namespace) > ${threshold}`;

  $.when($.getJSON(helper.getQueryEndpoint() + encodeURIComponent(q1))).then(
    function (qr1) {
      foundArray = qr1.data.result;

      if (typeof callback !== 'undefined') {
        callback(foundArray);
      }

      deferred.resolve(foundArray);
    },
  );
  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const highClusterMemoryUsage = function (
  timeWindowMins,
  threshold,
  callback,
) {
  var deferred = new $.Deferred();
  var overThreshold = false;
  let usage = 0;

  var q1 = `quantile_over_time(0.99,kubecost_cluster_memory_working_set_bytes[${timeWindowMins}m]) / sum(kube_node_status_capacity_memory_bytes)`;

  $.when($.getJSON(helper.getQueryEndpoint() + encodeURIComponent(q1))).then(
    function (qr1) {
      if (
        qr1 === null ||
        typeof qr1.data === 'undefined' ||
        typeof qr1.data.result === 'undefined' ||
        qr1.data.result.length < 1
      ) {
        console.warn('Warning: unable to measure cluster memory usage');
      } else {
        usage = qr1.data.result[0].value[1];
        if (usage > threshold) {
          overThreshold = true;
        }
      }

      if (typeof callback !== 'undefined') {
        callback({
          overThreshold: overThreshold,
          usage: usage,
        });
      }
      deferred.resolve({
        overThreshold: overThreshold,
        usage: usage,
      });
    },
  );
  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const lowClusterMemory = function (timeWindowMins, threshold, callback) {
  var deferred = new $.Deferred();
  var overThreshold = false;

  var q1 = `sum(avg(kube_pod_container_resource_requests{resource="memory", unit="byte"}) by (container, pod, namespace)) / sum(avg(kube_node_status_capacity_memory_bytes) by (node))`;

  var params = helper.getQueryRangeParams(timeWindowMins, 'm', 1, 'm');

  $.when(
    $.getJSON(helper.getQueryRangeEndpoint() + encodeURIComponent(q1) + params),
  ).then(function (qr1) {
    var min;

    if (
      qr1 === null ||
      typeof qr1.data === 'undefined' ||
      typeof qr1.data.result === 'undefined' ||
      qr1.data.result.length < 1
    ) {
      console.warn('Warning: unable to measure cluster memory usage');
      log(qr1);
    } else {
      $.each(qr1.data.result[0].values, function (i, n) {
        var val = parseFloat(n[1]);
        if (min == undefined) {
          min = val;
        } else if (val < min) {
          min = val;
        }
      });
      if (min > threshold) {
        overThreshold = true;
      }
    }

    if (typeof callback !== 'undefined') {
      callback({
        overThreshold: overThreshold,
        usage: min,
      });
    }
    deferred.resolve({
      overThreshold: overThreshold,
      usage: min,
    });
  });
  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const lowClusterCPU = function (timeWindowMins, threshold, callback) {
  var deferred = new $.Deferred();
  var overThreshold = false;

  var q1 = `sum(avg(kube_pod_container_resource_requests{resource="cpu", unit="core"}) by (container, pod, namespace)) / sum(avg(kube_node_status_capacity_cpu_cores) by (node))`;

  var params = helper.getQueryRangeParams(timeWindowMins, 'm', 1, 'm');

  $.when(
    $.getJSON(helper.getQueryRangeEndpoint() + encodeURIComponent(q1) + params),
  ).then(function (qr1) {
    var min;

    if (
      qr1 === null ||
      typeof qr1 === 'undefined' ||
      typeof qr1.data === 'undefined' ||
      typeof qr1.data.result === 'undefined' ||
      qr1.data.result.length < 1
    ) {
      console.warn('Warning: unable to measure cluster CPU usage');
      log(qr1);
    } else {
      $.each(qr1.data.result[0].values, function (i, n) {
        var val = parseFloat(n[1]);
        if (min == undefined) {
          min = val;
        } else if (val < min) {
          min = val;
        }
      });
    }

    if (min > threshold) {
      overThreshold = true;
    }
    if (typeof callback !== 'undefined') {
      callback({
        overThreshold: overThreshold,
        usage: min,
      });
    }
    deferred.resolve({
      overThreshold: overThreshold,
      usage: min,
    });
  });
  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const hasTooManyPods = function (timeWindow, threshold, callback) {
  var deferred = new $.Deferred();
  var nodeArray = [];

  var q1 = `avg(avg_over_time(kubelet_running_pod_count[${timeWindow}])) by (instance) > (100 * ${threshold})`;

  $.when($.getJSON(helper.getQueryEndpoint() + encodeURIComponent(q1))).then(
    function (qr1) {
      if (typeof qr1.data != 'undefined') {
        nodeArray = qr1.data.result;
      } else {
        console.warn('Warning: unable to find nodes with high pod count');
      }

      if (typeof callback !== 'undefined') {
        callback(nodeArray);
      }

      deferred.resolve(nodeArray);
    },
  );

  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const crashLooping = function (timeWindow, threshold, callback) {
  var deferred = new $.Deferred();
  var podArray = [];

  var q1 = `avg(rate(kube_pod_container_status_restarts_total[${timeWindow}])) by (pod,namespace) * 60 * 5 > ${threshold}`;

  $.when($.getJSON(helper.getQueryEndpoint() + encodeURIComponent(q1))).then(
    function (qr1) {
      podArray = qr1 === null ? [] : qr1.data.result;

      if (typeof callback !== 'undefined') {
        callback(podArray);
      }

      deferred.resolve(podArray);
    },
  );

  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const pendingPods = function (timeWindow, callback) {
  var deferred = new $.Deferred();
  var podArray = [];
  var stepDuration = 1;
  var maxSamples = timeWindow / stepDuration + 1;

  var q1 = `sum(kube_pod_status_phase{phase="Pending"}) by (pod,namespace) > 0`;
  var params = helper.getQueryRangeParams(timeWindow, 'm', stepDuration, 'm');

  $.when(
    $.getJSON(helper.getQueryRangeEndpoint() + encodeURIComponent(q1) + params),
  ).then(function (qr1) {
    $.each(qr1.data.result, function (i, d) {
      var total = 0;
      $.each(d.values, function (i, value) {
        var v = parseInt(value[1]);
        total += v;
      });

      if (total >= maxSamples) {
        podArray.push(d);
      }
    });

    if (typeof callback !== 'undefined') {
      callback(podArray);
    }

    deferred.resolve(podArray);
  });
  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const failedJobs = function (timeWindowMins, callback) {
  var deferred = new $.Deferred();
  var podArray = [];
  var q1 = `kube_job_status_failed > 0`;
  var params = helper.getQueryRangeParams(timeWindowMins, 'm', 1, 'm');

  $.when(
    $.getJSON(helper.getQueryRangeEndpoint() + encodeURIComponent(q1) + params),
  ).then(function (qr1) {
    $.each(qr1.data.result, function (i, d) {
      if (d.values[0][1] < d.values[d.values.length - 1][1]) {
        podArray.push(d);
      }
    });

    if (typeof callback !== 'undefined') {
      callback(podArray);
    }

    deferred.resolve(podArray);
  });
  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const persistentVolINodes = function (timeWindow, threshold, callback) {
  var deferred = new $.Deferred();
  var pvcArray = [];

  var q1 = `avg(kubelet_volume_stats_inodes_used) by (persistentvolumeclaim)  / avg(kubelet_volume_stats_inodes) by (persistentvolumeclaim) > ${threshold}`;

  var stepDuration = 2;
  var maxSamples = timeWindow / stepDuration;
  var params = helper.getQueryRangeParams(timeWindow, 'm', stepDuration, 'm');

  $.when(
    $.getJSON(helper.getQueryRangeEndpoint() + encodeURIComponent(q1) + params),
  ).then(function (qr1) {
    $.each(qr1.data.result, function (i, pvc) {
      // ~50% of samples crossed threshold
      if (pvc.values.length > maxSamples / 2) {
        pvcArray.push(pvc);
      }
    });

    if (typeof callback !== 'undefined') {
      callback(pvcArray);
    }

    deferred.resolve(pvcArray);
  });

  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const localINodes = function (timeWindowMins, threshold, callback) {
  var deferred = new $.Deferred();
  var deviceArray = [];

  var q1 = `1 - avg(container_fs_inodes_free{container_name=""}) by (instance,device) / avg(container_fs_inodes_total{container_name=""}) by (instance,device) > ${threshold}`;

  var stepDuration = 2;
  var maxSamples = timeWindowMins / stepDuration;
  var params = helper.getQueryRangeParams(
    timeWindowMins,
    'm',
    stepDuration,
    'm',
  );

  $.when(
    $.getJSON(helper.getQueryRangeEndpoint() + encodeURIComponent(q1) + params),
  ).then(function (qr1) {
    if (typeof qr1.data === 'undefined') {
      console.warn('Warning: unable to get iNode metrics');
    } else {
      $.each(qr1.data.result, function (i, device) {
        // ~50% of samples crossed threshold
        if (device.values.length > maxSamples / 2) {
          deviceArray.push(device);
          device.metric.name =
            device.metric.instance + ' (' + device.metric.device + ')';
        }
      });
    }

    if (typeof callback !== 'undefined') {
      callback(deviceArray);
    }

    deferred.resolve(deviceArray);
  });

  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const tooManyFiles = function (timeWindowMins, threshold, callback) {
  var deferred = new $.Deferred();
  var foundArray = [];

  var q1 = `avg(process_open_fds) by (job,instance,kubernetes_name,kubernetes_namespace,kubernetes_pod_name) / avg(process_max_fds) by (job,instance,kubernetes_name,kubernetes_namespace,kubernetes_pod_name) > ${threshold}`;

  var stepDuration = 2;
  var maxSamples = timeWindowMins / stepDuration;
  var params = helper.getQueryRangeParams(
    timeWindowMins,
    'm',
    stepDuration,
    'm',
  );

  $.when(
    $.getJSON(helper.getQueryRangeEndpoint() + encodeURIComponent(q1) + params),
  ).then(function (qr1) {
    $.each(qr1.data.result, function (i, job) {
      if (typeof job.metric.kubernetes_name !== 'undefined') {
        job.metric.name = job.metric.kubernetes_name;
      } else {
        job.metric.name =
          'job: ' + job.metric.job + ' (' + job.metric.instance + ')';
      }
      foundArray.push(job);
    });

    if (typeof callback !== 'undefined') {
      callback(foundArray);
    }

    deferred.resolve(foundArray);
  });

  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const lowReplicas = function (timeWindowMins, threshold, callback) {
  var deferred = new $.Deferred();
  var foundArray = [];
  var q1 = `avg(kube_deployment_status_replicas_available) by (deployment,namespace) / avg(kube_deployment_spec_replicas) by (deployment,namespace) < ${threshold}`;

  var stepDuration = 1;
  var maxSamples = timeWindowMins / stepDuration + 1;
  var params = helper.getQueryRangeParams(
    timeWindowMins,
    'm',
    stepDuration,
    'm',
  );

  $.when(
    $.getJSON(helper.getQueryRangeEndpoint() + encodeURIComponent(q1) + params),
  ).then(function (qr1) {
    $.each(qr1.data.result, function (i, deployment) {
      var metric = deployment.metric;

      if (deployment.values.length >= maxSamples) {
        foundArray.push(deployment);
      }
    });

    if (typeof callback !== 'undefined') {
      callback(foundArray);
    }

    deferred.resolve(foundArray);
  });
  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const pidPressure = function (timeWindowMins, threshold, callback) {
  var deferred = new $.Deferred();
  var nodeArray = [];
  var q1 = `sum(kube_node_status_condition{condition="PIDPressure",status="true"}) by (node) > 0`;

  var stepDuration = 2;
  var maxSamples = timeWindowMins / stepDuration + 1;
  var params = helper.getQueryRangeParams(
    timeWindowMins,
    'm',
    stepDuration,
    'm',
  );

  $.when(
    $.getJSON(helper.getQueryRangeEndpoint() + encodeURIComponent(q1) + params),
  ).then(function (qr1) {
    if (
      qr1 === null ||
      typeof qr1 === 'undefined' ||
      typeof qr1.data === 'undefined' ||
      typeof qr1.data.result === 'undefined'
    ) {
      console.warn('Warning: unable to measure PID pressure');
      log(qr1);
      deferred.resolve(nodeArray);
      return;
    }

    $.each(qr1.data.result, function (i, node) {
      // threshold % of samples where in PIDPressure state
      if (node.values.length / maxSamples > threshold) {
        node.metric.name = node.metric.node;
        nodeArray.push(node);
      }
    });

    if (typeof callback !== 'undefined') {
      callback(nodeArray);
    }

    deferred.resolve(nodeArray);
  });

  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const podsOOMed = function (timeWindowMins, callback) {
  var deferred = new $.Deferred();
  var podArray = [];

  var q1 = `kube_pod_container_status_terminated_reason{reason = "OOMKilled"} > 0`;
  var params = helper.getQueryRangeParams(timeWindowMins, 'm', 1, 'm');

  $.when(
    $.getJSON(helper.getQueryRangeEndpoint() + encodeURIComponent(q1) + params),
  ).then(function (qr1) {
    $.each(qr1.data.result, function (i, d) {
      $.each(d.values, function (i, val) {
        if (d.values[0][1] >= 1) {
          podArray.push(d);
          return false;
        }
      });
    });

    if (typeof callback !== 'undefined') {
      callback(podArray);
    }

    deferred.resolve(podArray);
  });
  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const pvErrors = function (timeWindowMins, callback) {
  var deferred = new $.Deferred();
  var pvArray = [];
  var q1 = `sum(kube_persistentvolume_status_phase{phase="Failed"}) by (persistentvolume) > 1`;

  var stepDuration = 2;
  var maxSamples = timeWindowMins / stepDuration + 1;
  var params = helper.getQueryRangeParams(
    timeWindowMins,
    'm',
    stepDuration,
    'm',
  );

  $.when(
    $.getJSON(helper.getQueryRangeEndpoint() + encodeURIComponent(q1) + params),
  ).then(function (qr1) {
    $.each(qr1.data.result, function (i, pv) {
      if (
        pv.values[0][1] == 1 &&
        pv.values[pv.values.length - 1][1] == 1 &&
        pv.values.length >= maxSamples * 0.8
      ) {
        pvArray.push(pv);
      }
    });

    if (typeof callback !== 'undefined') {
      callback(pvArray);
    }

    deferred.resolve(pvArray);
  });
  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const clusterCostsUp = function (timeWindowDays, threshold, callback) {
  var deferred = new $.Deferred();
  var overThreshold = false;

  // @ts-ignore
  getClusterCosts('24h', function (clusterCosts1d, clusterInfo, modelConfigs) {
    // @ts-ignore
    getClusterCosts(
      '48h',
      function (clusterCosts2d, clusterInfo, modelConfigs) {
        try {
          let total2dCost =
            clusterCosts2d[Object.keys(clusterCosts2d)[0]].totalCumulativeCost;
          let total1dCost =
            clusterCosts1d[Object.keys(clusterCosts1d)[0]].totalCumulativeCost;
          let increase = total2dCost - 2 * total1dCost;
          var percentIncrease = increase / total1dCost;

          if (percentIncrease > threshold) {
            overThreshold = true;
          }
        } catch (err) {
          console.warn('Warning: unable to measure cluster cost change');
        }

        if (typeof callback !== 'undefined') {
          callback({
            overThreshold: overThreshold,
            increase: percentIncrease,
          });
        }
        deferred.resolve({
          overThreshold: overThreshold,
          increase: percentIncrease,
        });
      },
    );
  });

  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

var SEVERE_ERROR_PENALTY = 50;
var ERROR_PENALTY = 15;
var WARNING_PENALTY = 3;

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const getClusterHealth = function (tier, callback) {
  let healthResults = {};
  let score = 100;

  $.when(
    isSingleMasterCluster(),
    isClusterSingleRegion(),
    findNodeMemoryPressure(),
    findBadNodes(),
    predictFullLocalDisks(48),
    predictFullPVs(48),
    highClusterMemoryUsage('120', 0.9),
  ).then(function (
    isSingleMaster,
    isClusterSingleRegion,
    hasNodeMemoryPressue,
    hasBadNodes,
    predictedFullLocalDisks,
    predictedFullPVs,
    hasHighMemoryUsage,
  ) {
    healthResults.isSingleMasterCluster = getHealthTestObject(
      !isSingleMaster,
      WARNING_PENALTY,
    );
    healthResults.isSingleRegionCluster = getHealthTestObject(
      !isClusterSingleRegion,
      WARNING_PENALTY,
    );
    healthResults.hasBadNodes = getHealthTestObject(
      !arrayHasItems(hasBadNodes),
      ERROR_PENALTY,
    );
    healthResults.predictedFullPVs = getHealthTestObject(
      !arrayHasItems(predictedFullPVs),
      WARNING_PENALTY,
    );
    healthResults.predictedFullLocalDisks = getHealthTestObject(
      !arrayHasItems(predictedFullLocalDisks),
      WARNING_PENALTY,
    );
    healthResults.hasHighMemoryUsage = getHealthTestObject(
      !hasHighMemoryUsage.overThreshold,
      SEVERE_ERROR_PENALTY,
    );
    healthResults.hasNodeMemoryPressue = getHealthTestObject(
      !arrayHasItems(hasNodeMemoryPressue),
      ERROR_PENALTY,
    );

    $.each(healthResults, function (i, healthTest) {
      score -= healthTest.penalty;
    });

    healthResults.score = score;

    if (healthResults.score > 90) {
      healthResults.category = 'GOOD';
      healthResults.categoryColor = '#3cba54';
    } else if (healthResults.score > 70) {
      healthResults.category = 'FAIR';
      healthResults.categoryColor = '#F4B400';
    } else {
      healthResults.category = 'POOR';
      healthResults.categoryColor = 'red';
    }

    callback(healthResults);
  });
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const getNetworkErrors = function (timeWindowDays, threshold, callback) {
  var deferred = new $.Deferred();
  var podArray = [];
  var q1 = `label_replace( sum(kube_pod_container_status_running) by (pod,namespace) , "pod_name", "$1", "pod", "(.+)" )`;
  var q2 = `sum(avg( rate( container_network_receive_errors_total[${timeWindowDays}d])) by (container_name, pod_name, namespace)) by (pod_name, container_name)`;
  var q3 = `sum(avg( rate( container_network_transmit_errors_total[${timeWindowDays}d])) by (container_name, pod_name, namespace)) by (pod_name, container_name)`;

  $.when(
    $.getJSON(getQueryEndpoint() + encodeURIComponent(q1)),
    $.getJSON(getQueryEndpoint() + encodeURIComponent(q2)),
    $.getJSON(getQueryEndpoint() + encodeURIComponent(q3)),
  ).then(function (qr1, qr2, qr3) {
    helper.joinPromQsMultipleKeys(qr1, qr2, 'namespace', 'pod_name');
    helper.joinPromQsMultipleKeys(qr1, qr3, 'namespace', 'pod_name');

    $.each(qr1[0].data.result, function (i, pod) {
      if (pod.value[2] > threshold || pod.value[3] > threshold) {
        podArray.push(pod);
      }
    });

    if (typeof callback !== 'undefined') {
      callback(podArray);
    }

    deferred.resolve(podArray);
  });

  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const packetsDropped = function (
  timeWindowDays,
  threshold,
  selector,
  callback,
) {
  var deferred = new $.Deferred();
  var podArray = [];
  var q1 = `label_replace( sum(kube_pod_container_status_running) by (pod,namespace) , "pod_name", "$1", "pod", "(.+)" )`;
  var q2 = `sum(avg( rate( container_network_receive_packets_dropped_total[${timeWindowDays}d])) by (container_name, pod_name, namespace)) by (pod_name, container_name)`;
  var q3 = `sum(avg( rate( container_network_transmit_packets_dropped_total[${timeWindowDays}d])) by (container_name, pod_name, namespace)) by (pod_name, container_name)`;

  $.when(
    $.getJSON(getQueryEndpoint() + encodeURIComponent(q1)),
    $.getJSON(getQueryEndpoint() + encodeURIComponent(q2)),
    $.getJSON(getQueryEndpoint() + encodeURIComponent(q3)),
  ).then(function (qr1, qr2, qr3) {
    helper.joinPromQsMultipleKeys(qr1, qr2, 'namespace', 'pod_name');
    helper.joinPromQsMultipleKeys(qr1, qr3, 'namespace', 'pod_name');
    $.each(qr1[0].data.result, function (i, pod) {
      if (pod.value[2] > threshold || pod.value[3] > threshold) {
        podArray.push(pod);
      }
    });

    if (typeof callback !== 'undefined') {
      callback(podArray);
    }

    deferred.resolve(podArray);
  });

  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const findDaemonsWithoutLimits = function (callback) {
  var deferred = new $.Deferred();
  var dsArray = [];

  $.when($.getJSON(getCurrentContainerAddressModel() + '/allDaemonSets')).then(
    function (qr1) {
      $.each(qr1.items, function (i, ds) {
        $.each(ds.spec.template.spec.containers, function (i, container) {
          if (
            container.resources.limits == undefined ||
            container.resources.limits.memory == undefined
          ) {
            dsArray.push(ds);
            return false;
          }
        });
      });

      if (typeof callback !== 'undefined') {
        callback(dsArray);
      }

      deferred.resolve(dsArray);
    },
  );

  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const getCloseCPULimit = function (
  timeWindowDays,
  thresholds,
  aggregator,
  callback,
) {
  var deferred = new $.Deferred();
  var q1 = `label_replace( sum(kube_pod_container_status_running) by (pod,namespace) , "pod_name", "$1", "pod", "(.+)" )`;
  var q2 = `sum( avg(rate(container_cpu_usage_seconds_total{pod_name=~".+",container_name!="POD"}[${timeWindowDays}d])) by (pod_name, container_name, namespace)) by (pod_name, namespace)`;
  var q3 = `label_replace(sum(kube_pod_container_resource_limits_cpu_cores) by (pod,namespace), "pod_name", "$1", "pod", "(.+)" )`;
  var podArray = [];

  $.when(
    helper.getAllNodesPromise(),
    $.getJSON(getQueryEndpoint() + encodeURIComponent(q1)),
    $.getJSON(getQueryEndpoint() + encodeURIComponent(q2)),
    $.getJSON(getQueryEndpoint() + encodeURIComponent(q3)),
  ).then(function (allNodes, qr1, qr2, qr3) {
    helper.joinPromQsMultipleKeys(qr1, qr2, 'namespace', 'pod_name');
    helper.joinPromQsMultipleKeys(qr1, qr3, 'namespace', 'pod_name');
    $.each(qr1[0].data.result, function (i, pod) {
      if (pod.value[3] > 0 && pod.value[2] / pod.value[3] > thresholds) {
        podArray.push(pod);
      }
    });

    if (typeof callback !== 'undefined') {
      callback(podArray);
    }

    deferred.resolve(podArray);
  });

  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const getCloseRAMLimit = function (
  timeWindowDays,
  thresholds,
  aggregator,
  callback,
) {
  var deferred = new $.Deferred();
  var q1 = `label_replace( sum(kube_pod_container_status_running) by (pod,namespace) , "pod_name", "$1", "pod", "(.+)" )`;
  var q2 = `sum(avg(avg_over_time(container_memory_usage_bytes{container_name!="",container_name!="POD"}[${timeWindowDays}d])) by (namespace,pod_name,container_name)) by (namespace,pod_name) / 1024 / 1024 / 1024`;
  var q3 = `label_replace(sum(kube_pod_container_resource_limits_memory_bytes) by (pod,namespace), "pod_name", "$1", "pod", "(.+)" ) / 1024 / 1024 / 1024`;
  var podArray = [];

  $.when(
    helper.getAllNodesPromise(),
    $.getJSON(getQueryEndpoint() + encodeURIComponent(q1)),
    $.getJSON(getQueryEndpoint() + encodeURIComponent(q2)),
    $.getJSON(getQueryEndpoint() + encodeURIComponent(q3)),
  ).then(function (allNodes, qr1, qr2, qr3) {
    helper.joinPromQsMultipleKeys(qr1, qr2, 'namespace', 'pod_name');
    helper.joinPromQsMultipleKeys(qr1, qr3, 'namespace', 'pod_name');
    $.each(qr1[0].data.result, function (i, pod) {
      if (pod.value[3] > 0 && pod.value[2] / pod.value[3] > thresholds) {
        podArray.push(pod);
      }
    });

    if (typeof callback !== 'undefined') {
      callback(podArray);
    }

    deferred.resolve(podArray);
  });

  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const getCPUOverusers = function (
  timeWindowDays,
  thresholds,
  aggregator,
  callback,
) {
  var deferred = new $.Deferred();
  var q1 = `label_replace( sum(kube_pod_container_status_running) by (pod,namespace) , "pod_name", "$1", "pod", "(.+)" )`;
  var q2 = `sum( avg(rate(container_cpu_usage_seconds_total{pod_name=~".+",container_name!="POD"}[${timeWindowDays}d])) by (pod_name, container_name, namespace)) by (pod_name, namespace)`;
  var q3 = `label_replace(sum(kube_pod_container_resource_requests{resource="cpu", unit="core"}) by (pod,namespace), "pod_name", "$1", "pod", "(.+)" )`;
  var podArray = [];
  $.when(
    helper.getAllNodesPromise(),
    $.getJSON(getQueryEndpoint() + encodeURIComponent(q1)),
    $.getJSON(getQueryEndpoint() + encodeURIComponent(q2)),
    $.getJSON(getQueryEndpoint() + encodeURIComponent(q3)),
  ).then(function (allNodes, qr1, qr2, qr3) {
    helper.joinPromQsMultipleKeys(qr1, qr2, 'namespace', 'pod_name');
    helper.joinPromQsMultipleKeys(qr1, qr3, 'namespace', 'pod_name');

    $.each(qr1[0].data.result, function (i, pod) {
      if (pod.value[3] > 0 && pod.value[2] / pod.value[3] > thresholds) {
        podArray.push(pod);
      }
    });

    if (typeof callback !== 'undefined') {
      callback(podArray);
    }

    deferred.resolve(podArray);
  });
  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const getMemoryOverusers = function (
  timeWindowDays,
  thresholds,
  aggregator,
  callback,
) {
  var deferred = new $.Deferred();
  var q1 = `label_replace( sum(kube_pod_container_status_running) by (pod,namespace) , "pod_name", "$1", "pod", "(.+)" )`;
  var q2 = `sum(avg(avg_over_time(container_memory_usage_bytes{container_name!="",container_name!="POD"}[${timeWindowDays}d])) by (namespace,pod_name,container_name)) by (namespace,pod_name) / 1024 / 1024 / 1024`;
  var q3 = `label_replace(sum(kube_pod_container_resource_requests{resource="memory", unit="byte"}) by (pod,namespace), "pod_name", "$1", "pod", "(.+)" ) / 1024 / 1024 / 1024`;
  var podArray = [];

  $.when(
    helper.getAllNodesPromise(),
    $.getJSON(getQueryEndpoint() + encodeURIComponent(q1)),
    $.getJSON(getQueryEndpoint() + encodeURIComponent(q2)),
    $.getJSON(getQueryEndpoint() + encodeURIComponent(q3)),
  ).then(function (allNodes, qr1, qr2, qr3) {
    helper.joinPromQsMultipleKeys(qr1, qr2, 'namespace', 'pod_name');
    helper.joinPromQsMultipleKeys(qr1, qr3, 'namespace', 'pod_name');

    $.each(qr1[0].data.result, function (i, pod) {
      if (pod.value[3] > 0 && pod.value[2] / pod.value[3] > thresholds) {
        podArray.push(pod);
      }
    });

    if (typeof callback !== 'undefined') {
      callback(podArray);
    }

    deferred.resolve(podArray);
  });
  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const getBestEffortCPUConsumers = function (
  timeWindowDays,
  threshold,
  callback,
) {
  var deferred = new $.Deferred();
  var q1 = `label_replace( sum(kube_pod_container_status_running) by (pod,namespace) , "pod_name", "$1", "pod", "(.+)" )`;
  var q2 = `sum(avg(rate(container_cpu_usage_seconds_total{pod_name=~".+",container_name!="POD"}[${timeWindowDays}d])) by (container,pod_name,namespace)) by (pod_name,namespace)`;
  var q3 = `label_replace(sum(kube_pod_container_resource_requests{resource="cpu", unit="core"}) by (pod,namespace), "pod_name", "$1", "pod", "(.+)" ) / 1024 / 1024 / 1024`;
  var totalClusterCPU = 0;
  var podArray = [];

  $.when(
    helper.getAllNodesPromise(),
    $.getJSON(getQueryEndpoint() + encodeURIComponent(q1)),
    $.getJSON(getQueryEndpoint() + encodeURIComponent(q2)),
    $.getJSON(getQueryEndpoint() + encodeURIComponent(q3)),
  ).then(function (allNodes, qr1, qr2, qr3) {
    $.each(allNodes.items, function (i, node) {
      totalClusterCPU += getNodeCPUCapacity(node);
    });

    helper.joinPromQsMultipleKeys(qr1, qr2, 'namespace', 'pod_name');
    helper.joinPromQsMultipleKeys(qr1, qr3, 'namespace', 'pod_name');

    if (typeof qr1[0].data !== 'undefined') {
      $.each(qr1[0].data.result, function (i, pod) {
        if (pod.value[3] == 0 && pod.value[2] > totalClusterCPU * threshold) {
          podArray.push(pod);
        }
      });
    }

    if (typeof callback !== 'undefined') {
      callback(podArray);
    }

    deferred.resolve(podArray);
  });
  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const getUnreplicatedDeployments = function (callback) {
  var deferred = new $.Deferred();
  var deploymentArray = [];

  $.when($.getJSON(getCurrentContainerAddressModel() + '/allDeployments')).then(
    function (qr1) {
      $.each(qr1.items, function (i, deployment) {
        // TODO: decide if we should exclude kube-system and montioring ns
        if (deployment.status.availableReplicas <= 1) {
          deploymentArray.push(deployment);
        }
      });

      if (typeof callback !== 'undefined') {
        callback(deploymentArray);
      }

      deferred.resolve(deploymentArray);
    },
  );
  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const isClusterSingleRegion = function (callback) {
  var deferred = new $.Deferred();
  var singleZone = true;
  var zone = undefined;

  $.when(helper.getAllNodesPromise()).then(function (qr1) {
    $.each(qr1.items, function (i, node) {
      if (zone == undefined) {
        zone = node.metadata.labels['failure-domain.beta.kubernetes.io/zone'];
      }

      if (
        zone !== node.metadata.labels['failure-domain.beta.kubernetes.io/zone']
      ) {
        singleZone = false;
      }
    });

    if (typeof callback !== 'undefined') {
      callback(singleZone);
    }

    deferred.resolve(singleZone);
  });
  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const getHealthTestObject = function (passed, penalty, message) {
  if (passed) penalty = 0;
  if (typeof message === 'undefined') message = null;
  let result = { passed: passed, penalty: penalty, message: message };
  return result;
};

export const arrayHasItems = function (array) {
  return typeof array === 'object' && array.length > 0;
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const isEKS = function (node) {
  let isEKS = false;
  try {
    if (node.status.nodeInfo.kubeletVersion.includes('eks')) isEKS = true;
  } catch (error) {}

  return isEKS;
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const isSingleMasterCluster = function (callback) {
  var deferred = new $.Deferred();
  var masterCount = 0;

  $.when(helper.getAllNodesPromise()).then(function (qr1) {
    $.each(qr1.items, function (i, node) {
      if (helper.isMaster(node) || isEKS(node)) {
        masterCount++;
      }
    });

    var isSingleMaster = masterCount < 2;
    if (typeof callback !== 'undefined') {
      callback(isSingleMaster);
    }

    deferred.resolve(isSingleMaster);
  });
  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const findNodeMemoryPressure = function (callback) {
  var deferred = new $.Deferred();
  var nodeArray = [];

  $.when(helper.getAllNodesPromise()).then(function (qr1) {
    $.each(qr1.items, function (i, node) {
      $.each(node.status.conditions, function (i, condition) {
        if (
          condition.status.toLowerCase() === 'true' &&
          condition.type.toLowerCase() === 'memorypressure'
        ) {
          nodeArray.push(node);
          return false;
        }
      });
    });

    if (typeof callback !== 'undefined') {
      callback(nodeArray);
    }

    deferred.resolve(nodeArray);
  });
  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~

export const findBadNodes = function (callback) {
  var deferred = new $.Deferred();
  var nodeArray = [];

  $.when(helper.getAllNodesPromise()).then(function (qr1) {
    $.each(qr1.items, function (i, node) {
      $.each(node.status.conditions, function (i, condition) {
        if (
          condition.status.toLowerCase() === 'true' &&
          condition.type.toLowerCase() !== 'ready'
        ) {
          nodeArray.push(node);
          return false;
        }
      });
    });

    if (typeof callback !== 'undefined') {
      callback(nodeArray);
    }
    deferred.resolve(nodeArray);
  });
  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const predictFullLocalDisks = function (hoursAhead, callback) {
  var q1 = `sum(predict_linear(container_fs_usage_bytes{device=~"^/dev/[sv]d[a-z][1-9]$",id="/"}[48h], ${hoursAhead} * 3600)) by (instance) / 1024 / 1024 / 1024`;
  var q2 =
    'sum(container_fs_limit_bytes{id="/"}) by (instance) / 1024 / 1024 / 1024';
  var deferred = new $.Deferred();
  var diskArray = [];
  var threshold = 0.9;

  $.when(
    $.getJSON(helper.getQueryEndpoint() + encodeURIComponent(q1)),
    $.getJSON(helper.getQueryEndpoint() + encodeURIComponent(q2)),
  ).then(function (qr1, qr2) {
    helper.joinPromQRs(qr1, qr2, 'instance', 'instance');

    if (
      qr1[0] === undefined ||
      typeof qr1[0].data === 'undefined' ||
      typeof qr1[0].data.result === 'undefined'
    ) {
      console.error('Error: unable to detect full disks');
      log(qr1[0]);
    } else {
      $.each(qr1[0].data.result, function (i, disk) {
        var predictedUsage = parseFloat(disk.value[1]);
        var currentCapacity = parseFloat(disk.value[2]);

        if (currentCapacity > MAX_FS_BYTES / 1024 / 1024 / 1024) {
          console.warn(
            'Warning: very large container_fs_limit_bytes result detected, ignoring...',
          );
        } else {
          if (
            predictedUsage / currentCapacity > threshold &&
            currentCapacity > 0
          ) {
            diskArray.push(disk);
          }
        }
      });
    }

    if (typeof callback !== 'undefined') {
      callback(diskArray);
    }

    deferred.resolve(diskArray);
  });

  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const predictFullPVs = function (hoursAhead, callback) {
  var q1 = `sum( predict_linear(kubelet_volume_stats_used_bytes[48h], ${hoursAhead} * 3600) ) by (persistentvolumeclaim) / 1024 / 1024 / 1024`;
  var q2 =
    'sum(kube_persistentvolumeclaim_info) by (persistentvolumeclaim, storageclass, namespace) * on (persistentvolumeclaim,namespace) group_right(storageclass) sum(kube_persistentvolumeclaim_resource_requests_storage_bytes) by (persistentvolumeclaim,namespace) / 1024 / 1024 / 1024';
  var deferred = new $.Deferred();
  var diskArray = [];
  var threshold = 0.9;

  $.when(
    $.getJSON(helper.getQueryEndpoint() + encodeURIComponent(q1)),
    $.getJSON(helper.getQueryEndpoint() + encodeURIComponent(q2)),
  ).then(function (qr1, qr2) {
    helper.joinPromQRs(
      qr1,
      qr2,
      'persistentvolumeclaim',
      'persistentvolumeclaim',
    );

    if (
      qr1[0] === undefined ||
      typeof qr1[0].data === 'undefined' ||
      typeof qr1[0].data.result === 'undefined' ||
      typeof qr2[0].data === 'undefined'
    ) {
      console.error(
        'Error: unable to detect full pvs : ' +
          JSON.stringify(qr1[0]) +
          JSON.stringify(qr2[0]),
      );
    } else {
      $.each(qr1[0].data.result, function (i, disk) {
        var predictedUsage = parseFloat(disk.value[1]);
        var currentCapacity = parseFloat(disk.value[2]);

        if (
          predictedUsage / currentCapacity > threshold &&
          currentCapacity > 0
        ) {
          diskArray.push(disk);
        }
      });
    }

    if (typeof callback !== 'undefined') {
      callback(diskArray);
    }

    deferred.resolve(diskArray);
  });

  return deferred.promise();
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
