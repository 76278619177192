import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';

import { ProductTier } from '../../hooks/useProductTier';
import { EnablementNames } from '../../hooks/useEnablements';
import MagicWand from '../../assets/images/magic-wand-icon';
import { Outlet } from 'react-router';
import { use_DEPRECATED_ClustersEnablements } from '../../hooks/useClustersEnablement';

const sessionKey = 'enablementsModalDismissed';

const EnablementsModal: React.FC = () => {
  const enablements = use_DEPRECATED_ClustersEnablements();
  const violations = enablements.filter((enablement) => enablement.isViolation);
  const isModalDismissed = window.sessionStorage.getItem(sessionKey) === 'true';

  /* Only show the modal if you haven't dismissed it in the current session or
   * if you have enablement violations. Order matters! */
  const [isOpen, setIsOpen] = useState<boolean>(
    !isModalDismissed || violations.length > 0,
  );

  if (violations.length === 0 || isModalDismissed) return <Outlet />;

  const isPlural = violations.length > 1;

  /* This logic is pretty primitive but basically, you can't be inviolation on
   * the Free tier so if you're not Enterprise than you're Business.
   * Additionally, we "round up" of sorts by suggesting the highest necessary
   * tier to cover all enablements.
   *
   * Example:
   * If a users has 4 enablement violations and even just one is an Enterprise
   * violation we suggest Enterprise.
   */
  const recommendedUpgrade = violations.filter(
    (violation) => violation.requiredTier === ProductTier.Enterprise,
  )
    ? ProductTier.Enterprise
    : ProductTier.Business;

  const articleGrammar =
    recommendedUpgrade === ProductTier.Enterprise ? 'an' : 'a';

  const getTitle = () => {
    if (!isPlural) {
      return (
        <>
          You found {articleGrammar}{' '}
          <span className={'capitalize'}>{violations[0].requiredTier}</span>{' '}
          feature!
        </>
      );
    }
    // Handles title pluralization
    for (const violation of violations) {
      if (violation.requiredTier === ProductTier.Enterprise) {
        return (
          <>
            You found{' '}
            <span className={'capitalize'}>{violation.requiredTier}</span>{' '}
            features!
          </>
        );
      }
    }
  };

  const handleOnClick = () => {
    setIsOpen((prevState) => {
      window.sessionStorage.setItem(sessionKey, Boolean(prevState).toString());

      return !prevState;
    });
  };

  return (
    <>
      <Outlet />
      <Dialog fullWidth maxWidth="sm" open={isOpen}>
        <DialogContent className={'!p-7 text-center'}>
          <Typography
            className={'!font-[700] !leading-normal !text-3xl'}
            variant={'h4'}
          >
            {getTitle()}
          </Typography>
          <List>
            {violations.map((violation) => (
              <ListItem className={'mx-auto !py-0 !w-fit'}>
                <ListItemAvatar className={'!min-w-min pr-2'}>
                  <MagicWand />
                </ListItemAvatar>
                <ListItemText>{EnablementNames[violation.key]}</ListItemText>
              </ListItem>
            ))}
          </List>

          <Typography className={'!mx-auto pb-7 !w-3/4'} variant="subtitle1">
            {isPlural ? 'These are' : 'This is a'} paid{' '}
            <span className={'capitalize'}>{recommendedUpgrade}</span>{' '}
            {isPlural ? 'features' : 'feature'}. Upgrade to explore{' '}
            {isPlural ? 'these features' : 'this feature'} and many others.
          </Typography>
          <footer className={'flex flex-col items-center'}>
            <Button
              className={'!mb-4 w-fit'}
              color={'primary'}
              href={'https://www.kubecost.com/pricing/?upgrade=true'}
              target={'_blank'}
              variant="contained"
            >
              Learn More
            </Button>

            <Button
              className={'w-fit'}
              onClick={handleOnClick}
              variant={'text'}
            >
              Dismiss
            </Button>
          </footer>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EnablementsModal;
