import model from '../model';

export enum SpotReady {
  No = 'Not spot ready',
  Maybe = 'May be spot ready',
  Yes = 'Spot ready',
  NA = 'Not applicable',
}

export type ChecklistCheck = {
  ready: SpotReady;
  shortMessage: string;
  longMessage: string;
};

export type Checklist = {
  controllerTypeCheck: ChecklistCheck;
  replicasCheck: ChecklistCheck;
  localStorageCheck: ChecklistCheck;
  namespaceAnnotationOverrideCheck: ChecklistCheck;
  controllerAnnotationOverrideCheck: ChecklistCheck;
  controllerPDBCheck: ChecklistCheck;
  deploymentRollingUpdateCheck: ChecklistCheck;
};

export type ChecklistResult = {
  name: string;
  namespace: string;
  kind: string;
  checklist: Checklist;
  readiness: SpotReady;
};

export type ChecklistResponse = ChecklistResult[];

// fetchSpotChecklist returns the cluster controllers with a spot ready checklist criteria
export async function fetchSpotChecklist(): Promise<ChecklistResponse> {
  const result: Promise<ChecklistResponse> = model.get(
    '/savings/spotChecklist',
  );
  return result;
}

export type EstimatedChecklist = {
  checklistResult: ChecklistResult;
  monthlyRate: number;
  monthlySpotRate: number;
  monthlySavings: number;
  monthlySavingsPct: number;

  errors: string[];
};

export type EstimatedChecklistResponse = EstimatedChecklist[];

export async function fetchSpotChecklistEstimated(
  window: string,
): Promise<EstimatedChecklistResponse> {
  const result: Promise<EstimatedChecklistResponse> = model.get(
    '/savings/spotChecklistEstimated',
    { window },
  );
  return result;
}
