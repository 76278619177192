import { useQuery } from 'react-query';

import { getCurrentContainerAddressModel } from '../services/util';
import { AllocationSet } from '../types/allocation';

import { APIEnablementDatum, EnablementKeys } from './useEnablements';
import useProductTier, { ProductTier } from './useProductTier';

const getClusters = async (): Promise<AllocationSet> => {
  const response = await fetch(
    `${getCurrentContainerAddressModel()}/allocation?window=7d&accumulate=true&aggregate=cluster&idle=false`,
  );

  if (!response.ok) throw new Error('Network response was not ok');

  const data = await response.json();

  return data.data[0];
};

/* This hook is deprecated and will be removed when the `useEnablements` hook
 * supports returning data for `unifiedMultiCluster`
 *
 * TODO: delete and point references to `useEnablements`
 * https://github.com/kubecost/cost-analyzer-frontend/issues/1389
 */
const use_DEPRECATED_ClustersEnablements = (): APIEnablementDatum[] => {
  const isProductTier = useProductTier();
  const { data: clusters } = useQuery(
    ['enablements', 'data', ['unifiedMultiCluster']],
    getClusters,
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
    },
  );

  let isUnifiedMultiCluster = false;

  if (clusters) {
    isUnifiedMultiCluster = Object.keys(clusters).length > 1;
  }

  return [
    {
      key: EnablementKeys.unifiedMultiCluster,
      requiredTier: ProductTier.Enterprise,
      isEnabled: isUnifiedMultiCluster,
      isViolation: !isProductTier.Enterprise && isUnifiedMultiCluster,
    },
  ];
};

export { use_DEPRECATED_ClustersEnablements };
