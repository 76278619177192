import React, { FC } from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import InfoIcon from '@material-ui/icons/Info';
import Link from '@material-ui/core/Link';

export const NoCacheMessage: FC = () => {
  const noCacheMessage = 'No Caches currently available.';

  return (
    <>
      <Typography variant="body2" color="textSecondary">
        {noCacheMessage}
        <Tooltip title="To Learn more about savings diagnotics click here">
          <Link
            target="_blank"
            href="https://guide.kubecost.com/hc/en-us/articles/4407595942679-Kubecost-Diagnostics"
          >
            <InfoIcon style={{ color: grey[500], fontSize: '20px' }} />
          </Link>
        </Tooltip>
      </Typography>
    </>
  );
};

NoCacheMessage.displayName = 'NoCacheMessage';
