import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@kubecost-frontend/holster';

interface ApplyMessagesProps {
    header: string;
    data: string[];
}

export const ApplyMessages: FC<ApplyMessagesProps> = ({
  header,
  data,
}) => {
  const listItems = data.map((datum, i) => (
    <li
      key={i}
      style={{
        listStyle: 'disc',
      }}
    >
      <Typography variant='h6'>{datum}</Typography>
    </li>
  ));
  const dataDisplay = <ul>{listItems}</ul>;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant='h5'>{header}</Typography>
      </Grid>
      <Grid item>{dataDisplay}</Grid>
    </Grid>
  );
};

ApplyMessages.displayName = 'ApplyMessages';