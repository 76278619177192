import React from 'react';

/**
 * Create child component(s) based on the result of some boolean expression.
 */
function If({ children, condition }: ComponentProps): React.ReactElement {
  if (condition) {
    if (Array.isArray(children)) {
      return <>{children}</>;
    }
    return children;
  }
  return <></>;
}

export default If;

type ComponentProps = {
  children: React.ReactElement[] | React.ReactElement;
  condition: boolean;
};
