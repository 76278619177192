import React, { useState } from 'react';
import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  Paper,
  TableContainer,
} from '@material-ui/core';
import {
  ReportConfigItem,
  handleValueFromString,
  handleActionColumn,
  ReportConfig,
} from './reportingHelpers';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import get from 'lodash/get';
import { toCurrency } from '../../services/format';
import { colorMap } from '../../services/colors';

interface AssetBreakdownMapObjectItem {
  columnName: string;
  dataKey: string;
  defaultValue: string;
}

interface AssetBreakdownMapObject {
  category: AssetBreakdownMapObjectItem[];
  cluster: AssetBreakdownMapObjectItem[];
  service: AssetBreakdownMapObjectItem[];
  type: AssetBreakdownMapObjectItem[];
  provider: AssetBreakdownMapObjectItem[];
  account: AssetBreakdownMapObjectItem[];
  providerid: AssetBreakdownMapObjectItem[];
}

const baseDataSet: AssetBreakdownMapObjectItem[] = [
  {
    columnName: 'Credits',
    dataKey: 'credit',
    defaultValue: '0.00$',
  },
  {
    columnName: 'Adjusted',
    dataKey: 'adjustment',
    defaultValue: '0.00$',
  },
  {
    columnName: 'Total Cost ($)',
    dataKey: 'totalCost',
    defaultValue: '0.00$',
  },
];

const assetAggMapForBreakdownTable: AssetBreakdownMapObject = {
  category: [
    {
      columnName: 'Category',
      dataKey: 'properties.category',
      defaultValue: '-',
    },
    ...baseDataSet,
  ],
  cluster: [
    {
      columnName: 'Cluster Name',
      dataKey: 'properties.cluster',
      defaultValue: '-',
    },
    ...baseDataSet,
  ],
  service: [
    {
      columnName: 'Service',
      dataKey: 'properties.service',
      defaultValue: '-',
    },
    ...baseDataSet,
  ],
  type: [
    {
      columnName: 'Type',
      dataKey: 'properties.type',
      defaultValue: '-',
    },
    ...baseDataSet,
  ],
  provider: [
    {
      columnName: 'Provider',
      dataKey: 'properties.provider',
      defaultValue: '-',
    },
    ...baseDataSet,
  ],
  account: [
    {
      columnName: 'Account',
      dataKey: 'properties.account',
      defaultValue: '-',
    },
    ...baseDataSet,
  ],
  providerid: [
    {
      columnName: 'Provider',
      dataKey: 'properties.providerID',
      defaultValue: '-',
    },
    ...baseDataSet,
  ],
};

export const CollapsibleRow = ({
  item,
  reportConfig,
}: {
  item: any;
  reportConfig: ReportConfig;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const tableDisplay: AssetBreakdownMapObjectItem[] =
    assetAggMapForBreakdownTable[`${reportConfig.assetAggregateBy}`];
  return (
    <>
      <React.Fragment>
        <TableRow>
          <TableCell>
            <IconButton
              size="small"
              onClick={() => setIsOpen(!isOpen)}
              style={{ borderStyle: 'solid', borderWidth: 1 }}
            >
              {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          </TableCell>
          {reportConfig.dataMap.map((reportItem: ReportConfigItem) => (
            <TableCell style={{ color: '#28B359' }} key={reportItem.columnName}>
              {reportItem.action
                ? handleActionColumn(item, reportItem)
                : handleValueFromString(item, reportItem.dataKey)}
              {}
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <Box style={{ padding: '1em' }} margin={1}>
                <Typography
                  variant="body1"
                  gutterBottom
                  component="div"
                  style={{ marginBottom: '1em' }}
                >
                  <strong>
                    {'__undefined__' === item.allocation.name
                      ? '__unallocated__'
                      : item.allocation.name}
                  </strong>{' '}
                  Asset Breakdown by{' '}
                  <strong>{reportConfig.assetAggregateBy}</strong>
                </Typography>
                <TableContainer component={Paper}>
                  <Table size="medium" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        {tableDisplay.map(
                          (item: AssetBreakdownMapObjectItem, idx: number) => (
                            <TableCell
                              width={idx === 0 ? '70%' : '10%'}
                              key={item.columnName}
                            >
                              {item.columnName}
                            </TableCell>
                          ),
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {item.assets.entries.map(
                        (assetEntry: any, idx: number) => (
                          <TableRow key={idx}>
                            {tableDisplay.map(
                              (item: AssetBreakdownMapObjectItem) => {
                                const columnValue = get(
                                  assetEntry,
                                  item.dataKey,
                                );
                                return (
                                  <TableCell key={item.dataKey}>
                                    {typeof columnValue === 'string'
                                      ? columnValue
                                      : toCurrency(columnValue, 'USD') ||
                                        item.defaultValue}
                                  </TableCell>
                                );
                              },
                            )}
                          </TableRow>
                        ),
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    </>
  );
};
