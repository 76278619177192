// react
import React from 'react';

// holster
import { Button, Modal, Typography } from '@kubecost-frontend/holster';

interface Alert {
  type: string;
  window: string;
  aggregation: string;
  filter: string;
  id?: string;
  threshold?: number;
  efficiencyThreshold?: number;
  spendThreshold?: number;
  baselineWindow?: string;
  relativeThreshold?: number;
  ownerContact?: string[];
  slackWebhookUrl?: string;
}

interface ComponentProps {
  alert: Alert | null;
  close: () => void;
  remove: (alert: Alert) => Promise<void>;
}

const DeleteAlertModal: React.FC<ComponentProps> = ({
  alert,
  close,
  remove,
}: ComponentProps) => {
  if (!alert) {
    return <></>;
  }
  return (
    <Modal
      open
      data-test="delete-alert-dialog"
      title="Deleting Alert"
      onClose={close}
    >
      <Typography variant="h6" style={{ marginBottom: 20 }}>
        Preparing to delete the following alert:
      </Typography>
      <Typography variant="p">Type: {alert.type}</Typography>
      <Typography variant="p">Window: {alert.window}</Typography>
      <Typography variant="p">
        Aggregation: {alert.aggregation || '""'}
      </Typography>
      <Typography variant="p">Filter: {alert.filter || '*'}</Typography>
      {has(alert, 'threshold') ? (
        <Typography variant="p">
          Threshold: {alert.threshold || 'Not Set'}
        </Typography>
      ) : (
        <></>
      )}
      {has(alert, 'efficiencyThreshold') ? (
        <Typography variant="p">
          Efficiency Threshold: {alert.efficiencyThreshold || 'Not Set'}
        </Typography>
      ) : (
        <></>
      )}
      {has(alert, 'spendThreshold') ? (
        <Typography variant="p">
          Spend Threshold: {alert.spendThreshold || 'Not Set'}
        </Typography>
      ) : (
        <></>
      )}
      {has(alert, 'baselineWindow') ? (
        <Typography variant="p">
          Baseline Window: {alert.baselineWindow || 'Not Set'}
        </Typography>
      ) : (
        <></>
      )}
      {has(alert, 'relativeThreshold') ? (
        <Typography variant="p">
          Relative Threshold: {alert.relativeThreshold || 'Not Set'}
        </Typography>
      ) : (
        <></>
      )}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="default"
          style={{ marginRight: 16 }}
          data-test="cancel-button"
          onClick={close}
        >
          Cancel
        </Button>
        <Button variant="primary" data-test="confirm-button" onClick={onDelete}>
          Confirm
        </Button>
      </div>
    </Modal>
  );

  async function onDelete() {
    if (alert) {
      await remove(alert);
    }
    close();
  }
};

function has(alert: Alert | null, prop: string) {
  return Object.prototype.hasOwnProperty.call(alert, prop);
}

export default DeleteAlertModal;
