// react
import React, { FC, useState } from 'react';

// holster
import { Button, FormControlLabel, Select } from '@kubecost-frontend/holster';

// local
import { AccumulateOptions, FilterType, SelectOptions } from './types';
import { AddFilter } from './AddFilter';

const emptyFilter: FilterType = { property: '', value: '' };

const allFilterPropertyOptions = [
  'Account',
  'Category',
  'Cluster',
  'Label/Tag',
  'Name',
  'Project',
  'Provider',
  'ProviderID',
  'Service',
  'Type',
];

interface EditControlProps {
  accumulateOptions: AccumulateOptions[];
  accumulate: boolean;
  setAccumulate: (item: string) => void;
  filters: FilterType[];
  setFilters: (item: any) => void;
  rate: any;
  setRate: (iotem: any) => void;
  onClose: () => void;
}

export const EditControl: FC<EditControlProps> = ({
  onClose,
  accumulateOptions,
  accumulate,
  setAccumulate,
  filters,
  setFilters,
  rate,
  setRate,
}) => {
  // local state of report config, persisted on save
  const [localAccumulate, setLocalAccumulate] = useState(accumulate.toString());
  const [localFilters, setLocalFilters] = useState([...filters, emptyFilter]);
  const [localRate, setLocalRate] = useState(rate);

  const usedFilterProps = localFilters.map((f) => f.property);
  const filterPropertyOptions = allFilterPropertyOptions.filter(
    (opt) => !usedFilterProps.includes(opt),
  );

  const handleAddFilterToList = () => {
    setLocalFilters((prev) => [...prev, { ...emptyFilter }]);
  };

  const handleRemoveFilterFromList = (index: number) => {
    console.log('deleting filter at index ', index);
    setLocalFilters((prev) => [
      ...prev.slice(0, index),
      ...prev.slice(index + 1),
    ]);
  };

  const handleUpdateFilter = (index: number) => (f: FilterType) => {
    setLocalFilters((prev) => [
      ...prev.slice(0, index),
      f,
      ...prev.slice(index + 1),
    ]);
  };

  const handleSave = () => {
    setAccumulate(localAccumulate);
    setFilters(localFilters);
    setRate(localRate);
    onClose();
  };

  const resolutionOptions: SelectOptions[] = accumulateOptions.map((opt) => ({
    label: opt.name,
    value: opt.value.toString(),
  }));

  const costMetricOpts: SelectOptions[] = [
    {
      label: 'Cumulative Cost',
      value: 'cumulative',
    },
    {
      label: 'Monthly Rate',
      value: 'monthly',
    },
    {
      label: 'Daily Rate',
      value: 'daily',
    },
    {
      label: 'Hourly Rate',
      value: 'hourly',
    },
  ];

  return (
    <>
      <div style={{ padding: '1em' }}>
        <FormControlLabel>Resolution</FormControlLabel>
        <Select
          options={resolutionOptions}
          setValue={setLocalAccumulate}
          value={localAccumulate}
        />
      </div>
      <div style={{ padding: '1em' }}>
        <FormControlLabel>Cost Metric</FormControlLabel>
        <Select
          options={costMetricOpts}
          setValue={setLocalRate}
          value={localRate}
        />
      </div>
      <div>
        {localFilters.map((item, idx) => (
          <AddFilter
            index={idx}
            property={item.property}
            propertyOptions={filterPropertyOptions}
            value={item.value}
            count={localFilters.length}
            handleAddFilter={handleAddFilterToList}
            handleDeleteFilter={() => handleRemoveFilterFromList(idx)}
            handleUpdateFilter={handleUpdateFilter(idx)}
          />
        ))}
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '.2fr .2fr',
          gap: '10px',
          padding: '1em 1em 0 1em',
          justifyContent: 'flex-end',
        }}
      >
        <Button variant="primary" onClick={handleSave}>
          Apply
        </Button>
        <Button variant="default" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </>
  );
};

export const EditoControlMemoized = React.memo(EditControl);
