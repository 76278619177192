import { Paper, Box, Button } from '@material-ui/core';
import React, { useState } from 'react';
import { InspectedResourceListItem } from './inspectState';
import { FilterItem } from './FilterItem';
import { TimeWindowSelector } from './TimeWindowSelector';
import FilterListIcon from '@material-ui/icons/FilterList';

export interface InspectFilterTileProps {
  primaryAggregation?: InspectedResourceListItem;
  secondaryAggregations: InspectedResourceListItem[];
  activeTimeWindow: string;
  setActiveTimeWindow: (time: string) => void;
  handleFilterUpdate: (type: string, value: string) => Promise<boolean>;
}

export const InspectFilterTile = ({
  handleFilterUpdate,
  activeTimeWindow,
  setActiveTimeWindow,
  primaryAggregation,
  secondaryAggregations,
}: InspectFilterTileProps) => {
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const filterCount = secondaryAggregations.filter(
    ({ name }: InspectedResourceListItem) => name !== '',
  ).length;
  return (
    <Paper style={{ padding: '2em' }}>
      <Box
        style={{
          display: 'flex',
          gridGap: '1.5em',
        }}
      >
        {primaryAggregation && (
          <div style={{ fontSize: 28 }}>
            <strong>{primaryAggregation.name}</strong> {primaryAggregation.type}
          </div>
        )}
        <TimeWindowSelector
          activeTimeWindow={activeTimeWindow}
          setActiveTimeWindow={setActiveTimeWindow}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<FilterListIcon />}
          onClick={() => setShowFilters(!showFilters)}
        >
          Filters ({filterCount})
        </Button>
      </Box>
      {showFilters && (
        <Box style={{ marginTop: '1em' }} display="flex" alignItems="center">
          {secondaryAggregations.map(
            ({ type, name }: InspectedResourceListItem) => (
              <FilterItem
                type={type}
                defaultValue={name}
                updateItem={handleFilterUpdate}
              />
            ),
          )}
        </Box>
      )}
    </Paper>
  );
};
