import React, { memo, useEffect, useState } from 'react';
import get from 'lodash/get';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClusterIcon from '@material-ui/icons/GroupWork';
import NodeIcon from '@material-ui/icons/Memory';
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';
import CloudIcon from '@material-ui/icons/Cloud';
import MapIcon from '@material-ui/icons/Map';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import CategoryIcon from '@material-ui/icons/Category';
import FolderIcon from '@material-ui/icons/Folder';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
  Tooltip,
  Grid,
} from '@material-ui/core';
import Warnings, { Warning } from '../../components/Warnings';
import model from '../../services/model';
import { captureError } from '../../services/error_reporting';
import logger from '../../services/logger';
import { Allocation, AllocationProperties } from '../../types/allocation';
import DetailsRow from './DetailsRow';

type AllocationListItemProps = {
  tooltip: string;
  value: string | React.ReactElement;
  icon: React.ReactElement;
};

const AllocationPropertiesItem = ({
  tooltip,
  value,
  icon,
}: AllocationListItemProps): React.ReactElement => (
  <Grid item xs={4}>
    <ListItem>
      <ListItemIcon>
        <Tooltip title={tooltip} placement="bottom">
          {icon}
        </Tooltip>
      </ListItemIcon>
      <ListItemText primary={value} />
    </ListItem>
  </Grid>
);

type DetailsProps = {
  window: string;
  service: string;
  properties: AllocationProperties;
};

const Details = ({ window, service, properties }: DetailsProps) => {
  const [fetch, setFetch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [warnings, setWarnings] = useState<Array<Warning>>([]);
  const [rows, setRows] = useState<Array<Allocation>>([]);

  const [cluster, setCluster] = useState(get(properties, 'cluster', ''));
  const [node, setNode] = useState(get(properties, 'node', ''));
  const [nodeURL, setNodeURL] = useState('');
  const [container, setContainer] = useState(get(properties, 'container', ''));
  const [controller, setController] = useState(
    get(properties, 'controller', ''),
  );
  const [controllerKind, setControllerKind] = useState(
    get(properties, 'controllerKind', ''),
  );
  const [namespace, setNamespace] = useState(get(properties, 'namespace', ''));
  const [pod, setPod] = useState(get(properties, 'pod', ''));
  const [services, setServices] = useState(get(properties, 'services', []));
  const [providerID, setProviderID] = useState(
    get(properties, 'providerID', ''),
  );
  const [provider, setProvider] = useState(get(properties, 'provider', ''));
  const [account, setAccount] = useState(get(properties, 'account', ''));
  const [project, setProject] = useState(get(properties, 'project', ''));
  const [region, setRegion] = useState(get(properties, 'region', ''));

  useEffect(() => {
    if (fetch) {
      fetchData();
    }
  }, [fetch]);

  async function fetchNodeURL(nodeName: string) {
    const context = [
      {
        property: 'Service',
        value: 'Kubernetes',
        name: 'Kubernetes',
      },
      {
        property: 'Type',
        value: 'Node',
        name: 'Node',
      },
    ];
    const contextStr = btoa(JSON.stringify(context));
    let url = `assets?agg=&window=${window}&context=${contextStr}`;
    // Get details on asset to open dialog if possible
    const nodeFilter = [
      {
        property: 'type',
        value: 'node',
      },
      {
        property: 'name',
        value: nodeName,
      },
    ];
    const assetResp = await model.getAssets(window, {
      filters: nodeFilter,
      accumulate: true,
    });
    if (assetResp.data && assetResp.data.length > 0) {
      const assetKeys = Object.keys(assetResp.data[0]);
      if (assetKeys.length > 0) {
        const details = btoa(JSON.stringify(assetResp.data[0][assetKeys[0]]));
        url += `&details=${details}`;
      }
    }
    setNodeURL(url);
  }

  async function fetchData() {
    setLoading(true);
    setWarnings([]);

    try {
      const filters = [];

      if (cluster) {
        filters.push({
          property: 'cluster',
          value: cluster,
        });
      }
      if (node) {
        filters.push({
          property: 'node',
          value: node,
        });
      }

      if (namespace) {
        filters.push({
          property: 'namespace',
          value: namespace,
        });
      }

      if (controllerKind) {
        filters.push({
          property: 'controllerKind',
          value: controllerKind,
        });
      }

      if (controller) {
        filters.push({
          property: 'controller',
          value: controller,
        });
      }

      if (service) {
        filters.push({
          property: 'service',
          value: service,
        });
      }

      if (pod) {
        filters.push({
          property: 'pod',
          value: pod,
        });
      }

      const resp = await model.getAllocation(window, '', {
        filters,
        accumulate: true,
      });
      let data: Array<Allocation> = [];
      let nodeName = '';
      Object.keys(resp.data[0]).forEach((key) => {
        const accumulation = resp.data[0][key];
        if (accumulation.name === '__idle__') {
          return;
        }
        const accProps = get(accumulation, 'properties', {});
        if (!cluster) {
          setCluster(get(accProps, 'cluster', ''));
        }

        if (!node) {
          nodeName = get(accProps, 'node', '');
          setNode(nodeName);
        }

        if (!container) {
          setContainer(get(accProps, 'container', ''));
        }

        if (!controller) {
          setController(get(accProps, 'controller', ''));
        }

        if (!controllerKind) {
          setControllerKind(get(accProps, 'controllerKind', ''));
        }

        if (!namespace) {
          setNamespace(get(accProps, 'namespace', ''));
        }

        if (!pod) {
          setPod(get(accProps, 'pod', ''));
        }

        if (!services) {
          setServices(get(accProps, 'services', []));
        }

        if (!providerID) {
          setProviderID(get(accProps, 'providerID', ''));
        }

        if (!provider) {
          setProvider(get(accProps, 'provider', ''));
        }

        if (!account) {
          setAccount(get(accProps, 'account', ''));
        }

        if (!project) {
          setProject(get(accProps, 'project', ''));
        }

        if (!region) {
          setRegion(get(accProps, 'region', ''));
        }
        data.push(accumulation);
      });

      data = reverse(sortBy(data, 'totalCost'));

      setRows(data);
      if (nodeName && !nodeURL) {
        await fetchNodeURL(nodeName);
      }
    } catch (e) {
      logger.warn(
        `Error fetching details for (${controllerKind}, ${controller}):`,
        e,
      );
      captureError(e as Error);
      let secondary = `Tried fetching credentials for: ${namespace}, `;
      if (controllerKind) {
        secondary += `${controllerKind}, `;
      }
      if (controller) {
        secondary += `${controller}, `;
      }
      secondary += pod;
      setWarnings([
        {
          primary: 'Error fetching details',
          secondary,
        },
      ]);
    }

    setLoading(false);
    setFetch(false);
  }

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ paddingTop: 100, paddingBottom: 100 }}>
          <CircularProgress />
        </div>
      </div>
    );
  }
  return (
    <div>
      {!loading && warnings.length > 0 && (
        <div style={{ marginBottom: 20 }}>
          <Warnings warnings={warnings} />
        </div>
      )}
      {node && (
        <ListItem>
          <ListItemIcon>
            <Tooltip title="Node" placement="bottom">
              <NodeIcon />
            </Tooltip>
          </ListItemIcon>
          <Link href={nodeURL}>{node}</Link>
        </ListItem>
      )}
      <Grid container>
        {cluster && (
          <AllocationPropertiesItem
            tooltip="Cluster"
            value={cluster}
            icon={<ClusterIcon />}
          />
        )}
        {controllerKind && (
          <AllocationPropertiesItem
            tooltip="Controller Kind"
            value={controllerKind}
            icon={<CategoryIcon />}
          />
        )}
        {controller && (
          <AllocationPropertiesItem
            tooltip="Controller"
            value={controller}
            icon={<SettingsRemoteIcon />}
          />
        )}
        {provider && (
          <AllocationPropertiesItem
            tooltip="Provider"
            value={provider}
            icon={<CloudIcon />}
          />
        )}
        {account && (
          <AllocationPropertiesItem
            tooltip="Account"
            value={account}
            icon={<AccountBoxIcon />}
          />
        )}
        {project && (
          <AllocationPropertiesItem
            tooltip="project"
            value={project}
            icon={<FolderIcon />}
          />
        )}
        {region && (
          <AllocationPropertiesItem
            tooltip="Region"
            value={region}
            icon={<MapIcon />}
          />
        )}
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={25} />
              <TableCell align="left" component="th" scope="row" width={200}>
                Container
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                Hours
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                Cost-per-Hour
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                Total cost
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <DetailsRow key={row.name} allocation={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default memo(Details);
