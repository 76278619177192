import React, { FC, useEffect, useState } from 'react';
import { endOfDay, startOfDay, isValid } from 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Button, Typography } from '@kubecost-frontend/holster';
import DateFnsUtils from '@date-io/date-fns';
import Popover from '@material-ui/core/Popover';

import find from 'lodash/find';
import get from 'lodash/get';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface SelectWindowProps {
  windowOptions: { label: string; value: string }[];
  window: string;
  setWindow: (window: string) => void;
}

export const DateSelectorNew: FC<SelectWindowProps> = ({
  windowOptions,
  window,
  setWindow,
}) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [intervalString, setIntervalString] = useState<string | null>(null);

  const handleClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStartDateChange = (date: MaterialUiPickersDate) => {
    if (isValid(date)) {
      setStartDate(startOfDay(date));
    }
  };

  const handleEndDateChange = (date: MaterialUiPickersDate) => {
    if (isValid(date)) {
      setEndDate(endOfDay(date));
    }
  };

  const handleSubmitPresetDates = (dateString: string) => {
    setWindow(dateString);
    setStartDate(null);
    setEndDate(null);
    handleClose();
  };

  const handleSubmitCustomDates = () => {
    if (intervalString !== null) {
      setWindow(intervalString);
      handleClose();
    }
  };

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      // Selecting a date should result in a start date at time 00:00:00.000
      // and an end date of midnight, i.e. the following day at 00:00:00.000.
      // This is a hacky solution to say that, if the date picked is
      // 2021-06-20T23:59:59, then make endDate 2021-06-21T00:00:00.
      //
      // NOTE: We should consider, perhaps, an API-side fix that rounds-up
      // 23:59:59 to midnight, but this will work until then.

      const s = new Date(startDate);
      s.setHours(0);
      s.setMinutes(0);
      s.setSeconds(0);
      s.setMilliseconds(0);

      const e = new Date(endDate);
      e.setDate(e.getDate() + 1);
      e.setHours(0);
      e.setMinutes(0);
      e.setSeconds(0);
      e.setMilliseconds(0);

      // Note: getTimezoneOffset() is calculated based on current system locale, NOT date object
      const adjustedStartDate = new Date(s - s.getTimezoneOffset() * 60000);
      const adjustedEndDate = new Date(e - e.getTimezoneOffset() * 60000);

      const startStr = adjustedStartDate.toISOString().split('.')[0];
      const endStr = adjustedEndDate.toISOString().split('.')[0];
      setIntervalString(`${startStr}Z,${endStr}Z`);
    }
  }, [startDate, endDate]);

  const open = Boolean(anchorEl);
  const id = open ? 'date-range-popover' : undefined;

  return (
    <>
      <Button variant="default" onClick={(e) => handleClick(e)}>
        {get(find(windowOptions, { value: window }), 'label', 'Custom')}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="p-4 min-width-400">
          <div
            className="flex justify-center align-center"
            style={{
              gap: '.5em',
            }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                style={{ width: '144px' }}
                autoOk
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-range-control-custom-start"
                label="Start Date"
                value={startDate}
                maxDate={new Date()}
                maxDateMessage="Date should not be after today."
                onChange={handleStartDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <KeyboardDatePicker
                style={{ width: '144px' }}
                autoOk
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-range-control-custom-end"
                label="End Date"
                value={endDate}
                maxDate={new Date()}
                maxDateMessage="Date should not be after today."
                onChange={handleEndDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div
            className="grid justify-center mt-4 p-4"
            style={{
              gridTemplateColumns: '1fr 1fr',
            }}
          >
            {windowOptions.map((opt) => (
              <Typography
                key={opt.value}
                variant="p"
                className={`mr-6 p-2 hover:bg-kc-success-light rounded cursor-pointer ${
                  get(find(windowOptions, { value: window }), 'label') ===
                  opt.label
                    ? 'text-kc-link'
                    : ''
                }`}
                onClick={() => handleSubmitPresetDates(opt.value)}
              >
                {opt.label}
              </Typography>
            ))}
          </div>
          <div
            className="flex justify-end mt-4"
            style={{
              gap: '.5em',
            }}
          >
            <Button variant="default" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSubmitCustomDates}>
              Apply
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
};

DateSelectorNew.displayName = 'DateSelectorNew';
