import React, { useState } from 'react';
import { Button, Input } from '@kubecost-frontend/holster';

import { PlusIcon } from '../../assets/images/plus-icon';

interface ListInputProps {
  label?: string;
  onClick: (inputValue: string) => void;
}

const ListInput = ({ label, onClick }: ListInputProps) => {
  const [inputValue, setInputValue] = useState<string>('');

  const handleOnClickButton = () => {
    onClick(inputValue);
    setInputValue('');
  };

  return (
    <fieldset>
      {label && (
        <label
          className={'block font-bold pb-1'}
          htmlFor={`listInput-${label}`}
        >
          {label}
        </label>
      )}
      <div className={'flex'}>
        <Input
          className={'flex-1'}
          id={label ? `listInput-${label}` : undefined}
          onChange={(e) => setInputValue(e.target.value)}
          value={inputValue}
        />
        <Button
          aria-label={`Add ${label ?? 'item'}`}
          className="px-3 ml-4"
          disabled={!inputValue}
          onClick={handleOnClickButton}
          variant="default"
        >
          <PlusIcon />
        </Button>
      </div>
    </fieldset>
  );
};

export { ListInput };
