// react
import React, { FC, useEffect, useState } from 'react';

// lodash
import get from 'lodash/get';

// holster
import { Card, Tooltip, Typography } from '@kubecost-frontend/holster';

// local
import { Header } from '../../components/Header2New';
import Loading from '../../components/Loading';
import { toCurrency } from '../../services/format';
import Logger from '../../services/logger';
import ModelService from '../../services/model';
import { savings } from './SavingsData';
import { SquareInfoIcon } from '../../assets/images';

import model from '../../services/model';

import {
  fetchSpotChecklistClusterSizing,
  defaultSpotAllowSharedCore,
  defaultSpotMinNodeCount,
  defaultSpotMinOnDemandNodeCount,
  defaultSpotWindow,
  defaultSpotTargetUtilization,
} from '../../services/savings/spotclustersizing';

import {
  getPvSavings,
  getReservedRecSavings,
  getSavingsSummary,
  getUnassignedAddressSavings,
  getUnassignedDiskSavings,
  getUnutilizedLocalDiskSavings,
} from '../../services/savings';
import { SavingsCard } from './SavingsCard';
import usePersistentVolumeSizing from '../../hooks/usePersistentVolumeSizing';
import { defaultHeadroom } from '../PersistentVolumeSizing/PersistentVolumeSizing';
import { RequestSizingResponse } from '../RequestSizingNew/types';
import { sumNonNegativeSavings } from '../RequestSizingNew/utils';

export const Savings: FC = () => {
  const { pvTotalSavings } = usePersistentVolumeSizing(defaultHeadroom);
  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState<boolean>();
  const [cloudIntegration, setCloudIntegration] = useState<boolean>(true);
  const [totalSavings, setTotalSavings] = useState<number>(0);
  const [currency, setCurrency] = useState('');
  const [savingsData, setSavingsData] = useState(savings);

  useEffect(() => {
    loadBasicData();
  }, []);

  useEffect(() => {
    if (!currency) return;

    loadSavingsData();
  }, [currency]);

  useEffect(() => {
    // react-router doesn't guarentee a page unmounts so our state might already be set.
    if (!pvTotalSavings || savingsData.persistentVolumeSizing.savings !== 0)
      return;

    setSavingsData((prevState) => {
      prevState.persistentVolumeSizing.savings = pvTotalSavings;
      prevState.persistentVolumeSizing.loading = false;
      return { ...prevState };
    });
  }, [savingsData.persistentVolumeSizing.savings, pvTotalSavings]);

  const getTooltipForSavingsCardIcon = (
    SavingsIcon: any,
    tootipText: string,
  ) => (
    <Tooltip
      position="TOP"
      header={
        <span style={{ fontSize: '15px', fontWeight: 400 }}>{tootipText}</span>
      }
    >
      <div style={{ marginLeft: '1em' }}>
        <SavingsIcon />
      </div>
    </Tooltip>
  );

  if (loading === true) {
    return (
      <>
        <Header refreshCallback={loadBasicData} title="Savings" />
        <Loading message="Loading savings..." />
      </>
    );
  }

  if (loadingError === true) {
    return (
      <>
        <Header refreshCallback={loadBasicData} title="Savings" />
        <div style={{ textAlign: 'center', display: 'block' }}>
          Error: failed to connect to cluster.
        </div>
      </>
    );
  }

  return (
    <>
      <Header refreshCallback={loadBasicData} title="Savings" />
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          gap: '1em',
          paddingBottom: '1em',
        }}
      >
        <Card
          style={{ backgroundColor: 'rgb(234, 249, 240, 0.8)', padding: '1em' }}
        >
          <div>
            <Typography variant="h5" className={'flex items-center font-bold'}>
              Estimated monthly savings available
              <Tooltip
                className="font-normal w-52"
                content={
                  'Estimated savings are probability adjusted and include both Kubernetes and external cloud insights.'
                }
                position="TOP"
              >
                <SquareInfoIcon className={'ml-3 text-kc-gray-200'} />
              </Tooltip>
            </Typography>
          </div>
          <Typography variant="h2" style={{ color: '#31C46C' }}>
            {toCurrency(totalSavings + pvTotalSavings * 0.65, currency)}
          </Typography>
        </Card>
      </div>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '1em',
        }}
      >
        {Object.values(savingsData)
          .sort((a, b) => b.savings - a.savings)
          .map((category) => (
            <SavingsCard
              key={category.title}
              title={category.title}
              description={category.description}
              url={category.url}
              cardLoading={category.loading}
              icon={getTooltipForSavingsCardIcon(
                category.icon,
                category.tooltipText,
              )}
              isBeta={category.isBeta}
              isBetaMessage={category.isBetaMessage}
              type={category.type}
              savings={category.savings}
              currency={currency}
            />
          ))}
      </div>
    </>
  );

  async function loadBasicData() {
    setLoading(true);
    try {
      const configs = await ModelService.getConfigs();
      setCurrency(ModelService.getModelCurrency(configs));
    } catch (err) {
      setLoadingError(true);
      setLoading(false);
      Logger.error(err);
    }
    try {
      const etlStatus = await ModelService.etlStatus();
      const cloud = get(etlStatus, 'cloud');
      if (cloud) {
        Object.keys(cloud).forEach((providerKey) => {
          if (
            cloud[providerKey].cloudConnectionStatus === 'Connection Successful'
          ) {
            setCloudIntegration(true);
          } else {
            setCloudIntegration(false);
          }
        });
      }
    } catch (err) {
      Logger.error(err);
    }

    setLoading(false);
  }

  async function loadSavingsData() {
    // Use the SavingsSummary to retrieve abandonedWorkloads, requestSizing, and clusterSizing
    // Since we pick the best (max) savings between clusterSizing and turndownSavings to include
    // in the total savings calculation, we also calculate turndown savings in this group
    getSavingsSummary().then((savingsSummary) => {
      setSavingsData((prevState) => {
        prevState.abandonedWorkloads.savings =
          savingsSummary.abandonedWorkloads;
        prevState.abandonedWorkloads.loading = false;

        prevState.clusterSizing.savings = savingsSummary.clusterSizing;
        prevState.clusterSizing.loading = false;

        prevState.underutilizedNodes.savings = savingsSummary.nodeTurndown;
        prevState.underutilizedNodes.loading = false;
        return { ...prevState };
      });

      let clusterSizing =
        savingsSummary.clusterSizing === undefined
          ? 0
          : savingsSummary.clusterSizing;
      let nodeTurndown =
        savingsSummary.nodeTurndown === undefined
          ? 0
          : savingsSummary.nodeTurndown;

      const nodeSavings = Math.max(clusterSizing, nodeTurndown);
      const savings =
        savingsSummary.abandonedWorkloads +
        savingsSummary.requestSizing +
        nodeSavings;
      setTotalSavings((prev) => prev + 0.65 * savings);
    });

    getPvSavings().then((savings) => {
      setSavingsData((prevState) => {
        prevState.unclaimedVolumes.savings = savings;
        prevState.unclaimedVolumes.loading = false;
        return { ...prevState };
      });
      setTotalSavings((prev) => prev + 0.65 * savings);
    });

    getReservedRecSavings().then((savings) => {
      setSavingsData((prevState) => {
        prevState.reservedInstances.savings = savings;
        prevState.reservedInstances.loading = false;
        return { ...prevState };
      });
      setTotalSavings((prev) => prev + 0.65 * savings);
    });

    Promise.all([
      getUnassignedAddressSavings(),
      getUnassignedDiskSavings(),
    ]).then(([addrSavings, diskSavings]) => {
      setSavingsData((prevState) => {
        prevState.unassignedResources.savings = addrSavings + diskSavings;
        prevState.unassignedResources.loading = false;
        return { ...prevState };
      });
      setTotalSavings((prev) => prev + 0.65 * (addrSavings + diskSavings));
    });

    getUnutilizedLocalDiskSavings().then((savings) => {
      setSavingsData((prevState) => {
        prevState.localDisks.savings = savings;
        prevState.localDisks.loading = false;
        return { ...prevState };
      });
      setTotalSavings((prev) => prev + 0.65 * savings);
    });

    fetchSpotChecklistClusterSizing(
      defaultSpotMinOnDemandNodeCount,
      defaultSpotMinNodeCount,
      defaultSpotTargetUtilization,
      defaultSpotAllowSharedCore,
      defaultSpotWindow,
    ).then((sizing) => {
      setSavingsData((prevState) => {
        //@ts-ignore
        prevState.spotSizing.savings = sizing.monthlySavings;
        prevState.spotSizing.loading = false;
        return { ...prevState };
      });
      // Don't include these savings as part of total savings, because spot savings
      // is redundant with cluster-sizing savings, and including both vastly overstates
      // the total available savings.
    });

    model
      .get<RequestSizingResponse>(`/savings/requestSizingV2`, {
        window: '2d',
        algorithmCPU: 'max',
        algorithmRAM: 'max',
        qCPU: 0.95,
        qRAM: 0.95,
        targetUtilizationCPU: 0.8,
        targetUtilizationRAM: 0.8,
      })
      .then((recs) => {
        const requestSizingSavings = sumNonNegativeSavings(recs);
        setSavingsData((prevState) => {
          prevState.requestSizing.savings = requestSizingSavings;
          prevState.requestSizing.loading = false;
          return { ...prevState };
        });
        setTotalSavings((prev) => prev + 0.65 * requestSizingSavings);
      });
  }
};

Savings.displayName = 'Savings';
