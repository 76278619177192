import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

function SaveControl({ report, save, title, unsave }) {
  const [open, setOpen] = useState(false);
  const [localTitle, setLocalTitle] = useState('');

  useEffect(() => {
    if (report && report.title) {
      setLocalTitle(report.title);
    } else if (title) {
      setLocalTitle(title);
    }
  }, [report, title]);

  return (
    <>
      {/* Save button */}
      <Tooltip title={report ? 'Unsave report' : 'Save report'}>
        <IconButton onClick={() => setOpen(true)}>
          {report ? <BookmarkIcon /> : <BookmarkBorderIcon />}
        </IconButton>
      </Tooltip>

      {/* Modal */}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>{report ? 'Unsave report' : 'Save report'}</DialogTitle>
        <DialogContent>
          {report ? (
            <DialogContentText>{`Are you sure you want to unsave ${localTitle}? This action affects all users.`}</DialogContentText>
          ) : (
            <TextField
              fullWidth
              onChange={(e) => setLocalTitle(e.target.value)}
              value={localTitle}
            />
          )}
        </DialogContent>
        <DialogActions>
          {report ? (
            <Button onClick={unsaveHandler}>Unsave</Button>
          ) : (
            <Button onClick={saveHandler}>Save</Button>
          )}
          <Button onClick={() => setOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );

  function saveHandler() {
    save(localTitle);
    setOpen(false);
  }

  function unsaveHandler() {
    unsave(report);
    setOpen(false);
  }
}

SaveControl.defaultProps = {
  report: null,
  save: () => {},
  title: '',
  unsave: () => {},
};

SaveControl.propTypes = {
  report: PropTypes.shape({ title: PropTypes.string }),
  title: PropTypes.string,
  save: PropTypes.func,
  unsave: PropTypes.func,
};

export default React.memo(SaveControl);
