import { ColoredCloudIcon, ColoredKubernetesIcon } from './Icons';

export const savings = {
  clusterSizing: {
    title: 'Right-size your cluster nodes',
    description:
      "Adjust the number and size of your cluster's nodes to stop over-spending on unused capacity.",
    url: '../cluster-sizing',
    savings: 0,
    loading: true,
    icon: ColoredCloudIcon,
    isBeta: false,
    isBetaMessage: '',
    type: 'cloud',
    tooltipText: 'This is a cloud insight outside of your cluster(s)',
  },
  requestSizing: {
    title: 'Right-size your container requests',
    description:
      'Over-provisioned containers provide an opportunity to lower requests and save money. Under-provisioned containers may cause CPU throttling or memory-based evictions.',
    url: '../request-sizing',
    savings: 0,
    loading: true,
    icon: ColoredKubernetesIcon,
    isBeta: true,
    isBetaMessage:
      'This calculation is being handled by a beta API and should be used with caution!',
    type: 'kube',
    tooltipText: 'This insight applies to Kubernetes.',
  },
  abandonedWorkloads: {
    title: 'Remedy abandoned workloads',
    description:
      "Scale down, delete or resize pods that don't send or receive a meaningful rate of network traffic.",
    url: '../abandoned-workloads',
    savings: 0,
    loading: true,
    icon: ColoredKubernetesIcon,
    isBeta: false,
    isBetaMessage: '',
    type: 'kube',
    tooltipText: 'This insight applies to Kubernetes.',
  },
  reservedInstances: {
    title: 'Reserve instances',
    description:
      'Consider purchasing reserved instances based on historical resource usage patterns.',
    url: '../reserved-instances',
    savings: 0,
    loading: true,
    icon: ColoredCloudIcon,
    isBeta: false,
    isBetaMessage: '',
    type: 'cloud',
    tooltipText: 'This is a cloud insight outside of your cluster(s)',
  },
  localDisks: {
    title: 'Resize local disks',
    description:
      'Resize local disks with low utilization, and see whether you may launch new nodes with smaller disks on the next node turndown.',
    url: '../low-disk',
    savings: 0,
    loading: true,
    icon: ColoredKubernetesIcon,
    isBeta: false,
    isBetaMessage: '',
    type: 'kube',
    tooltipText: 'This insight applies to Kubernetes.',
  },
  underutilizedNodes: {
    title: 'Manage underutilized nodes',
    description:
      'Turn down or resize nodes with low memory and CPU utilization.',
    url: '../nodes',
    savings: 0,
    loading: true,
    icon: ColoredKubernetesIcon,
    isBeta: false,
    isBetaMessage: '',
    type: 'kube',
    tooltipText: 'This insight applies to Kubernetes.',
  },
  unclaimedVolumes: {
    title: 'Manage unclaimed volumes',
    description:
      'Delete volumes that are unused by any pods or move them to a cheaper storage tier.',
    url: '../unclaimed-volumes',
    savings: 0,
    loading: true,
    icon: ColoredKubernetesIcon,
    isBeta: false,
    isBetaMessage: '',
    type: 'kube',
    tooltipText: 'This insight applies to Kubernetes.',
  },
  unassignedResources: {
    title: 'Delete unassigned resources',
    description:
      'Disks and IP addresses that are not being used by any clusters may continue to incur charges.',
    url: '../orphaned-resources',
    savings: 0,
    loading: true,
    icon: ColoredCloudIcon,
    isBeta: false,
    isBetaMessage: '',
    type: 'cloud',
    tooltipText: 'This is a cloud insight outside of your cluster(s)',
  },
  spotSizing: {
    title: 'Spot Instances',
    description:
      'Identify workloads ready for spot (preemptible) nodes and resize your cluster to realize the savings of migrating workloads to spot.',
    url: '../spot',
    savings: 0,
    loading: true,
    icon: ColoredCloudIcon,
    isBeta: false,
    isBetaMessage: '',
    type: 'cloud',
    tooltipText: 'This is a cloud insight outside of your cluster(s)',
  },
  persistentVolumeSizing: {
    title: 'Right-size your persistent volumes',
    description:
      'Adjust the size of your persistent volumes to stop over-spending on unused capacity.',
    url: '../persistent-volume-sizing',
    savings: 0,
    loading: true,
    icon: ColoredKubernetesIcon,
    isBeta: true,
    isBetaMessage:
      'This calculation is being handled by a beta API and should be used with caution!',
    type: 'kube',
    tooltipText: 'This insight applies to Kubernetes.',
  },
};
