// react
import React, { FC } from 'react';

// recharts
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

// local
import { accents, browns } from '../../../services/colors';
import { toCurrency } from '../../../services/format';

function toPieData(top, other) {
  const slices: Array<{ name: string; value: number; fill: string }> = [];

  top.forEach((asset, i) => {
    const fill =
      asset.name === '__unallocated__'
        ? '#212121'
        : accents[i % accents.length];
    slices.push({
      name: asset.name,
      value: asset.totalCost,
      fill,
    });
  });

  other.forEach((asset, i) => {
    const fill = browns[i % browns.length];
    slices.push({
      name: asset.name,
      value: asset.totalCost,
      fill,
    });
  });

  return slices;
}

interface SummaryChartProps {
  top: string | number;
  other: any;
  currency: string;
  height: string | number;
}

export const SummaryChart: FC<SummaryChartProps> = ({
  top,
  other,
  currency,
  height,
}) => {
  const pieData = toPieData(top, other);

  const renderLabel = (params) => {
    const { cx, cy, midAngle, outerRadius, percent, name, fill, value } =
      params;

    const RADIAN = Math.PI / 180;
    const radius = outerRadius * 1.1;
    let x = cx + radius * Math.cos(-midAngle * RADIAN);
    x += x > cx ? 2 : -2;
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    // y -= Math.min(Math.abs(2 / Math.cos(-midAngle * RADIAN)), 8)

    if (percent < 0.02) {
      return undefined;
    }

    return (
      <text
        x={x}
        y={y}
        fill={fill}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${name}: ${toCurrency(value, currency)} (${(percent * 100).toFixed(
          1,
        )}%)`}
      </text>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={height}>
      <PieChart>
        <Pie
          data={pieData}
          dataKey="value"
          nameKey="name"
          label={renderLabel}
          labelLine
          // niko: if tooltips error, try disabling animation
          // isAnimationActive={false}
          animationDuration={400}
          cy="90%"
          outerRadius="140%"
          innerRadius="60%"
          startAngle={180}
          endAngle={0}
        >
          {pieData.map((datum, i) => (
            <Cell key={i} fill={datum.fill} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

SummaryChart.displayName = 'SummaryChart';
