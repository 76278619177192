import { CircularProgress } from '@material-ui/core';
import React from 'react';

const QbCostLoadingStateGreen = '#179c46';

const ColoredLoadingScreenText = ({ text }: { text: string }) => (
  <span style={{ color: QbCostLoadingStateGreen }}>{text}</span>
);

export const LoadingScreen = () => (
  <div
    style={{
      height: '50vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <div style={{ textAlign: 'center' }}>
      <CircularProgress size={26} />
      <div style={{ fontSize: 24, marginTop: '2em' }}>
        Asking all the <ColoredLoadingScreenText text="kubes" /> to report their{' '}
        <ColoredLoadingScreenText text="costs" />.
      </div>
      <div style={{ fontSize: 24, marginTop: '.5em' }}>brace yourself...</div>
    </div>
  </div>
);
