import { LinearProgress, withStyles, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import { designSystemColorMap } from '../../../services/colors';

export const BorderLinearProgress2 = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor:
        designSystemColorMap.offWhiteDarker,
    },
    bar: {
      borderRadius: 5,
      backgroundColor: designSystemColorMap.primary,
    },
  }),
)(LinearProgress);
