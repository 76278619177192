// react
import React, { FC, useState } from 'react';

// mui
import Popover from '@material-ui/core/Popover';

// holster
import {
  Button,
  FormControlLabel,
  Input,
  Typography,
} from '@kubecost-frontend/holster';

interface CloudBreakdownSelectorProps {
  aggregateBy: string;
  setAggregateBy: (agg: string) => void;
  joinLabel: string;
  setJoinLabel: (breakdown: string) => void;
  allocationAgg: string;
}

// currently only service, provider, and account are supported
const aggOptions = [
  { display: 'Service', value: 'service' },
  { display: 'Provider', value: 'provider' },
  { display: 'Account', value: 'account' },
  // { display: 'Category', value: 'category' },
  // { display: 'Cluster', value: 'cluster' },
  // { display: 'Type', value: 'type' },
  // { display: 'Provider ID', value: 'providerid' },
];

const CloudBreakdownSelector: FC<CloudBreakdownSelectorProps> = ({
  aggregateBy,
  setAggregateBy,
  joinLabel,
  setJoinLabel,
  allocationAgg
}) => {
  const [anchorEl, setAnchorEl] = useState<EventTarget & Element>();
  const [labelValue, setLabelValue] = useState(joinLabel);

  const col1Options = aggOptions.slice(0, Math.floor(aggOptions.length / 2));
  const col2Options = aggOptions.slice(Math.floor(aggOptions.length / 2));
  const open = Boolean(anchorEl);

  const handleSelectAggOption = (agg: string) => {
    setAggregateBy(agg);
    setAnchorEl(undefined);
  };

  return (
    <>
      <Button
        variant="default"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        className="mr-3"
      >
        Cloud Breakdown
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(undefined);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          className: 'p-6',
        }}
      >
        <div className="grid" style={{ gridTemplateColumns: '1fr 1fr' }}>
          <div className="flex flex-col">
            {col2Options.map((opt) => (
              <Typography
                variant="p"
                className={`p-2 hover:bg-kc-success-light rounded cursor-pointer ${
                  aggregateBy == opt.value ? 'text-kc-link' : ''
                }`}
                onClick={() => handleSelectAggOption(opt.value)}
              >
                {opt.display}
              </Typography>
            ))}
          </div>
          <div className="flex flex-col">
            {col1Options.map((opt) => (
              <Typography
                variant="p"
                className={`mr-6 p-2 hover:bg-kc-success-light rounded cursor-pointer ${
                  aggregateBy === opt.value ? 'text-kc-link' : ''
                }`}
                onClick={() => handleSelectAggOption(opt.value)}
              >
                {opt.display}
              </Typography>
            ))}
          </div>
        </div>
        <section className="my-3">
          <FormControlLabel>Custom Data Mapping</FormControlLabel>
          <Typography variant="h6">
            Override default label mappings for Agg/Asset aggregations
          </Typography>
          <Input
            className="mt-2 w-full"
            helperText={allocationAgg.toUpperCase()}
            onChange={(e) => setLabelValue(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setJoinLabel(labelValue);
              }
            }}
            defaultValue={joinLabel}
          />
        </section>
      </Popover>
    </>
  );
};

export default CloudBreakdownSelector;
