import { Button, Dialog, DialogContent, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AssessmentIcon from '@material-ui/icons/Assessment';
import React from 'react';
import { colorMap } from '../../services/colors';

interface CreateNewReportModalProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  createNewReportFromTemplate: (templateName: string) => void;
}

interface TemplateCardProps {
  title: string;
  Icon: any;
  dashed: boolean;
  createNewReport: (templateName: string) => void;
  description: string;
}

const TemplateCard = ({
  title,
  Icon,
  createNewReport,
  dashed,
  description,
}: TemplateCardProps) => (
  <div
    style={{
      width: 600,
      display: 'grid',
      gridTemplateColumns: '3em 1fr',
      padding: '2em',
      cursor: 'pointer',
      border: dashed
        ? '2px dashed rgba(0,0,0,0.3)'
        : '1px solid rgba(0,0,0,0.3)',
      borderRadius: '0.5em',
    }}
    onClick={() => createNewReport(title)}
  >
    <Icon style={{ fontSize: 40, color: colorMap.blue }} />
    <Typography variant="h4" style={{ color: colorMap.blue }}>
      {title}
    </Typography>
    <div></div>
    <div>
      <Typography variant="body2">{description}</Typography>
    </div>
  </div>
);

export const CreateNewReportModal = ({
  isOpen,
  setIsOpen,
  createNewReportFromTemplate,
}: CreateNewReportModalProps) => {
  return (
    <Dialog onClose={setIsOpen} open={isOpen} maxWidth="lg" fullWidth={true}>
      <DialogContent>
        <div
          style={{
            display: 'grid',
            gap: '3em',
            gridTemplateColumns: '1fr 1fr',
            padding: '2em',
          }}
        >
          <div
            style={{
              display: 'grid',
              gridTemplateRows: '1fr 40px',
            }}
          >
            <div>
              <Typography gutterBottom variant="h4">
                New Report
              </Typography>
              <Typography variant="body2">
                Not sure where to get started? Try one of our example templates
                to hit the ground running. Or, choose a blank report for a fresh
                start.
              </Typography>
            </div>
            <Button
              onClick={() => setIsOpen(false)}
              color="primary"
              variant="contained"
            >
              Cancel
            </Button>
          </div>

          <div
            style={{
              display: 'grid',
              gap: '2em',
            }}
          >
            <TemplateCard
              createNewReport={createNewReportFromTemplate}
              title="K8s Allocation Report + Assets by Service"
              dashed={false}
              Icon={AssessmentIcon}
              description="Basic breakdown that shows you the costs incurred by kuberentes namespace, and joined together with assets. "
            />
            <TemplateCard
              createNewReport={createNewReportFromTemplate}
              title="Chargeback Report"
              dashed={false}
              Icon={AssessmentIcon}
              description="An example that filters to a specific namespace and shared asset cost to submit for internal chargeback."
            />
            <TemplateCard
              createNewReport={createNewReportFromTemplate}
              title="Blank"
              description="Starting fresh with Allocations and Assets for all resources."
              dashed
              Icon={AddIcon}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
