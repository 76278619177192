import React from 'react';
import { IconProps } from './types';

const BellIcon = ({ ...args }: IconProps) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...args}
  >
    <path
      d="M14.7495 24.5C15.4125 24.5 16.0484 24.2366 16.5172 23.7678C16.986 23.299 17.2494 22.6631 17.2494 22.0001H12.2497C12.2497 22.6631 12.513 23.299 12.9819 23.7678C13.4507 24.2366 14.0865 24.5 14.7495 24.5ZM14.7495 6.89846L13.7533 7.0997C12.6233 7.32994 11.6076 7.94353 10.878 8.83663C10.1485 9.72973 9.74992 10.8475 9.74979 12.0007C9.74979 12.7856 9.5823 14.7468 9.17608 16.6779C8.97609 17.6366 8.7061 18.6353 8.34737 19.5003H21.1517C20.7929 18.6353 20.5242 17.6379 20.323 16.6779C19.9167 14.7468 19.7492 12.7856 19.7492 12.0007C19.7488 10.8477 19.3501 9.73024 18.6206 8.8374C17.8911 7.94456 16.8755 7.33115 15.7457 7.10095L14.7495 6.89721V6.89846ZM22.5241 19.5003C22.8028 20.059 23.1253 20.5015 23.499 20.7502H6C6.37373 20.5015 6.69621 20.059 6.97495 19.5003C8.09988 17.2504 8.49986 13.1006 8.49986 12.0007C8.49986 8.97585 10.6497 6.45099 13.5058 5.87477C13.4884 5.70097 13.5076 5.52545 13.5621 5.35951C13.6167 5.19358 13.7054 5.04093 13.8226 4.91139C13.9398 4.78186 14.0828 4.67833 14.2425 4.60747C14.4021 4.53661 14.5748 4.5 14.7495 4.5C14.9242 4.5 15.0969 4.53661 15.2566 4.60747C15.4162 4.67833 15.5593 4.78186 15.6764 4.91139C15.7936 5.04093 15.8824 5.19358 15.9369 5.35951C15.9915 5.52545 16.0106 5.70097 15.9932 5.87477C17.406 6.16213 18.676 6.92891 19.5883 8.04528C20.5006 9.16164 20.999 10.559 20.9992 12.0007C20.9992 13.1006 21.3992 17.2504 22.5241 19.5003Z"
      fill="#222325"
    />
  </svg>
);

export default BellIcon;
