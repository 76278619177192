// react
import React, { FC, useEffect, useRef, useState } from 'react';

// react-router
import { useLocation, useNavigate } from 'react-router-dom';

// mui
import { Drawer, Snackbar } from '@material-ui/core';

// lodash
import trim from 'lodash/trim';

// holster
import {
  Alert as HolsterAlert,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from '@kubecost-frontend/holster';

// local
import AggregateByControl from '../../components/AggregateByControlAllocation';
import { DateSelectorNew } from '../../components/DateSelectorNew';
import { Header } from '../../components/Header2New';
import { useClusters } from '../../contexts/ClusterConfig';
import { toCurrency } from '../../services/format';
import model, {
  ExternalAllocationResponse,
  ExternalAllocationResponseItem,
} from '../../services/model';
import { EditControl } from '../AllocationNew/EditControl';
import { EditReportDialog } from './EditReportDialog';
import useAllocationConfig from '../AllocationNew/hooks/useAllocationConfig';
import { SaveControl } from '../AllocationNew/SaveControl';
import CloudBreakdownSelector from './CloudBreakdownSelector';
import { ARChart } from './ARChart';
import { monitorWidthResize } from '../../hooks/monitorWidthResize';
import { SaveReportDialog } from '../AllocationNew/SaveReportDialog';
import { UnsaveReportDialog } from '../AllocationNew/UnsaveReportDialog';
import Alert from '@material-ui/lab/Alert';
import {
  deleteAdvancedReport,
  saveAdvancedReport,
} from '../../services/reports';
import { labelAssetsMap } from '../../pages/Inspect/inspectHelpers';
import { NewARDataTable } from './NewARDataTable';
import { AdvancedReport as Report } from '../../types/advancedReport';

// control options
const windowOptions = [
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Week-to-date', value: 'week' },
  { label: 'Month-to-date', value: 'month' },
  { label: 'Last week', value: 'lastweek' },
  { label: 'Last month', value: 'lastmonth' },
  { label: 'Last 24h', value: '24h' },
  { label: 'Last 48h', value: '48h' },
  { label: 'Last 7 days', value: '7d' },
  { label: 'Last 30 days', value: '30d' },
  { label: 'Last 60 days', value: '60d' },
  { label: 'Last 90 days', value: '90d' },
];

const reportDefaults: Report = {
  window: '7d',
  aggregateBy: 'namespace',
  cloudBreakdown: 'service',
  cloudJoin: 'label:kubernetes_namespace',
  title: 'Breakdown by service join on label:kubernetes_namespace',
  filters: [],
  sharedLabels: [],
  sharedNamespaces: [],
  sharedOverhead: 0,
};

export const NewAdvancedReporting: FC = () => {
  const routerLocation = useLocation();
  const searchParams = new URLSearchParams(routerLocation.search);
  const navigate = useNavigate();
  const { modelConfig } = useClusters();
  const defaultConfig = useAllocationConfig();
  const [tableData, setTableData] =
    useState<ExternalAllocationResponse | null>(null);
  const [activeRow, setActiveRow] =
    useState<ExternalAllocationResponseItem | null>(null); // responsible for drawer
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [unsaveDialogOpen, setUnsaveDialogOpen] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    message?: string;
    severity?: 'success' | 'info' | 'warning' | 'error';
  }>({});

  const [editDialogAnchorEl, setEditDialogAnchorEl] =
    useState<EventTarget & HTMLButtonElement>();

  const [init, setInit] = useState(false);
  const [activeReport, setActiveReport] = useState<Report>(reportDefaults);
  const [dirty, setDirty] = useState(false);

  // ref and state for hook setting chart width
  const arChartRef = useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = useState(0);

  // automatically set chartWidth on window resize
  useEffect(() => {
    monitorWidthResize(arChartRef, setChartWidth);
  }, []);

  // watch for URL param changes and update the active report accordingly
  useEffect(() => {
    checkAndUpdateInternalStateFromURL();
    setInit(true);
  }, [routerLocation.search]);

  // when active report changes, fetch data
  useEffect(() => {
    if (init) {
      getAllocationData();
    }
  }, [activeReport, init]);

  const saveReport = async (report: any) => {
    try {
      const savedReport = await saveAdvancedReport(report);
      setSnackbar({
        message: 'Report saved',
        severity: 'success',
      });
      searchParams.set('id', savedReport.id);
      setDirty(false);
      navigate({ search: `?${searchParams.toString()}` });
    } catch (err) {
      setSnackbar({
        message: 'Failed to save report',
        severity: 'error',
      });
    }
  };

  const unsaveReport = async () => {
    try {
      await deleteAdvancedReport(activeReport.id);
      searchParams.delete('id');
      setSnackbar({
        message: 'Report unsaved',
        severity: 'success',
      });
      navigate({ search: `?${searchParams.toString()}` });
    } catch (err) {
      setSnackbar({
        message: 'Failed to unsave report',
        severity: 'error',
      });
    }
  };

  const checkAndUpdateInternalStateFromURL = () => {
    // id
    const id = searchParams.get('id') || undefined;

    // cloudBreakdown
    const cloudBreakdown =
      searchParams.get('breakdown') || reportDefaults.cloudBreakdown;

    // cloudJoinLabel
    const cloudJoin =
      searchParams.get('cloudJoinLabel') || reportDefaults.cloudJoin;

    // filters
    const urlFilter = searchParams.get('filters') || '';
    let filters: Array<{ property: string; value: string }> = [
      ...reportDefaults.filters,
    ];
    try {
      filters = JSON.parse(atob(urlFilter)) || [...reportDefaults.filters];
    } catch (err) {
      filters = [...reportDefaults.filters];
    }

    // shared namespaces
    let sns = searchParams.get('sharedNamespaces');
    let sharedNamespaces: string[] = [...reportDefaults.sharedNamespaces];
    if (typeof sns === 'string') {
      sharedNamespaces = sns
        .split(',')
        .map((s) => trim(s))
        .filter((s) => !!s);
    }

    // shared overhead
    let soh = searchParams.get('sharedOverhead');
    let sharedOverhead: number = reportDefaults.sharedOverhead;
    if (soh) {
      sharedOverhead = parseFloat(soh);
    }

    // shared labels
    let sls = searchParams.get('sharedLabels');
    let sharedLabels: string[] = [...reportDefaults.sharedLabels];
    if (typeof sls === 'string') {
      sharedLabels = sls
        .split(',')
        .map((s) => trim(s))
        .filter((s) => !!s);
    }

    const window = searchParams.get('window') || reportDefaults.window;

    const aggregateBy = searchParams.get('agg') || reportDefaults.aggregateBy;

    const title =
      searchParams.get('title') ||
      `Breakdown by ${cloudBreakdown} join on ${cloudJoin}`;

    setActiveReport({
      id,
      window,
      aggregateBy,
      title,
      cloudBreakdown,
      cloudJoin,
      filters,
      sharedNamespaces,
      sharedLabels,
      sharedOverhead,
    });
  };

  const getAllocationData = async () => {
    const { window, aggregateBy, filters, cloudBreakdown, cloudJoin } =
      activeReport;
    const resp = await model.getAllocationExternal(
      window,
      aggregateBy,
      {
        filters,
        accumulate: true,
        cloudBreakdown,
        cloudJoin,
      },
      {},
    );
    if (resp.code === 200) {
      let items = resp.data.items;
      let totals = resp.data.totals;
      const undef = resp.data.items.find(
        (item) => item.name === '__undefined__',
      );
      if (undef) {
        items = items.filter((item) => item.name !== '__undefined__');
        totals.totalCost -= undef.totalCost;
        totals.externalCost -= undef.externalCost;
      }
      setTableData({ items, totals });
    }
  };

  return (
    <div>
      <SaveReportDialog
        onClose={() => setSaveDialogOpen(false)}
        open={saveDialogOpen}
        report={activeReport}
        save={saveReport}
        title={'New Advanced Report'}
      />
      <UnsaveReportDialog
        onClose={() => setUnsaveDialogOpen(false)}
        open={unsaveDialogOpen}
        report={activeReport}
        title={'New Advanced Report'}
        unsave={unsaveReport}
      />
      <Drawer
        anchor="right"
        open={!!activeRow}
        onClose={() => setActiveRow(null)}
      >
        <div style={{ padding: '2em' }}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: '.5em',
              marginBottom: '2em',
            }}
          >
            <div style={{ textAlign: 'center' }}>
              <Typography variant="h5">
                {activeRow && toCurrency(activeRow.externalCost, 'USD')}
              </Typography>
              <Typography variant="h5">OOC</Typography>
            </div>
            <div style={{ textAlign: 'center' }}>
              <Typography variant="h5">
                {activeRow &&
                  toCurrency(
                    activeRow.cpuCost +
                      activeRow.gpuCost +
                      activeRow.ramCost +
                      activeRow.pvCost +
                      activeRow.loadBalancerCost +
                      activeRow.networkCost,
                    'USD',
                  )}
              </Typography>
              <Typography variant="h5">K8s</Typography>
            </div>
          </div>
          <Typography variant="h5">Cloud Breakdown</Typography>
          <div>
            <Table className="w-full">
              <TableHead>
                <TableRow>
                  <TableHeadCell>Name</TableHeadCell>
                  <TableHeadCell>Cost</TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activeRow &&
                  activeRow.externalBreakdown &&
                  activeRow.externalBreakdown.map((item: any) => (
                    <TableRow>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{toCurrency(item.cost, 'USD')}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </Drawer>
      <Header
        helpHref="https://docs.kubecost.com/cost-allocation"
        helpTooltip="Product Documentation"
        title="Advanced Reporting"
      />
      <div className="mb-4">
        <HolsterAlert
          content="Advanced Reports are a beta feature."
          title="Beta Feature"
          variant="warning"
        />
      </div>
      <Typography variant="p-large">{activeReport.title}</Typography>
      <div className="flex justify-between mt-6">
        <div className="flex justify-start">
          <div className="mr-3">
            <DateSelectorNew
              windowOptions={windowOptions}
              window={activeReport.window}
              setWindow={(win: string) => {
                searchParams.set('window', win);
                setDirty(true);
                navigate({
                  search: `?${searchParams.toString()}`,
                });
              }}
            />
          </div>
          <AggregateByControl
            aggregateBy={[activeReport.aggregateBy]}
            disallowMultiAgg={true}
            setAggregateBy={(aggBy) => {
              searchParams.set('agg', aggBy.toString());
              searchParams.set(
                'cloudJoinLabel',
                `label:${
                  labelAssetsMap[aggBy.toString()] ||
                  `label:${aggBy.toString()}`
                }`,
              );
              setDirty(true);
              navigate({ search: `?${searchParams.toString()}` });
            }}
          />
          <CloudBreakdownSelector
            aggregateBy={activeReport.cloudBreakdown}
            joinLabel={activeReport.cloudJoin}
            setJoinLabel={(joinLabel: string) => {
              searchParams.set('cloudJoinLabel', joinLabel);
              setDirty(true);
              navigate({ search: `?${searchParams.toString()}` });
            }}
            setAggregateBy={(breakdownBy) => {
              searchParams.set('breakdown', breakdownBy);
              setDirty(true);
              navigate({ search: `?${searchParams.toString()}` });
            }}
            allocationAgg={activeReport.aggregateBy}
          />
        </div>
        <div className="flex items-stretch">
          <SaveControl
            saved={Boolean(activeReport.id && !dirty)}
            setSaveDialogOpen={setSaveDialogOpen}
            setUnsaveDialogOpen={setUnsaveDialogOpen}
          />
          <EditControl
            onClick={(e) => setEditDialogAnchorEl(e.currentTarget)}
          />
          <EditReportDialog
            anchorEl={editDialogAnchorEl}
            defaultSharedNamespaces={defaultConfig.shareNamespaces || []}
            defaultSharedLabels={defaultConfig.shareLabelNames || []}
            defaultSharedOverhead={defaultConfig.sharedOverhead}
            shareTenancyCosts={defaultConfig.shareTenancyCosts}
            customSharedNamespaces={activeReport.sharedNamespaces}
            customSharedLabels={activeReport.sharedLabels}
            customSharedOverhead={activeReport.sharedOverhead}
            setCustomSharedOverhead={(o: string | null) => {
              if (o !== null) {
                searchParams.set('sharedOverhead', o);
              } else {
                searchParams.delete('sharedOverhead');
              }
              setDirty(true);
              navigate({
                search: `?${searchParams.toString()}`,
              });
            }}
            setCustomSharedNamespaces={(ns: string) => {
              if (ns !== null) {
                searchParams.set('sharedNamespaces', ns);
              } else {
                searchParams.delete('sharedNamespaces');
              }
              setDirty(true);
              navigate({
                search: `?${searchParams.toString()}`,
              });
            }}
            setCustomSharedLabels={(lbls: string) => {
              if (lbls !== null) {
                searchParams.set('sharedLabels', lbls);
              } else {
                searchParams.delete('sharedLabels');
              }
              setDirty(true);
              navigate({
                search: `?${searchParams.toString()}`,
              });
            }}
            onClose={() => setEditDialogAnchorEl(undefined)}
            setFilters={(fs: { property: string; value: string }[]) => {
              const fltr = btoa(JSON.stringify(fs));
              searchParams.set('filters', fltr);
              setDirty(true);
              navigate({
                search: `?${searchParams.toString()}`,
              });
            }}
            filters={activeReport.filters}
          />
        </div>
      </div>
      <div className="w-full mt-8 mb-8" ref={arChartRef}>
        {tableData && (
          <ARChart
            data={tableData.items ?? []}
            dataKey="totalCost"
            width={chartWidth}
          />
        )}
      </div>
      <div className={'overflow-x-auto'}>
        {tableData && (
          <NewARDataTable
            tableData={tableData}
            currency={modelConfig.currencyCode}
            setActiveRow={setActiveRow}
          />
        )}
      </div>
      <Snackbar
        open={!!snackbar.message}
        autoHideDuration={4000}
        onClose={() => setSnackbar({})}
      >
        <Alert
          onClose={() => setSnackbar({})}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

NewAdvancedReporting.displayName = 'NewAdvancedReporting';
