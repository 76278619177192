import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Grid,
  TableCell,
  // Typography,
} from '@material-ui/core';
import { Typography } from '@kubecost-frontend/holster';
import React, { FC } from 'react';
import { toCurrency } from '../../../services/format';
import { Controller, RequestSizingRec } from '../../../services/savings/index';
import { Allocation } from '../../../types/allocation';
import { BorderLinearProgress2 } from './BorderLinearProgress2';

interface ControllerListTileProps {
  savingsData: RequestSizingRec;
  controllerMap: Record<string, Allocation> | null;
  handleContextSwitch: (controllerName: string) => void;
  currency: string;
}

const efficiencyForController = (item: Allocation) => {
  let cpuEfficiency = 0;
  if (item.cpuCoreRequestAverage > 0) {
    cpuEfficiency = item.cpuCoreUsageAverage / item.cpuCoreRequestAverage;
  } else if (item.cpuCoreUsageAverage > 0) {
    cpuEfficiency = 1;
  }
  let ramEfficiency = 0;
  if (item.ramByteRequestAverage > 0) {
    ramEfficiency = item.ramByteUsageAverage / item.ramByteRequestAverage;
  } else if (item.ramByteUsageAverage > 0) {
    ramEfficiency = 1;
  }
  const totalEfficiency =
    (item.cpuCost * cpuEfficiency + item.ramCost * ramEfficiency) /
    (item.cpuCost + item.ramCost);
  return totalEfficiency;
};

const totalCostForItem = ({
  cpuCost,
  ramCost,
  networkCost,
  loadBalancerCost,
  externalCost,
  pvCost,
  sharedCost,
  gpuCost,
}: Allocation) => {
  return (
    cpuCost +
    ramCost +
    networkCost +
    loadBalancerCost +
    externalCost +
    pvCost +
    sharedCost +
    gpuCost
  );
};

export const ControllerListTile: FC<ControllerListTileProps> = ({
  savingsData,
  handleContextSwitch,
  currency,
  controllerMap,
}) => {
  if (
    controllerMap === null ||
    !savingsData ||
    savingsData.controllers.filter((contr: Controller) => contr.name !== '')
      .length < 1
  )
    return <div />;

  const controllerList = Object.entries(controllerMap)
    .map(([key, value]) => ({
      name: key,
      efficiency: efficiencyForController(value),
      totalCost: totalCostForItem(value),
    }))
    .filter((item: any) => item.name !== '__idle__');

  return (
    <Paper style={{ padding: '2em' }}>
      <Typography variant="h5">
        Controllers
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>NAME (CLUSTER/CONTROLLER)</TableCell>
            <TableCell align="right">EFFICIENCY SCORE</TableCell>
            <TableCell align="right">TOTAL COST</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {controllerList.map((row: any) => (
            <TableRow
              style={{ cursor: 'pointer' }}
              key={row.name}
              onClick={() => {
                handleContextSwitch(row.name);
              }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={5}>
                    {(row.efficiency * 100).toFixed(2)}%
                  </Grid>
                  <Grid item xs={7}>
                    <BorderLinearProgress2
                      variant="determinate"
                      value={row.efficiency * 100}
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell align="right">
                {toCurrency(row.totalCost || 0, currency)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

ControllerListTile.displayName = 'ControllerListTile';
