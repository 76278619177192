import React from 'react';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import reverse from 'lodash/reverse';
import round from 'lodash/round';
import sortBy from 'lodash/sortBy';
import ExportIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { getCost } from '../allocation';

const columns = [
  {
    head: 'Name',
    prop: 'name',
    currency: false,
  },
  {
    head: 'CPU',
    prop: 'cpuCost',
    currency: true,
  },
  {
    head: 'GPU',
    prop: 'gpuCost',
    currency: true,
  },
  {
    head: 'RAM',
    prop: 'ramCost',
    currency: true,
  },
  {
    head: 'PV',
    prop: 'pvCost',
    currency: true,
  },
  {
    head: 'Network',
    prop: 'networkCost',
    currency: true,
  },
  {
    head: 'LoadBalancer',
    prop: 'loadBalancerCost',
    currency: true,
  },
  {
    head: 'External',
    prop: 'externalCost',
    currency: true,
  },
  {
    head: 'Shared',
    prop: 'sharedCost',
    currency: true,
  },
  {
    head: 'Efficiency',
    prop: 'totalEfficiency',
    currency: false,
  },
  {
    head: 'Total',
    prop: 'totalCost',
    currency: true,
  },
];

const toCSVLine = (datum) => {
  const cols = [];

  forEach(columns, (c) => {
    if (c.currency) {
      cols.push(round(getCost(datum, c.prop), 2));
    } else {
      if (c.prop === 'name') {
        cols.push(`"${get(datum, c.prop, '')}"`);
      } else {
        cols.push(`${get(datum, c.prop, 0)}`);
      }
    }
  });

  return cols.join(',');
};

const DownloadControl = ({ cumulativeData, title }) => {
  // downloadReport downloads a CSV of the cumulative allocation data
  function downloadReport() {
    // Build CSV
    const head = columns.map((c) => c.head).join(',');
    const body = reverse(sortBy(cumulativeData, 'totalCost'))
      .map(toCSVLine)
      .join('\r\n');
    const csv = `${head}\r\n${body}`;

    // Create download link
    const a = document.createElement('a');
    a.href = URL.createObjectURL(new Blob([csv], { type: 'text/csv' }));
    const filename = title.toLowerCase().replace(/\s/gi, '-');
    a.setAttribute('download', `${filename}-${Date.now()}.csv`);

    // Click the link
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  return (
    <Tooltip title="Download CSV">
      <IconButton
        id="allocation-download-report-button"
        onClick={downloadReport}
      >
        <ExportIcon />
      </IconButton>
    </Tooltip>
  );
};

export default React.memo(DownloadControl);
