import mixpanel from 'mixpanel-browser';
import React, { ReactText } from 'react';
import { ProductTier } from '../hooks/useProductTier';
import ConfigService from './config';
import { version } from './env';
import logger from './logger';
import model from './model';
import { getCurrentContainerAddressModel } from './util';

class Analytics {
  initialized: Promise<void>;
  config: any;
  ready: (value: void | PromiseLike<void>) => void = () => {};
  superProps: {
    appVersion: string | undefined;
    host: string;
    kubecostToken?: string;
    product_key?: string;
    product_tier?: string;
  };

  constructor() {
    this.superProps = {
      host: window.location.host,
      appVersion: version,
    };
    this.initialized = new Promise((resolve) => {
      this.ready = resolve;
    });
  }

  async init() {
    // only capture analytics if enabled
    try {
      const clusterInfo = await model.clusterInfo();
      if (clusterInfo.productAnalytics !== 'true') {
        return;
      }
    } catch (err) {
      return;
    }
    // initialize mixpanel
    try {
      mixpanel.init('9bfb1dea1874f9ea59453846a9ccd7d3');
    } catch (err) {
      logger.warn('Failed to initialize mixpanel');
    }

    // read user product key, license tier
    try {
      const license = await ConfigService.fetchProductKey();
      if (license && license.productKey) {
        if (license.productKey.key) {
          this.superProps.product_key = license.productKey.key;
        }
        if (license.productKey.tier) {
          this.superProps.product_tier = license.productKey.tier;
        } else {
          this.superProps.product_tier = ProductTier.Free;
        }
      }
    } catch (err) {
      logger.warn('Failed to get product license.');
    }

    // read and save a reference to the kubecost token if possible
    try {
      const tokenResp = await fetch(
        `${getCurrentContainerAddressModel()}/getConfigs`,
      );
      const payload = await tokenResp.json();
      this.config = payload.data;
      this.superProps.kubecostToken = this.config.kubecostToken;
    } catch (err) {}

    // identify user by token if one is found
    if (
      this.superProps.kubecostToken &&
      this.superProps.kubecostToken !== 'not-applied'
    ) {
      try {
        mixpanel.identify(this.superProps.kubecostToken);
        mixpanel.people.set({
          installed_version: this.superProps.appVersion,
          product_tier: this.superProps.product_tier,
        });
      } catch (err) {}
    }

    mixpanel.register({
      ...this.superProps,
    });

    // initialize gtag
    try {
      initGtag(this.config.googleAnalyticsTag);
    } catch (err) {}

    this.ready();
  }

  async record(name: string, props?: Record<string, unknown>) {
    await this.initialized;
    try {
      mixpanel.track(name, props);
    } catch (err) {}
  }

  async setProfileValue(name: string, value: any) {
    await this.initialized;
    try {
      mixpanel.people.set(name, value);
    } catch (err) {}
  }

  async recordRequestResponse(
    url: string,
    response: Response,
    responseDurationMilliseconds: number,
    options?: RequestInit,
  ) {
    try {
      // Record the request with Analytics.
      var uri = new URL(url);
      const analyticsParams = {
        path: uri.pathname,
        // @ts-ignore
        ...Object.fromEntries(uri.searchParams),
        method: options?.method,
        responseDurationMilliseconds,
        status: response.status,
      };
      this.record('api_request', analyticsParams);
    } catch (err) {}
  }

  setProductKey(key: string) {
    this.superProps.product_key = key;
    mixpanel.alias(key);
  }

  setProductTier(tier: string) {
    this.superProps.product_tier = tier;
  }
}

function initGtag(customerGTag?: string) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(['js', new Date()]);
  window.dataLayer.push(['config', 'UA-129422090-1']);

  if (customerGTag) {
    window.dataLayer.push(['config', customerGTag]);
  }
}

const analytics = new Analytics();

export default analytics;

export const AnalyticsHOC = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  analytics.init();
  return children;
};
