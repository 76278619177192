import { Modal, Typography } from '@kubecost-frontend/holster';
import React, { useEffect } from 'react';

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

function getItemKey(item, key) {
  if (item.metric != undefined) return item.metric[key];
  else if (item.metadata != undefined) return item.metadata[key];
  else return null;
}
function setIssueDialogContent(result, key, type) {
  if (result.length == 0 && key != null) {
    $('.mdl-dialog__content').append(`<div><b>None currently found.</b></div>`);
  } else if (result.length > 0 && key != null) {
    $.each(result, function (i, item) {
      var name = getItemKey(item, key);
      var ns = '';

      if (type === 'deployment') {
        ns = ' (' + item.metadata.namespace + ')';
      }

      if (
        typeof item != 'undefined' &&
        typeof item.metric != 'undefined' &&
        typeof item.metric.namespace != 'undefined'
      ) {
        ns = ' (' + item.metric.namespace + ')';
      }

      var linkStr = '';
      var dbPath = '';
      if (type === 'pod') {
        dbPath = `/d/at-cost-analysis-pod/pod-cost-and-utilization-metrics?var-namespace=${getItemKey(
          item,
          'namespace',
        )}&var-pod=${name}&from=now-24h`;
      } else if (type === 'node') {
        dbPath =
          '/d/NUQW37Lmk/node-utilization-metrics?var-node=' +
          item.metadata.name;
        linkStr = `<a href="" target="_blank">Details</a>`;
        dbPath +
          '/d/deployment-metrics/deployment-statefulset-daemonset-utilization-metrics?&var-Deployment=' +
          item.metadata.name;
      }

      helper.getCurrentGrafanaAddress(function (url) {
        fetch(`${getCurrentContainerAddressModel()}/getApiConfig`)
          .then((resp) => resp.json())
          .then((payload) => {
            if (payload.grafanaEnabled || payload.grafanaURL) {
              url += dbPath;
              linkStr = `<a href="${url}" target="_blank">Details</a>`;
              $('.mdl-dialog__content').append(
                `<div><b>${name}</b> ${ns}${linkStr}</div>`,
              );
            } else {
              $('.mdl-dialog__content').append(
                `<div title="Grafana is disabled. Enable Grafana to access dashboard links here."><b>${name}</b> ${ns}</div>`,
              );
            }
          });
      });
    });
  }
}

const IssueDialog = ({ issue, open, onClose, error }: any) => {
  useEffect(() => {
    if (issue === null || !error) return;
    setIssueDialogContent(error.data, error.key, error.type);
  }, [issue]);

  return (
    <Modal open={open} onClose={onClose} title={issue && issue[2]}>
      <Typography variant="p">
        <span dangerouslySetInnerHTML={{ __html: issue && issue[4] }}></span>
      </Typography>

      <div className="mdl-dialog__content"></div>
    </Modal>
  );
};

export default IssueDialog;
