// react
import React, { FC, useCallback, useEffect, useState } from 'react';

// react-query
import { useQueryClient } from 'react-query';

// mui
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';

// holster
import {
  Alert,
  FormControlLabel,
  NativeSelect,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from '@kubecost-frontend/holster';

// local
import { ArrowIcon } from '../../assets/images';
import { Header } from '../../components/Header2New';
import { bytesToStringBase10, toCurrency } from '../../services/format';
import usePersistentVolumeSizing from '../../hooks/usePersistentVolumeSizing';
import { useClusters } from '../../contexts/ClusterConfig';
import Analytics from '../../services/analytics';
import { BetaBadge } from '../../components';

const options = [
  { label: 'Low (25%)', value: 0.25 },
  { label: 'Medium (50%)', value: 0.5 },
  { label: 'High (100%)', value: 1.0 },
];

const defaultHeadroom = options[0].value;

const PersistentVolumeSizing: FC = () => {
  const queryClient = useQueryClient();
  const [extraHeadroom, setExtraHeadroom] = useState<number>(defaultHeadroom);
  const { data, isLoading, error } = usePersistentVolumeSizing(extraHeadroom);
  const { modelConfig } = useClusters();

  // Sort by cost. If costs are equal, sort by name.
  const sortedData = data?.sort((a, b) => {
    const costDiff = b.savingsMonthly - a.savingsMonthly;
    if (costDiff) return costDiff;
    return a.volumeName > b.volumeName ? 1 : -1;
  });

  return (
    <>
      <Header
        refreshCallback={useCallback(
          () =>
            queryClient.invalidateQueries([
              'savings',
              'persistentVolumeSizing',
              { headroomPercent: extraHeadroom },
            ]),
          [extraHeadroom],
        )}
        title={
          <>
            <div className="flex items-center">
              Persistent Volume Right Sizing
              <Typography variant="h6" className="pl-2">
                <BetaBadge message="This calculation is being handled by a beta API and should be used with caution!" />
              </Typography>
            </div>
            <Typography variant="p" className="text-kc-gray-200 font-normal">
              <a href="savings">
                <ArrowIcon direction="UP" className="inline" />
                Back to savings
              </a>
            </Typography>
          </>
        }
      />
      <FormControlLabel>Desired headroom</FormControlLabel>
      <NativeSelect
        defaultValue={extraHeadroom}
        options={options}
        onChange={(event) => {
          Analytics.record('persistent-volume-sizing: overhead-change', {
            value: event.target.value,
          });
          setExtraHeadroom(parseFloat(event.target.value));
        }}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>Persistent Volume Name</TableHeadCell>
            <TableHeadCell>Cluster</TableHeadCell>
            <TableHeadCell>Max Usage</TableHeadCell>
            <TableHeadCell>Recommended Capacity</TableHeadCell>
            <TableHeadCell>Current Capacity</TableHeadCell>
            <TableHeadCell>Recommended Cost / mo</TableHeadCell>
            <TableHeadCell>Current Cost / mo</TableHeadCell>
            <TableHeadCell>Savings / mo</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading && sortedData ? (
            sortedData.map((row, index) => (
              <TableRow key={`${row.volumeName}-${index}`}>
                <TableCell>{row.volumeName}</TableCell>
                <TableCell>{row.clusterId}</TableCell>
                <TableCell>{bytesToStringBase10(row.maxUsageBytes)}</TableCell>
                <TableCell>
                  {bytesToStringBase10(row.recommendedCapacityBytes)}
                </TableCell>
                <TableCell>
                  {bytesToStringBase10(row.currentCapacityBytes)}
                </TableCell>
                <TableCell>
                  {toCurrency(
                    row.recommendedCostMonthly,
                    modelConfig.currencyCode,
                  )}
                </TableCell>
                <TableCell>
                  {toCurrency(row.currentCostMonthly, modelConfig.currencyCode)}
                </TableCell>
                <TableCell>
                  {toCurrency(row.savingsMonthly, modelConfig.currencyCode)}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <td className="p-4 text-center" colSpan={8}>
                <CircularProgress />
              </td>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={4000}
        open={!!error}
      >
        <Alert
          content="Something helpful a user can do!"
          title="Could not fetch persistent volume data"
          variant="danger"
        />
      </Snackbar>
    </>
  );
};

export { PersistentVolumeSizing as default, defaultHeadroom };
