import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Slider from '@material-ui/core/Slider';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import Header from '../../components/Header';
import Page from '../../components/Page';
import Loading from '../../components/Loading';
import { FetchStates } from '../../constants';
import logger from '../../services/logger';

import {
  SpotReady,
  fetchSpotChecklist,
  ChecklistResponse,
} from '../../services/savings/spot';
import {
  fetchSpotChecklistClusterSizing,
  ValidSizingResponse,
} from '../../services/savings/spotclustersizing';
import model from '../../services/model';
import SpotChecklistTable from './SpotChecklistTable';
import TableKey from './TableKey';
import SpotChecklistSizingCard from './SpotChecklistSizingCard';

const useStyles = makeStyles({
  description: {
    padding: '24px 36px',
    marginBottom: 20,
  },
  recommendations: {
    display: 'flex',
  },
  spotready: {
    display: 'flex',
  },
  heading: {
    flexBasis: '33.33%',
    flexShrink: 0,
  },
});

function SpotChecklistEstimatedPage(): React.ReactElement {
  const classes = useStyles();

  const [fetchState, setFetchState] = useState(FetchStates.INIT);
  const [fetchStateSizing, setFetchStateSizing] = useState(FetchStates.INIT);

  const [spotChecklist, setSpotChecklist] =
    useState<ChecklistResponse | undefined>(undefined);
  const [spotChecklistClusterSizing, setSpotChecklistClusterSizing] =
    useState<ValidSizingResponse | undefined>(undefined);
  const [currency, setCurrency] = useState<string | undefined>(undefined);

  const [window, setWindow] = useState<string>('1d');
  const [allowSharedCore, setAllowSharedCore] = useState<boolean>(true);
  const [sizingTargetUtilizationPct, setSizingTargetUtilizationPct] =
    useState<number>(90);
  const [
    sizingTargetUtilizationPctSlider,
    setSizingTargetUtilizationPctSlider,
  ] = useState<number>(90);
  const [minOnDemandNodeCount, setMinOnDemandNodeCount] = useState<number>(2);
  const [minSpotNodeCount, setMinSpotNodeCount] = useState<number>(3);

  const [tabsValue, setTabsValue] = useState(0);

  async function initialize() {
    try {
      const spotCLPromise = fetchSpotChecklist();
      const spotCL = await spotCLPromise;

      setSpotChecklist(spotCL);

      setFetchState(FetchStates.DONE);
    } catch (err) {
      console.error(err);
      logger.error('Unexpected error loading spot checklist:', err);
      setFetchState(FetchStates.ERROR);
    }
  }

  // Load sizing separately so it can fail separately without crashing
  // the whole page.
  //
  // TODO: Add cancellation. Users changing the parameters multiple times will
  // encounter bad data because the frontend is updated for each request that
  // returns. This means that the state will often not match the parameters and
  // will change as each request returns. If the last request sent (containing
  // the most recent parameters set by the user) does not return last, then the
  // response shown to the user will not be correct because it does not align
  // with the parameters they configured.
  async function initializeSizing() {
    try {
      const sizingPromise = fetchSpotChecklistClusterSizing(
        minOnDemandNodeCount,
        minSpotNodeCount,
        sizingTargetUtilizationPct / 100,
        allowSharedCore,
        window,
      );
      const configPromise = model.getConfigs();

      const sizing = await sizingPromise;
      const config = await configPromise;

      setCurrency(config.currencyCode);

      // These blocks are necessary for "narrowing", see
      // https://www.typescriptlang.org/docs/handbook/2/narrowing.html#instanceof-narrowing
      //
      if (sizing instanceof Error) {
        logger.error('Sizing request returned an error:', sizing.message);
        setFetchStateSizing(FetchStates.ERROR);
      } else {
        setSpotChecklistClusterSizing(sizing);
        setFetchStateSizing(FetchStates.DONE);
      }
    } catch (err) {
      logger.error('Unexpected error initializing spot cluster sizing: ', err);
      setFetchStateSizing(FetchStates.ERROR);
    }
  }

  // Handle fetching data
  useEffect(() => {
    if (fetchState === FetchStates.INIT) {
      setFetchState(FetchStates.LOADING);

      initialize();
    }
  }, [fetchState]);

  useEffect(() => {
    if (fetchStateSizing === FetchStates.INIT) {
      setFetchStateSizing(FetchStates.LOADING);

      initializeSizing();
    }
  }, [fetchStateSizing]);

  const utilizationSliderMarks = [
    {
      value: 100,
      label: '100%',
    },
    {
      value: 50,
      label: '50%',
    },
  ];

  function sizingTab(): React.ReactElement {
    if (
      fetchState === FetchStates.ERROR ||
      fetchStateSizing === FetchStates.ERROR
    ) {
      return (
        <div>
          <Typography>
            Failed to load spot cluster sizing recommendation
          </Typography>
          <IconButton
            aria-label="refresh"
            onClick={() => {
              setFetchState(FetchStates.INIT);
              setFetchStateSizing(FetchStates.INIT);
            }}
          >
            <RefreshIcon />
          </IconButton>
        </div>
      );
    } else if (
      fetchState === FetchStates.LOADING ||
      fetchStateSizing === FetchStates.LOADING
    ) {
      return <Loading message="Computing spot cluster sizing recommendation" />;
    } else if (
      fetchState === FetchStates.DONE &&
      fetchStateSizing === FetchStates.DONE &&
      spotChecklist !== undefined &&
      currency !== undefined
    ) {
      return (
        <SpotChecklistSizingCard
          sizingResponse={spotChecklistClusterSizing}
          spotReadyChecklists={spotChecklist.filter(
            (checklist) => checklist.readiness === SpotReady.Yes,
          )}
          currencyCode={currency}
        />
      );
    } else {
      return <div></div>;
    }
  }

  function checklistTab(): React.ReactElement {
    if (fetchState === FetchStates.ERROR) {
      return (
        <div>
          <Typography>Failed to load spot checklist</Typography>
          <IconButton
            aria-label="refresh"
            onClick={() => {
              setFetchState(FetchStates.INIT);
            }}
          >
            <RefreshIcon />
          </IconButton>
        </div>
      );
    } else if (fetchState === FetchStates.LOADING) {
      return <Loading message="Computing spot cluster sizing recommendation" />;
    } else if (fetchState === FetchStates.DONE && spotChecklist !== undefined) {
      return (
        <div>
          <Typography variant="h6">Checklist</Typography>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Key</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableKey />
            </AccordionDetails>
          </Accordion>
          <SpotChecklistTable response={spotChecklist} />
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  function clusterSizingOptions(): React.ReactElement {
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Cluster recommendation options</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid container item spacing={3}>
              <Grid item>
                <Typography gutterBottom>
                  Target resource utilization of nodes
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip title="Percentage of resource overhead. At 90%, the algorithm targets minimum 90% node resource usage (CPU and RAM) based on maximum resource usage in the window.">
                  <InfoIcon />
                </Tooltip>
              </Grid>
              <Grid item md={2}>
                <Slider
                  value={sizingTargetUtilizationPctSlider}
                  min={50}
                  max={100}
                  valueLabelFormat={(value) => `${value}%`}
                  getAriaValueText={(value) => `${value}%`}
                  aria-labelledby="discrete-slider-restrict"
                  valueLabelDisplay="auto"
                  marks={utilizationSliderMarks}
                  onChange={(event, newValue) => {
                    // Separate onChange lets the slider value update interactively
                    // without firing a request until the new value has been locked in
                    if (typeof newValue === 'number') {
                      setSizingTargetUtilizationPctSlider(newValue);
                    } else {
                      if (newValue.length === 0) {
                        logger.warn(`Slider value ${newValue} not valid`);
                      } else {
                        setSizingTargetUtilizationPctSlider(newValue[0]);
                      }
                    }
                  }}
                  onChangeCommitted={(event, newValue) => {
                    if (typeof newValue === 'number') {
                      setSizingTargetUtilizationPct(newValue);
                      setFetchStateSizing(FetchStates.INIT);
                    } else {
                      if (newValue.length === 0) {
                        logger.warn(`Slider value ${newValue} not valid`);
                      } else {
                        setSizingTargetUtilizationPct(newValue[0]);
                        setFetchStateSizing(FetchStates.INIT);
                      }
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={3}>
              <Grid item>
                <Typography>Minimum node count: on-demand nodes</Typography>
              </Grid>
              <Grid item>
                <TextField
                  id="field-min-on-demand-node-count"
                  label="Node Count"
                  type="number"
                  defaultValue={minOnDemandNodeCount}
                  onChange={(event) => {
                    const newValue = parseInt(event.target.value);
                    if (newValue !== NaN) {
                      setMinOnDemandNodeCount(newValue);
                      setFetchStateSizing(FetchStates.INIT);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={3}>
              <Grid item>
                <Typography>Minimum node count: spot nodes</Typography>
              </Grid>
              <Grid item>
                <TextField
                  id="field-min-spot-node-count"
                  label="Node Count"
                  type="number"
                  defaultValue={minSpotNodeCount}
                  onChange={(event) => {
                    const newValue = parseInt(event.target.value);
                    if (newValue !== NaN) {
                      setMinSpotNodeCount(newValue);
                      setFetchStateSizing(FetchStates.INIT);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={3}>
              <Grid item>
                <Typography>
                  Window of data to base estimates and recommendations on
                </Typography>
              </Grid>
              <Grid item>
                <FormControl>
                  <InputLabel id="window-select-label">Window</InputLabel>
                  <Select
                    id="window-select"
                    value={window}
                    onChange={(e) => {
                      const newWindow = e.target.value;
                      setWindow(newWindow as string); // this is ugly, but it doesn't know its a string
                      setFetchStateSizing(FetchStates.INIT);
                    }}
                  >
                    <MenuItem key="1d" value="1d">
                      1 day
                    </MenuItem>
                    <MenuItem key="7d" value="7d">
                      7 day
                    </MenuItem>
                    <MenuItem key="30d" value="30d">
                      30 day
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item spacing={3}>
              <Grid item>
                <Typography>Allow shared-core machine types?</Typography>
              </Grid>
              <Grid item>
                <Switch
                  checked={allowSharedCore}
                  onChange={(event) => {
                    setAllowSharedCore(event.target.checked);
                    setFetchStateSizing(FetchStates.INIT);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <>
      <Header
        breadcrumbs={[
          { name: 'Cluster Savings', href: 'savings.html' },
          { name: 'Spot Commander', href: 'spot.html' },
        ]}
      >
        <IconButton
          aria-label="refresh"
          onClick={() => {
            setFetchState(FetchStates.INIT);
            setFetchStateSizing(FetchStates.INIT);
          }}
        >
          <RefreshIcon />
        </IconButton>
        <Link href="settings.html">
          <IconButton aria-label="refresh">
            <SettingsIcon />
          </IconButton>
        </Link>
      </Header>

      <Paper className={classes.description}>
        <Typography variant="h5" paragraph>
          Spot Commander
        </Typography>
        <Typography variant="body1" paragraph>
          <b>Identify workloads</b> ready for spot (preemptible) nodes and{' '}
          <b>resize your cluster</b> to realize the savings of migrating
          workloads to spot. The Checklist assesses the controllers running on
          your cluster for spot-readiness. Learn more about the checks performed{' '}
          <a href="https://github.com/kubecost/docs/blob/master/spot-checklist.md">
            here
          </a>
          . The recommended cluster configuration suggests a reconfiguration of
          your cluster's nodes to put spot-ready workloads on spot nodes while
          minimizing cost. It also generates CLI commands to help you implement
          the recommendation. Learn more{' '}
          <a href="https://github.com/kubecost/docs/blob/main/spot-cluster-sizing.md">
            here
          </a>
          .
        </Typography>
        <Typography>
          Spot Commander is currently in beta. Reach out to our team if you
          encounter issues or have suggestions!
        </Typography>
        {clusterSizingOptions()}
      </Paper>

      <Paper className={classes.description}>
        <Tabs
          value={tabsValue}
          onChange={(event, newValue) => {
            setTabsValue(newValue);
          }}
        >
          <Tab label="Spot Cluster Sizing Recommendation" />
          <Tab label="Spot Checklist" />
        </Tabs>
        {tabsValue === 0 && sizingTab()}

        {tabsValue === 1 && checklistTab()}
      </Paper>
    </>
  );
}

export default React.memo(SpotChecklistEstimatedPage);
