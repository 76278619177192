import React from 'react';
import { Table } from '@kubecost-frontend/holster';
import { TableBody } from '@kubecost-frontend/holster';
import { TableCell } from '@kubecost-frontend/holster';
import { TableHead } from '@kubecost-frontend/holster';
import { TableRow } from '@kubecost-frontend/holster';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import { Typography } from '@kubecost-frontend/holster';
import Tooltip from '@material-ui/core/Tooltip';
import WarningIcon from '@material-ui/icons/Warning';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import round from 'lodash/round';
import { toCurrency } from '../../services/format';
import AdoptDialog from './AdoptDialog';
import {
  CurrentInstanceBreakdownCell,
  RecommendedInstanceBreakdownCell,
} from './InstanceBreakdownCell';

const RecommendationTable = ({
  recommendations,
  currentClusterInformation,
  negotiatedDiscount,
  status,
  ready,
  setup,
  showActions,
  adoptRecommendation,
  currency,
}: RecommendationTableProps): React.ReactElement | null => {
  const [isExpandedCost, setIsExpandedCost] = React.useState(false);
  const [isExpandedCPU, setIsExpandedCPU] = React.useState(false);
  const [isExpandedRAM, setIsExpandedRAM] = React.useState(false);
  const [isExpandedBreakdown, setIsExpandedBreakdown] = React.useState(false);

  return (
    <div style={{ borderBottom: 'unset', width: '100% ' }}>
      <Table aria-label="simple table" style={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell align="center" style={{ color: grey[800] }}>
              CURRENT
            </TableCell>
            {recommendations.map(
              (recommendation: Recommendation, key: number) => (
                <TableCell align="center">
                  <div style={{ display: 'flex' }}>
                    <div style={{ flexGrow: 1 }}>
                      {recommendation.strategy.toUpperCase()}
                    </div>
                  </div>
                </TableCell>
              ),
            )}
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell>
              <IconButton onClick={() => setIsExpandedCost(!isExpandedCost)}>
                {isExpandedCost ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </TableCell>
            <TableCell align="left">Total cost</TableCell>
            <TableCell align="center">
              <Typography variant="p">
                {toCurrency(currentClusterInformation.monthlyRate, currency)}/mo
              </Typography>
            </TableCell>
            {recommendations.map((recommendation: Recommendation) => (
              <TableCell align="center">
                <Typography
                  variant="p"
                  style={{ fontWeight: 'bold', color: green[700] }}
                >
                  {toCurrency(recommendation.totalMonthlyCost, currency)}/mo
                </Typography>
              </TableCell>
            ))}
          </TableRow>
          {isExpandedCost && (
            <TableRow style={{ borderTopStyle: 'hidden' }}>
              <TableCell />
              <TableCell align="left">Savings</TableCell>
              <TableCell />
              {recommendations.map((recommendation: Recommendation) => {
                return recommendation.monthlySavings > 0 ? (
                  <TableCell align="center">
                    <Typography variant="p" style={{ color: green[700] }}>
                      {toCurrency(recommendation.monthlySavings, currency)} (
                      {round(
                        (100 * recommendation.monthlySavings) /
                          currentClusterInformation.monthlyRate,
                        1,
                      )}
                      %)
                    </Typography>
                  </TableCell>
                ) : (
                  <TableCell align="center">
                    <Typography variant="p" style={{ color: grey[700] }}>
                      {toCurrency(-recommendation.monthlySavings, currency)}{' '}
                      increase (
                      {round(
                        (100 * recommendation.monthlySavings) /
                          currentClusterInformation.monthlyRate,
                        1,
                      )}
                      %)
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          )}

          <TableRow>
            <TableCell />
            <TableCell align="left">Node count</TableCell>
            {currentClusterInformation.totalCounts ? (
              <TableCell align="center" style={{ color: grey[800] }}>
                {currentClusterInformation.totalCounts.totalNodeCount}
              </TableCell>
            ) : (
              <TableCell align="center">
                <Tooltip
                  placement="bottom"
                  title="Could not fetch current cluster information."
                >
                  <WarningIcon style={{ color: grey[800] }} />
                </Tooltip>
              </TableCell>
            )}
            {recommendations.map((recommendation: Recommendation) => (
              <TableCell align="center">{recommendation.nodeCount}</TableCell>
            ))}
          </TableRow>

          <TableRow>
            <TableCell>
              <IconButton onClick={() => setIsExpandedCPU(!isExpandedCPU)}>
                {isExpandedCPU ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </TableCell>
            <TableCell align="left">CPU</TableCell>
            {currentClusterInformation.totalCounts ? (
              <TableCell align="center" style={{ color: grey[800] }}>
                {currentClusterInformation.totalCounts.totalVCPUs} VCPUs
              </TableCell>
            ) : (
              <TableCell />
            )}
            {recommendations.map((recommendation: Recommendation) => (
              <TableCell align="center">
                {round(recommendation.totalVCPUs, 2)} VCPUs
              </TableCell>
            ))}
          </TableRow>
          {isExpandedCPU && (
            <TableRow style={{ borderTopStyle: 'hidden' }}>
              <TableCell />
              <TableCell align="left">CPU utilization</TableCell>
              {currentClusterInformation.totalCounts ? (
                <TableCell align="center" style={{ color: grey[800] }}>
                  {round(
                    100 *
                      currentClusterInformation.totalCounts.utilizationVCPUs,
                    1,
                  )}
                  % utilized
                </TableCell>
              ) : (
                <TableCell />
              )}
              {recommendations.map((recommendation: Recommendation) => (
                <TableCell align="center">
                  {round(100 * recommendation.utilizationVCPUs, 1)}% utilized
                </TableCell>
              ))}
            </TableRow>
          )}

          <TableRow>
            <TableCell>
              <IconButton onClick={() => setIsExpandedRAM(!isExpandedRAM)}>
                {isExpandedRAM ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </TableCell>
            <TableCell align="left">RAM</TableCell>
            {currentClusterInformation.totalCounts ? (
              <TableCell align="center" style={{ color: grey[800] }}>
                {currentClusterInformation.totalCounts.totalRAMGB} GB
              </TableCell>
            ) : (
              <TableCell />
            )}
            {recommendations.map((recommendation: Recommendation) => (
              <TableCell align="center">
                {round(recommendation.totalRAMGB, 2)} GB
              </TableCell>
            ))}
          </TableRow>
          {isExpandedRAM && (
            <TableRow style={{ borderTopStyle: 'hidden' }}>
              <TableCell />
              <TableCell align="left">RAM utilization</TableCell>
              {currentClusterInformation.totalCounts ? (
                <TableCell align="center" style={{ color: grey[800] }}>
                  {round(
                    100 *
                      currentClusterInformation.totalCounts.utilizationRAMGB,
                    1,
                  )}
                  % utilized
                </TableCell>
              ) : (
                <TableCell />
              )}
              {recommendations.map((recommendation: Recommendation) => (
                <TableCell align="center">
                  {round(100 * recommendation.utilizationRAMGB, 1)}% utilized
                </TableCell>
              ))}
            </TableRow>
          )}

          <TableRow style={{ verticalAlign: 'top' }}>
            <TableCell>
              <IconButton
                onClick={() => setIsExpandedBreakdown(!isExpandedBreakdown)}
              >
                {isExpandedBreakdown ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </TableCell>
            <TableCell align="left">Instance breakdown</TableCell>
            {currentClusterInformation.nodes ? (
              <TableCell align="center">
                <CurrentInstanceBreakdownCell
                  nodes={currentClusterInformation.nodes}
                  isExpanded={isExpandedBreakdown}
                />
              </TableCell>
            ) : (
              <TableCell />
            )}
            {recommendations.map((recommendation: Recommendation) => (
              <TableCell align="center">
                <RecommendedInstanceBreakdownCell
                  nodes={recommendation.pools}
                  isExpanded={isExpandedBreakdown}
                  currency={currency}
                  discount={negotiatedDiscount}
                />
              </TableCell>
            ))}
          </TableRow>

          <TableRow>
            <TableCell style={{ borderBottom: 'none' }} />
            <TableCell style={{ borderBottom: 'none' }} />
            <TableCell style={{ borderBottom: 'none' }} />
            {recommendations.map((recommendation: Recommendation) => (
              <TableCell style={{ borderBottom: 'none' }} align="center">
                {showActions && (
                  <AdoptDialog
                    recommendation={recommendation}
                    adopt={adoptRecommendation}
                    isError={status.error !== undefined}
                    isReady={ready}
                    isSetUp={setup}
                  />
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export type CurrentNodeInformation = {
  name: string;
  provider: string;
  architecture: string;
  count: number;
  RAMGB: number;
  vCPUs: number;
};

export type TotalCounts = {
  totalNodeCount: number;
  totalRAMGB: number;
  totalVCPUs: number;
  utilizationVCPUs: number;
  utilizationRAMGB: number;
};

export type CurrentClusterInformation = {
  monthlyRate: number;
  nodes: CurrentNodeInformation[];
  totalCounts: TotalCounts;
};

export type Type = {
  provider: string;
  name: string;
  vCPUs: number;
  RAMGB: number;
  hourlyPrice: number;
  spotHourlyPrice: number;
  sharedCore: boolean;
  architecture: string;
  pricePerRAMByteHr: number;
  pricePerCPUCoreHr: number;
  spotPricePerRAMByteHr: number;
  spotPricePerCPUCoreHr: number;
};

export type NodePool = {
  type: Type;
  count: number;
  totalMonthlyCost: number;
  totalRAMGB: number;
  totalVCPUs: number;
};

export type Recommendation = {
  strategy: string;
  pools: NodePool[];
  nodeCount: number;
  monthlySavings: number;
  totalMonthlyCost: number;
  requiredRAMGB: number;
  totalRAMGB: number;
  utilizationRAMGB: number;
  requiredVCPUs: number;
  totalVCPUs: number;
  utilizationVCPUs: number;
};

export type RecommendationTableProps = {
  recommendations: any;
  currentClusterInformation: CurrentClusterInformation;
  negotiatedDiscount: number;
  status:
    | { data: string }
    | { error: unknown; message: string; status: number };
  ready: boolean;
  setup: boolean;
  showActions: boolean;
  adoptRecommendation: (r: Recommendation) => void;
  currency: string;
};

export default RecommendationTable;
