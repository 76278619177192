// react
import React, { FC } from 'react';

// holster
import { Button } from '@kubecost-frontend/holster';

// local
import { EllipsisIcon } from '../../assets/images/ellipsis-icon';

interface MoreControlProps {
  onClick: () => void;
}

const MoreControl: FC<MoreControlProps> = ({ onClick }) => {
  return (
    <Button className="h-[100%]" onClick={onClick} variant="default">
      <EllipsisIcon />
    </Button>
  );
};

export { MoreControl as default };
