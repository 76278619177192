import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import isEmpty from 'lodash/isEmpty';
import model from '../services/model';
import Footer from './Footer';
import Nav from './Nav';
import NavNew from './NavNew';
import { NavigationBar } from './SpotlightSearch';
import useBetaUI from '../hooks/useBetaUI';
import BetaUIBanner from './BetaUIBanner';
import { usePageViewRecorder } from '../hooks/usePageViewRecorder';

export const SearchBarContext = React.createContext({
  isVisible: false,
  setIsVisible: (value: boolean) => {},
});

const Page = (): React.ReactElement => {
  const { isBetaUI } = useBetaUI();
  const { pathname } = useLocation();
  usePageViewRecorder();

  const [thanosEnabled, setThanosEnabled] = React.useState(false);
  const [thanosReady, setThanosReady] = React.useState(false);
  const [singleClusterReady, setSingleClusterReady] = React.useState(false);
  const [bannerDataReady, setBannerDataReady] = React.useState(false);
  const [startupBannerDismissed, setStartupBannerDismissed] =
    React.useState(false);
  React.useEffect(() => {
    setStartupBannerDismissed(
      localStorage.getItem('startup_banner_dismissed') === 'true',
    );
    Promise.all([
      model.clusterInfo().then((info) => {
        setThanosEnabled(info.thanosEnabled === 'true');
      }),
      model
        .getAllocationSummary('4h', 'namespace', { accumulate: false })
        .then(({ data }) => {
          const { sets } = data;
          const validDataPoints = sets.filter((s) => !isEmpty(s.allocations));
          let scr = false;
          if (validDataPoints.length > 1) {
            scr = true;
          } else {
            const longestRun = Math.max.apply(
              null,
              Object.values(sets[3].allocations)
                .filter(
                  (alloc) =>
                    !['__idle__', '__unmounted__'].includes(alloc.name),
                )
                .map(model.getSummaryMinutes),
            );
            scr = longestRun > 15;
          }
          setThanosReady(validDataPoints.length >= 1);
          setSingleClusterReady(scr);
        }),
    ]).then(() => setBannerDataReady(true));
  }, []);

  const [isSearchBarVisible, setIsSearchBarVisible] = React.useState(false);
  return (
    <SearchBarContext.Provider
      value={{
        isVisible: isSearchBarVisible,
        setIsVisible: setIsSearchBarVisible,
      }}
    >
      <div className={isBetaUI ? 'flex' : 'kubecost-app-body'}>
        <NavigationBar />
        {isBetaUI ? <NavNew active={pathname} /> : <Nav active={pathname} />}
        <main className="grow h-screen overflow-x-auto pt-10 px-16 relative">
          <BetaUIBanner />
          {bannerDataReady &&
            thanosEnabled &&
            !thanosReady &&
            !startupBannerDismissed && (
              <Alert
                severity="info"
                onClose={() => {
                  localStorage.setItem('startup_banner_dismissed', 'true');
                  setStartupBannerDismissed(true);
                }}
              >
                Kubecost is collecting data. It takes Thanos about 4 hours to
                write its first block.
              </Alert>
            )}
          {bannerDataReady &&
            !thanosEnabled &&
            !singleClusterReady &&
            !startupBannerDismissed && (
              <Alert
                severity="info"
                onClose={() => {
                  localStorage.setItem('startup_banner_dismissed', 'true');
                  setStartupBannerDismissed(true);
                }}
              >
                Kubecost is collecting data. Data should be ready for viewing
                within 15 minutes.
              </Alert>
            )}
          <Outlet />
          <Footer />
        </main>
      </div>
    </SearchBarContext.Provider>
  );
};

export default Page;
