// reusable type definitions!

import { DateLocale } from "yup/lib/locale";
import { SummaryQueryOpts } from '../../services/model';

// NOTE: These types are for the V2 Request Sizing API

export enum RecommendationAlgorithm {
  Max = 'max',
  Quantile = 'quantile',
}

export type Filter = {
  property: string;
  value: string;
  equality: string;
};

export interface RequestSizingParams {
  window: string;

  algorithmCPU: RecommendationAlgorithm;
  algorithmRAM: RecommendationAlgorithm;

  qCPU?: number;
  qRAM?: number;

  targetUtilizationCPU: number;
  targetUtilizationRAM: number;

  filters?: Filter[];
}

// E.g. { "cpu": "55m" } or { "memory": "5Gi" }
export interface ResourceToAmountMapping {
  [resource: string]: string;
}

export interface RecommendedRequestType {
  cpu: string;
  memory: string;
}

// E.g. { "cpu": 500 } or { "memory": 230.3 }
export interface ResourceToNumberMapping {
  [resource: string]: number;
}

export interface RequestSizingRecommendation {
  clusterID: string;
  namespace: string;
  controllerKind: string;
  controllerName: string;
  containerName: string;

  recommendedRequest: RecommendedRequestType;
  monthlySavings: ResourceToNumberMapping;

  latestKnownRequest: RecommendedRequestType;
  currentEfficiency: ResourceToNumberMapping;
}

// The ultimate response format of the request sizing V2 API
export type RequestSizingResponse = RequestSizingRecommendation[];

export type SelectOptions = {
  label: string;
  value: string;
};

export type AggregationType = {
  name: string;
  value: string;
};

export interface AutoscalingWorkloadSchedule {
  workload: AutoscalingWorkload;
  schedule: AutoscalingSchedule;
}

export interface AutoscalingWorkload {
  clusterID: string;
  namespace: string;
  controllerKind: string;
  controllerName: string;
}

export interface AutoscalingSchedule {
  start: Date;
  frequencyMinutes: number;
  lastModified?: Date;
  targetUtilizationCPU: number;
  targetUtilizationMemory: number;
}

export interface DrilldownChartData {
  currentRequest: number;
  dailyAvg: number;
  recommendation: number;
  time: string;
}

export interface AvgSavingsChartData {
  cost: number;
  request: number;
  time: string | undefined;
  usage: number;
}

export interface DrillDownFilters {
  filterClusters: string;
  filterNamespaces: string;
  filterContainers: string;
  filterControllers: string;
}
