import React from 'react';
import map from 'lodash/map';
import round from 'lodash/round';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import green from '@material-ui/core/colors/green';
import Link from '@material-ui/core/Link';
import OpenNewIcon from '@material-ui/icons/Launch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import {
  toCurrency,
  bytesToString,
  coresToString,
} from '../../../services/format';
import AppConfig from '../../../services/appconfig';
import { useClusters } from '../../../contexts/ClusterConfig';

const useStyles = makeStyles({
  flex: {
    display: 'flex',
  },
  flexGrow: {
    flexGrow: 1,
  },
  savings: {
    color: green[700],
  },
  summaryTable: {
    marginBottom: 12,
  },
});

const ContainerDialog = ({
  container,
  name,
  open,
  handleClose,
  currency,
  grafanaURL,
}) => {
  const classes = useStyles();

  const {
    apiConfig: { grafanaEnabled },
  } = useClusters();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      aria-labelledby="container-dialog-title"
      aria-describedby="container-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <span className={classes.flex}>
          <span className={classes.flexGrow}>{name}</span>
          <span className={classes.savings}>{`${toCurrency(
            container.monthlySavings,
            currency,
          )}/mo`}</span>
        </span>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom>
          Summary
        </Typography>
        <Typography variant="body1">
          Resources usage, requests, and request recommendations are computed as
          a maximum among all running instances. Savings and efficiency are
          computed as cumulative among all instances.
        </Typography>
        <TableContainer className={classes.summaryTable}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left" component="th" scope="row" width={200}>
                  Container
                </TableCell>
                <TableCell align="right" component="th" scope="row">
                  CPU usage
                </TableCell>
                <TableCell align="right" component="th" scope="row">
                  CPU request
                </TableCell>
                <TableCell align="right" component="th" scope="row">
                  CPU recomm'd
                </TableCell>
                <TableCell align="right" component="th" scope="row">
                  RAM usage
                </TableCell>
                <TableCell align="right" component="th" scope="row">
                  RAM request
                </TableCell>
                <TableCell align="right" component="th" scope="row">
                  RAM recomm'd
                </TableCell>
                <TableCell align="right" component="th" scope="row">
                  Efficiency
                </TableCell>
                <TableCell align="right" component="th" scope="row">
                  Savings
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left" width={200}>
                  {container.name}
                </TableCell>
                <TableCell align="right">
                  {coresToString(container.usage.cpuCores)}
                </TableCell>
                <TableCell align="right">
                  {coresToString(container.requests.cpuCores)}
                </TableCell>
                <TableCell align="right">
                  {coresToString(container.target.cpuCores)}
                </TableCell>
                <TableCell align="right">
                  {bytesToString(container.usage.ramBytes)}
                </TableCell>
                <TableCell align="right">
                  {bytesToString(container.requests.ramBytes)}
                </TableCell>
                <TableCell align="right">
                  {bytesToString(container.target.ramBytes)}
                </TableCell>
                <TableCell align="right">
                  {round(container.efficiency * 100, 1)}%
                </TableCell>
                <TableCell align="right">
                  {toCurrency(container.monthlySavings, currency)}
                </TableCell>
              </TableRow>
            </TableBody>
            <TableHead>
              <TableRow>
                <TableCell align="left" component="th" scope="row" width={200}>
                  Instance
                </TableCell>
                <TableCell align="right" component="th" scope="row">
                  CPU usage
                </TableCell>
                <TableCell align="right" component="th" scope="row">
                  CPU request
                </TableCell>
                <TableCell align="right" component="th" scope="row">
                  CPU recomm'd
                </TableCell>
                <TableCell align="right" component="th" scope="row">
                  RAM usage
                </TableCell>
                <TableCell align="right" component="th" scope="row">
                  RAM request
                </TableCell>
                <TableCell align="right" component="th" scope="row">
                  RAM recomm'd
                </TableCell>
                <TableCell align="right" component="th" scope="row">
                  Efficiency
                </TableCell>
                <TableCell align="right" component="th" scope="row">
                  Savings
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {map(container.instances, (instance, i) => {
                let link = null;
                if (container.namespace && instance.pod) {
                  link =
                    grafanaURL +
                    `/d/at-cost-analysis-pod/pod-cost-and-utilization-metrics?from=now-1d&orgId=1&var-costStorageStandard=0.044&var-costStorageSSD=0.187&var-costDiscount=30&var-namespace=${container.namespace}&var-pod=${instance.pod}`;
                }
                return (
                  <TableRow key={i}>
                    <TableCell align="left" width={200}>
                      {grafanaEnabled ? (
                        link ? (
                          <Tooltip
                            title="View Grafana dashboard"
                            placement="top"
                            aria-label="grafana"
                          >
                            <Link href={link} target="_blank">
                              {instance.pod} <OpenNewIcon fontSize="small" />
                            </Link>
                          </Tooltip>
                        ) : (
                          instance.pod
                        )
                      ) : (
                        <Tooltip title="Grafana is disabled. Enable Grafana to use this feature.">
                          <span>
                            {instance.pod} <OpenNewIcon fontSize="small" />
                          </span>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {coresToString(instance.usage.cpuCores)}
                    </TableCell>
                    <TableCell align="right">
                      {coresToString(instance.requests.cpuCores)}
                    </TableCell>
                    <TableCell align="right">
                      {coresToString(instance.target.cpuCores)}
                    </TableCell>
                    <TableCell align="right">
                      {bytesToString(instance.usage.ramBytes)}
                    </TableCell>
                    <TableCell align="right">
                      {bytesToString(instance.requests.ramBytes)}
                    </TableCell>
                    <TableCell align="right">
                      {bytesToString(instance.target.ramBytes)}
                    </TableCell>
                    <TableCell align="right">
                      {round(instance.efficiency * 100, 1)}%
                    </TableCell>
                    <TableCell align="right">
                      {toCurrency(instance.monthlySavings, currency)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContainerDialog;
