// react
import React, { FC } from 'react';

// holster
import { Menu } from '@kubecost-frontend/holster';
import { MenuItemProps } from '@kubecost-frontend/holster/src/components/Menu';

// local
import { DownloadIcon, FolderIcon } from '../../assets/images';
import BellIcon from '../../assets/images/bell-icon';

interface MoreMenuProps {
  onClose: () => void;
  onSelectAlerts: () => void;
  onSelectCsv: () => void;
  onSelectOpen: () => void;
  onSelectPDF: () => void;
}

const items: MenuItemProps[] = [
  { leftIcon: <FolderIcon height={18} width={18} />, text: 'Open Report' },
  { leftIcon: <BellIcon height={20} width={20} />, text: 'Alerts' },
  { leftIcon: <DownloadIcon height={18} width={18} />, text: 'Download CSV' },
  { leftIcon: <DownloadIcon height={18} width={18} />, text: 'Download PDF' },
];

const MoreMenu: FC<MoreMenuProps> = ({
  onClose,
  onSelectAlerts,
  onSelectCsv,
  onSelectOpen,
  onSelectPDF,
}) => {
  const selectItem = ({ text }: MenuItemProps) => {
    if (text === 'Open Report') {
      onSelectOpen();
    } else if (text === 'Alerts') {
      onSelectAlerts();
    } else if (text === 'Download CSV') {
      onSelectCsv();
    } else if (text === 'Download PDF') {
      onSelectPDF();
    }
  };
  return (
    <Menu
      className="-translate-x-[90px]"
      items={items}
      onClose={onClose}
      selectItem={selectItem}
    />
  );
};

export { MoreMenu as default };
