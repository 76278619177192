// react
import React, { FC } from 'react';

// lodash
import get from 'lodash/get';
import round from 'lodash/round';

// holster
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from '@kubecost-frontend/holster';

// local
import { toCurrency } from '../../services/format';
import { DiskAsset } from '../../types/asset';

interface DiskAssetDetailsProp {
  diskAsset: DiskAsset;
  currency: string;
  hours: number;
}

export const DiskAssetDetails: FC<DiskAssetDetailsProp> = ({
  diskAsset,
  currency,
  hours,
}) => {
  const totalCost = get(diskAsset, 'totalCost', 0);
  const bytes = get(diskAsset, 'bytes', 0);

  let costPerGBHour = 0;
  const gb = bytes / 1024 / 1024 / 1024;
  if (hours !== 0) {
    // if hours or ramGB is 0, then either we're getting bad data, or the cost is also 0
    // for now, just show 0 (not sure what to show in event that data is just wrong).
    if (gb !== 0) {
      costPerGBHour = totalCost / (gb * hours);
    } else if (totalCost !== 0) {
      console.warn('totalCost is nonzero, but bytes is 0');
    }
  }
  return (
    <>
      <Typography variant="h5">Disk Breakdown</Typography>

      <Table className="w-[100%]">
        <TableHead>
          <TableRow>
            <TableHeadCell align="left" scope="row">
              Space
            </TableHeadCell>
            <TableHeadCell align="right" scope="row">
              Hourly Rate
            </TableHeadCell>
            <TableHeadCell align="right" scope="row">
              Cost
            </TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left" scope="row">
              {round(gb, 3)} GiB
            </TableCell>
            <TableCell align="right" scope="row">
              {toCurrency(costPerGBHour, currency, 3)}/GiBHr
            </TableCell>
            <TableCell align="right" scope="row">
              {toCurrency(totalCost, currency, 2)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

DiskAssetDetails.displayName = 'DiskAssetDetails';
