import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import React, { useState } from 'react';
import { AssetLabelOverride, ReportConfig } from './reportingHelpers';
import { DataSourceGroupingChip } from './DataSourceGroupingChip';

export const DataSourceGroupingTable = ({
  reportConfig,
  updateConfig,
  labelMap,
}: {
  reportConfig: ReportConfig;
  updateConfig: React.Dispatch<React.SetStateAction<ReportConfig>>;
  labelMap: any;
}) => {
  const [canSave, setCanSave] = useState(false);
  const [currentDataSourceOverrides, setCurrentDataSourceOverrides] = useState<
    AssetLabelOverride[]
  >(reportConfig.assetLabelOverrides);

  const saveOverrides = () => {
    updateConfig({
      ...reportConfig,
      assetLabelOverrides: currentDataSourceOverrides,
    });
  };

  const handleRowUpdate = (value: string, allocationAggType: string) => {
    if (!canSave) {
      setCanSave(true);
    }
    const currentValueIndex = currentDataSourceOverrides.findIndex(
      (item: AssetLabelOverride) =>
        item.allocationAggregation === allocationAggType,
    );
    if (currentValueIndex !== -1) {
      currentDataSourceOverrides[currentValueIndex].assetLabelOverride = value;
      setCurrentDataSourceOverrides([...currentDataSourceOverrides]);
    } else {
      setCurrentDataSourceOverrides([
        ...currentDataSourceOverrides,
        { allocationAggregation: allocationAggType, assetLabelOverride: value },
      ]);
    }
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell key="Allocation Agg">Allocation Agg</TableCell>
              <TableCell key="Asset Label">Asset Label</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reportConfig.allocationAggregationBy
              .split(',')
              .map((item: string) => (
                <TableRow key={item}>
                  <TableCell key={item}>{item}</TableCell>
                  <TableCell key="Asset Label">
                    <DataSourceGroupingChip
                      labelMap={labelMap}
                      reportConfig={reportConfig}
                      item={item}
                      handleRowUpdate={handleRowUpdate}
                      saveOverride={saveOverrides}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
