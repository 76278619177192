// react
import React, { FC, useEffect, useState } from 'react';

// react-router
import { useNavigate } from 'react-router-dom';

// recharts
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

// holster
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from '@kubecost-frontend/holster';

// local
import { ArrowIcon } from '../../../assets/images/arrow-icon';
import { Header } from '../../../components/Header2New';
import { useClusters } from '../../../contexts/ClusterConfig';
import { getReservedInstanceData } from './api';
import { colorMap } from '../../../services/colors';
import ChevronLeftIcon from '../../../assets/images/ChevronLeft';

import { StatCard } from './StatCard';
import { ReservedData } from './types';

export const ReservedInstancesNew: FC = () => {
  const [reservedData, setReservedData] = useState<ReservedData>({
    cards: { totalSavings: 0, totalSpend: 0 },
    cpu: [],
    memory: [],
    table: [],
  });
  const { promQueryUrl, promRangeQueryUrl } = useClusters();
  const navigate = useNavigate();

  async function fetchData() {
    let values = await getReservedInstanceData(
      30,
      '3',
      promQueryUrl,
      promRangeQueryUrl,
    );
    setReservedData(values);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Header
        title={
          <>
            Reserved Instances
            <Typography variant="p" className="text-kc-gray-200 font-normal">
              <a href="savings">
                <ArrowIcon direction="UP" className="inline" />
                Back to savings
              </a>
            </Typography>
          </>
        }
        refreshCallback={fetchData}
      />

      <Typography variant="p" style={{ marginTop: '1em' }}>
        A recommended strategy for purchasing reserved instances is to observe
        the sustained usage/request low point (1st percentile) for both RAM and
        CPU capacity. This is often a good indicator for your future floor for
        CPU/RAM needs.
      </Typography>
      <hr style={{ marginTop: '1em', opacity: 0.1 }} />
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
        <div
          style={{
            paddingTop: '1em',
            paddingBottom: '1em',
          }}
        >
          <Typography variant="p-large">
            Memory Usage and Reservation Recommendation
          </Typography>
          <Typography variant="p" style={{ marginBottom: '1em' }}>
            Requests and usage for all containers in this pod
          </Typography>
          <ResponsiveContainer height={290} width="100%">
            <LineChart
              data={reservedData.memory}
              margin={{
                top: 0,
                bottom: 0,
                left: 0,
                right: 15,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis dataKey="time" />
              <YAxis />
              <Line
                dataKey="request"
                dot={false}
                type="monotone"
                stroke={colorMap.blue}
              />
              <Line
                type="monotone"
                dataKey="recommended"
                dot={false}
                stroke="#f4b400"
              />
              <Line
                dataKey="usage"
                dot={false}
                type="monotone"
                stroke={colorMap.red}
              />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div style={{ paddingTop: '1em' }}>
          <Typography variant="p-large">
            CPU Usage and Reservation Recommendation
          </Typography>
          <Typography variant="p" style={{ marginBottom: '1em' }}>
            Requests and usage for all containers in this pod
          </Typography>
          <ResponsiveContainer height={290} width="100%">
            <LineChart
              data={reservedData.cpu}
              margin={{
                top: 0,
                bottom: 0,
                left: 0,
                right: 15,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis dataKey="time" />
              <YAxis />
              <Line
                dataKey="request"
                dot={false}
                stroke={colorMap.blue}
                type="monotone"
              />
              <Line
                type="monotone"
                dataKey="recommended"
                dot={false}
                stroke="#f4b400"
              />
              <Line
                dataKey="usage"
                dot={false}
                stroke={colorMap.red}
                type="monotone"
              />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      <hr style={{ opacity: 0.1 }} />
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
        <StatCard
          title="Estimated Annual Savings"
          value={'$' + (reservedData.cards.totalSavings * 12).toFixed(2)}
        />
        <StatCard
          title="Savings Rate"
          value={
            (
              (100 * reservedData.cards.totalSavings) /
                reservedData.cards.totalSpend || 0
            ).toFixed() + '%'
          }
        />
        <StatCard title="Upfront Payment" value="$0.00" />
      </div>
      <Table style={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableHeadCell>Instance Type</TableHeadCell>
            <TableHeadCell>Current Cost</TableHeadCell>
            <TableHeadCell>Recommendation</TableHeadCell>
            <TableHeadCell>Savings</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reservedData.table.map((row) => {
            return (
              <TableRow>
                <TableCell>{row.instanceType}</TableCell>
                <TableCell>{row.currentCost}</TableCell>
                <TableCell>Reserve</TableCell>
                <TableCell>{row.savings}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

ReservedInstancesNew.displayName = 'ReservedInstancesNew';
