import React from 'react';
import get from 'lodash/get';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { Box, Chip } from '@material-ui/core';
import Details from './Details';
import { toVerboseTimeRange } from '../../services/format';
import { Asset } from '../../types/asset';

type DetailsDialogProps = {
  open: boolean;
  close: () => void;
  asset: Asset;
  window: string;
  currency: string;
};

const DetailsDialog = ({
  open,
  close,
  asset,
  window,
  currency,
}: DetailsDialogProps): React.ReactElement => {
  let title = '';
  let name = get(asset, 'name', '');
  const properties = get(asset, 'properties', {});
  const providerID = get(properties, 'providerID', '');
  // Attempt to reconstruct dialog name (type/name) if it is empty
  if (name === '') {
    name = get(properties, 'name', '');
    const type = get(asset, 'type', '');
    if (type !== '') {
      name = `${type}/${name}`;
    }
  }
  title = name || '';

  if (providerID) {
    title = `${name} | ${providerID}`;
  }
  const preemptible = get(asset, 'preemptible', 0);

  return (
    <Dialog open={open} onClose={close} maxWidth="lg" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box style={{ display: 'flex' }}>
          <Typography variant="body1" paragraph>
            {toVerboseTimeRange(window)}
          </Typography>
          <Box m={1} />
          {preemptible > 0 && <Chip label="Preemtible" color="default" />}
        </Box>
        <Details window={window} currency={currency} asset={asset} />
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DetailsDialog;
