// react
import React, { useState } from 'react';

// mui
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

// holster
import {
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from '@kubecost-frontend/holster';

// local
import { AlertTypes, Alert, ClusterHealthAlert } from '../../services/alerts';

function ClusterHealthAlertsTable({
  alerts,
  createAlert,
  removeAlert,
  locked = {},
}: ComponentProps): React.ReactElement {
  const [newWindow, setNewWindow] = useState('');
  const [newThreshold, setNewThreshold] = useState('');
  return (
    <Table data-test="alerts-table" className="w-full">
      <TableHead>
        <TableRow>
          <TableHeadCell>Window</TableHeadCell>
          <TableHeadCell>Threshold</TableHeadCell>
          <TableHeadCell>Add/Delete</TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {alerts.map((a) => (
          <TableRow key={a.id}>
            <TableCell>{a.window}</TableCell>
            <TableCell>{a.threshold}</TableCell>
            <TableCell>
              <IconButton
                data-test="alert-delete"
                onClick={() => removeAlert(a)}
              >
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell data-test="new-alert-window">
            {locked.window || (
              <Input
                value={newWindow}
                onChange={(e) => setNewWindow(e.target.value)}
              />
            )}
          </TableCell>
          <TableCell data-test="new-alert-threshold">
            {typeof locked.threshold === 'undefined' ? (
              <Input
                value={newThreshold}
                onChange={(e) => setNewThreshold(e.target.value)}
              />
            ) : (
              locked.threshold
            )}
          </TableCell>
          <TableCell>
            <IconButton data-test="new-alert-add" onClick={handleAddAlert}>
              <AddIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );

  function handleAddAlert() {
    const newAlert = new ClusterHealthAlert({
      type: AlertTypes.Health,
      window: newWindow || locked.window,
      threshold:
        typeof locked.threshold === 'undefined'
          ? parseFloat(newThreshold)
          : locked.threshold,
    });
    createAlert(newAlert);
    setNewWindow('');
    setNewThreshold('');
  }
}

export default ClusterHealthAlertsTable;

ClusterHealthAlertsTable.defaultProps = {
  locked: {},
};

interface ComponentProps {
  alerts: ClusterHealthAlert[];
  createAlert: (alert: Alert) => void;
  removeAlert: (alert: Alert) => void;
  locked?: {
    window?: string;
    threshold?: number;
  };
}
