// react
import React, { useEffect, useState } from 'react';

// mui
import { OpenInNew } from '@material-ui/icons';

// holster
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeadCell,
  Typography,
  Button,
  Menu,
} from '@kubecost-frontend/holster';

// local
import { ArrowIcon } from '../../../assets/images';
import { Header } from '../../../components/Header2New';
import { useClusters } from '../../../contexts/ClusterConfig';
import { getUnutilizedLocalDisks } from './api';
import { EllipsisIcon } from '../../../assets/images/ellipsis-icon';
import { MenuItemProps } from '@kubecost-frontend/holster/dist/components/Menu';

const LOW_DISK_THRESHOLD = 0.2;

export const LowDiskUsageNew = () => {
  const [underutilizedDisks, setUnderutilizedDisks] = useState([]);
  const [openActionsMenu, setOpenActionsMenu] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { clusterConfig, envConfig, promQueryUrl, thanosQueryUrl, grafanaUrl } =
    useClusters();

  const grafanaDiskURL = (diskName: string): string => {
    return `${grafanaUrl}/d/nBH7qBgMk/attached-disk-metrics?var-disk=${diskName}`;
  };

  const onTableRowClick = (diskName: string) => () => {
    window.open(grafanaDiskURL(diskName));
  };

  const handleOpenMenu = (idx: number) => {
    setOpenActionsMenu(!openActionsMenu);
    setCurrentIndex(idx);
  };
  async function fetchData() {
    let values = await getUnutilizedLocalDisks(
      LOW_DISK_THRESHOLD,
      clusterConfig,
      envConfig,
      thanosQueryUrl,
      promQueryUrl,
    );
    setUnderutilizedDisks(values);
  }
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Header
        title={
          <>
            Cluster Savings
            <Typography variant="p" className="text-kc-gray-200 font-normal">
              <a href="savings">
                <ArrowIcon direction="UP" className="inline" />
                Back to savings
              </a>
            </Typography>
          </>
        }
        refreshCallback={fetchData}
      />

      <div style={{ marginBottom: '2em' }}>
        <Typography variant="h5">Local Disks with low utilization</Typography>
        <br />
        Attached disks with under 20% current usage and 30% predicted usage can
        often be resized to save costs. Consider launching new nodes with
        smaller disks on the next node turndown.
      </div>

      <div>
        <Table style={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableHeadCell>Disk Name</TableHeadCell>
              <TableHeadCell>Cluster</TableHeadCell>
              <TableHeadCell>Current Utilization</TableHeadCell>
              <TableHeadCell>Recommendation</TableHeadCell>
              <TableHeadCell>Savings</TableHeadCell>
              <TableHeadCell>Actions</TableHeadCell>
            </TableRow>
          </TableHead>
          {!underutilizedDisks.length ? (
            <TableCell align="center" colSpan={5}>
              No data available
            </TableCell>
          ) : (
            <TableBody>
              {underutilizedDisks.map((disk, index) => (
                <TableRow>
                  <TableCell
                    style={{ color: '#2196f3', textDecoration: 'underline' }}
                  >
                    {disk.metric.instance}{' '}
                    <OpenInNew
                      style={{ fontSize: 'small', marginLeft: '0.5em' }}
                    />
                  </TableCell>
                  <TableCell>{clusterConfig.id}</TableCell>
                  <TableCell>
                    {parseFloat((disk.value[1] / disk.value[2]) * 100).toFixed(
                      1,
                    ) + '%'}
                  </TableCell>
                  <TableCell>
                    Resize disk to{' '}
                    {Math.ceil(disk.value[2] / 1024 / 1024 / 1024 / 5) *
                      5 *
                      0.5}{' '}
                    Gb
                  </TableCell>
                  <TableCell>${disk.savings.toFixed(2)}</TableCell>
                  <TableCell className="flex justify-center pt-5 cursor-pointer hover:">
                    <Button
                      variant="default"
                      onClick={() => handleOpenMenu(index)}
                    >
                      <EllipsisIcon />
                    </Button>
                  </TableCell>
                  {openActionsMenu && index === currentIndex ? (
                    <Menu
                      items={[
                        { leftIcon: null, text: ' View Grafana Instance' },
                      ]}
                      onClose={handleOpenMenu}
                      selectItem={onTableRowClick(disk.metric.instance)}
                    />
                  ) : null}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </div>
    </>
  );
};

LowDiskUsageNew.displayName = 'LowDiskUsage';
