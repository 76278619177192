// react
import React, { useState } from 'react';

// mui
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

// holster
import {
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from '@kubecost-frontend/holster';

// local
import { AlertTypes, Alert, EfficiencyAlert } from '../../services/alerts';

function EfficiencyAlertsTable({
  alerts,
  createAlert,
  removeAlert,
  locked = {},
}: ComponentProps): React.ReactElement {
  const [newWindow, setNewWindow] = useState('');
  const [newAgg, setNewAgg] = useState('');
  const [newFilters, setNewFilters] = useState('');
  const [newEff, setNewEff] = useState('');
  const [newSpend, setNewSpend] = useState('');
  return (
    <Table data-test="alerts-table" className="w-full">
      <TableHead>
        <TableRow>
          <TableHeadCell>Window</TableHeadCell>
          <TableHeadCell>Aggregation</TableHeadCell>
          <TableHeadCell>Filter</TableHeadCell>
          <TableHeadCell>Efficiency threshold</TableHeadCell>
          <TableHeadCell>Spend threshold</TableHeadCell>
          <TableHeadCell>Add/Delete</TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {alerts.map((a) => (
          <TableRow key={a.id}>
            <TableCell>{a.window}</TableCell>
            <TableCell>{a.aggregation}</TableCell>
            <TableCell>{a.filter}</TableCell>
            <TableCell>{a.efficiencyThreshold}</TableCell>
            <TableCell>{a.spendThreshold || 'None'}</TableCell>
            <TableCell>
              <IconButton
                data-test="alert-delete"
                onClick={() => removeAlert(a)}
              >
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell data-test="new-alert-window">
            {locked.window || (
              <Input
                value={newWindow}
                onChange={(e) => setNewWindow(e.target.value)}
              />
            )}
          </TableCell>
          <TableCell data-test="new-alert-aggregate">
            {locked.aggregate || (
              <Input
                value={newAgg}
                onChange={(e) => setNewAgg(e.target.value)}
              />
            )}
          </TableCell>
          <TableCell data-test="new-alert-filters">
            <Input
              value={newFilters}
              onChange={(e) => setNewFilters(e.target.value)}
            />
          </TableCell>
          <TableCell data-test="new-alert-efficiency">
            {locked.effThresh || (
              <Input
                value={newEff}
                onChange={(e) => setNewEff(e.target.value)}
              />
            )}
          </TableCell>
          <TableCell data-test="new-alert-spend">
            {locked.spendThresh || (
              <Input
                value={newSpend}
                onChange={(e) => setNewSpend(e.target.value)}
              />
            )}
          </TableCell>
          <TableCell>
            <IconButton data-test="new-alert-add" onClick={handleAddAlert}>
              <AddIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );

  function handleAddAlert() {
    const newAlert = new EfficiencyAlert({
      type: AlertTypes.Efficiency,
      window: newWindow || locked.window,
      aggregation: newAgg || locked.aggregate,
      filter: newFilters,
      efficiencyThreshold:
        typeof locked.effThresh !== 'undefined'
          ? locked.effThresh
          : parseFloat(newEff),
      spendThreshold:
        typeof locked.spendThresh !== 'undefined'
          ? locked.spendThresh
          : parseFloat(newSpend),
    });
    createAlert(newAlert);
    setNewWindow('');
    setNewAgg('');
    setNewFilters('');
    setNewEff('');
    setNewSpend('');
  }
}

export default EfficiencyAlertsTable;

EfficiencyAlertsTable.defaultProps = {
  locked: {},
};

interface ComponentProps {
  alerts: EfficiencyAlert[];
  createAlert: (alert: Alert) => void;
  removeAlert: (alert: Alert) => void;
  locked?: {
    window?: string;
    aggregate?: string;
    effThresh?: number;
    spendThresh?: number;
  };
}
