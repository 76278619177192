// react
import React, { FC, useState, useEffect, useMemo } from 'react';

// holster
import { Button, Typography } from '@kubecost-frontend/holster';

// Icons
import { SettingsIcon } from '../../assets/images/settings-icon';
import { ArrowIcon, CheckIcon } from '../../assets/images';

// Local Components
import ResultsTable from './ResultsTable';
import Loading from '../../components/Loading';
import { Header } from '../../components/Header2New';
import { CustomizeRequestSizingMenu } from './CustomizeRequestSizingMenu';
import { RequestSizingDrilldown } from './RequestSizingDrilldown';
import { BetaBadge } from '../../components';
import { ApplyRecommendations } from './ApplyRecommendations';

// Hooks
import { useClusters } from '../../contexts/ClusterConfig';
import { isRequestSizerAvailable } from '../../services/clustercontroller/requestsizerv2';
import { useLocation, useNavigate } from 'react-router';
import { useGetRecommendations } from './api';
import { useGetAllocation } from '../AllocationNew/hooks/useGetAllocationSummary';

// Helper Functions
import {
  cpuParser,
  mebibyteFactor,
  memoryParser,
  sumNonNegativeSavings,
} from './utils';
import { toCurrency } from '../../services/format';
import { colorMap, designSystemColorMap } from '../../services/colors';
import { parseFilters, savingsKeyMap } from '../../services/model';

// types
import {
  AutoscalingWorkloadSchedule,
  Filter,
  RecommendationAlgorithm,
  RequestSizingParams,
  RequestSizingRecommendation,
} from './types';

// recharts
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { EnableAutoscaling } from './EnableAutoscaling';
import { useAPIClient } from '../../services/APIClient/api-client';

// date-fns
import { format } from 'date-fns';
import { getCurrentContainerAddressModel } from '../../services/util';

// TODO: consolidate these from requestsizer and cluster.ts
// remove /model from service_address
const baseURL = getCurrentContainerAddressModel().substr(
  0,
  getCurrentContainerAddressModel().length - 6,
);

export const RequestSizing: FC = () => {
  const [activeFilters, setActiveFilters] = useState<Filter[]>([]);

  const [userInputs, setUserInputs] = useState<RequestSizingParams>({
    window: '2d',
    algorithmCPU: RecommendationAlgorithm.Max,
    algorithmRAM: RecommendationAlgorithm.Max,
    qCPU: 0.95,
    qRAM: 0.95,
    targetUtilizationCPU: 0.8,
    targetUtilizationRAM: 0.8,
    filters: [],
  });

  const [currency, setCurrency] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [requestSizerAvailable, setRequestSizerAvailable] = useState(false);
  const [openAutoRecommendations, setOpenAutoRecommendations] = useState(false);
  const [isSendingAutoscalingRequest, setIsSendingAutoscalingRequest] =
    useState(false);
  const [currentAutoscalings, setCurrentAutoscalings] = useState([]);
  const [drillDownRecommendation, setDrillDownRecommendation] =
    useState<RequestSizingRecommendation | null>(null);
  const [openDrilldown, setOpenDrilldown] = useState(false);
  const { modelConfig: config } = useClusters();
  const routerLocation = useLocation();
  const searchParams = new URLSearchParams(routerLocation.search);
  const navigate = useNavigate();

  const oldFilters = userInputs.filters?.map((f) => {
    return {
      property: f.property,
      value: f.value,
    };
  });

  const allocationParams = useMemo(() => {
    return {
      window: userInputs.window,
      // Note: By aggregating on an impossible (due to K8s character restrictions) label,
      // the backend aggregates everything into a single "__unallocated__" allocation,
      // thus doing all aggregation math for us.

      aggregate: 'label:!!!impossible!!!',
      idle: false,
      accumulate: false,
      step: '24h',
      //TODO: implement this with v2 filters
      ...parseFilters(oldFilters, savingsKeyMap),
    };
  }, [userInputs.window]);

  const {
    recommendations = [],
    isLoading,
    refetch,
  } = useGetRecommendations(userInputs);

  const { allocations = [] } = useGetAllocation(allocationParams as any);

  const handleOpenCustomizeMenu = () => {
    setOpenModal(!openModal);
  };

  const handleUpdateRecommendations = (r: RequestSizingParams) => {
    setUserInputs(r);
  };

  const handleUpdateFilters = (f: Filter) => {
    const fltr = btoa(JSON.stringify(f));
    searchParams.set('filters', fltr);
    navigate({
      search: `?${searchParams.toString()}`,
    });
  };

  const handleAutoScalingRequest = (frequency: number, startDate: Date) => {
    setIsSendingAutoscalingRequest(true);
    const workloadPayload: AutoscalingWorkloadSchedule[] = recommendations
      .filter(
        (item: RequestSizingRecommendation) =>
          item.controllerKind === 'deployment',
      )
      .map((rec: RequestSizingRecommendation) => ({
        workload: {
          clusterID: rec.clusterID,
          namespace: rec.namespace,
          controllerKind: rec.controllerKind,
          controllerName: rec.controllerName,
        },
        schedule: {
          start: startDate,
          frequencyMinutes: frequency,
          targetUtilizationCPU: userInputs.targetUtilizationCPU,
          targetUtilizationMemory: userInputs.targetUtilizationRAM,
        },
      }));

    fetch(
      `${baseURL}/cluster/kubescaler/resource/requests/schedules/upsert`,
      {
        method: 'POST',
        body: JSON.stringify(workloadPayload),
      },
    ).then(function (res) {
      alert('Successfully enabled autoscaling');
      setIsSendingAutoscalingRequest(false);
      // refetch recs & currentAutoscalings
      getCurrentAutoScalings();
      refetch();
    });
  };

  const getCurrentAutoScalings = () => {
    const APIClient = useAPIClient();
    APIClient.get(
      `${baseURL}/cluster/kubescaler/resource/requests/schedules`,
    ).then((resp) => setCurrentAutoscalings(resp.data));
  };

  const openAutoRecommendationsDocuments = () => {
    window.open(
      'https://github.com/kubecost/docs/blob/main/auto-request-sizing.md',
      '_blank',
    );
  };

  const onRequestSizerAvailable = async () => {
    const sizerAvailable = await isRequestSizerAvailable();
    setRequestSizerAvailable(sizerAvailable);
  };

  const handleTableRowSelect = (rowRecs: RequestSizingRecommendation) => {
    setDrillDownRecommendation(rowRecs);
    const selectedRowFilters = [
      { property: 'cluster', equality: ':', value: rowRecs.clusterID },
      { property: 'container', equality: ':', value: rowRecs.containerName },
      {
        property: 'controller',
        equality: ':',
        value: rowRecs.controllerName,
      },
      { property: 'namespace', equality: ':', value: rowRecs.namespace },
      {
        property: 'controllerKind',
        equality: ':',
        value: rowRecs.controllerKind,
      },
    ];
    setUserInputs({ ...userInputs, filters: selectedRowFilters });
    setOpenDrilldown(true);
  };

  const avgCpuData = allocations.map((data) => {
    const datapoint = {
      time: format(new Date(data.__unallocated__.start), 'MM/dd/yyyy'),
      request: data.__unallocated__.cpuCoreRequestAverage,
      usage: data.__unallocated__.cpuCoreUsageAverage,
      cost: data.__unallocated__.cpuCost,
    };
    return datapoint;
  });

  const avgRamData = allocations.map((data) => {
    const datapoint = {
      time: format(new Date(data.__unallocated__.start), 'MM/dd/yyyy'),
      request: data.__unallocated__.ramByteRequestAverage,
      usage: data.__unallocated__.ramByteUsageAverage,
      cost: data.__unallocated__.ramCost,
    };

    return datapoint;
  });

  // This is silly and the respoinse should be adjusted here.
  const allocationResponse = allocations.map((data) => data.__unallocated__);

  const cpuDrillDownData = allocationResponse.map((data) => {
    const dataPoint = {
      time: format(new Date(data.window.start), 'MM/dd/yyyy'),
      currentRequest: 0,
      recommendation: 0,
      dailyAvg: data.cpuCoreRequestAverage,
    };

    const recs = Object.values(recommendations);
    recs
      .filter((r) => r.containerName === drillDownRecommendation?.containerName)
      .forEach((d) => {
        dataPoint.recommendation += cpuParser(d.recommendedRequest.cpu);
        dataPoint.currentRequest += cpuParser(d.latestKnownRequest.cpu);
      });

    return dataPoint;
  });

  const ramDrillDownData = allocationResponse.map((data) => {
    const dataPoint = {
      time: format(new Date(data.window.start), 'MM/dd/yyyy'),
      currentRequest: 0,
      recommendation: 0,
      dailyAvg: data.ramByteUsageAverage,
    };

    const recs = Object.values(recommendations);
    recs
      .filter((r) => r.containerName === drillDownRecommendation?.containerName)
      .forEach((d) => {
        dataPoint.recommendation += memoryParser(d.recommendedRequest.memory);
        dataPoint.currentRequest += memoryParser(d.latestKnownRequest.memory);
      });

    return dataPoint;
  });

  const drillDownDataSet = {
    ramDrillDownData,
    cpuDrillDownData,
    recommendation: drillDownRecommendation,
  };

  useEffect(() => {
    const urlFilter = searchParams.get('filters') || '';
    let fltr: Filter[] = [];
    try {
      fltr = JSON.parse(atob(urlFilter)) || [];
    } catch (err) {
      fltr = [];
    }

    if (btoa(JSON.stringify(fltr)) !== btoa(JSON.stringify(activeFilters))) {
      setActiveFilters(fltr);
    }
  }, [routerLocation]);

  useEffect(() => {
    setCurrency(config.currencyCode);
    getCurrentAutoScalings();
  }, []);

  useEffect(() => {
    onRequestSizerAvailable();
  }, []);

  // TODO: Paginate table
  return (
    <>
      <Header
        refreshCallback={refetch}
        title={
          <>
            <div className="flex items-center">
              Request right-sizing recommendations
              <Typography variant="h6" className="pl-2">
                <BetaBadge message="This calculation is being handled by a beta API and should be used with caution!" />
              </Typography>
            </div>
            <Typography variant="p" className="font-normal text-kc-gray-200">
              <a href="savings">
                <ArrowIcon direction="UP" className="inline" />
                Back to savings
              </a>
            </Typography>
          </>
        }
      />
      <section className="mb-8">
        <div className="flex">
          <Typography variant="h5" className="text-kc-gray-200">
            Estimated available savings:
          </Typography>
          <Typography variant="h5" className="text-kc-success">
            &nbsp;
            {toCurrency(sumNonNegativeSavings(recommendations) || 0, currency)}
            /mo
          </Typography>
        </div>
      </section>

      <main>
        <div className="flex">
          <Button
            variant="default"
            className="flex items-center h-33px mr-3"
            onClick={handleOpenCustomizeMenu}
          >
            <SettingsIcon className="mr-2" />
            Customize
          </Button>

          {recommendations !== undefined && !requestSizerAvailable && (
            <Button
              variant="default"
              className="flex items-center h-33px mr-3"
              onClick={openAutoRecommendationsDocuments}
            >
              <CheckIcon className="mr-2" />
              Set Up Auto Recommendations
            </Button>
          )}
          {recommendations !== undefined && requestSizerAvailable && (
            <ApplyRecommendations
              recommendations={recommendations}
              open={openAutoRecommendations}
              setOpen={setOpenAutoRecommendations}
            />
          )}
          {recommendations !== undefined && requestSizerAvailable && (
            <EnableAutoscaling
              handleAutoscalingRequest={handleAutoScalingRequest}
              loading={isSendingAutoscalingRequest}
            />
          )}
          <CustomizeRequestSizingMenu
            open={openModal}
            onClose={handleOpenCustomizeMenu}
            handleRecsUpdate={handleUpdateRecommendations}
            handleAddFilter={handleUpdateFilters}
            userInputs={userInputs}
            activeFilters={activeFilters}
          />
        </div>
        <div className="flex pt-4 pb-4 mt-4 gap-6 border border-kc-gray-100">
          <div className="w-1/2 px-4">
            <div className="font-bold mb-4">RAM Requests</div>
            <ResponsiveContainer height={250}>
              <LineChart
                data={avgRamData}
                margin={{
                  top: 0,
                  bottom: 0,
                  left: 25,
                  right: 0,
                }}
              >
                <CartesianGrid vertical={false} />
                <Legend verticalAlign="bottom" />
                <XAxis dataKey="time" />
                <YAxis
                  tickFormatter={(tick) =>
                    (tick / mebibyteFactor).toFixed() + 'Mi'
                  }
                  type="number"
                  allowDataOverflow
                  scale="linear"
                  tickCount={7}
                  domain={[
                    (dataMin: number) => Math.min(0, dataMin),
                    (dataMax: number) => dataMax * 1.5,
                  ]}
                />
                <Line
                  dataKey="request"
                  dot={false}
                  type="monotone"
                  name="Requested"
                  stroke={designSystemColorMap.primary}
                  strokeWidth={3}
                />
                <Line
                  dataKey="usage"
                  dot={false}
                  type="monotone"
                  name="Utilized"
                  stroke={colorMap.red}
                  strokeWidth={3}
                />
                <Tooltip
                  formatter={(value: any) =>
                    (value / mebibyteFactor).toFixed(2) + 'Mi'
                  }
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className="w-1/2 pr-8">
            <div className="font-bold mb-4">CPU Requests</div>
            <ResponsiveContainer height={250}>
              <LineChart
                data={avgCpuData}
                margin={{
                  top: 0,
                  bottom: 0,
                  left: 20,
                  right: 0,
                }}
              >
                <CartesianGrid vertical={false} />
                <Legend verticalAlign="bottom" />
                <XAxis dataKey="time" />
                <YAxis
                  tickFormatter={(tick) => tick + 'm'}
                  type="number"
                  allowDataOverflow
                  scale="linear"
                  tickCount={7}
                  domain={[
                    (dataMin: number) => Math.min(0, dataMin),
                    (dataMax: number) => dataMax * 1.5,
                  ]}
                />
                <Line
                  dataKey="request"
                  dot={false}
                  type="monotone"
                  name="Requested"
                  stroke={designSystemColorMap.primary}
                  strokeWidth={3}
                />

                <Line
                  dataKey="usage"
                  dot={false}
                  type="monotone"
                  name="Utilized"
                  stroke={colorMap.red}
                  strokeWidth={3}
                />
                <Tooltip formatter={(value: any) => value.toFixed(2) + 'm'} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="mt-8">
          {recommendations === undefined || isLoading ? (
            <Loading message="Loading" />
          ) : (
            <ResultsTable
              recommendations={recommendations!}
              currency={currency}
              onRowSelect={handleTableRowSelect}
              currentAutoscalings={currentAutoscalings}
            />
          )}
        </div>
      </main>
      <>
        {openDrilldown && (
          <RequestSizingDrilldown
            drilldownData={drillDownDataSet}
            currency={currency}
            open={openDrilldown}
            setOpen={setOpenDrilldown}
            title="Workload Savings"
          />
        )}
      </>
    </>
  );
};

RequestSizing.displayName = 'RequestSizing';
