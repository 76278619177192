import { Button, Paper, Typography } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import { makeStyles, withStyles } from '@material-ui/styles';
import React from 'react';
import List from '@material-ui/core/List';
import { version } from '../../services/env';

const useStyles = makeStyles({
  card: {
    margin: '12px 12px 12px 12px',
    display: 'flex',
    flexFlow: 'column',
    padding: '12px 0px 12px 0px',
  },
  content: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row',
    verticalAlign: 'middle',
  },
  versionText: {
    paddingTop: '4px',
    paddingRight: '12px',
  },
});

// Orange Update Button
const UpdateButton = withStyles(() => ({
  root: {
    color: '#FFFFFF',
    backgroundColor: orange[400],
    '&:hover': {
      backgroundColor: orange[700],
    },
  },
}))(Button);

// App Info Card
const AppInfo = ({ id, isUpdate, nodeCount }) => {
  const classes = useStyles();

  return (
    <Paper id={id} elevation={0}>
      <div className={classes.card}>
        <Typography variant="h5" gutterBottom>
          App Info
        </Typography>
        <div className={classes.content}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            className={classes.versionText}
          >
            Kubecost v{version}
          </Typography>
          <div style={{ flex: '1 1 0%' }} />
          {isUpdate && (
            <UpdateButton
              disableElevation
              variant="contained"
              color="primary"
              onClick={() =>
                window.open(
                  'https://kubecost.com/install#update-kubecost',
                  '_blank',
                )
              }
              href=""
            >
              UPDATE AVAILABLE
            </UpdateButton>
          )}
        </div>
        <div className={classes.content}>
          <List>
            <Typography variant="body2">
              <b>Node Usage over last 7 days</b>
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Max: {nodeCount.max} Avg: {nodeCount.avg}
            </Typography>
          </List>
        </div>
      </div>
    </Paper>
  );
};

export default AppInfo;
