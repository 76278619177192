import get from 'lodash/get';
import isPlainObject from 'lodash/isPlainObject';
import Logger from './logger';
import { getCurrentContainerAddressModel, parseResponseJSON } from './util';

// api provides a basic interface for making requests to the
// cost-analyzer API endpoints
const api = {
  async get(url: string, params?: Record<string, string>): Promise<unknown> {
    let queryStr = '';
    if (params && isPlainObject(params)) {
      queryStr = `?${Object.keys(params)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
        )
        .join('&')}`;
    }
    return fetch(`${getCurrentContainerAddressModel()}${url}${queryStr}`).then(
      parseResponseJSON,
    );
  },

  async isEKS(): Promise<boolean> {
    const nodes = get(await this.get('/allNodes'), 'items', []);
    if (nodes.length === 0) {
      return false;
    }

    const labels = get(nodes[0], 'metadata.labels', {});
    const eksLabel = labels['eks.amazonaws.com/nodegroup'];

    return eksLabel !== undefined;
  },

  async prometheusQuery(query: string): Promise<unknown> {
    return fetch(
      `${getCurrentContainerAddressModel()}/prometheusQuery?query=${encodeURI(
        query,
      )}`,
    ).then(parseResponseJSON);
  },

  async prometheusQueryRange(query: string): Promise<unknown> {
    return fetch(
      `${getCurrentContainerAddressModel()}/prometheusQueryRange?query=${encodeURI(
        query,
      )}`,
    ).then(parseResponseJSON);
  },

  async prometheusTargets(): Promise<unknown> {
    const targets = await fetch(
      `${getCurrentContainerAddressModel()}/prometheusTargets`,
    );

    let resp;
    let text;
    try {
      text = await targets.text();
      resp = JSON.parse(text);
    } catch (err) {
      Logger.log(`Error Parsing Prometheus Targets: ${text}`);
      // the error here is returned as a string, need to wrap in expected object format
      resp = {
        status: 'error',
        message: text,
      };
    }

    return resp;
  },

  async thanosQuery(query: string): Promise<unknown> {
    const response = await fetch(
      `${getCurrentContainerAddressModel()}/thanosQuery?query=${encodeURI(
        query,
      )}`,
    );
    return parseResponseJSON(response);
  },

  async thanosQueryRange(query: string): Promise<unknown> {
    const response = await fetch(
      `${getCurrentContainerAddressModel()}/thanosQueryRange?query=${encodeURI(
        query,
      )}`,
    );
    return parseResponseJSON(response);
  },
};

// TODO react add POST, PUT, etc here as needed

export default api;
