import { ArrowLeftSharp } from '@material-ui/icons';
import React from 'react';

function Unauthorized(): React.ReactElement {
  return (
    <div className="main">
      <div className="card">
        <div className="header">
          <h1>Unauthorized Access</h1>
        </div>
        <div className="body">
          <p>
            Your account is not currently authorized to access this product. We
            recommend contacting your internal administrator to be granted
            access.
          </p>
          <p>
            <a id="back-link" href="/">
              <ArrowLeftSharp />
              Back to our site
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default React.memo(Unauthorized);
