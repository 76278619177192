import React, { FC } from 'react';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

interface LoadingProps {
  message: string;
}

const Loading: FC<LoadingProps> = ({ message = '' }) => {
  return (
    <div className="w-full text-center">
      <CircularProgress />
      {message && (
        <Typography variant="h6" color="textSecondary">
          {message}
        </Typography>
      )}
    </div>
  );
};

Loading.displayName = 'Loading';
export default Loading;
