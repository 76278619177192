import React from 'react';
import { makeStyles } from '@material-ui/styles';
import isArray from 'lodash/isArray';
import upperFirst from 'lodash/upperFirst';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { toVerboseTimeRange } from '../../services/format';

const useStyles = makeStyles(() => ({
  root: {
    '& > * + *': {
      marginTop: 2,
    },
  },
  link: {
    cursor: 'pointer',
  },
}));

const handleBreadcrumbClick = (i, cb) => (e) => {
  e.preventDefault();
  cb(i);
};

const Subtitle = ({
  aggregateBy,
  window,
  context,
  clearContext,
  goToContext,
}) => {
  const classes = useStyles();

  if (!isArray(context) || context.length === 0) {
    return (
      <div className={classes.root}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {aggregateBy && aggregateBy.length > 0 ? (
            <Typography>
              {toVerboseTimeRange(window)} by{' '}
              {aggregateBy.map(upperFirst).join('/')}
            </Typography>
          ) : (
            <Typography>{toVerboseTimeRange(window)}</Typography>
          )}
        </Breadcrumbs>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link
          className={classes.link}
          color="inherit"
          onClick={() => clearContext()}
        >
          <Typography>
            {toVerboseTimeRange(window)} by{' '}
            {context.map((c) => c.property).join('/')}
          </Typography>
        </Link>
        {context.map((ctx, c) =>
          c === context.length - 1 ? (
            <Typography key={c}>{ctx.name}</Typography>
          ) : (
            <Link
              key={c}
              className={classes.link}
              color="inherit"
              onClick={handleBreadcrumbClick(c, goToContext)}
            >
              <Typography>{ctx.name}</Typography>
            </Link>
          ),
        )}
      </Breadcrumbs>
    </div>
  );
};

export default React.memo(Subtitle);
