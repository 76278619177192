import React, { ChangeEvent, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import Grid, { GridSize } from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/styles';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { useNavigate, useLocation } from 'react-router-dom';
import DiagnosticsChecker from '../../components/DiagnosticsChecker';
import Header from '../../components/Header';
import Loading from '../../components/Loading';
import Warnings, { Warning } from '../../components/Warnings';
import Analytics from '../../services/analytics';
import cluster from '../../services/cluster';
import { captureError } from '../../services/error_reporting';
import model from '../../services/model';
import {
  fetchRequestSizingRecommendationsMaxHeadroom,
  RequestSizingRec,
} from '../../services/savings';
import { isRequestSizerAvailable } from '../../services/clustercontroller/requestsizer';
import ApplyRecommendations from './ApplyRecommendations';
import Breakdown from './Breakdown';
import DownloadControl from './Breakdown/Download';
import profiles from './profiles';
import Summary from './Summary';
import { HelpIconLink } from '../../components/HelpIcon';
import Logger from '../../services/logger';
import { NumberFormatCustom } from '../../components/NumberFormatCustom';

const useStyles = makeStyles({
  description: {
    padding: '24px 36px',
    marginBottom: 20,
  },
  form: {
    alignItems: 'center',
    display: 'flex',
    padding: '0 12px',
  },
  formControl: {
    margin: 8,
    minWidth: 120,
  },
  percentForm: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const OpenAutoRecommendationsDocuments = () => {
  window.open(
    'https://github.com/kubecost/docs/blob/main/guide-one-click-request-sizing.md',
    '_blank',
  );
};

const RequestSizingPage = () => {
  const classes = useStyles();

  const [fetch, setFetch] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [warnings, setWarnings] = useState<Array<Warning>>([]);
  const [currency, setCurrency] = useState('');
  const [grafanaURL, setGrafanaURL] = useState('');
  const [allContainers, setAllContainers] = useState([]);
  const [containers, setContainers] = useState([]);
  const [profile, setProfile] = useState(profiles.getProfile('development'));
  const [window, setWindow] = useState('2d');
  const [discount, setDiscount] = useState(0.0);

  const [requestSizerAvailable, setRequestSizerAvailable] = useState(false);
  const [recommendations, setRecommendations] =
    useState<RequestSizingRec | undefined>(undefined);

  const [cpuRequestsCores, setCPURequestsCores] = useState(0.0);
  const [cpuUsageCores, setCPUUsageCores] = useState(0.0);
  const [cpuSavings, setCPUSavings] = useState(0.0);
  const [cpuOverprovisionedCores, setCPUOverprovisionedCores] = useState(0.0);
  const [cpuUnderprovisionedCores, setCPUUnderprovisionedCores] = useState(0.0);

  const [ramRequestsBytes, setRAMRequestsBytes] = useState(0.0);
  const [ramUsageBytes, setRAMUsageBytes] = useState(0.0);
  const [ramSavings, setRAMSavings] = useState(0.0);
  const [ramOverprovisionedBytes, setRAMOverprovisionedBytes] = useState(0.0);
  const [ramUnderprovisionedBytes, setRAMUnderprovisionedBytes] = useState(0.0);

  const [totalSavings, setTotalSavings] = useState(0.0);

  const [filterType, setFilterType] = useState('cluster');
  const [filterText, setFilterText] = useState('');
  const [cpuPercentage, setCpuPercentage] = useState<number>(5);
  const [ramPercentage, setRamPercentage] = useState<number>(5);
  const [filters, setFilters] = useState<{ type: string; value: string }[]>([]);
  const [gridSize, setGridSize] = useState<GridSize>(5);

  const navigate = useNavigate();
  const routerLocation = useLocation();
  const search = new URLSearchParams(routerLocation.search);

  useEffect(() => {
    if (search.has('profile')) {
      setProfile(profiles.getProfile(search.get('profile')));
    }
    if (search.has('window')) {
      setWindow(search.get('window') || '');
    }
    if (search.has('filters')) {
      const f = (search.get('filters') || '').split('+').map((ff) => {
        const [type, value] = ff.split(':').map((s) => decodeURIComponent(s));
        return { type, value };
      });
      setFilters(f);
    } else {
      setFilters([]);
    }
    setFetch((prev) => !prev);
  }, [search.get('profile'), search.get('filters'), search.get('window')]);

  const clearWarnings = () => {
    setWarnings(() => []);
  };

  const pushWarning = (warn: Warning) => {
    setWarnings((warns: Warning[]) => [...warns, warn]);
  };

  async function initialize() {
    const config = await model.getConfigs();
    setCurrency(config.currencyCode);

    const gURL = await model.grafanaAddress();
    setGrafanaURL(gURL);

    if (!search.has('profile')) {
      const clusterInfo = await model.clusterInfo();
      const profileName = get(clusterInfo, 'clusterProfile', 'development');
      setProfile(profiles.getProfile(profileName));
    }

    const dc = await model.getDiscount();
    setDiscount(dc);

    setInitialized(true);
  }

  async function fetchData(abortController: AbortController) {
    clearWarnings();
    setLoading(true);

    try {
      const { targetUtilization } = profile;

      const recs = await fetchRequestSizingRecommendationsMaxHeadroom(
        window,
        targetUtilization.cpu,
        targetUtilization.ram,
        filters,
        { signal: abortController.signal },
      );
      setRecommendations(recs);

      const cntnrs = {};
      recs.controllers.forEach((controller) => {
        const ctlr = {
          name: controller.name,
          type: controller.type,
          namespace: controller.namespace,
        };
        Object.entries(controller.containers || {}).forEach(
          ([containerKey, container]) => {
            const key = `${container.clusterId}/${container.namespace}/${controller.name}:${containerKey}`;
            const instances = [];
            Object.entries(controller.pods || {}).forEach(([podKey, pod]) => {
              const instance = pod.containers[containerKey];
              if (instance) {
                instance.pod = podKey;
                instances.push(instance);
              }
            });
            container.cluster = cluster.clusterNameId(container);
            container.instances = instances;
            container.controller = ctlr;
            cntnrs[key] = container;
          },
        );
      });
      if (!Object.keys(cntnrs).length && filters.length) {
        pushWarning({
          primary: 'No sizing recommendations loaded',
          secondary:
            'A filter may be excluding all allocations from consideration',
        });
      }
      setAllContainers(cntnrs);
    } catch (err) {
      Logger.error(err);
      if (err && err.message && err.message.startsWith('illegal label')) {
        pushWarning({
          primary: 'Failed to load request sizing recommendations',
          secondary:
            'Invalid label filter. Label filters must be of the form key:value',
        });
      } else {
        pushWarning({
          primary: 'Failed to load request sizing recommendations',
          secondary:
            'Check that Prometheus is running properly, then try again',
        });
      }
      captureError(err);
    }

    const sizerAvailable = await isRequestSizerAvailable();
    setRequestSizerAvailable(sizerAvailable);

    setLoading(false);
  }

  // Handle fetching data
  useEffect(() => {
    if (!initialized) {
      initialize();
    } else {
      const abortController = new AbortController();
      fetchData(abortController);
      return () => {
        abortController.abort();
      };
    }
    return undefined;
  }, [initialized, fetch]);

  // Handle form changes
  const handleProfileChange = (e) => {
    const p = e.target.value;
    search.set('profile', p);
    Analytics.record('request_sizing:profile', { from: profile.name, to: p });
    navigate({ search: `?${search.toString()}` });

    if (p === 'custom') {
      setGridSize(9);
    } else {
      setGridSize(5);
    }
  };
  const handleWindowChange = (e) => {
    const w = e.target.value;
    search.set('window', w);
    Analytics.record('request_sizing:window', { from: window, to: w });
    navigate({ search: `?${search.toString()}` });
  };

  const handleCpuChange = (event: ChangeEvent<HTMLInputElement>) => {
    const c = event.target.value;
    setCpuPercentage(Number(c));
    setProfile((customProfile) => {
      customProfile.targetUtilization.cpu = Number(c.replace('%', '')) / 100;
      return customProfile;
    });
  };

  const handleRamChange = (event: ChangeEvent<HTMLInputElement>) => {
    const r = event.target.value;
    setRamPercentage(Number(r));
    setProfile((customProfile) => {
      customProfile.targetUtilization.ram = Number(r.replace('%', '')) / 100;
      return customProfile;
    });
  };

  const handleFilterAdd = (f) => {
    const fs = filter(filters, (ff) => f.type !== ff.type);

    // The chosen delimiting characters for filters are ``:``,
    // separating key from value (e.g. namespace from kubecost), and ``+``, to separate sets of these pairs
    // which form the filters.
    // We avoid potential conflict with filter values (labels filters always contain ``:`` in their value) by
    // double-URI-encoding the filter values.
    //
    // We achieve this by calling ``encodeURIComponent`` on each filter type and value. This encodes
    // the filter type and value once. Calling the ``URLSearchParams`` method ``toString()`` produces a query
    // string which has been URI-encoded, thus double-encoding the filter types and values, while single-encoding
    // their delimiters.
    //
    // So while filter type-value pairs are separated by ``:``, which encodes to ``%3A``, any ``:`` characters
    // in a filter value are encoded to ``%3A``, and then encoded again to ``%253A``.
    // This allows us to differentiate ``:`` and ``+`` that happen to lie in a filter value from ``:`` and ``+`` that
    // are being used to delineate separate filters.
    const searchFilters = [...fs, { type: filterType, value: filterText }]
      .map((ff) =>
        [encodeURIComponent(ff.type), encodeURIComponent(ff.value)].join(':'),
      )
      .join('+');
    search.set('filters', searchFilters);
    setFilterText('');
    navigate({ search: `?${search.toString()}` });
  };
  const handleFilterDelete = (f) => {
    const newFilters = filters.filter((ff) => ff !== f);
    const searchFilters = newFilters
      .map((ff) =>
        [encodeURIComponent(ff.type), encodeURIComponent(ff.value)].join(':'),
      )
      .join('+');
    if (newFilters.length) {
      search.set('filters', searchFilters);
    } else {
      search.delete('filters');
    }
    navigate({ search: `?${search.toString()}` });
  };

  // Build options for filter selects
  const clusterSet = {};
  const namespaceSet = {};
  Object.values(allContainers).forEach((container) => {
    if (container.cluster && container.cluster.length) {
      clusterSet[container.cluster] = container.cluster;
    }
    if (container.namespace && container.namespace.length) {
      namespaceSet[container.namespace] = container.namespace;
    }
  });

  // Filter containers by user-defined filters, then compute summary
  useEffect(() => {
    if (allContainers.length === 0) {
      pushWarning({
        primary: 'Failed to load request sizing recommendations',
        secondary: 'Check that Prometheus is running properly, then try again',
      });
    }

    const containerList = Object.values(allContainers);
    setContainers(reverse(sortBy(containerList, (c) => c.monthlySavings)));

    let totalCPUCoresR = 0.0;
    let totalCPUCoresU = 0.0;
    let totalOverprovisionedCPUCores = 0.0;
    let totalUnderprovisionedCPUCores = 0.0;
    let cpuSavings = 0.0;

    let totalRAMBytesR = 0.0;
    let totalRAMBytesU = 0.0;
    let totalOverprovisionedRAMBytes = 0.0;
    let totalUnderprovisionedRAMBytes = 0.0;
    let ramSavings = 0.0;

    containerList.forEach((c) => {
      totalCPUCoresU += c.usage.cpuCores;
      totalRAMBytesU += c.usage.ramBytes;
      totalCPUCoresR += c.requests.cpuCores;
      totalRAMBytesR += c.requests.ramBytes;
      if (c.requests.cpuCores >= c.target.cpuCores) {
        totalOverprovisionedCPUCores += c.requests.cpuCores - c.target.cpuCores;
        cpuSavings += c.monthlySavingsCPU;
      } else {
        totalUnderprovisionedCPUCores +=
          c.target.cpuCores - c.requests.cpuCores;
      }

      if (c.requests.ramBytes >= c.target.ramBytes) {
        totalOverprovisionedRAMBytes += c.requests.ramBytes - c.target.ramBytes;
        ramSavings += c.monthlySavingsRAM;
      } else {
        totalUnderprovisionedRAMBytes +=
          c.target.ramBytes - c.requests.ramBytes;
      }
    });

    setCPURequestsCores(totalCPUCoresR);
    setCPUUsageCores(totalCPUCoresU);
    setCPUOverprovisionedCores(totalOverprovisionedCPUCores);
    setCPUUnderprovisionedCores(totalUnderprovisionedCPUCores);

    setRAMRequestsBytes(totalRAMBytesR);
    setRAMUsageBytes(totalRAMBytesU);
    setRAMOverprovisionedBytes(totalOverprovisionedRAMBytes);
    setRAMUnderprovisionedBytes(totalUnderprovisionedRAMBytes);

    setCPUSavings(cpuSavings);
    setRAMSavings(ramSavings);
    setTotalSavings(cpuSavings + ramSavings);
  }, [allContainers]);

  return (
    <>
      <Header
        breadcrumbs={[
          { name: 'Cluster Savings', href: 'savings.html' },
          { name: 'Request Sizing', href: 'request-sizing.html' },
        ]}
      >
        <IconButton
          aria-label="refresh"
          onClick={() => setFetch((prev) => !prev)}
        >
          <RefreshIcon />
        </IconButton>
        <DiagnosticsChecker />
        <DownloadControl cumulativeData={containers} title={generateTitle()} />
        <Link href="settings.html">
          <IconButton aria-label="refresh">
            <SettingsIcon />
          </IconButton>
        </Link>
        <HelpIconLink
          href="https://docs.kubecost.com/api-request-right-sizing"
          tooltipText="Product Documentation"
        />
      </Header>
      <Paper className={classes.description}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Typography variant="h5" paragraph>
              Request Sizing Recommendations
            </Typography>
            <Typography variant="body1">
              Using Kubecost allocation metrics, we determine how well each
              container in your infrastructure is provisioned. Over-provisioned
              containers provide an opportunity to lower requests and save
              money. Under-provisioned containers represent a risk of resources
              running out, causing CPU throttling or OOM errors. Savings are
              then computed as the difference between current and recommended
              request levels, based on current node costs.
            </Typography>
          </Grid>
          <Grid item md={gridSize}>
            <FormControl className={classes.formControl}>
              <InputLabel id="profile-select-label">Profile</InputLabel>
              <Select
                id="profile-select"
                value={profile.name}
                onChange={handleProfileChange}
              >
                <MenuItem key="development" value="development">
                  Development
                </MenuItem>
                <MenuItem key="production" value="production">
                  Production
                </MenuItem>
                <MenuItem key="high-availability" value="high-availability">
                  High-availability
                </MenuItem>
                <MenuItem key="custom" value="custom">
                  Custom
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="window-select-label">Window</InputLabel>
              <Select
                id="window-select"
                value={window}
                onChange={handleWindowChange}
              >
                <MenuItem key="2d" value="2d">
                  2 days
                </MenuItem>
                <MenuItem key="7d" value="7d">
                  7 days
                </MenuItem>
                <MenuItem key="30d" value="30d">
                  30 days
                </MenuItem>
              </Select>
            </FormControl>
            {profile.name === 'custom' ? (
              <>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="cpu-precentage-input"
                    label="CPU Percentage"
                    value={cpuPercentage}
                    onChange={handleCpuChange}
                    onBlur={() => setFetch((prev) => !prev)}
                    InputProps={{
                      inputComponent: NumberFormatCustom as any,
                    }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="ram-percentage-input"
                    label="RAM Percentage"
                    value={ramPercentage}
                    onChange={handleRamChange}
                    onBlur={() => setFetch((prev) => !prev)}
                    InputProps={{
                      inputComponent: NumberFormatCustom as any,
                    }}
                  />
                </FormControl>
              </>
            ) : null}

            <Typography variant="body2" style={{ marginLeft: 8 }}>
              {profile.description} Utilization is based on the maximum resource
              usage during the window.
            </Typography>
          </Grid>

          <Grid item md={5}>
            <Typography variant="body2" style={{ paddingLeft: 12 }}>
              Filters
            </Typography>
            <div className={classes.form}>
              <Select
                onChange={(e) => {
                  setFilterType(e.target.value as string);
                }}
                value={filterType}
              >
                <MenuItem key="cluster" value="cluster">
                  Cluster
                </MenuItem>
                <MenuItem key="node" value="node">
                  Node
                </MenuItem>
                <MenuItem key="namespace" value="namespace">
                  Namespace
                </MenuItem>
                <MenuItem key="label" value="label">
                  Label
                </MenuItem>
                <MenuItem key="service" value="service">
                  Service
                </MenuItem>
                <MenuItem key="controller" value="controller">
                  Controller
                </MenuItem>
                <MenuItem key="controllerKind" value="controller kind">
                  Controller Kind
                </MenuItem>
                <MenuItem key="pod" value="pod">
                  Pod
                </MenuItem>
                <MenuItem key="container" value="container">
                  Container
                </MenuItem>
              </Select>
              <TextField
                onChange={(e) => {
                  setFilterText(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleFilterAdd({ type: filterType, value: filterText });
                  }
                }}
                placeholder={
                  filterType === 'label' ? 'E.g. app:prometheus' : ''
                }
                style={{ marginLeft: 25 }}
                value={filterText}
              />
              <IconButton
                onClick={() => {
                  handleFilterAdd({ type: filterType, value: filterText });
                }}
              >
                <AddIcon />
              </IconButton>
            </div>
            <div style={{ marginTop: 20 }}>
              {filters.map((f) => (
                <Chip
                  key={`${f.type}=${f.value}`}
                  label={`${f.type}=${f.value}`}
                  onDelete={() => handleFilterDelete(f)}
                />
              ))}
            </div>
          </Grid>

          <Grid item>
            <Grid container>
              {recommendations !== undefined && !requestSizerAvailable && (
                <Button
                  onClick={OpenAutoRecommendationsDocuments}
                  variant="outlined"
                  color="primary"
                >
                  Setup Auto Recommendations
                </Button>
              )}
              {recommendations !== undefined && requestSizerAvailable && (
                <ApplyRecommendations
                  recommendations={recommendations}
                  filters={filters}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      {loading ? <Loading message="Loading request recommendations" /> : <></>}

      {!loading && warnings.length > 0 ? (
        <div style={{ marginBottom: 20 }}>
          <Warnings warnings={warnings} />
        </div>
      ) : (
        <></>
      )}

      {!loading ? (
        <Summary
          cpuRequestsCores={cpuRequestsCores}
          cpuUsageCores={cpuUsageCores}
          cpuUnderprovisionedCores={cpuUnderprovisionedCores}
          cpuOverprovisionedCores={cpuOverprovisionedCores}
          cpuSavings={cpuSavings}
          ramRequestsBytes={ramRequestsBytes}
          ramUsageBytes={ramUsageBytes}
          ramUnderprovisionedBytes={ramUnderprovisionedBytes}
          ramOverprovisionedBytes={ramOverprovisionedBytes}
          ramSavings={ramSavings}
          totalSavings={totalSavings}
          currency={currency}
        />
      ) : (
        <></>
      )}

      {!loading && !!containers.length ? (
        <Breakdown
          containers={containers}
          currency={currency}
          grafanaURL={grafanaURL}
        />
      ) : (
        <></>
      )}
    </>
  );

  function generateTitle() {
    const windowText = [
      { name: '2 days', value: '2d' },
      { name: '7 days', value: '7d' },
      { name: '30 days', value: '30d' },
    ];

    const windowName = get(find(windowText, { value: window }), 'name', '');
    let str = `${windowName} breakdown for ${profile.name}`;

    if (filters && filters.length) {
      str += ' with filters';
    }

    return str;
  }
};

export default React.memo(RequestSizingPage);
