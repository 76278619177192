import get from 'lodash/get';
import Logger from './logger';
import model from './model';
import prom from './prometheus';
import { sleep } from './util';

const DEFAULT_CPU_PRICE = get(localStorage, 'cpu', 0.031611 * 730.0) / 730.0;
const DEFAULT_RAM_PRICE = get(localStorage, 'ram', 0.004237 * 730.0) / 730.0;

type NodePricing = {
  cpuHr: number;
  name: string;
  ramGbHr: number;
};

// TODO be smarter about sustained use discount
// TODO TTL cache, once this is being called once on app-load
const nodePricingMap: Record<string, NodePricing> = {};
let nodePricingMapIsFetching = false;
let nodePricingMapIsFetched = false;
const fetchNodePricingMap = async () => {
  while (nodePricingMapIsFetching) {
    await sleep(5);
  }
  nodePricingMapIsFetching = true;

  if (nodePricingMapIsFetched) {
    nodePricingMapIsFetching = false;
    return nodePricingMap;
  }

  const [discount, respNodeCPU, respNodeRAM] = await Promise.all([
    model.getDiscount(),
    prom.query('node_cpu_hourly_cost'),
    prom.query('node_ram_hourly_cost'),
  ]);

  respNodeCPU.data.result.forEach((r) => {
    const n = r.metric.node;
    const v = parseFloat(r.value[1]);
    if (!nodePricingMap[n]) {
      nodePricingMap[n] = {
        name: n,
        cpuHr: DEFAULT_CPU_PRICE * (1.0 - discount),
        ramGbHr: DEFAULT_RAM_PRICE * (1.0 - discount),
      };
    }
    nodePricingMap[n].cpuHr = v * (1.0 - discount);
  });

  respNodeRAM.data.result.forEach((r) => {
    const n = r.metric.node;
    const v = parseFloat(r.value[1]);
    if (!nodePricingMap[n]) {
      nodePricingMap[n] = {
        name: n,
        cpuHr: DEFAULT_CPU_PRICE * (1.0 - discount),
        ramGbHr: DEFAULT_RAM_PRICE * (1.0 - discount),
      };
    }
    nodePricingMap[n].ramGbHr = v * (1.0 - discount);
  });

  nodePricingMapIsFetching = false;
  nodePricingMapIsFetched = true;
  return nodePricingMap;
};

// billing provides and interface for determining resource pricing
const billing = {
  // getMonthlyStorageCost determines monthly storage cost by storage type.
  // TODO should be improved to consider region and provider
  // see static/helper.js getHourlyStorageCost
  getMonthlyStorageCost(
    type: string,
    region: string,
    provider: string,
  ): number {
    const resourceType = typeof type === 'string' ? type.toLowerCase() : '';

    let resourceGroup = 'pd-standard';
    if (resourceType.indexOf('ssd') >= 0) {
      resourceGroup = 'ssd';
    }
    if (resourceType.indexOf('gp2') >= 0) {
      resourceGroup = 'gp2';
    }

    let price = 0.04;
    if (resourceGroup === 'ssd') {
      price = 0.17;
    }
    if (resourceGroup === 'gp2') {
      price = 0.1;
    }

    return price;
  },

  // getHourlyIPAddressCost determines hourly cost of an unused external IP address
  // TODO should be improved to consider region and provider (GCP only at the moment)
  // see https://cloud.google.com/compute/all-pricing?hl=en_US&_ga=2.176145697.-2028771354.1570384965#ipaddress
  getHourlyIPAddressCost(provider: string, region: string): number {
    if (provider === 'aws') {
      return 0.005;
    }

    return 0.01;
  },

  // nodePricing returns the CPU and RAM hourly pricing for the given node, returning
  // default pricing if the node's pricing cannot be found.
  async nodePricing(
    node: string,
  ): Promise<{ cpuHr: number; name: string; ramGbHr: number }> {
    const def = {
      name: '',
      cpuHr: DEFAULT_CPU_PRICE,
      ramGbHr: DEFAULT_RAM_PRICE,
    };

    if (!node || node === '') {
      return def;
    }

    const npm = await fetchNodePricingMap();
    const np = npm[node];
    if (np === undefined) {
      Logger.warn(
        `Failed to find node pricing for node ${node}. Using default.`,
      );
      return def;
    }
    return np;
  },

  // allNodePricing returns the CPU and RAM hourly pricing for all nodes
  async allNodePricing(): Promise<Record<string, NodePricing>> {
    const def = {
      name: '',
      cpuHr: DEFAULT_CPU_PRICE,
      ramGbHr: DEFAULT_RAM_PRICE,
    };

    const npm = await fetchNodePricingMap();
    npm.default = def;

    return npm;
  },
};

export default billing;
