import { Tooltip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from '@kubecost-frontend/holster';
import React from 'react';

/**
 *
 * Common table cards
 */
export interface Row extends Record<string, React.ReactNode> {
  id: string;
}

export interface TableCardProps {
  rows: Row[];
  columns: Array<{ name: string; prop: string }>;
  title?: string;
  emptyTitle?: string;
  emptySubhead?: string;
  emptyHref?: string;
  emptyLinkStyle?: Record<string, string | number>;
  emptyCondition?: boolean;
  loading?: boolean;
  rowHref?: (row: Row) => string;
}

export const TableCard: React.FC<TableCardProps> = ({
  rows,
  columns,
  title,
  emptyTitle,
  emptySubhead,
  emptyHref,
  emptyLinkStyle,
  emptyCondition,
  loading = false,
  rowHref = null,
}) => {
  const [page, setPage] = React.useState(0);
  const [pageRows, setPageRows] = React.useState(5);
  const imgTable = React.useMemo(
    () => columns.find((c) => c.prop === 'provider'),
    [columns],
  );
  const emptyRows =
    pageRows -
    Math.min(pageRows, rows.length - page * pageRows) -
    (emptyCondition ? pageRows - rows.length : 0);

  const emptyTitleHeight = (imgTable ? 53 : 45) * (5 - rows.length);
  const emptyRowHeight = (imgTable ? 53 : 45) * emptyRows;
  const startIndex = page * pageRows + 1;
  const stopIndex = (page + 1) * pageRows;
  const pageBackward = () => {
    setPage((prev) => prev - 1);
  };
  const pageForward = () => setPage((prev) => prev + 1);

  const handleOnRowsPerPageChange: React.ChangeEventHandler<HTMLInputElement> =
    (e) => {
      setPageRows(parseInt(e.target.value, 10));
    };

  const handleRowClick = (row: Row) => () => {
    if (rowHref) {
      const loc = rowHref(row);
      if (loc) {
        window.location.href = loc;
      } else {
        alert('Cannot navigate to rows with no account');
      }
    }
  };

  return (
    <div
      style={{ width: '100%', height: '100%', overflowX: 'auto' }}
      className="border border-kc-gray-100"
    >
      {title && (
        <div
          className="flex border-b border-kc-gray-100"
          style={{ padding: '15px 0 15px 19px' }}
        >
          <Typography className="font-bold" variant="p">
            {title}
          </Typography>
        </div>
      )}
      {loading && (
        <Table className="w-full">
          <TableHead>
            <TableRow>
              <TableHeadCell>
                <Skeleton style={{}} />
              </TableHeadCell>
              <TableHeadCell>
                <Skeleton style={{}} />
              </TableHeadCell>
              <TableHeadCell>
                <Skeleton style={{}} />
              </TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Skeleton style={{}} />
              </TableCell>
              <TableCell>
                <Skeleton style={{}} />
              </TableCell>
              <TableCell>
                <Skeleton style={{}} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton style={{}} />
              </TableCell>
              <TableCell>
                <Skeleton style={{}} />
              </TableCell>
              <TableCell>
                <Skeleton style={{}} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton style={{}} />
              </TableCell>
              <TableCell>
                <Skeleton style={{}} />
              </TableCell>
              <TableCell>
                <Skeleton style={{}} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      {!loading && (
        <Table style={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableHeadCell
                  key={column.name}
                  className="text-kc-gray-300"
                  style={{
                    textAlign: 'left',
                    paddingTop: 16,
                    paddingBottom: 16,
                    paddingLeft: 19,
                    fontWeight: 700,
                  }}
                >
                  {column.name}
                </TableHeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * pageRows, (page + 1) * pageRows)
              .map((row, i) => (
                <TableRow
                  key={row.id}
                  onClick={handleRowClick(row)}
                  style={{
                    cursor: rowHref ? 'pointer' : '',
                  }}
                >
                  {columns.map((column) => (
                    <TableCell
                      key={`${row.id}-${column.name}`}
                      className={
                        column.name === 'COST'
                          ? 'text-kc-gray-300'
                          : 'text-kc-gray-200'
                      }
                      style={{
                        padding: '10px 10px 10px 19px',
                        fontWeight: 400,
                      }}
                    >
                      {column.prop === 'provider'
                        ? providerIcon(row[column.prop] as string)
                        : row[column.prop] === '__undefined__'
                        ? 'None'
                        : row[column.prop]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: emptyRowHeight }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
      {emptyTitle &&
        emptyCondition &&
        !loading &&
        (emptyHref ? (
          <a
            href={emptyHref}
            target="_blank"
            style={emptyLinkStyle || { textDecoration: 'none' }}
          >
            <div
              style={{
                paddingTop: '3em',
                width: '100%',
                textAlign: 'center',
                height: emptyTitleHeight,
                borderBottom: '1px solid rgb(191 205 201)',
              }}
            >
              <Typography variant="p-large" style={{ fontWeight: 700 }}>
                {emptyTitle}
              </Typography>
              {emptySubhead && (
                <p style={{ color: '#28b359' }}>{emptySubhead}</p>
              )}
            </div>
          </a>
        ) : (
          <div
            style={{
              paddingTop: '3em',
              width: '100%',
              textAlign: 'center',
              height: emptyTitleHeight,
              borderBottom: '1px solid rgb(191 205 201)',
            }}
          >
            <Typography variant="p-large" style={{ fontWeight: 700 }}>
              {emptyTitle}
            </Typography>
            {emptySubhead && <p style={{ color: '#28b359' }}>{emptySubhead}</p>}
          </div>
        ))}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: 54,
          width: '100%',
        }}
      >
        <Typography variant="p" style={{ marginRight: 19 }}>
          {startIndex}-{stopIndex} of {rows.length}
        </Typography>
        <Button
          variant="default"
          style={{ marginRight: 7 }}
          onClick={pageBackward}
          disabled={!page}
        >
          Previous
        </Button>
        <Button
          variant="default"
          style={{ marginRight: 15 }}
          onClick={pageForward}
          disabled={(page + 1) * pageRows >= rows.length}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

TableCard.defaultProps = {
  emptyTitle: '',
  emptySubhead: '',
  emptyHref: '',
  loading: false,
};

function providerIcon(provider: string): React.ReactNode {
  const src =
    {
      AWS: 'images/aws_logo_square.png',
      GCP: 'images/gcp_logo_square.png',
      Azure: 'images/azure_logo_square.png',
    }[provider] || 'images/kube_icon.png';
  return (
    <Tooltip title={provider}>
      <img alt={provider} src={src} width={32} />
    </Tooltip>
  );
}
