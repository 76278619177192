// React
import React, { FC, ReactNode } from 'react';

// Holster
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Chip,
} from '@kubecost-frontend/holster';

import { Tooltip } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

// basic component props example
interface ResultsTableProps {
  recommendations: RequestSizingRecommendation[];
  currency: string;
  currentAutoscalings: AutoscalingWorkloadSchedule[];
}

// types
import {
  AutoscalingWorkloadSchedule,
  RequestSizingRecommendation,
} from './types';

// utils
import { ChevronRight } from '@material-ui/icons';
import { recCurrency } from './utils';

interface ResultsTableProps {
  currency: string;
  recommendations: RequestSizingRecommendation[];
  onRowSelect: (Recommendation: RequestSizingRecommendation) => void;
}

const ResultsTable: FC<ResultsTableProps> = ({
  recommendations,
  currency,
  currentAutoscalings,
  onRowSelect,
}) => {
  const columns: ReactNode[] = [
    '',
    'Cluster',
    'Namespace\\Controller',
    'Container',
    'RAM',
    'CPU',
    'Current Efficiency',
    'Est. savings (monthly)',
  ];

  return (
    <Table className="w-full">
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableHeadCell className="font-bold text-left">
              {column}
            </TableHeadCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {recommendations.map((recommendation) => {
          const savingsFigure = recCurrency(recommendation, currency);

          const isPositiveSavings =
            recommendation.monthlySavings !== null &&
            recommendation.monthlySavings['cpu'] +
              recommendation.monthlySavings['memory'] >
              0;

          const currentAutoScaling = currentAutoscalings.find(
            (item: AutoscalingWorkloadSchedule) =>
              item.workload.clusterID === recommendation.clusterID &&
              item.workload.namespace === recommendation.namespace &&
              item.workload.controllerKind === recommendation.controllerKind &&
              item.workload.controllerName === recommendation.controllerName,
          );

          return (
            <>
              <TableRow
                className="h-12"
                onClick={() => onRowSelect(recommendation)}
              >
                <TableCell>
                  {currentAutoscalings.length && currentAutoScaling ? (
                    <Tooltip title="Auto-scaling enabled">
                      <CheckCircleIcon htmlColor="#63E892" />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Auto-scaling not enabled">
                      <ErrorIcon htmlColor="#607971" />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell className="text-left font-bold">
                  {recommendation.clusterID}
                </TableCell>
                <TableCell className="text-left">
                  {`${recommendation.namespace}\\${recommendation.controllerKind}\\${recommendation.controllerName}`}
                </TableCell>
                <TableCell className="text-left">
                  {recommendation.containerName}
                </TableCell>
                <TableCell className="w-12 text-left pr-4">
                  <div className="flex gap-1">
                    {recommendation.latestKnownRequest['memory']}
                    <ChevronRight />
                    <span className="font-bold">
                      {recommendation.recommendedRequest['memory']}
                    </span>
                  </div>
                </TableCell>
                <TableCell className="text-left pr-4">
                  <div className="flex gap-1">
                    {recommendation.latestKnownRequest['cpu']}
                    <ChevronRight />
                    <span className="font-bold">
                      {recommendation.recommendedRequest['cpu']}
                    </span>
                  </div>
                </TableCell>
                <TableCell>
                  {recommendation.currentEfficiency != null
                    ? (recommendation.currentEfficiency['total'] * 100).toFixed(
                        2,
                      ) + '%'
                    : '-'}
                </TableCell>
                <TableCell className="text-left">
                  <Chip
                    label={savingsFigure ? savingsFigure : ''}
                    color={isPositiveSavings ? 'default' : 'primary'}
                  ></Chip>
                </TableCell>
              </TableRow>
            </>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default ResultsTable;
