// react
import React from 'react';

// holster
import { Input } from '@kubecost-frontend/holster';

interface AssetsFilterSectionProps {
  updateFilter: (newText: string) => void;
}

export const AssetsFilterSection = ({
  updateFilter,
}: AssetsFilterSectionProps) => {
  return (
    <>
      <div className="mt-[43px] mb-[25px]">
        <Input
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            updateFilter(event.target.value)
          }
          placeholder="Search"
          type="text"
        />
      </div>
    </>
  );
};
