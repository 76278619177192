import { getCurrentContainerAddressModel } from '../util';

export type NodeType = {
  provider: string;
  name: string;
  vCPUs: number;
  RAMGB: number;
  hourlyPrice: number;
  spotHourlyPrice: number;
  sharedCore: boolean;
  architecture: string;
  pricePerRAMByteHr: number;
  pricePerCPUCoreHr: number;
  spotPricePerRAMByteHr: number;
  spotPricePerCPUCoreHr: number;
};

export type NodePool = {
  type: NodeType;
  count: number;
  totalMonthlyCost: number;
  totalRAMGB: number;
  totalVCPUs: number;
};

export type ClusterSize = {
  pools: NodePool[];
  nodeCount: number;
  monthlySavings: number;
  totalMonthlyCost: number;
  requiredRAMGB: number;
  totalRAMGB: number;
  utilizationRAMGB: number;
  requiredVCPUs: number;
  totalVCPUs: number;
  utilizationVCPUs: number;
};

export type WorkloadDistribution = {
  clusterId: string;
  clusterName: string;
  staticVCPUs: number;
  staticRAMGB: number;
  daemonSetVCPUs: number;
  daemonSetRAMGB: number;
  maxPodVCPUs: number;
  maxPodRAMGB: number;
};

export type ClusterPreferences = {
  minNodeCount: number;
  strategies: string[];
  targetUtilization: number;
  allowSharedCore: boolean;
  architecture: string;
};

interface Recommendations {
  [index: string]: ClusterSize;
}

export type ClusterSizingResponse = {
  recommendations: Recommendations;
  parameters: WorkloadDistribution;
  preferences: ClusterPreferences;
};

// This uses the "responseKind" field to make this type a Discriminated
// Union
//
// See https://basarat.gitbook.io/typescript/type-system/discriminated-unions for
// examples and explanations.
export type SizingResponse =
  | {
      responseKind: 'sizing';

      clusterID: string;
      spotClusterSizing: ClusterSizingResponse;
      nonSpotClusterSizing: ClusterSizingResponse;
      monthlyClusterCostsBefore: number;
      monthlyClusterCostsAfter: number;
      monthlySavings: number;
      monthlySavingsPct: number;
    }
  | {
      // Corresponds to a 204 response from the API, signifying that
      // no recommendation is possible because there are no spot-ready
      // workloads.

      responseKind: 'empty sizing';
    };

export const defaultSpotMinOnDemandNodeCount = 2;
export const defaultSpotMinNodeCount = 3;
export const defaultSpotTargetUtilization = 90 / 100;
export const defaultSpotAllowSharedCore = true;
export const defaultSpotWindow = '1d';

export async function fetchSpotChecklistClusterSizing(
  minOnDemandNodeCount: number,
  minSpotNodeCount: number,
  targetUtilization: number,
  allowSharedCore: boolean,
  window: string,
): Promise<SizingResponse | Error> {
  const parameters = {
    minOnDemandNodeCount: minOnDemandNodeCount.toString(),
    minSpotNodeCount: minSpotNodeCount.toString(),
    targetUtilization: targetUtilization.toString(),
    allowSharedCore: allowSharedCore.toString(),
    window,
  };

  const costModelURI = getCurrentContainerAddressModel();

  const response = fetch(
    `${costModelURI}/savings/spotChecklistClusterSizing?${new URLSearchParams(
      parameters,
    )}`,
  );

  return response.then((resp) => {
    return resp.text().then((raw) => {
      // This endpoint uses 204 to indicate that a recommendation could not
      // be produced because there are no spot-ready workloads to create
      // spot nodes for.
      if (resp.status === 204) {
        const result: SizingResponse = { responseKind: 'empty sizing' };
        return result;
      }
      if (resp.status === 200) {
        const parsed: SizingResponse = JSON.parse(raw);
        parsed.responseKind = 'sizing';
        return parsed;
      }
      return Error(JSON.stringify({ status: resp.status, data: raw }));
    });
  });
}

export const defaultSpotTaint = 'spot.kubecost.io=true:NoSchedule';
export const defaultSpotTolerationKey = 'spot.kubecost.io';
export const defaultSpotTolerationValue = 'true';
export const defaultSpotTolerationEffect = 'NoSchedule';

export function clusterRecommendationToGcloud(
  pool: NodePool,
  isSpot: boolean,
  taint: string,
  poolName: string,
): string {
  let command = `gcloud container node-pools create \\
    --project "$\{GKE_PROJECT}" \\
    --region "$\{GKE_REGION}" \\
    --cluster "$\{GKE_CLUSTER}" \\
    "${poolName}" \\
    --machine-type "${pool.type.name}" \\
    --num-nodes "${pool.count}"`;

  if (isSpot) {
    command += ` \\
    --preemptible \\
    --node-taints "${taint}"`;
  }

  return command;
}

// TODO: THIS FUNCTION IS NOT DONE, AUTOMATICALLY ADDING TAINTS IS TRICKY WITH EKSCTL.
export function clusterRecommendationToEksctlManagedNodeGroup(
  pool: NodePool,
  isSpot: boolean,
  taint: string,
  poolName: string,
): string {
  // TODO: AWS (eksctl) supports multiple node types in a single managed node group, which
  // could be hugely beneficial for spot
  let command = `eksctl create nodegroup \\
  --cluster <my-cluster> \\
  --region <region-code> \\
  --name "${poolName}" \\
  --node-type "${pool.type}" \\
  --nodes "${pool.count}"`;

  if (isSpot) {
    // Adding taints to EKS managed node groups:
    // with eksctl: https://github.com/aws/containers-roadmap/issues/864#issuecomment-760168725
    command += ` \\
  --spot`;
  }

  return command;
}
