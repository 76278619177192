// MUI components
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import { Link as RouteLink } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import Toolbar from '@material-ui/core/Toolbar';

// MUI icons
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';

// MUI styles
import { makeStyles } from '@material-ui/styles';

// react
import React, { useEffect, useState } from 'react';

// local
import Header from '../../components/Header';
import { FetchStates } from '../../constants';
import Logger from '../../services/logger';
import Model from '../../services/model';
import { AllocationSet } from '../../types/allocation';
import AllocationCard from './AllocationCard';
import AssetCard from './AssetCard';
import CloudCard from './CloudCard';
import EfficiencyCard from './EfficiencyCard';
import MonthlyCostsCard from './MonthlyCostsCard';
import SavingsCard from './SavingsCard';
import UtilizationCard from './UtilizationCard';
import NetworkCard from './NetworkCard';

const useStyles = makeStyles({
  toolbar: {
    justifyContent: 'flex-end',
  },
});

function Overview(): React.ReactElement {
  const classes = useStyles();

  const [clusterCount, setClusterCount] = useState(0);
  const [sufficientData, setSufficientData] = useState(false);
  const [fetchState, setFetchState] = useState(FetchStates.LOADING);
  const [timeseriesWindow, setTimeseriesWindow] = useState<'1d' | '7d'>('7d');
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Header
        breadcrumbs={[
          {
            href: '',
            name: clusterCount
              ? `Overview / ${clusterCount} cluster${
                  clusterCount > 1 ? 's' : ''
                }`
              : 'Overview',
          },
        ]}
      >
        <Toolbar className={classes.toolbar} variant="dense">
          <IconButton onClick={() => window.location.reload()}>
            <RefreshIcon />
          </IconButton>
          <Link component={RouteLink} to="/settings">
            <IconButton>
              <SettingsIcon />
            </IconButton>
          </Link>
        </Toolbar>
      </Header>
      {fetchState === FetchStates.ERROR ? (
        <Alert severity="error">
          Could not connect to Kubecost. Check console for details.
        </Alert>
      ) : (
        <></>
      )}
      {fetchState === FetchStates.DONE && !sufficientData ? (
        <Alert severity="info">
          Kubecost is collecting data. Check back in 5 minutes.
        </Alert>
      ) : (
        <></>
      )}
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} lg={4}>
          {fetchState !== FetchStates.LOADING && sufficientData ? (
            <SavingsCard />
          ) : (
            <Skeleton variant="rect" width={365} height={215} />
          )}
        </Grid>
        <Grid item xs={12} lg={4}>
          {fetchState !== FetchStates.LOADING && sufficientData ? (
            <MonthlyCostsCard />
          ) : (
            <Skeleton variant="rect" width={365} height={215} />
          )}
        </Grid>
        <Grid item xs={12} lg={4}>
          {fetchState !== FetchStates.LOADING && sufficientData ? (
            <UtilizationCard />
          ) : (
            <Skeleton variant="rect" width={365} height={215} />
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          {fetchState !== FetchStates.LOADING && sufficientData ? (
            <AllocationCard win={timeseriesWindow} />
          ) : (
            <Skeleton variant="rect" width={560} height={500} />
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          {fetchState !== FetchStates.LOADING && sufficientData ? (
            <AssetCard win={timeseriesWindow} />
          ) : (
            <Skeleton variant="rect" width={560} height={500} />
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          {fetchState !== FetchStates.LOADING && sufficientData ? (
            <EfficiencyCard win={timeseriesWindow} />
          ) : (
            <Skeleton variant="rect" width={560} height={500} />
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          {fetchState !== FetchStates.LOADING && sufficientData ? (
            <CloudCard />
          ) : (
            <Skeleton variant="rect" width={560} height={500} />
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          {fetchState !== FetchStates.LOADING && sufficientData ? (
            <NetworkCard />
          ) : (
            <Skeleton variant="rect" width={560} height={500} />
          )}
        </Grid>
      </Grid>
    </>
  );

  async function fetchData() {
    try {
      const response = await Model.getAllocationSummary('3d', 'cluster', {
        accumulate: true,
        idleByNode: false,
        shareIdle: true,
      });
      const clusters = Object.values(
        response.data.sets[0].allocations as AllocationSet,
      );
      const minutes = Math.max.apply(
        null,
        clusters.map((alloc) => Model.getSummaryMinutes(alloc)),
      );
      setClusterCount(clusters.length);
      setSufficientData(minutes > 5);
      setTimeseriesWindow(minutes < 2880 ? '1d' : '7d');
      setFetchState(FetchStates.DONE);
    } catch (err) {
      Logger.error(err);
      setFetchState(FetchStates.ERROR);
    }
  }
}

export default Overview;
