import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  styled,
} from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import get from 'lodash/get';
import React from 'react';
import { colorMap } from '../../services/colors';
import { toCurrency } from '../../services/format';

export const dataSources: DataSourceTypes = {
  allocation: [
    { name: 'name', dataType: 'string' },
    { name: 'cpuCost', dataType: 'number' },
    { name: 'gpuCost', dataType: 'number' },
    { name: 'ramCost', dataType: 'number' },
    { name: 'pvCost', dataType: 'number' },
    { name: 'networkCost', dataType: 'number' },
    { name: 'sharedCost', dataType: 'number' },
  ],
  assets: [
    { name: 'totalCost', dataType: 'number' },
    { name: 'minutes', dataType: 'number' },
    { name: 'adjustment', dataType: 'number' },
  ],
};

interface DataSourceItem {
  name: string;
  dataType: string;
}

interface DataSourceTypes {
  allocation: DataSourceItem[];
  assets: DataSourceItem[];
}
export const availableSumActions = () => {
  const sumActions: string[] = [];
  Object.keys(dataSources).forEach((dataSourceType: string) => {
    dataSources[`${dataSourceType}`].forEach(
      (item: { name: string; dataType: string }) => {
        if (item.dataType === 'number') {
          sumActions.push(`${dataSourceType}.${item.name}`);
        }
      },
    );
  });
  return sumActions;
};

export interface ActionTypeItem {
  name: string;
  key: string;
}

export const actionTypes: ActionTypeItem[] = [
  {
    name: 'Display Value',
    key: 'dataField',
  },
  {
    name: 'Sum',
    key: 'sum',
  },
  {
    name: 'Cost Efficiency',
    key: 'costEfficiency',
  },
];

export const shareSplitOptions = [
  { name: 'Share evenly', key: 'even' },
  { name: 'Share weighted by cost', key: 'weighted' },
];
export const MuiToggleButton = styled(ToggleButton)(() => ({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: colorMap.blue,
  },
}));

export enum VisualAddOnType {
  Card,
  Chart,
}

export interface VisualAddOn {
  name: string;
  type: VisualAddOnType;
  config: VisualAddOnCardConfig | VisualAddOnChartConfig;
}

export interface VisualAddOnCardConfig {
  label: string;
  dataKey: string;
  action?: string;
}

export interface VisualAddOnChartConfig {
  label: string;
  chartType: VisualAddOnType;
  dataKey: string;
  action?: string;
}

export interface ReportConfigItem {
  columnName: string;
  dataKey: string;
  action?: string;
}

export interface AssetLabelOverride {
  allocationAggregation: string; // ex: Namespace
  assetLabelOverride: string; // instead of label:kubernetes_namespace, it may be namepsace
}

export interface AllocationFilter {
  property: string;
  value: string;
}

export interface ReportConfig {
  id: number;
  lastTotal: number; // not conviced this is the best way
  name: string;
  includeAllocationData: boolean;
  includeAssetData: boolean;
  allocationAggregationBy: string;
  assetAggregateBy: string;
  assetLabelOverrides: AssetLabelOverride[];
  window: string;
  startDate: Date;
  endDate: Date;
  filtersInclude: AllocationFilter[];
  filtersExclude: AllocationFilter[];
  sharedOverheadCost: number;
  sharedNamespaces: string;
  sharedLabels: string;
  shareBy: string;
  visualAddOns: VisualAddOn[];
  dataMap: ReportConfigItem[];
}

interface DropdownValue {
  name: string;
  key: string;
}

interface DropdownControlProps {
  currentValue: string | null | undefined;
  setCurrentValue: (value: string) => void;
  valueList: DropdownValue[];
  labelText: string;
  placeholder?: string | React.ReactNode;
  disabled?: boolean;
}

const shortenDisplayText = (text: string, numChars: number) =>
  text.substring(0, 20);

// We need a central location for these.
// To be used in Allocation UI, Reporting, and Inspect
const allocationAggregationTypeOptions = [
  'namespace',
  'controller',
  'team',
  'product',
  'department',
];
export const assetAggregationOptions = [
  { name: 'Category', key: 'category' },
  { name: 'Cluster', key: 'cluster' },
  { name: 'Service', key: 'service' },
  { name: 'Type', key: 'type' },
  { name: 'Provider', key: 'provider' },
  { name: 'Account', key: 'account' },
  { name: 'Provider ID', key: 'providerid' },
];

export const allocationFilterTypes = [
  { name: 'Cluster', key: 'cluster' },
  { name: 'Node', key: 'node' },
  { name: 'Namespace', key: 'namespace' },
  { name: 'Label', key: 'label' },
  { name: 'Service', key: 'service' },
  { name: 'Controller', key: 'controller' },
  { name: 'Controller Kind', key: 'controllerKind' },
  { name: 'Pod', key: 'pod' },
];

export const dataSourceOptions = [
  { name: 'K8s Allocation', key: 'allocations' },
  { name: 'External Assets', key: 'assets' },
  { name: 'Cloud Assets', key: 'cloud' },
];

export const dateRangeOptions = ['24h', '48h', '7d', '30d'];

export const bogusAllocationEntry = {
  name: '__undefined__',
  cpuCoreRequestAverage: 0,
  cpuCoreUsageAverage: 0,
  cpuCost: 0,
  end: '2022-05-05T16:00:00Z',
  externalCost: 0,
  gpuCost: 0,
  loadBalancerCost: 0,
  networkCost: 0,
  pvCost: 0,
  ramByteRequestAverage: 0,
  ramByteUsageAverage: 0,
  ramCost: 0,
  sharedCost: 0,
  start: '2022-04-29T00:00:00Z',
};

export const handleValueFromString = (dataItem: string, dataKey: string) => {
  const rowValue = get(dataItem, `${dataKey}`);
  return typeof rowValue === 'string'
    ? rowValue === '__undefined__'
      ? '__unallocated__'
      : rowValue
    : toCurrency(rowValue, 'USD');
};

export const DropdownControl = ({
  currentValue,
  setCurrentValue,
  valueList,
  labelText,
  placeholder,
  disabled,
}: DropdownControlProps) => (
  <FormControl style={{ width: '100%' }}>
    <InputLabel>{!currentValue && placeholder}</InputLabel>
    <Select
      value={currentValue} // need to re-evaluate
      onChange={(e: any) => setCurrentValue(e.target.value)}
      disabled={disabled}
    >
      {valueList.map((item: DropdownValue) => (
        <MenuItem key={item.key} value={item.key}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
    <FormHelperText>{labelText}</FormHelperText>
  </FormControl>
);

export const getDataFromDataSetGivenColumn = (
  dataSet: any,
  addOnConfig: VisualAddOnCardConfig | VisualAddOnChartConfig,
) => {
  if (addOnConfig.action === 'sum') {
    const valuesToProcess = addOnConfig.dataKey.split(',');
    let total = 0;
    valuesToProcess.forEach((item: string) => {
      total += dataSet.reduce(
        (acc, costItem) => acc + parseFloat(get(costItem, item)),
        0,
      );
    });
    return total.toFixed(2);
  }
  return dataSet
    .reduce(
      (acc, costItem) => acc + parseFloat(get(costItem, addOnConfig.dataKey)),
      0,
    )
    .toFixed(2);
};

export const handleActionColumn = (item: any, reportItem: ReportConfigItem) => {
  // we can assume that reportItem has an action
  if (reportItem.action === 'sum') {
    // then we want to parse data key (',') and sum all
    const separateColumnsToSum = reportItem.dataKey.split(',');
    return toCurrency(
      separateColumnsToSum.reduce(
        (acc, columnDataKey) => acc + parseFloat(get(item, columnDataKey)),
        0,
      ),
      'USD',
    );
  } else if (reportItem.action === 'costEfficiency') {
    let cpuEfficiency = 0;
    if (item.allocation.cpuCoreRequestAverage > 0) {
      cpuEfficiency =
        item.allocation.cpuCoreUsageAverage /
        item.allocation.cpuCoreRequestAverage;
    } else if (item.allocation.cpuCoreUsageAverage > 0) {
      cpuEfficiency = 1;
    }
    let ramEfficiency = 0;
    if (item.allocation.ramByteRequestAverage > 0) {
      ramEfficiency =
        item.allocation.ramByteUsageAverage /
        item.allocation.ramByteRequestAverage;
    } else if (item.allocation.ramByteUsageAverage > 0) {
      ramEfficiency = 1;
    }
    const totalEfficiency =
      (item.allocation.cpuCost * cpuEfficiency +
        item.allocation.ramCost * ramEfficiency) /
      (item.allocation.cpuCost + item.allocation.ramCost);
    const formattedEfficiency = !!totalEfficiency
      ? `${(totalEfficiency * 100).toFixed(2)}%`
      : 'N/A';
    return formattedEfficiency;
  }
};

export const renderDataSourceSelector = (
  item: ReportConfigItem,
  editing: boolean,
  index: number,
  updateDataSource: (action: string, dataKey: string, index: number) => void,
) => {
  if (!editing) {
    return item.action
      ? shortenDisplayText(`${item.action}{${item.dataKey}}`, 20)
      : item.dataKey;
  } else {
    const [aggType, dataType] = item.dataKey.split('.');
    const sumActions = availableSumActions();
    if (item.action) {
      return (
        <>
          <Select
            value={item.action} // need to re-evaluate
            onChange={(e: any) => {
              updateDataSource(e.target.value, item.dataKey, index);
            }}
            style={{ width: 150 }}
          >
            {actionTypes.map(({ name, key }: ActionTypeItem) => (
              <MenuItem value={key}>{name}</MenuItem>
            ))}
          </Select>
          {item.action === 'sum' ? (
            <Select
              value={item.dataKey.split(',')} // need to re-evaluate
              multiple
              onChange={(e: any) => {
                updateDataSource('sum', e.target.value.toString(), index);
              }}
              style={{ width: 150 }}
            >
              {sumActions.map((item: string) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          ) : (
            <div>pre-configured cost weight</div>
          )}
        </>
      );
    } else {
      return (
        <>
          <Select
            value="dataField"
            onChange={(e: any) =>
              updateDataSource(e.target.value, item.dataKey, index)
            }
            style={{ width: 150 }}
          >
            {actionTypes.map(({ name, key }: ActionTypeItem) => (
              <MenuItem value={key}>{name}</MenuItem>
            ))}
          </Select>
          <Select
            value={aggType}
            onChange={(e: any) =>
              updateDataSource(
                'dataField',
                `${e.target.value}.${item.dataKey.split('.')[1]}`,
                index,
              )
            }
            style={{ width: 150 }}
          >
            {Object.keys(dataSources).map((item: string) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
          <Select
            value={dataType}
            onChange={(e: any) =>
              updateDataSource(
                'dataField',
                `${item.dataKey.split('.')[0]}.${e.target.value}`,
                index,
              )
            }
            style={{ width: 150 }}
          >
            {dataSources[`${aggType}`].map(
              (item: { name: string; dataType: string }) => (
                <MenuItem value={item.name}>{item.name}</MenuItem>
              ),
            )}
          </Select>
        </>
      );
    }
  }
};
