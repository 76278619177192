import { useQueryClient, useQuery } from 'react-query';

import model from '../services/model';
import { APICloudCostParamType } from '../types/cloudCosts';

const useCloudCosts = (
  queryWindow: string,
  aggregate: APICloudCostParamType[],
) => {
  const queryKey = ['cloudCosts', { aggregate, window: queryWindow }];

  const client = useQueryClient();
  const query = useQuery(queryKey, () =>
    model.getCloudCosts(queryWindow, aggregate),
  );

  const invalidate = () => {
    client.invalidateQueries(queryKey);
  };

  return { invalidate, ...query };
};

export { useCloudCosts };
