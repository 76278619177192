// react
import React, { useState } from 'react';

// holster
import {
  FormControlLabel,
  Select,
  Typography,
} from '@kubecost-frontend/holster';

// local
import {
  Alert,
  AlertTypes,
  BudgetAlert,
  ClusterHealthAlert,
  DiagnosticAlert,
  EfficiencyAlert,
  RecurringUpdate,
  SpendChangeAlert,
} from '../../services/alerts';

import If from '../If';
import BudgetAlertsTable from './BudgetAlertsTable';
import ClusterHealthAlertsTable from './ClusterHealthAlertsTable';
import DiagnosticAlertsTable from './DiagnosticAlertsTable';
import EfficiencyAlertsTable from './EfficiencyAlertsTable';
import RecurringAlertsTable from './RecurringAlertsTable';
import SpendChangeAlertsTable from './SpendChangeAlertsTable';

function AlertsTable({
  alerts,
  alertTypeOpts,
  createAlert,
  removeAlert,
}: ComponentProps): React.ReactElement {
  const [alertType, setAlertType] = useState(alertTypeOpts[0]);

  return (
    <>
      {/* select alert type to work with */}
      <section className="my-4">
        <FormControlLabel>Alert Type</FormControlLabel>
        <Select
          data-test="alerts-table-selector"
          options={alertTypeOpts.map(({ label, value }) => ({
            value,
            label: `${label} (${
              alerts.filter((a) => a.type === value).length
            })`,
          }))}
          value={alertType.value}
          setValue={(e) => {
            const chosen = alertTypeOpts.find((ato) => ato.value === e);
            if (chosen) {
              setAlertType(chosen);
            }
          }}
        />
      </section>

      {/* blurb explaining the chosen alert type */}
      <If condition={!!alertType.explanation}>
        <Typography variant="p">{alertType.explanation}</Typography>
      </If>

      {/* table showing alerts of the selected type */}
      {getAlertsTable()}
    </>
  );

  function getAlertsTable() {
    switch (alertType.value) {
      case AlertTypes.Budget:
        return (
          <BudgetAlertsTable
            alerts={
              alerts.filter((a) => a.type === alertType.value) as BudgetAlert[]
            }
            createAlert={createAlert}
            removeAlert={removeAlert}
            locked={alertType.lockedFields}
          />
        );
      case AlertTypes.Health:
        return (
          <ClusterHealthAlertsTable
            alerts={
              alerts.filter(
                (a) => a.type === alertType.value,
              ) as ClusterHealthAlert[]
            }
            createAlert={createAlert}
            removeAlert={removeAlert}
            locked={alertType.lockedFields}
          />
        );
      case AlertTypes.Diagnostic:
        return (
          <DiagnosticAlertsTable
            alerts={
              alerts.filter(
                (a) => a.type === alertType.value,
              ) as DiagnosticAlert[]
            }
            createAlert={createAlert}
            removeAlert={removeAlert}
            locked={alertType.lockedFields}
          />
        );
      case AlertTypes.Efficiency:
        return (
          <EfficiencyAlertsTable
            alerts={
              alerts.filter(
                (a) => a.type === alertType.value,
              ) as EfficiencyAlert[]
            }
            createAlert={createAlert}
            removeAlert={removeAlert}
            locked={alertType.lockedFields}
          />
        );
      case AlertTypes.Recurring:
        return (
          <RecurringAlertsTable
            alerts={
              alerts.filter(
                (a) => a.type === alertType.value,
              ) as RecurringUpdate[]
            }
            createAlert={createAlert}
            removeAlert={removeAlert}
            locked={alertType.lockedFields}
          />
        );
      case AlertTypes.SpendChange:
        return (
          <SpendChangeAlertsTable
            alerts={
              alerts.filter(
                (a) => a.type === alertType.value,
              ) as SpendChangeAlert[]
            }
            createAlert={createAlert}
            removeAlert={removeAlert}
            locked={alertType.lockedFields}
          />
        );
      default:
        return <></>;
    }
  }
}

export default React.memo(AlertsTable);

interface ComponentProps {
  alerts: Alert[];
  alertTypeOpts: AlertTypeOpt[];
  createAlert: (arg0: Alert) => void;
  removeAlert: (arg0: Alert) => void;
}

interface AlertTypeOpt {
  label: string;
  value: AlertTypes;
  explanation?: React.ReactElement[] | React.ReactElement | string;
  lockedFields?: {
    window?: string;
    aggregate?: string;
    threshold?: number;
    baseline?: string;
    effThresh?: number;
    spendThresh?: number;
  };
}
