import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DiagnosticsChecker from '../../components/DiagnosticsChecker';
import Header from '../../components/Header';
import Page from '../../components/Page';
import RefreshIcon from '@material-ui/icons/Refresh';
import Link from '@material-ui/core/Link';
import SettingsIcon from '@material-ui/icons/Settings';
import {
  DropdownControl,
  ReportConfig,
} from '../AdvancedReportingDetail/reportingHelpers';
import {
  sampleReportConfig,
  templateReportConfig,
} from '../AdvancedReportingDetail/sampleReportPayload';
import { CreateNewReportModal } from './CreateNewReportModal';
import { ReportCardOverflowMenu } from './ReportCardOverflowMenu';
import { HelpIconLink } from '../../components/HelpIcon';
import { useNavigate } from 'react-router-dom';
import { colorMap } from '../../services/colors';

export const advancedReportingLocalStorageKey = 'QBCostReports';

const dateCreatedValueList = [
  { name: 'All', key: 'all' },
  { name: 'Today', key: 'today' },
  { name: 'Yesterday', key: 'yesterday' },
  { name: 'This Week', key: 'thisweek' },
  { name: 'Last Week', key: 'lastweek' },
];

function AdvancedReporting() {
  const [currentReportList, setCurrentReportList] = useState<ReportConfig[]>(
    [],
  );
  const [dateCreatedFilter, setDateCreatedFilter] = useState<string>();
  const [newReportModalOpen, setNewReportModalOpen] = useState<boolean>(false);
  const openNewReportModal = () => {
    setNewReportModalOpen(true);
  };
  const navigate = useNavigate();

  const newReportFromTemplate = (templateName: string) => {
    const existingReports = localStorage.getItem(
      advancedReportingLocalStorageKey,
    );
    if (!existingReports) {
      // if none exist, we have a problem, should at least be an empty array
      console.log('no reports');
    } else if (JSON.parse(existingReports).length === 0) {
      const reportToUse = sampleReportConfig;
      // TEMP until we get good templates
      reportToUse.name = templateName;
      localStorage.setItem(
        advancedReportingLocalStorageKey,
        JSON.stringify([reportToUse]),
      );
      setCurrentReportList([reportToUse]);
      setNewReportModalOpen(false);
      handleExistingReportNavigation(reportToUse.id);
    } else {
      // we want to add to our list of reports
      const storedReports = JSON.parse(existingReports);
      const reportToUse = sampleReportConfig;
      // TEMP until we get good templates
      reportToUse.name = templateName;
      reportToUse.id = storedReports[storedReports.length - 1].id + 1;
      storedReports.push(reportToUse);
      localStorage.setItem(
        advancedReportingLocalStorageKey,
        JSON.stringify(storedReports),
      );
      setCurrentReportList(storedReports);
      setNewReportModalOpen(false);
      handleExistingReportNavigation(reportToUse.id);
    }
  };

  const handleExistingReportNavigation = (reportId: number) => {
    navigate(`/advanced-reporting-detail?id=${reportId}`);
  };

  const deleteReport = (reportId: number) => {
    const existingReports = localStorage.getItem(
      advancedReportingLocalStorageKey,
    );
    if (!existingReports) {
      // if none exist, then we have a problem
      // throw error
      console.log('no reports');
    } else {
      // we want to add to our list of reports
      const storedReports = JSON.parse(existingReports);
      const filteredReports = storedReports.filter(
        (item: ReportConfig) => item.id !== reportId,
      );
      localStorage.setItem(
        advancedReportingLocalStorageKey,
        JSON.stringify(filteredReports),
      );
      setCurrentReportList(filteredReports);
    }
  };

  // It would be nice to have this for v1
  const ReportCard = ({ reportItem }: { reportItem: ReportConfig }) => (
    <Grid key={reportItem.id} item xs={3} style={{ margin: '1em' }}>
      <Card
        style={{
          cursor: 'pointer',
        }}
        onClick={() => handleExistingReportNavigation(reportItem.id)}
      >
        <CardContent>
          <Grid container justifyContent="space-between">
            <Grid item xs>
              <Typography style={{ fontSize: 24 }}>
                {reportItem.name}
              </Typography>
              <Typography style={{ fontSize: 48 }}>
                $XXX.XK
              </Typography>
            </Grid>
            <Grid item style={{ width: 35 }}>
              <ReportCardOverflowMenu
                deleteItem={() => deleteReport(reportItem.id)}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );

  const handleLocaleStorageExtraction = () => {
    const existingReports = localStorage.getItem(
      advancedReportingLocalStorageKey,
    );
    if (!existingReports) {
      // if none exist, then we want to pre-load our sample
      localStorage.setItem(
        advancedReportingLocalStorageKey,
        JSON.stringify([sampleReportConfig]),
      );
      setCurrentReportList([sampleReportConfig]);
    } else {
      setCurrentReportList(JSON.parse(existingReports));
    }
  };

  useEffect(() => {
    // we want to extract the values currently in localStorage,
    // if none exist, we want to stick in a sample for sake of a POC
    handleLocaleStorageExtraction();
  }, []);

  return (
    <>
      <Header
        breadcrumbs={[
          { name: 'Advanced Report Manager', href: 'advanced-reporting.html' },
        ]}
      >
        <IconButton
          aria-label="refresh"
          onClick={() => console.log('refreshing')}
        >
          <RefreshIcon />
        </IconButton>
        <DiagnosticsChecker />
        <Link href="settings.html">
          <IconButton aria-label="refresh">
            <SettingsIcon />
          </IconButton>
        </Link>
        <HelpIconLink
          href="https://guide.kubecost.com/hc/en-us/articles/5991192077079-Advanced-Reporting"
          tooltipText="AR Documentation"
        />
      </Header>
      <Paper style={{ padding: '2em', minHeight: '75vh' }}>
        <CreateNewReportModal
          createNewReportFromTemplate={newReportFromTemplate}
          isOpen={newReportModalOpen}
          setIsOpen={() => setNewReportModalOpen(false)}
        />
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 150px',
            marginBottom: '2em',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" component="div">
            Saved Reports
          </Typography>
          {/* <FormControl style={{ width: '100%' }}>
            <InputLabel>Date Created</InputLabel>
            <Select
              style={{ marginRight: '2em' }}
              onChange={(e: any) => setDateCreatedFilter(e.target.value)}
            >
              {dateCreatedValueList.map((item: any) => (
                <MenuItem key={item.key} value={item.key}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Filters</FormHelperText>
          </FormControl> */}
          <Button
            variant="contained"
            size="medium"
            onClick={openNewReportModal}
            style={{ height: 40, backgroundColor: colorMap.blue, color: 'white'  }}
          >
            Create Report
          </Button>
        </div>
        <Grid style={{ padding: '2em' }} container>
          {currentReportList.map((item: ReportConfig) => (
            <ReportCard reportItem={item} />
          ))}
        </Grid>
      </Paper>
    </>
  );
}

export default React.memo(AdvancedReporting);
