// react
import React from 'react';

// lodash
import capitalize from 'lodash/capitalize';

// mui
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

// holster
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from '@kubecost-frontend/holster';

interface Alert {
  type: string;
  window: string;
  aggregation: string;
  filter: string;
  id: string;
}

interface ComponentProps {
  alerts: Alert[];
  deleteAlert?: (alert: Alert) => void;
  editAlert?: (alert: Alert) => void;
  testAlert?: (alert: Alert) => Promise<void>;
  onRowClick?: (row: Alert) => void;
}

function AlertsList({
  alerts,
  deleteAlert,
  editAlert,
  testAlert,
  onRowClick,
}: ComponentProps): JSX.Element {
  return (
    <Table data-test="alerts-list" style={{ width: '100%' }}>
      <TableHead>
        <TableRow>
          <TableHeadCell>Type</TableHeadCell>
          <TableHeadCell>Window</TableHeadCell>
          <TableHeadCell>Aggregation</TableHeadCell>
          <TableHeadCell>Filter</TableHeadCell>
          {editAlert && <TableHeadCell>Edit</TableHeadCell>}
          {testAlert && <TableHeadCell>Test</TableHeadCell>}
          {deleteAlert && <TableHeadCell>Delete</TableHeadCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        {alerts.map((alert) => (
          <TableRow
            onClick={() => onRowClick && onRowClick(alert)}
            key={alert.id}
          >
            <TableCell>{formatType(alert.type)}</TableCell>
            <TableCell>{alert.window}</TableCell>
            <TableCell>{alert.aggregation}</TableCell>
            <TableCell>{alert.filter}</TableCell>
            {editAlert && (
              <TableCell onClick={() => editAlert(alert)}>
                <EditIcon style={{ cursor: 'pointer' }} />
              </TableCell>
            )}
            {testAlert && (
              <TableCell onClick={() => testAlert(alert)}>
                <SendIcon style={{ cursor: 'pointer' }} />
              </TableCell>
            )}
            {deleteAlert && (
              <TableCell
                data-test="delete-alert"
                onClick={() => deleteAlert(alert)}
              >
                <DeleteIcon style={{ cursor: 'pointer' }} />
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

AlertsList.defaultProps = {
  deleteAlert: undefined,
  editAlert: undefined,
  testAlert: undefined,
  onRowClick: undefined,
};

/**
 *
 * @param type The Alert type to format for display.
 * @returns The words of the type, separated and capitalized.
 *
 * @remarks
 * Example: spendChange -> Spend Change
 */
function formatType(type: string): string {
  return type
    .split(/(?=[A-Z])/)
    .map(capitalize)
    .join(' ');
}

export default AlertsList;
