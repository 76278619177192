import { Card, Typography, Divider, IconButton } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { makeStyles } from '@material-ui/styles';
import map from 'lodash/map';
import React, { useState } from 'react';
import { ETLCloudStatus } from '../../../services/diagnostics';
import CloudIntegrationDetailDialog from './CloudIntegrationDetailDialog';
import CloudIntegrationEmptyState from './CloudIntegrationEmptyState';
import CloudIntegrationRow from './CloudIntegrationRow';

const KubecostETLCloudDoc =
  'https://github.com/kubecost/docs/blob/master/diagnostics.md#kubecost-etl-pipeline-cloud-metrics';

const useStyles = makeStyles({
  card: {
    paddingTop: '1em',
  },
  headerRow: {
    width: '100%',
    display: 'inline-flex',
    gap: '1em',
  },
  header: {
    marginBottom: '1em',
    paddingLeft: '1em',
  },
  helpButton: {
    flexGrow: 1,
    padding: 0,
    paddingTop: '4px',
  },
});

export type CloudIntegrationStatusProps = {
  etlCloudStatus?: { [key: string]: ETLCloudStatus };
};

const CloudIntegrationStatusPanel = ({
  etlCloudStatus,
}: CloudIntegrationStatusProps) => {
  const classes = useStyles();
  const [selectedCloudProvider, setSelectedCloudProvider] =
    useState<ETLCloudStatus | null>(null);

  return (
    <Card variant="outlined" className={classes.card}>
      <div className={classes.headerRow}>
        <Typography
          data-testid="ci-header"
          className={classes.header}
          variant="h6"
        >
          Cloud Integrations
        </Typography>

        <div>
          <IconButton
            className={classes.helpButton}
            target="_blank"
            href={KubecostETLCloudDoc}
            data-testid="ci-help-button"
          >
            <HelpIcon />
          </IconButton>
        </div>
      </div>

      <Divider />

      {!etlCloudStatus && <CloudIntegrationEmptyState />}

      {map(
        etlCloudStatus,
        (cloudStatus: ETLCloudStatus, providerKey: string) => {
          if (
            providerKey === '' &&
            cloudStatus.cloudConnectionStatus === 'Missing Configuration'
          ) {
            return;
          }

          if (
            providerKey === '_' &&
            cloudStatus.cloudConnectionStatus === 'Incomplete Configuration'
          ) {
            return;
          }

          const key = providerKey || 'Cloud Provider';

          return (
            <span key={key}>
              <CloudIntegrationRow
                key={key}
                onClick={() => setSelectedCloudProvider(cloudStatus)}
                cloudStatus={cloudStatus}
                providerKey={key}
              />
            </span>
          );
        },
      )}

      <CloudIntegrationDetailDialog
        open={selectedCloudProvider != null}
        onClose={() => setSelectedCloudProvider(null)}
        cloudProvider={selectedCloudProvider}
      />
    </Card>
  );
};

export default CloudIntegrationStatusPanel;
