import { Grid, Tooltip } from '@material-ui/core';
import React, { useState, useEffect, useCallback, FC } from 'react';

import { Header } from '../../components/Header2New';
import { FetchStates } from '../../constants';
import usePersistentVolumeSizing from '../../hooks/usePersistentVolumeSizing';
import { toCurrency } from '../../services/format';
import logger from '../../services/logger';
import { defaultHeadroom } from '../PersistentVolumeSizing/PersistentVolumeSizing';
import {
  Api,
  ClusterTotals,
  NamespaceTotals,
  NetworkTotals,
  ProviderAccount,
  ProviderService,
} from './api';
import { GraphCard } from './GraphCard';
import { HighlightCard } from './HighlightCard';
import { SectionBreak } from './SectionBreak';
import { TableCard } from './TableCard';

const defaultNumDays = 7;
const server = new Api();

const Overview: FC = () => {
  const [configsFetchState, setConfigsFetchState] = React.useState(
    FetchStates.LOADING,
  );
  const [countsLoading, setCountsLoading] = React.useState(FetchStates.LOADING);
  const [costsLoading, setCostsLoading] = React.useState(FetchStates.LOADING);
  const [assetFetchState, setAssetFetchState] = React.useState(
    FetchStates.LOADING,
  );
  const [savingsFetchState, setSavingsFetchState] = React.useState(
    FetchStates.LOADING,
  );

  const [currency, setCurrency] = useState('USD');
  const [k8sCosts, setK8sCosts] = useState(0);
  const [k8sCostTrend, setK8sCostTrend] = useState(0);
  const [totalCostTrend, setTotalCostTrend] = useState(0);
  const [clusterCount, setClusterCount] = useState(0);
  const [cloudCosts, setCloudCosts] = useState(0);
  const [cloudCostsTrend, setCloudCostsTrend] = useState(0);
  const [providerCount, setProviderCount] = useState(0);
  const [clusters, setClusters] = useState<ClusterTotals[]>([]);
  const [namespaces, setNamespaces] = useState<NamespaceTotals[]>([]);
  const [cloudServices, setCloudServices] = useState<ProviderService[]>([]);
  const [providers, setProviders] = useState<ProviderAccount[]>([]);
  const [allNamespaces, setAllNamespaces] = useState<string[]>([]);
  const [networkTotals, setNetworkTotals] = useState<NetworkTotals[]>([]);
  const [allClusters, setAllClusters] = useState<string[]>([]);
  const [allSavings, setAllSavings] = useState(0);
  const [savingsInitialized, setSavingsInitialized] = useState(false);
  const [totalEfficiency, setTotalEfficiency] = useState(0);
  const [totalEfficienyTrend, setTotalEfficiencyTrend] = useState(0);
  const { isLoading: pvIsLoading, pvTotalSavings } =
    usePersistentVolumeSizing(defaultHeadroom);
  const [namespaceDailyCostData, setNamespaceDailyCostData] = useState<
    Record<string, number | string>[]
  >([]);
  const [namespaceDailyCountData, setNamespaceDailyCountData] = useState<
    Record<string, number | string>[]
  >([]);
  const [clusterDailyCostData, setClusterDailyCostData] = useState<
    Record<string, number | string>[]
  >([]);
  const [clusterDailyCountData, setClusterDailyCountData] = useState<
    Record<string, number | string>[]
  >([]);

  const refreshCallback = useCallback(() => {
    setConfigsFetchState(FetchStates.LOADING);
    setCountsLoading(FetchStates.LOADING);
    setCostsLoading(FetchStates.LOADING);
    setAssetFetchState(FetchStates.LOADING);
    setSavingsFetchState(FetchStates.LOADING);
  }, []);

  // fetch configs
  useEffect(() => {
    if (configsFetchState !== FetchStates.LOADING) {
      return;
    }
    server
      .initConfigs()
      .then(() => {
        setCurrency(server.currency);
        setConfigsFetchState(FetchStates.DONE);
      })
      .catch((err) => {
        logger.error(err);
        setConfigsFetchState(FetchStates.ERROR);
      });
  }, [configsFetchState]);

  // fetch allocations
  useEffect(() => {
    let costCounter = 0;
    let countCounter = 0;
    if (
      configsFetchState !== FetchStates.DONE ||
      countsLoading !== FetchStates.LOADING ||
      costsLoading !== FetchStates.LOADING
    ) {
      return;
    }
    server.initAllocations(
      defaultNumDays,
      () => {
        countCounter += 1;
        if (countCounter === 7) {
          setAllClusters(Array.from(server.allClusters));
          setAllNamespaces(Array.from(server.allNamespaces));
          setClusters(
            Object.values(server.clusterTotals).sort((a, b) =>
              a.cost > b.cost ? -1 : 1,
            ),
          );
          setNamespaces(
            Object.values(server.namespaceTotals).sort((a, b) =>
              a.cost > b.cost ? -1 : 1,
            ),
          );
          setClusterDailyCountData(server.clusterDailyCounts);
          setNamespaceDailyCountData(server.namespaceDailyCounts);
          setCountsLoading(FetchStates.DONE);
        }
      },
      () => {
        costCounter += 1;
        if (costCounter === 16) {
          const diff =
            (server.kubernetesCostsNew / server.kubernetesCostsOld) * 100 - 100;
          const totalCostsDiff =
            ((server.kubernetesCostsNew + server.cloudCostsNew) /
              (server.kubernetesCostsOld + server.cloudCostsOld)) *
              100 -
            100;
          setK8sCostTrend(diff);
          setTotalCostTrend(totalCostsDiff);
          setAllNamespaces(Array.from(server.allNamespaces));
          setAllClusters(Array.from(server.allClusters));
          setTotalEfficiency(server.totalEfficiency);
          const efficiencyDiff =
            (server.totalEfficiencyNew / server.totalEfficiencyOld) * 100 - 100;
          setTotalEfficiencyTrend(efficiencyDiff);
          setK8sCosts(server.kubernetesCosts);
          setAllClusters(Array.from(server.allClusters));
          setAllNamespaces(Array.from(server.allNamespaces));
          setTotalEfficiency(server.totalEfficiency);
          setClusters(
            Object.values(server.clusterTotals).sort((a, b) =>
              a.cost > b.cost ? -1 : 1,
            ),
          );
          setNamespaces(
            Object.values(server.namespaceTotals).sort((a, b) =>
              a.cost > b.cost ? -1 : 1,
            ),
          );
          setNetworkTotals(
            Object.values(server.namespaceNetworkCosts)
              .filter((a) => a.cost > 0.01)
              .sort((a, b) => (a.cost > b.cost ? -1 : 1)),
          );
          setClusterDailyCostData(server.clusterDailyCosts);
          setNamespaceDailyCostData(server.namespaceDailyCosts);
          setCostsLoading(FetchStates.DONE);
        }
      },
    );
  }, [configsFetchState, costsLoading, countsLoading]);

  // fetch assets
  useEffect(() => {
    if (assetFetchState !== FetchStates.LOADING) {
      return;
    }
    server
      .initAssets(defaultNumDays)
      .then(() => {
        setCloudCosts(server.cloudCosts);
        const diff = (server.cloudCostsNew / server.cloudCostsOld) * 100 - 100;
        const totalCostsDiff =
          ((server.cloudCostsNew + server.kubernetesCostsNew) /
            (server.cloudCostsOld + server.kubernetesCostsOld)) *
            100 -
          100;
        setCloudCostsTrend(diff);
        setClusterCount(server.clusterCount);
        setProviders(server.providers);
        setProviderCount(server.providerCount);
        setCloudServices(server.cloudServices);
        setTotalCostTrend(totalCostsDiff);
        setAssetFetchState(FetchStates.DONE);
      })
      .catch((err) => {
        logger.error(err);
        setAssetFetchState(FetchStates.ERROR);
      });
  }, [assetFetchState]);

  // fetch savings data
  useEffect(() => {
    if (savingsFetchState !== FetchStates.LOADING) {
      return;
    }

    server
      .initSavings()
      .then(() => {
        setAllSavings(server.totalSavings);
        setSavingsInitialized(true);
        setSavingsFetchState(FetchStates.DONE);
      })
      .catch((err) => {
        logger.error(err);
        setSavingsInitialized(true);
        setSavingsFetchState(FetchStates.ERROR);
      });
  }, [savingsFetchState]);

  // check for network daemonset
  useEffect(() => {
    server.networkCheck();
  }, []);

  const clusterTableRows = clusters.map((c) => ({
    ...c,
    totalCost: toCurrency(c.cost, currency, 2, false),
  }));
  const accountTableRows = providers.map((provider) => ({
    ...provider,
    totalCost: toCurrency(provider.totalCost, currency, 2, false),
    provider: provider.provider,
  }));
  const cloudTableRows = cloudServices.map((cs) => ({
    ...cs,
    totalCost: toCurrency(cs.totalCost, currency, 2, false),
  }));

  return (
    <>
      <Header
        refreshCallback={refreshCallback}
        title={
          <>
            Overview{' '}
            <div style={{ fontSize: '0.5em' }}>Last {defaultNumDays} Days</div>
          </>
        }
      />

      <section
        className={
          'border-kc-gray-100 border-l border-t grid grid-cols-[repeat(auto-fit,_minmax(14rem,_auto))]'
        }
      >
        <HighlightCard
          loading={
            costsLoading === FetchStates.LOADING ||
            assetFetchState === FetchStates.LOADING
          }
          header="Kubernetes costs"
          content={toCurrency(k8sCosts, currency, 2, true)}
          footer={`Including ${clusterCount} ${
            clusterCount > 1 ? 'clusters' : 'cluster'
          }`}
          trendIndicator={k8sCostTrend.toFixed(2)}
          arrowDirection={k8sCostTrend > 0 ? 'UP' : 'DOWN'}
          isEfficiency={false}
          href={`allocations?window=${defaultNumDays}d`}
        />
        <HighlightCard
          loading={assetFetchState === FetchStates.LOADING}
          header="Cloud costs"
          content={toCurrency(cloudCosts, currency, 2, true)}
          footer={`Including ${providerCount} cloud ${
            providerCount > 1 ? 'providers' : 'provider'
          }`}
          trendIndicator={cloudCostsTrend.toFixed(2)}
          isEfficiency={false}
          arrowDirection={cloudCostsTrend > 0 ? 'UP' : 'DOWN'}
          href={`assets?window=${defaultNumDays}d&agg=service&filters=${encodeURIComponent(
            btoa(JSON.stringify([{ property: 'type', value: 'cloud' }])),
          )}`}
        />
        <HighlightCard
          loading={
            costsLoading === FetchStates.LOADING ||
            assetFetchState === FetchStates.LOADING
          }
          header="Total costs"
          content={toCurrency(k8sCosts + cloudCosts, currency, 2, true)}
          trendIndicator={totalCostTrend.toFixed(2)}
          isEfficiency={false}
          arrowDirection={totalCostTrend > 0 ? 'UP' : 'DOWN'}
          footer="Combined Kubernetes and Cloud costs"
          href={`assets?window=${defaultNumDays}d`}
        />
        <HighlightCard
          loading={savingsFetchState === FetchStates.LOADING || pvIsLoading}
          header="Possible savings"
          content={
            savingsInitialized &&
            toCurrency(allSavings + pvTotalSavings * 0.65, currency, 2, true)
          }
          footer="See Recommendations"
          href="savings"
        />
        {totalEfficiency >= 0 && totalEfficiency <= 100 ? (
          <HighlightCard
            loading={
              costsLoading === FetchStates.LOADING ||
              assetFetchState === FetchStates.LOADING
            }
            header="Efficiency"
            content={`${totalEfficiency.toFixed(1)}%`}
            trendIndicator={totalEfficienyTrend.toFixed(2)}
            arrowDirection={totalEfficienyTrend > 0 ? 'UP' : 'DOWN'}
            isEfficiency={true}
            footer={`Including ${clusterCount} ${
              clusterCount > 1 ? 'clusters' : 'cluster'
            }`}
            href={`allocations?window=${defaultNumDays}d&agg=namespace&chartDisplay=series&idle=shareByCluster`}
          />
        ) : (
          <Tooltip title="More data is required to report a reliable efficiency number. Try back in a few minutes.">
            <div style={{ height: '100%', width: '100%' }}>
              <HighlightCard
                loading={
                  costsLoading === FetchStates.LOADING ||
                  assetFetchState === FetchStates.LOADING
                }
                header="Total Efficiency"
                content="..."
                footer={`Including ${clusterCount} ${
                  clusterCount > 1 ? 'clusters' : 'cluster'
                }`}
                href={`allocations?window=${defaultNumDays}d&agg=namespace&chartDisplay=series&idle=shareByCluster`}
              />
            </div>
          </Tooltip>
        )}
      </section>
      <SectionBreak title="Clusters" />
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} lg={6}>
          <TableCard
            loading={costsLoading === FetchStates.LOADING}
            title="Cluster breakdown"
            emptySubhead="Click Here to learn how to add more!"
            emptyTitle="Have more clusters?"
            emptyHref="https://guide.kubecost.com/hc/en-us/articles/4407595970711-Multi-cluster"
            emptyLinkStyle={{ textDecorationColor: 'rgba(0, 0, 0, 0.5)' }}
            emptyCondition={clusterTableRows.length < 2}
            columns={[
              { name: 'CLUSTER', prop: 'name' },
              { name: 'COST', prop: 'totalCost' },
            ]}
            rows={clusterTableRows}
            rowHref={(row) =>
              `allocations?window=${defaultNumDays}d&context=${encodeURIComponent(
                btoa(
                  JSON.stringify([{ property: 'cluster', value: row.name }]),
                ),
              )}&idle=separate&agg=namespace`
            }
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <GraphCard
            loading={costsLoading === FetchStates.LOADING}
            colorKeys={allClusters}
            data={{
              count: clusterDailyCountData,
              cost: clusterDailyCostData,
            }}
            selectOpts={[{ display: 'Total Cost', value: 'cost' }]}
            title="Cluster trends"
          />
        </Grid>
      </Grid>
      <SectionBreak title="Namespaces" />
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} lg={6}>
          <TableCard
            title="Namespace breakdown"
            loading={costsLoading === FetchStates.LOADING}
            columns={[
              { name: 'CLUSTER', prop: 'cluster' },
              { name: 'NAMESPACE', prop: 'namespace' },
              { name: 'COST', prop: 'totalCost' },
            ]}
            rows={namespaces.map((ns) => ({
              ...ns,
              totalCost: toCurrency(ns.cost, currency, 2, false),
            }))}
            rowHref={(row) =>
              `allocations?window=${defaultNumDays}d&filters=${encodeURIComponent(
                btoa(
                  JSON.stringify([
                    { property: 'cluster', value: row.cluster },
                    { property: 'namespace', value: row.namespace },
                  ]),
                ),
              )}&idle=shareByCluster&agg=controller`
            }
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <GraphCard
            loading={costsLoading === FetchStates.LOADING}
            colorKeys={allNamespaces}
            data={{
              cost: namespaceDailyCostData,
              count: namespaceDailyCountData,
            }}
            selectOpts={[
              { display: 'Total Cost', value: 'cost' },
              { display: '# of Clusters', value: 'count' },
            ]}
            title="Namespace trends"
          />
        </Grid>
      </Grid>
      <SectionBreak title="Cloud Costs Breakdown" />
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} lg={6}>
          <TableCard
            loading={assetFetchState === FetchStates.LOADING}
            title="Accounts"
            emptyTitle="Have more providers?"
            emptySubhead="Click here to learn how to add more!"
            emptyHref="https://guide.kubecost.com/hc/en-us/articles/4407595968919-Setting-Up-Cloud-Integrations"
            emptyCondition={accountTableRows.length < 2}
            columns={[
              { name: 'PROVIDER', prop: 'provider' },
              { name: 'ACCOUNT', prop: 'account' },
              { name: 'COST', prop: 'totalCost' },
            ]}
            rows={accountTableRows}
            rowHref={(row) => {
              if (row.account === '__undefined__') {
                return '';
              }
              const context = [
                { property: 'provider', value: row.provider },
                { property: 'account', value: row.account },
              ];
              return `assets.html?window=${defaultNumDays}d&agg=unaggregated&context=${encodeURIComponent(
                btoa(JSON.stringify(context)),
              )}`;
            }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TableCard
            loading={assetFetchState === FetchStates.LOADING}
            title="Services"
            columns={[
              { name: 'PROVIDER', prop: 'provider' },
              { name: 'SERVICE', prop: 'service' },
              { name: 'COST', prop: 'totalCost' },
            ]}
            rows={cloudTableRows}
            rowHref={(row) =>
              `assets.html?window=${defaultNumDays}d&context=${encodeURIComponent(
                btoa(
                  JSON.stringify([
                    { property: 'provider', value: row.provider },
                    { property: 'service', value: row.service },
                  ]),
                ),
              )}&agg=unaggregated`
            }
            emptyTitle="Have more providers?"
            emptySubhead="Click here to learn how to add more!"
            emptyHref="https://guide.kubecost.com/hc/en-us/articles/4407595968919-Setting-Up-Cloud-Integrations"
            emptyCondition={cloudTableRows.length < 2}
          />
        </Grid>
      </Grid>
      <SectionBreak title="Network Costs Breakdown" />
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} lg={6}>
          <TableCard
            title="Namespace breakdown"
            loading={
              costsLoading === FetchStates.LOADING ||
              !server.networkCheckComplete
            }
            columns={[
              { name: 'NAMESPACE', prop: 'namespace' },
              { name: 'COST', prop: 'networkCost' },
            ]}
            rows={
              server.networkDaemonsetNotActive || !networkTotals.length
                ? []
                : networkTotals.map((ns) => ({
                    ...ns,
                    networkCost: toCurrency(ns.cost, currency, 2, false),
                  }))
            }
            rowHref={(row) =>
              `/network.html?window=${defaultNumDays}d&ns=${row.namespace}`
            }
            emptyTitle={
              server.networkDaemonsetNotActive
                ? 'Set up the Network Costs Daemonset to see data'
                : 'No namespace network costs recorded yet'
            }
            emptySubhead={server.networkDaemonsetNotActive ? 'Learn more' : ''}
            emptyHref={
              server.networkDaemonsetNotActive
                ? 'https://guide.kubecost.com/hc/en-us/articles/4407595973527'
                : ''
            }
            emptyCondition={
              server.networkDaemonsetNotActive || !networkTotals.length
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Overview;
