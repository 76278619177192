import { Chip, IconButton, TextField } from '@material-ui/core';
import capitalize from 'lodash/capitalize';
import React, { useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { AssetLabelOverride, ReportConfig } from './reportingHelpers';

interface DataSourceGroupingChipProps {
  item: any;
  reportConfig: ReportConfig;
  labelMap: any;
  handleRowUpdate: (value: string, allocationAggType: string) => void;
  saveOverride: any;
}

export const DataSourceGroupingChip = ({
  reportConfig,
  labelMap,
  item,
  handleRowUpdate,
  saveOverride,
}: DataSourceGroupingChipProps) => {
  const [showTextField, setShowTextField] = useState(false);

  const handleSave = () => {
    saveOverride();
    setShowTextField(false);
  };

  const handleDefaultLableValueForType = (aggType: string) => {
    const defaultResponse = `label:${labelMap[`${aggType}`] || aggType}`;
    if (reportConfig.assetLabelOverrides.length > 0) {
      const foundItem = reportConfig.assetLabelOverrides.find(
        (item: AssetLabelOverride) => item.allocationAggregation === aggType,
      );
      if (foundItem) {
        return foundItem.assetLabelOverride;
      } else {
        return defaultResponse;
      }
    } else if (aggType.startsWith('label:')) {
      return aggType;
    } else {
      return defaultResponse;
    }
  };
  return (
    <>
      {showTextField ? (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '30px 1fr',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={handleSave}>
            <SaveIcon color="primary" />
          </IconButton>
          <TextField
            onChange={(e: any) => handleRowUpdate(e.currentTarget.value, item)}
            id="standard-basic"
            fullWidth
            defaultValue={handleDefaultLableValueForType(item)}
          />
        </div>
      ) : (
        <Chip
          label={capitalize(item)}
          onDelete={() => setShowTextField(true)}
          deleteIcon={<EditIcon />}
        />
      )}
    </>
  );
};
