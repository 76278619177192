import { Button } from '@kubecost-frontend/holster';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CloseIcon } from '../assets/images';
import useBetaUI from '../hooks/useBetaUI';

const BetaUIBanner: React.FC = () => {
  const [isDismissed, setIsDismissed] = useState<boolean>(false);
  const { isBetaUI } = useBetaUI();
  const { pathname } = useLocation();

  const isVisible = pathname !== '/settings' && !isBetaUI && !isDismissed;

  return isVisible ? (
    <header
      className={
        'bg-kc-primary flex items-center justify-center mb-7 p-2 relative'
      }
      role="banner"
    >
      🎉 A new look is coming to Kubecost! Get a sneak peak today{' '}
      <Link
        className={'holster-button kc-button-primary ml-5 mr-9 shrink-0'}
        to={'/settings'}
      >
        Try it out
      </Link>
      <button
        className={'absolute h-full px-3 right-0'}
        onClick={() => setIsDismissed(true)}
      >
        <CloseIcon />
      </button>
    </header>
  ) : null;
};

export default BetaUIBanner;
