import { Box, Paper } from '@material-ui/core';
import React from 'react';
import { primary } from '../../services/colors';
import { CircleProgress } from './CircleProgress';

interface CostEfficiencyCardProps {
  efficiencyCostWeight: number;
  cpuEfficiency: number;
  ramEfficiency: number;
}

export const CostEfficiencyCard = ({
  efficiencyCostWeight,
  cpuEfficiency,
  ramEfficiency,
}: CostEfficiencyCardProps) => (
  <Box
    component={Paper}
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    }}
  >
    <div>
      <div style={{ fontSize: 22, marginBottom: '.5em', fontWeight: 600 }}>
        Cost Efficiency
      </div>
      <div style={{ color: primary[0], fontSize: 34 }}>
        <strong>{(efficiencyCostWeight || 0).toFixed(2)}%</strong>
      </div>
      <Box style={{ marginTop: '3em', display: 'flex', gridGap: '2em' }}>
        <CircleProgress
          width="5em"
          label="CPU"
          value={parseInt(cpuEfficiency.toFixed(2)) || 0}
        />
        <CircleProgress
          width="5em"
          label="RAM"
          value={parseInt(ramEfficiency.toFixed(2)) || 0}
        />
      </Box>
    </div>
  </Box>
);
