import React, { useState } from 'react';
import { InspectedResourceListItem } from './inspectState';
import { FilterItem } from './FilterItem';
import { Button, MenuButton } from '@kubecost-frontend/holster';
import { Header } from '../../../components/Header2New';
import { ArrowIcon } from '../../../assets/images';
export interface InspectFilterTileProps {
  primaryAggregation?: InspectedResourceListItem;
  secondaryAggregations: InspectedResourceListItem[];
  activeTimeWindow: string;
  setActiveTimeWindow: (time: string) => void;
  handleFilterUpdate: (type: string, value: string) => Promise<boolean>;
}

const timeWindowOptions = ['12h', '24h', '7d', '30d'].map((time: string) => ({
  label: `Last ${time}`,
  value: time,
}));

export const InspectFilterTile = ({
  handleFilterUpdate,
  activeTimeWindow,
  setActiveTimeWindow,
  primaryAggregation,
  secondaryAggregations,
}: InspectFilterTileProps) => {
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const filterCount = secondaryAggregations.filter(
    ({ name }: InspectedResourceListItem) => name !== '',
  ).length;
  return (
    <div className="mb-8">
      {primaryAggregation && (
        <Header
          refreshCallback={() => location.reload()}
          title={`${primaryAggregation.name} ${primaryAggregation.type}`}
        />
      )}
      <div className="flex justify-end h-8 gap-4">
        <Button
          variant="default"
          onClick={() => setShowFilters((prevState) => !prevState)}
        >
          Filters ({filterCount})
        </Button>
        <MenuButton
          value={activeTimeWindow}
          setValue={setActiveTimeWindow}
          options={timeWindowOptions}
          trailingIcon={<ArrowIcon direction="LEFT" />}
        />
      </div>
      {showFilters && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            marginTop: '1em',
            marginBottom: '1em',
          }}
        >
          {secondaryAggregations.map(
            ({ type, name }: InspectedResourceListItem) => (
              <FilterItem
                type={type}
                defaultValue={name}
                updateItem={handleFilterUpdate}
              />
            ),
          )}
        </div>
      )}
    </div>
  );
};
