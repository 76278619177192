// MUI components
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Link from '@material-ui/core/Link';
import { Link as RouteLink } from 'react-router-dom';

// MUI icons
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

// MUI styles
import { makeStyles } from '@material-ui/styles';

// react
import React, { useEffect, useState } from 'react';

// recharts
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
} from 'recharts';

// local
import { primary } from '../../services/colors';
import BarChartTooltipMaker from '../../components/BarChartTooltip';
import WithDataLoader from '../../components/DataLoader';
import Model from '../../services/model';
import { toCurrency } from '../../services/format';
import { AssetSet } from '../../types/asset';

const useStyles = makeStyles({
  actionButton: {
    color: '#2196f3',
  },
  actionLink: {
    marginLeft: 'auto',
  },
});

const BarChartWithLoading = WithDataLoader(BarChart);

function AssetCard({ win }: { win: '1d' | '7d' }): React.ReactElement {
  const classes = useStyles();

  const [promise, setPromise] = useState<Promise<unknown> | null>(null);
  const [currency, setCurrency] = useState('USD');
  const [barData, setBarData] = useState<BarData[]>([]);
  const [barLabels, setBarLabels] = useState<string[][]>([]);

  useEffect(() => {
    setPromise(fetchData());
  }, []);

  // resize chart when the containing element resizes
  const [chartWidth, setChartWidth] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      const el = document.querySelector(
        '[data-ref="cost-efficiency-card-wrapper"]',
      );
      if (!el) {
        return;
      }
      const w = el.clientWidth - 25;
      setChartWidth(w);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const CustomTooltip = BarChartTooltipMaker(currency);

  return (
    <Card>
      <CardHeader
        title="Kubernetes Asset Costs"
        subheader={`Cumulative Kubernetes cluster costs per asset type over the last ${
          win === '1d' ? 'day' : '7 days'
        }. Does not include Network costs.`}
      />
      <CardContent>
        <BarChartWithLoading
          data={barData}
          margin={{ top: 30, right: 30, left: 30, bottom: 12 }}
          height={350}
          width={chartWidth}
          errorMessage="Could not load asset chart"
          loadMessage="Loading asset chart"
          promise={promise}
        >
          <CartesianGrid strokeDasharray="0" vertical={false} />
          <XAxis dataKey="start" />
          <YAxis tickFormatter={(val) => toCurrency(val, currency, 0, true)} />
          <Legend />
          <Tooltip content={<CustomTooltip />} />
          {barLabels.map((barLabel) => (
            <Bar
              key={barLabel[0]}
              dataKey={barLabel[0]}
              stackId="a"
              fill={barLabel[1]}
            />
          ))}
        </BarChartWithLoading>
      </CardContent>
      <CardActions>
        <Link
          component={RouteLink}
          to={`/assets?agg=type&filters=${btoa(
            JSON.stringify([
              {
                property: 'type',
                value: 'ClusterManagement,Disk,LoadBalancer,Node',
              },
            ]),
          )}`}
          className={classes.actionLink}
        >
          <Button
            className={classes.actionButton}
            endIcon={<KeyboardArrowRightIcon />}
          >
            Assets
          </Button>
        </Link>
      </CardActions>
    </Card>
  );

  async function fetchData() {
    const prom = Promise.all([
      Model.getAssets(win, {
        aggregate: 'type',
        filters: [
          {
            property: 'type',
            value: 'Disk,LoadBalancer,ClusterManagement,Node',
          },
        ],
      }),
      Model.getConfigs(),
    ]);
    const [assetResponse, modelConfig] = await prom;
    const assetSetRange = assetResponse.data;
    const mergedObj = assetSetRange.reduce(
      (acc: AssetSet, assetSet: AssetSet) => ({ ...acc, ...assetSet }),
      {},
    );
    const names = Object.keys(mergedObj);
    const labels = names.map((name, i) => [name, primary[i % primary.length]]);

    const data = assetSetRange.map((assetSet: AssetSet) => {
      let start = '';
      const obj = Object.entries(assetSet).reduce((acc, [key, asset]) => {
        if (key === 'Cloud') {
          return acc;
        }
        start = asset.window.start.split('T')[win === '1d' ? 1 : 0];
        return {
          ...acc,
          [key]: asset.totalCost,
        };
      }, {});
      return { ...obj, start };
    });

    setBarLabels(labels);
    setBarData(data);
    setCurrency(modelConfig.currencyCode || 'USD');
  }
}

export default AssetCard;

interface BarData {
  [index: string]: string | number;
}
