// react
import React, { FC } from 'react';

// holster
import { Button } from '@kubecost-frontend/holster';

// local
import { SettingsIcon } from '../../assets/images/settings-icon';

interface EditControlProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const EditControl: FC<EditControlProps> = ({ onClick }) => {
  return (
    <Button
      variant="default"
      className="flex items-center h-33px mr-3"
      onClick={onClick}
    >
      <SettingsIcon className="mr-2" />
      Edit Report
    </Button>
  );
};
