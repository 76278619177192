import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import SpeakerIcon from '@material-ui/icons/Speaker';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/styles';
import concat from 'lodash/concat';
import filter from 'lodash/filter';
import get from 'lodash/get';
import keys from 'lodash/keys';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import Page from '../../components/Page';
import { toCurrency } from '../../services/format';
import model from '../../services/model';
import {
  fetchOrphanedDisks,
  fetchOrphanedIPAddresses,
} from '../../services/savings';
import { captureError } from '../../services/error_reporting';
import DiagnosticsChecker from '../../components/DiagnosticsChecker';
import { Warning } from '../../components/Warnings';
import { CopyToClipBoard } from '../../components/CopyToClipboard';
import {
  OrphanedResourcesResponse,
  fetchOrphanedResources,
} from './OrphanedResources';

const useStyles = makeStyles({
  description: {
    padding: '24px 36px',
    marginBottom: 20,
  },
  errors: {
    padding: '12px 18px',
    marginBottom: 20,
  },
  loading: {
    textAlign: 'center',
    width: '100%',
  },
  totalSavings: {
    alignItems: 'center',
    color: green[700],
    display: 'flex',
    textAlign: 'center',
  },
  flexGrow: {
    flexGrow: 1,
  },
  panelNameWrapper: {
    flex: '1 0 auto',
    display: 'flex',
  },
  chip: {
    margin: '-2px 12px -2px 0',
  },
  form: {
    alignItems: 'center',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    display: 'flex',
    padding: '16px 24px',
    marginBottom: 0,
  },
  formControl: {
    margin: 8,
    minWidth: 120,
  },
  green: {
    color: green[700],
  },
});

const AddressDescription = (address) => (
  <div>
    <Typography paragraph>Address: {address.address}</Typography>
    <Typography paragraph>Region: {address.region}</Typography>
    <Typography>Description: {JSON.stringify(address.description)}</Typography>
  </div>
);

const DiskDescription = (disk) => (
  <div>
    <Typography paragraph>Size: {disk.sizeGB} GB</Typography>
    <Typography paragraph>Region: {disk.region}</Typography>
    <Typography paragraph>Last attached: {disk.lastAttached || '-'}</Typography>
    <Typography paragraph>Last detached: {disk.lastDetached || '-'}</Typography>
    <Typography>Description: {JSON.stringify(disk.description)}</Typography>
  </div>
);

const OrphanedResourceDescription = (or: OrphanedResourcesResponse) => (
  <div>
    <Typography paragraph>Size: {or.diskSizeInGB} GB</Typography>
    <Typography paragraph>Region: {or.region}</Typography>
    <Typography>Description: {JSON.stringify(or.description)}</Typography>
  </div>
);

const OrphanedResourcesPage = () => {
  const classes = useStyles();

  const [fetch, setFetch] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [warnings, setWarnings] = useState<Array<Warning>>([]);
  const [currency, setCurrency] = useState('');
  const [disks, setDisks] = useState([]);
  const [loadingDisks, setLoadingDisks] = useState(true);
  const [addresses, setAddresses] = useState([]);
  const [loadingAddresses, setLoadingAddresses] = useState(true);
  const [regionFilter, setRegionFilter] = useState('all');
  const [resourceFilter, setResourceFilter] = useState('all');
  const [durationFilter, setDurationFilter] = useState(60 * 60);
  const [items, setItems] = useState([]);
  const [provider, setProvider] = useState('');
  const [orphanedResources, setOrphanedResources] = useState<
    OrphanedResourcesResponse[]
  >([]);
  const [loadingOrphanedResources, setLoadingOrphanedResources] =
    useState(true);

  async function initialize() {
    const config = await model.getConfigs();
    setCurrency(config.currencyCode);
    const clusterInfo = await model.clusterInfo();
    setProvider(clusterInfo.provider.toLowerCase());
    setInitialized(true);
  }

  async function fetchData() {
    if (fetch) {
      clearWarnings();

      try {
        if (provider === 'azure') {
          setLoadingOrphanedResources(true);
          const orphanedResources = await fetchOrphanedResources();
          setOrphanedResources(orphanedResources);
          setLoadingOrphanedResources(false);
        } else {
          setLoadingDisks(true);
          const disks = await fetchOrphanedDisks();
          setDisks(disks);
          setLoadingDisks(false);

          setLoadingAddresses(true);
          const addresses = await fetchOrphanedIPAddresses();
          setAddresses(addresses);
          setLoadingAddresses(false);
        }
      } catch (err) {
        console.error(err);
        pushWarning('Failed to load resources');
        captureError(err);

        if (provider === 'azure') {
          setOrphanedResources([]);
          setLoadingOrphanedResources(false);
        } else {
          setDisks([]);
          setLoadingDisks(false);
          setAddresses([]);
          setLoadingAddresses(false);
        }
      }

      setFetch(false);
    }
  }

  const clearWarnings = () => {
    setWarnings(() => []);
  };

  const pushWarning = (warn: Warning) => {
    setWarnings((warns: Warning[]) => [...warns, warn]);
  };

  // Handle fetching data
  useEffect(() => {
    if (!initialized) {
      initialize();
    }
    if (initialized && fetch) {
      fetchData();
    }
  }, [initialized, fetch]);

  const loading =
    (loadingDisks || loadingAddresses) && loadingOrphanedResources;

  // Handle filter states
  const regionSet = {};
  if (provider === 'azure') {
    orphanedResources.map((or) => {
      if (or.region) {
        regionSet[or.region] = or.region;
      }
    });
  } else {
    for (const address of addresses) {
      if (address.region) {
        regionSet[address.region] = address.region;
      }
    }
    for (const disk of disks) {
      if (disk.region) {
        regionSet[disk.region] = disk.region;
      }
    }
  }
  const regions = keys(regionSet);

  const handleRegionFilterChange = (e) => setRegionFilter(e.target.value);
  const handleResourceFilterChange = (e) => setResourceFilter(e.target.value);

  // Combine all orphaned resources into one collection and sort by cost
  useEffect(() => {
    let newItems = concat(
      disks.map((d) => ({
        description: DiskDescription(d),
        icon: <SpeakerIcon />,
        label: d.sizeGB + ' GB',
        lastUsed: moment(get(d, 'lastDetached', 0)),
        name: d.name,
        monthlyCost: d.monthlyCost,
        region: d.region,
        type: 'disk',
        url: d.url,
      })),
      addresses.map((a) => ({
        description: AddressDescription(a),
        icon: <DeviceHubIcon />,
        label: a.address,
        lastUsed: moment(0),
        name: a.name,
        monthlyCost: a.monthlyCost,
        region: a.region,
        type: 'address',
        url: a.url,
      })),
      orphanedResources.map((or) => ({
        description: OrphanedResourceDescription(or),
        icon: <SpeakerIcon />,
        label: or.diskSizeInGB + ' GB',
        lastUsed: moment(0),
        name: or.diskName,
        monthlyCost: or.monthlyCost,
        region: or.region,
        type: or.resourceKind,
        url: 'tbd',
      })),
    );
    newItems = filter(newItems, (item) => {
      if (regionFilter !== 'all' && item.region !== regionFilter) {
        return false;
      }
      if (resourceFilter !== 'all' && item.type !== resourceFilter) {
        return false;
      }
      const threshold = moment().subtract(durationFilter, 'seconds');
      if (
        durationFilter > 0 &&
        item.lastUsed &&
        threshold.isBefore(item.lastUsed)
      ) {
        return false;
      }
      return true;
    });
    setItems(reverse(sortBy(newItems, 'monthlyCost')));
  }, [
    disks,
    addresses,
    orphanedResources,
    durationFilter,
    regionFilter,
    resourceFilter,
  ]);

  // Compute savings totals
  const diskSavings = disks.reduce(
    (total, disk) => total + disk.monthlyCost,
    0.0,
  );
  const addressSavings = addresses.reduce(
    (total, address) => total + address.monthlyCost,
    0.0,
  );
  const orphanedResourcesSavings = orphanedResources.reduce(
    (total, or) => total + or.monthlyCost,
    0.0,
  );
  const totalSavings = diskSavings + addressSavings + orphanedResourcesSavings;
  const filteredSavings = items.reduce(
    (total, item) => total + item.monthlyCost,
    0.0,
  );

  return (
    <>
      <Header
        breadcrumbs={[
          { name: 'Cluster Savings', href: 'savings.html' },
          { name: 'Orphaned Resources', href: 'orphaned-resources.html' },
        ]}
      >
        <IconButton aria-label="refresh" onClick={() => setFetch(true)}>
          <RefreshIcon />
        </IconButton>
        <DiagnosticsChecker />
        <Link href="settings.html">
          <IconButton aria-label="refresh">
            <SettingsIcon />
          </IconButton>
        </Link>
      </Header>

      <Paper className={classes.description}>
        <Grid container spacing={3}>
          <Grid item md={4} className={classes.totalSavings}>
            {loading && (
              <div className={classes.loading}>
                <CircularProgress />
              </div>
            )}
            {!loading && (
              <Typography
                variant="h3"
                className={classes.flexGrow}
                id="total-savings"
              >{`${toCurrency(totalSavings, currency)}/mo`}</Typography>
            )}
          </Grid>
          <Grid item md={8}>
            <Typography variant="h5" paragraph>
              Orphaned Resources
            </Typography>
            <Typography variant="body1">
              Disks and IP addresses that are not being used by any clusters may
              continue to incur charges. The following resources have been
              identified as potentially orphaned and are candidates for
              deletion.
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      {!loading && warnings.length > 0 && (
        <Paper className={classes.errors}>
          <List>
            {warnings.map((err, i) => (
              <ListItem key={i}>
                <ListItemIcon>
                  <WarningIcon />
                </ListItemIcon>
                <ListItemText
                  primary={err}
                  secondary="Check that you have a valid service key and the cost analyzer API is running, then refresh"
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}

      {!loading && (
        <Paper className={classes.form}>
          <div className={classes.flexGrow}>
            <FormControl className={classes.formControl}>
              <InputLabel id="resource-type-select-label">Resource</InputLabel>
              <Select
                labelId="resource-type-select-label"
                id="resource-type-select"
                value={resourceFilter}
                onChange={handleResourceFilterChange}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="disk">Disk</MenuItem>
                <MenuItem value="address">IP Address</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="region-select-label">Region</InputLabel>
              <Select
                labelId="region-select-label"
                id="region-select"
                value={regionFilter}
                onChange={handleRegionFilterChange}
              >
                <MenuItem key="all" value="all">
                  All
                </MenuItem>
                {regions.map((region) => (
                  <MenuItem key={region} value={region}>
                    {region}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ textAlign: 'center', padding: '0 1em' }}>
            <Typography variant="h5">
              <span className={classes.green}>
                {toCurrency(filteredSavings, currency)}/mo
              </span>
            </Typography>
            <Typography variant="body2">{items.length} resources</Typography>
          </div>
        </Paper>
      )}

      {!loading &&
        items.map((item, i) => (
          <ExpansionPanel key={i}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <div className={classes.panelNameWrapper}>
                <Chip
                  icon={item.icon}
                  label={item.label}
                  size="small"
                  className={classes.chip}
                />
                <Typography>{item.name}</Typography>
                <div style={{ marginLeft: '.5em' }}>
                  <CopyToClipBoard copyText={item.name} />
                </div>
              </div>
              <Typography style={{ color: green[700] }}>
                {toCurrency(item.monthlyCost, currency)}/mo
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div>{item.description}</div>
            </ExpansionPanelDetails>
            <ExpansionPanelActions>
              <Button
                href={item.url}
                color="primary"
                target="_blank"
                rel="noopener"
              >
                View in console
              </Button>
            </ExpansionPanelActions>
          </ExpansionPanel>
        ))}
    </>
  );
};

export default React.memo(OrphanedResourcesPage);
