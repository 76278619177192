// react
import React, { FC, useState } from 'react';

// mui
import Popover from '@material-ui/core/Popover';
import DateFnsUtils from '@date-io/date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import CircularProgress from '@material-ui/core/CircularProgress';
import { isValid } from 'date-fns';

// holster
import {
  Button,
  FormControlLabel,
  Input,
  Typography,
} from '@kubecost-frontend/holster';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

interface EnableAutoscalingProps {
  handleAutoscalingRequest: (frequency: number, startDate: Date) => void;
  loading: boolean;
}

const frequencyOptions = [
  { display: 'Daily', value: 60 * 24 },
  { display: 'Weekly', value: 60 * 24 * 7 },
  { display: 'Monthly', value: 60 * 24 * 7 * 4 },
  { display: 'Quarterly', value: 60 * 24 * 7 * 4 * 3 },
];

export const EnableAutoscaling: FC<EnableAutoscalingProps> = ({
  handleAutoscalingRequest,
  loading,
}) => {
  const [anchorEl, setAnchorEl] = useState<EventTarget & Element>();
  const [selectedFrequency, setSelectedFrequency] = useState(
    frequencyOptions[0].value,
  );
  const [startDate, setStartDate] = useState(new Date());

  const handleStartDateChange = (date: MaterialUiPickersDate) => {
    if (isValid(date)) {
      setStartDate(date);
    }
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <Button
        variant="default"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        className="mr-3"
      >
        Enable Autoscaling{' '}
        {loading && <CircularProgress className="ml-2" size="15px" />}
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(undefined);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          className: 'p-6',
        }}
      >
        <div style={{ width: 200 }}>
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                style={{ width: '200px' }}
                autoOk
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy HH:mm"
                margin="normal"
                id="date-range-control-custom-start"
                label="Start Date"
                value={startDate}
                onChange={handleStartDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="mt-4">
            {frequencyOptions.map((opt: { display: string; value: number }) => (
              <Typography
                variant="p"
                className={`p-2 hover:bg-kc-success-light rounded cursor-pointer ${
                  selectedFrequency == opt.value ? 'text-kc-link' : ''
                }`}
                onClick={() => setSelectedFrequency(opt.value)}
              >
                {opt.display}
              </Typography>
            ))}
          </div>
        </div>
        <div className="flex justify-end mt-3">
          <div className="hover:bg-kc-success-light cursor-pointer p-2">
            Cancel
          </div>
          <div
            onClick={() => {
              handleAutoscalingRequest(selectedFrequency, startDate);
              setAnchorEl(undefined);
            }}
            className="text-kc-primary hover:bg-kc-success-light cursor-pointer p-2"
          >
            Apply
          </div>
        </div>
        <div className="mt-2" style={{ width: 200 }}>
          <Typography variant="p-small">
            <span className="font-bold">Note: </span>
            this will only be applied to Deployments
          </Typography>
        </div>
      </Popover>
    </>
  );
};

EnableAutoscaling.displayName = 'EnableAutoscaling';
