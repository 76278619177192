import { Chip, Typography } from '@kubecost-frontend/holster';
import { round } from 'lodash';
import React from 'react';
import { toCurrency } from '../../services/format';
import { greyscale } from '../../services/colors';

interface InstanceBreakdownCellProps {
  isExpanded: boolean;
  nodes: any;
  currency?: string;
  discount?: number;
}

interface CellDisplayElementProps {
  value: string;
  title: string;
}
const CellDisplayElement = ({ value, title }: CellDisplayElementProps) => (
  <div>
    <Typography className="font-bold" variant="p-large">{title}</Typography>
    <Typography variant="p">{value}</Typography>
  </div>
);

export const CurrentInstanceBreakdownCell = ({
  nodes,
  isExpanded,
}: InstanceBreakdownCellProps) => {
  const firstNode = nodes[0];
  return (
    <div>
      {isExpanded ? (
        <div>
          {nodes.map((node, i) => (
            <div>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '.5em' }}
              >
                <Chip label={node.count} color="primary" />
                <Typography variant="p">{node.name}</Typography>
                <Chip
                  color="primary"
                  label={node.architecture === 'ARM' ? 'ARM' : 'x86'}
                />
              </div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr',
                  marginTop: '.5em',
                  marginBottom: '.5em',
                }}
              >
                <CellDisplayElement
                  value={`${round(node.vCPUs, 2)} VCPUs ea.`}
                  title="VCPUs"
                />
                <CellDisplayElement
                  value={`${round(node.RAMGB, 2)} RAM (GB) ea.`}
                  title="RAM"
                />
                <CellDisplayElement value="n/a" title="Cost" />
              </div>
              <div
                style={{
                  height: 1,
                  width: '100%',
                  backgroundColor: greyscale[0],
                  margin: '1em',
                }}
              />
            </div>
          ))}
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', gap: '.5em' }}>
          <Chip label={firstNode.count} color="primary" />
          <Typography variant="p">
            {firstNode.name}{' '}
            {nodes.length > 1 && (
              <strong>{` + ${nodes.length - 1} more`}</strong>
            )}
          </Typography>
        </div>
      )}
    </div>
  );
};

export const RecommendedInstanceBreakdownCell = ({
  nodes,
  isExpanded,
  discount,
  currency,
}: InstanceBreakdownCellProps) => {
  const firstNode = nodes[0];
  return (
    <div>
      {isExpanded ? (
        <div>
          {nodes.map((node, i) => (
            <div>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '.5em' }}
              >
                <Chip label={node.count} color="primary" />
                <Typography variant="p">{node.type.name}</Typography>
                <Chip
                  color="primary"
                  label={node.type.architecture === 'ARM' ? 'ARM' : 'x86'}
                />
              </div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr',
                  marginTop: '.5em',
                  marginBottom: '.5em',
                }}
              >
                <CellDisplayElement
                  value={`${round(node.type.vCPUs, 2)} VCPUs ea.`}
                  title="VCPUs"
                />
                <CellDisplayElement
                  value={`${round(node.type.RAMGB, 2)} RAM (GB) ea.`}
                  title="RAM"
                />
                <CellDisplayElement
                  value={`${toCurrency(
                    round(730.0 * (1.0 - discount!) * node.type.hourlyPrice, 2),
                    currency!,
                  )}/mo ea.`}
                  title="Cost"
                />
              </div>
              <div
                style={{
                  height: 1,
                  width: '100%',
                  backgroundColor: greyscale[0],
                  margin: '1em',
                }}
              />
            </div>
          ))}
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', gap: '.5em' }}>
          <Chip label={firstNode.count} color="primary" />
          <Typography variant="p">
            {firstNode.type.name}{' '}
            {nodes.length > 1 && (
              <strong>{` + ${nodes.length - 1} more`}</strong>
            )}
          </Typography>
        </div>
      )}
    </div>
  );
};
