import Chip from '@material-ui/core/Chip';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';

import React, { useState } from 'react';

function EmailRecipients({
  addItem,
  recipients,
  removeItem,
}: ComponentProps): React.ReactElement {
  const [text, setText] = useState('');

  return (
    <>
      <FormGroup>
        <TextField
          helperText="List of email recipients (optional). Press Enter after typing each address."
          inputProps={{
            'data-test': 'emailRecipientInput',
          }}
          label="Add a recipient"
          placeholder="user@domain.tld"
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyPress={(e) => {
            if (e.key.toLowerCase() === 'enter') {
              addItem(text);
              setText('');
            }
          }}
        />
      </FormGroup>
      {recipients.map((r, i) => (
        <Chip
          key={r}
          label={r}
          onDelete={() => removeItem(i)}
          data-test="emailRecipientsList"
        />
      ))}
    </>
  );
}

export default EmailRecipients;

interface ComponentProps {
  recipients: string[];
  addItem: (item: string) => void;
  removeItem: (item: number) => void;
}
