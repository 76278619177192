import React from 'react';

interface ActionMenuItemProps {
  className?: string;
  children: React.ReactNode;
  isSelected?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const ActionMenuItem = ({
  className,
  children,
  isSelected,
  onClick,
}: ActionMenuItemProps) => (
  <button
    aria-pressed={isSelected}
    className={`p-2 ${isSelected ? 'text-kc-link' : ''} ${
      className ? className : ''
    }`}
    onClick={onClick}
  >
    {children}
  </button>
);

export { ActionMenuItem };
