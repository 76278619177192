// react and react-router
import React, { FC } from 'react';
import { useNavigate } from 'react-router';

// holster
import { Modal } from '@kubecost-frontend/holster';

// local
import { AssetFilter } from '../../types/asset';
import { EditoControlMemoized } from './AssetControls';

interface CustomizeReportModalProps {
  accumulate: any;
  accumulateOptions: any;
  filters: any;
  rate: any;
  open: boolean;
  closeModal: () => void;
}

export const CustomizeReportModal: FC<CustomizeReportModalProps> = ({
  accumulate,
  accumulateOptions,
  filters,
  rate,
  open,
  closeModal,
}) => {
  const searchParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

  return (
    <Modal open={open} onClose={closeModal} title="Edit Report">
      <EditoControlMemoized
        accumulateOptions={accumulateOptions}
        accumulate={accumulate}
        setAccumulate={(acc: any) => {
          searchParams.set('acc', String(acc));
          navigate({
            search: `?${searchParams.toString()}`,
          });
        }}
        onClose={closeModal}
        filters={filters}
        setFilters={(f: AssetFilter[]) => {
          const fltr = btoa(JSON.stringify(f));
          searchParams.set('filters', fltr);
          navigate({
            search: `?${searchParams.toString()}`,
          });
        }}
        rate={rate}
        setRate={(r: string) => {
          searchParams.set('rate', r);
          navigate({
            search: `?${searchParams.toString()}`,
          });
        }}
      />
    </Modal>
  );
};
